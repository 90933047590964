define('webapp/components/blob-download', ['exports', 'webapp/utils/file-path', 'ember-cli-file-saver/mixins/file-saver', 'ember-ajax/request'], function (exports, _filePath, _fileSaver, _request) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileSaver.default, {
    isLoading: false,
    fingerprintService: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    gdprService: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    siteId: Ember.computed.alias('cachedData.cachedSite._id'),

    _getDataForFingerprint: function _getDataForFingerprint(fingerprint) {
      var gdprStatus = this.get('gdprService.gdprStatus');
      var user = this.get('userProxy._id');
      var sessionId = this.get('cmsService.sessionId');
      var location = window.location.pathname;
      var site = this.get('siteId');
      var type = 'File-Download';
      var file = this.get('file');
      var fileId = file._id;
      var fileName = file.filename;
      var fileTitle = file.infos.title;
      var filePath = file.path;
      var fileDirectory = file.directory;
      var fileLanguages = file.languages;
      var data = {
        fileId: fileId,
        fileName: fileName,
        fileTitle: fileTitle,
        filePath: filePath,
        fileDirectory: fileDirectory,
        fileLanguages: fileLanguages
      };

      if (gdprStatus !== 'accepted') {
        fingerprint = undefined;
      }

      return {
        fingerprint: fingerprint,
        location: location,
        user: user,
        gdprStatus: gdprStatus,
        sessionId: sessionId,
        site: site,
        type: type,
        data: data
      };
    },
    _download: function _download(url) {
      var _this = this;

      var file = this.get('file');
      var data = { dataType: 'blob' };

      this.set('isLoading', true);

      (0, _request.default)(url, data).then(function (content) {
        _this.saveFileAs(file.filename, content, 'application/' + file.extension);
        _this.set('isLoading', false);
      });
    },


    actions: {
      download: function download(path) {
        var _this2 = this;

        var fingerprint = this.get('fingerprint');
        var url = (0, _filePath.default)(path);

        if (fingerprint) {
          var fingerprintService = this.get('fingerprintService');

          fingerprintService.getFingerprint().then(function (fingerprint) {
            var myStorage = _this2.get('myStorage');
            var data = _this2._getDataForFingerprint(fingerprint);

            myStorage.fingerprint.ajaxPost({ data: data }).then(function () {
              _this2._download(url);
            });
          });
        } else {
          this._download(url);
        }
      }
    }
  });
});
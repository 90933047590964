define('webapp/components/product-navigation', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    languageService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    selectedTab: 0,

    changedLanguage: Ember.observer('languageService.selectedLanguage', function () {
      this.fetchData(this.get('selectedTab'));
      this.fetchSpecialPages();
    }),

    cachedCarriers: null,
    cachedAttachments: null,

    didInsertElement: function didInsertElement() {
      this.fetchData();
      this.fetchSpecialPages();
    },
    fetchData: function fetchData() {
      var _this = this;

      Ember.RSVP.all([this.fetchCarriers(), this.fetchAttachments()]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            carrierProps = _ref2[0],
            attachmentProps = _ref2[1];

        _this.setProperties(_extends({}, carrierProps, attachmentProps));
      });
    },
    fetchCarriers: function fetchCarriers() {
      var myStorage = this.get('myStorage');
      return myStorage.search.ajaxPost({
        data: {
          itemTypes: ['product'],
          categoryIds: ['5cb722adedef932daa315c8d'], // Traegerfahrzeuge
          sort: 'sortOrder'
        },
        query: { lang: this.get('languageService.selectedLanguage') }
      }).then(function (res) {
        return { carriers: res.hits };
      });
    },
    fetchSpecialPages: function fetchSpecialPages() {
      var _this2 = this;

      var cachedData = this.get('cachedData');
      return cachedData.getFlatPages().then(function (pages) {
        var categoryContainer = pages.find(function (page) {
          return page.fullSlug === 'produktkategorien';
        });
        if (categoryContainer) {
          // hacky... site is expected by page-link-to component
          _this2.set('categoryContainer', _extends({}, categoryContainer, { site: cachedData.cachedSite }));
        } else {
          // if on other domain than main, need to fetch full page
          var myStorage = _this2.get('myStorage');
          return myStorage.page.ajaxGet({ param: 'produktkategorien' }).then(function (page) {
            _this2.set('categoryContainer', page);
          });
        }
      });
    },
    fetchAttachments: function fetchAttachments() {
      var myStorage = this.get('myStorage');
      var cachedData = this.get('cachedData');
      return Ember.RSVP.all([myStorage.search.ajaxPost({
        data: {
          itemTypes: ['product'],
          categoryIds: ['5cb722bbedef932daa315c8e'], // Anbaugeräte
          sort: 'alphabetical'
        },
        query: { lang: this.get('languageService.selectedLanguage') }
      }), cachedData.getCategories()]).then(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            res = _ref4[0],
            categories = _ref4[1];

        var items = res.hits;

        var newItems = [];
        var restItems = [];
        items.forEach(function (item) {
          if (item.tags.includes('neu')) {
            newItems.push(item);
          } else {
            restItems.push(item);
          }
        });

        var mainCat = (0, _treeUtils.findInTree)(categories, function (cat) {
          return cat._id === '5cb722bbedef932daa315c8e';
        });
        var attachmentCats = mainCat.children;

        var relevantCats = [];
        attachmentCats.forEach(function (cat) {
          relevantCats.push(_extends({}, cat));
          if (cat.children) {
            cat.children.forEach(function (childCat) {
              relevantCats.push(_extends({}, childCat, {
                title: cat.title + ' \u2013 ' + childCat.title
              }));
            });
          }
        });

        var groupedItems = relevantCats.map(function (cat) {
          return {
            title: cat.title,
            slug: cat.slug,
            image: cat.image,
            items: restItems.filter(function (item) {
              return item.primaryCategory && item.primaryCategory._id === cat._id;
            })
            // items: restItems.filter(item => item.categories.some(_cat => _cat._id === cat._id))
          };
        }).filter(function (group) {
          return group.items.length;
        });

        return {
          attachments: items,
          newAttachments: newItems,
          groupedAttachments: groupedItems
        };
      });
    },


    actions: {
      selectTab: function selectTab(id) {
        this.set('selectedTab', id);
      }
    }
  });
});
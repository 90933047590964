define('webapp/components/widgets/widget-searchbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function findNearestComponent(element) {
    while (element && element.classList) {
      if (element.classList.contains('ember-view')) {
        return element;
      }
      element = element.parentNode;
    }
  }

  exports.default = Ember.Component.extend({
    searchService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    viewService: Ember.inject.service(),
    router: Ember.inject.service(),
    isSearchRoute: Ember.computed.alias('searchService.isSearchRoute'),
    searchTerm: Ember.computed.alias('searchService.searchTerm'),
    selectedCategory: Ember.computed.alias('searchService.selectedCategory'),

    searchShown: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('cachedData').getSite().then(function (site) {
        if (site && site.regionPages) {
          _this.set('regionPages', site.regionPages);
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this.initUikitToggle();
    },
    willDestroyElement: function willDestroyElement() {
      this.destroyUikitToggle();
    },
    search: function search() {
      var searchService = this.get('searchService');
      var searchTerm = this.get('searchTerm');
      searchService.setSearchTerm(searchTerm);

      this.set('viewService.showSearchbar', !this.get('media.isMobile'));

      searchService.transitionSearchRegion();
    },


    // uikit toggle hacks
    initUikitToggle: function initUikitToggle() {
      var _this2 = this;

      if (!this.element) {
        return;
      }
      var elementToToggle = this.element.querySelector('.nav-overlay[hidden]');
      var toggleElementWrapper = findNearestComponent(elementToToggle);

      if (elementToToggle && toggleElementWrapper) {
        this._elementToToggle = elementToToggle;
        var showHandler = this._showHandler = function () {
          toggleElementWrapper.classList.add('uk-width-expand');
          _this2.searchShown = true;
        };
        var hideHandler = this._hideHandler = function () {
          toggleElementWrapper.classList.remove('uk-width-expand');
          _this2.searchShown = false;
        };

        UIkit.util.on(elementToToggle, 'show', showHandler);
        UIkit.util.on(elementToToggle, 'hide', hideHandler);
      }
    },
    destroyUikitToggle: function destroyUikitToggle() {
      if (this._elementToToggle) {
        UIkit.util.off(this._elementToToggle, 'show', this._showHandler);
        UIkit.util.off(this._elementToToggle, 'hide', this._hideHandler);
      }
    },


    actions: {
      search: function search() {
        this.search();
      },
      searchKeyup: function searchKeyup(e) {
        var searchService = this.get('searchService');
        var searchTerm = e.target.value;
        searchService.set('searchTerm', searchTerm);
        if (e.key === 'Enter') {
          this.search();
        } else {
          Ember.run.debounce(searchService, searchService.searchAsYouType, searchTerm, 200);
        }
      },
      selectSuggestion: function selectSuggestion(suggestion) {
        var searchService = this.get('searchService');
        var searchTerm = suggestion.replace(/<.*?>/g, '');
        searchService.set('searchTerm', searchTerm);
        searchService.set('suggestions', null);
        searchService.transitionSearch();
      }
    }
  });
});
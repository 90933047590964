define('webapp/components/widgets/widget-last-visited-products', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    languageService: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.setupProducts();
    },
    setupProducts: function setupProducts() {
      var _this = this;

      var myStorage = this.get('myStorage');

      return myStorage.lastViewed.ajaxGet({
        param: 'products',
        query: {
          sessionId: this.get('cmsService.sessionId'),
          lang: this.get('languageService.selectedLanguage')
        }
      }).then(function (products) {
        _this.set('products', products);
      });
    }
  });
});
define('webapp/components/widget-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    viewService: Ember.inject.service(),
    message: Ember.inject.service(),
    pageModel: Ember.computed.alias('viewService.currentModel'),
    classNameBindings: ['containerClass', 'navbarClass'],
    attributeBindings: ['uk-navbar'],

    containerClass: Ember.computed.oneWay('widgetBucket.containerClass'),

    widgets: Ember.computed('widgetBucket.widgets', function () {
      var owner = Ember.getOwner(this);
      var widgets = this.get('widgetBucket.widgets');
      var message = this.get('message');

      return widgets.filter(function (widget) {
        var widgetExists = owner.hasRegistration('component:widgets/widget-' + widget.widget.type);
        if (!widgetExists) {
          message.infoUnique('component: widgets/widget-' + widget.widget.type + ' doesnt exist');
        }
        return widgetExists;
      });
    }),

    navbarClass: Ember.computed('isNavbar', 'computedClass', function () {
      if (this.get('isNavbar')) {
        return this.get('computedClass');
      }
    }),

    computedClass: Ember.computed('widgetBucket.alignment', function () {
      if (this.get('widgetBucket.alignment') === 'none') {
        return;
      } else {
        var horizontalAlignment = this.get('widgetBucket.horizontalAlignment') || 'space-between';
        if (horizontalAlignment === 'space-between') {
          horizontalAlignment = 'between';
        }

        var addClass = 'uk-flex uk-flex-' + horizontalAlignment;

        if (this.get('widgetBucket.wrap')) {
          addClass += ' uk-flex-wrap';
        }

        if (this.get('widgetBucket.margin')) {
          addClass += ' flex-margin';
        }

        if (this.get('widgetBucket.alignment') === 'stacked') {
          addClass += ' uk-flex-column';
        } else {
          addClass += ' uk-flex-row';
        }

        var vertAlignment = this.get('widgetBucket.vertAlignment');

        if (vertAlignment === 'top') {
          addClass += ' uk-flex-top';
        } else if (vertAlignment === 'bottom') {
          addClass += ' uk-flex-bottom';
        } else {
          addClass += ' uk-flex-middle';
        }

        return addClass;
      }
    })
  });
});
define('webapp/controllers/checkout/register', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    languageService: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    router: Ember.inject.service(),
    message: Ember.inject.service(),

    actions: {
      afterLogin: function afterLogin(error) {
        if (error) {
          var i18n = this.get('i18n');
          var message = i18n.t('user.message.can-not-login').toString();
          UIkit.modal.alert(message);
        } else {
          var router = this.get('router');
          router.replaceWith('checkout.cart');
        }
      },
      afterRegister: function afterRegister(user) {
        var router = this.get('router');
        var userProxy = this.get('userProxy');
        userProxy._login(user);
        router.replaceWith('checkout.cart');
      },
      back: function back() {
        try {
          history.back();
        } catch (err) {
          history.go(-1);
        }
      }
    }
  });
});
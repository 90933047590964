define('webapp/components/detail/entry-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    gdprService: Ember.inject.service(),
    trackingService: Ember.inject.service(),
    isOpen: false,
    selectedTab: 1,

    didInsertElement: function didInsertElement() {
      var gdprService = this.get('gdprService');
      gdprService.one('gdprAccepted', this, this.initTrackers);
    },
    willDestroyElement: function willDestroyElement() {
      var gdprService = this.get('gdprService');
      gdprService.off('gdprAccepted', this, this.initTrackers);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.isDestroyed) {
        this.set('userAvatar', this.get('userProxy.image'));
        this.set('showMessageModal', false);
        Ember.run.debounce(this, this.initTrackers, 500);
        this.fetchPESnippet();
      }
    },
    fetchPESnippet: function fetchPESnippet() {
      var _this = this;

      var provenExpertsCode = this.get('detail.fields.provenExpertsCode');
      var email = this.get('detail.contact.email');
      if (!(provenExpertsCode && email)) {
        return;
      }

      var myStorage = this.get('myStorage');

      return myStorage.entry.ajaxPost({ param: 'proven_experts/rich_snippet', data: { email: email } }).then(function (res) {
        _this.set('provenExpertsSnippet', res && res.html);
      });
    },
    initTrackers: function initTrackers() {
      var detail = this.get('detail');
      var trackingService = this.get('trackingService');
      trackingService.trackFB(detail);
      trackingService.trackGA(detail);
    },
    selectUikitTab: function selectUikitTab(tabIdx) {
      var _this2 = this;

      Ember.run.schedule('afterRender', function () {
        var flyoutContent = _this2.element.querySelector('.flyout-content');
        if (flyoutContent) {
          var children = flyoutContent.children;
          for (var i = 0; i < children.length; i++) {
            var child = children[i];
            if (i === tabIdx) {
              child.classList.add('uk-active');
            } else {
              child.classList.remove('uk-active');
            }
          }
        }
      });
    },
    setAndSelectTab: function setAndSelectTab(tab) {
      this.set('selectedTab', tab);
      this.selectUikitTab(tab - 1);
    },


    isIOS: Ember.computed(function () {
      return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      navigator.userAgent.includes('Mac') && 'ontouchend' in document;
    }),

    actions: {
      toggleIsOpen: function toggleIsOpen() {
        this.toggleProperty('isOpen');
      },
      selectTab: function selectTab(tab) {
        this.toggleProperty('isOpen');
        this.setAndSelectTab(tab);
      },
      selectTabOnly: function selectTabOnly(tab) {
        this.setAndSelectTab(tab);
        if (this._laterCb) {
          Ember.run.cancel(this._laterCb);
        }
      },
      setIsOpen: function setIsOpen(value) {
        this.set('isOpen', value);
      },
      sendMessage: function sendMessage() {
        this.set('showMessageModal', true);
      },
      closeMessageModal: function closeMessageModal() {
        this.set('showMessageModal', false);
      }
    }
  });
});
define('webapp/helpers/has-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  exports.default = Ember.Helper.extend({
    userProxy: Ember.inject.service(),

    _obsGroups: Ember.observer('userProxy.userGroups', function () {
      this.recompute();
    }),

    compute: function compute(_ref) {
      var _ref2 = _toArray(_ref),
          groupSlugs = _ref2.slice(0);

      var userGroups = this.get('userProxy.userGroups') || [];
      return userGroups.find(function (group) {
        return groupSlugs.indexOf(group.slug) >= 0;
      });
    }
  });
});
define('webapp/routes/mailinglist-unsubscribe', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    cmsService: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      var myStorage = this.get('myStorage');
      var cmsService = this.get('cmsService');
      return cmsService.getAndSetActivePage('mailinglist-unsubscribe').catch(function () {}).then(function (model) {
        var controller = _this.controllerFor(_this.routeName);

        return myStorage.mailingList.ajaxGet({ param: 'by_id/' + params.mailinglist }).then(function (res) {
          controller.set('mailingList', res);
          controller.set('memberId', params.memberId);
        }).then(function () {
          return model;
        });
      });
    },


    actions: {
      unsubscribe: function unsubscribe() {
        var _this2 = this;

        var message = this.get('message');
        var myStorage = this.get('myStorage');
        var controller = this.controllerFor(this.routeName);
        var mailingList = controller.get('mailingList');
        var memberId = controller.get('memberId');

        myStorage.mail.ajaxPut({ param: 'opt_out/' + mailingList._id + '/' + memberId }).then(function () {
          message.infoT('user.ui.unsubscribeSuccess');
          _this2.transitionTo('index');
        });
      }
    }
  });
});
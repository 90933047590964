define('webapp/mixins/transition-logger', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    trackPiwik: Ember.on('didTransition', function () {
      var url = this.get('url');

      if (window.location && window.location.href) {
        // piwik
        if (window._paq) {
          window._paq.push(['trackPageView', url]);
        }

        // google tag manager
        if (window.dataLayer) {
          var data = {
            event: 'Pageview',
            pagePath: window.location.href,
            pageTitle: document.title
          };
          window.dataLayer.push(data);
        }
      }
    })
  });
});
define('webapp/services/tracking-service', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var registeredGaCodes = [];
  var fbSetup = false;

  var siteToGaField = {
    '.de': 'gaCode_DE',
    '.at': 'gaCode_AT',
    '.ch': 'gaCode_CH',
    '.co.uk': 'gaCode_UK',
    '.it': 'gaCode_IT',
    '.us': 'gaCode_US',
    '.fr': 'gaCode_FR'
  };

  exports.default = Ember.Service.extend({
    gdprService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    viewService: Ember.inject.service(),
    userProxy: Ember.inject.service(),

    uedTrackingCalled: false,

    productRootCateoryId: Ember.computed('', function () {
      //
    }),

    gtagConsentOptionsApplied: false,

    gaCustomEvent: function gaCustomEvent() {
      var eventName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'mozaikoo_custom';
      var eventAttribute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'custom_attribute';
      var eventValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      if (this.get('gdprService.gdprStatus') !== 'accepted') {
        return;
      }

      if (window.gtag) {
        var _eventAttributes;

        var eventAttributes = (_eventAttributes = {
          page_title: document.title
        }, _defineProperty(_eventAttributes, eventAttribute, eventValue), _defineProperty(_eventAttributes, 'send_to', window.GA_MEASUREMENT_ID), _eventAttributes);

        window.gtag('event', eventName, eventAttributes);
      }
    },
    gaSearch: function gaSearch(searchTerm, lat, lon) {
      if (this.get('gdprService.gdprStatus') !== 'accepted') {
        return;
      }

      if (window.gtag) {
        var eventAttributes = {
          page_title: document.title,
          search_term: searchTerm,
          lat: lat,
          lon: lon,
          send_to: window.GA_MEASUREMENT_ID
        };

        window.gtag('event', 'view_search_results', eventAttributes);
      }
    },
    gaPageView: function gaPageView() {
      if (this.get('gdprService.gdprStatus') !== 'accepted') {
        return;
      }

      if (window.gtag) {
        // TODO set gtag consent options if not already done
        if (_environment.default.gtagConsentEnabled && !this.gtagConsentOptionsApplied) {
          this.get('gdprService').applyGtagConsent();
          this.gtagConsentOptionsApplied = true;
        }

        var activePage = this.get('viewService.currentModel');
        var activeDetail = this.get('cmsService.activeDetail');
        var userGroups = this.get('userProxy.userGroups');

        var eventAttributes = {
          page_title: document.title,
          page_location: window.location.href,
          page_path: window.location.pathname,
          send_to: window.GA_MEASUREMENT_ID
        };

        // optional attributes:
        //   user_group
        //   page_property
        //   page_internal
        //   product_type
        //   product_mainType

        if (userGroups && userGroups.length) {
          eventAttributes.user_group = userGroups[0].slug;
        }

        if (activePage) {
          if (activePage.options && activePage.options.trackingPageName) {
            eventAttributes.page_property = activePage.options.trackingPageName;
          }

          if (activePage.internalName) {
            eventAttributes.page_internal = activePage.internalName;
          }

          if (activePage.type === 'productContainer' && activeDetail) {
            if (activeDetail.primaryCategory) {
              eventAttributes.product_type = activeDetail.primaryCategory.slug;
            }

            // first child category of products
            if (activeDetail.categories && activeDetail.categories.length) {
              var productRoot = activeDetail.categories.find(function (cat) {
                return cat.slug === 'product' && cat.level === 1;
              });
              if (productRoot) {
                var firstProductCategory = activeDetail.categories.find(function (cat) {
                  return cat.parent === productRoot._id && cat.level === 2;
                });
                if (firstProductCategory) {
                  eventAttributes.product_mainType = firstProductCategory.slug;
                }
              }
            }
          }
        }
        // console.log('eventAttributes', eventAttributes);

        window.gtag('event', 'page_view', eventAttributes);
      } else if (window.ga) {
        // this is deprecated now, all new sites should use gtag
        window.ga('set', 'page', window.location.pathname + window.location.search);
        window.ga('send', 'pageview');
      }
    },
    uetPageView: function uetPageView() {
      if (window.uetq) {
        if (this.uedTrackingCalled) {
          // skip first event. its triggered by ued script
          window.uetq.push('event', 'page_view', {
            page_path: window.location.pathname + window.location.search
          });
        }
        this.uedTrackingCalled = true;
      }
    },
    trackGA: function trackGA(detail) {
      if (this.get('gdprService.gdprStatus') !== 'accepted') {
        return;
      }

      if (detail.fields) {
        // detail.fields.gaCode &&
        var siteShortTitle = this.get('cachedData.cachedSite.shortTitle');
        var siteGaField = siteToGaField[siteShortTitle];
        var gaCode = detail.fields.gaCode;

        // check if item has site specific ga code
        if (siteGaField && detail.fields[siteGaField]) {
          gaCode = detail.fields[siteGaField];
        }

        var key = gaCode + '|' + detail._id;

        if (!registeredGaCodes.includes(key)) {
          if (window.gtag) {
            window.gtag('config', gaCode, { send_page_view: false });
          } else if (window.ga) {
            window.ga('create', gaCode, 'auto', detail._id);
          }
          registeredGaCodes.push(key);
        }

        if (gaCode) {
          var pathname = window.location.pathname + window.location.search;

          if (window.gtag) {
            var eventData = {
              page_title: document.title,
              page_location: window.location.href,
              page_path: window.location.pathname,
              send_to: gaCode
            };
            window.gtag('event', 'page_view', eventData);
          } else if (window.ga) {
            window.ga('set', 'page', pathname);
            window.ga(detail._id + '.set', 'page', pathname);
            window.ga(detail._id + '.send', 'pageview');
          }
        }
      }
    },
    getFbPixel: function getFbPixel() {
      var siteShortTitle = this.get('cachedData.cachedSite.shortTitle');

      var fbPixel = _environment.default.fbPixel;

      var domainOverride = _environment.default.fbPixelDomain && _environment.default.fbPixelDomain[siteShortTitle];

      if (domainOverride) {
        fbPixel = domainOverride;
      }

      return fbPixel;
    },
    setupFbTracker: function setupFbTracker() {
      var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (window.localStorage.getItem('social-tracking-enabled') !== 'true') {
        return false;
      }

      var site = this.get('cachedData.cachedSite');

      if (fbSetup) {
        return false;
      }

      if (force || !window.fbq && site && ['.de', '.com', '.at', 'store'].includes(site.shortTitle)) {
        window.eval('\n    !function(f,b,e,v,n,t,s)\n    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n    n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\';\n    n.queue=[];t=b.createElement(e);t.async=!0;\n    t.src=v;s=b.getElementsByTagName(e)[0];\n    s.parentNode.insertBefore(t,s)}(window, document,\'script\',\n    \'https://connect.facebook.net/en_US/fbevents.js\');\n    ');
        fbSetup = true;
      }
      return true;
    },
    trackFB: function trackFB(detail) {
      if (this.get('gdprService.gdprStatus') !== 'accepted') {
        return;
      }

      if (detail.fields && detail.fields.fbPixel) {
        this.setupFbTracker(true);
        var fbPixel = detail.fields.fbPixel;
        if (window.fbq) {
          window.fbq('init', fbPixel);
          window.fbq('trackSingle', fbPixel, 'PageView');
        }
      }
    }
  });
});
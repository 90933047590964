define("webapp/utils/object-utils", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.arraysEqual = arraysEqual;
  exports.getChanges = getChanges;
  function arraysEqual(arr1, arr2) {
    if (!arr1 || !arr2) {
      return arr1 === arr2;
    }

    return arr1.length === arr2.length && arr1.every(function (value) {
      return arr2.includes(value);
    });
  }

  // only return the changed fields
  function getChanges(newItem, oldItem) {
    var res = {};

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = Object.keys(newItem)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var key = _step.value;

        if (newItem[key] !== oldItem[key] || Array.isArray(newItem[key]) && Array.isArray(oldItem[key]) && !arraysEqual(newItem[key], oldItem[key])) {
          res[key] = newItem[key];
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return res;
  }
});
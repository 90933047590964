define('webapp/routes/account/component-channels/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),

    model: function model() {
      var myStorage = this.get('myStorage');

      return myStorage.channel.ajaxGet();
    }
  });
});
define("webapp/utils/collections", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.unique = unique;
  exports.uniqueBy = uniqueBy;
  function unique(values) {
    var res = [];

    values.forEach(function (x) {
      if (!res.includes(x)) {
        res.push(x);
      }
    });

    return res;
  }

  function uniqueBy(values, iterator) {
    var keys = [];
    var res = [];

    values.forEach(function (x) {
      var key = iterator(x);
      if (!keys.includes(key)) {
        keys.push(key);
        res.push(x);
      }
    });

    return res;
  }
});
define('webapp/controllers/checkout/order', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['payment_intent', 'payment_intent_client_secret', 'redirect_status', 'source_type'],
    paymentIntentClientSecret: Ember.computed.alias('payment_intent_client_secret'),
    redirectStatus: Ember.computed.alias('redirect_status'),
    paymentIntent: Ember.computed.alias('payment_intent'),
    sourceType: Ember.computed.alias('source_type')
  });
});
define("webapp/locales/ru/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.entryBannerImageNew": "Установите главное изображение для своей записи.",
    "account.ui.profileBannerImageNew": "Установите основное изображение для своего профиля.",
    "account.ui.register": "Зарегистрироваться",
    "account.ui.registeredAs": "В настоящее время вы зарегистрированы как",
    "account.ui.personate": "Изменение идентификационных данных пользователя",
    "account.ui.userNameNotEditableTooltip": "Ваше имя пользователя. Вы не можете изменить введенное имя пользователя. Чтобы изменить имя пользователя, обратитесь к своему контактному лицу. Важно: После изменения вы должны снова войти в систему с новым именем пользователя.",
    "account.ui.additionalDeliveryAddresses": "Вы указали разные адреса доставки.",
    "account.ui.editDeliveryAddresses": "Редактирование адреса доставки",
    "account.ui.editInvoiceAddress": "Редактировать адрес компании",
    "account.ui.noDeliveryAddresses": "Вы еще не ввели другой адрес доставки.",
    "account.ui.addDeliveryAddresses": "Добавить {{further}} Добавить другой адрес доставки",
    "account.ui.addDeliveryAddressTitle": "Добавить адрес доставки",
    "account.ui.editDeliveryAddressTitle": "Редактирование адреса доставки",
    "account.ui.isDefaultAddress": "Это стандартный адрес доставки",
    "account.ui.saveToApplyChanges": "Вы должны сохранить изменения, после чего вы сможете, например, указать разные адреса доставки.",
    "accountTypes.aussteller": "Экспонент",
    "accountTypes.gastgeber": "Хозяин",
    "accountTypes.veranstalter": "Организатор",
    "application.beta.infotext": "Подписки не были созданы.",
    "application.breadcrumbsHome": "Главная",
    "application.month": "в месяц",
    "application.newsletter.alreadySubscribed": "Вы уже являетесь подписчиком {{list}} информационный бюллетень. Вы можете получить доступ к подписке на рассылку в своем аккаунте.",
    "application.newsletter.alreadySubscribedNoList": "Вы уже зарегистрировались на рассылку новостей",
    "application.newsletter.goToList": "Список рассылки звонков",
    "application.newsletter.subscribeButton": "Введите свой адрес электронной почты для подписки на информационный бюллетень",
    "application.newsletter.subscribeButtonMobile": "Электронная почта",
    "application.newsletter.noAccess": "На предлагаемый информационный бюллетень нельзя подписаться с вашего разрешения.",
    "application.newsletter.subscribe": "Подписаться",
    "application.newsletter.subscribeList": "Вы подписались на эту рассылку:",
    "application.newsletter.subscribeThanks": "Спасибо, что подписались на нашу рассылку. Вы получите последний информационный бюллетень в ближайшее время.",
    "application.newsletter.subscribeThanksOptIn": "Благодарим вас за подписку на рассылку. Вы получите электронное письмо с подтверждением регистрации.",
    "application.newsletter.subscribeWithAccount": "Подпишитесь под своим текущим зарегистрированным пользователем.",
    "application.noCosts": "Бесплатно",
    "application.placeholderImageText": "Без иллюстраций",
    "application.searchAllGroups": "во всём",
    "application.searchAllRegions": "во всех местах",
    "application.ui.access": "доступ",
    "application.ui.action": "Действие",
    "application.ui.addDocument": "Добавить документ",
    "application.ui.addRelation": "Добавить соединение",
    "application.ui.addImage": "Добавить изображение",
    "application.ui.addImages": "Добавить фотографии",
    "application.ui.addMedia": "Добавить фотографии/видео",
    "application.ui.addVideo": "Добавить видео",
    "application.ui.advancedEdit": "Расширенный",
    "application.ui.all": "Все",
    "application.ui.alreadyFavorite": "Этот товар находится в вашем списке наблюдения",
    "application.ui.makeFavorite": "Добавить этот товар в ваш список наблюдения",
    "application.ui.also": "Также",
    "application.ui.alphabetical": "По алфавиту",
    "application.ui.and": "и",
    "application.ui.at": "на сайте",
    "application.ui.in": "в",
    "application.ui.atHour": "на сайте",
    "application.ui.hour": "Часы",
    "application.ui.buy": "купить",
    "application.ui.certificate": "Сертификаты",
    "application.ui.confirmDeleteEntry": "Удалить запись {{title}} удалить безвозвратно?",
    "application.ui.contact": "Связаться с",
    "application.ui.count": "Номер",
    "application.ui.currentlyActive": "Работает прямо сейчас",
    "application.ui.deleteEntry": "удалить",
    "application.ui.discardUnsavedChanges": "Отбросить изменения и продолжить",
    "application.ui.discardUnsavedChangesMobile": "Отбросить",
    "application.ui.distance": "Расстояние",
    "application.ui.distant": "удалено",
    "application.ui.dreampoints": "Dreampoints",
    "application.ui.dreampointsAllDreams": "Дримпоинты ваших записей",
    "application.ui.dreampointsDonate": "Дримпоинты для пожертвований",
    "application.ui.dreampointsTooltip": "Вы можете накапливать баллы Dreampoints, участвуя в конкурсе",
    "application.ui.edit": "изменить",
    "application.ui.editDescription": "Редактировать описание",
    "application.ui.editEntry": "редактировать",
    "application.ui.editEvent": "Редактировать событие",
    "application.ui.editImage": "Редактировать изображение",
    "application.ui.editPoi": "Редактировать местоположение",
    "application.ui.editProduct": "Редактировать продукт",
    "application.ui.editProfile": "Редактировать мой профиль",
    "application.ui.editShownProfile": "Изменить отображение моего профиля",
    "application.ui.entriesFocus": "Избранные записи в \"Фокусе\":",
    "application.ui.entriesLatest": "Последние записи в рубрике \"Фокус\":",
    "application.ui.email": "Электронная почта",
    "application.ui.for": "для",
    "application.ui.from": "с сайта",
    "application.ui.fullView": "Общий вид",
    "application.ui.gallery": "Изображения",
    "application.ui.galleryImages": "в галерею...",
    "application.ui.gavePoints": "дал тебе {{points}} точки.",
    "application.ui.goToEntry": "Вход по вызову",
    "application.ui.goToEvent": "Событие вызова",
    "application.ui.goToPoi": "Место вызова",
    "application.ui.goToMeasuring": "Точки измерения",
    "application.ui.goToProduct": "Звоните по телефону",
    "application.ui.hasBeen": "был",
    "application.ui.here": "Поблизости",
    "application.ui.invalidEmbed": "Этот URL-адрес не может быть встроен.",
    "application.ui.imprintLink": "Несет ответственность и юридическую ответственность за содержание данной подстраницы {{entry}} - Оттиск",
    "application.ui.isActive": "Активный",
    "application.ui.isNotActive": "Инвалид",
    "application.ui.knowMore": "Все {{aboutPre}} {{about}}",
    "application.ui.language.de": "Deutsch",
    "application.ui.language.en": "English",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "Italiano",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "Текущий выбранный язык",
    "application.ui.language.switchTo": "Изменить язык на ",
    "application.ui.mailBodyPre": "Здравствуйте, я нашел кое-что интересное:",
    "application.ui.mailSubject": "Я нашел интересную ссылку:",
    "application.ui.mandatory": "Обязательное поле",
    "application.ui.memberSince": "Член клуба с",
    "application.ui.mobileRotate": "Поворот для полноэкранного просмотра",
    "application.ui.more": "больше",
    "application.ui.name": "Имя",
    "application.ui.navigateOneLevelUp": "Вернуться в родительскую папку",
    "application.ui.new": "Новый",
    "application.ui.newEntry": "Создать новую запись",
    "application.ui.newEvent": "Создать новое событие",
    "application.ui.newPoi": "Создать новое местоположение",
    "application.ui.newProduct": "Создание нового продукта",
    "application.ui.noEntry": "Вы еще не создали запись. Создайте свою первую запись прямо сейчас...",
    "application.ui.noEntryProfile": "еще не создал запись.",
    "application.ui.noEvent": "Вы еще не создали событие. Создайте свое первое мероприятие прямо сейчас...",
    "application.ui.noPoi": "Вы еще не создали местоположение. Создайте свое первое место прямо сейчас...",
    "application.ui.noJob": "Вы еще не создали работу. Создайте свою первую работу прямо сейчас...",
    "application.ui.noProduct": "Вы еще не создали продукт. Создайте свой первый продукт прямо сейчас...",
    "application.ui.notEditable": "не может быть изменен",
    "application.ui.nothingFound": "Мы не смогли найти никаких результатов для этого поиска.",
    "application.ui.offer": "Предлагает",
    "application.ui.offer.offers": "Предлагает",
    "application.ui.offer.acceptOffer": "Принять предложение",
    "application.ui.offer.rejectOffer": "Отклонить предложение",
    "application.ui.offer.noOffers": "Нет предложений",
    "application.ui.offer.expiresAt": "Предложение действует до",
    "application.ui.offer.offerExpires": "Срок действия предложения истекает",
    "application.ui.offer.setOtherExpirationDate": "Назначьте другую дату",
    "application.ui.offer.inXDays": "за 30 дней",
    "application.ui.only": "Только",
    "application.ui.openDocument": "Открыть файл",
    "application.ui.or": "или",
    "application.ui.playVideo": "Воспроизвести видео",
    "application.ui.readMore": "Читать далее",
    "application.ui.region": "Регион",
    "application.ui.relevance": "Актуальность",
    "application.ui.reset": "Сбросить все фильтры",
    "application.ui.routingDescription": "Направления",
    "application.ui.saveShownProfile": "Сохранить изменения профиля",
    "application.ui.saveUnsavedChanges": "Сохранить",
    "application.ui.select": "Пожалуйста, выберите",
    "application.ui.shop": "Магазин",
    "application.ui.showAllEntries": "Показать все записи",
    "application.ui.showAllSearch": "Показать все результаты поиска",
    "application.ui.showMoreSearch": "Показать больше результатов поиска",
    "application.ui.resetSearch": "Сброс выбора и повторный поиск",
    "application.ui.sort": "Сортировка",
    "application.ui.stockPhotoLinks": "Ссылки на базы данных изображений",
    "application.ui.suitableFor": "Рекомендуется для",
    "application.ui.total": "Всего",
    "application.ui.totalDreampoints": "Дримпоинты всех ваших записей",
    "application.ui.trySearch": "Хотите попробовать другие поисковые запросы, например",
    "application.ui.type": "Тип",
    "application.ui.unsavedChanges": "Несохраненные изменения",
    "application.ui.updated": "обновленный",
    "application.ui.uploadedFileFor": "Загруженный файл для:",
    "application.ui.userName": "Имя пользователя",
    "application.ui.viewAsList": "Просмотр списка",
    "application.ui.viewAsTable": "Вид таблицы",
    "application.ui.visibleForAll": "Видимый для всех пользователей",
    "application.ui.visibleForFollower": "Видимый для последователей",
    "application.ui.visibleForJoiner": "Видимые для столяров",
    "application.ui.visibleForNone": "Никому не видна",
    "application.ui.visibleForSupporter": "Видимый для сторонников",
    "application.ui.years": "Годы",
    "application.ui.yearsFew": "Детский возраст",
    "application.widgets.highestRatedEntries": "Наибольшее количество баллов Dreampoints",
    "application.widgets.inFocus": "В центре внимания",
    "application.widgets.newestEntries": "Последние {{size}} Записи",
    "application.widgets.noNewestEntries": "Новых записей не найдено...",
    "application.widgets.showCategories": "Показать фильтр категорий",
    "application.widgets.showFilter": "Показать больше фильтров",
    "application.ui.writeUs": "Напишите нам...",
    "blog.ui.category": "Категория",
    "blog.ui.categoryHeading": "Из категории",
    "blog.ui.lastUpdated": "Последнее обновление",
    "blog.ui.openSinglePost": "Читать далее",
    "blog.ui.publishedIn": "Опубликовано в",
    "blog.ui.subCategories": "Подкатегории",
    "blog.ui.written": "Написано",
    "blog.ui.writtenBy": "Написано",
    "buttons.alreadyMeToo": "Я рада, что и вам приснилась эта запись.",
    "buttons.alreadyMeTooMobile": "Я рада, что и вам приснилась эта запись.",
    "buttons.cancel": "Отмена",
    "buttons.confirm": "Подтвердить",
    "buttons.createAccount": "Установите EntryerAccount сейчас",
    "buttons.delete": "удалить",
    "buttons.deleteFavorite": "Больше не следуют",
    "buttons.deleteLike": "Никаких больше подобий",
    "buttons.meToo": "Я бы тоже хотел это сделать",
    "buttons.newPassword": "Запрос нового пароля",
    "buttons.ok": "Хорошо",
    "buttons.ready": "Готовый",
    "buttons.save": "Сохранить изменения",
    "buttons.saveMobile": "Сохранить",
    "buttons.selectThis": "Выберите это",
    "buttons.tryAgain": "Пожалуйста, попробуйте еще раз",
    "category.ui.hideCategories": "Скрыть подкатегории",
    "category.ui.oneLevelUp": "Вернуться к категории суперординат",
    "category.ui.showCategories": "Показать подкатегории",
    "categoryFields.attachmentWidth": "Ширина внедрения",
    "categoryFields.clutch": "Муфта",
    "categoryFields.companyName": "Название компании",
    "categoryFields.dealerInfo": "Информация о дилере",
    "categoryFields.diameter": "Диаметр",
    "categoryFields.displayNetPrice": "Чистая цена",
    "categoryFields.electricalPower": "Электрическая энергия",
    "categoryFields.engine": "Двигатель",
    "categoryFields.engineDescription": "Двигательное оборудование",
    "categoryFields.ERPname": "Имя в управлении товарами",
    "categoryFields.horsePower": "Мощность",
    "categoryFields.netWidth": "Ширина колеи без шин",
    "categoryFields.packingUnit": "Упаковочная единица",
    "categoryFields.PTO": "ВОМ",
    "categoryFields.relativeGauge": "Расширяет дорожку за счет",
    "categoryFields.vendorAcronym": "Краткое обозначение",
    "categoryFields.volume": "Объем",
    "categoryFields.weight": "Вес",
    "categoryFields.wheelLock": "Блокировка опорного колеса",
    "checkout.cart.title": "Моя корзина",
    "checkout.cart.item": "Статья",
    "checkout.cart.amount": "Номер",
    "checkout.cart.edit": "Изменить",
    "checkout.cart.price": "Цена",
    "checkout.cart.singlePrice": "Цена за единицу",
    "checkout.cart.product.isNonPhysical": "Товар не поставляется (может быть доступен для скачивания)",
    "checkout.cart.product.requiresExpressDelivery": "Свежий продукт с ЭКСПРЕСС-доставкой. Только в пределах D.",
    "checkout.cart.product.deliveryProblem": "Выбранный продукт может поставляться с задержкой.",
    "checkout.cart.subtotal": "Промежуточный итог",
    "checkout.cart.deliveryCosts": "Стоимость доставки",
    "checkout.cart.total": "Всего",
    "checkout.cart.help": "У вас есть вопросы о процессе заказа? Мы собрали для вас важную информацию.",
    "checkout.cart.subtotalInfo": "вкл. НДС",
    "checkout.cart.subtotalShipment": "плюс отклоняющиеся расходы на доставку",
    "checkout.cart.checkout": "Касса",
    "checkout.cart.checkoutWithoutUser": "Касса без учетной записи клиента",
    "checkout.cart.checkoutFast": "Быстрая выписка",
    "checkout.cart.deliveryInfo": "Предполагаемая дата доставки товаров в корзине",
    "checkout.cart.orderSuccess": "Ваш заказ был успешно выполнен.",
    "checkout.cart.orderCommitting": "Ваш заказ обрабатывается - пожалуйста, подождите",
    "checkout.cart.myOrder": "Мой заказ",
    "checkout.cart.leftover": "слева",
    "checkout.checkout.orderNumber": "Номер заказа",
    "checkout.checkout.account": "Счет клиента",
    "checkout.checkout.andOrder": "и приказ",
    "checkout.checkout.delivery": "Доставка и адрес доставки",
    "checkout.checkout.delivery.type": "Отправка осуществляется с помощью",
    "checkout.checkout.delivery.street": "Адресная строка 1",
    "checkout.checkout.delivery.houseNumber": "Нет.",
    "checkout.checkout.delivery.co": "Суффикс адреса (c/o)",
    "checkout.checkout.delivery.city": "Город",
    "checkout.checkout.delivery.selectCountry": "Пожалуйста, выберите страну",
    "checkout.checkout.delivery.addressBoth": "Адрес выставления счета и доставки",
    "checkout.checkout.delivery.addressInvoice": "Адрес счета-фактуры",
    "checkout.checkout.delivery.addressDelivery": "Адрес доставки",
    "checkout.checkout.delivery.default": "Установить этот адрес в качестве моего адреса доставки по умолчанию",
    "checkout.checkout.delivery.otherAddress": "Адрес доставки отличается от адреса выставления счета",
    "checkout.checkout.delivery.dhlNr": "Ваш почтовый номер DHL",
    "checkout.checkout.delivery.packingStation": "Номер упаковочной станции",
    "checkout.checkout.delivery.showPackingStations": "Показать пакгаузы поблизости от моего адреса",
    "checkout.checkout.delivery.wantExpressShipping": "Экспресс-доставка",
    "checkout.checkout.delivery.isPickUp": "Забрать на месте",
    "checkout.checkout.delivery.specialCountryInfo": "Ваша страна не может быть выбрана?",
    "checkout.checkout.delivery.specialCountry": "Ваша страна находится за пределами нашей стандартной доставки",
    "checkout.checkout.delivery.specialCountryMessage": "Отдельные товары в вашей корзине не могут быть отправлены во все страны. Пожалуйста, удалите некоторые товары из вашего заказа, пока не будет выбрана ваша страна, или свяжитесь с нами.",
    "checkout.checkout.payment": "Способ оплаты",
    "checkout.checkout.paymentProblem": "Произошла ошибка при выполнении платежа",
    "checkout.checkout.backToPayment": "Вернитесь к настройкам платежей.",
    "checkout.checkout.paymentTitle": "Как вы хотите платить?",
    "checkout.checkout.paymentSelected": "Выбранный способ оплаты",
    "checkout.checkout.payment.cc": "Кредитная карта",
    "checkout.checkout.payment.ccInfo": "При оплате картой на территории ЕС ваша личность должна подтверждаться каждый раз, когда вы платите онлайн. После размещения заказа вы пройдете через процесс подтверждения, предоставленный вашим банком.",
    "checkout.checkout.payment.complimentary": "бесплатно",
    "checkout.checkout.payment.paypal": "PayPal",
    "checkout.checkout.payment.paypalInfo": "Вы будете перенаправлены в PayPal для завершения процесса оплаты. Средства с вашего счета PayPal не будут списаны, пока вы не подтвердите заказ на следующем этапе.",
    "checkout.checkout.payment.sepa": "Прямой дебет (SEPA)",
    "checkout.checkout.payment.sepaLegal": "Я разрешаю {{siteOwner}} {{siteOwnerAddress}} (Идентификатор кредитора: {{siteOwnerCreditorID}}) для сбора платежей с моего счета путем прямого дебета. В то же время я даю указание своему банку оплатить прямые дебетовые платежи с {{siteOwner}} на мой счет.",
    "checkout.checkout.payment.noSepa": "Ваш адрес выставления счета или доставки находится за пределами ЕС. Поэтому мы не можем предложить вам оплату по прямому дебету.",
    "checkout.checkout.payment.sepaInfo": "Быстро и без лишних хлопот. При завершении заказа (следующий шаг) вам будет предложено ввести свои банковские реквизиты (IBAN). Пожалуйста, подготовьте их. Когда перевод будет завершен, вы получите подтверждение.",
    "checkout.checkout.payment.prepayment": "Предоплата (банковский перевод)",
    "checkout.checkout.payment.prepaymentInfo": "После того как вы оформите заказ, мы вышлем вам информацию о банковском переводе по электронной почте. Мы можем зарезервировать товары, указанные в вашем заказе, не более чем на 7 дней. Пожалуйста, сохраните это время для банковского перевода. Как только мы получим оплату, заказ будет отправлен.",
    "checkout.checkout.payment.invoice": "Счет-фактура",
    "checkout.checkout.payment.invoiceInfo": "Сумма счета подлежит оплате в день платежа, указанный в подтверждении об отправке по электронной почте.",
    "checkout.checkout.payment.noInvoice": "К сожалению, мы не можем предложить вам оплату по счету-фактуре для данного заказа. Пожалуйста, выберите один из других способов оплаты.",
    "checkout.checkout.payment.sofort": "Мгновенный банковский перевод",
    "checkout.checkout.payment.sofortInfo": "При завершении заказа (следующий шаг) вам будет предложено ввести свои банковские реквизиты (IBAN). Пожалуйста, подготовьте их. Когда перевод будет завершен, вы получите подтверждение.",
    "checkout.checkout.payment.accountName": "Владелец счета",
    "checkout.checkout.payment.accountEmail": "Адрес электронной почты для подтверждения",
    "checkout.checkout.sepaAndOrder": "Мандат на прямое дебетование для {{totalAmount}} € подтвердить и заказать с указанием расходов",
    "checkout.checkout.sofortAndOrder": "Мандат на передачу {{totalAmount}} € подтвердить и заказать с указанием расходов",
    "checkout.checkout.title": "Касса",
    "checkout.checkout.next": "больше",
    "checkout.checkout.order": "Купить сейчас",
    "checkout.checkout.orderConsentPre": "Размещая заказ, вы соглашаетесь с нашими",
    "checkout.checkout.orderConsentPost": "согласны.",
    "checkout.checkout.noAccount": "Не создавайте учетную запись клиента",
    "checkout.redirect.countryPriceChange": "Цены для вашей страны отличаются от стандартной цены. Пожалуйста, подтвердите изменение цены.",
    "comments.blockComments": "Комментирование этого сообщения закрыто.",
    "comments.comment.anonymous": "Аноним",
    "comments.comment.button": "Написать новый комментарий",
    "comments.comment.buttonReply": "Ответы",
    "comments.comment.cancel": "Отмена",
    "comments.comment.content": "Ваш комментарий",
    "comments.comment.hasCommented": "комментарии",
    "comments.comment.login": "Войдите, чтобы оставить комментарий",
    "comments.comment.past": "есть",
    "comments.comment.replyFrom": "Ответ на",
    "comments.comment.showReplies": "Показать ответы",
    "comments.comment.showReply": "Показать ответ",
    "comments.comment.submit": "Отправить",
    "comments.comment.title": "Название комментария",
    "comments.comment.userName": "Ваше имя пользователя",
    "comments.heading": "Предложения, заметки, комментарии",
    "comments.hierarchy.flat": "Плоский",
    "comments.hierarchy.nested": "Genested",
    "comments.hierarchy.title": "Просмотр комментариев:",
    "comments.message.afterSaveError": "Ваш комментарий не может быть сохранен в данный момент!",
    "comments.message.afterSaveSuccess": "Ваш комментарий сохранен.",
    "comments.message.afterSaveUnlock": "Ваш комментарий сохранен, но все еще должен быть проверен администратором.",
    "comments.noComments": "Комментариев пока нет",
    "comments.reply": "AN:",
    "comments.show": "Показать комментарии",
    "comments.title": "Комментарии",
    "entries.edit": "Вход <strong>{{title}}</strong> редактировать",
    "entries.entries": "Записи",
    "entries.product.ui.chooseVariant": "Выберите вариант",
    "entries.product.ui.chooseVariantMobile": "Выберите вариант",
    "entries.product.ui.combinationNotAvailable": "Комбинация невозможна",
    "entries.entry.fields.address.city": "Город",
    "entries.entry.fields.address.country": "Страна",
    "entries.entry.fields.address.houseNumber": "Номер дома",
    "entries.entry.fields.address.street": "Улица",
    "entries.entry.fields.address.zip": "ПОСТКОД",
    "entries.entry.fields.language": "Язык",
    "entries.entry.fields.prefixMissing": "Следующие поля не заполнены:",
    "entries.entry.profile.edit": "Редактировать профиль",
    "entries.entry.profile.publicProfile": "на страницу моего публичного профиля",
    "entries.entry.tooltip.alreadyCopied": "Вы уже мечтаете об этой записи.",
    "entries.entry.tooltip.alreadyFollow": "Вы уже следите за этой записью",
    "entries.entry.tooltip.alreadyLiked": "Вам уже нравится эта запись",
    "entries.entry.tooltip.copied": "Приятно, что вы тоже хотите помечтать об этой записи. Ваша фотография профиля была добавлена к этой записи.",
    "entries.entry.tooltip.filterComments": "Нажмите здесь, чтобы показать только комментарии в строке ввода",
    "entries.entry.tooltip.filterFollows": "Нажмите здесь, чтобы показать только фолловеров в линии входа",
    "entries.entry.tooltip.filterLikes": "Нажмите здесь, чтобы показать только лайки в строке ввода",
    "entries.entry.tooltip.filterShares": "Нажмите здесь, чтобы показать только акции в строке входа",
    "entries.entry.tooltip.follow": "Нажмите здесь, чтобы следить за этой записью",
    "entries.entry.tooltip.like": "Нажмите здесь, чтобы поставить лайк этой записи",
    "entries.entry.tooltip.noFollow": "Follow работает только для чужих записей",
    "entries.entry.tooltip.noFollowNotLoggedIn": "Вы должны войти в систему, чтобы следить за этой записью",
    "entries.entry.tooltip.noLike": "Like работает только для чужих записей",
    "entries.entry.ui.access": "Эту запись могут видеть только следующие лица",
    "entries.entry.ui.added": "...был добавлен.",
    "entries.entry.ui.addEntry": "Добавить записи",
    "entries.entry.ui.addOpening": "Добавить время работы",
    "entries.entry.ui.address": "Адресные данные",
    "entries.entry.ui.addStep": "Добавить шаг",
    "entries.entry.ui.agreementHours": "Открыто по договоренности",
    "entries.entry.ui.allAccess": "Все могут видеть эту запись",
    "entries.entry.ui.assignedCategories": "присвоенные категории",
    "entries.entry.ui.assignedCertificates": "Мои сертификаты",
    "entries.entry.ui.assignedProducts": "Назначенные продукты",
    "entries.entry.ui.businessContact": "Контактные данные компании",
    "entries.entry.ui.categories": "Категории для этой записи",
    "entries.entry.ui.categoryFields": "Дополнительная информация",
    "entries.entry.ui.commercialTitle": "Информация о компании на входе",
    "entries.entry.ui.companyTitle": "Реквизиты моей компании",
    "entries.entry.ui.contactPerson": "Мои контактные данные",
    "entries.entry.ui.cssClass": "CSS-класс для содержимого",
    "entries.entry.ui.deliveryInfo": "Информация о службе доставки",
    "entries.entry.ui.descriptionInfo": "Совет: Когда слова отмечены, можно назначить стили.",
    "entries.entry.ui.descriptionPlaceholder": "Описание с помощью HTML. Дважды щелкните по тексту для функциональной панели HTML (максимум 600 символов).",
    "entries.entry.ui.editEntry": "Редактировать запись",
    "entries.entry.ui.editEntryNew": "Создать запись",
    "entries.entry.ui.exclusionHours": "Время исключения",
    "entries.entry.ui.galleryImages": "Картинная галерея (2 или 3 фотографии в зависимости от расположения)",
    "entries.entry.ui.globalTitle": "Имя",
    "entries.entry.ui.hasKiosk": "У меня есть ларек",
    "entries.entry.ui.imagesHorizontal": "Горизонтальный",
    "entries.entry.ui.imagesInfo": "Изображения можно редактировать в разделе \"Изображения/Видео/Документы\".",
    "entries.entry.ui.imagesOrientation": "Выравнивание изображения",
    "entries.entry.ui.imagesSquared": "Квадрат",
    "entries.entry.ui.imagesVertical": "Вертикальный",
    "entries.entry.ui.isListImage": "Это изображение является изображением списка",
    "entries.entry.ui.isMainImage": "Это изображение является основным",
    "entries.entry.ui.isMainImageAndListImage": "Это изображение появляется в качестве основного изображения записи",
    "entries.entry.ui.jobApply": "Применить сейчас",
    "entries.entry.ui.kioskDescription": "Описание вашего торгового стенда",
    "entries.entry.ui.listImage": "Картинка из списка",
    "entries.entry.ui.logo": "Логотип",
    "entries.entry.ui.longDescription": "Длинный текст описания",
    "entries.entry.ui.mainImage": "Основная фотография",
    "entries.entry.ui.makeListImage": "Установите это изображение в качестве изображения списка",
    "entries.entry.ui.makeListImageTooltip": "Результаты поиска отображаются в виде списка с изображением списка. Если изображение списка не выбрано, используется основное изображение.",
    "entries.entry.ui.makeMainImage": "Установите это изображение в качестве основного",
    "entries.entry.ui.makeMainImageTooltip": "Основная фотография отображается в верхней части профиля. Изображения, не выбранные в качестве основного или изображения списка, отображаются в галерее изображений.",
    "entries.entry.ui.metaDescription": "Краткое описание",
    "entries.entry.ui.metaDescriptionPlaceholder": "Краткое описание максимум 200 символов",
    "entries.entry.ui.multiCode": "Дополнительный код для этого шага",
    "entries.entry.ui.multiDescription": "Описание этого шага",
    "entries.entry.ui.multiImages": "Изображения для этого шага",
    "entries.entry.ui.multiProgress": "Прогресс",
    "entries.entry.ui.multiTitle": "Название для этого шага",
    "entries.entry.ui.multiTitlePlaceholder": "Название для этого шага (не более 50 символов)",
    "entries.entry.ui.noDescriptions": "У этой записи пока нет описательного текста.",
    "entries.entry.ui.noEntries": "Записи не найдены.",
    "entries.entry.ui.onlyVisibleForCustomers": "Эта запись видна только зарегистрированным клиентам",
    "entries.entry.ui.openAt": "Открыть",
    "entries.entry.ui.openingHours": "Часы работы",
    "entries.entry.ui.openingInfo": "Примечания о часах работы",
    "entries.entry.ui.openingReligious": "Адрес в преимущественно католическом приходе",
    "entries.entry.ui.overallProgress": "Эта запись {{value}}% реализации",
    "entries.entry.ui.parking": "Услуги по парковке автомобилей",
    "entries.entry.ui.parkingLots": "Номер",
    "entries.entry.ui.parkingType": "Искусство",
    "entries.entry.ui.personImage": "Фотография контактного лица",
    "entries.entry.ui.relatedEvents": "Сопутствующие мероприятия",
    "entries.entry.ui.relatedJobs": "Подключенные рабочие места",
    "entries.entry.ui.relatedArticles": "Ассоциированные статьи",
    "entries.entry.ui.relatedCampaigns": "Ассоциированные кампании",
    "entries.entry.ui.rentKiosk": "Я хотел бы арендовать торговый стенд",
    "entries.entry.ui.resolveAddress": "Определите координаты",
    "entries.entry.ui.resolveAddressInfo": "Пожалуйста, определите геоточку (положение на карте) по вашему адресу. Без правильной геоточки они могут быть найдены неправильно.",
    "entries.entry.ui.resolveGeolocation": "Получить адрес",
    "entries.entry.ui.routingPlaceholder": "Введите свои указания с помощью HTML здесь.",
    "entries.entry.ui.selectEvent": "Выберите событие",
    "entries.entry.ui.selectJob": "Выберите работу",
    "entries.entry.ui.selectArticle": "Выберите статью",
    "entries.entry.ui.selectCampaign": "Выберите кампанию",
    "entries.entry.ui.sharing": "Эта страница на {{sharing}} акция",
    "entries.entry.ui.shareMail": "Поделиться этой страницей по почте",
    "entries.entry.ui.shortDescriptionInfo": "В то же время краткое описание",
    "entries.entry.ui.skillsNeeded": "Я мог бы использовать его для своей записи.....",
    "entries.entry.ui.sortword": "Альтернативные названия для сортировки",
    "entries.entry.ui.step": "Шаг",
    "entries.entry.ui.stepInDream": "В какой фазе вступления находится этот шаг?",
    "entries.entry.ui.stepInDreamAfter": "после реализации",
    "entries.entry.ui.stepInDreamBefore": "до реализации",
    "entries.entry.ui.stepInDreamDuring": "во время реализации",
    "entries.entry.ui.subtitle": "Описательный субтитр",
    "entries.entry.ui.subtitlePlaceholder": "Введите подзаголовок (максимум 60 символов)",
    "entries.entry.ui.tabCategories": "Категории",
    "entries.entry.ui.tabCategoriesMobile": "Кошка.",
    "entries.entry.ui.tabCompany": "Данные о компании",
    "entries.entry.ui.tabDescription": "Описание",
    "entries.entry.ui.tabDescriptionMobile": "Beschr.",
    "entries.entry.ui.tabExhibitor": "Информация об участниках",
    "entries.entry.ui.tabImages": "Изображения/видео/документы",
    "entries.entry.ui.tabImagesMobile": "Изображения/видео/файлы",
    "entries.entry.ui.tabRelations": "Соединения",
    "entries.entry.ui.tags": "Теги",
    "entries.entry.ui.title": "Запись появляется под следующим именем",
    "entries.entry.ui.titleError": "Запись должна быть названа перед сохранением",
    "entries.entry.ui.titlePlaceholder": "Введите название (не более 50 символов)",
    "entries.entry.ui.visible": "Эта запись видна",
    "entries.entry.ui.promotion": "Участие в акциях для этой записи",
    "entries.entry.ui.regions": "Категории регионов для этой записи",
    "entries.job.ui.promotion": "Участие в акциях для этой работы",
    "entries.event.edit": "Событие <strong>{{title}}</strong> редактировать",
    "entries.event.ui.addEvent": "Добавить событие",
    "entries.event.ui.cooperation": "Партнер по сотрудничеству",
    "entries.event.ui.directions": "Направления",
    "entries.event.ui.editEvent": "Редактировать событие",
    "entries.event.ui.editEventNew": "Создать событие",
    "entries.event.ui.endDate": "Окончание мероприятия (день)",
    "entries.event.ui.eventDone": "Отчет о выставке",
    "entries.event.ui.noEvents": "События не найдены",
    "entries.event.ui.openEvent": "Событие вызова",
    "entries.event.ui.opening": "Часы работы",
    "entries.event.ui.startDate": "Начало мероприятия (день)",
    "entries.event.ui.tabCategories": "Категории",
    "entries.event.ui.tabDescription": "Описание",
    "entries.event.ui.tabOpening": "Дата и время",
    "entries.event.ui.tabRelations": "Соединения",
    "entries.event.ui.title": "Событие появляется под следующими названиями",
    "entries.event.ui.upcoming": "В двух словах",
    "entries.event.ui.current": "В настоящее время проводится",
    "entries.event.ui.history": "Уже состоялось",
    "entries.open": "Вызов записи во фронтенде",
    "entries.person.ui.company": "Компания",
    "entries.person.ui.female": "Госпожа",
    "entries.person.ui.diverse": "Без",
    "entries.person.ui.fullName": "Имя и фамилия",
    "entries.person.ui.fullSize": "Полный размер",
    "entries.person.ui.gender": "Пол",
    "entries.person.ui.greeting": "Приветствие",
    "entries.person.ui.male": "Мистер",
    "entries.person.ui.open": "Непосредственно к записи этого человека",
    "entries.person.ui.personAddress": "Адрес лица",
    "entries.person.ui.personContact": "Личные контактные данные",
    "entries.person.ui.personImage": "Личное фото",
    "entries.person.ui.position": "Позиция",
    "entries.person.ui.quote": "Цитата/заголовок",
    "entries.person.ui.signature": "Изображение подписи",
    "entries.poi.ui.poiEvents": "События в этом месте",
    "entries.poi.ui.title": "Это место фигурирует под следующими названиями",
    "entries.product.edit": "Продукт <strong>{{title}}</strong> редактировать",
    "entries.product.fields.engine": "Двигатель",
    "entries.product.fields.horsePower": "Мощность",
    "entries.product.menu.allAttachments": "Все вложения с первого взгляда",
    "entries.product.menu.allCarriers": "Все транспортные средства в обзоре",
    "entries.product.menu.attachments": "Вложения",
    "entries.product.menu.carriers": "Автомобили-носители",
    "entries.product.tags.eco": "эко",
    "entries.product.tags.neu": "новый",
    "entries.product.ui.addProduct": "Добавить продукт",
    "entries.product.ui.choose": "выберите",
    "entries.product.ui.discount": "Скидка",
    "entries.product.ui.discountSelect": "Используйте преимущество % скидки",
    "entries.product.ui.equipmentVariants": "Варианты оборудования",
    "entries.product.ui.variants": "Варианты",
    "entries.product.ui.gauge": "Ширина в см",
    "entries.product.ui.gaugeMin": "Минимальная ширина колеи в см",
    "entries.product.ui.inclusive": "В том числе",
    "entries.product.ui.live": "{{name}} живой опыт",
    "entries.product.ui.netPrize": "включая {{tax}}% НДС, плюс",
    "entries.product.ui.netPrizeDefault": "включая установленный законом налог на добавленную стоимость, плюс",
    "entries.product.ui.deliveryTerms": "Стоимость доставки",
    "entries.product.ui.noProducts": "Продукция не найдена",
    "entries.product.ui.noPurchaseOnThisPage": "Не может быть заказан на этой странице, для этого вызовите продукт.",
    "entries.product.ui.onlyBasicVariant": "Только в базовой комплектации",
    "entries.product.ui.onlyWithCarrier": "Доступно только при покупке базового блока",
    "entries.product.ui.order": "Добавить в корзину",
    "entries.product.ui.notOrderable": "Пожалуйста, выберите вариант.",
    "entries.entry.ui.products.delivery.red": "Продукт в настоящее время недоступен",
    "entries.entry.ui.products.delivery.yellow": "Продукт в наличии. Возможно более длительное время доставки.",
    "entries.entry.ui.products.delivery.green": "Наличие товара на складе в достаточном количестве",
    "entries.products.ui.combinationNotAvailable": "Продукт недоступен в данной комбинации",
    "product.ui.content": "Содержание",
    "product.ui.ingredients": "Содержание",
    "product.ui.ingredientsFood": "Ингредиенты",
    "entries.product.ui.overview": "Обзор",
    "entries.product.ui.prizeDecimalPoint": ",",
    "entries.product.ui.prizeFrom": "с сайта",
    "entries.product.ui.item": "Кусок",
    "entries.product.ui.prizePerItem": "Цена за штуку",
    "entries.product.ui.noPrize": "Пожалуйста, уточните цену у вашего контактного лица.",
    "entries.product.ui.size": "Размер",
    "entries.product.ui.required": "Только с",
    "entries.product.ui.suitableFor": "Подходит для",
    "entries.product.ui.suitableForAttachments": "Подходит для следующих навесных устройств",
    "entries.product.ui.suitableForCarriers": "Подходит для следующих транспортных средств",
    "entries.product.ui.title": "Продукт",
    "entries.product.ui.variant": "Вариант/пакет",
    "entries.product.ui.variantNotOrderable": "Выбранный вариант не может быть заказан",
    "entries.product.ui.inVariants": "для вариантов продукции",
    "entries.product.ui.bundleparts": "Состоит из",
    "entries.progress.title": "Как улучшить свою запись",
    "entries.tabbar.basics": "Основные данные",
    "entries.tabbar.entry": "Вводные данные",
    "entries.tabbar.gallery": "Изображения",
    "entries.tabbar.layout": "Макет",
    "entries.tabbar.openingHours": "Часы работы",
    "entries.tabbar.order": "Информация для заказа",
    "entries.tabbar.tooltip.entryNotSavedDetail": "Данные записи можно редактировать только после того, как запись сохранена с названием.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "Данные макета можно редактировать только после сохранения записи с названием.",
    "entries.types.poi": "Poi",
    "entries.types.standard": "Стандарт",
    "entries.ui.groupDetailName": "Имя для групповых записей",
    "entries.ui.groupName": "Название для групп (региона)",
    "entryTypes.entry": "Вход",
    "entryTypes.event": "Событие",
    "entryTypes.poi": "Место проведения",
    "errors.blank": "{{description}} должны быть заполнены",
    "errors.description": "Это поле",
    "errors.email": "Пожалуйста, введите действительный {{description}}-адрес",
    "errors.empty": "{{description}} не должен быть пустым",
    "errors.exclusion": "Это {{description}} уже занят",
    "errors.inclusion": "Это {{description}} не включается в выбор",
    "errors.invalid": "{{description}} недействителен",
    "errors.mandatory": "{{field}} должны быть заполнены",
    "errors.mandatoryCheckbox": "Должно быть подтверждено.",
    "errors.mandatorySubmit": "Отправить форму можно только после заполнения всех обязательных полей.",
    "errors.slug": "Slug может содержать только строчные буквы, цифры и \"-\".",
    "forms.ui.deleteFile": "Удалить файл",
    "forms.ui.formErr": "Ошибка при отправке формы",
    "forms.ui.formSent": "Форма отправлена",
    "forms.ui.noForm": "Форма не назначена",
    "forms.ui.invalidMail": "Пожалуйста, введите действительный адрес электронной почты.",
    "forms.ui.isSubmitted": "Форма была отправлена. Мы свяжемся с вами как можно скорее.",
    "forms.ui.missingFields": "Следующие поля должны быть заполнены:",
    "forms.ui.uploadSuccess": "{{file}} был загружен",
    "forms.ui.fieldBuilder.street": "Улица",
    "forms.ui.fieldBuilder.houseNumber": "Нет.",
    "forms.ui.fieldBuilder.zip": "ПОСТКОД",
    "forms.ui.fieldBuilder.city": "Город",
    "forms.ui.fieldBuilder.country": "Страна",
    "forms.ui.fieldBuilder.firstName": "Имя",
    "forms.ui.fieldBuilder.lastName": "Фамилия",
    "forms.ui.privacyRead": "Я принял к сведению политику конфиденциальности",
    "forms.ui.privacy": "Политика конфиденциальности",
    "jobs.ui.employmentType": "Тип работы",
    "jobs.ui.employmentType.FULL_TIME": "Полный рабочий день",
    "jobs.ui.employmentType.PART_TIME": "Неполный рабочий день",
    "jobs.ui.employmentType.APPRENTICE": "Обучение",
    "jobs.ui.employmentType.INTERN": "Стажировка",
    "jobs.ui.employmentType.CONTRACTOR": "Внештатная работа",
    "jobs.ui.employmentType.TRAINEE": "Стажер, неполный рабочий день",
    "jobs.ui.employmentType.VOLUNTEER": "Добровольно",
    "jobs.ui.employmentType.OTHER": "",
    "jobs.ui.location": "Работа доступна в",
    "jobs.ui.role.male": "M",
    "jobs.ui.role.female": "W",
    "jobs.ui.role.divers": "D",
    "jobs.ui.startDateNow": "На данный момент",
    "jobs.ui.startFrom": "С сайта",
    "media.documents.search.noResult": "Пожалуйста, введите серийный номер или выберите категорию.",
    "media.documents.search.placeholder": "Поиск по серийному номеру или номеру типа устройства",
    "media.documents.search.reset": "Сброс результатов поиска",
    "media.ui.copyright": "Авторское право",
    "media.ui.cropFormat": "Спецификация формата",
    "media.ui.cropImage": "Обрезка",
    "media.ui.cropImageDuplicate": "Дублировать и вырезать",
    "media.ui.description": "Описание",
    "media.ui.filename": "Имя файла",
    "media.ui.imageSaved": "Сохраненное изображение",
    "media.ui.processingImage": "Изображение обрезается, пожалуйста, подождите",
    "media.ui.resetCrop": "Сброс резки",
    "media.ui.resetImage": "Вернуться к оригиналу",
    "media.ui.rotateImage": "Поверните изображение на 90° по часовой стрелке",
    "media.ui.title": "Название",
    "name": "Имя",
    "openingHours.additionalInfo": "Информация о часах работы",
    "openingHours.addOpening": "Добавить время работы",
    "openingHours.date.day.di": "Tue",
    "openingHours.date.day.do": "Сделать",
    "openingHours.date.day.fr": "Пн",
    "openingHours.date.day.mi": "Mi",
    "openingHours.date.day.mo": "Mo",
    "openingHours.date.day.sa": "Сб",
    "openingHours.date.day.so": "Так что",
    "openingHours.dateS": "Дата",
    "openingHours.delete": "Удалить",
    "openingHours.from": "с сайта",
    "openingHours.options": "Область",
    "openingHours.time": "Время",
    "openingHours.to": "на",
    "openingHours.type": "Тип времени работы",
    "opt-in.error-can-not-update-member": "Изменения в данных о членах для {{email}} не может быть осуществлена.",
    "opt-in.error-list-does-not-exist": "Для указанных дат список не найден.",
    "opt-in.error-list-does-not-need-validation": "Указанный список не имеет opt-int.",
    "opt-in.error-member-not-in-list": "Ни один член списка не был найден для указанных дат.",
    "opt-in.success-member-verified": "Сайт {{email}} был добавлен в {{listname}} добавлены в список.",
    "opt-out.error-can-not-update-member": "Изменения в данных о членах для {{email}} не может быть осуществлена.",
    "opt-out.error-list-does-not-exist": "Для указанных дат список не найден.",
    "opt-out.error-list-does-not-have-opt-out": "Указанный список не имеет возможности отказа от рассылки.",
    "opt-out.error-member-not-in-list": "Ни один член списка не был найден для указанных дат.",
    "opt-out.success-membership-terminated": "Сайт {{email}} был удален из {{listname}} удалены.",
    "password.errors.not-secure-enough": "Мы рекомендуем пароль длиной не менее 8 символов, состоящий из букв верхнего и нижнего регистра, цифр и специальных символов ($@$!%*#?&amp;).",
    "password.errors.password-and-conf-do-not-match": "Оба пароля должны быть одинаковыми.",
    "password.quality.0": "Очень плохо",
    "password.quality.1": "Плохой",
    "password.quality.2": "Достаточно",
    "password.quality.3": "Хорошо",
    "password.quality.4": "Очень хорошо",
    "profile.entries.copycats": "Записи, которые {{userName}} также хочет сделать",
    "profile.entries.donates": "Записи, которые {{userName}} поддерживает",
    "profile.entries.entries": "Записи от {{userName}}",
    "profile.entries.follows": "Записи, которые {{userName}} следует",
    "profile.entries.joins": "Записи, в которых {{userName}} участвует",
    "profile.marketplace.fields.energyRequired": "Спрос на электроэнергию",
    "profile.marketplace.fields.livingAnimals": "Взятие живых животных",
    "profile.marketplace.fields.loadingUnloading": "Необходимые для прибытия/отъезда",
    "profile.marketplace.fields.maxRange": "Для моего предложения я уже проехал столько км.",
    "profile.marketplace.fields.placeRequired": "Требования к полям",
    "profile.marketplace.fields.securityRequired": "Потребность в безопасности",
    "profile.marketplace.fields.shows": "Предложение по программе",
    "profile.marketplace.fields.spaceRequired": "Потребность в площади в м²",
    "profile.marketplace.fields.waterRequired": "Потребность в воде в м³",
    "profile.marketplace.new": "Новое предложение или потребность",
    "profile.marketplace.offer.delete": "Удалить",
    "profile.marketplace.offer.description": "Описание",
    "profile.marketplace.offer.descriptionPlaceholder": "Осмысленное описание, чтобы другой пользователь мог что-то с ним сделать.",
    "profile.marketplace.offer.new": "Создать новое предложение",
    "profile.marketplace.offer.relatedEntry": "Назначенный вход",
    "profile.marketplace.offer.tags": "Теги для предложения/запроса (будут найдены лучше)",
    "profile.marketplace.offer.title": "Я предлагаю",
    "profile.marketplace.request.delete": "Удалить запрос",
    "profile.marketplace.request.new": "Создать новый запрос",
    "profile.marketplace.request.missingCredentials": "Вы можете начать запрос только после создания хотя бы одного местоположения и одной точки измерения.",
    "profile.marketplace.request.title": "Мне нужно",
    "profile.messages.approve": "Подтвердить",
    "profile.messages.as": "в качестве",
    "profile.messages.commentPre": "есть",
    "profile.messages.commentSuff": "комментарии",
    "profile.messages.decided": "решил",
    "profile.messages.deletedUser": "Удаленный пользователь",
    "profile.messages.donateDream": "Большое спасибо за то, что помогли этому участнику реализовать его заявку. Он немедленно получит ваше сообщение и, надеюсь, свяжется с вами и примет вашу помощь.",
    "profile.messages.dreamer": "Абитуриент",
    "profile.messages.dreamToo": "также мечтать",
    "profile.messages.enter": "присоединился к",
    "profile.messages.favoriteDream": "Вы следите за этой записью сейчас.",
    "profile.messages.follow": "следует",
    "profile.messages.has": "есть",
    "profile.messages.is": "это",
    "profile.messages.join": "JOIN",
    "profile.messages.joinDream": "Вы хотите мечтать вместе с этой записью.",
    "profile.messages.likeDream": "Вам нравится эта запись.",
    "profile.messages.sendText": "Ваше сообщение отправлено.",
    "profile.messages.shareDream": "Вы успешно поделились этой записью.",
    "profile.messages.supporter": "Сторонники",
    "profile.messages.want": "хотел бы",
    "profile.messages.youAre": "Вы",
    "profile.messages.youWant": "Вы бы хотели",
    "profile.newsletter.notSubscribed": "подписаться",
    "profile.newsletter.notSubscribedAny": "Вы еще не подписались на рассылку новостей.",
    "profile.newsletter.privacyInformation": "Нажимая на кнопку \"подписаться\", вы соглашаетесь с тем, чтобы мы отправляли вам будущие информационные бюллетени по этой подписке (opt-in). Вы можете в любое время отказаться от подписки на рассылку, повторно нажав на кнопку \"подписаться\" (opt-out).",
    "profile.newsletter.subscribed": "подписался",
    "profile.press.articleDetail": "Деталь статьи",
    "profile.press.downloadImage": "Загрузить изображение",
    "profile.press.downloadImages": "Загрузить изображения",
    "profile.press.downloadText": "Экспорт текста",
    "profile.press.filterAuthor": "Фильтр по автору",
    "profile.press.hide": "скрыть",
    "profile.press.show": "затухать",
    "profile.progress.title": "Как улучшить свой профиль",
    "register.ui.addressData": "Адресные данные",
    "register.ui.addressInfo": "Им не нужно вводить свой личный адрес. Однако это приводит к правильным расчетам маршрута и расстояния.",
    "register.ui.companyData": "Данные о вашей компании",
    "register.ui.companyDataMandatory": "Вы должны ввести название своей компании",
    "register.ui.emptyFirstName": "Вы должны ввести свое имя и фамилию",
    "register.ui.emptyLastName": "Вы должны ввести свою фамилию",
    "register.ui.emptyMail": "Вы должны указать действительный адрес электронной почты",
    "register.ui.emptyUserName": "Вы должны указать имя пользователя",
    "register.ui.language": "Язык",
    "register.ui.loginData": "Данные для входа в систему",
    "register.ui.password": "Пароль",
    "register.ui.passwordRepeat": "Повторите пароль",
    "register.ui.personalData": "Ваши личные данные",
    "register.ui.privacyStatement": "Я согласен на хранение моих персональных данных. Мои личные данные не будут переданы третьим лицам. Я могу в любое время отозвать данное согласие на защиту данных с действием на будущее.",
    "register.ui.takenEmail": "Этот email уже был указан пользователем.",
    "register.ui.takenUserName": "Это имя пользователя уже занято",
    "register.ui.timezone": "Часовой пояс",
    "register.ui.typeMinimum": "Вы должны выбрать хотя бы один тип",
    "register.ui.usernameInfo": "Важно: Это ваше имя пользователя для входа в систему",
    "register.ui.oauthInfo": "Вы будете перенаправлены на страницу входа в FRM, чтобы войти в систему, указав свои данные пользователя, а затем вернуться на сайт.",
    "search.allDistances": "Все расстояния",
    "search.andFilter": "и настройки фильтра",
    "search.closeTags": "Скрыть теги",
    "search.for": "на",
    "search.resetPosition": "Сброс позиции",
    "search.geoPositionBrowser": "Определение положения через браузер",
    "search.geoPositionFault": "Определение позиции браузером невозможно",
    "search.geoPositionTitle": "Установите позицию для поиска по близости",
    "search.selectedFilters": "Выбранные фильтры",
    "search.selectFilters": "Фильтр поиска по категориям",
    "search.hits": "Хит",
    "search.openTags": "Показать теги",
    "search.removeDatefilter": "Удалить фильтр даты",
    "search.resulted": "раскрыт",
    "search.resultType.category": "Категория",
    "search.resultType.entry": "Вход",
    "search.resultType.event": "Событие",
    "search.resultType.group": "Группа",
    "search.resultType.page": "Страница",
    "search.resultType.person": "Человек",
    "search.resultType.post": "Пост",
    "search.resultType.product": "Продукт",
    "search.resultType.article": "Статья",
    "search.resultType.job": "Работа",
    "search.resultType.recipe": "Рецепт",
    "search.search": "Поиск",
    "search.result": "Результат",
    "search.results": "Результаты",
    "search.searchBarPlaceholder": "Поиск...",
    "search.searchBarPlaceholderEntries": "Поиск по названию дилера или адресу дилера.",
    "search.searchBarPlaceholderEntriesPosition": "Поиск по периметру с указанием собственного местоположения (например, города)...",
    "search.searchBarPlaceholderEntriesPositionZip": "...или почтовый индекс",
    "search.searchBarPlaceholderGeoSearch": "Введите свой адрес...",
    "search.searchBarPlaceholderMobile": "Поиск...",
    "search.searchBarPlaceholderProducts": "Поиск продуктов",
    "search.searchBarPositionTooltip": "Определите местоположение или введите местоположение с адресом",
    "search.showAll": "Показать все",
    "search.globalSearch": "Страница поиска",
    "social.comment": "Комментарий",
    "social.Comment": "Комментарий",
    "social.CopycatJoiner": "Мечтайте о своем вступлении",
    "social.dream.donateAmount": "Поддержка денежной суммой",
    "social.dream.donateDreampoints": "Поддержка в работе с Dreampoints",
    "social.dream.donateInfo": "Сообщение является личным и может быть прочитано только владельцем записи.",
    "social.dream.donateTip": "Поддержка советами и действиями",
    "social.dream.done": "ГОТОВО",
    "social.dream.edit": "Редактировать запись",
    "social.dream.findDreams": "Найдите здесь маленькие и большие записи и сделайте свою запись реальной и/или помогите другим реализовать их записи.",
    "social.dream.followedDreams": "Записи, за которыми вы следите",
    "social.dream.giveEmail": "Поделиться адресом электронной почты",
    "social.dream.givePhone": "Поделитесь моим номером телефона",
    "social.dream.joinerApproved": "Вы присоединяетесь к этой записи",
    "social.dream.joinerButton": "Присоединиться к этой записи",
    "social.dream.joinerButtonMobile": "Участвуйте",
    "social.dream.joinerDream": "Групповая мечта",
    "social.dream.joinerInfo": "Если владелец записи подтверждает ваше участие, эта запись становится групповой комнатой, где вы имеете дальнейшие права.",
    "social.dream.joinerMessage": "Введите здесь свое сообщение для владельца записи:",
    "social.dream.joinerPending": "Владелец заявки все равно должен подтвердить ваше участие.",
    "social.dream.joinerSupport": "Вы поддерживаете эту запись",
    "social.dream.joinerSupportButton": "Поддержать эту запись",
    "social.dream.joinerSupportButtonMobile": "Поддержка",
    "social.dream.joinerSupportInfo": "Мы постоянно работаем над улучшениями, например, над поддержкой деньгами или точками входа. Оставайтесь с нами.",
    "social.dream.joinerSupportPending": "Владелец записи должен по-прежнему разрешать вашу поддержку",
    "social.dream.member": "Член",
    "social.dream.members": "Члены",
    "social.dream.meToo": "Я тоже хочу это сделать",
    "social.dream.meTooInfo": "Покажите владельцу записи, что вы считаете запись замечательной и что вы хотите сделать эту запись.",
    "social.dream.noFollow": "Вы не выполняете ни одной записи!",
    "social.dream.noJoiner": "Вы еще не являетесь участником группового сна",
    "social.dream.noLikes": "Вам пока не нравится ни одна запись!",
    "social.dream.noSupport": "Вы не поддерживаете запись",
    "social.dream.registerFirstText": "Чтобы иметь возможность поделиться записью, поддержать запись или указать, что вы также хотели бы сделать эту запись, вы должны войти в систему. Регистрация для участия в конкурсе является быстрой и бесплатной. Просто нажмите на кнопку \"Регистрация\".",
    "social.dream.registerFirstTitle": "Мечтаете, поддерживаете, даже делаете?",
    "social.dream.settings": "Настройки входа",
    "social.dream.shareTitle": "Поделитесь мечтой",
    "social.dream.similar": "Похожие записи",
    "social.dreamline.addImageToDream": "Добавьте это изображение к своей записи",
    "social.dreamline.addMedia": "Добавить фотографию / видео",
    "social.dreamline.allActivities": "Все мероприятия Dreamline",
    "social.dreamline.anonymous": "Аноним",
    "social.dreamline.commentButton": "Комментарий",
    "social.dreamline.comments": "пишет:",
    "social.dreamline.depublished": "Этот комментарий был удален за неподобающее поведение.",
    "social.dreamline.dreampointsInfo": "Это точки входа. Более подробную информацию о Dreampoints можно найти в профиле.",
    "social.dreamline.fewDreampoints": "имеет всего несколько баллов Dreampoints. Прокомментируйте, поставьте лайк или поделитесь записью.",
    "social.dreamline.filterBySteps": "Фильтр по шагам",
    "social.dreamline.filterComment": "Комментарии",
    "social.dreamline.filteredBy": "Мероприятия, отфильтрованные по",
    "social.dreamline.filteredBySteps": "Отфильтровано по шагам",
    "social.dreamline.filterFavorite": "Следит за",
    "social.dreamline.filterLike": "Нравится",
    "social.dreamline.filterShare": "Акции",
    "social.dreamline.filterStep": "Шаги",
    "social.dreamline.flagged": "Об этом сообщении было сообщено",
    "social.dreamline.isComment": "комментарии",
    "social.dreamline.isCopycatJoiner": "также мечтать",
    "social.dreamline.isFirstUpdate": "создан",
    "social.dreamline.isShare": "общий",
    "social.dreamline.isStep": "сделал новый шаг",
    "social.dreamline.isUpdate": "обновленный",
    "social.dreamline.lastEntry": "Последнее обновление записи",
    "social.dreamline.locked": "Это содержание видно только коллегам-мечтателям",
    "social.dreamline.lockedCTA": "Присоединяйтесь к этой записи сейчас",
    "social.dreamline.noMessages": "Эта запись пока не имеет активности. Напишите первый комментарий сейчас.",
    "social.dreamline.noMessagesFiltered": "Нет активности с этими настройками фильтра для этой записи.",
    "social.dreamline.nowFollower": "следует за этой записью!",
    "social.dreamline.nowJoiner": "теперь является сторонником",
    "social.dreamline.nowLikes": "нравится эта запись сейчас!",
    "social.dreamline.nowSharing": "поделился этой записью!",
    "social.dreamline.onlyJoinerVisible": "Виден только товарищам по мечте/сторонникам",
    "social.dreamline.prefixComment": "имеет эту запись раньше",
    "social.dreamline.prefixCopycatJoiner": "хотел бы добавить эту запись, поскольку",
    "social.dreamline.prefixFavorite": "следит за этой записью с тех пор, как",
    "social.dreamline.prefixJoiner": "поддерживает эту запись, поскольку",
    "social.dreamline.prefixLike": "нравится эта запись с",
    "social.dreamline.prefixShare": "имеет эту запись раньше",
    "social.dreamline.prefixStep": "раньше",
    "social.dreamline.prefixUpdate": "имеет эту запись раньше",
    "social.dreamline.profile": "Профиль",
    "social.dreamline.provideImage": "Я предоставляю эту фотографию в распоряжение владельца записи",
    "social.dreamline.resetFilter": "Сброс фильтра",
    "social.dreamline.sendComment": "Отправить",
    "social.dreamline.sharePart": "Поделиться",
    "social.dreamline.toggleStream": "Комментарии",
    "social.dreamline.toggleStreamSingle": "Комментарий",
    "social.dreamline.writeComment": "Написать комментарий...",
    "social.Favorite": "Следуйте за",
    "social.follow": "Следуйте за",
    "social.Follow": "Следуйте за",
    "social.hasLiked": "Вам уже нравится эта запись.",
    "social.join": "Присоединяйтесь к",
    "social.Join": "Присоединяйтесь к",
    "social.Joiner": "Мечтаю",
    "social.like": "Например,",
    "social.Like": "Например,",
    "social.report.headlinePrefix": "Ты там",
    "social.report.headlineSuffix": "сообщить.",
    "social.report.login": "Вы должны войти в систему, чтобы сообщить об этом сообщении.",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "Другое оскорбительное содержание",
    "social.report.reportScam": "Введение в заблуждение, запугивание.",
    "social.report.reportSex": "Сексуальное содержание",
    "social.report.reportSpam": "Это спам",
    "social.report.reportViolence": "Изображение насилия",
    "social.report.send": "Отправить сообщение",
    "social.report.title": "Отчет",
    "social.share": "Поделиться",
    "social.Share": "Поделиться",
    "syd.ui.dreamerCommercial": "Стандарт B2B",
    "syd.ui.userCommercial": "Мечтатель B2B",
    "user.company.addUser": "Создайте дополнительных пользователей для",
    "user.company.inviteUser": "Отправить приглашение по почте",
    "user.company.userIsInvited": "Было отправлено письмо с приглашением",
    "user.company.userIsActive": "Пользователь активен",
    "user.company.userIsNotActive": "Пользователь еще не активирован",
    "user.company.additionalWebsite": "Дальнейший веб-сайт компании (только домен без https://)",
    "user.company.companyName": "Название компании",
    "user.company.contactFor": "Контактная информация",
    "user.company.contactForDefault": "Общий контакт",
    "user.company.copyContact": "Перенять контактные данные из сведений о компании для записи",
    "user.company.email": "Контактная почта",
    "user.company.facebook": "Facebook-аккаунт компании (только имя аккаунта)",
    "user.company.fax": "Номер факса для письменного контакта",
    "user.company.google": "Google+ аккаунт компании (только название аккаунта)",
    "user.company.instagram": "Instagram-аккаунт компании (только название аккаунта)",
    "user.company.linkedin": "Аккаунт компании в LinkedIn (только имя аккаунта)",
    "user.company.newCustomerAccount": "Создайте бизнес-аккаунт",
    "user.company.noCustomerValidation": "Ваш бизнес-аккаунт еще не активирован.",
    "user.company.noCustomerYet": "Вашей регистрации еще не присвоен бизнес-аккаунт.",
    "user.company.notAllowed": "Вы не можете внести какие-либо изменения, потому что ваш статус пользователя ({{type}}) не позволяет этого сделать.",
    "user.company.phone": "Номер контактного телефона",
    "user.company.pinterest": "Pinterest счет компании (только название счета)",
    "user.company.remarks": "Примечания",
    "user.company.registerNumber": "Номер торгового реестра",
    "user.company.registerAuth": "Местный суд",
    "user.company.acerCode": "ACER-код вашего энергоснабжения",
    "user.company.taxNumber": "Налоговый номер",
    "user.company.type.default": "Стандарт",
    "user.company.type.procurator": "Уполномоченное лицо, подписавшее документ",
    "user.company.type.manager": "Управляющий директор",
    "user.company.twitter": "Twitter-аккаунт компании (только имя аккаунта без @)",
    "user.company.ustid": "UStID",
    "user.company.ustid.notValid": "Пожалуйста, укажите действительный идентификационный номер НДС",
    "user.company.website": "Веб-сайт компании (только домен без https://)",
    "user.company.xing": "Xing-аккаунт компании (только название аккаунта)",
    "user.company.youtube": "Аккаунт компании на Youtube (только название аккаунта)",
    "user.message.after-save-error": "Ваши изменения не удалось сохранить.",
    "user.message.after-save-success": "Ваши изменения были сохранены.",
    "user.message.can-not-login": "Мы не сможем войти в систему с этими данными пользователя и пароля! Правильно ли написано имя пользователя? Правильно ли вы ввели пароль? Возможно, что-то пошло не так во время регистрации.",
    "user.message.can-not-logout": "Мы не смогли вас выписать!",
    "user.message.conflict": "Это имя пользователя уже существует, выберите другое!",
    "user.message.fileExistsError": "Загрузка не удалась. Файл уже существует",
    "user.message.fileSizeError": "Загрузка не удалась. Файл слишком большой",
    "user.message.loginFailureBottom": "Может быть, у вас еще нет учетной записи Entryer?",
    "user.message.loginFailureTop": "Правильно ли написано ваше имя пользователя? Правильно ли вы ввели пароль?",
    "user.message.registration-error": "Во время регистрации произошла ошибка!",
    "user.message.registration-success": "Вскоре вы должны получить от нас подтверждение по электронной почте.",
    "user.message.unknownError": "Неизвестная ошибка при загрузке файла",
    "user.message.upload-success": "Загрузка прошла успешно",
    "user.message.verify-error": "Ваш адрес электронной почты не может быть проверен. Пожалуйста, свяжитесь с администратором.",
    "user.message.verify-success": "Ваш адрес электронной почты был подтвержден. Вы можете зарегистрироваться:",
    "user.ui.accountGreeting": "Здравствуйте,",
    "user.ui.accountMenu": "Главная страница и информация",
    "user.ui.accountMenuActivities": "Деятельность",
    "user.ui.accountMenuCompanySettings": "Информация о компании",
    "user.ui.accountMenuEntries": "Мои записи",
    "user.ui.accountMenuEntryPre": "Записи,",
    "user.ui.accountMenuEvents": "Мои события",
    "user.ui.accountMenuFavorites": "Мои фавориты",
    "user.ui.accountMenuFollows": "Записи, за которыми я слежу",
    "user.ui.accountMenuImages": "Изображения/видео/документы",
    "user.ui.accountMenuJoin": "Мои групповые мечты",
    "user.ui.accountMenuLikes": "Записи, которые мне нравятся",
    "user.ui.accountMenuMeasuring": "Мои места измерений и данные",
    "user.ui.accountMenuMessages": "Новости",
    "user.ui.accountMenuNewsletter": "Мои подписки на рассылку новостей",
    "user.ui.accountMenuPoi": "Мои места",
    "user.ui.accountMenuPress": "Мои статьи",
    "user.ui.accountMenuProducts": "Мои продукты",
    "user.ui.accountMenuJobs": "Моя работа предлагает",
    "user.ui.accountMenuProfile": "Профиль пользователя",
    "user.ui.accountMenuRatings": "Отзывы",
    "user.ui.accountMenuRegistrationData": "Данные для входа в систему",
    "user.ui.accountMenuReviews": "Отзывы",
    "user.ui.accountMenuSettings": "Настройки",
    "user.ui.accountMenuSkillsOffer": "Я предлагаю...",
    "user.ui.accountMenuSkillsRequest": "Мне нужно...",
    "user.ui.accountMenuOffer": "Мои предложения",
    "user.ui.accountMenuRequest": "Мои просьбы",
    "user.ui.accountMenuSkillsSearch": "Запросы и предложения",
    "user.ui.accountMenuSubscriptions": "Подписки",
    "user.ui.accountMenuSubscritions": "Моя подписка",
    "user.ui.accountMenuSupport": "Поддерживаемые записи",
    "user.ui.accountMenuSupports": "Записи, которые я поддерживаю",
    "user.ui.accountMenuTender": "Тендеры",
    "user.ui.accountMenuOrders": "Мои заказы",
    "user.ui.accountNoMessages": "Вы еще не получили ни одного сообщения.",
    "user.ui.accountProgressbar": "Ваш профиль {{value}}% выполнения",
    "user.ui.accountProgressbarEntry": "Ваша запись {{value}}% выполнения",
    "user.ui.accountSettings": "Дополнительные параметры",
    "user.ui.accountCookieSettings": "Нажмите здесь, чтобы настроить параметры.",
    "user.ui.accountCookieEssential": "Essential",
    "user.ui.accountCookieAnalysis": "Данные анализа",
    "user.ui.accountCookieSocial": "Социальные сети",
    "user.ui.accountCookieReviews": "Отзывы",
    "user.ui.accountToolbarLogin": "Войти",
    "user.ui.accountToolbarLoginTooltip": "Зарегистрируйтесь бесплатно и приступайте к работе",
    "user.ui.accountToolbarLogout": "Выйти из системы",
    "user.ui.adminPreview": "Предварительный просмотр",
    "user.ui.backToLogin": "Вернуться на страницу регистрации",
    "user.ui.birthday": "День рождения",
    "user.ui.cannotLogin": "Не можете войти в систему? Нажмите здесь, чтобы сбросить пароль.",
    "user.ui.changePassword": "Я хотел бы изменить свой пароль",
    "user.ui.changeUserGroup": "Другая подписка",
    "user.ui.city": "Город",
    "user.ui.companyName": "Название компании",
    "user.ui.companyNameTooltip": "Важно: Ваша запись будет также отображаться под этими названиями компаний",
    "user.ui.country": "Страна",
    "user.ui.createNewAccount": "Создайте новую учетную запись пользователя",
    "user.ui.dataDelete": "другие записи. Вы хотите удалить файл навсегда? Везде, где используется этот файл, он заменяется заполнителем.",
    "user.ui.dataUsage": "Файл сохраняется в",
    "user.ui.dataUsageNone": "Файл еще не используется. Вы хотите удалить файл навсегда?",
    "user.ui.deleteImage": "Удалить изображение",
    "user.ui.description": "Введите здесь общее описание человека или компании",
    "user.ui.documents": "Документы",
    "user.ui.documentSearch": "Поиск документов",
    "user.ui.download": "{{title}} скачать",
    "user.ui.email": "Адрес электронной почты",
    "user.ui.fillOutForm": "Для регистрации все обязательные поля должны быть заполнены полностью",
    "user.ui.firstName": "Имя",
    "user.ui.academicTitle": "Ученое звание",
    "user.ui.gender": "М/Ф",
    "user.ui.genderFemale": "женщина",
    "user.ui.genderFemaleSalutation": "Госпожа",
    "user.ui.genderMale": "мужчина",
    "user.ui.genderMaleSalutation": "Мистер",
    "user.ui.genderDiverse": "разнообразные",
    "user.ui.genderThird": "Не указано",
    "user.ui.housenumber": "Нет.",
    "user.ui.iAmCustomer": "Я хотел бы создать учетную запись B2B",
    "user.ui.internalInfo": "Для вас есть послания. Нажмите здесь.",
    "user.ui.lastLogin": "Последняя регистрация на",
    "user.ui.languageAvailable": "Доступно на вашем языке",
    "user.ui.languageMissing": "Доступно только в",
    "user.ui.lastName": "Фамилия",
    "user.ui.loginButton": "Войти",
    "user.ui.loginTitle": "Вход пользователя в систему",
    "user.ui.memberSince": "Член клуба с",
    "user.ui.mobileNumber": "Номер мобильного телефона",
    "user.ui.myAddedStorage": "Фотографии предоставлены",
    "user.ui.myStorage": "Мой каталог",
    "user.ui.noRegisterMessage": "Для получения учетной записи пользователя, пожалуйста, обращайтесь по указанным контактным адресам. Вы не можете зарегистрировать себя в качестве пользователя.",
    "user.ui.oldPassword": "Предыдущий пароль",
    "user.ui.password": "Пароль",
    "user.ui.phoneType": "Телефон",
    "user.ui.phoneTypeMobile": "Мобильный",
    "user.ui.phoneTypeLandline": "Наземная линия",
    "user.ui.pleaseSelect": "Пожалуйста, выберите",
    "user.ui.pleaseSelectSalutation": "Пожалуйста, выберите приветствие",
    "user.ui.registerButton": "Настройте учетную запись пользователя сейчас",
    "user.ui.registerMessage": "Зарегистрируйтесь сейчас и приступайте к работе немедленно. Это быстро и просто.",
    "user.ui.registerTitle": "Вы недавно с нами?",
    "user.ui.requestPasswordReset": "Запросите новый пароль и удалите старый пароль",
    "user.ui.requestPasswordSuccess": "Вскоре вы должны получить электронное письмо со ссылкой для сброса.",
    "user.ui.requestPasswordError": "Неизвестная ошибка при сбросе пароля.",
    "user.ui.resetPassword": "Сброс пароля",
    "user.ui.setPassword": "Установите пароль",
    "user.ui.resetPasswordPage": "Сброс пароля",
    "user.ui.setPasswordPage": "Установите пароль",
    "user.ui.resetPasswordSuccess": "Ваш пароль был восстановлен, теперь вы можете войти в систему",
    "user.ui.setPasswordSuccess": "Ваш пароль был установлен, теперь вы можете войти в систему",
    "user.ui.selectCustomerType": "Вы ... пожалуйста, выберите",
    "user.ui.stayLoggedin": "Оставайтесь в системе",
    "user.ui.stayLoggedinInfo": "Если это общедоступное устройство, отключите эту опцию и снова выйдите из системы для завершения работы.",
    "user.ui.street": "Улица",
    "user.ui.salutation": "Приветствие",
    "user.ui.title": "Название",
    "user.ui.thankYouForSubscribing": "Спасибо, что подписались на нашу рассылку.",
    "user.ui.errorAtSubscribing": "Ошибка при подписке на рассылку новостей",
    "user.ui.unsubscribe": "выход из системы",
    "user.ui.unsubscribePage": "Вы действительно хотите отписаться от нашей рассылки?",
    "user.ui.uploadDrop": "Чтобы загрузить файл, перетащите его сюда или",
    "user.ui.uploadSelectFile": "выбрать файл",
    "user.ui.uploadToMyStorage": "Загрузите новые изображения в мой каталог",
    "user.ui.uploadUserImage": "Выберите изображение пользователя",
    "user.ui.username": "Адрес электронной почты",
    "user.ui.userName": "Имя пользователя",
    "user.ui.visibilityInfo": "Совет: Вы сами решаете, что будет публично отображаться в вашем профиле! Нажмите на символ глаза, чтобы настроить видимость ваших записей:",
    "user.ui.zip": "Почтовый индекс",
    "user.ui.phone": "Номер телефона",
    "user.user": "Абитуриент",
    "user.users": "Записи",
    "widgets.shoppingCart.wishlist": "Памятка и список пожеланий",
    "widgets.shoppingCart.wishlist.empty": "У вас еще нет продуктов в блокноте и списке желаний",
    "widgets.shoppingCart.cart": "Корзина",
    "user.product.addedToFavorites": "Товар добавлен в избранное",
    "order.status.zero": "Заказ получен",
    "order.status.one": "В процессе выполнения",
    "order.status.two": "Заказ был отправлен",
    "order.status.three": "Accessed",
    "order.status.four": "Возврат",
    "order.status.default": "Неизвестное состояние профессии",
    "country.germany": "Германия",
    "country.austria": "Австрия",
    "country.belgium": "Бельгия",
    "country.denmark": "Дания",
    "country.bulgaria": "Болгария",
    "country.croatia": "Хорватия",
    "country.cyprus": "Кипр",
    "country.czechia": "Чешская Республика",
    "country.estonia": "Эстония",
    "country.finland": "Финляндия",
    "country.france": "Франция",
    "country.greece": "Греция",
    "country.hungary": "Венгрия",
    "country.ireland": "Ирландия",
    "country.italy": "Италия",
    "country.latvia": "Латвия",
    "country.lithuania": "Литва",
    "country.luxembourg": "Люксембург",
    "country.lu": "Люксембург",
    "country.malta": "Мальта",
    "country.netherlands": "Нидерланды",
    "country.poland": "Польша",
    "country.portugal": "Португалия",
    "country.romania": "Румыния",
    "country.slovakia": "Словакия",
    "country.slovenia": "Словения",
    "country.spain": "Испания",
    "country.sweden": "Швеция",
    "country.norway": "Норвегия",
    "country.switzerland": "Швейцария",
    "country.turkey": "Индейка",
    "country.unitedKingdom": "Великобритания",
    "openingHours.tNowClosed": "Закрыто",
    "openingHours.tOpeningHoursNotKnown": "Дальнейшие часы работы неизвестны",
    "openingHours.tNowOpen": "Открыто сейчас",
    "openingHours.tNowStillOpen": "Сейчас все еще открыты",
    "openingHours.tNextOpeningHourUnknown": "следующая дата открытия неизвестна",
    "openingHours.tSoonOpenFrom": "скоро снова откроется из",
    "openingHours.tFrom": "с сайта",
    "openingHours.tUntil": "на",
    "openingHours.tOpenAgainFrom": "открыть снова с",
    "openingHours.tOpenAgainFrom2": "Откройте снова на",
    "comparison.fields.measurements": "Масса",
    "comparison.fields.weight": "Вес",
    "application.tag.neu": "Новый",
    "application.tag.sale": "Продажа",
    "application.tag.eco": "Эко",
    "application.tag.sonderrabatt": "Специальная скидка",
    "job.application.selectLocation": "Выберите свое местоположение",
    "job.application.location": "Доступно в следующих регионах"
  };
});
define('webapp/utils/min-variation-price', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.minVariationPrice = minVariationPrice;
  function minVariationPrice(priceType, subType, context) {
    if (['Net', 'Gross'].includes(priceType)) {
      var prices = [];
      var price = Ember.get(context, 'price' + priceType);

      if (price) {
        prices.push(price);
      }

      if (subType === 'products') {
        var detailProducts = Ember.get(context, 'products') || [];

        detailProducts.forEach(function (_ref) {
          var item = _ref.item,
              type = _ref.type,
              childRelations = _ref.childRelations;

          if (type === 'variation') {
            if (item['price' + priceType]) {
              prices.push(item['price' + priceType]);
            }

            if (childRelations) {
              childRelations.forEach(function (_ref2) {
                var item = _ref2.item,
                    type = _ref2.type;

                if (type === 'variation' && item['price' + priceType]) {
                  prices.push(item['price' + priceType]);
                }
              });
            }
          }
        });

        return Math.min.apply(Math, prices);
      } else if (subType === 'variants') {
        var _detailProducts = Ember.get(context, 'variants') || [];

        _detailProducts.forEach(function (_ref3) {
          var item = _ref3.item;

          if (item.priceGross) {
            prices.push(item.priceGross);
          }
        });

        return prices.length ? Math.min.apply(Math, prices) : 0;
      }
    }
  }
});
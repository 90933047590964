define('webapp/components/ptminder-wrapper', ['exports', 'webapp/utils/inject-script'], function (exports, _injectScript) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var code = this.get('code');
      if (!code) {
        console.errror('No code provided to ptminder-wrapper component');
        return;
      }
      (0, _injectScript.default)('https://ptminder.com/website/extform/?guid=' + code + '=&st[]=sign_up', true);
    }
  });
});
define('webapp/utils/find-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = findBy;
  function findBy(array, key, value) {
    if (array) {
      for (var i = 0; i < array.length; i++) {
        if (Ember.get(array[i], key) === value) {
          return array[i];
        }
      }
    }
  }
});
define('webapp/services/cms-service', ['exports', 'webapp/utils/tree-utils', 'webapp/utils/transform-widgets', 'webapp/utils/misc'], function (exports, _treeUtils, _transformWidgets, _misc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function filterWidgetsByBlogPage(page, widgets) {
    if (page.type === 'blog') {
      return widgets.filter(function (w) {
        return !(w.widget.options && w.widget.options.noDisplayBlog);
      });
    } else if (page.type === 'blogPost') {
      return widgets.filter(function (w) {
        return !(w.widget.options && w.widget.options.noDisplayBlogPost);
      });
    } else if (page.type === 'blogCategory') {
      return widgets.filter(function (w) {
        return !(w.widget.options && w.widget.options.noDisplayBlogCategory);
      });
    } else {
      return widgets;
    }
  }

  exports.default = Ember.Service.extend({
    myStorage: Ember.inject.service(),
    media: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    languageService: Ember.inject.service(),
    fingerprintService: Ember.inject.service(),
    locationService: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    router: Ember.inject.service(),

    widgets: [],
    navbarColumns: {
      atLarge: 0,
      atMedium: 0,
      atSmall: 0
    },

    activePage: null,
    activeDetail: null, // set by abstract-container

    // cached versions
    menus: null,
    fullDynamicPageRoutes: {},

    translatedMenus: Ember.computed('languageService.selectedLanguage', 'menus', function () {
      var languageService = this.get('languageService');
      var menus = this.get('menus');

      return menus.map(function (menu) {
        return _extends({}, menu, {
          pages: menu.pages.map(function (mPage) {
            return _extends({}, mPage, {
              page: _extends({}, mPage.page, {
                translatedMenuTitle: languageService.getTranslatedMenuTitle(mPage.page)
              })
            });
          }),
          title: languageService.translateField(menu, 'title')
        });
      });
    }),

    calcNavbarColumns: function calcNavbarColumns(widgets) {
      var navbarColumns = this.get('navbarColumns');
      var l = 0;
      var m = 0;
      var s = 0;

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = widgets[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var widget = _step.value;

          if (!(widget.options && widget.options.hiddenLarge)) {
            l = l + 1;
          }
          if (!(widget.options && widget.options.hiddenMedium)) {
            m = m + 1;
          }
          if (!(widget.options && widget.options.hiddenSmall)) {
            s = s + 1;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      if (l) {
        Ember.set(navbarColumns, 'atLarge', navbarColumns.atLarge + 1);
      }
      if (m) {
        Ember.set(navbarColumns, 'atMedium', navbarColumns.atMedium + 1);
      }
      if (s) {
        Ember.set(navbarColumns, 'atSmall', navbarColumns.atSmall + 1);
      }

      this.set('navbarColumns', navbarColumns);
    },
    getNavbarColumns: function getNavbarColumns(widgetBuckets) {
      this.set('navbarColumns', {
        atLarge: 0,
        atMedium: 0,
        atSmall: 0
      });

      if (widgetBuckets.navbarLeft) {
        this.calcNavbarColumns(widgetBuckets.navbarLeft.widgets);
      }
      if (widgetBuckets.navbarRight) {
        this.calcNavbarColumns(widgetBuckets.navbarRight.widgets);
      }
      if (widgetBuckets.navbarCenter) {
        this.calcNavbarColumns(widgetBuckets.navbarCenter.widgets);
      }
      if (widgetBuckets.logo) {
        this.calcNavbarColumns(widgetBuckets.logo.widgets);
      }
    },
    filterTrackingWidgets: function filterTrackingWidgets(widgets) {
      var hasTracking = widgets.some(function (w) {
        return w.widget.options && w.widget.options.isTrackingActive;
      });
      if (hasTracking) {
        var fingerprintService = this.get('fingerprintService');
        var fingerprintData = fingerprintService.getProperties(['isKnown', 'isKnownTime', 'wasOnPage', 'wasOnPageTime']);
        return widgets.filter(function (w) {
          var options = w.widget.options || {};

          if (options.isTrackingActive) {
            var wasHere = fingerprintData.isKnown;
            var wasHereTime = fingerprintData.isKnownTime;
            if (options.pageRelevant) {
              wasHere = fingerprintData.wasOnPage;
              wasHereTime = fingerprintData.wasOnPageTime;
            }

            var trackingMatch = wasHere;
            var trackingInterval = parseFloat(options.trackingInterval);
            if (trackingMatch && trackingInterval) {
              trackingMatch = Date.now() - new Date(wasHereTime).getTime() < trackingInterval * 60 * 1000;
            }

            if (options.showForRecurringUsers) {
              return trackingMatch;
            } else {
              return !trackingMatch;
            }
          }

          return true;
        });
      } else {
        return widgets;
      }
    },
    waitForBrowserLocation: function waitForBrowserLocation(location) {
      if (!location) {
        return;
      }
      var widgetsIncludingRegionRestriction = this.get('widgetsIncludingRegionRestriction');
      var widgets = _transformWidgets.default.createWidgetBuckets(widgetsIncludingRegionRestriction, this.get('positions'));
      this.getNavbarColumns(widgets);
      this.set('widgets', widgets);
    },
    mapPageWidgets: function mapPageWidgets(page, positions) {
      var _this = this;

      var filteredWidgets = this.filterTrackingWidgets(filterWidgetsByBlogPage(page, page.widgets));

      this.set('widgetsIncludingRegionRestriction', filteredWidgets);
      this.set('positions', positions);

      var hasRegionRestriction = filteredWidgets.some(function (x) {
        return x.widget.restrictRegion;
      });
      if (hasRegionRestriction && !this.get('locationService.browserLocation')) {
        filteredWidgets = filteredWidgets.filter(function (x) {
          return !x.widget.restrictRegion;
        });
        this.get('locationService').getBrowserLocation().then(function (location) {
          return _this.waitForBrowserLocation(location);
        });
      }

      // Mobile widgets filter
      if (this.get('media.isMobile')) {
        filteredWidgets = filteredWidgets.filter(function (x) {
          return !(x.options && x.options.hiddenSmall);
        });
      }

      var widgets = _transformWidgets.default.createWidgetBuckets(filteredWidgets, positions);
      this.getNavbarColumns(widgets);
      return widgets;
    },
    getHomePage: function getHomePage() {
      var _this2 = this;

      var cachedData = this.get('cachedData');
      var myStorage = this.get('myStorage');
      var languageService = this.get('languageService');

      return cachedData.getSite().then(function (site) {
        return myStorage.page.ajaxGet({ query: { lang: languageService.selectedLanguage, site: site._id } }).then(function (page) {
          var widgets = _this2.mapPageWidgets(page, site.positions);
          _this2.set('widgets', widgets);
          _this2.set('activePage', page);
          return page;
        });
      });
    },
    getPageAndWidgets: function getPageAndWidgets(fullSlug) {
      var _this3 = this;

      var cachedData = this.get('cachedData');
      var myStorage = this.get('myStorage');
      var languageService = this.get('languageService');
      return cachedData.getSite().then(function (site) {
        return myStorage.page.ajaxGet({
          param: fullSlug,
          query: { lang: languageService.selectedLanguage, site: site._id }
        }).then(function (page) {
          var widgets = _this3.mapPageWidgets(page, site.positions);
          return { page: page, widgets: widgets };
        });
      });
    },
    getPage: function getPage(fullSlug) {
      var _this4 = this;

      return this.getPageAndWidgets(fullSlug).then(function (_ref) {
        var page = _ref.page,
            widgets = _ref.widgets;

        _this4.set('widgets', widgets);
        return page;
      });
    },
    getAndSetActivePage: function getAndSetActivePage(fullSlug) {
      var _this5 = this;

      return this.getPage(fullSlug).then(function (page) {
        _this5.set('activePage', page);
        return page;
      });
    },
    getFixedPage: function getFixedPage(type) {
      var _this6 = this;

      var cachedData = this.get('cachedData');
      var containerPages = this.get('cachedData.cachedSite.containerPages'); // all containerpages on all sites

      return cachedData.getFlatPages().then(function (flatPages) {
        var ownContainerPage = flatPages.find(function (page) {
          return page.type === type;
        });
        // if no container page is found on this site, look if there is a container page on the main site
        if (ownContainerPage) {
          var site = _this6.get('cachedData.cachedSite');
          return _extends({}, ownContainerPage, { site: { _id: site._id, url: site.url } });
        } else {
          return containerPages && containerPages.find(function (page) {
            return page.site.isMain && page.type === type;
          });
        }
      });
    },
    setupDynamicPages: function setupDynamicPages() {
      var _this7 = this;

      var dynamicPageTypes = ['search', 'entryContainer', 'poiContainer', 'eventContainer', 'productContainer', 'categoryContainer', 'profileContainer', 'documentContainer', 'articleContainer', 'jobContainer', 'recipeContainer', 'campaignContainer', 'newsletterContainer', 'globalSearchContainer', 'comparisonContainer'];
      return Ember.RSVP.map(dynamicPageTypes, function (type) {
        return _this7.getFixedPage(type).then(function (page) {
          if (page) {
            return {
              type: type,
              fullSlug: page ? page.fullSlug : '',
              fullSlugs: page.fullSlugs,
              site: page.site
            };
          }
        });
      }).then(function (dynamicPages) {
        var dynamicPageRoutes = {};
        dynamicPages.filter(Boolean).forEach(function (_ref2) {
          var type = _ref2.type,
              fullSlug = _ref2.fullSlug,
              fullSlugs = _ref2.fullSlugs,
              site = _ref2.site;

          // dynamicPageRoutes[type] = fullSlug;
          dynamicPageRoutes[type] = { fullSlug: fullSlug, fullSlugs: fullSlugs, site: site };
        });
        _this7.set('fullDynamicPageRoutes', dynamicPageRoutes);
      });
    },


    // translated slugs of container pages
    dynamicPageRoutes: Ember.computed('languageService.selectedLanguage', 'fullDynamicPageRoutes', function () {
      var languageService = this.get('languageService');
      var fullDynamicPageRoutes = this.get('fullDynamicPageRoutes');
      var dynamicPageRoutes = {};

      Object.keys(fullDynamicPageRoutes).forEach(function (key) {
        dynamicPageRoutes[key] = languageService.translateSlug(fullDynamicPageRoutes[key]);
      });

      return dynamicPageRoutes;
    }),

    // params optional
    getPageRouteParams: function getPageRouteParams(page, params) {
      var languageService = this.get('languageService');
      var selectedLanguage = this.get('languageService.selectedLanguage');
      var langSlug = this.get('languageService.languageSlug');

      if (page.type === 'account') {
        if (params && params[1]) {
          return ['account.' + params[1].replace(/\//g, '.')];
        }
        return ['account'];
      } else if (page.type === 'login') {
        return ['login'];
      } else if (page.type === 'register') {
        return ['register'];
      } else if (page.fullSlug === '') {
        // index route;
        if (langSlug) {
          return ['page', langSlug];
        } else {
          return ['index'];
        }
      } else {
        var pageParams = void 0;
        if (langSlug) {
          pageParams = languageService.translateSlug(page, selectedLanguage);
        } else {
          pageParams = page.fullSlug;
        }

        if (params && params.length > 1) {
          pageParams = _misc.joinRoutes.apply(undefined, [pageParams].concat(_toConsumableArray(params.slice(1))));
        }
        return ['page', pageParams];
      }
    },
    setSiteCodes: function setSiteCodes(site) {
      var isFastBoot = this.get('fastboot.isFastBoot');
      // const controller = this.controllerFor('application');
      var owner = Ember.getOwner(this);
      var applicationController = owner.lookup('controller:application');
      var applicationRoute = owner.lookup('route:application');

      var headerCode = site.headerCode && site.headerCode.trim();
      var bodyCode = site.bodyCode && site.bodyCode.trim();
      var footerCode = site.footerCode && site.footerCode.trim();

      // TODO check if cookies accepted
      applicationRoute.set('headData.headerCode', headerCode);
      applicationController.set('bodyCode', bodyCode);
      applicationController.set('footerCode', footerCode);

      if (!isFastBoot) {
        if (localStorage.getItem('social-tracking-enabled') === 'true') {
          window.socialTrackingEnabled = true;
        }

        Ember.run.schedule('afterRender', function () {
          // this only needs to be enabled, if fastboot is switched off!
          // injected script are not executed https://www.danielcrabtree.com/blog/25/gotchas-with-dynamically-adding-script-tags-to-html
          [headerCode, bodyCode, footerCode].forEach(function (data) {
            (0, _misc.executeScripts)(data);
            (0, _misc.executeScriptsSrc)(data);
          });
        });
      }
    },
    setSessionInfo: function setSessionInfo(site) {
      if (!this.get('fastboot.isFastBoot')) {
        var sessionId = window.localStorage.getItem('sessionId');
        if (!sessionId) {
          sessionId = site.uuid;
          window.localStorage.setItem('sessionId', sessionId);
        }
        this.set('sessionId', sessionId);
      }
    },
    redirectToPageById: function redirectToPageById(pageId) {
      var languageService = this.get('languageService');
      var pages = this.get('cachedData.cachedSite.pages');
      var router = this.get('router');
      var page = (0, _treeUtils.findInTree)(pages, function (x) {
        return x._id === pageId;
      });

      if (page) {
        var translatedSlug = languageService.translateSlug(page);
        Ember.run.next(function () {
          return router.transitionTo('page', translatedSlug);
        });
      } else {
        window.console.error('page for _id: ' + pageId + ' not found.');
      }
    },


    // used for form submissions
    redirectToPageByInternalName: function redirectToPageByInternalName(pageInternalName, overrideActiveDetail) {
      var languageService = this.get('languageService');
      var pages = this.get('cachedData.cachedSite.pages');
      var router = this.get('router');
      var page = (0, _treeUtils.findInTree)(pages, function (x) {
        return x.internalName === pageInternalName;
      });

      if (page) {
        var activePage = this.get('activePage');
        var activeDetail = this.get('activeDetail');
        var translatedSlug = languageService.translateSlug(page);
        var slugSplit = translatedSlug.split('/');

        var redirectUrl = translatedSlug;
        if (overrideActiveDetail) {
          var parentPage = (0, _treeUtils.findInTree)(pages, function (x) {
            return x._id === page.parentPage;
          });
          if (parentPage && parentPage.type.includes('Container')) {
            redirectUrl = [].concat(_toConsumableArray(slugSplit.slice(0, slugSplit.length - 1)), [overrideActiveDetail.slug, slugSplit[slugSplit.length - 1]]).join('/');
          }
        } else if (activeDetail && activePage.type.includes('Container') && activePage._id === page.parentPage) {
          redirectUrl = [].concat(_toConsumableArray(slugSplit.slice(0, slugSplit.length - 1)), [activeDetail.slug, slugSplit[slugSplit.length - 1]]).join('/');
        }
        // doesnt work without next somehow
        Ember.run.next(function () {
          return router.transitionTo('page', redirectUrl);
        });
      } else {
        window.console.error('page for internalName: ' + pageInternalName + ' not found.');
      }
    }
  });
});
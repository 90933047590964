define('webapp/routes/account/component-press/articles', ['exports', 'moment', 'webapp/utils/filter-categories'], function (exports, _moment, _filterCategories) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),
    cachedData: Ember.inject.service(),

    model: function model() {
      var filter = this.get('filter') || {};
      return this.controllerFor(this.routeName).search();
    },
    afterModel: function afterModel() {
      var _this = this;

      this._super.apply(this, arguments);
      return this.get('cachedData').getCategories().then(function (categories) {
        _this.controllerFor(_this.routeName).set('categories', (0, _filterCategories.default)(categories, ['article', 'group']));
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('calendarCenter', (0, _moment.default)());
    }
  });
});
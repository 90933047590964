define('webapp/utils/ustid', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.checkUstID = checkUstID;
  // taken from http://www.pruefziffernberechnung.de/U/USt-IdNr.shtml

  function checkUstIDDE(ustidString) {
    ustidString = ustidString.replace(/\s+/g, '');
    var ustidMatch = ustidString.match(/^(DE)(\d{9})$/);
    if (!ustidMatch) {
      return false;
    }

    var ustidNumber = ustidMatch[2];

    var m = 10;
    var n = 11;
    var sum = 0;
    var prod = m;

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = ustidNumber.slice(0, 8)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var c = _step.value;

        var x = Number(c);
        sum = (x + prod) % m;
        if (sum === 0) {
          sum = m;
        }
        prod = 2 * sum % n;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return Number(ustidNumber[8]) === n - prod;
  }

  function calcDigitSum(value) {
    var sum = 0;
    while (value) {
      sum += value % 10;
      value = Math.floor(value / 10);
    }
    return sum;
  }

  function checkUstIDAT(ustidString) {
    ustidString = ustidString.replace(/\s+/g, '');
    var ustidMatch = ustidString.match(/^(ATU)(\d{8})$/);
    if (!ustidMatch) {
      return false;
    }

    var ustidNumber = ustidMatch[2];

    var digitSum = 0;
    var multiplier = 1;
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = ustidNumber.slice(0, 7)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var c = _step2.value;

        var x = multiplier * Number(c);
        digitSum += calcDigitSum(x);
        multiplier = multiplier === 1 ? 2 : 1;
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    var controlNumber = (96 - digitSum) % 10;

    return Number(ustidNumber[7]) === controlNumber;
  }

  function checkUstID(ustidString) {
    var countryCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DE';

    if (countryCode === 'DE') {
      return checkUstIDDE(ustidString);
    } else {
      return checkUstIDAT(ustidString);
    }
  }
});
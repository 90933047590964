define('webapp/routes/account/favorites', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var onScroll = function onScroll() {};

  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),
    languageService: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    page: 1,
    isLoading: false,

    activate: function activate() {
      var _this = this;

      onScroll = function onScroll() {
        var isLoading = _this.get('isLoading');
        var docHeight = document.body.clientHeight;

        if (!isLoading && window.scrollY + 1.7 * window.innerHeight > docHeight) {
          _this.loadMoreData();
        }
      };

      Ember.$(document).bind('touchmove', onScroll);
      Ember.$(window).bind('scroll', onScroll);
    },
    deactivate: function deactivate() {
      Ember.$(window).unbind('scroll');
      Ember.$(document).unbind('touchmove');
    },
    model: function model() {
      var myStorage = this.get('myStorage');

      var sessionId = this.get('cmsService.sessionId');
      var lang = this.get('languageService.selectedLanguage');
      var query = { sessionId: sessionId, lang: lang };
      var params = { query: query };

      return myStorage.favorite.ajaxGet(params).then(function (res) {
        return res;
      });
    },
    loadMoreData: function loadMoreData() {
      var _this2 = this;

      this.set('isLoading', true);

      var controller = this.controllerFor(this.routeName);
      var page = this.get('page') + 1;
      var myStorage = this.get('myStorage');
      var hits = controller.get('model.hits');
      var model = controller.get('model.model');

      if (hits > model.length) {
        myStorage.favorite.ajaxGet({ param: '?page=' + page }).then(function (data) {
          var _model;

          hits = data.hits;
          model = (_model = model).concat.apply(_model, _toConsumableArray(data.model));
          controller.set('model', {
            hits: hits,
            model: model
          });
          _this2.set('page', page);
          _this2.set('isLoading', false);
        });
      }
    }
  });
});
define('webapp/components/trial-calendar', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function startOfDay(date) {
    return (0, _moment.default)(date).startOf('day').toDate();
  }

  // round date to 30mins -> 13:27 -> 13:30, 14:20 -> 14:00
  function roundDate(date) {
    var minutes = date.getMinutes();

    var targetMinutes = Math.round(minutes / 30) * 30;

    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), targetMinutes);
  }

  function getTimeIndex(minHour, date) {
    var idx = Math.floor((60 * date.getHours() + date.getMinutes() - 60 * minHour) / 30);
    return idx;
  }

  function transformSlots(startDate, slots) {
    slots = slots.filter(function (slot) {
      return new Date(slot.startDateTime).getTime() >= startDate.getTime();
    });

    if (slots.length === 0) {
      return [];
    }

    var minHour = 24;
    var maxHour = 0;

    slots.forEach(function (slot) {
      var startHour = new Date(slot.startDateTime).getHours();
      if (startHour < minHour) {
        minHour = startHour;
      }
      var endHour = new Date(slot.endDateTime).getHours();
      if (endHour > maxHour) {
        maxHour = endHour;
      }
    });

    var normedSlots = slots.map(function (x) {
      return {
        startDateTime: roundDate(new Date(x.startDateTime))
      };
    });

    var timeLabels = [];
    for (var i = minHour * 2; i <= maxHour * 2 + 1; i++) {
      var h = Math.floor(i / 2);
      var m = i % 2 === 0 ? '00' : '30';
      timeLabels.push(h + ':' + m);
    }

    function getDefaultColumn(date) {
      return {
        date: startOfDay(date),
        slots: timeLabels.map(function (time) {
          return { time: time, isActive: false };
        })
      };
    }

    var columns = [];
    for (var _i = 0; _i < 7; _i++) {
      var colDate = (0, _moment.default)(startDate).add(_i, 'day').toDate();
      columns.push(getDefaultColumn(colDate));
    }

    normedSlots.forEach(function (normedSlot) {
      var slotDate = new Date(normedSlot.startDateTime);

      var currentColumn = columns.find(function (col) {
        return col.date.getTime() === startOfDay(slotDate).getTime();
      });

      if (currentColumn) {
        var slot = currentColumn.slots[getTimeIndex(minHour, slotDate)];
        slot.date = slotDate;
        slot.dateString = slotDate.toISOString();
        slot.isActive = true;
      }
    });

    return { columns: columns, timeLabels: timeLabels };
  }

  var mandatoryFields = ['trialData.leadCustomer.gender', 'trialData.leadCustomer.firstname', 'trialData.leadCustomer.lastname', 'trialData.leadCustomer.email', 'trialData.leadCustomer.phone'];

  exports.default = Ember.Component.extend({
    cmsService: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    i18n: Ember.inject.service(),
    isLoaded: false,
    studioId: '1217472670',
    now: Ember.computed(function () {
      return startOfDay(new Date());
    }),
    dayOffset: 0,
    selectedSlotDate: null,

    startDate: Ember.computed('dayOffset', 'now', function () {
      var now = this.get('now');
      var dayOffset = this.get('dayOffset');
      return (0, _moment.default)(now).add(dayOffset, 'days').toDate();
    }),

    tennant: Ember.computed('studioCategories', function () {
      var studioCategories = this.get('studioCategories') || [];

      if (studioCategories.some(function (x) {
        return x.slug === 'oesterreich';
      })) {
        return 'bodystreet-at';
      }

      return 'bodystreet';
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.fetchData(this.get('studioId')).then(function () {
        _this.set('isLoaded', true);
      });

      this.setProperties({
        trialSessionBooked: false,
        privacyChecked: false,
        trialData: {
          leadCustomer: {
            address: { city: '', houseNumber: '', country: 'DE', street: '', zip: '' },
            gender: 'FEMALE'
          },
          studioId: Number(this.get('studioId')),
          note: '',
          referrerId: null,
          trainerRequired: false,
          sourceCampaignId: ''
        }
      });
    },
    fetchData: function fetchData(studioId) {
      var _this2 = this;

      var myStorage = this.get('myStorage');

      return myStorage.magicline.ajaxGet({
        param: 'trialsession',
        query: { studioId: studioId, tennant: this.get('tennant') }
      }).then(function (res) {
        _this2.set('slots', res.slots);
      });
    },


    slotData: Ember.computed('slots', 'startDate', function () {
      var slots = this.get('slots');
      var startDate = this.get('startDate');
      if (!slots) {
        return;
      }
      var slotData = transformSlots(startDate, slots);
      return slotData;
    }),

    trialDataValid: Ember.computed.apply(undefined, ['privacyChecked'].concat(mandatoryFields, [function () {
      var _this3 = this;

      return this.get('privacyChecked') && mandatoryFields.every(function (field) {
        var fieldValue = _this3.get(field);
        return fieldValue && fieldValue.trim();
      });
    }])),

    actions: {
      nextDay: function nextDay() {
        this.set('dayOffset', this.dayOffset + 1);
      },
      previousDay: function previousDay() {
        this.set('dayOffset', this.dayOffset - 1);
      },
      selectSlot: function selectSlot(slot) {
        this.set('selectedSlotDate', slot.dateString);
        this.set('trialData.startDateTime', slot.dateString);
        this.get('selectSlot') && this.get('selectSlot')(slot.date); // call passed action
      },
      submitTrialSession: function submitTrialSession() {
        var _this4 = this;

        var myStorage = this.get('myStorage');
        var trialData = this.get('trialData');

        return myStorage.magicline.ajaxPost({
          param: 'trialsession',
          data: _extends({}, trialData, { tennant: this.get('tennant') })
        }).then(function () {
          _this4.set('trialSessionBooked', true);
          var cmsService = _this4.get('cmsService');
          cmsService.redirectToPageByInternalName('entry-thanks');
        }).catch(function (err) {
          var message = _this4.get('message');
          message.error(err.payload && err.payload.message);
        });
      }
    }
  });
});
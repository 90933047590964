define('webapp/components/widgets/widget-search-address', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    languageService: Ember.inject.service(),
    searchTerm: '',
    searchPromise: null,

    fetchGeoSearchResult: function fetchGeoSearchResult(searchTerm) {
      var isZip = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var myStorage = this.get('myStorage');

      var mapboxSearchCountriesString = (this.get('data.options.mapboxSearchCountries') || '').trim();

      var countries = undefined;
      if (mapboxSearchCountriesString) {
        countries = mapboxSearchCountriesString.split(/\s*,\s*/);
      }

      var proximity = undefined;
      var types = undefined;
      if (isZip) {
        types = ['postcode'];
        var isNumber = !isNaN(Number(searchTerm));
        var isAustria = isNumber && searchTerm.length === 4;
        if (isAustria) {
          proximity = { lat: 47.72, lon: 14.76 };
        }
      }

      return myStorage.geocoder.ajaxPost({
        param: 'search_place',
        data: {
          searchTerm: searchTerm,
          language: this.get('languageService.selectedLanguage'),
          proximity: proximity,
          countries: countries,
          types: types
        }
      });
    },
    geoSearch: function geoSearch(searchTerm) {
      var _this = this;

      var p = this.fetchGeoSearchResult(searchTerm);
      this.searchPromise = p;

      // probably bug: why not just p.then ?
      this.fetchGeoSearchResult(searchTerm).then(function (res) {
        if (p === _this.searchPromise) {
          // so old delayed result cant overwrite latest
          _this.set('suggestions', res);
        }
      });
    },
    resetSearch: function resetSearch() {
      this.set('suggestions', null);
      this.set('searchTerm', '');
    },
    getCategoryFilter: function getCategoryFilter() {
      var categoryFilterOperation = this.get('data.options.categoryFilterOperation');
      var rootCategories = this.get('data.options.rootCategories');

      var catFilters = [];
      if (rootCategories && rootCategories.length) {
        catFilters.push({ type: categoryFilterOperation, items: rootCategories });
      }

      return catFilters;
    },
    selectSuggestion: function selectSuggestion(geoResult) {
      var _this2 = this;

      var center = geoResult.center;
      var myStorage = this.get('myStorage');

      var searchBody = {
        location: { lat: center[1], lon: center[0] },
        itemTypes: this.get('data.options.itemTypes'),
        sort: 'geoDistance',
        size: this.get('data.options.perPage') || 12,
        categoryFilter: this.getCategoryFilter(),
        aggregations: false
      };

      myStorage.search.ajaxPost({
        data: searchBody,
        query: {
          lang: this.get('languageService.selectedLanguage'),
          site: this.get('cachedData.cachedSite._id')
        }
      }).then(function (res) {
        _this2.set('items', res.hits);
      });
    },
    confirmSearch: function confirmSearch() {
      var suggestions = this.get('suggestions');
      var firstSuggestion = suggestions && suggestions[0];
      if (firstSuggestion) {
        this.selectSuggestion(firstSuggestion);
      }
    },


    actions: {
      searchKeyup: function searchKeyup(e) {
        var searchTerm = e.target.value;
        this.set('searchTerm', searchTerm);
        if (e.key === 'Escape') {
          this.resetSearch();
        } else if (e.key === 'Enter') {
          // this is handled by action in template
          // this.confirmSearch();
        } else {
          Ember.run.debounce(this, this.geoSearch, searchTerm, 200);
        }
      },
      clearSuggestions: function clearSuggestions() {
        this.set('suggestions', undefined);
      },
      selectSuggestion: function selectSuggestion(suggestion) {
        this.selectSuggestion(suggestion);
        this.set('suggestions', null);
      },
      confirmSearch: function confirmSearch() {
        this.confirmSearch();
      }
    }
  });
});
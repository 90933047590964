define('webapp/routes/page', ['exports', 'webapp/routes/base-route', 'webapp/utils/head-tags', 'webapp/utils/image-path'], function (exports, _baseRoute, _headTags, _imagePath) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    templateSettings: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    viewService: Ember.inject.service(),
    searchService: Ember.inject.service(),
    globalSearchContainer: Ember.inject.service(),
    entryContainer: Ember.inject.service(),
    poiContainer: Ember.inject.service(),
    eventContainer: Ember.inject.service(),
    productContainer: Ember.inject.service(),
    productAccessoryContainer: Ember.inject.service(),
    productAttachmentContainer: Ember.inject.service(),
    productCompletionContainer: Ember.inject.service(),
    productSparePartContainer: Ember.inject.service(),
    profileContainer: Ember.inject.service(),
    categoryContainer: Ember.inject.service(),
    newsletterContainer: Ember.inject.service(),
    documentContainer: Ember.inject.service(),
    articleContainer: Ember.inject.service(),
    jobContainer: Ember.inject.service(),
    recipeContainer: Ember.inject.service(),
    campaignContainer: Ember.inject.service(),
    languageService: Ember.inject.service(),
    fingerprintService: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),
    comparisonContainer: Ember.inject.service(),

    // services that handle special pages like detail, search
    pageHandlers: Ember.computed(function () {
      return {
        search: 'searchService',
        globalSearchContainer: 'globalSearchContainer',
        entryContainer: 'entryContainer',
        poiContainer: 'poiContainer',
        eventContainer: 'eventContainer',
        productContainer: 'productContainer',
        productAccessoryContainer: 'productAccessoryContainer',
        productAttachmentContainer: 'productAttachmentContainer',
        productCompletionContainer: 'productCompletionContainer',
        productSparePartContainer: 'productSparePartContainer',
        jobContainer: 'jobContainer',
        recipeContainer: 'recipeContainer',
        campaignContainer: 'campaignContainer',
        profileContainer: 'profileContainer',
        categoryContainer: 'categoryContainer',
        newsletterContainer: 'newsletterContainer',
        documentContainer: 'documentContainer',
        articleContainer: 'articleContainer',
        comparisonContainer: 'comparisonContainer'
      };
    }),

    beforeModel: function beforeModel(transition) {
      this.set('breadcrumbs', null);
      this.set('transitionData', transition);
    },
    setupPage: function setupPage(page, params) {
      var _this = this;

      var pageHandlers = this.get('pageHandlers');
      var handlerService = pageHandlers[page.type];
      this.set('currentHandlerService', handlerService && this.get(handlerService));
      this.set('page', page);

      if (handlerService) {
        return this.get(handlerService).setupPage(this, page, params).then(function (detail) {
          _this.set('cmsService.activeDetail', detail);
          return page;
        });
      } else {
        this.set('cmsService.activeDetail', null);
      }
      return Ember.RSVP.resolve(page);
    },
    model: function model(params) {
      var _this2 = this;

      if (!params.path) {
        return this.replaceWith('index');
      }

      var cmsService = this.get('cmsService');
      var languageService = this.get('languageService');
      var targetSlug = params.path;

      return this.get('cmsService').getPageAndWidgets(targetSlug).then(function (_ref) {
        var page = _ref.page,
            widgets = _ref.widgets;

        if (page.detectedLanguage) {
          var oldSelectedLanguage = languageService.selectedLanguage;
          languageService.setInitialLanguage(page.detectedLanguage);

          if (page.detectedLanguage !== oldSelectedLanguage) {
            // need to refecth shoppingCart, so products are displayed in the correct language
            var shoppingCartService = _this2.get('shoppingCartService');
            if (shoppingCartService.shoppingCart) {
              shoppingCartService.fetchShoppingCart();
            }
          }
        }

        return _this2.setupPage(page, params).then(function (page) {
          cmsService.set('activePage', page);
          cmsService.set('widgets', widgets);
          return page;
        });
      });
    },
    fingerprintPage: function fingerprintPage() {
      var transition = this.get('transitionData');
      var fingerprintService = this.get('fingerprintService');
      var page = this.get('page');
      var type = page.type;
      var lang = this.get('languageService.selectedLanguage');
      var data = { lang: lang };

      if (type === 'search') {
        data['searchTerm'] = page.restSlug;
      }

      fingerprintService.handleBeforeTransition(transition, type, data);
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      this.fingerprintPage();

      var currentHandlerService = this.get('currentHandlerService');
      var headTags = this.getPageHeadTags(model);

      if (currentHandlerService) {
        if (currentHandlerService.afterPageModel) {
          currentHandlerService.afterPageModel(this, model);
        }
        if (currentHandlerService.getTitleToken) {
          this.set('titleToken', currentHandlerService.getTitleToken(model));
        } else {
          this.set('titleToken', '');
        }
        if (currentHandlerService.getHeadTags) {
          headTags = headTags.concat(currentHandlerService.getHeadTags(this, model));
        }
      } else if (model.type === 'blogPost') {
        headTags = headTags.concat(this.generatePostHeadTags(model));
      }

      this.set('headTags', (0, _headTags.removeDuplicates)(headTags));

      Ember.run.schedule('afterRender', function () {
        if (window.location && window.location.hash) {
          var hashElem = document.getElementById(window.location.hash.slice(1));

          if (hashElem) {
            setTimeout(function () {
              hashElem.scrollIntoView();
            }, 100);
          }
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('detail', this.get('currentHandlerService.detail'));

      // TODO not needed after poi/event containers are split from entry container
      controller.set('itemType', this.get('currentHandlerService.itemType'));
    },
    generatePostHeadTags: function generatePostHeadTags(model) {
      var headTags = [];
      var user = model.post.createdBy;
      if (model.post) {
        headTags.pushObject((0, _headTags.generateHeadTag)('twitter:card', 'summary'));
        if (user && user.twitter) {
          headTags.pushObject((0, _headTags.generateHeadTag)('twitter:creator', user.twitter));
        }

        headTags.pushObjects((0, _headTags.generateHeadTags)(['name', 'twitter:title', 'og:title'], model.post.title));
        headTags.pushObjects((0, _headTags.generateHeadTags)(['description', 'twitter:description', 'og:description'], model.post.metaDescription || model.post.description));

        if (model.post.image) {
          headTags.pushObjects((0, _headTags.generateHeadTags)(['og:image', 'twitter:image'], (0, _imagePath.default)(model.post.image.path, 'medium')));
          headTags.pushObject((0, _headTags.generateHeadTag)('og:image:width', '1024'));
          headTags.pushObject((0, _headTags.generateHeadTag)('og:image:height', '768'));
        }
      }

      return headTags;
    },
    blogCategoryBreadcrumbs: function blogCategoryBreadcrumbs() {
      var currentModel = this.get('currentModel');
      var breadcrumbs = [];
      var breadcrumbInfos = currentModel.category.breadcrumbs;
      var slug = currentModel.fullSlug + '/category';
      breadcrumbs.push({
        route: 'page',
        routeParams: slug,
        title: 'Kategorie'
      });
      for (var i = 0; i < breadcrumbInfos.length; i++) {
        var cat = breadcrumbInfos[i];
        slug += '/' + cat.slug;
        breadcrumbs.push({
          route: 'page',
          routeParams: slug,
          title: cat.title
        });
      }
      return breadcrumbs;
    },
    getBreadcrumbs: function getBreadcrumbs() {
      var languageService = this.get('languageService');
      var currentHandlerService = this.get('currentHandlerService');
      var currentModel = this.get('currentModel');

      var parentPages = currentModel.parentPages;

      var breadcrumbs = parentPages.map(function (p) {
        return {
          route: 'page',
          title: languageService.getTranslatedMenuTitle(p),
          routeParams: languageService.translateSlug(p)
        };
      });

      breadcrumbs.push({
        route: 'page',
        routeParams: currentModel.fullSlug,
        title: currentModel.menuTitle
      });

      if (currentModel.type === 'blogPost') {
        breadcrumbs.push({
          route: 'page',
          routeParams: currentModel.fullSlug + '/' + currentModel.post.slug,
          title: currentModel.post.title
        });
      } else if (currentModel.type === 'blogCategory') {
        breadcrumbs = breadcrumbs.concat(this.blogCategoryBreadcrumbs());
      }

      if (currentHandlerService && currentHandlerService.getBreadcrumbs) {
        breadcrumbs = currentHandlerService.getBreadcrumbs(breadcrumbs);
      }

      return breadcrumbs;
    }
  });
});
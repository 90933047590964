define('webapp/components/widgets/widget-custom-search', ['exports', 'webapp/utils/filter-utils'], function (exports, _filterUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function calcMinVariationPrice(item) {
    var prices = [];
    var priceGross = item.priceGross;
    if (priceGross) {
      prices.push(priceGross);
    }
    var detailProducts = item.variants || [];
    detailProducts.forEach(function (_ref) {
      var item = _ref.item;

      if (item.priceGross) {
        prices.push(item.priceGross);
      }
    });

    return prices.length ? Math.min.apply(Math, prices) : 0;
  }

  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    languageService: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this.fetchData();
    },
    getCategoryFilter: function getCategoryFilter() {
      var categoryFilterOperation = this.get('data.options.categoryFilterOperation');
      var rootCategories = this.get('data.options.rootCategories');

      var catFilters = [];
      if (rootCategories && rootCategories.length) {
        catFilters.push({ type: categoryFilterOperation, items: rootCategories });
      }

      return catFilters;
    },
    fetchEntries: function fetchEntries(options) {
      var myStorage = this.get('myStorage');

      var searchRequest = {
        itemTypes: options.types,
        size: Number(options.size) || 500,
        categoryFilter: this.getCategoryFilter(),
        tagFilter: options.tags,
        sort: options.sortField,
        sortOrder: options.sortOrder,
        aggregations: false,
        showVariants: options.types.includes('product')
      };

      return myStorage.search.ajaxPost({
        data: searchRequest,
        query: {
          lang: this.get('languageService.selectedLanguage'),
          site: this.get('cachedData.cachedSite._id')
        }
      }).then(function (res) {
        return {
          items: res.hits.map(function (item) {
            item.minVariationPrice = calcMinVariationPrice(item);
            return item;
          })
        };
      });
    },
    fetchArticles: function fetchArticles(options) {
      var _this = this;

      var filter = [];
      var categoryIds = this.get('data.options.rootCategories');

      if (categoryIds.length) {
        filter.push({
          terms: {
            'categories._id': categoryIds
          }
        });
      }

      if (options.tags && options.tags.length) {
        filter.push({
          terms: {
            tags: options.tags
          }
        });
      }

      if (options.timeframe) {
        var timeframeFilter = (0, _filterUtils.getTimeframeFilter)(options.timeframe);
        if (timeframeFilter) {
          filter.push(timeframeFilter);
        }
      }

      var sortField = options.sortField;
      if (options.sortField === 'alphabetical') {
        sortField = 'title.keyword';
      } else {
        sortField = options.sortField;
      }

      var sortOrder = options.sortOrder || 'asc';

      var myStorage = this.get('myStorage');
      return myStorage.article.ajaxPost({
        param: 'search_public',
        data: {
          allFields: false,
          aggregations: false,
          perPage: Number(options.size) || 500,
          sortField: sortField,
          sortOrder: sortOrder,
          filter: filter.length ? filter : undefined
        },
        query: { lang: this.get('languageService.selectedLanguage') }
      }).then(function (res) {
        if (!_this.isDestroyed) {
          res.model.forEach(function (item) {
            if (item.fields) {
              item.fields = JSON.parse(item.fields);
            }
          });
          return {
            items: res.model.map(function (x) {
              return _extends({}, x, { itemType: 'article' });
            })
          };
        }
      });
    },
    fetchData: function fetchData() {
      var _this2 = this;

      var options = this.get('data.options');

      var p = void 0;
      if (options.types.includes('article')) {
        p = this.fetchArticles(options);
      } else {
        p = this.fetchEntries(options);
      }

      p.then(function (res) {
        if (!_this2.isDestroyed) {
          _this2.setProperties(res);
        }
      });
    },


    hasContentAfter: Ember.computed('data.contentAfter', function () {
      var contentAfter = this.get('data.contentAfter');
      if (contentAfter) {
        return true;
      } else if (contentAfter === '') {
        return false;
      } else {
        return false;
      }
    }),
    hasContentBefore: Ember.computed('data.content', function () {
      var contentBefore = this.get('data.content');
      if (contentBefore) {
        return true;
      } else if (contentBefore === '') {
        return false;
      } else {
        return false;
      }
    }),

    _updateFavoriteCount: function _updateFavoriteCount() {
      var event = new Event('updated-favorite-count');
      var elements = document.getElementsByClassName('tm-widget-favorites');
      var element = elements[0];
      element.dispatchEvent(event);
    },


    actions: {
      addToFavorite: function addToFavorite(item, evt) {
        var _this3 = this;

        evt.stopPropagation();

        var message = this.get('message');
        var myStorage = this.get('myStorage');
        var data = { item: item._id };
        this.set(item, 'isFavorite', true);

        myStorage.favorite.ajaxPost({ data: data }).finally(function () {
          message.infoT('user.product.addedToFavorites');
          _this3._updateFavoriteCount();
        });

        return false;
      }
    }
  });
});
define('webapp/components/field-visibility', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      toggleVisibility: function toggleVisibility(fieldName, status) {
        var invisibleFields = this.get('options.invisibleFields') || [];
        if (status) {
          if (invisibleFields.indexOf(fieldName) < 0) {
            invisibleFields = invisibleFields.concat(fieldName);
          }
        } else {
          invisibleFields = invisibleFields.filter(function (field) {
            return field !== fieldName;
          });
        }
        this.set('options.invisibleFields', invisibleFields);
      }
    }
  });
});
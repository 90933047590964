define('webapp/routes/base-route', ['exports', 'webapp/config/environment', 'webapp/utils/head-tags', 'webapp/utils/image-path'], function (exports, _environment, _headTags, _imagePath) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Route.extend({
    cmsService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    viewService: Ember.inject.service(),
    activeBodyClass: null,
    languageService: Ember.inject.service(),
    fastboot: Ember.inject.service(),

    positions: _environment.default.widgetPositions,

    afterModel: function afterModel(model) {
      this.controllerFor('application').set('widgets', model.widgets);
      this.set('viewService.currentModel', model);
      this.set('titleToken', model.title);
    },
    getBreadcrumbs: function getBreadcrumbs(handlerInfos) {
      return [{
        route: handlerInfos.name,
        routeParams: this.get('currentModel.fullSlug'),
        title: this.get('currentModel.menuTitle')
      }];
    },
    getLanguageLinks: function getLanguageLinks(page) {
      var languageService = this.get('languageService');
      var selectedLanguage = languageService.selectedLanguage;

      var site = this.get('cachedData.cachedSite');

      if (!site.multiLanguage) {
        return [];
      }

      var languages = site.languages;
      var otherLanguages = languages.filter(function (x) {
        return x !== selectedLanguage;
      });

      var headTags = [];
      page.fullSlugs.forEach(function (slugElem) {
        var slugLang = slugElem.language;
        var languagePrefix = languageService.getLanguagePrefix(slugLang);
        if (otherLanguages.includes(slugLang)) {
          headTags.push({
            type: 'link',
            tagId: 'canonical-' + slugLang,
            attrs: {
              rel: 'alternate',
              hreflang: slugLang,
              href: [site.url, languagePrefix, slugElem.fullSlug, page.restSlug].filter(Boolean).join('/')
            }
          });
        }
      });

      var defaultLanguage = site.defaultLanguage || 'de';
      var slugElem = page.fullSlugs.find(function (x) {
        return x.language === defaultLanguage;
      });
      if (slugElem) {
        var languagePrefix = languageService.getLanguagePrefix(defaultLanguage);
        headTags.push({
          type: 'link',
          tagId: 'canonical-x-default',
          attrs: {
            rel: 'alternate',
            hreflang: 'x-default',
            href: [site.url, languagePrefix, slugElem.fullSlug, page.restSlug].filter(Boolean).join('/')
          }
        });
      }

      return headTags;
    },
    getPageHeadTags: function getPageHeadTags(model) {
      var languageService = this.get('languageService');
      var headTags = [];

      var siteTitle = this.get('cachedData.cachedSite.title');

      headTags.pushObject((0, _headTags.generateHeadTag)('twitter:card', 'summary'));
      headTags.pushObject((0, _headTags.generateHeadTag)('og:type', 'website'));
      if (this.get('fastboot.isFastBoot')) {
        headTags.pushObject((0, _headTags.generateHeadTag)('og:url', _environment.default.baseUrl.slice(0, _environment.default.baseUrl.length - 1) + this.get('fastboot.request.path')));
      } else {
        headTags.pushObject((0, _headTags.generateHeadTag)('og:url', window.location.href));
      }

      var title = '';
      if (model.type === 'home') {
        title = siteTitle;
      } else {
        title = languageService.translateField(model, 'title');
        if (title) {
          title += ' - ' + siteTitle;
        } else {
          title = siteTitle;
        }
      }

      headTags.pushObjects((0, _headTags.generateHeadTags)(['name', 'twitter:title', 'og:title'], title));

      var metaDescription = languageService.translateField(model, 'metaDescription');
      if (metaDescription) {
        headTags.pushObjects((0, _headTags.generateHeadTags)(['description', 'twitter:description', 'og:description'], metaDescription));
      }

      var metaKeywords = languageService.translateField(model, 'metaKeywords');
      if (metaKeywords) {
        headTags.pushObject((0, _headTags.generateHeadTag)('keywords', metaKeywords));
      }

      if (model.image) {
        headTags.pushObjects((0, _headTags.generateHeadTags)(['og:image', 'twitter:image'], (0, _imagePath.default)(model.image.path, 'medium')));
        headTags.pushObject((0, _headTags.generateHeadTag)('og:image:width', '1024'));
        headTags.pushObject((0, _headTags.generateHeadTag)('og:image:height', '768'));
      }

      if (model.excludeFromRobots) {
        headTags.push({
          type: 'meta',
          tagId: 'noindex',
          attrs: {
            name: 'robots',
            content: 'noindex'
          }
        });
      }

      var allHeadTags = [].concat(_toConsumableArray(this.getLanguageLinks(model)), headTags);
      return allHeadTags;
    }
  });
});
define('webapp/components/form/entries-component', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    message: Ember.inject.service(),
    submitted: false,
    selectedCategory: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var cachedData = this.get('cachedData');
      var entries = this.get('entries');
      var entryCatIds = [];

      var formField = this.get('formField');
      this.set('formData.' + formField.key, []);

      if (entries) {
        entries.forEach(function (entry) {
          return entry.categories.forEach(function (cat) {
            return entryCatIds.push(cat);
          });
        });
      }

      cachedData.getCategories().then(function (categories) {
        var shownCategories = [];
        var regionCat = (0, _treeUtils.findInTree)(categories, function (x) {
          return x.slug === 'region';
        });
        (0, _treeUtils.walkTree)(regionCat.children, function (rCat) {
          if (!rCat.noDisplay && rCat.level > 1 && entryCatIds.includes(rCat._id)) {
            shownCategories.push(rCat);
          }
        });

        _this.set('shownCategories', shownCategories);
      });
    },


    entries: Ember.computed('detail.entries', function () {
      var entryRelations = this.get('detail.entries') || [];
      return entryRelations && entryRelations.map(function (x) {
        return x.item;
      });
    }),

    filteredEntries: Ember.computed('entries', 'selectedCategory', function () {
      var entries = this.get('entries');
      var selectedCategory = this.get('selectedCategory');

      if (selectedCategory) {
        var selectedCategoryId = selectedCategory._id;
        return entries.filter(function (entry) {
          return entry.categories.some(function (eCatId) {
            return eCatId === selectedCategoryId;
          });
        });
      } else {
        return entries;
      }
    }),

    actions: {
      selectCategory: function selectCategory(category) {
        var formField = this.get('formField');
        if (category && this.get('selectedCategory') !== category) {
          this.set('formData.' + formField.key, []);
        }
        this.set('selectedCategory', category);
      }
    }
  });
});
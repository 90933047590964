define('webapp/routes/account/newsletter/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),

    model: function model() {
      var myStorage = this.get('myStorage');
      // return myStorage.mailingList.ajaxGet({param: 'own'});
      return Ember.RSVP.hash({
        lists: myStorage.mailingList.ajaxGet(),
        own: myStorage.mailingList.ajaxGet({ param: 'own' })
      }).then(function (_ref) {
        var lists = _ref.lists,
            own = _ref.own;

        var ownIds = own.map(function (x) {
          return x._id;
        });
        lists.forEach(function (list) {
          list.subscribed = ownIds.indexOf(list._id) >= 0;
        });
        return lists;
      });
    },
    unsubscribe: function unsubscribe(list) {
      var myStorage = this.get('myStorage');
      myStorage.mailingList.ajaxPost({
        param: 'unsubscribe_logged_in',
        data: {
          mailingList: list._id
        }
      }).then(function () {
        Ember.set(list, 'subscribed', false);
      });
    },
    subscribe: function subscribe(list) {
      var myStorage = this.get('myStorage');
      myStorage.mailingList.ajaxPost({
        param: 'subscribe_logged_in',
        data: {
          mailingList: list._id
        }
      }).then(function () {
        Ember.set(list, 'subscribed', true);
      });
    },


    actions: {
      toggleSubscribe: function toggleSubscribe(list) {
        var subscribed = Ember.get(list, 'subscribed');
        if (subscribed) {
          this.unsubscribe(list);
        } else {
          this.subscribe(list);
        }
      },
      unsubscribe: function unsubscribe(list) {
        this.unsubscribe(list);
      },
      subscribe: function subscribe(list) {
        this.subscribe(list);
      }
    }
  });
});
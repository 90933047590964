define('webapp/components/press/quick-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    selection: [],
    prevInput: '',
    currentInput: '',
    suggestions: null,
    selectedIdx: -1,
    data: [],

    changeIndex: function changeIndex(no) {
      var selectedIdx = this.get('selectedIdx');
      var suggestions = this.get('suggestions');

      if (suggestions) {
        selectedIdx += no;
        if (selectedIdx < 0) selectedIdx = 0;else if (selectedIdx >= suggestions.length) selectedIdx = suggestions.length - 1;
      } else {
        selectedIdx = -1;
      }

      this.set('selectedIdx', selectedIdx);
    },
    keyDown: function keyDown(evt) {
      if (evt.keyCode === 38) {
        this.changeIndex(-1);
        evt.preventDefault();
      } else if (evt.keyCode === 40) {
        this.changeIndex(1);
        evt.preventDefault();
      } else if (evt.keyCode === 27) {
        // escape
        this.setProperties({
          suggestions: null,
          selectedIdx: -1,
          currentInput: '',
          prevInput: ''
        });
      }
    },


    inputLength: Ember.computed('currentInput.length', function () {
      return this.get('currentInput.length') + 1;
    }),

    actions: {
      inputChanged: function inputChanged(val) {
        var currentInput = this.get('currentInput');
        var prevInput = this.get('prevInput');
        var data = this.get('data');

        if (currentInput === prevInput) {
          return;
        }

        if (currentInput) {
          var re = new RegExp(currentInput, 'i');
          var suggestions = data.filter(function (x) {
            return x.match(re);
          });
          if (suggestions.length === 0) {
            suggestions = null;
          }
          this.setProperties({
            suggestions: suggestions,
            selectedIdx: -1
          });
        } else {
          this.setProperties({
            suggestions: null,
            selectedIdx: -1
          });
        }

        this.set('prevInput', currentInput);
      },
      inputEnter: function inputEnter() {
        var _getProperties = this.getProperties(['currentInput', 'suggestions', 'selectedIdx', 'selection']),
            currentInput = _getProperties.currentInput,
            suggestions = _getProperties.suggestions,
            selectedIdx = _getProperties.selectedIdx,
            selection = _getProperties.selection;

        if (selectedIdx >= 0) {
          var suggestion = suggestions[selectedIdx];

          selection.pushObject(suggestion);
          this.sendAction('selectionChange', selection);

          this.setProperties({
            suggestions: null,
            currentInput: '',
            selectedIdx: -1
          });
        } else if (currentInput.length === 0) {
          // show all suggestions
          this.set('suggestions', this.get('data'));
        }
      },
      removeItem: function removeItem(item) {
        var selection = this.get('selection');
        selection.removeObject(item);
        this.sendAction('selectionChange', selection);
      },
      selectSuggestion: function selectSuggestion(idx) {
        var suggestions = this.get('suggestions');
        var selection = this.get('selection');

        var suggestion = suggestions[idx];

        if (selection.indexOf(suggestion) < 0) {
          selection.pushObject(suggestion);
          this.sendAction('selectionChange', selection);
        }

        this.setProperties({
          selectedIdx: -1,
          currentInput: '',
          prevInput: '',
          suggestions: []
        });
      }
    }
  });
});
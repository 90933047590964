define('webapp/components/social-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    shareService: Ember.inject.service(),
    loadData: true,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var entry = this.get('entry');
      var myStorage = this.get('myStorage');
      // TODO JB mergecheck
      return;
      if (this.get('loadData')) {
        myStorage.social.ajaxGet({ param: 'count/' + entry }).then(function (res) {
          for (var item in res) {
            if (res.hasOwnProperty(item)) {
              if (!_this.isDestroyed) {
                _this.set(item, res[item]);
              }
            }
          }
        });
      } else {
        var counts = this.get('counts');
        if (!this.isDestroyed) {
          for (var item in counts) {
            if (counts.hasOwnProperty(item)) {
              this.set(item, counts[item] || 0);
            }
          }
        }
      }
    },


    actions: {
      shareDream: function shareDream(type, refEntry, refPost) {
        var _this2 = this;

        var myStorage = this.get('myStorage');
        var user = this.get('userProxy._id');
        var shareService = this.get('shareService');
        var currentURL = '/dream/' + refEntry;
        if (refPost) {
          currentURL = currentURL + '?highlight=' + refPost;
        }

        if (!this.get('fastboot.isFastBoot')) {
          shareService.shareLinkBuilder(type, '', currentURL);
        }
        myStorage.share.ajaxPost({
          param: 'by_slug/' + refEntry,
          data: {
            uid: user,
            socialMedia: type
          }
        }).then(function (res) {
          var message = _this2.get('message');
          if (res.message) {
            message.info(res.message);
          }
        });
      }
    }
  });
});
define('webapp/components/uk-offcanvas', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function parseOptions(options) {
    var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    try {
      return options ? options.startsWith('{') ? JSON.parse(options) : args.length && !options.includes(':') ? _defineProperty({}, args[0], options) : options.split(';').reduce(function (options, option) {
        var _option$split = option.split(/:(.*)/),
            _option$split2 = _slicedToArray(_option$split, 2),
            key = _option$split2[0],
            value = _option$split2[1];

        if (key && value != null) {
          options[key.trim()] = value.trim();
        }
        return options;
      }, {}) : {};
    } catch (e) {
      return {};
    }
  }

  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      var options = parseOptions(this.get('uk-offcanvas'));
      UIkit.offcanvas(this.element, options);

      if (this.offcanvasToggled) {
        this.element.addEventListener('beforeshow', function () {
          _this.offcanvasToggled(true);
        });
        this.element.addEventListener('beforehide', function () {
          _this.offcanvasToggled(false);
        });
      }
    }
  });
});
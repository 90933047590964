define('webapp/components/zen-planner', ['exports', 'webapp/utils/inject-script'], function (exports, _injectScript) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var zenJSHost = 'https://studio.zenplanner.com';
  var zenSrc = 'zenplanner/studio/target/zp-widget-direct.js';
  var zenModule = 'leadcapture';

  var defaultPartitionApiKey = '5197fd7b-cb39-49f6-b985-3fd796107f18';
  var defaultWidgetInstanceId = 'dea6657b-2b3a-4f74-a466-f4f937fd5aee';

  exports.default = Ember.Component.extend({
    partitionApiKey: '',
    widgetInstanceId: '',

    didInsertElement: function didInsertElement() {
      var partitionApiKey = this.get('partitionApiKey') || defaultPartitionApiKey;
      var widgetInstanceId = this.get('widgetInstanceId') || defaultWidgetInstanceId;
      var zenplanner = window.zenplanner = window.zenplanner || {};
      zenplanner.directLoadArgs = zenplanner.directLoadArgs || [];

      zenplanner.directLoadArgs.push({
        module: zenModule,
        partitionApiKey: partitionApiKey,
        widgetInstanceId: widgetInstanceId
      });

      var loadWidget = function loadWidget() {
        if (zenplanner && zenplanner.directLoader) {
          zenplanner.directLoader.loadWidget(zenJSHost, zenModule, partitionApiKey, widgetInstanceId);
        } else {
          console.log('Zen Planner widget failed to load.');
        }
      };
      (0, _injectScript.default)(zenJSHost + '/' + zenSrc).then(loadWidget);
    }
  });
});
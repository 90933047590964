define('webapp/components/social-url-embed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    socialService: Ember.inject.service(),
    message: Ember.inject.service(),
    i18n: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    isPastingUrl: false,

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('fastboot.isFastBoot')) {
        if (!window.twttr) {
          this.get('socialService').initTwitter();
        }
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var code = this.get('code');

      if (!this.get('fastboot.isFastBoot')) {
        if (code) {
          this.triggerRender(code);
        } else {
          this.set('rendered', false);
          this.set('embed', '');
          var tweet = document.getElementsByClassName('twitter-tweet');
          if (tweet[0]) {
            tweet[0].remove();
          }
        }
      }
    },
    triggerRender: function triggerRender(code) {
      if (code.match(/twitter/)) {
        this.renderTweet(code);
      }
      if (code.match(/youtu\.be\/|youtube/)) {
        this.renderYoutube(code);
      }
      if (code.match(/vimeo/)) {
        this.renderVimeo(code);
      }
    },
    renderTweet: function renderTweet(code) {
      var _this = this;

      var myStorage = this.get('myStorage');
      if (window.twttr && window.twttr.init) {
        var twitterArr = code.match(/https?:\/\/twitter.com\/[a-zA-Z_]{1,20}\/status\/([0-9]*)/);

        if (twitterArr) {
          myStorage.social.ajaxPost({
            param: '',
            data: {
              url: code
            }
          }).then(function (res) {
            if (!_this.get('rendered')) {
              window.twttr.widgets.createTweet(twitterArr[1], document.getElementById('preview')).then(function () {
                if (!_this.isDestroyed) {
                  _this.set('rendered', true);
                  _this.set('contentLink', res.html);
                }
              });
            }
          });
        } else {
          var message = this.get('message');
          var i18n = this.get('i18n');
          message.error(i18n.t('application.ui.invalidEmbed').toString());
        }
      }
    },
    renderYoutube: function renderYoutube(code) {
      var ytArr = code.match(/(?:https?:\/\/)(?:www\.)?(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=))([A-Za-z0-9]{10,12})/);
      if (ytArr) {
        this.set('embed', '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + ytArr[1] + '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
      } else {
        var message = this.get('message');
        var i18n = this.get('i18n');
        message.error(i18n.t('application.ui.invalidEmbed').toString());
      }
    },
    renderVimeo: function renderVimeo(code) {
      var vimeoKey = code.match(/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i)[1];
      if (vimeoKey) {
        this.set('embed', '<iframe src="https://player.vimeo.com/video/' + vimeoKey + '" width="640" height="338" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
      } else {
        var message = this.get('message');
        var i18n = this.get('i18n');
        message.error(i18n.t('application.ui.invalidEmbed').toString());
      }
    },


    actions: {
      openModal: function openModal() {
        this.set('rendered', false);
        this.set('isPastingUrl', true);
        this.triggerRender(this.get('code'));
      },
      cancelPastingUrl: function cancelPastingUrl() {
        this.set('isPastingUrl', false);
      },
      confirmCode: function confirmCode() {
        var embed = this.get('embed');
        if (embed) {
          this.set(' contentLink', embed);
        }
        this.set('isPastingUrl', false);
      }
    }
  });
});
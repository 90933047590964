define('webapp/components/detail/comparison-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),

    _removeFavorite: function _removeFavorite(id) {
      var model = [];
      var favorites = this.get('detail.model');

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = favorites[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var favorite = _step.value;

          if (id !== favorite._id) {
            model.push(favorite);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this.set('detail.model', model);
    },
    _updateFavoriteCount: function _updateFavoriteCount() {
      var event = new Event('updated-favorite-count');
      var elements = document.getElementsByClassName('tm-widget-favorites');
      var element = elements[0];
      element.dispatchEvent(event);
    },


    actions: {
      removeFavorite: function removeFavorite(favorite) {
        var _this = this;

        var id = favorite._id;
        var myStorage = this.get('myStorage');

        myStorage.favorite.ajaxDelete({ param: id }).then(function (fields) {
          _this.set('fields', fields);
          _this._removeFavorite(id);
          _this._updateFavoriteCount();
        });
      }
    }
  });
});
define('webapp/components/calendly-wrapper', ['exports', 'webapp/utils/inject-script'], function (exports, _injectScript) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      if (window.Calendly) {
        var calendlyElement = this.element.querySelector('.calendly-inline-widget');
        var url = calendlyElement.getAttribute('data-url');
        window.Calendly.initInlineWidget({
          url: url,
          parentElement: calendlyElement
        });
      } else {
        (0, _injectScript.default)('https://assets.calendly.com/assets/external/widget.js');
      }
    }
  });
});
define('webapp/utils/countries', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // not used
  var DEFAULT_COUNTRIES = exports.DEFAULT_COUNTRIES = [{ code: 'DE', group: 'DE', translationKey: 'country.germany', minActive: true }, { code: 'AT', group: 'EU', translationKey: 'country.austria', minActive: true }, { code: 'BE', group: 'EU', translationKey: 'country.belgium' }, { code: 'BG', group: 'EU', translationKey: 'country.bulgaria' }, { code: 'HR', group: 'EU', translationKey: 'country.croatia' }, { code: 'CY', group: 'EU', translationKey: 'country.cyprus' }, { code: 'CZ', group: 'EU', translationKey: 'country.czechia' }, { code: 'DK', group: 'EU', translationKey: 'country.denmark' }, { code: 'EE', group: 'EU', translationKey: 'country.estonia' }, { code: 'FI', group: 'EU', translationKey: 'country.finland' }, { code: 'FR', group: 'EU', translationKey: 'country.france' }, { code: 'GR', group: 'EU', translationKey: 'country.greece' }, { code: 'HU', group: 'EU', translationKey: 'country.hungary' }, { code: 'IE', group: 'EU', translationKey: 'country.ireland' }, { code: 'IT', group: 'EU', translationKey: 'country.italy' }, { code: 'LV', group: 'EU', translationKey: 'country.latvia' }, { code: 'LT', group: 'EU', translationKey: 'country.lithuania' }, { code: 'LU', group: 'EU', translationKey: 'country.luxembourg' }, { code: 'MT', group: 'EU', translationKey: 'country.malta' }, { code: 'NL', group: 'EU', translationKey: 'country.netherlands' }, { code: 'PL', group: 'EU', translationKey: 'country.poland' }, { code: 'PT', group: 'EU', translationKey: 'country.portugal' }, { code: 'RO', group: 'EU', translationKey: 'country.romania' }, { code: 'SK', group: 'EU', translationKey: 'country.slovakia' }, { code: 'SI', group: 'EU', translationKey: 'country.slovenia' }, { code: 'ES', group: 'EU', translationKey: 'country.spain' }, { code: 'SE', group: 'EU', translationKey: 'country.sweden' },
  // Norway is only associated, but complies with EU
  { code: 'NO', group: 'EU', translationKey: 'country.norway' },
  // Switzerland is not EU
  { code: 'CH', group: 'NONEU', translationKey: 'country.switzerland' },
  // Turkey is not EU
  { code: 'TR', group: 'NONEU', translationKey: 'country.turkey' },
  // United Kingdom is not EU
  { code: 'GB', group: 'NONEU', translationKey: 'country.unitedKingdom' }];
  // new
  var DEFAULT_COUNTRIES_GROUPED = exports.DEFAULT_COUNTRIES_GROUPED = {
    DE: [{ code: 'DE', group: 'DE', translationKey: 'country.germany' }],
    EU: [{ code: 'AT', group: 'EU', translationKey: 'country.austria' }, { code: 'BE', group: 'EU', translationKey: 'country.belgium' }, { code: 'BG', group: 'EU', translationKey: 'country.bulgaria' }, { code: 'HR', group: 'EU', translationKey: 'country.croatia' }, { code: 'CY', group: 'EU', translationKey: 'country.cyprus' }, { code: 'CZ', group: 'EU', translationKey: 'country.czechia' }, { code: 'DK', group: 'EU', translationKey: 'country.denmark' }, { code: 'EE', group: 'EU', translationKey: 'country.estonia' }, { code: 'FI', group: 'EU', translationKey: 'country.finland' }, { code: 'FR', group: 'EU', translationKey: 'country.france' }, { code: 'GR', group: 'EU', translationKey: 'country.greece' }, { code: 'HU', group: 'EU', translationKey: 'country.hungary' }, { code: 'IE', group: 'EU', translationKey: 'country.ireland' }, { code: 'IT', group: 'EU', translationKey: 'country.italy' }, { code: 'LV', group: 'EU', translationKey: 'country.latvia' }, { code: 'LT', group: 'EU', translationKey: 'country.lithuania' }, { code: 'LU', group: 'EU', translationKey: 'country.luxembourg' }, { code: 'MT', group: 'EU', translationKey: 'country.malta' }, { code: 'NL', group: 'EU', translationKey: 'country.netherlands' }, { code: 'PL', group: 'EU', translationKey: 'country.poland' }, { code: 'PT', group: 'EU', translationKey: 'country.portugal' }, { code: 'RO', group: 'EU', translationKey: 'country.romania' }, { code: 'SK', group: 'EU', translationKey: 'country.slovakia' }, { code: 'SI', group: 'EU', translationKey: 'country.slovenia' }, { code: 'ES', group: 'EU', translationKey: 'country.spain' }, { code: 'SE', group: 'EU', translationKey: 'country.sweden' }],
    NONEU:
    // Norway is only associated, but complies with EU
    [{ code: 'NO', group: 'EU', translationKey: 'country.norway' },
    // Switzerland is not EU
    { code: 'CH', group: 'NONEU', translationKey: 'country.switzerland' },
    // Turkey is not EU
    { code: 'TR', group: 'NONEU', translationKey: 'country.turkey' },
    // United Kingdom is not EU
    { code: 'GB', group: 'NONEU', translationKey: 'country.unitedKingdom' }]
  };
});
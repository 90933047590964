define('webapp/components/rating-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    message: Ember.inject.service(),
    myStorage: Ember.inject.service(),

    avgRating: Ember.computed('ratingInfos.ratings.avg', function () {
      return parseFloat(this.get('ratingInfos.ratings.avg')).toFixed(1);
    }),

    ownRatings: Ember.computed('ratingInfos.ratingTexts', function () {
      return this.get('ratingInfos.ratingTexts').map(function (text) {
        return {
          text: text,
          rating: 0
        };
      });
    }),

    disableSubmit: Ember.computed('ownRatings.@each.rating', function () {
      return !this.get('ownRatings').every(function (x) {
        return x.rating > 0;
      });
    }),

    sendRating: function sendRating(ownRatings) {
      var myStorage = this.get('myStorage');
      var parentType = this.get('parentType');
      var parentId = this.get('parentId');

      var data = { parentType: parentType, parent: parentId, detailedRatings: ownRatings };
      return myStorage.ratings.ajaxPost({ data: data });
    },


    actions: {
      setRating: function setRating(item, value) {
        Ember.set(item, 'rating', value);
      },
      submitRating: function submitRating() {
        var ownRatings = this.get('ownRatings');
        var message = this.get('message');
        this.set('ratingInfos.canWriteRating', false);

        this.sendRating(ownRatings).then(function () {
          message.info('Bewertung erfolgreich abgegegeben.');
        }).catch(function (err) {
          console.error(err.stack);
          message.error('Fehler beim Bewerten');
        });
      }
    }
  });
});
define('webapp/routes/account/documents/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),

    getFiles: function getFiles(folder) {
      var myStorage = this.get('myStorage');
      return myStorage.media.ajaxPost({
        param: 'public_folder',
        data: { folder: folder, onlyFiles: false }
      });
    },
    beforeModel: function beforeModel() {
      var userProxy = this.get('userProxy');
      var controller = this.controllerFor(this.routeName);

      var documentContainerOptions = {};
      var assignedFolders = userProxy.userGroups.filter(function (group) {
        return group.assignedFolder;
      }).map(function (group) {
        documentContainerOptions = group.options;
        return { routingPath: group.assignedFolder };
      });

      if (assignedFolders.length === 1) {
        this.transitionTo('account.documents.folder', assignedFolders[0].routingPath);
      }

      controller.set('folders', assignedFolders);
      controller.set('documentContainerOptions', documentContainerOptions);
    }
  });
});
define('webapp/components/shopping/address-form', ['exports', 'webapp/utils/countries'], function (exports, _countries) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showModal: false,
    countries: Ember.computed(function () {
      return _countries.DEFAULT_COUNTRIES;
    }),

    actions: {
      setCountryCode: function setCountryCode(countryCode) {
        this.set('address.countryCode', countryCode);
        var countryCodeChangeAction = this.get('countryCodeChanged');

        countryCodeChangeAction && countryCodeChangeAction(countryCode);
      },
      toggleModal: function toggleModal() {
        this.set('showModal', true);
      },
      cancel: function cancel() {
        this.set('showModal', false);
      }
    }
  });
});
define('webapp/controllers/account/component-press/articles', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    myStorage: Ember.inject.service(),

    searchTerm: '',
    itemStorage: Ember.computed.alias('myStorage.article'),
    searchField: ['title', 'slug', 'subtitle', 'hint'],
    filter: null, //override this
    isActiveFilter: null,
    statusFilter: null,

    hasPaging: true,
    page: 1,
    perPage: 20,
    sortBy: 'startDate',
    sortDir: 'desc',

    selectedTag: null,
    selectedAuthors: [],

    expandedItem: null,

    calendarCenter: null, // center date for calendar
    dateRange: null,

    categoryGroups: {
      group: [],
      article: []
    },

    getSort: function getSort() {
      return this.getProperties(['sortBy', 'sortDir']);
    },
    beforeQuery: function beforeQuery(query) {
      query.categoryGroups = this.get('categoryGroups');
      return query;
    },
    buildFilter: function buildFilter() {
      var staticFilter = this.get('filter');
      var filter = {};
      if (staticFilter) {
        Ember.assign(filter, staticFilter);
      }
      var categoryGroups = this.get('categoryGroups');
      var groupedCats = [categoryGroups.article, categoryGroups.group];

      groupedCats = groupedCats.filter(function (x) {
        return x && x.length;
      });
      if (groupedCats.length) {
        filter['$and'] = groupedCats.map(function (gCat) {
          return {
            categories: { $in: gCat }
          };
        });
      }

      var isActiveFilter = this.get('isActiveFilter');
      var statusFilter = this.get('statusFilter');
      if (isActiveFilter !== null) {
        filter.isActive = isActiveFilter;
      }
      if (statusFilter !== null) {
        filter.status = statusFilter;
      }

      var selectedAuthors = this.get('selectedAuthors');
      if (selectedAuthors.length) {
        filter.author = { $in: selectedAuthors };
      }

      var dateRange = this.get('dateRange');
      if (dateRange) {
        if (dateRange.start && dateRange.end) {
          filter.hotDate = {
            $gte: dateRange.start.toISOString(),
            $lte: (0, _moment.default)(dateRange.end).add(1, 'day').toISOString()
          };
        } else if (dateRange.start) {
          filter.hotDate = { $gte: dateRange.start.toISOString() };
        }
      }

      var selectedTag = this.get('selectedTag');
      if (selectedTag) {
        filter.tags = selectedTag;
      }

      if (Object.keys(filter).length) {
        return filter;
      }
    },


    selectedCategories: Ember.computed('categoryGroups.group.[]', 'categoryGroups.article.[]', function () {
      var groupCats = this.get('categoryGroups.group');
      var articleCats = this.get('categoryGroups.article');
      return [].concat(groupCats).concat(articleCats);
    }),

    categoryFilterActive: Ember.computed('categoryGroups.article.[]', 'categoryGroups.groups.[]', function () {
      return this.get('categoryGroups.article.length') || this.get('categoryGroups.groups.length');
    }),

    search: function search() {
      var _this = this;

      var query = {};
      var searchTerm = this.get('searchTerm');
      var sortBy = this.get('sortBy');

      if (sortBy) {
        var sort = this.getSort();
        Ember.setProperties(query, sort);
      }

      if (searchTerm) {
        query.search = {
          term: searchTerm,
          field: this.get('searchField')
        };
      }

      var filter = this.buildFilter();
      if (filter) {
        query.filter = filter;
      }

      if (this.get('hasPaging')) {
        Ember.setProperties(query, this.getProperties(['page', 'perPage']));
      }

      // if we would keep the selection, there could be items in this array that are not visible, dangerous!
      // other possibility is to remove all items from the selection that dont show up in searchresult
      query = this.beforeQuery(query);
      return this.get('itemStorage').ajaxPost({ param: 'search', data: query }).then(function (res) {
        // search res contains properties like {hits, model, aggregations} and so on, where model contains the list of retrieved items
        _this.setProperties(res);
        return res.model;
      });
    },


    filterActive: Ember.computed('categoryFilterActive', 'isActiveFilter', 'statusFilter', 'dateRange', 'selectedTag', 'selectedAuthors.length', function () {
      return this.get('categoryFilterActive') || this.get('isActiveFilter') !== null || this.get('statusFilter') !== null || this.get('selectedTag') !== null || this.get('dateRange') !== null || this.get('selectedAuthors.length');
    }),

    availableAuthors: Ember.computed('aggregations.authors', function () {
      return this.get('aggregations.authors').map(function (x) {
        return x.name;
      });
    }),

    actions: {
      setSort: function setSort(sortBy) {
        var oldSortBy = this.get('sortBy');
        var sortDir = this.get('sortDir');

        if (oldSortBy === sortBy) {
          this.set('sortDir', sortDir === 'asc' ? 'desc' : 'asc');
        } else {
          this.setProperties({
            sortBy: sortBy,
            sortDir: 'asc'
          });
        }

        this.set('page', 1);
        this.search();
      },
      toggleExpandedItem: function toggleExpandedItem(item) {
        var expandedItem = this.get('expandedItem');
        if (expandedItem === item) {
          expandedItem = null;
        } else {
          expandedItem = item;
        }
        this.set('expandedItem', expandedItem);
      },
      search: function search() {
        this.set('page', 1);
        this.search();
      },
      selectCategory: function selectCategory(catGroup, catId) {
        var categoryGroups = this.get('categoryGroups');
        Ember.set(categoryGroups, catGroup, [catId]);
        this.set('page', 1);
        this.search();
      },
      setIsActiveFilter: function setIsActiveFilter(isActive) {
        var isActiveFilter = this.get('isActiveFilter');
        if (isActiveFilter === isActive) {
          isActiveFilter = null;
        } else {
          isActiveFilter = isActive;
        }
        this.set('isActiveFilter', isActiveFilter);
        this.set('page', 1);
        this.search();
      },
      setPage: function setPage(page) {
        this.set('page', page + 1);
        this.search();
      },
      resetFilters: function resetFilters() {
        this.setProperties({
          categoryGroups: {
            group: [],
            article: []
          },
          isActiveFilter: null,
          statusFilter: null,
          dateRange: null,
          selectedTag: null,
          selectedAuthors: []
        });
        this.set('page', 1);
        this.search();
      },
      selectAuthors: function selectAuthors(selection) {
        this.set('selectedAuthors', selection);
        this.set('page', 1);
        this.search();
      },
      selectTag: function selectTag(tag) {
        var selectedTag = this.get('selectedTag');
        var newTag = void 0;
        if (selectedTag === tag) {
          newTag = null;
        } else {
          newTag = tag;
        }
        this.set('selectedTag', newTag);
        this.search();
      },
      selectDateRange: function selectDateRange(range) {
        this.set('dateRange', range);
        this.search();
      }
    }
  });
});
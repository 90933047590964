define("webapp/locales/pl/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.entryBannerImageNew": "Zdefiniuj główny obraz dla swojego wpisu.",
    "account.ui.profileBannerImageNew": "Ustaw główne zdjęcie dla swojego profilu.",
    "account.ui.register": "Rejestr",
    "account.ui.registeredAs": "Jesteś obecnie zarejestrowany jako",
    "account.ui.userNameNotEditableTooltip": "Twoja nazwa użytkownika. W ustawieniach można zmienić określoną nazwę użytkownika. Ważne: Po zmianie należy ponownie zalogować się przy użyciu nowej nazwy użytkownika.",
    "accountTypes.aussteller": "Wystawca",
    "accountTypes.gastgeber": "Gospodarz",
    "accountTypes.veranstalter": "Organizator",
    "application.beta.infotext": "Funkcja ta jest tylko wizualizowana i nie jest dostępna w wersji beta.",
    "application.breadcrumbsHome": "Strona główna",
    "application.month": "w skali miesiąca",
    "application.newsletter.alreadySubscribed": "Jesteś już zapisany do {{list}} Newslettery. Możesz uzyskać dostęp do subskrypcji newslettera na swoim koncie.",
    "application.newsletter.alreadySubscribedNoList": "Zapisałeś się już do newslettera",
    "application.newsletter.goToList": "Wywołaj listę mailingową",
    "application.newsletter.subscribeButton": "Podaj swój adres e-mail, aby zapisać się do newslettera",
    "application.newsletter.subscribeButtonMobile": "E-mail",
    "application.newsletter.noAccess": "Oferowany Entry Newsletter nie może być subskrybowany za zgodą użytkownika.",
    "application.newsletter.subscribe": "Subskrybuj",
    "application.newsletter.subscribeList": "Zapisałeś się do tego newslettera:",
    "application.newsletter.subscribeThanks": "Dziękuję za subskrypcję newslettera. Otrzymasz maila potwierdzającego aktywację Opt-In.",
    "application.newsletter.subscribeWithAccount": "Subskrybuj z aktualnie zalogowanym użytkownikiem.",
    "application.noCosts": "Bezpłatnie",
    "application.searchAllGroups": "ogólnie rzecz biorąc",
    "application.searchAllRegions": "we wszystkich miejscach",
    "application.ui.access": "Wezwać",
    "application.ui.action": "Działanie",
    "application.ui.addDocument": "Dodaj dokument",
    "application.ui.addRelation": "Dodaj połączenie",
    "application.ui.addImage": "Dodaj obrazek",
    "application.ui.addImages": "Dodaj obrazy",
    "application.ui.addMedia": "Dodawanie zdjęć/filmów",
    "application.ui.addVideo": "Dodaj wideo",
    "application.ui.advancedEdit": "Rozszerzony",
    "application.ui.all": "Wszystkie",
    "application.ui.alphabetical": "Alfabetycznie",
    "application.ui.at": "na stronie",
    "application.ui.back": "z powrotem",
    "application.ui.buy": "Kupić",
    "application.ui.certificate": "Certyfikaty",
    "application.ui.changesSinceLastLogin": "Nowy od ostatniego logowania",
    "application.ui.confirmDeleteEntry": "Wejście {{title}} nieodwołalnie skasować?",
    "application.ui.currentlyActive": "Biegnąc teraz",
    "application.ui.deleteEntry": "usunąć",
    "application.ui.discardUnsavedChanges": "Odrzuć zmiany i kontynuuj",
    "application.ui.discardUnsavedChangesMobile": "Odrzucić",
    "application.ui.distance": "Odległość",
    "application.ui.distant": "usunięty",
    "application.ui.dreampoints": "Dreampoints",
    "application.ui.dreampointsAllDreams": "Dreampoints of your entries",
    "application.ui.dreampointsDonate": "Dreampoints do oddania",
    "application.ui.dreampointsTooltip": "Możesz zbierać Dreampoints za pomocą swojego wpisu",
    "application.ui.edit": "Zmiana",
    "application.ui.editDescription": "Opis edycji",
    "application.ui.editEntry": "edytować",
    "application.ui.editEvent": "Wydarzenie edycyjne",
    "application.ui.editImage": "edytować obraz",
    "application.ui.editPoi": "Edycja Lokalizacja",
    "application.ui.editProduct": "Edytuj produkt",
    "application.ui.editProfile": "Edytuj mój profil",
    "application.ui.editShownProfile": "Zmień moje ogłoszenie w profilu",
    "application.ui.entriesFocus": "Wybrane wpisy w polu Focus:",
    "application.ui.entriesLatest": "Najnowsze wpisy w Focusie:",
    "application.ui.for": "dla",
    "application.ui.fullView": "Widok ogólny",
    "application.ui.gallery": "Zdjęcia",
    "application.ui.galleryImages": "do galerii...",
    "application.ui.gavePoints": "dał ci {{points}} Podane punkty.",
    "application.ui.goToEntry": "Wywołaj wpis",
    "application.ui.goToEvent": "Wywołaj wydarzenie",
    "application.ui.goToPoi": "Lokalizacja połączenia",
    "application.ui.goToProduct": "Zobacz produkt",
    "application.ui.hasBeen": "był",
    "application.ui.here": "W pobliżu",
    "application.ui.invalidEmbed": "Ten adres URL nie może być wbudowany.",
    "application.ui.isActive": "Aktywny",
    "application.ui.isNotActive": "Niepełnosprawni",
    "application.ui.knowMore": "Dowiedz się więcej o {{about}}",
    "application.ui.language.de": "Deutsch",
    "application.ui.language.en": "English",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "Italiano",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "Aktualnie wybrany język",
    "application.ui.language.switchTo": "Zmień język na ",
    "application.ui.mailBodyPost": "",
    "application.ui.mailBodyPre": "Witam, znalazłem coś ciekawego:",
    "application.ui.mailSubject": "Znalazłem ciekawy link:",
    "application.ui.mandatory": "Pole obowiązkowe",
    "application.ui.memberSince": "Członek od",
    "application.ui.mobileRotate": "Obracanie w celu uzyskania widoku pełnoekranowego",
    "application.ui.more": "więcej",
    "application.ui.navigateOneLevelUp": "Powrót do folderu głównego",
    "application.ui.new": "Nowy",
    "application.ui.newEntry": "Tworzenie nowego wpisu",
    "application.ui.newEvent": "Tworzenie nowego wydarzenia biznesowego",
    "application.ui.newPoi": "Stwórz nową lokalizację",
    "application.ui.newProduct": "Stwórz nowy produkt",
    "application.ui.noEntry": "Nie stworzyłeś jeszcze wpisu. Stwórz pierwszy wpis teraz...",
    "application.ui.noEntryProfile": "nie stworzył jeszcze wpisu.",
    "application.ui.noEvent": "Nie stworzyłeś jeszcze imprezy biznesowej. Stwórzcie teraz pierwsze wydarzenie...",
    "application.ui.noPoi": "Nie stworzyłeś jeszcze lokalizacji. Załóż teraz pierwszą lokalizację...",
    "application.ui.noJob": "Nie stworzyłeś jeszcze żadnej pracy. Stwórz swoją pierwszą pracę teraz...",
    "application.ui.noProduct": "Nie stworzyłeś jeszcze produktu. Stwórzcie pierwszy produkt już teraz...",
    "application.ui.notEditable": "nie może być zmieniony",
    "application.ui.nothingFound": "Nie mogliśmy znaleźć żadnych wyników tego wyszukiwania.",
    "application.ui.offer": "Oferty",
    "application.ui.only": "Tylko",
    "application.ui.or": "lub",
    "application.ui.openDocument": "Akta otwarte",
    "application.ui.playVideo": "Odtwarzanie wideo",
    "application.ui.readMore": "Przeczytaj więcej",
    "application.ui.region": "Region",
    "application.ui.relevance": "Znaczenie",
    "application.ui.resultsFrom": "Wyniki z",
    "application.ui.reset": "Reiniciar",
    "application.ui.routingDescription": "Wskazówki",
    "application.ui.saveShownProfile": "Zapisywanie zmian w profilu",
    "application.ui.saveUnsavedChanges": "Zapisz",
    "application.ui.showAllEntries": "Pokaż wszystkie wpisy",
    "application.ui.showAllSearch": "Pokaż wszystkie wyniki",
    "application.ui.showMoreSearch": "Pokaż więcej wyników wyszukiwania",
    "application.ui.resetSearch": "Zresetuj wybór i wyszukaj ponownie",
    "application.ui.sort": "Sortowanie",
    "application.ui.stockPhotoLinks": "Linki do baz danych obrazów",
    "application.ui.suitableFor": "Zalecany dla",
    "application.ui.total": "Łączna kwota",
    "application.ui.totalDreampoints": "Dreampoints wszystkich twoich wpisów",
    "application.ui.trySearch": "Czy chcesz spróbować tego z innymi wyszukiwanymi terminami, takimi jak",
    "application.ui.type": "Typ",
    "application.ui.unsavedChanges": "Niezapisane zmiany",
    "application.ui.updated": "Aktualizacja",
    "application.ui.uploadedFileFor": "Wgrany plik do:",
    "application.ui.userName": "Nazwa użytkownika",
    "application.ui.visibleForAll": "Widoczny dla wszystkich użytkowników",
    "application.ui.visibleForFollower": "Widoczny dla zwolenników",
    "application.ui.visibleForJoiner": "Widoczny dla stolarza",
    "application.ui.visibleForNone": "Niewidoczny dla nikogo",
    "application.ui.visibleForSupporter": "Widoczny dla kibiców",
    "application.ui.writeUs": "pisz do nas",
    "application.ui.years": "lata",
    "application.ui.yearsFew": "Wiek dziecka",
    "application.widgets.highestRatedEntries": "Większość Dreampoints",
    "application.widgets.inFocus": "Skoncentruj się na",
    "application.widgets.newestEntries": "Najnowsze wpisy",
    "blog.ui.category": "Kategoria",
    "blog.ui.categoryHeading": "Z kategorii",
    "blog.ui.lastUpdated": "Ostatnio zaktualizowany na",
    "blog.ui.openSinglePost": "Przeczytaj więcej",
    "blog.ui.publishedIn": "Opublikowane w",
    "blog.ui.subCategories": "Podkategorie",
    "blog.ui.written": "Napisane",
    "blog.ui.writtenBy": "Napisany przez",
    "buttons.alreadyMeToo": "Cieszę się, że ten wpis też ci się śni.",
    "buttons.alreadyMeTooMobile": "Cieszę się, że ten wpis też ci się śni.",
    "buttons.cancel": "Anuluj",
    "buttons.confirm": "Potwierdzić",
    "buttons.createAccount": "Skonfiguruj teraz konto wejściowe",
    "buttons.delete": "usunąć",
    "buttons.deleteFavorite": "Nie podążaj już za",
    "buttons.deleteLike": "Już nie zostało",
    "buttons.meToo": "Ja też chcę to zrobić.",
    "buttons.newPassword": "Poproś o nowe hasło",
    "buttons.ok": "Ok",
    "buttons.ready": "Zakończono",
    "buttons.save": "z wyjątkiem zmian",
    "buttons.saveMobile": "Zapisz",
    "buttons.selectThis": "Wybierz ten",
    "buttons.tryAgain": "Proszę spróbować ponownie",
    "category.ui.hideCategories": "Ukrywać podkategorie",
    "category.ui.oneLevelUp": "Powrót do kategorii nadrzędnościowej",
    "category.ui.showCategories": "Pokaż podkategorie",
    "categoryFields.additionalGauge": "",
    "categoryFields.additionalWeight": "",
    "categoryFields.attachmentWidth": "Szerokość mocowania",
    "categoryFields.booth": "",
    "categoryFields.capacity": "Pojemność",
    "categoryFields.clutch": "Złącze",
    "categoryFields.companyName": "Nazwa firmy",
    "categoryFields.customAward": "",
    "categoryFields.dealerInfo": "Informacje o sprzedawcy",
    "categoryFields.diameter": "Średnica",
    "categoryFields.displayNetPrice": "Cena netto",
    "categoryFields.electricalPower": "Moc elektryczna",
    "categoryFields.elektricAttachment": "",
    "categoryFields.engine": "Silnik",
    "categoryFields.engineDescription": "Wyposażenie silnika",
    "categoryFields.ERPname": "Nazwa w systemie zarządzania towarami",
    "categoryFields.horsePower": "Wydajność",
    "categoryFields.netWidth": "szerokość toru bez opon",
    "categoryFields.optionalDrive": "",
    "categoryFields.optionalIntake": "",
    "categoryFields.optionalPanning": "",
    "categoryFields.packingUnit": "Jednostka opakowaniowa",
    "categoryFields.PTO": "przystawka odbioru mocy",
    "categoryFields.relativeGauge": "Poszerzyć tor o",
    "categoryFields.sectionWidth": "",
    "categoryFields.vendorAcronym": "Krótki opis",
    "categoryFields.volume": "Tom",
    "categoryFields.weight": "Waga",
    "categoryFields.wheelLock": "Blokada kół stojących",
    "comments.blockComments": "Komentarz do tego stanowiska został zakończony.",
    "comments.comment.anonymous": "Anonimowy",
    "comments.comment.button": "Napisz nowy komentarz",
    "comments.comment.buttonReply": "Odpowiedzi",
    "comments.comment.cancel": "Anuluj",
    "comments.comment.content": "Twój komentarz",
    "comments.comment.hasCommented": "Uwagi",
    "comments.comment.login": "Zaloguj się, aby dodać komentarz",
    "comments.comment.past": "ma",
    "comments.comment.replyFrom": "odpowiedź na",
    "comments.comment.showReplies": "Zobacz odpowiedzi",
    "comments.comment.showReply": "Pokaż odpowiedź",
    "comments.comment.submit": "Zgłoś się",
    "comments.comment.title": "Tytuł komentarza",
    "comments.comment.userName": "twoja nazwa użytkownika",
    "comments.heading": "Propozycje, uwagi, komentarze",
    "comments.hierarchy.flat": "Flat",
    "comments.hierarchy.nested": "Genested",
    "comments.hierarchy.title": "Widok komentarza:",
    "comments.message.afterSaveError": "Twój komentarz nie może być w tej chwili zapisany!",
    "comments.message.afterSaveSuccess": "Twój komentarz został zapisany.",
    "comments.message.afterSaveUnlock": "Twój komentarz został zapisany, ale nadal musi być przejrzany przez administratora.",
    "comments.noComments": "Nie ma jeszcze żadnych uwag",
    "comments.reply": "AN:",
    "comments.show": "Pokaż komentarze",
    "comments.title": "Komentarze",
    "entries.edit": "Wejście: <strong>{{title}}</strong> edytować",
    "entries.entries": "Wpisy",
    "entries.entry.fields.address.city": "Miasto",
    "entries.entry.fields.address.country": "Kraj",
    "entries.entry.fields.address.houseNumber": "Numer domu",
    "entries.entry.fields.address.street": "Ulica",
    "entries.entry.fields.address.zip": "POSTCODE",
    "entries.entry.fields.language": "Język",
    "entries.entry.fields.prefixMissing": "Poniższe pola nie są wypełnione:",
    "entries.entry.profile.edit": "Profil edycyjny",
    "entries.entry.profile.publicProfile": "do mojej publicznej strony profilu",
    "entries.entry.tooltip.alreadyCopied": "Już marzysz o tym wpisie.",
    "entries.entry.tooltip.alreadyFollow": "Podążasz już za tym wpisem",
    "entries.entry.tooltip.alreadyLiked": "Możesz już mieć ten wpis",
    "entries.entry.tooltip.copied": "Cieszę się, że ty też chcesz marzyć o tym wpisie. Twoje zdjęcie profilowe zostało dodane do tego wpisu.",
    "entries.entry.tooltip.filterComments": "Kliknij tutaj, aby pokazać tylko komentarze w Entryline",
    "entries.entry.tooltip.filterFollows": "Kliknij tutaj, aby w polu Entryline pokazać tylko następujące elementy",
    "entries.entry.tooltip.filterLikes": "Kliknij tutaj, aby pokazać tylko Likes in the Entryline",
    "entries.entry.tooltip.filterShares": "Kliknij tutaj, aby wyświetlić tylko akcje w linii wejściowej",
    "entries.entry.tooltip.follow": "Kliknij tutaj, aby śledzić ten wpis",
    "entries.entry.tooltip.like": "Kliknij tutaj, aby nadać temu wpisowi charakter",
    "entries.entry.tooltip.noFollow": "Śledzić tylko prace dla wpisów zagranicznych",
    "entries.entry.tooltip.noFollowNotLoggedIn": "Musisz być zalogowany, aby postępować zgodnie z tym wpisem",
    "entries.entry.tooltip.noLike": "Jak działa tylko w przypadku wpisów zagranicznych",
    "entries.entry.ui.access": "This entry can only be seen by",
    "entries.entry.ui.added": "...został dodany.",
    "entries.entry.ui.addEntry": "Dodaj wpisy",
    "entries.entry.ui.addOpening": "Dodaj czas otwarcia",
    "entries.entry.ui.address": "Dane adresowe",
    "entries.entry.ui.addStep": "Dodaj krok",
    "entries.entry.ui.agreementHours": "Otwarte po uzgodnieniu",
    "entries.entry.ui.allAccess": "Wszyscy mogą zobaczyć ten wpis",
    "entries.entry.ui.assignedCategories": "przypisane kategorie",
    "entries.entry.ui.assignedCertificates": "Moje certyfikacje",
    "entries.entry.ui.assignedProducts": "przypisane produkty",
    "entries.entry.ui.businessContact": "Dane kontaktowe firmy",
    "entries.entry.ui.categories": "Kategorie dla tego wpisu",
    "entries.entry.ui.categoryFields": "Dalsze szczegóły",
    "entries.entry.ui.commercialTitle": "Informacje o firmie na wpisie",
    "entries.entry.ui.companyTitle": "Dane mojej firmy",
    "entries.entry.ui.contactPerson": "Moje dane kontaktowe",
    "entries.entry.ui.cssClass": "Klasa CSS dla zawartości",
    "entries.entry.ui.deliveryInfo": "Informacje o usłudze dostawy",
    "entries.entry.ui.descriptionInfo": "Wskazówka: W przypadku wybrania słów można przypisać im style.",
    "entries.entry.ui.descriptionPlaceholder": "Opis za pomocą HTML. Podwójne kliknięcie na tekst dla paska funkcji HTML (maksymalnie 600 znaków).",
    "entries.entry.ui.editEntry": "Wpis do edycji",
    "entries.entry.ui.editEntryNew": "Tworzenie wpisu",
    "entries.entry.ui.exclusionHours": "Okresy wyłączenia",
    "entries.entry.ui.galleryImages": "Galeria zdjęć (2 lub 3 zdjęcia w zależności od ustawienia)",
    "entries.entry.ui.globalTitle": "Nazwa",
    "entries.entry.ui.hasKiosk": "Mam stoisko",
    "entries.entry.ui.imagesHorizontal": "Horyzontalny",
    "entries.entry.ui.imagesInfo": "Obrazy można edytować w sekcji \"Obrazy/Klipy wideo/Dokumenty\".",
    "entries.entry.ui.imagesOrientation": "Wyrównanie obrazu",
    "entries.entry.ui.imagesSquared": "Plac",
    "entries.entry.ui.imagesVertical": "Pionowo",
    "entries.entry.ui.isListImage": "Ten ekran jest ekranem listy",
    "entries.entry.ui.isMainImage": "Ten ekran jest ekranem głównym",
    "entries.entry.ui.isMainImageAndListImage": "Ten ekran jest wyświetlany jako główny ekran wpisu",
    "entries.entry.ui.kioskDescription": "Opis Państwa stanowiska sprzedaży",
    "entries.entry.ui.listImage": "Ekran listy",
    "entries.entry.ui.logo": "Logo",
    "entries.entry.ui.longDescription": "Długi tekst opisu",
    "entries.entry.ui.mainImage": "Główny obraz",
    "entries.entry.ui.makeListImage": "Ustaw ten ekran jako ekran listy",
    "entries.entry.ui.makeListImageTooltip": "Wyniki wyszukiwania są wyświetlane w postaci listy z ekranem listy. Jeśli nie jest wybrany żaden ekran listy, używany jest ekran główny.",
    "entries.entry.ui.makeMainImage": "Ustaw ten ekran jako ekran główny",
    "entries.entry.ui.makeMainImageTooltip": "Główne zdjęcie jest wyświetlane w górnej części profilu. Obrazy, które nie zostały wybrane jako obrazy główne lub listy, są wyświetlane w galerii obrazów.",
    "entries.entry.ui.metaDescription": "Krótki opis",
    "entries.entry.ui.metaDescriptionPlaceholder": "Krótki opis zawierający maksymalnie 200 znaków",
    "entries.entry.ui.multiCode": "Dodatkowy kod dla tego etapu",
    "entries.entry.ui.multiDescription": "Opis tego etapu",
    "entries.entry.ui.multiImages": "Zdjęcia do tego etapu",
    "entries.entry.ui.multiProgress": "Postępy",
    "entries.entry.ui.multiTitle": "Tytuł dla tego etapu",
    "entries.entry.ui.multiTitlePlaceholder": "Tytuł dla tego etapu (maksymalnie 50 znaków)",
    "entries.entry.ui.noDescriptions": "Wpis nie posiada jeszcze żadnych tekstów opisowych.",
    "entries.entry.ui.noEntries": "Nie znaleziono żadnych wpisów.",
    "entries.entry.ui.onlyVisibleForCustomers": "Ten wpis jest widoczny tylko dla zarejestrowanych klientów",
    "entries.entry.ui.openAt": "Otwarte",
    "entries.entry.ui.openingHours": "Godziny otwarcia",
    "entries.entry.ui.openingInfo": "Komentarze dotyczące godzin otwarcia",
    "entries.entry.ui.openingReligious": "Adres w parafii w przeważającej mierze katolickiej",
    "entries.entry.ui.overallProgress": "This entry is to {{value}}% zrealizowany",
    "entries.entry.ui.parking": "Obiekty parkingowe",
    "entries.entry.ui.parkingLots": "Ilość",
    "entries.entry.ui.parkingType": "Gatunek",
    "entries.entry.ui.personImage": "Zdjęcie osoby kontaktowej",
    "entries.entry.ui.relatedEvents": "Wydarzenia powiązane",
    "entries.entry.ui.rentKiosk": "Chciałbym wynająć stanowisko sprzedaży",
    "entries.entry.ui.resolveAddress": "Ustalenie współrzędnych",
    "entries.entry.ui.resolveAddressInfo": "Proszę określić Geopunkt (pozycję na mapie) na podstawie swojego adresu. Bez prawidłowego Geopunktu mogą nie zostać znalezione poprawnie.",
    "entries.entry.ui.resolveGeolocation": "Ustalić adres",
    "entries.entry.ui.routingPlaceholder": "Podaj tutaj swoje wskazówki używając HTML.",
    "entries.entry.ui.selectEvent": "Wybierz wydarzenie",
    "entries.entry.ui.shortDescriptionInfo": "Jeśli krótki opis",
    "entries.entry.ui.sharing": "Udostępnij tę stronę na {{sharing}}",
    "entries.entry.ui.shareMail": "Share this page by email",
    "entries.entry.ui.skillsNeeded": "Na mój wpis, naprawdę przydałoby mi się trochę...",
    "entries.entry.ui.sortword": "Alternatywne tytuły do sortowania",
    "entries.entry.ui.step": "Krok",
    "entries.entry.ui.stepInDream": "W którym etapie jest ten krok?",
    "entries.entry.ui.stepInDreamAfter": "po zrealizowaniu",
    "entries.entry.ui.stepInDreamBefore": "przed realizacją",
    "entries.entry.ui.stepInDreamDuring": "w trakcie realizacji",
    "entries.entry.ui.subtitle": "Podtytuł opisowy",
    "entries.entry.ui.subtitlePlaceholder": "Wprowadź podtytuł (maksymalnie 60 znaków)",
    "entries.entry.ui.tabCategories": "Kategorie",
    "entries.entry.ui.tabCategoriesMobile": "Kat.",
    "entries.entry.ui.tabCompany": "Dane firmy",
    "entries.entry.ui.tabDescription": "Opis",
    "entries.entry.ui.tabDescriptionMobile": "opis",
    "entries.entry.ui.tabExhibitor": "Dane dotyczące wystawcy",
    "entries.entry.ui.tabImages": "Obrazy/Klipy wideo/Dokumenty",
    "entries.entry.ui.tabImagesMobile": "Zdjęcia/wideo/pliki",
    "entries.entry.ui.tabRelations": "Połączenia",
    "entries.entry.ui.tags": "Tagi",
    "entries.entry.ui.title": "Wpis ten pojawia się pod następującymi nazwami",
    "entries.entry.ui.titleError": "Wpis musi być nazwany przed zapisaniem",
    "entries.entry.ui.titlePlaceholder": "Wpisz tytuł (maksymalnie 50 znaków)",
    "entries.entry.ui.visible": "Ten wpis jest widoczny",
    "entries.entry.ui.promotion": "Udział w akcjach dla tego wpisu",
    "entries.entry.ui.regions": "Kategorie regionalne dla tego wpisu",
    "entries.job.ui.promotion": "Udział w akcjach dla tej pracy",
    "entries.event.edit": "Wydarzenie <strong>{{title}}</strong> edytować",
    "entries.event.ui.addEvent": "Dodaj wydarzenie",
    "entries.event.ui.cooperation": "Partner współpracujący",
    "entries.event.ui.directions": "Wskazówki",
    "entries.event.ui.editEvent": "Wydarzenie edycyjne",
    "entries.event.ui.editEventNew": "Tworzenie wydarzenia biznesowego",
    "entries.event.ui.endDate": "Koniec zdarzenia (dzień)",
    "entries.event.ui.eventDone": "Sprawozdanie z targów",
    "entries.event.ui.noEvents": "Nie stwierdzono żadnych zdarzeń",
    "entries.event.ui.openEvent": "Wywołaj wydarzenie",
    "entries.event.ui.opening": "Godziny otwarcia",
    "entries.event.ui.startDate": "Początek zdarzenia (dzień)",
    "entries.event.ui.tabCategories": "Kategorie",
    "entries.event.ui.tabDescription": "Opis",
    "entries.event.ui.tabOpening": "Data i godzina",
    "entries.event.ui.tabRelations": "Połączenia",
    "entries.event.ui.title": "Zdarzenie pojawia się pod następującymi nazwami",
    "entries.event.ui.upcoming": "W skrócie",
    "entries.event.ui.moreEvents": "Inne wydarzenia",
    "entries.open": "Wywołanie wpisu w przedniej części",
    "entries.person.ui.company": "Firma",
    "entries.person.ui.female": "Pani",
    "entries.person.ui.fullName": "Imię i nazwisko",
    "entries.person.ui.fullSize": "Pełny rozmiar",
    "entries.person.ui.gender": "Płeć",
    "entries.person.ui.greeting": "Powitanie",
    "entries.person.ui.male": "Pan",
    "entries.person.ui.open": "Przejdź bezpośrednio do wejścia tej osoby",
    "entries.person.ui.personAddress": "Adres osoby",
    "entries.person.ui.personContact": "Osobiste dane kontaktowe",
    "entries.person.ui.personImage": "Zdjęcie osoby",
    "entries.person.ui.position": "Pozycja/typu",
    "entries.person.ui.quote": "Cytat/kapitulacja",
    "entries.person.ui.signature": "Podpisane zdjęcie",
    "entries.poi.ui.title": "Miejsce pojawia się pod następującymi nazwami",
    "entries.product.edit": "Produkt <strong>{{title}}</strong> edytować",
    "entries.product.fields.engine": "Silnik",
    "entries.product.fields.horsePower": "Wydajność",
    "entries.product.menu.allAttachments": "Wszystkie załączniki w przeglądzie",
    "entries.product.menu.allCarriers": "Wszystkie pojazdy nośne w zestawieniu",
    "entries.product.menu.attachments": "Załączniki",
    "entries.product.menu.motorHoes": "Motocykle",
    "entries.product.menu.carriers": "pojazdy nośne",
    "entries.product.tags.eco": "eko",
    "entries.product.tags.neu": "nowy",
    "product.ui.content": "Spis treści",
    "product.ui.ingredients": "Elementy",
    "product.ui.ingredientsFood": "Składniki",
    "product.ui.nonPhysical": "Pobierz produkt elektroniczny",
    "entries.product.ui.addProduct": "Dodaj produkt",
    "entries.product.ui.equipmentVariants": "Warianty wyposażenia",
    "entries.product.ui.variants": "Warianty",
    "entries.product.ui.gauge": "Szerokość w cm",
    "entries.product.ui.gaugeMin": "Minimalny rozstaw kół w cm",
    "entries.product.ui.inclusive": "W tym",
    "entries.product.ui.live": "{{name}} widzieć na żywo",
    "entries.product.ui.netPrize": "Cena netto plus podatek od wartości dodanej. Bez specjalnego wyposażenia i akcesoriów. Ex works Entschenreuth, plus koszty opakowania, frachtu, ubezpieczenia transportu, montażu i uruchomienia.",
    "entries.product.ui.noProducts": "Nie znaleziono żadnych produktów",
    "entries.product.ui.noPurchaseOnThisPage": "Jeśli nie ma możliwości złożenia zamówienia na tej stronie, prosimy o wywołanie produktu.",
    "entries.product.ui.onlyBasicVariant": "Tylko w konfiguracji podstawowej",
    "entries.product.ui.onlyWithCarrier": "Dostępne tylko przy zakupie jednostki podstawowej",
    "entries.product.ui.order": "Dodaj do koszyka",
    "entries.product.ui.overview": "Przegląd",
    "entries.product.ui.prizeFrom": "z",
    "entries.product.ui.noPrize": "O cenę należy zapytać osobę kontaktową.",
    "entries.product.ui.required": "Tylko z",
    "entries.product.ui.suitableFor": "Nadaje się do",
    "entries.product.ui.suitableForAttachments": "Nadaje się do następujących załączników",
    "entries.product.ui.suitableForCarriers": "Nadaje się do następujących pojazdów nośnych",
    "entries.product.ui.title": "Produkt",
    "entries.product.ui.variant": "Wersja/Pakiet",
    "entries.product.ui.variantNotOrderable": "Wybranego wariantu nie można zamówić",
    "entries.product.ui.chooseVariant": "Wybierz wariant",
    "entries.product.ui.chooseVariantMobile": "Wybierz wariant",
    "entries.product.ui.combinationNotAvailable": "Kombinacja nie jest dostępna",
    "entries.progress.title": "Jak poprawić swój wpis",
    "entries.tabbar.basics": "Podstawowe dane",
    "entries.tabbar.entry": "Dane wejściowe",
    "entries.tabbar.gallery": "Zdjęcia",
    "entries.tabbar.layout": "Layout",
    "entries.tabbar.openingHours": "Godziny otwarcia",
    "entries.tabbar.order": "Informacje o zamówieniu",
    "entries.tabbar.tooltip.entryNotSavedDetail": "Dane wejściowe mogą być edytowane tylko po zapisaniu wpisu z tytułem.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "Dane układu mogą być edytowane tylko po zapisaniu wpisu z tytułem.",
    "entries.types.poi": "Poi",
    "entries.types.standard": "Standard",
    "entries.ui.groupDetailName": "Nazwa dla wpisów grupowych",
    "entries.ui.groupName": "Nazwa grupy (regionu)",
    "entryTypes.entry": "Wejście:",
    "entryTypes.event": "Wydarzenie",
    "entryTypes.poi": "Miejsce:",
    "entryTypes.product": "Produkt",
    "errors.blank": "{{description}} musi być wypełniony",
    "errors.description": "To pole",
    "errors.email": "{{description}} musi to być ważny adres e-mail",
    "errors.empty": "{{description}} nie może być pusty",
    "errors.exclusion": "{{description}} jest zarezerwowany",
    "errors.inclusion": "{{description}} nie jest wymieniony w wykazie",
    "errors.invalid": "{{description}} jest niepełnosprawny",
    "errors.mandatory": "{{field}} musi być wypełniony",
    "errors.mandatoryCheckbox": "Do potwierdzenia.",
    "errors.mandatorySubmit": "Formularz można wysłać tylko wtedy, gdy wszystkie obowiązkowe pola zostaną wypełnione.",
    "errors.slug": "Wtyczka może zawierać tylko małe litery, cyfry i \"-\".",
    "firstname": "Imię",
    "forms.ui.deleteFile": "Usuń plik",
    "forms.ui.fieldBuilder.city": "Miejsce:",
    "forms.ui.fieldBuilder.country": "Kraj",
    "forms.ui.fieldBuilder.houseNumber": "Nie.",
    "forms.ui.fieldBuilder.street": "Ulica",
    "forms.ui.fieldBuilder.zip": "POSTCODE",
    "forms.ui.formErr": "Błąd podczas wysyłania formularza",
    "forms.ui.formSent": "Formularz wysłany",
    "forms.ui.noForm": "Brak przypisanego formularza",
    "forms.ui.invalidMail": "Proszę podać prawidłowy adres e-mail.",
    "forms.ui.isSubmitted": "Formularz został wysłany. Skontaktujemy się z Państwem tak szybko, jak to możliwe.",
    "forms.ui.missingFields": "Następujące pola muszą być wypełnione:",
    "forms.ui.uploadSuccess": "{{file}} został załadowany",
    "media.documents.search.noResult": "Proszę wprowadzić numer seryjny lub wybrać kategorię.",
    "media.documents.search.placeholder": "Wyszukiwanie numeru seryjnego lub numeru typu urządzenia",
    "media.documents.search.reset": "Resetowanie wyników wyszukiwania",
    "media.ui.copyright": "Copyright",
    "media.ui.cropFormat": "Specyfikacja formatu",
    "media.ui.cropImage": "Cięcie",
    "media.ui.cropImageDuplicate": "Duplikowanie i cięcie",
    "media.ui.description": "Opis",
    "media.ui.filename": "Nazwa pliku",
    "media.ui.imageSaved": "Obraz zapisany",
    "media.ui.processingImage": "Obraz zostanie przycięty, proszę czekać",
    "media.ui.resetCrop": "Resetowanie cięcia",
    "media.ui.resetImage": "Powrót do oryginału",
    "media.ui.rotateImage": "Obróć obraz o 90° zgodnie z ruchem wskazówek zegara",
    "media.ui.title": "Tytuł",
    "name": "Nazwa",
    "openingHours.additionalInfo": "Informacje o godzinach otwarcia",
    "openingHours.addOpening": "Dodaj czas otwarcia",
    "openingHours.date.day.di": "Tue",
    "openingHours.date.day.do": "Thu",
    "openingHours.date.day.fr": "Fri",
    "openingHours.date.day.mi": "Ślub",
    "openingHours.date.day.mo": "Mon",
    "openingHours.date.day.sa": "Sat",
    "openingHours.date.day.so": "Więc",
    "openingHours.dateS": "Data",
    "openingHours.delete": "Skreślić",
    "openingHours.from": "z",
    "openingHours.options": "Obszar",
    "openingHours.time": "Czas",
    "openingHours.to": "do",
    "openingHours.type": "Rodzaj godzin otwarcia",
    "opt-in.error-can-not-update-member": "Zmiany w danych członków dla {{email}} nie mógł być przeprowadzony.",
    "opt-in.error-list-does-not-exist": "Nie znaleziono żadnej listy dla określonych danych.",
    "opt-in.error-list-does-not-need-validation": "Podana lista nie ma opcji \"opt-int\".",
    "opt-in.error-member-not-in-list": "Dla określonych danych nie znaleziono żadnego członka listy.",
    "opt-in.success-member-verified": "{{email}} stał się {{listname}} Lista dodana.",
    "opt-out.error-can-not-update-member": "Zmiany w danych członków dla {{email}} nie mógł być przeprowadzony.",
    "opt-out.error-list-does-not-exist": "Nie znaleziono żadnej listy dla określonych danych.",
    "opt-out.error-list-does-not-have-opt-out": "Podana lista nie posiada klauzuli opt-out.",
    "opt-out.error-member-not-in-list": "Dla określonych danych nie znaleziono żadnego członka listy.",
    "opt-out.success-membership-terminated": "{{email}} została zatwierdzona przez {{listname}} Usunięty.",
    "password.errors.not-secure-enough": "Zalecamy hasło o długości co najmniej 8 znaków, dużych i małych liter, cyfr i znaków specjalnych ($@$!%*#?&amp;).",
    "password.errors.password-and-conf-do-not-match": "Te dwa hasła muszą być identyczne.",
    "password.quality.0": "Bardzo źle",
    "password.quality.1": "Zły",
    "password.quality.2": "Wystarczająco dużo",
    "password.quality.3": "Dobrze",
    "password.quality.4": "Bardzo dobry",
    "profile.entries.copycats": "Wpisy, które {{userName}} chce też zrobić",
    "profile.entries.donates": "Wpisy, które {{userName}} Podpory",
    "profile.entries.entries": "Wpisy z {{userName}}",
    "profile.entries.follows": "Wpisy, do których {{userName}} Następuje",
    "profile.entries.joins": "Wpisy, dla których {{userName}} przyłączyć się",
    "profile.marketplace.fields.energyRequired": "wymagania dotyczące mocy",
    "profile.marketplace.fields.livingAnimals": "Zabieranie żywych zwierząt",
    "profile.marketplace.fields.loadingUnloading": "Niezbędne do przylotu/odjazdu",
    "profile.marketplace.fields.maxRange": "Dla mojej oferty mam już tyle kilometrów do przejechania",
    "profile.marketplace.fields.placeRequired": "Wymagania dotyczące miejsca parkingowego",
    "profile.marketplace.fields.securityRequired": "Potrzeba bezpieczeństwa",
    "profile.marketplace.fields.shows": "Oferta programowa",
    "profile.marketplace.fields.spaceRequired": "Zapotrzebowanie na powierzchnię w m²",
    "profile.marketplace.fields.waterRequired": "Zapotrzebowanie na wodę w m³",
    "profile.marketplace.new": "Nowa podaż lub popyt",
    "profile.marketplace.offer.delete": "Skreślić",
    "profile.marketplace.offer.description": "Opis",
    "profile.marketplace.offer.descriptionPlaceholder": "Znaczący opis, aby inny użytkownik mógł coś z nim zrobić.",
    "profile.marketplace.offer.new": "Stwórz nową ofertę",
    "profile.marketplace.offer.relatedEntry": "Przypisany wpis",
    "profile.marketplace.offer.tags": "Znaczniki oferty/zapytania (będą znajdować się lepiej)",
    "profile.marketplace.offer.title": "Oferuję",
    "profile.marketplace.request.delete": "Usuń wniosek",
    "profile.marketplace.request.new": "Tworzenie nowego wniosku",
    "profile.marketplace.request.title": "Potrzebuję",
    "profile.messages.approve": "Potwierdzić",
    "profile.messages.as": "jak",
    "profile.messages.commentPre": "ma",
    "profile.messages.commentSuff": "Uwagi",
    "profile.messages.decided": "dekret",
    "profile.messages.deletedUser": "Usunięty użytkownik",
    "profile.messages.donateDream": "Dziękujemy bardzo za wsparcie tego wpisu słowami i czynami, aby zrealizować jego założenia. Natychmiast otrzyma twoją wiadomość i miejmy nadzieję, że wróci do ciebie i przyjmie twoją pomoc.",
    "profile.messages.dreamer": "Zwolennicy",
    "profile.messages.dreamToo": "również marzyć",
    "profile.messages.enter": "dołączony do",
    "profile.messages.favoriteDream": "Podążasz teraz za tym wpisem.",
    "profile.messages.follow": "Następuje",
    "profile.messages.has": "stał się",
    "profile.messages.is": "jest",
    "profile.messages.join": "Dołącz do",
    "profile.messages.joinDream": "Chcesz marzyć o tym wpisie.",
    "profile.messages.likeDream": "Podoba ci się teraz ten wpis.",
    "profile.messages.sendText": "Twoja wiadomość została wysłana.",
    "profile.messages.shareDream": "Udało ci się podzielić tym wpisem.",
    "profile.messages.supporter": "Zwolennicy",
    "profile.messages.want": "chciałby",
    "profile.messages.youAre": "Oni są",
    "profile.messages.youWant": "Chciałbyś",
    "profile.newsletter.notSubscribed": "zapisać się do",
    "profile.newsletter.privacyInformation": "Klikając na \"zaprenumeruj\" zgadzasz się, że będziemy przesyłać Ci przyszłe newslettery w ramach tej subskrypcji (Opt-In). W każdej chwili można zrezygnować z subskrypcji newslettera, klikając ponownie na \"Zapisz się\" (Opt-Out).",
    "profile.newsletter.subscribed": "Subskrybowany",
    "profile.newsletter.noSubscription": "Nie zapisałeś się jeszcze na listę newsletterów.",
    "profile.newsletter.list.tooltip": "Zobacz newsletter z tej listy",
    "profile.newsletter.table.date": "Data wysłania",
    "profile.newsletter.table.title": "Tytuł Newslettera",
    "profile.press.articleDetail": "Szczegóły pozycji",
    "profile.press.downloadImage": "Pobierz zdjęcie",
    "profile.press.downloadImages": "zdjęcia do pobrania",
    "profile.press.downloadText": "tekst eksportowy",
    "profile.press.filterAuthor": "Filtr autorstwa",
    "profile.press.hide": "Kryjówka",
    "profile.press.show": "Wystawa",
    "profile.progress.title": "Jak poprawić swój profil",
    "register.ui.addressData": "Dane adresowe",
    "register.ui.addressInfo": "Nie musisz podawać swojego osobistego adresu. Prowadzi to jednak do prawidłowych obliczeń trasy i odległości.",
    "register.ui.companyData": "dane dotyczące Twojej firmy",
    "register.ui.companyDataMandatory": "Musisz wpisać nazwę swojej firmy",
    "register.ui.emptyFirstName": "Musisz podać swoje imię",
    "register.ui.emptyLastName": "Musisz podać swoje nazwisko",
    "register.ui.emptyMail": "Musisz podać prawidłowy adres e-mail",
    "register.ui.emptyUserName": "Musisz podać nazwę użytkownika",
    "register.ui.language": "Język",
    "register.ui.loginData": "Dane rejestracyjne",
    "register.ui.password": "Hasło",
    "register.ui.passwordRepeat": "powtórzyć hasło",
    "register.ui.personalData": "Twoje dane osobowe",
    "register.ui.takenEmail": "Ten e-mail został już wprowadzony przez użytkownika.",
    "register.ui.takenUserName": "Ta nazwa użytkownika jest już przypisana",
    "register.ui.timezone": "Strefa czasowa",
    "register.ui.typeMinimum": "Musisz wybrać co najmniej jeden typ",
    "register.ui.usernameInfo": "Ważne: To jest twoja nazwa użytkownika do logowania.",
    "search.allDistances": "Wszystkie odległości",
    "search.andFilter": "i ustawienia filtrów",
    "search.closeTags": "Ukrycie znaczników",
    "search.for": "do",
    "search.resetPosition": "Pozycja resetowania",
    "search.geoPositionBrowser": "Określanie pozycji przez przeglądarkę",
    "search.geoPositionFault": "Ustalenie pozycji przez przeglądarkę nie jest możliwe",
    "search.geoPositionTitle": "Określanie pozycji do wyszukiwania w pobliżu",
    "search.hits": "Przeboje",
    "search.openTags": "Pokaż Tagi",
    "search.removeDatefilter": "Wyjąć filtr daty",
    "search.resulted": "skutkujący",
    "search.resultType.category": "Kategoria",
    "search.resultType.entry": "Dealer",
    "search.resultType.event": "Wydarzenie",
    "search.resultType.group": "Grupa",
    "search.resultType.page": "Strona",
    "search.resultType.person": "Osoba",
    "search.resultType.post": "Artykuł",
    "search.resultType.product": "Produkt",
    "search.search": "Szukaj",
    "search.searchBarPlaceholder": "przeszukać...",
    "search.searchBarPlaceholderEntries": "Wyszukaj nazwę lub adres sprzedawcy.",
    "search.searchBarPlaceholderEntriesPosition": "Określ pozycję do wyszukiwania w pobliżu.",
    "search.searchBarPlaceholderGeoSearch": "Podaj swój adres...",
    "search.searchBarPlaceholderMobile": "przeszukać...",
    "search.searchBarPlaceholderProducts": "Wyszukiwanie produktów",
    "search.searchBarPositionTooltip": "Ustalenie pozycji lub wprowadzenie lokalizacji z adresem",
    "search.showAll": "Pokaż wszystko",
    "social.comment": "Komentarz",
    "social.Comment": "Komentarz",
    "social.CopycatJoiner": "Śnij o swoim wpisie",
    "social.dream.donateAmount": "Wsparcie finansowe",
    "social.dream.donateDreampoints": "Wsparcie za pomocą Dreampoints",
    "social.dream.donateInfo": "Wiadomość jest prywatna i może być odczytana tylko przez właściciela wpisu.",
    "social.dream.donateTip": "Wsparcie słowami i czynami",
    "social.dream.done": "SPORZĄDZONO",
    "social.dream.edit": "Wpis do edycji",
    "social.dream.findDreams": "Znajdź tutaj małe i duże wpisy i spraw, aby Twój wpis był prawdziwy i/lub pomóż innym w realizacji ich wpisów.",
    "social.dream.followedDreams": "Wpisy, które śledzisz",
    "social.dream.giveEmail": "Podziel się moim adresem e-mail",
    "social.dream.givePhone": "Podziel się moim numerem telefonu",
    "social.dream.joinerApproved": "Uczestniczysz w tym wpisie",
    "social.dream.joinerButton": "Przyłącz się do tego wpisu",
    "social.dream.joinerButtonMobile": "Przyłączyć się",
    "social.dream.joinerDream": "Grupowe marzenie",
    "social.dream.joinerInfo": "Jeśli właściciel zgłoszenia potwierdzi Twój udział, zgłoszenie to staje się grupowym marzeniem, a Ty masz dalsze prawa.",
    "social.dream.joinerMessage": "Wpisz tutaj swoją wiadomość dla właściciela wpisu:",
    "social.dream.joinerPending": "Właściciel zgłoszenia musi jeszcze potwierdzić swój udział",
    "social.dream.joinerSupport": "Popierasz ten wpis",
    "social.dream.joinerSupportButton": "Poprzyj ten wpis",
    "social.dream.joinerSupportButtonMobile": "Wsparcie",
    "social.dream.joinerSupportInfo": "Nieustannie pracujemy nad usprawnieniami, takimi jak wsparcie finansowe czy punkty wejścia. Trzymajcie się.",
    "social.dream.joinerSupportPending": "Właściciel wpisu musi jeszcze zezwolić na wasze wsparcie.",
    "social.dream.member": "Członek",
    "social.dream.members": "Członkowie",
    "social.dream.meToo": "Chcę zrobić",
    "social.dream.meTooInfo": "Pokaż właścicielowi wpisu, że wpis Ci się podoba i chcesz go dokonać.",
    "social.dream.noFollow": "Nie idziesz za żadnym wpisem!",
    "social.dream.noJoiner": "Nie jesteś jeszcze członkiem jakiegoś grupowego marzenia",
    "social.dream.noLikes": "Nie podoba ci się jeszcze żaden wpis!",
    "social.dream.noSupport": "Nie popierasz wpisu",
    "social.dream.registerFirstText": "Aby móc dołączyć do wpisu, obsługiwać wpis lub zaznaczyć, że chcesz go dokonać, musisz być zalogowany. Rejestracja przy udostępnieniu wpisu jest szybka i bezpłatna. Wystarczy kliknąć na przycisk \"Zarejestruj\".",
    "social.dream.registerFirstTitle": "Marzysz, wspierasz, też to robisz?",
    "social.dream.settings": "Ustawienia wejściowe",
    "social.dream.shareTitle": "Podziel się tym marzeniem",
    "social.dream.similar": "Podobne wpisy",
    "social.dreamline.addImageToDream": "Dodaj ten obrazek do swojego wpisu",
    "social.dreamline.addMedia": "Dodaj zdjęcie / video",
    "social.dreamline.allActivities": "Wszystkie działania Dreamline",
    "social.dreamline.anonymous": "Anonimowy",
    "social.dreamline.commentButton": "Komentarz",
    "social.dreamline.comments": "pisze:",
    "social.dreamline.depublished": "Ta uwaga została usunięta za niewłaściwe zachowanie.",
    "social.dreamline.dreampointsInfo": "To są Dreampoints wpisu. Więcej o Dreampoints znajdziesz w swoim profilu.",
    "social.dreamline.edited": "[obiekt Object]",
    "social.dreamline.fewDreampoints": "ma tylko kilka Dreampoints. Komentarz, podoba mi się lub podziel się wpisem.",
    "social.dreamline.filterBySteps": "Filtr według kroków",
    "social.dreamline.filterComment": "Komentarze",
    "social.dreamline.filteredBy": "Działania filtrowane przez",
    "social.dreamline.filteredBySteps": "Filtrowanie krokowe",
    "social.dreamline.filterFavorite": "Następuje",
    "social.dreamline.filterLike": "Lubi",
    "social.dreamline.filterShare": "Akcje",
    "social.dreamline.filterStep": "Kroki",
    "social.dreamline.flagged": "Wkład ten został zgłoszony",
    "social.dreamline.isComment": "Uwagi",
    "social.dreamline.isCopycatJoiner": "marzenie dnia",
    "social.dreamline.isFavorite": "",
    "social.dreamline.isFirstUpdate": "Stworzony",
    "social.dreamline.isJoiner": "",
    "social.dreamline.isLike": "",
    "social.dreamline.isShare": "Współdzielony",
    "social.dreamline.isStep": "zrobić nowy krok",
    "social.dreamline.isUpdate": "Aktualizacja",
    "social.dreamline.lastEntry": "Ostatnia aktualizacja wpisu",
    "social.dreamline.locked": "Ta treść jest widoczna tylko dla innych marzycieli",
    "social.dreamline.lockedCTA": "Dołącz do tego wpisu teraz",
    "social.dreamline.noMessages": "Ten wpis nie ma jeszcze żadnej aktywności. Napisz pierwszy komentarz teraz.",
    "social.dreamline.noMessagesFiltered": "Brak aktywności z tymi ustawieniami filtrów dla tego wpisu.",
    "social.dreamline.nowFollower": "Podążaj za tym wpisem!",
    "social.dreamline.nowJoiner": "jest teraz zwolennikiem",
    "social.dreamline.nowLikes": "Podobał mi się ten wpis teraz!",
    "social.dreamline.nowSharing": "podzielił się tym wpisem!",
    "social.dreamline.onlyJoinerVisible": "Widoczny tylko dla innych marzycieli / kibiców",
    "social.dreamline.prefixComment": "ma ten wpis przed",
    "social.dreamline.prefixCopycatJoiner": "chce usunąć ten wpis, ponieważ",
    "social.dreamline.prefixFavorite": "następuje po tym wpisie, ponieważ",
    "social.dreamline.prefixJoiner": "wspiera ten wpis, ponieważ",
    "social.dreamline.prefixLike": "mógł być tym wpisem od",
    "social.dreamline.prefixShare": "ma ten wpis przed",
    "social.dreamline.prefixStep": "ma zamiar",
    "social.dreamline.prefixUpdate": "ma ten wpis przed",
    "social.dreamline.profile": "Profil",
    "social.dreamline.provideImage": "Udostępniam to zdjęcie właścicielowi płyty",
    "social.dreamline.resetFilter": "Filtr zresetowany",
    "social.dreamline.sendComment": "Zgłoś się",
    "social.dreamline.sharePart": "Podziel się",
    "social.dreamline.toggleStream": "Komentarze",
    "social.dreamline.toggleStreamSingle": "Komentarz",
    "social.dreamline.writeComment": "Dodaj komentarz...",
    "social.Favorite": "Follow",
    "social.follow": "Follow",
    "social.Follow": "Follow",
    "social.hasLiked": "Już ci się podoba ten wpis.",
    "social.join": "Dołącz do",
    "social.Join": "Dołącz do",
    "social.Joiner": "Sen wzdłuż",
    "social.like": "Jak",
    "social.Like": "Jak",
    "social.report.headlinePrefix": "Wchodzisz w to.",
    "social.report.headlineSuffix": "żeby się zgłosić.",
    "social.report.login": "Musisz być zalogowany, aby zgłosić to stanowisko.",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "Inne obraźliwe treści",
    "social.report.reportScam": "Fałszywe przedstawienie, zastraszanie.",
    "social.report.reportSex": "Treści seksualne",
    "social.report.reportSpam": "To jest spam.",
    "social.report.reportViolence": "Przedstawienie przemocy",
    "social.report.send": "Wyślij wiadomość",
    "social.report.title": "Raport",
    "social.share": "Podziel się",
    "social.Share": "Podziel się",
    "syd.ui.dreamerCommercial": "Standard B2B",
    "syd.ui.userCommercial": "B2B Dreamer",
    "user.anonymous-user-name": "",
    "user.company.additionalWebsite": "Dalsza strona internetowa firmy (tylko domena bez https://)",
    "user.company.companyName": "Nazwa firmy",
    "user.company.copyContact": "Skopiuj dane kontaktowe do wpisu z danych firmy",
    "user.company.email": "E-mail, aby się z nami skontaktować",
    "user.company.facebook": "Firmowe konto na Facebooku (tylko nazwa konta)",
    "user.company.fax": "Numer telefaksu do kontaktu pisemnego",
    "user.company.google": "Konto firmowe Google+ (tylko nazwa konta)",
    "user.company.instagram": "Konto Instagram firmy (tylko nazwa konta)",
    "user.company.linkedin": "LinkedIn konto firmy (tylko nazwa konta)",
    "user.company.newCustomerAccount": "Załóż konto biznesowe",
    "user.company.noCustomerValidation": "Twoje konto biznesowe nie zostało jeszcze aktywowane.",
    "user.company.noCustomerYet": "Twoja rejestracja nie została jeszcze przypisana do konta biznesowego.",
    "user.company.phone": "Numer telefonu do kontaktu z nami",
    "user.company.pinterest": "Rachunek Pinterest firmy (tylko nazwa rachunku)",
    "user.company.remarks": "Uwagi",
    "user.company.twitter": "Konto Twitter firmy (tylko nazwa konta bez @)",
    "user.company.website": "Strona internetowa firmy (tylko domena bez https://)",
    "user.company.xing": "Konto Xing firmy (tylko nazwa konta)",
    "user.company.youtube": "Konto Youtube firmy (tylko nazwa konta)",
    "user.message.after-save-error": "Twoje zmiany nie mogły zostać zapisane.",
    "user.message.after-save-success": "Twoje zmiany zostały zapisane.",
    "user.message.can-not-login": "Nie możemy Cię zalogować za pomocą tych wpisów użytkownika i hasła! Czy nazwa użytkownika jest poprawnie przeliterowana? Wprowadź poprawnie hasło? Może coś poszło nie tak z rejestracją.",
    "user.message.can-not-logout": "Nie mogliśmy cię wypisać!",
    "user.message.conflict": "Ta nazwa użytkownika już istnieje, wybierz inną!",
    "user.message.fileExistsError": "Przesyłka nie powiodła się. Akta już istnieją",
    "user.message.fileSizeError": "Przesyłka nie powiodła się. kartoteka jest zbyt duża",
    "user.message.loginFailureBottom": "Może nie masz jeszcze konta Entryer?",
    "user.message.loginFailureTop": "Czy twoja nazwa użytkownika jest poprawnie przeliterowana? Czy hasło zostało wpisane poprawnie?",
    "user.message.registration-error": "Podczas rejestracji wystąpił błąd!",
    "user.message.registration-success": "Wkrótce powinieneś otrzymać od nas e-mail potwierdzający.",
    "user.message.unknownError": "Nieznany błąd podczas wysyłania pliku",
    "user.message.upload-success": "Prześlij udany",
    "user.message.verify-error": "Twój adres e-mail nie mógł być zweryfikowany. Prosimy o kontakt z administratorem.",
    "user.message.verify-success": "Twój adres e-mail został potwierdzony. Możesz się zarejestrować teraz:",
    "user.ui.accountGreeting": "Witam,",
    "user.ui.accountMenuActivities": "Dreampoints",
    "user.ui.accountMenuCompanySettings": "Szczegóły dotyczące firmy",
    "user.ui.accountMenuEntries": "Moje wpisy",
    "user.ui.accountMenuEntryPre": "Wejścia,",
    "user.ui.accountMenuEvents": "Moje wydarzenia",
    "user.ui.accountMenuFavorites": "Moje ulubione",
    "user.ui.accountMenuFollows": "Wpisy, za którymi podążam",
    "user.ui.accountMenuImages": "Moje dokumenty",
    "user.ui.accountMenuJoin": "Moja grupa Marzenia",
    "user.ui.accountMenuLikes": "Wpisy, które mi się podobają",
    "user.ui.accountMenuMessages": "Aktualności",
    "user.ui.accountMenuNewsletter": "Moje subskrypcje newslettera",
    "user.ui.accountMenuPoi": "Moje miejsca",
    "user.ui.accountMenuPress": "Moje artykuły",
    "user.ui.accountMenuProducts": "Moje produkty",
    "user.ui.accountMenuJobs": "Moja praca oferuje",
    "user.ui.accountMenuProfile": "Profil",
    "user.ui.accountMenuRatings": "Recenzje",
    "user.ui.accountMenuRegistrationData": "Dane rejestracyjne",
    "user.ui.accountMenuReviews": "Recenzje",
    "user.ui.accountMenuSettings": "Ustawienia",
    "user.ui.accountMenuSkillsOffer": "Oferuję...",
    "user.ui.accountMenuSkillsRequest": "Potrzebuję...",
    "user.ui.accountMenuSkillsSearch": "Zapytania i oferty",
    "user.ui.accountMenuSubscriptions": "Abonamenty",
    "user.ui.accountMenuSubscritions": "Mój abonament",
    "user.ui.accountMenuSupport": "Obsługiwane wpisy",
    "user.ui.accountMenuSupports": "Wpisy, które popieram",
    "user.ui.accountNoMessages": "Nie otrzymałeś jeszcze żadnych wiadomości.",
    "user.ui.accountProgressbar": "Twój profil jest zbyt {{value}}% kompletny",
    "user.ui.accountProgressbarEntry": "Twój wpis jest zbyt {{value}}% kompletny",
    "user.ui.accountSettings": "Dalsze ustawienia",
    "user.ui.accountToolbarLogin": "Login",
    "user.ui.accountToolbarLoginTooltip": "Zarejestruj się za darmo i stwórz swój własny wpis",
    "user.ui.accountToolbarLogout": "Wylogować się",
    "user.ui.adminPreview": "Premiera",
    "user.ui.backToLogin": "Powrót do strony logowania",
    "user.ui.birthday": "Urodziny",
    "user.ui.cannotLogin": "Nie możesz się zarejestrować? Kliknij tutaj, aby zresetować swoje hasło.",
    "user.ui.changePassword": "Chcę zmienić moje hasło",
    "user.ui.changeUserGroup": "Inny abonament",
    "user.ui.city": "Miejsce:",
    "user.ui.companyName": "Nazwa firmy",
    "user.ui.companyNameTooltip": "Ważne: Twój wpis pojawi się pod tymi nazwami firm",
    "user.ui.country": "Kraj",
    "user.ui.createNewAccount": "Utwórz nowe konto użytkownika",
    "user.ui.dataDelete": "jest używany do dalszych wpisów. Czy chcesz usunąć plik na stałe? Wszędzie tam, gdzie ten plik jest używany, jest on zastępowany przez posiadacza miejsca.",
    "user.ui.dataUsage": "Plik jest zapisywany w",
    "user.ui.dataUsageNone": "Plik nie jest jeszcze używany. Czy chcesz usunąć plik na stałe?",
    "user.ui.deleteImage": "Usuń obrazek",
    "user.ui.deleteImageAssignment": "Usuń zadanie",
    "user.ui.description": "Wpisz tutaj ogólny opis osoby lub firmy",
    "user.ui.documents": "Dokumenty",
    "user.ui.documentSearch": "Wyszukiwanie dokumentów",
    "user.ui.download": "Pobierz {{title}}",
    "user.ui.email": "Adres e-mail",
    "user.ui.fillOutForm": "Aby się zarejestrować, wszystkie obowiązkowe pola muszą być wypełnione w całości",
    "user.ui.firstName": "Imię",
    "user.ui.academicTitle": "Tytuł naukowy",
    "user.ui.gender": "M/W",
    "user.ui.genderFemale": "kobieta",
    "user.ui.genderMale": "mężczyzna",
    "user.ui.genderThird": "nie wyszczególniony",
    "user.ui.housenumber": "Nie.",
    "user.ui.iAmCustomer": "Chciałbym założyć konto B2B",
    "user.ui.internalInfo": "Są dla ciebie jakieś wieści. Kliknij tutaj.",
    "user.ui.lastLogin": "Ostatnie logowanie na",
    "user.ui.lastName": "Nazwisko",
    "user.ui.loginButton": "Login",
    "user.ui.loginTitle": "Rejestracja użytkownika",
    "user.ui.languageAvailable": "Dostępne w Twoim języku",
    "user.ui.languageMissing": "Dostępne tylko w",
    "user.ui.memberSince": "Członek od",
    "user.ui.mobileNumber": "Numer telefonu komórkowego",
    "user.ui.myAddedStorage": "Podane zdjęcia",
    "user.ui.myStorage": "Mój katalog",
    "user.ui.noRegisterMessage": "Automatyczna rejestracja użytkownika nie jest aktywna. Aby utworzyć konto użytkownika, należy skontaktować się z podanymi adresami kontaktowymi.",
    "user.ui.oldPassword": "Poprzednie hasło",
    "user.ui.password": "Hasło",
    "user.ui.pleaseSelect": "Płeć",
    "user.ui.registerButton": "Utwórz konto użytkownika teraz",
    "user.ui.registerMessage": "Zarejestruj się teraz i zacznij natychmiast. To jest szybkie i łatwe.",
    "user.ui.registerTitle": "Jesteś tu nowy?",
    "user.ui.requestPasswordReset": "Poproś o nowe hasło i usuń stare hasło",
    "user.ui.resetPassword": "Resetowanie hasła",
    "user.ui.resetPasswordPage": "Zresetuj swoje hasło",
    "user.ui.resetPasswordSuccess": "Twoje hasło zostało zresetowane, możesz się teraz zalogować",
    "user.ui.selectCustomerType": "Jesteś... proszę wybrać jedną",
    "user.ui.stayLoggedin": "Pozostań zalogowany",
    "user.ui.stayLoggedinInfo": "Jeśli jest to urządzenie dostępne publicznie, należy wyłączyć tę opcję i wylogować się ponownie na końcu.",
    "user.ui.street": "Ulica",
    "user.ui.uploadDrop": "Aby przesłać plik, przeciągnij go tutaj lub",
    "user.ui.uploadSelectFile": "wybrać plik",
    "user.ui.uploadToMyStorage": "Wgraj nowe zdjęcia do mojego katalogu",
    "user.ui.uploadUserImage": "Wybierz zdjęcie użytkownika",
    "user.ui.username": "Nazwa użytkownika (adres e-mail)",
    "user.ui.userName": "Nazwa użytkownika",
    "user.ui.unsubscribePage": "Czy naprawdę chcesz się wypisać z naszego newslettera?",
    "user.ui.unsubscribeSuccess": "Pomyślnie wypisałeś się z naszego newslettera",
    "user.ui.unsubscribe": "Wylogować się",
    "user.ui.visibilityInfo": "Wskazówka: Ty decydujesz, co jest publicznie wyświetlane z Twojego profilu! Kliknij na ikonę oka, aby ustawić widoczność swoich wpisów:",
    "user.ui.zip": "Kod pocztowy",
    "user.user": "Zwolennicy",
    "user.users": "Abonenci",
    "user.ui.accountCookieSettings": "Kliknij na ustawienia, aby dostosować swoje ustawienia.",
    "user.ui.accountCookieEssential": "Niezbędne",
    "user.ui.accountCookieAnalysis": "Dane do analizy",
    "user.ui.accountCookieSocial": "Social Media"
  };
});
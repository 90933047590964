define('webapp/routes/login', ['exports', 'webapp/routes/base-route', 'webapp/config/environment'], function (exports, _baseRoute, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _baseRoute.default.extend({
    userProxy: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    languageService: Ember.inject.service(),
    i18n: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        page: this.get('cmsService').getAndSetActivePage('login')
      }).then(function (res) {
        return res.page;
      });
    },
    getBreadcrumbs: function getBreadcrumbs() {
      return [{
        route: 'login',
        title: 'Login'
      }];
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('azureOAuthUrl', _environment.default.azureOAuthUrl);
    },


    actions: {
      afterLogin: function afterLogin(error) {
        var _this = this;

        if (error) {
          var i18n = this.get('i18n');
          var message = i18n.t('user.message.can-not-login').toString();
          UIkit.modal.alert(message);
        } else {
          var userLanguage = this.get('userProxy.language');
          var languageService = this.get('languageService');

          if (userLanguage && userLanguage !== languageService.selectedLanguage) {
            languageService.setLanguage(userLanguage);
          }

          if (this.overrideRedirectTo) {
            try {
              return this.transitionTo(this.overrideRedirectTo);
            } catch (err) {
              console.log(err);
            }
          }

          var page = this.controller.get('model');
          var redirectTo = page && page.options && page.options.redirectTo;

          if (redirectTo) {
            var cachedData = this.get('cachedData');
            cachedData.getFlatPages().then(function (pages) {
              var redirectPage = pages.find(function (page) {
                return page._id === redirectTo;
              });

              if (redirectPage) {
                var cmsService = _this.get('cmsService');
                var params = [redirectPage];
                if (page.options.redirectParameter) {
                  params.push(page.options.redirectParameter);
                }
                var routeParams = cmsService.getPageRouteParams(redirectPage, params);
                _this.transitionTo.apply(_this, _toConsumableArray(routeParams));
              } else {
                languageService.transitionToHome();
              }
            });
          } else {
            languageService.transitionToHome();
          }
        }
      }
    }
  });
});
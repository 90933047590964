define('webapp/components/account/address-edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    message: Ember.inject.service(),
    geoCoding: Ember.inject.service(),
    showParking: false,
    hasGeopoint: true,

    canResolve: Ember.computed('address.street', 'address.city', 'address.zip', function () {
      return !!this.get('address.street') || !!this.get('address.city') || !!this.get('address.zip');
    }),

    latLon: Ember.computed('geoPoint.lat', 'geoPoint.lon', function () {
      return {
        lat: parseFloat(this.get('geoPoint.lat')),
        lon: parseFloat(this.get('geoPoint.lon'))
      };
    }),

    canReverse: Ember.computed('latLon', function () {
      var latLon = this.get('latLon');
      return !(isNaN(latLon.lat) || isNaN(latLon.lon));
    }),

    lookupFailed: function lookupFailed() {
      var message = this.get('message');
      message.error('Adresse konnte nicht ermittelt werden');
    },


    actions: {
      resolveAddress: function resolveAddress() {
        var _this = this;

        var address = this.get('address');
        this.get('geoCoding').resolveAddress(address).then(function (res) {
          if (res.found) {
            _this.set('geoPoint', {
              lat: res.lat,
              lon: res.lon
            });
          } else {
            _this.lookupFailed();
          }
        }).catch(function () {
          _this.lookupFailed();
        });
      },
      reverseGeo: function reverseGeo() {
        var _this2 = this;

        var latLon = this.get('latLon');
        this.get('geoCoding').reverseGeo(latLon).then(function (res) {
          if (res.found) {
            _this2.set('address', Ember.getProperties(res, ['street', 'houseNumber', 'city', 'zip']));
          } else {
            _this2.lookupFailed();
          }
        }).catch(function () {
          _this2.lookupFailed();
        });
      }
    }
  });
});
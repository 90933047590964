define('webapp/components/account/login-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    viewService: Ember.inject.service(),

    showModal: false,
    showRegisterModal: false,
    loginError: false,

    actions: {
      setShowModal: function setShowModal(value) {
        this.set('showModal', value);
        if (!value) {
          this.set('loginError', false);
          this.setProperties({
            loginError: false,
            showRegisterModal: false
          });
        }
      },
      afterLogin: function afterLogin(error) {
        var _this = this;

        this.set('loginError', !!error);
        if (!error) {
          this.set('showModal', false);
          var router = this.get('router');
          var currentRoute = Ember.getOwner(this).lookup('route:' + router.currentRouteName);
          Ember.run.later(function () {
            _this.get('viewService').skipNextScrollToTop();
            currentRoute.refresh();
          }, 100);
        }
      },
      afterRegister: function afterRegister(error) {
        this.set('registerError', !!error);
        if (!error) {
          this.setProperties({
            showModal: false,
            showRegisterModal: false,
            loginError: false
          });
        }
      }
    }
  });
});
define('webapp/services/event-container', ['exports', 'webapp/services/abstract-container'], function (exports, _abstractContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractContainer.default.extend({
    containerName: 'eventContainer',
    languageService: Ember.inject.service(),

    fetchDetail: function fetchDetail(detailSlug) {
      var myStorage = this.get('myStorage');
      return myStorage.event.ajaxGet({
        param: detailSlug,
        query: {
          lang: this.get('languageService').selectedLanguage
        }
      }).then(function (res) {
        return res;
      });
    }
  });
});
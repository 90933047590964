define('webapp/services/promocode-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    myStorage: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this._fetchPromocodeInfo();
    },
    _fetchPromocodeInfo: function _fetchPromocodeInfo() {
      var _this = this;

      var param = 'info';
      var myStorage = this.get('myStorage');
      return myStorage.promocode.ajaxGet({ param: param }).then(function (promocodeInfo) {
        _this.set('promocodeInfo', promocodeInfo);
        return promocodeInfo;
      });
    },
    isValidPromocode: function isValidPromocode(code) {
      var param = 'is_valid/' + code;
      var myStorage = this.get('myStorage');

      return myStorage.promocode.ajaxGet({ param: param });
    },


    hasActivePromocodes: Ember.computed('promocodeInfo', function () {
      var promocodeInfo = this.get('promocodeInfo');

      return promocodeInfo ? promocodeInfo.promocodeCount >= 1 : false;
    })
  });
});
define('webapp/routes/account/messages/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    countService: Ember.inject.service(),

    model: function model() {
      var myStorage = this.get('myStorage');
      var countService = this.get('countService');
      return myStorage.message.ajaxPost({ param: 'list', data: {} }).then(function (res) {
        countService.resetNotifications();
        return res.items;
      });
    },
    afterModel: function afterModel() {
      var myStorage = this.get('myStorage');
      var user = this.get('userProxy._id');
      // TODO JB mergecheck
      // return;
      // myStorage.notification.ajaxGet().then(res => {
      //   if (!res.message) {
      //     for (const notification of res) {
      //       if (notification.entry) {
      //         notification.isMyDream = notification.entry.owner === user;
      //       } else {
      //         notification.isMyDream = false;
      //       }
      //     }
      //     this.controllerFor(this.routeName).set('notifications', res);
      //   }
      // });
    }
  });
});
define('webapp/services/category-container', ['exports', 'webapp/utils/filter-utils'], function (exports, _filterUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function getCatSlugs(flatCategories, targetCategory) {
    var cat = flatCategories.find(function (x) {
      return x._id === targetCategory._id;
    });
    var catHierarchy = [];

    while (cat.parent) {
      catHierarchy.push(cat);
      cat = flatCategories.find(function (x) {
        return x._id === cat.parent;
      });
    }

    return catHierarchy.reverse().slice(1).map(function (x) {
      return x.slug;
    });
  }

  function buildCategoryFilter(options) {
    if (options.categories && options.categories.length) {
      return [{ type: options.categoryFilterOperator || 'and', items: options.categories }];
    }
  }

  function buildCategoryFilterArticles(options) {
    if (options.categories && options.categories.length) {
      var catIdsFilter = options.categories.map(function (id) {
        return {
          term: {
            'categories._id': id
          }
        };
      });

      if (options.categoryFilterOperator === 'or') {
        return {
          bool: {
            should: catIdsFilter
          }
        };
      } else {
        return {
          bool: {
            filter: catIdsFilter
          }
        };
      }
    }
  }

  exports.default = Ember.Service.extend({
    myStorage: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    languageService: Ember.inject.service(),
    parentSlug: '',
    pagingPage: 1,
    perPage: 12,
    isLoading: false,

    items: [],

    sortForeignCategory: Ember.computed('page.options.sortForeignCategories', function () {
      var sortForeignCategories = this.get('page.options.sortForeignCategories');
      return Array.isArray(sortForeignCategories) && sortForeignCategories[0];
    }),

    sortByForeignCategory: function sortByForeignCategory(hits) {
      var sortForeignCategory = this.get('sortForeignCategory');
      if (!sortForeignCategory) {
        console.log('sortForeignCategory not set');
        return hits;
      }

      var flatCategories = this.get('flatCategories');

      var childCategoryIds = flatCategories.filter(function (x) {
        return x.parent === sortForeignCategory;
      }).sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      }).map(function (x) {
        return x._id;
      });

      var itemBuckets = childCategoryIds.map(function () {
        return [];
      });
      var restBucket = [];

      hits.forEach(function (hit) {
        var foreignCategory = hit.categories.find(function (c) {
          return childCategoryIds.includes(c._id);
        });
        if (foreignCategory) {
          var bucketIdx = childCategoryIds.indexOf(foreignCategory._id);
          itemBuckets[bucketIdx].push(hit);
        } else {
          restBucket.push(hit);
        }
      });

      var totalHits = [];
      itemBuckets.forEach(function (bucket) {
        totalHits.push.apply(totalHits, _toConsumableArray(bucket));
      });
      totalHits.push.apply(totalHits, restBucket);
      return totalHits;
    },
    fetchEntries: function fetchEntries(activeCategory, type, options) {
      var _this = this;

      var isPaging = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

      if (activeCategory && activeCategory.isLocked) {
        activeCategory = null;
      }
      var andOnlyCategoryIds = this.get('andOnlyCategoryIds');
      var myStorage = this.get('myStorage');
      var sortForeignCategory = this.get('sortForeignCategory');

      return myStorage.search.ajaxPost({
        data: {
          itemTypes: [type],
          categoryIds: [activeCategory && activeCategory._id].concat(_toConsumableArray(andOnlyCategoryIds)).filter(Boolean),
          sort: options.sortField,
          sortOrder: options.sortOrder,
          from: (this.get('pagingPage') - 1) * this.get('perPage'),
          size: Number(options.size) || this.get('perPage'),
          aggregations: !isPaging,
          showVariants: type === 'product',
          showRelations: options.showRelations,
          internalTagBoost: 'boost',
          categoryFilter: buildCategoryFilter(options)
        },
        query: {
          lang: this.get('languageService.selectedLanguage'),
          site: this.get('cachedData.cachedSite._id')
        }
      }).then(function (searchResult) {
        if (!_this.isDestroyed) {
          var hits = searchResult.hits;
          if (sortForeignCategory) {
            hits = _this.sortByForeignCategory(hits);
          }

          return {
            items: hits,
            categoryCounts: searchResult.aggs && searchResult.aggs.categoryIds,
            totalHits: searchResult.totalHits
          };
        }
      });
    },
    fetchArticles: function fetchArticles(activeCategory, options, isPaging) {
      var _this2 = this;

      if (activeCategory && activeCategory.isLocked) {
        activeCategory = null;
      }

      var filter = [];

      if (activeCategory) {
        filter.push({
          term: {
            'categories._id': activeCategory._id
          }
        });
      }

      var catFilter = buildCategoryFilterArticles(options);
      if (catFilter) {
        filter.push(catFilter);
      }

      if (options.timeframe) {
        var timeframeFilter = (0, _filterUtils.getTimeframeFilter)(options.timeframe);
        if (timeframeFilter) {
          filter.push(timeframeFilter);
        }
      }

      var myStorage = this.get('myStorage');
      var perPage = this.get('perPage');
      perPage = options.size || perPage;

      var sortField = options.sortField;
      if (options.sortField === 'alphabetical') {
        sortField = 'title.keyword';
      } else {
        sortField = options.sortField;
      }

      var sortOrder = options.sortOrder || 'asc';

      return myStorage.article.ajaxPost({
        param: 'search_public',
        data: {
          allFields: false,
          page: this.get('pagingPage'),
          aggregations: !isPaging,
          perPage: perPage,
          sortField: sortField,
          sortOrder: sortOrder,
          filter: filter.length ? filter : undefined
        },
        query: { lang: this.get('languageService.selectedLanguage') }
      }).then(function (res) {
        if (!_this2.isDestroyed) {
          res.model.forEach(function (item) {
            if (item.fields) {
              item.fields = JSON.parse(item.fields);
            }
          });
          return {
            items: res.model,
            categoryCounts: res.aggs && res.aggs.categoryIds,
            totalHits: res.hits
          };
        }
      });
    },
    fetchItems: function fetchItems() {
      var isPaging = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var type = this.get('itemType');
      var activeCategory = this.get('activeCategory');
      var options = this.get('page.options');

      if (type === 'article') {
        return this.fetchArticles(activeCategory, options, isPaging);
      } else {
        return this.fetchEntries(activeCategory, type, options, isPaging);
      }
    },
    infiniteScroll: function infiniteScroll() {
      var _this3 = this;

      var pagingPage = this.get('pagingPage');
      var perPage = this.get('page.options.size') || this.get('perPage');
      var totalHits = this.get('totalHits');
      var isLoading = this.get('isLoading');

      var docHeight = document.body.clientHeight;

      if (!isLoading && totalHits > pagingPage * perPage && window.scrollY + 1.7 * window.innerHeight > docHeight) {
        this.set('pagingPage', pagingPage + 1);
        this.set('isLoading', true);
        return this.fetchItems(true).then(function (_ref) {
          var items = _ref.items;

          var currentItems = _this3.get('items');
          _this3.set('items', [].concat(_toConsumableArray(currentItems), _toConsumableArray(items)));
          _this3.set('isLoading', false);
        });
      }
      // return this.fetchItems()
    },
    setupPage: function setupPage(pageRoute, page) {
      var _this4 = this;

      var activeCategory = page.category;
      var itemType = page.options.type;

      this.set('page', page);
      this.set('activeCategory', page.category);
      this.set('andOnlyCategoryIds', page.andOnlyCategories.map(function (x) {
        return x._id;
      }));
      this.set('andOnlyCategories', page.andOnlyCategories);
      this.set('itemType', itemType);
      this.set('pagingPage', 1);

      this.set('displayOptions', Ember.getProperties(page.options, ['showParentNavigation', 'showCategoryNavigation', 'showCategoryContent', // show items
      'showOnlyCurrentCategoryContent', // show item, if current = primaryCategory
      'showCurrentCategory', // show current category info + image
      'showRelations', 'showCategoryMainImage', // common: show image
      'categoryCard', // common: show as cards
      'categoryCardWithImage', // current
      'childrenWidth', 'currentWidth', 'itemWidth']));

      return this.get('cachedData').getFlatCategories().then(function (flatCategories) {
        _this4.set('flatCategories', flatCategories); // needed for breadcrumbs

        var category = flatCategories.find(function (categories) {
          return categories._id === activeCategory.parent;
        });
        if (category.level >= 1) {
          _this4.set('parentSlug', category.slug);
        } else {
          _this4.set('parentSlug', '');
        }

        return _this4.fetchItems().then(function (res) {
          _this4.setProperties(res);
        });
      });
    },
    getBreadcrumbs: function getBreadcrumbs(breadCrumbs) {
      var flatCategories = this.get('flatCategories');
      var activeCategory = this.get('activeCategory');
      var baseRoute = this.get('cmsService.dynamicPageRoutes.categoryContainer');

      var catSlugs = getCatSlugs(flatCategories, activeCategory);

      var addSlugs = catSlugs.map(function (x, idx) {
        return {
          route: 'page',
          title: x,
          routeParams: baseRoute + '/' + catSlugs.slice(0, idx + 1).join('/')
        };
      });

      return breadCrumbs.concat(addSlugs);
    },
    getTitleToken: function getTitleToken(model) {
      if (model.category && model.category.level > 1) {
        return model.category.title;
      } else {
        return model.title;
      }
    }
  });
});
define('webapp/initializers/error-logger', ['exports', 'ember-ajax/request', 'webapp/config/environment'], function (exports, _request, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.logError = logError;
  exports.initialize = initialize;


  var countLogs = 0;

  function logError(err) {
    if (++countLogs > 10) {
      return;
    }
    console.error(err.stack);
    if (!window.document) {
      return;
    }
    var data = {
      source: 'webapp',
      level: 'error',
      url: window.document.URL,
      referrer: window.document.referrer,
      userAgent: navigator.userAgent,
      message: err.message,
      stack: err.stack,
      payload: err.payload
    };

    (0, _request.default)(_environment.default.apiUrl + '/error_log', {
      method: 'POST',
      data: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' }
    }).catch(function (err) {
      console.log('error sending to error_log');
    });
  }

  function initialize(application) {
    if (typeof FastBoot !== 'undefined') {
      return;
    }
    Ember.onerror = logError;
  }

  exports.default = {
    initialize: initialize
  };
});
define('webapp/routes/verify-email', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),

    model: function model(params) {
      var token = params.verification_token;
      var myStorage = this.get('myStorage');

      return myStorage.user.ajaxGet({ param: 'verify/' + token }).then(function () {
        return { success: true };
      }).catch(function () {
        return { success: false };
      });
    }
  });
});
define('webapp/utils/scroll-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.scrollToElement = scrollToElement;
  function scrollToElement(elementSelector) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref$offset = _ref.offset,
        offset = _ref$offset === undefined ? 0 : _ref$offset;

    var element = document.querySelector(elementSelector);

    if (element) {
      var _document$body$getBou = document.body.getBoundingClientRect(),
          bodyTop = _document$body$getBou.top;

      var _element$getBoundingC = element.getBoundingClientRect(),
          top = _element$getBoundingC.top;

      var absTop = top - bodyTop - offset;

      window.scrollTo({ left: 0, top: absTop, behavior: 'smooth' });
    } else {
      console.warn('scrollToElement: element ' + elementSelector + ' not found');
    }
  }
});
define('webapp/routes/logout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    fastboot: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      var param = 'logout';
      var i18n = this.get('i18n');
      var Users = this.get('myStorage.user');
      var userProxy = this.get('userProxy');
      var token = userProxy.get('token');
      var isLoggedIn = userProxy._isLoggedIn();

      if (isLoggedIn) {
        return Users.ajaxGet({ param: param, token: token }).then(function (data) {
          userProxy._logout();
          _this.transitionTo('index');
        }).catch(function (error) {
          console.error(error.stack);
          _this.transitionTo('index');
          if (!_this.get('fastboot.isFastBoot')) {
            UIkit.modal.alert(i18n.t('user.message.can-not-logout').toString());
          }
        });
      } else {
        this.transitionTo('index');
      }
    }
  });
});
define('webapp/initializers/service-initializers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    // add aria labels to {{input ...}} and {{textarea ...}}
    Ember.TextSupport.reopen({
      attributeBindings: ['aria-label', 'role']
    });
    Ember.LinkComponent.reopen({
      attributeBindings: ['aria-label', 'role']
    });

    application.inject('controller', 'cmsService', 'service:cmsService');
    application.inject('component', 'cmsService', 'service:cmsService');
    application.inject('controller', 'templateSettings', 'service:templateSettings');
    application.inject('component', 'templateSettings', 'service:templateSettings');
  }

  exports.default = {
    initialize: initialize
  };
});
define('webapp/services/product-sub-container', ['exports', 'webapp/services/abstract-container'], function (exports, _abstractContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BAD_TYPE = 'needs-to-be-overwritten';

  exports.default = _abstractContainer.default.extend({
    cachedData: Ember.inject.service(),
    type: BAD_TYPE,

    getType: function getType() {
      var type = this.get('type');

      if (type === BAD_TYPE) {
        throw new Error('overwrite type');
      } else {
        return type;
      }
    },
    setupPage: function setupPage(pageRoute, page, params) {
      var _this = this;

      // Slug (product slug) is not language specific here
      var path = params.path;
      var restSlug = page.restSlug;
      var slugs = restSlug.split('/');
      var detailSlug = slugs[0];

      if (detailSlug) {
        return this.fetchDetail(detailSlug).then(function (detail) {
          _this.set('detail', detail);
          return detail;
        });
      } else {
        window.console.warn('no detail for ' + page.type + ' ' + path);
        this.set('detail', null);
        return Ember.RSVP.resolve();
      }
    },
    fetchDetail: function fetchDetail(detailSlug) {
      var myStorage = this.get('myStorage');

      var type = this.getType();
      var lang = this.get('languageService.selectedLanguage');
      var site = this.get('cachedData.cachedSite._id');
      //const sessionId = this.get('cmsService.sessionId');
      //const view = true;
      var path = 'product-relations/' + detailSlug;
      var query = { type: type, lang: lang, site: site }; //, sessionId, view };
      var options = { path: path, query: query };

      return myStorage.entry.ajaxGet(options).then(function (product) {
        if (product.options) {
          product.options.productSlug = detailSlug;
        } else {
          product.options = { productSlug: detailSlug };
        }
        return product;
      });
    }
  });
});
define('webapp/components/page/blog-category-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    category: Ember.computed.oneWay('page.category'),
    currentCategory: Ember.computed.oneWay('category.currentCategory'),
    categoryOptions: Ember.computed.oneWay('category.rootCategory.options')
  });
});
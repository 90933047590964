define('webapp/components/media/document-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    languageService: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    cachedData: Ember.inject.service(),

    isInitial: true,
    page: 1,
    perPage: 100,
    searchTerm: '',
    selectedLanguage: null,

    model: null,
    categories: null,

    selectedCategories: Ember.computed(function () {
      return [];
    }),

    didInsertElement: function didInsertElement() {
      this.search();
      var languageService = this.get('languageService');
      languageService.on('languageChange', this, this.watchLang);
    },
    willDestroyElement: function willDestroyElement() {
      var languageService = this.get('languageService');
      languageService.off('languageChange', this, this.watchLang());
    },
    watchLang: function watchLang() {
      var _this = this;

      this.search().then(function () {
        return _this.recalcSelectedCategories();
      });
    },
    recalcSelectedCategories: function recalcSelectedCategories() {
      var _this2 = this;

      var cachedData = this.get('cachedData');
      var selectedCategories = this.get('selectedCategories');
      if (selectedCategories.length) {
        return cachedData.getFlatCategories().then(function (flatCats) {
          var newSelectedCategories = selectedCategories.map(function (sCat) {
            return flatCats.find(function (cat) {
              return sCat._id === cat._id;
            });
          });
          _this2.set('selectedCategories', newSelectedCategories);
        });
      }
    },
    loadCategories: function loadCategories(categoryAggregation) {
      var cachedData = this.get('cachedData');
      var categoryIds = Object.keys(categoryAggregation);

      return cachedData.getFlatCategories().then(function (flatCategories) {
        return flatCategories.filter(function (cat) {
          return categoryIds.includes(cat._id);
        }).map(function (cat) {
          return _extends({}, cat, {
            count: categoryAggregation[cat._id]
          });
        });
      });
    },
    search: function search() {
      var _this3 = this;

      var myStorage = this.get('myStorage');

      var searchTerm = this.get('searchTerm');
      var selectedCategories = this.get('selectedCategories');

      var isInitial = !searchTerm && selectedCategories.length === 0;

      var searchRequest = {
        searchTerm: searchTerm,
        language: this.get('selectedLanguage'),
        page: this.get('page'),
        perPage: isInitial ? 0 : this.get('perPage'),
        path: this.get('folders'),
        categories: selectedCategories.map(function (x) {
          return x._id;
        })
      };

      return myStorage.media.ajaxPost({ param: 'search', data: searchRequest }).then(function (searchRes) {
        if (_this3.isDestroyed) {
          return;
        }
        return _this3.loadCategories(searchRes.aggs.categoryIds).then(function (categories) {
          _this3.set('isInitial', isInitial);
          _this3.set('categories', categories);
          _this3.set('model', searchRes.model);
          _this3.set('totalHits', searchRes.totalHits);
          _this3.set('aggs', searchRes.aggs);
        });
      });
    },


    actions: {
      search: function search() {
        this.set('page', 1);
        this.search();
      },
      toggleCategory: function toggleCategory(cat) {
        var selectedCategories = this.get('selectedCategories');

        var hasCategory = selectedCategories.find(function (_cat) {
          return cat._id === _cat._id;
        });
        if (hasCategory) {
          this.set('selectedCategories', selectedCategories.filter(function (_cat) {
            return cat._id !== _cat._id;
          }));
        } else {
          this.set('selectedCategories', [].concat(_toConsumableArray(selectedCategories), [cat]));
        }
        this.search();
      },
      clearSearch: function clearSearch() {
        this.set('searchTerm', '');
        this.set('selectedCategories', []);
        this.search();
      },
      setLanguage: function setLanguage(language) {
        this.set('selectedLanguage', language);
        this.search();
      }
    }
  });
});
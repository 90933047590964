define('webapp/components/widgets/widget-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var options = this.get('data.options') || {};
      if (options.setCustomCenter) {
        this.setProperties({
          customCenter: options.customCenter,
          customZoom: options.customZoom
        });
      } else {
        this.setProperties({
          customCenter: null,
          customZoom: null
        });
      }

      this.fetchEntries();
    },
    getCategoryFilter: function getCategoryFilter() {
      var categoryFilterOperation = this.get('data.options.categoryFilterOperation');
      var rootCategories = this.get('data.options.rootCategories');

      var catFilters = [];
      if (rootCategories && rootCategories.length) {
        catFilters.push({ type: categoryFilterOperation, items: rootCategories });
      }

      return catFilters;
    },
    fetchEntries: function fetchEntries() {
      var _this = this;

      var options = this.get('data.options');
      var myStorage = this.get('myStorage');

      var searchRequest = {
        itemTypes: options.types,
        size: 10000,
        categoryFilter: this.getCategoryFilter(),
        tagFilter: options.tags,
        aggregations: false
      };

      return myStorage.search.ajaxPost({
        data: searchRequest,
        query: { lang: this.get('languageService.selectedLanguage') }
      }).then(function (res) {
        _this.set('items', res.hits);
      });
    }
  });
});
define('webapp/mixins/edit-route', ['exports', 'webapp/utils/clone-deep', 'webapp/utils/deep-equal', 'webapp/config/environment'], function (exports, _cloneDeep, _deepEqual, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    viewService: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    modelName: null,

    initialModel: null,
    enableTransitionCheck: true,

    onBeforeUnload: function onBeforeUnload(e) {
      if (this.modelIsDirty()) {
        var message = this.get('message');
        var messageString = 'Ungespeicherte Änderungen.';
        message.info(messageString);
        e.returnValue = messageString;
        return messageString;
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.onBeforeUnload = this.onBeforeUnload.bind(this);
    },
    activate: function activate() {
      if (_environment.default.dirtyModelCheck) {
        window.addEventListener('beforeunload', this.onBeforeUnload);
      }
      return this._super.apply(this, arguments);
    },
    deactivate: function deactivate() {
      if (_environment.default.dirtyModelCheck) {
        window.removeEventListener('beforeunload', this.onBeforeUnload);
      }
      return this._super.apply(this, arguments);
    },
    beforeModel: function beforeModel() {
      var _this = this;

      var cachedData = this.get('cachedData');
      var controller = this.controllerFor(this.routeName);
      return cachedData.getCategories().then(function (cats) {
        _this.set('availableCategories', cats);
        controller.set('availableCategories', cats);
      });
    },
    defaultItem: function defaultItem(item) {
      item.options = item.options || {};
      item.fields = item.fields || {};
      item.address = item.address || {};
      item.categories = item.categories || [];
      item.tags = item.tags || [];
      item.images = item.images || [];
      item.openingHoursData = item.openingHoursData || {
        data: {}
      };
      item.visibleForGroups = item.visibleForGroups || [];
      item.events = item.events || [];
      item.entries = item.entries || [];
      item.pois = item.pois || [];
      return item;
    },
    serializeItem: function serializeItem(item) {
      item = JSON.parse(JSON.stringify(item));
      delete item._id;
      return item;
    },
    model: function model(param) {
      var _this2 = this;

      var controller = this.controllerFor(this.routeName);
      var id = param.id;
      var isNew = this.isNew = id === 'new';
      controller.set('isNew', isNew);

      this.set('enableTransitionCheck', true);

      if (isNew) {
        this.set('initialModel', {});
        return this.defaultItem({});
      } else {
        var itemStorage = this.get('itemStorage');
        return itemStorage.ajaxGet({ param: id }).then(function (item) {
          var data = _this2.defaultItem(item);
          _this2.set('initialModel', (0, _cloneDeep.default)(data));
          return data;
        });
      }
    },
    save: function save() {
      var _this3 = this;

      var controller = this.controllerFor(this.routeName);
      var model = controller.get('model');
      var itemStorage = this.get('itemStorage');
      var message = this.get('message');

      var promise = void 0;
      if (this.isNew) {
        var data = this.serializeItem(model);
        data.owner = this.get('userProxy._id');
        promise = itemStorage.ajaxPost({
          data: data,
          query: { autoSlug: true, forcePermission: controller.get('forcePermission') }
        }).then(function (res) {
          _this3.set('enableTransitionCheck', false);
          _this3.set('viewService.skipScroll', true);
          _this3.replaceWith(_this3.routeName, res._id);
        });
      } else {
        promise = itemStorage.ajaxPut({ param: model._id, data: this.serializeItem(model) }).then(function () {
          _this3.set('enableTransitionCheck', false);
          _this3.set('viewService.skipScroll', true);
          _this3.refresh();
        });
      }

      return promise.then(function () {
        message.info('Erfolgreich gespeichert');
      }).catch(function (err) {
        message.error('Fehler beim speichern: ' + (err.payload && err.payload.message));
        throw err;
      });
    },
    modelIsDirty: function modelIsDirty() {
      var controller = this.controllerFor(this.routeName);
      var initialModel = this.get('initialModel');
      var model = controller.get('model');

      var modelsEqual = (0, _deepEqual.default)(model, initialModel);
      return !modelsEqual;
    },


    actions: {
      willTransition: function willTransition(transition) {
        var _this4 = this;

        var enableTransitionCheck = this.get('enableTransitionCheck');
        var isFastBoot = this.get('fastboot.isFastBoot');
        if (_environment.default.dirtyModelCheck && enableTransitionCheck) {
          if (this.modelIsDirty()) {
            transition.abort();
            if (!isFastBoot) {
              UIkit.modal.confirm('Ungespeicherte Änderungen', {
                labels: { cancel: 'Änderungen verwerfen und fortfahren', ok: 'Speichern' }
              }).then(function () {
                _this4.save();
              }).catch(function () {
                _this4.set('enableTransitionCheck', false);
                transition.retry();
              });
            }
          }
        }
      },
      save: function save() {
        this.save();
      }
    }
  });
});
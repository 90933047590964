define('webapp/components/account/crm/product-formats', ['exports', 'webapp/utils/find-by'], function (exports, _findBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AVAILABLE_FORMATS = [{
    name: "Ganze Seite (1/1)",
    standardPrice: 260
  }, {
    name: "Doppelseite (2/1)",
    standardPrice: 390
  }, {
    name: "Ganze Seite (U4)",
    standardPrice: 590
  }, {
    name: "Online-Eintrag",
    standardPrice: 290
  }, {
    name: "Online-Promotion",
    standardPrice: 190
  }];

  exports.default = Ember.Component.extend({
    displayedFormats: Ember.computed('productFormats.[]', function () {
      var availableFormats = Ember.copy(AVAILABLE_FORMATS, true);
      var productFormats = this.get('productFormats');

      return availableFormats.map(function (format) {
        var foundFormat = (0, _findBy.default)(productFormats, 'name', format.name);
        var retFormat = foundFormat || format;

        return { checked: !!foundFormat, item: retFormat };
      });
    }),

    actions: {
      toggleSelect: function toggleSelect(item) {
        var productFormats = this.get('productFormats');
        if (item.checked) {
          productFormats.removeObject((0, _findBy.default)(productFormats, 'name', item.item.name));
        } else {
          productFormats.pushObject(item.item);
        }
      }
    }
  });
});
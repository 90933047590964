define("webapp/locales/tr/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.entryBannerImageNew": "Girişiniz için bir ana görsel belirleyin.",
    "account.ui.profileBannerImageNew": "Profiliniz için bir ana görsel belirleyin.",
    "account.ui.register": "Kayıt Olun",
    "account.ui.registeredAs": "Şu anda şu şekilde kayıtlısınız",
    "account.ui.personate": "Kullanıcı kimliğini değiştirme",
    "account.ui.userNameNotEditableTooltip": "Kullanıcı adınız. Girmiş olduğunuz kullanıcı adını değiştiremezsiniz. Kullanıcı adınızı değiştirmek için lütfen irtibat kişinizle iletişime geçin. Önemli: Değişiklikten sonra yeni kullanıcı adı ile tekrar giriş yapmalısınız.",
    "account.ui.additionalDeliveryAddresses": "Farklı teslimat adresleri girdiniz.",
    "account.ui.editDeliveryAddresses": "Teslimat adresini düzenleme",
    "account.ui.editInvoiceAddress": "Şirket adresini düzenle",
    "account.ui.noDeliveryAddresses": "Henüz farklı bir teslimat adresi girmediniz.",
    "account.ui.addDeliveryAddresses": "Ekle {{further}} Farklı bir teslimat adresi ekleyin",
    "account.ui.addDeliveryAddressTitle": "Teslimat adresi ekle",
    "account.ui.editDeliveryAddressTitle": "Teslimat adresini düzenleme",
    "account.ui.isDefaultAddress": "Bu standart teslimat adresidir",
    "account.ui.saveToApplyChanges": "Değişikliği kaydetmelisiniz, ardından örneğin farklı teslimat adresleri belirleyebilirsiniz.",
    "accountTypes.aussteller": "Katılımcı",
    "accountTypes.gastgeber": "Ev sahibi",
    "accountTypes.veranstalter": "Organizatör",
    "application.beta.infotext": "Hiçbir abonelik oluşturulmamıştır.",
    "application.breadcrumbsHome": "Ev",
    "application.month": "her ay",
    "application.newsletter.alreadySubscribed": "Halihazırda bir aboneyseniz {{list}} Bülten. Bülten aboneliklerine hesabınızdan erişebilirsiniz.",
    "application.newsletter.alreadySubscribedNoList": "Haber bülteni için zaten kayıt oldunuz",
    "application.newsletter.goToList": "Çağrı posta listesi",
    "application.newsletter.noAccess": "Sunulan haber bültenine sizin izninizle abone olunamaz.",
    "application.newsletter.subscribeButton": "Haber bültenine abone olmak için e-posta adresinizi girin",
    "application.newsletter.subscribeButtonMobile": "E-posta",
    "application.newsletter.subscribe": "Abone Olun",
    "application.newsletter.subscribeList": "Bu bültene abone oluyorsunuz:",
    "application.newsletter.subscribeThanks": "Haber bültenimize abone olduğunuz için teşekkür ederiz. En son haber bültenini kısa süre içinde alacaksınız.",
    "application.newsletter.subscribeThanksOptIn": "Haber bültenimize abone olduğunuz için teşekkür ederiz. Aktivasyon için başka bir katılım onay e-postası alacaksınız.",
    "application.newsletter.subscribeWithAccount": "Mevcut oturum açmış kullanıcınızla abone olun.",
    "application.noCosts": "Ücretsiz",
    "application.placeholderImageText": "İllüstrasyon olmadan",
    "application.searchAllGroups": "hepsinde",
    "application.searchAllRegions": "her yerde",
    "application.ui.access": "erişim",
    "application.ui.action": "Eylem",
    "application.ui.addDocument": "Belge ekleyin",
    "application.ui.addRelation": "Bağlantı ekle",
    "application.ui.addImage": "Resim ekle",
    "application.ui.addImages": "Resim ekle",
    "application.ui.addMedia": "Resim/video ekleyin",
    "application.ui.addVideo": "Video ekle",
    "application.ui.advancedEdit": "Genişletilmiş",
    "application.ui.all": "Tümü",
    "application.ui.alreadyFavorite": "Bu ürün izleme listenizde",
    "application.ui.makeFavorite": "Bu ürünü izleme listenize ekleyin",
    "application.ui.also": "Ayrıca",
    "application.ui.alphabetical": "Alfabetik",
    "application.ui.and": "ve",
    "application.ui.at": "at",
    "application.ui.in": "içinde",
    "application.ui.atHour": "at",
    "application.ui.hour": "Saat",
    "application.ui.back": "geri",
    "application.ui.buy": "satın al",
    "application.ui.certificate": "Sertifikalar",
    "application.ui.confirmDeleteEntry": "Girişi sil {{title}} geri dönülmez bir şekilde silmek mi?",
    "application.ui.contact": "İletişim",
    "application.ui.count": "Sayı",
    "application.ui.currentlyActive": "Şu anda çalışıyor",
    "application.ui.deleteEntry": "silme",
    "application.ui.discardUnsavedChanges": "Değişiklikleri atın ve devam edin",
    "application.ui.discardUnsavedChangesMobile": "Iskarta",
    "application.ui.distance": "Mesafe",
    "application.ui.distant": "kaldırıldı",
    "application.ui.dreampoints": "Dreampoints",
    "application.ui.dreampointsAllDreams": "Girişlerinizin hayal noktaları",
    "application.ui.dreampointsDonate": "Bağış için Dreampoints",
    "application.ui.dreampointsTooltip": "Girişinizle Dreampuan toplayabilirsiniz",
    "application.ui.edit": "değişim",
    "application.ui.editDescription": "Açıklama düzenle",
    "application.ui.editEntry": "Düzenle",
    "application.ui.editEvent": "Etkinliği düzenle",
    "application.ui.editImage": "Resmi düzenle",
    "application.ui.editPoi": "Konumu düzenle",
    "application.ui.editProduct": "Ürün düzenleme",
    "application.ui.editProfile": "Profilimi düzenle",
    "application.ui.editShownProfile": "Profil ekranımı değiştir",
    "application.ui.entriesFocus": "Focus'ta seçilmiş girişler:",
    "application.ui.entriesLatest": "Focus'a son girişler:",
    "application.ui.email": "E-posta",
    "application.ui.for": "için",
    "application.ui.from": "itibaren",
    "application.ui.fullView": "Genel görünüm",
    "application.ui.gallery": "Görüntüler",
    "application.ui.galleryImages": "galeriye...",
    "application.ui.gavePoints": "sana verdi {{points}} Puan.",
    "application.ui.goToEntry": "Çağrı girişi",
    "application.ui.goToEvent": "Etkinlik çağrısı",
    "application.ui.goToPoi": "Çağrı yeri",
    "application.ui.goToMeasuring": "Ölçüm noktaları",
    "application.ui.goToProduct": "Ürünü arayın",
    "application.ui.hasBeen": "oldu",
    "application.ui.here": "Yakınlarda",
    "application.ui.invalidEmbed": "Bu URL gömülemez.",
    "application.ui.imprintLink": "Bu alt sayfanın içeriğinden sorumlu ve yasal olarak sorumlu {{entry}} - Künye",
    "application.ui.isActive": "Aktif",
    "application.ui.isNotActive": "Engelli",
    "application.ui.knowMore": "Tümü {{aboutPre}} {{about}}",
    "application.ui.language.de": "Alman",
    "application.ui.language.en": "İngilizce",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "İtalyan",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "Şu anda seçili dil",
    "application.ui.language.switchTo": "Dili şu şekilde değiştirin ",
    "application.ui.mailBodyPre": "Merhaba, ilginç bir şey buldum:",
    "application.ui.mailSubject": "İlginç bir bağlantı buldum:",
    "application.ui.mandatory": "Zorunlu alan",
    "application.ui.memberSince": "O zamandan beri üye",
    "application.ui.mobileRotate": "Tam ekran görünüm için döndürün",
    "application.ui.more": "daha fazla",
    "application.ui.name": "İsim",
    "application.ui.navigateOneLevelUp": "Ana klasöre geri dön",
    "application.ui.new": "Yeni",
    "application.ui.newEntry": "Yeni giriş oluştur",
    "application.ui.newEvent": "Yeni etkinlik oluştur",
    "application.ui.newPoi": "Yeni konum oluştur",
    "application.ui.newProduct": "Yeni ürün oluşturma",
    "application.ui.noEntry": "Henüz bir giriş oluşturmadınız. İlk girişinizi hemen şimdi oluşturun...",
    "application.ui.noEntryProfile": "henüz bir giriş oluşturmadı.",
    "application.ui.noEvent": "Henüz bir etkinlik oluşturmadınız. İlk etkinliğinizi hemen şimdi oluşturun...",
    "application.ui.noPoi": "Henüz bir konum oluşturmadınız. İlk konumunuzu hemen şimdi oluşturun...",
    "application.ui.noJob": "Henüz bir iş oluşturmadınız. İlk işinizi şimdi oluşturun...",
    "application.ui.noProduct": "Henüz bir ürün oluşturmadınız. İlk ürününüzü hemen şimdi oluşturun...",
    "application.ui.notEditable": "değiştirilemez",
    "application.ui.nothingFound": "Bu arama için herhangi bir sonuç bulamadık.",
    "application.ui.offer": "Teklifler",
    "application.ui.offer.offers": "Teklifler",
    "application.ui.offer.acceptOffer": "Teklifi kabul et",
    "application.ui.offer.rejectOffer": "Teklifi reddet",
    "application.ui.offer.noOffers": "Teklif yok",
    "application.ui.offer.expiresAt": "Teklif şu tarihe kadar geçerlidir",
    "application.ui.offer.offerExpires": "Teklifin süresi doluyor",
    "application.ui.offer.setOtherExpirationDate": "Başka bir tarih belirleyin",
    "application.ui.offer.inXDays": "30 gün içinde",
    "application.ui.only": "Sadece",
    "application.ui.openDocument": "Dosya aç",
    "application.ui.or": "veya",
    "application.ui.playVideo": "Video oynat",
    "application.ui.readMore": "Daha fazla bilgi edinin",
    "application.ui.region": "Bölge",
    "application.ui.relevance": "Alaka düzeyi",
    "application.ui.reset": "Tüm filtreleri sıfırla",
    "application.ui.routingDescription": "Yol Tarifi",
    "application.ui.saveShownProfile": "Profil değişikliklerini kaydet",
    "application.ui.saveUnsavedChanges": "Kaydet",
    "application.ui.searchEnter": "Arama terimini girin ve ⏎ tuşuna basın.",
    "application.ui.select": "Lütfen seçiniz",
    "application.ui.shop": "Mağaza",
    "application.ui.showAllEntries": "Tüm girişleri göster",
    "application.ui.showAllSearch": "Tüm arama sonuçlarını göster",
    "application.ui.showMoreSearch": "Daha fazla arama sonucu göster",
    "application.ui.resetSearch": "Seçimi sıfırlayın ve tekrar arayın",
    "application.ui.sort": "Sıralama",
    "application.ui.stockPhotoLinks": "Görüntü veritabanlarına bağlantılar",
    "application.ui.suitableFor": "Şunlar için önerilir",
    "application.ui.total": "Toplam",
    "application.ui.totalDreampoints": "Tüm girişlerinizin hayal noktaları",
    "application.ui.trySearch": "Diğer arama terimlerini denemek ister misiniz, örneğin",
    "application.ui.type": "Tip",
    "application.ui.unsavedChanges": "Kaydedilmemiş değişiklikler",
    "application.ui.updated": "güncellendi",
    "application.ui.uploadedFileFor": "İçin dosya yüklendi:",
    "application.ui.userName": "Kullanıcı Adı",
    "application.ui.viewAsList": "Liste görünümü",
    "application.ui.viewAsTable": "Tablo görünümü",
    "application.ui.visibleForAll": "Tüm kullanıcılar için görünür",
    "application.ui.visibleForFollower": "Takipçiler için görünür",
    "application.ui.visibleForJoiner": "Birleştiriciler için görünür",
    "application.ui.visibleForNone": "Kimse tarafından görülemez",
    "application.ui.visibleForSupporter": "Destekçiler için görünürlük",
    "application.ui.years": "Yıllar",
    "application.ui.yearsFew": "Bebek yaşı",
    "application.widgets.highestRatedEntries": "En Dreampoints",
    "application.widgets.inFocus": "Odak noktası",
    "application.widgets.newestEntries": "En son {{size}} Girişler",
    "application.widgets.noNewestEntries": "Yeni kayıt bulunamadı...",
    "application.widgets.showCategories": "Kategori filtresini göster",
    "application.widgets.showFilter": "Daha fazla filtre göster",
    "application.ui.writeUs": "Bize yazın...",
    "blog.ui.category": "Kategori",
    "blog.ui.categoryHeading": "Kategoriden",
    "blog.ui.lastUpdated": "Son güncelleme",
    "blog.ui.openSinglePost": "Daha fazla bilgi edinin",
    "blog.ui.publishedIn": "Yayınlandığı yer",
    "blog.ui.subCategories": "Alt Kategoriler",
    "blog.ui.written": "Yazılı",
    "blog.ui.writtenBy": "Tarafından yazıldı",
    "buttons.alreadyMeToo": "Senin de bu yazıyı hayal etmene sevindim.",
    "buttons.alreadyMeTooMobile": "Senin de bu yazıyı hayal etmene sevindim.",
    "buttons.cancel": "İptal",
    "buttons.confirm": "Onaylayın",
    "buttons.createAccount": "EntryerAccount'u şimdi kurun",
    "buttons.delete": "silme",
    "buttons.deleteFavorite": "Artık takip etmiyorum",
    "buttons.deleteLike": "Artık benzemek yok",
    "buttons.meToo": "Ben de bunu yapmak istiyorum.",
    "buttons.newPassword": "Yeni şifre iste",
    "buttons.ok": "Tamam.",
    "buttons.ready": "Hazır",
    "buttons.save": "Değişiklikleri kaydet",
    "buttons.saveMobile": "Kaydet",
    "buttons.selectThis": "Bunu seçin",
    "buttons.tryAgain": "Lütfen tekrar deneyin",
    "category.ui.hideCategories": "Alt kategorileri gizle",
    "category.ui.oneLevelUp": "Üst kategoriye geri dönün",
    "category.ui.showCategories": "Alt kategorileri göster",
    "categoryFields.attachmentWidth": "Genişlik uygulayın",
    "categoryFields.clutch": "Kaplin",
    "categoryFields.companyName": "Şirket adı",
    "categoryFields.dealerInfo": "Bayi bilgileri",
    "categoryFields.diameter": "Çap",
    "categoryFields.displayNetPrice": "Net fiyat",
    "categoryFields.electricalPower": "Elektrik gücü",
    "categoryFields.engine": "Motor",
    "categoryFields.engineDescription": "Motor ekipmanları",
    "categoryFields.ERPname": "Ürün yönetiminde isim",
    "categoryFields.horsePower": "Güç",
    "categoryFields.netWidth": "Lastiksiz iz genişliği",
    "categoryFields.packingUnit": "Paketleme birimi",
    "categoryFields.PTO": "PTO",
    "categoryFields.relativeGauge": "Pisti şu kadar genişletir",
    "categoryFields.vendorAcronym": "Kısa tanımlama",
    "categoryFields.volume": "Cilt",
    "categoryFields.weight": "Ağırlık",
    "categoryFields.wheelLock": "Stand tekerlek kilidi",
    "checkout.cart.title": "Alışveriş sepetim",
    "checkout.cart.item": "Makale",
    "checkout.cart.amount": "Sayı",
    "checkout.cart.edit": "Değişim",
    "checkout.cart.price": "Fiyat",
    "checkout.cart.singlePrice": "Birim fiyat",
    "checkout.cart.product.isNonPhysical": "Ürün elektronik olarak/indirme yoluyla gönderilir.",
    "checkout.cart.product.requiresExpressDelivery": "EXPRESS kargo ile taze ürün. Sadece D içinde.",
    "checkout.cart.product.deliveryProblem": "Seçilen ürün teslimat gecikmelerine tabi olabilir.",
    "checkout.cart.subtotal": "Ara Toplam",
    "checkout.cart.deliveryCosts": "Nakliye masrafları",
    "checkout.cart.total": "Toplam",
    "checkout.cart.help": "Sipariş süreci hakkında sorularınız mı var? Sizin için önemli bilgileri derledik.",
    "checkout.cart.subtotalInfo": "KDV dahil",
    "checkout.cart.subtotalShipment": "artı tahmini nakliye masrafları",
    "checkout.cart.checkout": "Ödeme",
    "checkout.cart.checkoutWithoutUser": "Müşteri hesabı olmadan ödeme",
    "checkout.cart.checkoutFast": "Hızlı ödeme",
    "checkout.cart.deliveryInfo": "Alışveriş sepetindeki ürünler için gönderim tarihi",
    "checkout.cart.orderSuccess": "Siparişiniz başarılı oldu.",
    "checkout.cart.orderCommitting": "Siparişiniz işleme alınıyor - lütfen bekleyin",
    "checkout.cart.myOrder": "Benim siparişim",
    "checkout.cart.leftover": "Sol",
    "checkout.checkout.orderNumber": "Sipariş numarası",
    "checkout.checkout.account": "Müşteri hesabı",
    "checkout.checkout.alreadyAccount": "Zaten müşteri misiniz? Kayıt olmak için buraya tıklayın.",
    "checkout.checkout.andOrder": "ve düzen",
    "checkout.checkout.delivery.title": "Adresler",
    "checkout.checkout.delivery": "Teslimat ve teslimat adresi",
    "checkout.checkout.delivery.type": "Sevkiyat şu şekilde gerçekleşir",
    "checkout.checkout.delivery.street": "Adres satırı 1",
    "checkout.checkout.delivery.houseNumber": "Hayır.",
    "checkout.checkout.delivery.co": "Adres son eki (c/o)",
    "checkout.checkout.delivery.city": "Şehir",
    "checkout.checkout.delivery.selectCountry": "Lütfen ülke seçiniz",
    "checkout.checkout.delivery.addressBoth": "Fatura ve teslimat adresi",
    "checkout.checkout.delivery.addressInvoice": "Fatura adresi",
    "checkout.checkout.delivery.addressDelivery": "Teslimat adresi",
    "checkout.checkout.delivery.default": "Bu adresi varsayılan teslimat adresim olarak ayarla",
    "checkout.checkout.delivery.otherAddress": "Teslimat adresinin fatura adresinden farklı olması",
    "checkout.checkout.delivery.dhlNr": "DHL Posta Numaranız",
    "checkout.checkout.delivery.packingStation": "Paketleme istasyonu numarası",
    "checkout.checkout.delivery.showPackingStations": "Adresimin çevresindeki Paket İstasyonlarını göster",
    "checkout.checkout.delivery.wantExpressShipping": "Hızlı teslimat",
    "checkout.checkout.delivery.isPickUp": "Yerinde toplama",
    "checkout.checkout.delivery.addressNotVerified": "Seçtiğiniz adres tam değil veya henüz doğrulanmadı. Size teslimat yapamıyoruz. Adresinizi tamamlamak için lütfen adresiniz için kalem sembolüne tıklayın. Sipariş süreciyle ilgili herhangi bir sorunuz varsa, müşteri destek ekibimiz size yardımcı olmaktan mutluluk duyacaktır.",
    "checkout.checkout.delivery.specialCountryInfo": "Ülkeniz seçilemiyor mu?",
    "checkout.checkout.delivery.specialCountry": "Ülkeniz standart gönderimimizin dışında",
    "checkout.checkout.delivery.specialCountryMessage": "Alışveriş sepetinizdeki münferit ürünler tüm ülkelere gönderilemez. Lütfen ülkeniz seçilinceye kadar bazı ürünleri siparişinizden çıkarın veya bizimle iletişime geçin.",
    "checkout.checkout.payment": "Ödeme yöntemi",
    "checkout.checkout.paymentProblem": "Ödeme gerçekleştirilirken bir hata oluştu",
    "checkout.checkout.backToPayment": "Ödeme ayarlarına geri dönün.",
    "checkout.checkout.paymentTitle": "Bizimle ödeme yaparsınız ...",
    "checkout.checkout.paymentSelected": "Seçilen ödeme yöntemi",
    "checkout.checkout.payment.cc": "Kredi kartı",
    "checkout.checkout.payment.ccInfo": "AB içindeki kart ödemeleri için, çevrimiçi ödeme yaptığınız her seferde kimliğinizin onaylanması gerekir. Siparişinizi verdikten sonra, bankanız tarafından sağlanan onay süreci boyunca size rehberlik edilecektir.",
    "checkout.checkout.payment.complimentary": "ücretsiz",
    "checkout.checkout.payment.paypal": "Paypal, banka havalesi veya kredi kartı",
    "checkout.checkout.payment.paypalButton": "Paypal hesabı olmadan bile diğer ödeme yöntemleriyle",
    "checkout.checkout.payment.paypalInfo": "Ödeme işlemini tamamlamak için PayPal'a yönlendirileceksiniz. PayPal hesabınızdan yalnızca bir sonraki adımda siparişi onayladığınızda para çekilecektir.",
    "checkout.checkout.payment.paypalInfoExtended": "Ödeme işlemini tamamlamak için PayPal'a yönlendirileceksiniz. Hesabınızdan veya PayPal hesabınızdan yalnızca bir sonraki adımda siparişi onayladığınızda para çekilecektir.",
    "checkout.checkout.payment.sepa": "Doğrudan borçlandırma (SEPA)",
    "checkout.checkout.payment.sepaLegal": "İzin veriyorum {{siteOwner}}, {{siteOwnerAddress}} (Alacaklı Kimliği: {{siteOwnerCreditorID}}) hesabımdan otomatik ödeme yoluyla tahsilat yapmasına izin veriyorum. Aynı zamanda, bankama aşağıdaki hesaplardan otomatik ödeme talimatı veriyorum {{siteOwner}} hesabıma otomatik ödeme ile.",
    "checkout.checkout.payment.noSepa": "Fatura veya teslimat adresiniz AB dışında. Bu nedenle, size otomatik ödeme ile ödeme teklif edemiyoruz.",
    "checkout.checkout.payment.sepaInfo": "Hızlı ve karmaşık olmayan. Siparişinizi tamamlarken (bir sonraki adım), banka bilgilerinizi (IBAN) girmeniz istenecektir. Lütfen bunları hazır bulundurun. Transfer tamamlandığında bir onay alacaksınız.",
    "checkout.checkout.payment.prepayment": "Ön ödeme (banka havalesi)",
    "checkout.checkout.payment.prepaymentInfo": "Siparişi verdikten sonra, banka havalesi bilgilerini size e-posta ile göndereceğiz. Siparişinizdeki ürünleri yalnızca en fazla 7 gün için rezerve edebiliriz. Lütfen banka havalesi için bu zaman aralığını göz önünde bulundurun. Ödeme elimize ulaşır ulaşmaz siparişiniz gönderilecektir.",
    "checkout.checkout.payment.invoice": "Fatura",
    "checkout.checkout.payment.invoiceInfo": "Fatura tutarı, e-posta ile gönderim onayında belirtilen ödeme tarihinde ödenmelidir.",
    "checkout.checkout.payment.noInvoice": "Maalesef, bu sipariş için size fatura ile ödeme sunamıyoruz. Lütfen diğer ödeme yöntemlerinden birini seçin.",
    "checkout.checkout.payment.sofort": "Anında banka transferi",
    "checkout.checkout.payment.sofortInfo": "Siparişinizi tamamlarken (bir sonraki adım), banka bilgilerinizi (IBAN) girmeniz istenecektir. Lütfen bunları hazır bulundurun. Transfer tamamlandığında bir onay alacaksınız.",
    "checkout.checkout.payment.accountName": "Hesap sahibi",
    "checkout.checkout.payment.accountEmail": "Onay e-posta adresi",
    "checkout.checkout.sepaAndOrder": "Otomatik ödeme talimatı {{totalAmount}} € onaylayın ve masraflarla birlikte sipariş verin",
    "checkout.checkout.sofortAndOrder": "Transfer yetkisi {{totalAmount}} € onaylayın ve masraflarla birlikte sipariş verin",
    "checkout.checkout.title": "Ödeme",
    "checkout.checkout.next": "daha fazla",
    "checkout.checkout.orderPre": "Siparişinizi tamamlamak için \"Şimdi satın al \"a tıklayın",
    "checkout.checkout.order": "Şimdi satın al",
    "checkout.checkout.orderConsentPre": "Bir sipariş vererek aşağıdakileri kabul etmiş olursunuz",
    "checkout.checkout.orderConsentPost": "Katılıyorum.",
    "checkout.checkout.noAccount": "Müşteri hesabı oluşturmayın",
    "checkout.redirect.countryPriceChange": "Ülkeniz için fiyatlar standart fiyattan farklıdır. Lütfen fiyat değişikliğini onaylayın.",
    "comments.blockComments": "Bu gönderi için yorum yapma kapatılmıştır.",
    "comments.comment.anonymous": "Anonim",
    "comments.comment.button": "Yeni bir yorum yaz",
    "comments.comment.buttonReply": "Cevaplar",
    "comments.comment.cancel": "İptal",
    "comments.comment.content": "Sizin yorumunuz",
    "comments.comment.hasCommented": "yorumlar",
    "comments.comment.login": "Yorum göndermek için giriş yapın",
    "comments.comment.past": "var",
    "comments.comment.replyFrom": "Yanıt",
    "comments.comment.showReplies": "Cevapları göster",
    "comments.comment.showReply": "Cevabı göster",
    "comments.comment.submit": "Gönder",
    "comments.comment.title": "Yorum başlığı",
    "comments.comment.userName": "Kullanıcı adınız",
    "comments.heading": "Öneriler, notlar, yorumlar",
    "comments.hierarchy.flat": "Düz",
    "comments.hierarchy.nested": "Genested",
    "comments.hierarchy.title": "Yorum görünümü:",
    "comments.message.afterSaveError": "Yorumunuz şu anda kaydedilemiyor!",
    "comments.message.afterSaveSuccess": "Yorumunuz kaydedildi.",
    "comments.message.afterSaveUnlock": "Yorumunuz kaydedildi, ancak hala bir yönetici tarafından kontrol edilmesi gerekiyor.",
    "comments.noComments": "Henüz yorum yapılmadı",
    "comments.reply": "AN:",
    "comments.show": "Yorumları göster",
    "comments.title": "Yorumlar",
    "entries.edit": "Giriş <strong>{{title}}</strong> Düzenle",
    "entries.entries": "Girişler",
    "entries.product.ui.chooseVariant": "Bir varyant seçin",
    "entries.product.ui.chooseVariantMobile": "Varyant seçin",
    "entries.product.ui.combinationNotAvailable": "Kombinasyon mümkün değil",
    "entries.entry.fields.address.city": "Şehir",
    "entries.entry.fields.address.country": "Ülke",
    "entries.entry.fields.address.houseNumber": "Ev numarası",
    "entries.entry.fields.address.street": "Sokak",
    "entries.entry.fields.address.zip": "POSTA KODU",
    "entries.entry.fields.language": "Dil",
    "entries.entry.fields.prefixMissing": "Aşağıdaki alanlar doldurulmamıştır:",
    "entries.entry.profile.edit": "Profili düzenle",
    "entries.entry.profile.publicProfile": "herkese açık profil sayfama",
    "entries.entry.tooltip.alreadyCopied": "Zaten bu girişi hayal ediyorsunuz.",
    "entries.entry.tooltip.alreadyFollow": "Bu girişi zaten takip ediyorsunuz",
    "entries.entry.tooltip.alreadyLiked": "Bu girişi zaten beğendiniz",
    "entries.entry.tooltip.copied": "Sizin de bu yazıyı hayal etmek istemeniz çok güzel. Profil resminiz bu girişe eklendi.",
    "entries.entry.tooltip.filterComments": "Giriş satırında yalnızca Yorumları göstermek için buraya tıklayın",
    "entries.entry.tooltip.filterFollows": "Giriş satırında yalnızca Takip Edilenleri göstermek için buraya tıklayın",
    "entries.entry.tooltip.filterLikes": "Giriş satırında sadece beğenileri göstermek için buraya tıklayın",
    "entries.entry.tooltip.filterShares": "Sadece giriş satırındaki hisseleri göstermek için buraya tıklayın",
    "entries.entry.tooltip.follow": "Bu girişi takip etmek için buraya tıklayın",
    "entries.entry.tooltip.like": "Bu girişi beğenmek için buraya tıklayın",
    "entries.entry.tooltip.noFollow": "Takip et yalnızca başkalarının girişleri için çalışır",
    "entries.entry.tooltip.noFollowNotLoggedIn": "Bu girişi takip edebilmek için giriş yapmış olmalısınız",
    "entries.entry.tooltip.noLike": "Beğen sadece başkalarının girişleri için çalışır",
    "entries.entry.ui.access": "Bu girişi sadece aşağıdakiler görebilir",
    "entries.entry.ui.added": "...eklendi.",
    "entries.entry.ui.addEntry": "Girişleri ekleyin",
    "entries.entry.ui.addOpening": "Açılış saatini ekleyin",
    "entries.entry.ui.address": "Adres verileri",
    "entries.entry.ui.addStep": "Bir adım ekleyin",
    "entries.entry.ui.agreementHours": "Anlaşmaya göre açık",
    "entries.entry.ui.allAccess": "Bu girişi herkes görebilir",
    "entries.entry.ui.assignedCategories": "atanmış kategoriler",
    "entries.entry.ui.assignedCertificates": "Sertifikalarım",
    "entries.entry.ui.assignedProducts": "Atanmış ürünler",
    "entries.entry.ui.businessContact": "Şirket iletişim verileri",
    "entries.entry.ui.categories": "Bu giriş için kategoriler",
    "entries.entry.ui.categoryFields": "Daha fazla bilgi",
    "entries.entry.ui.commercialTitle": "Girişteki şirket bilgileri",
    "entries.entry.ui.companyTitle": "Şirket bilgilerim",
    "entries.entry.ui.contactPerson": "İletişim bilgilerim",
    "entries.entry.ui.cssClass": "İçerik için CSS sınıfı",
    "entries.entry.ui.deliveryInfo": "Teslimat hizmeti bilgileri",
    "entries.entry.ui.descriptionInfo": "İpucu: Kelimeler işaretlendiğinde stiller atanabilir.",
    "entries.entry.ui.descriptionPlaceholder": "HTML ile açıklama. HTML işlev çubuğu için metne çift tıklayın (maksimum 600 karakter).",
    "entries.entry.ui.editEntry": "Girişi düzenle",
    "entries.entry.ui.editEntryNew": "Giriş oluştur",
    "entries.entry.ui.exclusionHours": "Dışlama süreleri",
    "entries.entry.ui.galleryImages": "Resim galerisi (düzenlemeye bağlı olarak 2 veya 3 resim)",
    "entries.entry.ui.globalTitle": "İsim",
    "entries.entry.ui.hasKiosk": "Bir tezgahım var",
    "entries.entry.ui.imagesHorizontal": "Yatay",
    "entries.entry.ui.imagesInfo": "Görüntüler \"Görüntüler/Videolar/Belgeler\" altında düzenlenebilir.",
    "entries.entry.ui.imagesOrientation": "Görüntü hizalama",
    "entries.entry.ui.imagesSquared": "Kare",
    "entries.entry.ui.imagesVertical": "Dikey",
    "entries.entry.ui.isListImage": "Bu görüntü liste görüntüsüdür",
    "entries.entry.ui.isMainImage": "Bu resim ana resimdir",
    "entries.entry.ui.isMainImageAndListImage": "Bu görüntü girişin ana görüntüsü olarak görünür",
    "entries.entry.ui.jobApply": "Şimdi başvurun",
    "entries.entry.ui.kioskDescription": "Satış standınızın tanımı",
    "entries.entry.ui.listImage": "Liste resmi",
    "entries.entry.ui.logo": "Logo",
    "entries.entry.ui.longDescription": "Uzun açıklama metni",
    "entries.entry.ui.mainImage": "Ana resim",
    "entries.entry.ui.makeListImage": "Bu görüntüyü liste görüntüsü olarak ayarlayın",
    "entries.entry.ui.makeListImageTooltip": "Arama sonuçları liste resmiyle birlikte bir liste olarak görüntülenir. Herhangi bir liste resmi seçilmezse, ana resim kullanılır.",
    "entries.entry.ui.makeMainImage": "Bu görüntüyü ana görüntü olarak ayarlayın",
    "entries.entry.ui.makeMainImageTooltip": "Ana resim profilin üst kısmında görüntülenir. Ana resim veya liste resmi olarak seçilmeyen resimler resim galerisinde görüntülenir.",
    "entries.entry.ui.metaDescription": "Kısa açıklama",
    "entries.entry.ui.metaDescriptionPlaceholder": "En fazla 200 karakterlik kısa açıklama",
    "entries.entry.ui.multiCode": "Bu adım için ek kod",
    "entries.entry.ui.multiDescription": "Bu adımın açıklaması",
    "entries.entry.ui.multiImages": "Bu adım için görseller",
    "entries.entry.ui.multiProgress": "İlerleme",
    "entries.entry.ui.multiTitle": "Bu adım için başlık",
    "entries.entry.ui.multiTitlePlaceholder": "Bu adım için başlık (maksimum 50 karakter)",
    "entries.entry.ui.noDescriptions": "Girişin henüz açıklayıcı bir metni yok.",
    "entries.entry.ui.noEntries": "Kayıt bulunamadı.",
    "entries.entry.ui.onlyVisibleForCustomers": "Bu giriş sadece kayıtlı müşteriler tarafından görülebilir",
    "entries.entry.ui.openAt": "Açık",
    "entries.entry.ui.openingHours": "Çalışma saatleri",
    "entries.entry.ui.openingInfo": "Çalışma saatleri hakkında notlar",
    "entries.entry.ui.openingReligious": "Ağırlıklı olarak Katoliklerin yaşadığı bir bölgede adres",
    "entries.entry.ui.overallProgress": "Bu giriş {{value}}Gerçekleşen %",
    "entries.entry.ui.parking": "Park olanakları",
    "entries.entry.ui.parkingLots": "Sayı",
    "entries.entry.ui.parkingType": "Sanat",
    "entries.entry.ui.personImage": "İrtibat kurulacak kişinin resmi",
    "entries.entry.ui.relatedEvents": "İlişkili etkinlikler",
    "entries.entry.ui.relatedJobs": "Bağlantılı işler",
    "entries.entry.ui.relatedArticles": "İlişkili makaleler",
    "entries.entry.ui.relatedCampaigns": "İlişkili kampanyalar",
    "entries.entry.ui.rentKiosk": "Bir satış standı kiralamak istiyorum",
    "entries.entry.ui.resolveAddress": "Koordinatları belirleyin",
    "entries.entry.ui.resolveAddressInfo": "Lütfen adresinizden coğrafi noktayı (harita üzerindeki konum) belirleyin. Doğru coğrafi nokta olmadan, doğru şekilde bulunamayabilirler.",
    "entries.entry.ui.resolveGeolocation": "Adres al",
    "entries.entry.ui.routingPlaceholder": "Yönergelerinizi HTML ile buraya girin.",
    "entries.entry.ui.selectEvent": "Etkinlik seçin",
    "entries.entry.ui.selectJob": "İş seçin",
    "entries.entry.ui.selectArticle": "Makale seçiniz",
    "entries.entry.ui.selectCampaign": "Kampanya seçin",
    "entries.entry.ui.sharing": "Bu sayfa {{sharing}} Paylaş",
    "entries.entry.ui.shareMail": "Bu sayfayı posta ile paylaşın",
    "entries.entry.ui.shortDescriptionInfo": "Aynı zamanda kısa açıklama",
    "entries.entry.ui.skillsNeeded": "Giriş için iyi bir şekilde kullanabilirim....",
    "entries.entry.ui.sortword": "Sıralama için alternatif başlıklar",
    "entries.entry.ui.step": "Adım",
    "entries.entry.ui.stepInDream": "Bu adım girişin hangi aşamasındadır?",
    "entries.entry.ui.stepInDreamAfter": "gerçekleştirildikten sonra",
    "entries.entry.ui.stepInDreamBefore": "gerçekleştirmeden önce",
    "entries.entry.ui.stepInDreamDuring": "gerçekleştirilmesi sırasında",
    "entries.entry.ui.subtitle": "Açıklayıcı altyazı",
    "entries.entry.ui.subtitlePlaceholder": "Altyazıyı girin (maksimum 60 karakter)",
    "entries.entry.ui.tabCategories": "Kategoriler",
    "entries.entry.ui.tabCategoriesMobile": "Kedi.",
    "entries.entry.ui.tabCompany": "Şirket verileri",
    "entries.entry.ui.tabDescription": "Açıklama",
    "entries.entry.ui.tabDescriptionMobile": "Beschr.",
    "entries.entry.ui.tabExhibitor": "Katılımcı bilgileri",
    "entries.entry.ui.tabImages": "Resimler/Videolar/Belgeler",
    "entries.entry.ui.tabImagesMobile": "Resimler/Videolar/Dosyalar",
    "entries.entry.ui.tabRelations": "Bağlantılar",
    "entries.entry.ui.tags": "Etiketler",
    "entries.entry.ui.title": "Giriş aşağıdaki ad altında görünür",
    "entries.entry.ui.titleError": "Giriş kaydedilmeden önce adlandırılmalıdır",
    "entries.entry.ui.titlePlaceholder": "Başlık girin (maksimum 50 karakter)",
    "entries.entry.ui.visible": "Bu giriş görülebilir",
    "entries.entry.ui.promotion": "Bu giriş için eylemlere katılım",
    "entries.entry.ui.regions": "Bu giriş için bölge kategorileri",
    "entries.job.ui.promotion": "Bu iş için yapılan eylemlere katılım",
    "entries.event.edit": "Etkinlik <strong>{{title}}</strong> Düzenle",
    "entries.event.ui.addEvent": "Etkinlik ekle",
    "entries.event.ui.cooperation": "İşbirliği ortağı",
    "entries.event.ui.directions": "Yol Tarifi",
    "entries.event.ui.editEvent": "Etkinliği düzenle",
    "entries.event.ui.editEventNew": "Etkinlik oluştur",
    "entries.event.ui.endDate": "Etkinlik sonu (gün)",
    "entries.event.ui.eventDone": "Ticaret fuarı raporu",
    "entries.event.ui.noEvents": "Etkinlik bulunamadı",
    "entries.event.ui.openEvent": "Etkinlik çağrısı",
    "entries.event.ui.opening": "Çalışma saatleri",
    "entries.event.ui.startDate": "Etkinlik başlangıcı (gün)",
    "entries.event.ui.tabCategories": "Kategoriler",
    "entries.event.ui.tabDescription": "Açıklama",
    "entries.event.ui.tabOpening": "Tarih ve saatler",
    "entries.event.ui.tabRelations": "Bağlantılar",
    "entries.event.ui.title": "Etkinlik aşağıdaki isimler altında görünür",
    "entries.event.ui.upcoming": "Özetle",
    "entries.event.ui.current": "Şu anda gerçekleşiyor",
    "entries.event.ui.history": "Çoktan gerçekleşti",
    "entries.event.ui.moreEvents": "Diğer etkinlikler",
    "entries.open": "Ön uçta girişi çağırın",
    "entries.person.ui.company": "Şirket",
    "entries.person.ui.female": "Bayan",
    "entries.person.ui.diverse": "olmadan",
    "entries.person.ui.fullName": "Adı ve soyadı",
    "entries.person.ui.fullSize": "Tam boy",
    "entries.person.ui.gender": "Cinsiyet",
    "entries.person.ui.greeting": "Selamlama",
    "entries.person.ui.male": "Bay",
    "entries.person.ui.open": "Doğrudan bu kişinin girişine",
    "entries.person.ui.personAddress": "Kişinin adresi",
    "entries.person.ui.personContact": "Kişisel iletişim bilgileri",
    "entries.person.ui.personImage": "Kişisel fotoğraf",
    "entries.person.ui.position": "Pozisyon",
    "entries.person.ui.quote": "Alıntı/başlık",
    "entries.person.ui.signature": "İmza resmi",
    "entries.poi.ui.poiEvents": "Bu lokasyondaki etkinlikler",
    "entries.poi.ui.title": "Bu yer aşağıdaki isimler altında görünmektedir",
    "entries.product.edit": "Ürün <strong>{{title}}</strong> Düzenle",
    "entries.product.fields.engine": "Motor",
    "entries.product.fields.horsePower": "Güç",
    "entries.product.menu.allAttachments": "Bir bakışta tüm ekler",
    "entries.product.menu.allCarriers": "Genel bakıştaki tüm taşıyıcı araçlar",
    "entries.product.menu.attachments": "Ekler",
    "entries.product.menu.carriers": "Taşıyıcı araçlar",
    "entries.product.tags.eco": "eko",
    "entries.product.tags.neu": "yeni",
    "entries.product.ui.addProduct": "Ürün ekle",
    "entries.product.ui.choose": "seçin",
    "entries.product.ui.discount": "İndirim",
    "entries.product.ui.discountSelect": "% indirim avantajını kullanın",
    "entries.product.ui.equipmentVariants": "Ekipman çeşitleri",
    "entries.product.ui.variants": "Varyantlar",
    "entries.product.ui.gauge": "cm cinsinden genişlik",
    "entries.product.ui.gaugeMin": "cm cinsinden minimum iz genişliği",
    "entries.product.ui.inclusive": "Dahil olmak üzere",
    "entries.product.ui.live": "{{name}} canlı deneyim",
    "entries.product.ui.netPrize": "dahil. {{tax}}KDV dahil, artı.",
    "entries.product.ui.netPrizeDefault": "yasal katma değer vergisi dahil, artı",
    "entries.product.ui.netPrizeDefaultNonPhysical": "yasal oranda KDV dahil.",
    "entries.product.ui.deliveryTerms": "Nakliye masrafları",
    "entries.product.ui.noProducts": "Ürün bulunamadı",
    "entries.product.ui.noPurchaseOnThisPage": "Bu sayfadan sipariş verilemez, bunun için ürünü arayın.",
    "entries.product.ui.onlyBasicVariant": "Sadece temel ekipmanlarda",
    "entries.product.ui.onlyWithCarrier": "Sadece temel ünite satın alındığında kullanılabilir",
    "entries.product.ui.order": "Alışveriş sepetine ekle",
    "entries.product.ui.notOrderable": "Lütfen bir varyant seçin.",
    "entries.entry.ui.products.delivery.red": "Ürün şu anda mevcut değil",
    "entries.entry.ui.products.delivery.yellow": "Ürün stokta. Muhtemelen daha uzun teslimat süresi.",
    "entries.entry.ui.products.delivery.green": "Ürünün yeterli miktarda stokta olması",
    "entries.products.ui.combinationNotAvailable": "Ürün bu kombinasyonda mevcut değildir",
    "product.ui.content": "İçerik",
    "product.ui.ingredients": "İçerik",
    "product.ui.ingredientsFood": "İçindekiler",
    "product.ui.nonPhysical": "Elektronik ürün indirme",
    "entries.product.ui.overview": "Genel Bakış",
    "entries.product.per": "Bu",
    "entries.product.perServing": "Porsiyon başına",
    "entries.product.reference": "Referans miktarı",
    "entries.product.nutritionTable": "Beslenme bilgileri",
    "entries.product.nutrientsTable": "Besinler, mineraller ve vitaminler",
    "entries.product.bio": "Kontrollü organik yetiştiricilikten elde edilen bileşenler",
    "entries.product.ui.prizeDecimalPoint": ",",
    "entries.product.ui.prizeFrom": "itibaren",
    "entries.product.ui.item": "Parça",
    "entries.product.ui.prizePerItem": "Parça başına fiyat",
    "entries.product.ui.noPrize": "Lütfen fiyat için irtibat kişinize danışın.",
    "entries.product.ui.size": "Boyut",
    "entries.product.ui.required": "Sadece",
    "entries.product.ui.suitableFor": "Şunlar için uygundur",
    "entries.product.ui.suitableForAttachments": "Aşağıdaki ataşmanlar için uygundur",
    "entries.product.ui.suitableForCarriers": "Aşağıdaki taşıyıcı araçlar için uygundur",
    "entries.product.ui.title": "Ürün",
    "entries.product.ui.variant": "Varyant/Paket",
    "entries.product.ui.variantNotOrderable": "Seçilen varyant sipariş edilemiyor",
    "entries.product.ui.inVariants": "ürün varyantları için",
    "entries.product.ui.bundleparts": "Şunlardan oluşur",
    "entries.progress.title": "Girişinizi nasıl geliştirebilirsiniz?",
    "entries.tabbar.basics": "Temel veriler",
    "entries.tabbar.entry": "Giriş verileri",
    "entries.tabbar.gallery": "Görüntüler",
    "entries.tabbar.layout": "Düzen",
    "entries.tabbar.openingHours": "Çalışma saatleri",
    "entries.tabbar.order": "Sipariş bilgileri",
    "entries.tabbar.tooltip.entryNotSavedDetail": "Giriş verileri yalnızca giriş bir başlıkla kaydedildikten sonra düzenlenebilir.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "Yerleşim verileri yalnızca giriş bir başlıkla kaydedildikten sonra düzenlenebilir.",
    "entries.types.poi": "Poi",
    "entries.types.standard": "Standart",
    "entries.ui.groupDetailName": "Grup girişleri için ad",
    "entries.ui.groupName": "Gruplar için ad (bölge)",
    "entryTypes.entry": "Giriş",
    "entryTypes.event": "Etkinlik",
    "entryTypes.poi": "Mekan",
    "errors.blank": "{{description}} doldurulmalıdır",
    "errors.description": "Bu alan",
    "errors.email": "Lütfen geçerli bir {{description}}-adres",
    "errors.empty": "{{description}} boş olmamalıdır",
    "errors.exclusion": "Bu {{description}} zaten alınmış",
    "errors.inclusion": "Bu {{description}} seçime dahil değildir",
    "errors.invalid": "{{description}} geçersizdir",
    "errors.mandatory": "{{field}} doldurulmalıdır",
    "errors.mandatoryCheckbox": "Onaylanmalıdır.",
    "errors.mandatorySubmit": "Formu yalnızca tüm zorunlu alanlar doldurulduğunda gönderebilirsiniz.",
    "errors.slug": "Slug yalnızca küçük harfler, sayılar ve \"-\" içerebilir.",
    "forms.ui.deleteFile": "Dosya silme",
    "forms.ui.formErr": "Form gönderilirken hata oluştu",
    "forms.ui.formSent": "Form gönderildi",
    "forms.ui.noForm": "Belirlenmiş bir form yok",
    "forms.ui.invalidMail": "Lütfen geçerli bir e-posta adresi girin.",
    "forms.ui.isSubmitted": "Form gönderilmiştir. Mümkün olan en kısa sürede sizinle iletişime geçeceğiz.",
    "forms.ui.missingFields": "Aşağıdaki alanlar doldurulmalıdır:",
    "forms.ui.uploadSuccess": "{{file}} yüklendi",
    "forms.ui.fieldBuilder.street": "Sokak",
    "forms.ui.fieldBuilder.houseNumber": "Hayır.",
    "forms.ui.fieldBuilder.zip": "POSTA KODU",
    "forms.ui.fieldBuilder.city": "Şehir",
    "forms.ui.fieldBuilder.country": "Ülke",
    "forms.ui.fieldBuilder.firstName": "İlk isim",
    "forms.ui.fieldBuilder.lastName": "Soyadı",
    "forms.ui.privacyRead": "Gizlilik politikasını dikkate aldım",
    "forms.ui.privacy": "Gizlilik Politikası",
    "jobs.ui.employmentType": "İş türü",
    "jobs.ui.employmentType.FULL_TIME": "Tam zamanlı",
    "jobs.ui.employmentType.PART_TIME": "Yarı zamanlı",
    "jobs.ui.employmentType.APPRENTICE": "Eğitim",
    "jobs.ui.employmentType.INTERN": "Stajyerlik",
    "jobs.ui.employmentType.CONTRACTOR": "Serbest çalışma",
    "jobs.ui.employmentType.TRAINEE": "Stajyer, yarı zamanlı çalışma",
    "jobs.ui.employmentType.VOLUNTEER": "Gönüllü",
    "jobs.ui.employmentType.OTHER": "",
    "jobs.ui.location": "İş şu adreste mevcut",
    "jobs.ui.role.male": "M",
    "jobs.ui.role.female": "W",
    "jobs.ui.role.divers": "D",
    "jobs.ui.startDateNow": "Şu andan itibaren",
    "jobs.ui.startFrom": "Kimden",
    "media.documents.search.noResult": "Lütfen bir seri numarası girin veya bir kategori seçin.",
    "media.documents.search.placeholder": "Birim seri numarasına veya tip numarasına göre arama",
    "media.documents.search.reset": "Arama sonucunu sıfırla",
    "media.ui.copyright": "Telif Hakkı",
    "media.ui.cropFormat": "Format özellikleri",
    "media.ui.cropImage": "Kırpma",
    "media.ui.cropImageDuplicate": "Çoğaltın ve kesin",
    "media.ui.description": "Açıklama",
    "media.ui.filename": "Dosya adı",
    "media.ui.imageSaved": "Resim kaydedildi",
    "media.ui.processingImage": "Görüntü kırpılıyor, lütfen bekleyin",
    "media.ui.resetCrop": "Kesimi sıfırla",
    "media.ui.resetImage": "Orijinaline geri dön",
    "media.ui.rotateImage": "Görüntüyü saat yönünde 90° döndürün",
    "media.ui.title": "Başlık",
    "name": "İsim",
    "openingHours.additionalInfo": "Çalışma saatleri hakkında bilgi",
    "openingHours.addOpening": "Açılış saatini ekleyin",
    "openingHours.date.day.di": "Salı",
    "openingHours.date.day.do": "Yapmak",
    "openingHours.date.day.fr": "Cuma",
    "openingHours.date.day.mi": "Mi",
    "openingHours.date.day.mo": "Mo",
    "openingHours.date.day.sa": "Otur",
    "openingHours.date.day.so": "Bu yüzden",
    "openingHours.dateS": "Tarih",
    "openingHours.delete": "Silme",
    "openingHours.from": "itibaren",
    "openingHours.options": "Alan",
    "openingHours.time": "Zaman",
    "openingHours.to": "için",
    "openingHours.type": "Açılış saati türü",
    "opt-in.error-can-not-update-member": "Üye verilerinde yapılan değişiklikler {{email}} gerçekleştirilememiştir.",
    "opt-in.error-list-does-not-exist": "Verilen tarihler için herhangi bir liste bulunamamıştır.",
    "opt-in.error-list-does-not-need-validation": "Belirtilen listede opt-int yoktur.",
    "opt-in.error-member-not-in-list": "Verilen tarihler için bir liste üyesi bulunamadı.",
    "opt-in.success-member-verified": "Bu {{email}} eklenmiştir. {{listname}} listeye eklendi.",
    "opt-out.error-can-not-update-member": "Üye verilerinde yapılan değişiklikler {{email}} gerçekleştirilememiştir.",
    "opt-out.error-list-does-not-exist": "Verilen tarihler için herhangi bir liste bulunamamıştır.",
    "opt-out.error-list-does-not-have-opt-out": "Belirtilen listenin devre dışı bırakma özelliği yoktur.",
    "opt-out.error-member-not-in-list": "Verilen tarihler için bir liste üyesi bulunamadı.",
    "opt-out.success-membership-terminated": "Bu {{email}} 'den çıkarıldı. {{listname}} kaldırıldı.",
    "password.errors.not-secure-enough": "En az 8 karakter uzunluğunda, büyük ve küçük harfler, sayılar ve özel karakterler ($@$!%*#?&amp;) içeren bir parola öneririz.",
    "password.errors.password-and-conf-do-not-match": "İki parola aynı olmalıdır.",
    "password.quality.0": "Çok Kötü",
    "password.quality.1": "Kötü",
    "password.quality.2": "Yeterli",
    "password.quality.3": "İyi",
    "password.quality.4": "Çok iyi",
    "profile.entries.copycats": "Girişler {{userName}} aynı zamanda",
    "profile.entries.donates": "Girişler {{userName}} destekler",
    "profile.entries.entries": "Girişler {{userName}}",
    "profile.entries.follows": "Hangi girişler {{userName}} aşağıdaki gibi",
    "profile.entries.joins": "Girişler hangi {{userName}} katılımcılar",
    "profile.marketplace.fields.energyRequired": "Elektrik talebi",
    "profile.marketplace.fields.livingAnimals": "Canlı hayvanların alınması",
    "profile.marketplace.fields.loadingUnloading": "Varış/ayrılış için gerekli",
    "profile.marketplace.fields.maxRange": "Teklifim için zaten çok fazla km yol yaptım.",
    "profile.marketplace.fields.placeRequired": "Saha gereksinimleri",
    "profile.marketplace.fields.securityRequired": "Güvenlik ihtiyacı",
    "profile.marketplace.fields.shows": "Program teklifi",
    "profile.marketplace.fields.spaceRequired": "m² cinsinden alan gereksinimi",
    "profile.marketplace.fields.waterRequired": "m³ cinsinden su talebi",
    "profile.marketplace.new": "Yeni teklif veya ihtiyaç",
    "profile.marketplace.offer.delete": "Silme",
    "profile.marketplace.offer.description": "Açıklama",
    "profile.marketplace.offer.descriptionPlaceholder": "Başka bir kullanıcının onunla bir şeyler yapabilmesi için anlamlı açıklama.",
    "profile.marketplace.offer.new": "Yeni teklif oluşturun",
    "profile.marketplace.offer.relatedEntry": "Atanmış giriş",
    "profile.marketplace.offer.tags": "Teklif/talep için etiketler (daha iyi bulunacaktır)",
    "profile.marketplace.offer.title": "Teklif ediyorum.",
    "profile.marketplace.request.delete": "İstek silme",
    "profile.marketplace.request.new": "Yeni talep oluştur",
    "profile.marketplace.request.missingCredentials": "Bir sorgulamayı ancak en az bir konum ve bir ölçüm noktası oluşturduğunuzda başlatabilirsiniz.",
    "profile.marketplace.request.title": "İhtiyacım var.",
    "profile.messages.approve": "Onaylayın",
    "profile.messages.as": "olarak",
    "profile.messages.commentPre": "var",
    "profile.messages.commentSuff": "yorumlar",
    "profile.messages.decided": "karar verdi",
    "profile.messages.deletedUser": "Silinen kullanıcı",
    "profile.messages.donateDream": "Bu katılımcının katılımını gerçekleştirmesine yardımcı olduğunuz için çok teşekkür ederiz. Mesajınızı hemen alacak ve umarım sizinle iletişime geçip yardımınızı kabul edecektir.",
    "profile.messages.dreamer": "Katılımcı",
    "profile.messages.dreamToo": "rüya görmek için de",
    "profile.messages.enter": "katıldı",
    "profile.messages.favoriteDream": "Şu anda bu girişi takip ediyorsunuz.",
    "profile.messages.follow": "aşağıdaki",
    "profile.messages.has": "var",
    "profile.messages.is": "o",
    "profile.messages.join": "BİRLEŞİN",
    "profile.messages.joinDream": "Bu yazıyla birlikte hayal kurmak istiyorsunuz.",
    "profile.messages.likeDream": "Bu girişi şimdi beğendiniz.",
    "profile.messages.sendText": "Mesajınız gönderildi.",
    "profile.messages.shareDream": "Bu girişi başarıyla paylaştınız.",
    "profile.messages.supporter": "Destekçiler",
    "profile.messages.want": "isterdim",
    "profile.messages.youAre": "Sen",
    "profile.messages.youWant": "Sen istersin",
    "profile.newsletter.notSubscribed": "abone olun",
    "profile.newsletter.notSubscribedAny": "Henüz bir haber bülteni listesine abone olmadınız.",
    "profile.newsletter.privacyInformation": "\"Abone ol\" seçeneğine tıklayarak, size bu abonelikle ilgili gelecekteki haber bültenlerini göndermemizi kabul edersiniz (opt-in). İstediğiniz zaman \"abone ol\" seçeneğine tekrar tıklayarak haber bülteni aboneliğinizi iptal edebilirsiniz (abonelikten çıkma).",
    "profile.newsletter.subscribed": "abone olundu",
    "profile.press.articleDetail": "Makale detayı",
    "profile.press.downloadImage": "Resmi indir",
    "profile.press.downloadImages": "Resimleri indirin",
    "profile.press.downloadText": "Metni dışa aktar",
    "profile.press.filterAuthor": "Yazara göre filtrele",
    "profile.press.hide": "Gizle",
    "profile.press.show": "içeri gir",
    "profile.progress.title": "Profilinizi nasıl geliştirebilirsiniz?",
    "register.ui.addressData": "Adres verileri",
    "register.ui.addressInfo": "Kişisel adreslerini girmek zorunda değiller. Ancak bu, doğru rota ve mesafe hesaplamalarına yol açar.",
    "register.ui.companyData": "Şirket bilgileriniz",
    "register.ui.companyDataMandatory": "Şirketinizin adını girmelisiniz",
    "register.ui.emptyFirstName": "İlk adınızı girmelisiniz",
    "register.ui.emptyLastName": "Soyadınızı girmelisiniz",
    "register.ui.emptyMail": "Geçerli bir e-posta adresi sağlamalısınız",
    "register.ui.emptyUserName": "Bir kullanıcı adı belirtmelisiniz",
    "register.ui.language": "Dil",
    "register.ui.loginData": "Giriş verileri",
    "register.ui.password": "Şifre",
    "register.ui.passwordRepeat": "Şifreyi tekrarla",
    "register.ui.personalData": "Kişisel verileriniz",
    "register.ui.privacyStatement": "Kişisel verilerimin saklanmasına izin veriyorum. Kişisel verilerim üçüncü taraflara aktarılmayacaktır. Bu veri koruma iznini gelecekte geçerli olmak üzere istediğim zaman iptal edebilirim.",
    "register.ui.takenEmail": "Bu e-posta zaten bir kullanıcı tarafından belirtilmiş.",
    "register.ui.takenUserName": "Bu kullanıcı adı zaten alınmış",
    "register.ui.timezone": "Saat dilimi",
    "register.ui.typeMinimum": "En az bir tür seçmelisiniz",
    "register.ui.usernameInfo": "Önemli: Bu, oturum açma için kullanıcı adınızdır",
    "register.ui.oauthInfo": "Kullanıcı bilgilerinizle giriş yapmak için FRM giriş sayfasına yönlendirileceksiniz ve ardından web sitesine geri döneceksiniz.",
    "search.allDistances": "Tüm mesafeler",
    "search.andFilter": "ve filtre ayarları",
    "search.closeTags": "Etiketleri gizle",
    "search.for": "için",
    "search.zipCountries": "Posta kodları",
    "search.resetPosition": "Pozisyonu sıfırla",
    "search.geoPositionBrowser": "Tarayıcı aracılığıyla konum belirleme",
    "search.geoPositionFault": "Tarayıcı tarafından konum belirleme mümkün değil",
    "search.geoPositionTitle": "Yakınlık araması için konumu ayarlama",
    "search.selectedFilters": "Seçilen filtreler",
    "search.selectFilters": "Aramayı kategorilere göre filtreleyin",
    "search.hits": "Vurmak",
    "search.openTags": "Etiketleri göster",
    "search.removeDatefilter": "Tarih filtresini kaldır",
    "search.resulted": "ortaya çıktı",
    "search.resultType.category": "Kategori",
    "search.resultType.entry": "Giriş",
    "search.resultType.event": "Etkinlik",
    "search.resultType.group": "Grup",
    "search.resultType.page": "Sayfa",
    "search.resultType.person": "Kişi",
    "search.resultType.post": "Posta",
    "search.resultType.product": "Ürün",
    "search.resultType.article": "Makale",
    "search.resultType.job": "İş",
    "search.resultType.recipe": "Yemek Tarifi",
    "search.search": "Arama",
    "search.result": "Sonuç",
    "search.results": "Sonuçlar",
    "search.searchBarPlaceholder": "Arama...",
    "search.searchBarPlaceholderEntries": "Bayi adına veya bayi adresine göre arama yapın.",
    "search.searchBarPlaceholderEntriesPosition": "Kendi konumu ile çevre araması (örneğin şehir)...",
    "search.searchBarPlaceholderEntriesPositionZip": "...veya posta kodu",
    "search.searchBarPlaceholderGeoSearch": "Adresinizi girin...",
    "search.searchBarPlaceholderMobile": "Arama...",
    "search.searchBarPlaceholderProducts": "Ürün arama",
    "search.searchBarPositionTooltip": "Konum belirleme veya adres ile konum girme",
    "search.showAll": "Hepsini göster",
    "search.globalSearch": "Arama sayfası",
    "social.comment": "Yorum",
    "social.Comment": "Yorum",
    "social.CopycatJoiner": "Girişinizi hayal edin",
    "social.dream.donateAmount": "Bir miktar para ile destek",
    "social.dream.donateDreampoints": "Dreampoints ile Destek",
    "social.dream.donateInfo": "Mesaj özeldir ve sadece giriş sahibi tarafından okunabilir.",
    "social.dream.donateTip": "Tavsiye ve eylem ile destek",
    "social.dream.done": "BİTTİ",
    "social.dream.edit": "Girişi düzenle",
    "social.dream.findDreams": "Burada küçük ve büyük girişler bulun ve girişinizi gerçekleştirin ve/veya başkalarının girişlerini gerçekleştirmelerine yardımcı olun.",
    "social.dream.followedDreams": "Takip ettiğiniz girişler",
    "social.dream.giveEmail": "E-posta adresimi paylaş",
    "social.dream.givePhone": "Telefon numaramı paylaş",
    "social.dream.joinerApproved": "Bu girişte yer alıyorsunuz",
    "social.dream.joinerButton": "Bu girişe katılın",
    "social.dream.joinerButtonMobile": "Katılmak",
    "social.dream.joinerDream": "Grup rüyası",
    "social.dream.joinerInfo": "Giriş sahibi katılımınızı onaylarsa, bu giriş daha fazla hakka sahip olduğunuz bir grup odası haline gelir.",
    "social.dream.joinerMessage": "Giriş sahibi için mesajınızı buraya girin:",
    "social.dream.joinerPending": "Giriş sahibinin katılımınızı onaylaması gerekmektedir.",
    "social.dream.joinerSupport": "Bu girişi destekliyorsunuz",
    "social.dream.joinerSupportButton": "Bu girişi destekleyin",
    "social.dream.joinerSupportButtonMobile": "Destek",
    "social.dream.joinerSupportInfo": "Para veya giriş noktaları ile destekleme gibi iyileştirmeler üzerinde sürekli çalışıyoruz. Bizi izlemeye devam edin.",
    "social.dream.joinerSupportPending": "Giriş sahibi yine de desteğinize izin vermelidir",
    "social.dream.member": "Üye",
    "social.dream.members": "Üyeler",
    "social.dream.meToo": "Ben de yapmak istiyorum.",
    "social.dream.meTooInfo": "Giriş sahibine girişin harika olduğunu düşündüğünüzü ve bu girişi yapmak istediğinizi gösterin.",
    "social.dream.noFollow": "Herhangi bir girişi takip etmiyorsunuz!",
    "social.dream.noJoiner": "Henüz bir grup rüyasının üyesi değilsiniz",
    "social.dream.noLikes": "Henüz hiçbir girişi beğenmedin!",
    "social.dream.noSupport": "Bir girişi desteklemiyorsunuz",
    "social.dream.registerFirstText": "Bir girişi paylaşabilmek, girişi desteklemek veya bu girişi yapmak istediğinizi belirtmek için giriş yapmış olmanız gerekir. Girişinizi paylaşmak için kaydolmak hızlı ve ücretsizdir. \"Kaydol\" düğmesine tıklamanız yeterlidir.",
    "social.dream.registerFirstTitle": "Birlikte hayal kurmak, desteklemek, hatta yapmak?",
    "social.dream.settings": "Giriş ayarları",
    "social.dream.shareTitle": "Hayalinizi paylaşın",
    "social.dream.similar": "Benzer girişler",
    "social.dreamline.addImageToDream": "Bu görseli girişinize ekleyin",
    "social.dreamline.addMedia": "Resim / video ekleyin",
    "social.dreamline.allActivities": "Tüm Dreamline faaliyetleri",
    "social.dreamline.anonymous": "Anonim",
    "social.dreamline.commentButton": "Yorum",
    "social.dreamline.comments": "yazıyor:",
    "social.dreamline.depublished": "Bu yorum uygunsuz davranış nedeniyle kaldırılmıştır.",
    "social.dreamline.dreampointsInfo": "Bunlar girişin Hayal Noktalarıdır. Dreampoints hakkında daha fazla bilgiyi profilinizde bulabilirsiniz.",
    "social.dreamline.fewDreampoints": "sadece birkaç Dreamp puanı var. Yorum yapın, beğenin veya paylaşın.",
    "social.dreamline.filterBySteps": "Adımlara Göre Filtrele",
    "social.dreamline.filterComment": "Yorumlar",
    "social.dreamline.filteredBy": "Tarafından filtrelenen faaliyetler",
    "social.dreamline.filteredBySteps": "Adımlara göre filtrelendi",
    "social.dreamline.filterFavorite": "Takip eder",
    "social.dreamline.filterLike": "Beğeniler",
    "social.dreamline.filterShare": "Hisseler",
    "social.dreamline.filterStep": "Adımlar",
    "social.dreamline.flagged": "Bu gönderi rapor edildi",
    "social.dreamline.isComment": "yorumlar",
    "social.dreamline.isCopycatJoiner": "ayrıca rüya",
    "social.dreamline.isFirstUpdate": "yaratıldı",
    "social.dreamline.isShare": "paylaşılan",
    "social.dreamline.isStep": "yeni bir adım attı",
    "social.dreamline.isUpdate": "güncellendi",
    "social.dreamline.lastEntry": "Son giriş güncellemesi",
    "social.dreamline.locked": "Bu içerik sadece diğer hayalperestler tarafından görülebilir",
    "social.dreamline.lockedCTA": "Bu girişe şimdi katılın",
    "social.dreamline.noMessages": "Bu girişte henüz bir etkinlik yok. İlk yorumu şimdi yazın.",
    "social.dreamline.noMessagesFiltered": "Bu giriş için bu filtre ayarlarıyla etkinlik yok.",
    "social.dreamline.nowFollower": "bu girişi takip ediyor!",
    "social.dreamline.nowJoiner": "artık bir destekçi",
    "social.dreamline.nowLikes": "bu yazıyı şimdi beğen!",
    "social.dreamline.nowSharing": "bu girişi paylaştı!",
    "social.dreamline.onlyJoinerVisible": "Sadece diğer hayalperestler/destekçiler tarafından görülebilir",
    "social.dreamline.prefixComment": "daha önce bu girişe sahip",
    "social.dreamline.prefixCopycatJoiner": "bu girişi eklemek istiyorum çünkü",
    "social.dreamline.prefixFavorite": "'den beri bu girişi takip ediyor.",
    "social.dreamline.prefixJoiner": "bu girişi desteklediğinden",
    "social.dreamline.prefixLike": "bu girişi beğendiğinden beri",
    "social.dreamline.prefixShare": "daha önce bu girişe sahip",
    "social.dreamline.prefixStep": "daha önce",
    "social.dreamline.prefixUpdate": "daha önce bu girişe sahip",
    "social.dreamline.profile": "Profil",
    "social.dreamline.provideImage": "Bu resmi giriş sahibinin kullanımına sunuyorum",
    "social.dreamline.resetFilter": "Filtreyi sıfırla",
    "social.dreamline.sendComment": "Gönder",
    "social.dreamline.sharePart": "Paylaş",
    "social.dreamline.toggleStream": "Yorumlar",
    "social.dreamline.toggleStreamSingle": "Yorum",
    "social.dreamline.writeComment": "Bir yorum yazın...",
    "social.Favorite": "Takip et",
    "social.follow": "Takip et",
    "social.Follow": "Takip et",
    "social.hasLiked": "Bu girişi zaten beğendiniz.",
    "social.join": "Katılın",
    "social.Join": "Katılın",
    "social.Joiner": "Birlikte hayal kurmak",
    "social.like": "Gibi",
    "social.Like": "Gibi",
    "social.report.headlinePrefix": "Sen oradasın",
    "social.report.headlineSuffix": "rapor etmek için.",
    "social.report.login": "Gönderiyi bildirmek için giriş yapmış olmalısınız.",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "Diğer saldırgan içerikler",
    "social.report.reportScam": "Yanlış beyan, zorbalık.",
    "social.report.reportSex": "Cinsel içerik",
    "social.report.reportSpam": "Bu spam",
    "social.report.reportViolence": "Şiddet tasviri",
    "social.report.send": "Mesaj gönder",
    "social.report.title": "Rapor",
    "social.share": "Paylaş",
    "social.Share": "Paylaş",
    "syd.ui.dreamerCommercial": "B2B Standart",
    "syd.ui.userCommercial": "B2B Dreamer",
    "user.company.addUser": "Şunlar için ek kullanıcılar oluşturun",
    "user.company.inviteUser": "Davetiye postası gönderin",
    "user.company.userIsInvited": "Davet e-postası gönderildi",
    "user.company.userIsActive": "Kullanıcı aktif",
    "user.company.userIsNotActive": "Kullanıcı henüz etkinleştirilmedi",
    "user.company.additionalWebsite": "Şirketin diğer web sitesi (https:// olmadan sadece alan adı)",
    "user.company.companyName": "Şirket adı",
    "user.company.contactFor": "İletişim bilgileri için",
    "user.company.contactForDefault": "Genel iletişim",
    "user.company.copyContact": "Giriş için şirket bilgilerinden iletişim verilerini devralın",
    "user.company.email": "İletişim e-postası",
    "user.company.facebook": "Şirketin Facebook hesabı (sadece hesap adı)",
    "user.company.fax": "Yazılı iletişim için faks numarası",
    "user.company.google": "Şirketin Google+ hesabı (yalnızca hesap adı)",
    "user.company.instagram": "Şirketin Instagram hesabı (sadece hesap adı)",
    "user.company.linkedin": "Şirketin LinkedIn hesabı (sadece hesap adı)",
    "user.company.newCustomerAccount": "Bir işletme hesabı oluşturun",
    "user.company.noCustomerValidation": "İşletme hesabınız henüz etkinleştirilmedi.",
    "user.company.noCustomerYet": "Kaydınıza henüz bir işletme hesabı atanmamıştır.",
    "user.company.notAllowed": "Herhangi bir değişiklik yapamazsınız çünkü kullanıcı durumunuz ({{type}}) buna izin vermez.",
    "user.company.phone": "İletişim telefon numarası",
    "user.company.pinterest": "Şirketin Pinterest hesabı (sadece hesap adı)",
    "user.company.remarks": "Notlar",
    "user.company.registerNumber": "Ticaret sicil numarası",
    "user.company.registerAuth": "Yerel mahkeme",
    "user.company.acerCode": "Enerji kaynağınızın ACER kodu",
    "user.company.taxNumber": "Vergi numarası",
    "user.company.type.default": "Standart",
    "user.company.type.procurator": "Yetkili imza sahibi",
    "user.company.type.manager": "Genel Müdür",
    "user.company.twitter": "Twitter şirket hesabı (@ olmadan sadece hesap adı)",
    "user.company.ustid": "UStID",
    "user.company.ustid.notValid": "Lütfen geçerli bir KDV kimlik numarası sağlayın<br>(varsa)",
    "user.company.website": "Şirket web sitesi (https:// olmadan sadece alan adı)",
    "user.company.xing": "Şirketin Xing hesabı (yalnızca hesap adı)",
    "user.company.youtube": "Şirketin Youtube hesabı (sadece hesap adı)",
    "user.message.after-save-error": "Değişiklikleriniz kaydedilemedi.",
    "user.message.after-save-success": "Değişiklikleriniz kaydedildi.",
    "user.message.can-not-login": "Bu kullanıcı ve şifre girişleriyle oturumunuzu açamayız! Kullanıcı adı doğru yazıldı mı? Şifreyi doğru girdiniz mi? Kayıt sırasında bir şeyler yanlış gitmiş olabilir.",
    "user.message.can-not-logout": "Çıkışınızı yapamadık!",
    "user.message.conflict": "Bu kullanıcı adı zaten var, başka bir tane seçin!",
    "user.message.fileExistsError": "Yükleme başarısız oldu. Dosya zaten mevcut",
    "user.message.fileSizeError": "Yükleme başarısız oldu. Dosya çok büyük",
    "user.message.loginFailureBottom": "Belki de henüz bir Entryer hesabınız yoktur?",
    "user.message.loginFailureTop": "Kullanıcı adınız doğru yazıldı mı? Şifrenizi doğru girdiniz mi?",
    "user.message.registration-error": "Kaydınız sırasında bir hata oluştu!",
    "user.message.registration-success": "Kaydınız için teşekkür ederiz",
    "user.message.unknownError": "Dosya yüklenirken bilinmeyen hata",
    "user.message.upload-success": "Yükleme başarılı",
    "user.message.verify-error": "E-posta adresiniz doğrulanamadı. Lütfen yönetici ile iletişime geçin.",
    "user.message.verify-success": "E-posta adresiniz onaylandı. Şimdi kayıt olabilirsiniz:",
    "user.ui.accountGreeting": "Merhaba,",
    "user.ui.accountMenu": "Ana sayfa ve bilgiler",
    "user.ui.accountMenuActivities": "Faaliyetler",
    "user.ui.accountMenuCompanySettings": "Şirket bilgileri",
    "user.ui.accountMenuEntries": "Benim girişlerim",
    "user.ui.accountMenuEntryPre": "Girişler,",
    "user.ui.accountMenuEvents": "Benim etkinliklerim",
    "user.ui.accountMenuFavorites": "Favorilerim",
    "user.ui.accountMenuFollows": "Takip ettiğim girişler",
    "user.ui.accountMenuImages": "Resimler/Videolar/Belgeler",
    "user.ui.accountMenuJoin": "Grup hayallerim",
    "user.ui.accountMenuLikes": "Beğendiğim girişler",
    "user.ui.accountMenuMeasuring": "Ölçüm konumlarım ve verilerim",
    "user.ui.accountMenuMessages": "Haberler",
    "user.ui.accountMenuNewsletter": "Bülten aboneliklerim",
    "user.ui.accountMenuPoi": "Benim konumlarım",
    "user.ui.accountMenuPress": "Makalelerim",
    "user.ui.accountMenuProducts": "Ürünlerim",
    "user.ui.accountMenuJobs": "İş tekliflerim",
    "user.ui.accountMenuProfile": "Kullanıcı profili",
    "user.ui.accountMenuRatings": "Yorumlar",
    "user.ui.accountMenuRegistrationData": "Giriş verileri",
    "user.ui.accountMenuReviews": "Yorumlar",
    "user.ui.accountMenuSettings": "Ayarlar",
    "user.ui.accountMenuSkillsOffer": "Teklif ediyorum.",
    "user.ui.accountMenuSkillsRequest": "İhtiyacım var.",
    "user.ui.accountMenuOffer": "Benim tekliflerim",
    "user.ui.accountMenuRequest": "Benim isteklerim",
    "user.ui.accountMenuSkillsSearch": "Talepler ve teklifler",
    "user.ui.accountMenuSubscriptions": "Abonelikler",
    "user.ui.accountMenuSubscritions": "Aboneliğim",
    "user.ui.accountMenuSupport": "Desteklenen girişler",
    "user.ui.accountMenuSupports": "Desteklediğim Girişler",
    "user.ui.accountMenuTender": "İhaleler",
    "user.ui.accountMenuOrders": "Benim emirlerim",
    "user.ui.accountNoMessages": "Henüz herhangi bir mesaj almadınız.",
    "user.ui.accountProgressbar": "Profiliniz {{value}}% tamamlandı",
    "user.ui.accountProgressbarEntry": "Girişiniz {{value}}% tamamlandı",
    "user.ui.accountSettings": "Daha fazla ayar",
    "user.ui.accountCookieSettings": "Ayarlarınızı yapmak için buraya tıklayın.",
    "user.ui.accountCookieEssential": "Temel",
    "user.ui.accountCookieAnalysis": "Analiz verileri",
    "user.ui.accountCookieSocial": "Sosyal medya",
    "user.ui.accountCookieReviews": "Yorumlar",
    "user.ui.accountToolbarLogin": "Giriş yap",
    "user.ui.accountToolbarLoginTooltip": "Ücretsiz kaydolun ve başlayın",
    "user.ui.accountToolbarLogout": "Oturumu kapat",
    "user.ui.adminPreview": "Önizleme",
    "user.ui.backToLogin": "Kayıt sayfasına geri dön",
    "user.ui.birthday": "Doğum Günü",
    "user.ui.cannotLogin": "Giriş yapamıyor musunuz? Şifrenizi sıfırlamak için buraya tıklayın.",
    "user.ui.changePassword": "Şifremi değiştirmek istiyorum",
    "user.ui.changeUserGroup": "Diğer abonelikler",
    "user.ui.city": "Şehir",
    "user.ui.companyName": "Şirket adı",
    "user.ui.companyNameTooltip": "Önemli: Girişiniz daha sonra şu şirket adları altında da görünecektir",
    "user.ui.country": "Ülke",
    "user.ui.createNewAccount": "Yeni bir kullanıcı hesabı oluşturun",
    "user.ui.dataDelete": "diğer girişler. Dosyayı kalıcı olarak silmek istiyor musunuz? Bu dosyanın kullanıldığı her yerde, bir yer tutucu ile değiştirilir.",
    "user.ui.dataUsage": "Dosya şuraya kaydedilir",
    "user.ui.dataUsageNone": "Dosya henüz kullanımda değil. Dosyayı kalıcı olarak silmek istiyor musunuz?",
    "user.ui.deleteImage": "Görüntüyü sil",
    "user.ui.description": "Buraya kişi veya şirketin genel bir tanımını girin",
    "user.ui.documents": "Belgeler",
    "user.ui.documentSearch": "Belge arama",
    "user.ui.download": "{{title}} İndir",
    "user.ui.email": "E-posta adresi",
    "user.ui.fillOutForm": "Kayıt olmak için tüm zorunlu alanlar eksiksiz doldurulmalıdır",
    "user.ui.firstName": "İlk isim",
    "user.ui.academicTitle": "Akademik unvan",
    "user.ui.gender": "E/K",
    "user.ui.genderFemale": "kadın",
    "user.ui.genderFemaleSalutation": "Bayan",
    "user.ui.genderMale": "erkek",
    "user.ui.genderMaleSalutation": "Bay",
    "user.ui.genderDiverse": "farklı",
    "user.ui.genderThird": "Belirtilmemiş",
    "user.ui.housenumber": "Hayır.",
    "user.ui.iAmCustomer": "Bir B2B hesabı oluşturmak istiyorum",
    "user.ui.internalInfo": "Sizin için mesajlar var. Buraya tıklayın.",
    "user.ui.lastLogin": "Son kayıt tarihi",
    "user.ui.languageAvailable": "Kendi dilinizde mevcuttur",
    "user.ui.languageMissing": "Yalnızca",
    "user.ui.lastName": "Soyadı",
    "user.ui.loginButton": "Giriş yap",
    "user.ui.loginTitle": "Kullanıcı girişi",
    "user.ui.memberSince": "O zamandan beri üye",
    "user.ui.mobileNumber": "Cep telefonu numarası",
    "user.ui.myAddedStorage": "Sağlanan resimler",
    "user.ui.myStorage": "Benim rehberim",
    "user.ui.noRegisterMessage": "Bir kullanıcı hesabı için lütfen belirtilen iletişim adreslerine başvurun. Kendinizi kullanıcı olarak kaydedemezsiniz.",
    "user.ui.oldPassword": "Önceki şifre",
    "user.ui.password": "Şifre",
    "user.ui.phoneType": "Telefon",
    "user.ui.phoneTypeMobile": "Mobil",
    "user.ui.phoneTypeLandline": "Sabit Hat",
    "user.ui.pleaseSelect": "Lütfen seçiniz",
    "user.ui.pleaseSelectSalutation": "Lütfen selamlama seçin",
    "user.ui.registerButton": "Kullanıcı hesabını şimdi ayarlayın",
    "user.ui.registerMessage": "Şimdi kaydolun ve hemen başlayın. Hızlı ve kolay.",
    "user.ui.registerTitle": "Bizimle yeni misiniz?",
    "user.ui.requestPasswordReset": "Yeni şifre talep edin ve eski şifreyi silin",
    "user.ui.requestPasswordSuccess": "Kısa süre içinde sıfırlama bağlantısı içeren bir e-posta alacaksınız.",
    "user.ui.requestPasswordError": "Şifre sıfırlanırken bilinmeyen hata.",
    "user.ui.resetPassword": "Şifreyi sıfırla",
    "user.ui.setPassword": "Şifre belirleme",
    "user.ui.resetPasswordPage": "Şifrenizi sıfırlayın",
    "user.ui.setPasswordPage": "Şifrenizi belirleyin",
    "user.ui.resetPasswordSuccess": "Şifreniz sıfırlandı, şimdi giriş yapabilirsiniz",
    "user.ui.setPasswordSuccess": "Parolanız belirlendi, artık giriş yapabilirsiniz",
    "user.ui.selectCustomerType": "Sen ... lütfen seçiniz",
    "user.ui.stayLoggedin": "Oturumunuzu açık tutun",
    "user.ui.stayLoggedinInfo": "Bu cihaz herkesin erişimine açıksa, lütfen bu seçeneği devre dışı bırakın ve bitirmek için tekrar oturumu kapatın.",
    "user.ui.street": "Sokak",
    "user.ui.salutation": "Selamlama",
    "user.ui.title": "Başlık",
    "user.ui.thankYouForSubscribing": "Haber bültenimize abone olduğunuz için teşekkür ederiz.",
    "user.ui.errorAtSubscribing": "Haber bültenine abone olurken hata oluştu",
    "user.ui.unsubscribe": "oturumu kapat",
    "user.ui.unsubscribePage": "Haber bülteni aboneliğinizi gerçekten iptal etmek istiyor musunuz?",
    "user.ui.uploadDrop": "Yüklemek için bir dosyayı buraya sürükleyin veya",
    "user.ui.uploadSelectFile": "bir dosya seçin",
    "user.ui.uploadToMyStorage": "Dizinime yeni resimler yükleyin",
    "user.ui.uploadUserImage": "Kullanıcı resmini seçin",
    "user.ui.username": "E-posta adresi",
    "user.ui.userName": "Kullanıcı Adı",
    "user.ui.visibilityInfo": "İpucu: Profilinizde herkese açık olarak nelerin görüntüleneceğine siz karar verirsiniz! Girişlerinizin görünürlüğünü ayarlamak için göz sembolüne tıklayın:",
    "user.ui.zip": "Posta kodu",
    "user.ui.phone": "Telefon numarası",
    "user.user": "Katılımcı",
    "user.users": "Girişler",
    "widgets.shoppingCart.wishlist": "Not ve dilek listesi",
    "widgets.shoppingCart.wishlist.empty": "Not defterinizde ve istek listenizde henüz hiçbir ürün yok",
    "widgets.shoppingCart.cart": "Alışveriş sepeti",
    "user.product.addedToFavorites": "Ürün favorilerinize eklendi",
    "order.status.zero": "Sipariş alındı",
    "order.status.one": "Devam ediyor",
    "order.status.two": "Sipariş gönderildi",
    "order.status.three": "Erişim sağlandı",
    "order.status.four": "Dönüş",
    "order.status.default": "Bilinmeyen meslek durumu",
    "country.germany": "Almanya",
    "country.de": "Almanya",
    "country.austria": "Avusturya",
    "country.at": "Avusturya",
    "country.belgium": "Belçika",
    "country.denmark": "Danimarka",
    "country.bulgaria": "Bulgaristan",
    "country.croatia": "Hırvatistan",
    "country.cyprus": "Kıbrıs",
    "country.czechia": "Çek Cumhuriyeti",
    "country.estonia": "Estonya",
    "country.finland": "Finlandiya",
    "country.france": "Fransa",
    "country.fr": "Fransa",
    "country.greece": "Yunanistan",
    "country.hungary": "Macaristan",
    "country.ireland": "İrlanda",
    "country.italy": "İtalya",
    "country.it": "İtalya",
    "country.latvia": "Letonya",
    "country.lithuania": "Litvanya",
    "country.luxembourg": "Lüksemburg",
    "country.lu": "Lüksemburg",
    "country.malta": "Malta",
    "country.netherlands": "Hollanda",
    "country.poland": "Polonya",
    "country.portugal": "Portekiz",
    "country.romania": "Romanya",
    "country.slovakia": "Slovakya",
    "country.slovenia": "Slovenya",
    "country.spain": "İspanya",
    "country.sweden": "İsveç",
    "country.norway": "Norveç",
    "country.switzerland": "İsviçre",
    "country.ch": "İsviçre",
    "country.turkey": "Türkiye",
    "country.unitedKingdom": "Birleşik Krallık",
    "country.gb": "Büyük Britanya",
    "openingHours.tNowClosed": "Şimdi kapalı",
    "openingHours.tOpeningHoursNotKnown": "Diğer çalışma saatleri bilinmiyor",
    "openingHours.tNowOpen": "Şimdi açın",
    "openingHours.tNowStillOpen": "Şimdi hala açık",
    "openingHours.tNextOpeningHourUnknown": "sonraki açılış tarihi bilinmiyor",
    "openingHours.tSoonOpenFrom": "yakında tekrar açılacak",
    "openingHours.tFrom": "itibaren",
    "openingHours.tUntil": "için",
    "openingHours.tOpenAgainFrom": "tekrar aç",
    "openingHours.tOpenAgainFrom2": "Tekrar aç",
    "comparison.fields.measurements": "Kütle",
    "comparison.fields.weight": "Ağırlık",
    "application.tag.neu": "Yeni",
    "application.tag.sale": "Satış",
    "application.tag.eco": "Eko",
    "application.tag.sonderrabatt": "Özel indirim",
    "job.application.selectLocation": "Konumunuzu seçin",
    "job.application.location": "Aşağıdaki bölgelerde mevcuttur"
  };
});
define('webapp/components/forms-entry-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    languageService: Ember.inject.service(),
    searchTerm: '',
    items: null,

    sort: 'alphabetical',
    size: 10,

    didInsertElement: function didInsertElement() {
      // this.search();
    },
    _search: function _search(searchTerm) {
      var options = this.get('field.options');
      var type = options.type;

      var categoryFilter = [{ type: options.categoryFilterOperation || 'and', items: options.rootCategories }];

      var searchRequest = {
        itemTypes: [type],
        searchTerm: searchTerm,
        size: this.get('size'),
        sort: this.get('sort'),
        categoryFilter: categoryFilter,
        aggregations: false
      };

      return this.get('myStorage').search.ajaxPost({
        data: searchRequest,
        query: {
          lang: this.get('languageService.selectedLanguage'),
          site: this.get('cachedData.cachedSite._id')
        }
      });
    },
    search: function search(searchTerm) {
      var _this = this;

      var searchPromise = this._search(searchTerm);
      this.searchPromise = searchPromise;

      return this.searchPromise.then(function (searchResult) {
        // only update state if its the most recent search
        if (_this.searchPromise === searchPromise) {
          _this.setProperties({
            items: searchResult.hits,
            total: searchResult.total
          });
        }
      });
    },
    resetSearch: function resetSearch() {
      this.setProperties({
        searchTerm: '',
        items: null,
        total: null
      });
    },
    selectItem: function selectItem(item) {
      var fieldKey = this.get('field.key');
      if (item.subtitle && item.itemType === 'entry') {
        this.set('formData.' + fieldKey, item.title + ' ' + item.subtitle);
        this.setProperties({
          searchTerm: item.title + ' ' + item.subtitle,
          items: null,
          total: null
        });
      } else {
        this.set('formData.' + fieldKey, item.title);
        this.setProperties({
          searchTerm: item.title,
          items: null,
          total: null
        });
      }
    },
    confirmSearch: function confirmSearch() {
      var items = this.get('items');
      var firstItem = items && items[0];
      if (firstItem) {
        this.selectItem(firstItem);
      }
    },


    actions: {
      search: function search() {
        this.search(this.get('searchTerm'));
      },
      confirmSearch: function confirmSearch() {
        this.confirmSearch();
      },
      selectItem: function selectItem(item) {
        this.selectItem(item);
      },
      resetSearch: function resetSearch() {
        this.resetSearch();
      },
      searchKeyup: function searchKeyup(e) {
        var searchTerm = e.target.value;
        this.set('searchTerm', searchTerm);
        if (e.key === 'Escape') {
          this.resetSearch();
        } else if (e.key === 'Enter') {
          this.confirmSearch();
        } else {
          Ember.run.debounce(this, this.search, searchTerm, 500);
        }
      }
    }
  });
});
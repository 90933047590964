define('webapp/helpers/divisible', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var number = params[0];
    var divider = params[1] || 1;

    return !(number % divider);
  });
});
define('webapp/routes/account/orders/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    customerService: Ember.inject.service(),

    model: function model(params) {
      var customerService = this.get('customerService');
      return customerService.getOrder(params.id);
    }
  });
});
define('webapp/models/opening-config', ['exports', 'webapp/config/environment', 'ember-cp-validations'], function (exports, _environment, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getRelevantFields(elemCfg) {
    var fields = ['option'];
    if (elemCfg.hasTime) {
      fields = fields.concat(['opening_from', 'opening_to']);
    }
    if (elemCfg.hasDate) {
      fields = fields.concat(['opening_day_from', 'opening_day_to']);
    }
    if (elemCfg.hasInterval) {
      fields = fields.concat(['opening_date_from', 'opening_date_to']);
    }
    fields = fields.concat(elemCfg.options.map(function (x) {
      return x.id;
    }));
    return fields;
  }

  var dayoption = {
    id: 'dayoption',
    prompt: 'Tag auswählen',
    values: [{ id: '0', title: 'Sonntag' }, { id: '1', title: 'Montag' }, { id: '2', title: 'Dienstag' }, { id: '3', title: 'Mittwoch' }, { id: '4', title: 'Donnerstag' }, { id: '5', title: 'Freitag' }, { id: '6', title: 'Samstag' }, { id: '8', title: 'Alle Tage (Mo-Mo)' }, { id: '9', title: 'Alle Werktage (Mo-Fr)' }, { id: '10', title: 'Alle Tage außer Sonntags (Mo-Sa)' }]
  };

  var config = [{
    option: 'default',
    title: 'Standard',
    options: [dayoption],

    hasTime: true,
    hasDate: false
  }, {
    option: 'repeatable',
    title: 'Zyklisch',
    options: [dayoption, {
      id: 'repeatoption',
      prompt: 'Wiederholung auswählen',
      values: [{ id: '1', title: '1. des Monats' }, { id: '2', title: '2. des Monats' }, { id: '3', title: '3. des Monats' }, { id: '4', title: '4. des Monats' }, { id: '14', title: '14-tägig' }, { id: '30', title: 'Letzter des Monats' }, { id: '21', title: '3-wöchig' }, { id: '28', title: '4-wöchig' }]
    }],
    hasTime: true,
    hasDate: true
  }, {
    option: 'season',
    title: 'Saisonal',
    options: [dayoption],
    hasTime: true,
    hasDate: true
  }, {
    option: 'holiday',
    title: 'Feiertage',
    options: [{
      id: 'holidayoption',
      prompt: 'Feiertag auswählen',
      values: [{ id: '0', title: 'Alle' }, { id: '101', title: 'Neujahrstag' }, { id: '106', title: 'Hl. drei Könige' }, { id: '301', title: 'Karfreitag' }, { id: '302', title: 'Ostersonntag' }, { id: '303', title: 'Ostermontag' }, { id: '501', title: 'Tag der Arbeit' }, { id: '502', title: 'Christi Himmelfahrt' }, { id: '601', title: 'Pfingstsonntag' }, { id: '602', title: 'Pfingstmontag' }, { id: '603', title: 'Fronleichnam' }, { id: '808', title: 'Augsburger Friedensfest' }, { id: '815', title: 'Mariä Himmelfahrt' }, { id: '103', title: 'Tag der Deutschen Einheit' }, { id: '131', title: 'Reformationstag' }, { id: '111', title: 'Allerheiligen' }, { id: '121', title: 'Heilig Abend' }, { id: '122', title: 'Erster Weihnachtstag' }, { id: '123', title: 'Zweiter Weihnachtstag' }]
    }],
    hasTime: true,
    hasDate: false
  }, {
    option: 'vacation',
    title: 'Urlaub',
    options: [],
    hasTime: false,
    hasDate: true
  }, {
    option: 'datespan',
    options: [],
    title: 'Start / Enddatum',
    hasInterval: true
  }];

  var timeValidation = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    message: 'Zeit muss im Format hh:mm angegeben werden'
  }), (0, _emberCpValidations.validator)('format', {
    regex: /^\d{1,2}:\d{2}$/,
    message: 'Zeit muss im Format hh:mm angegeben werden'
  })];

  var dateValidation = [(0, _emberCpValidations.validator)('format', {
    allowBlank: true,
    regex: /^\d{1,2}\.\d{2}$/,
    message: 'Datum muss im Format dd.mm angegeben werden'
  })];

  config.forEach(function (elemConfig) {
    var validations = {};
    var relevantFields = getRelevantFields(elemConfig);

    if (elemConfig.hasTime) {
      validations['opening_from'] = timeValidation;
      validations['opening_to'] = timeValidation;
    }
    if (elemConfig.hasDate) {
      validations['opening_day_from'] = dateValidation;
      validations['opening_day_to'] = dateValidation;
    }

    var Model = elemConfig.Model = Ember.Object.extend((0, _emberCpValidations.buildValidations)(validations), {
      init: function init() {
        this._super.apply(this, arguments);
        // var releventProps = Ember.Object.create(props).getProperties(relevantFields);
        // this.setProperties(releventProps);
      },

      toJSON: function toJSON() {
        return this.getProperties(relevantFields);
      },

      copy: function copy() {
        var props = this.toJSON();
        return Model.create(props);
      }
    });
  });

  if (_environment.default.simpleOpeningHours) {
    config = config.filter(function (elem) {
      return elem.option === 'default' || elem.option === 'vacation';
    });
  }

  exports.default = config;
});
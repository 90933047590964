define('webapp/components/widgets/widget-blog-tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showCount: Ember.computed.oneWay('data.options.showCount'),
    myStorage: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var blogId = this.get('data.options.blog');

      if (blogId) {
        this.fetchData(blogId);
      } else {
        console.info('no blog defined for widget: ' + this.get(data.title));
      }
    },
    fetchData: function fetchData(blogId) {
      var _this = this;

      var myStorage = this.get('myStorage');

      myStorage.blog.ajaxGet({ param: blogId + '/tags' }).then(function (res) {
        _this.set('tags', res);
      });
    }
  });
});
define('webapp/components/detail/document-files', ['exports', 'ember-ajax/request', 'webapp/utils/file-path', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _request, _filePath, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileSaver.default, {
    router: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    person: {},
    fingerprintService: Ember.inject.service(),

    verifyEmail: Ember.computed('person', 'person.mail', function () {
      var person = this.get('person');
      var validator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //RegExp to check Mails
      if (person.mail) {
        return !!person.mail.match(validator);
      } else {
        return false;
      }
    }),

    verifyName: Ember.computed('person', 'person.name', function () {
      var person = this.get('person');
      if (person.name && person.name.length >= 2) {
        return true;
      } else {
        return false;
      }
    }),

    verifyFirstName: Ember.computed('person', 'person.firstName', function () {
      var person = this.get('person');
      if (person.firstName && person.firstName.length >= 2) {
        return true;
      } else {
        return false;
      }
    }),

    _download: function _download(file) {
      var _this = this;

      var user = this.get('userProxy');
      var myStorage = this.get('myStorage');
      var fingerPrint = this.get('fingerprintService');

      myStorage.media.ajaxPost({
        param: 'public_download',
        data: { media: file, fingerPrint: fingerPrint, user: user }
      });

      (0, _request.default)((0, _filePath.default)(file.path), { dataType: 'blob' }).then(function (content) {
        _this.saveFileAs(file.infos.title, content, 'application/' + file.extension);
      });
    },
    subscribeMail: function subscribeMail() {
      var person = this.get('person');
      var myStorage = this.get('myStorage');
      var options = this.get('options');
      var emailSubscribed = localStorage.getItem('emailSubscribed');

      if (emailSubscribed) {
        return true;
      } else {
        return myStorage.mailingList.ajaxPost({ param: 'addMember', data: { person: person, list: options.list } }).then(function (res) {
          if (res.success) {
            localStorage.setItem('emailSubscribed', JSON.stringify(true));
            return true;
          } else {
            return false;
          }
        });
      }
    },


    actions: {
      download: function download(file) {
        this.set('downloadFile', file);
        var emailSubscribed = localStorage.getItem('emailSubscribed');

        if (emailSubscribed || !this.get('options.needsMail')) {
          this._download(file);
        } else {
          this.set('openEmailDialog', true);
        }
      },
      cancel: function cancel() {
        this.set('openEmailDialog', false);
      },
      confirm: function confirm(file) {
        var subscribed = this.subscribeMail();
        if (subscribed) {
          this._download(file);
        }
        this.set('openEmailDialog', false);
      }
    }
  });
});
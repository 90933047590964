define('webapp/controllers/account/ratings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    expandedItem: null,

    actions: {
      toggleExpand: function toggleExpand(item) {
        var expandedItem = this.get('expandedItem');
        if (expandedItem === item) {
          this.set('expandedItem', null);
        } else {
          this.set('expandedItem', item);
        }
      }
    }
  });
});
define('webapp/components/widgets/widget-new-items', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    fingerprintService: Ember.inject.service(),
    loaded: false,

    fetchData: function fetchData() {
      var _this = this;

      var myStorage = this.get('myStorage');
      var fingerprintService = this.get('fingerprintService');

      var options = this.get('data.options');
      this.set('loaded', false);

      return fingerprintService.getFingerprint().then(function (fingerprint) {
        return myStorage.globalSearch.ajaxPost({
          param: 'new_items',
          data: {
            fingerprint: fingerprint,
            types: options.types,
            size: options.size,
            threshold: options.threshold
          }
        }).then(function (res) {
          _this.set('loaded', true);
          _this.set('items', res.hits);
          // console.log(res.hits.map(x => x.createdAt));
        });
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.fetchData();
    }
  });
});
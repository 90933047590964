define('webapp/helpers/format-file-size', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fileSize = fileSize;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function fileSize(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        size = _ref2[0];

    if (size === undefined || size === null) return;
    var kbs = size / 1000;

    if (kbs >= 1000) {
      return (kbs / 1000).toFixed(2) + ' MB';
    }

    return Math.round(kbs) + ' KB';
  }

  exports.default = Ember.Helper.helper(fileSize);
});
define('webapp/routes/account/document-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var userProxy = this.get('userProxy');
      var controller = this.controllerFor(this.routeName);

      var documentContainerOptions = {};
      var assignedFolders = userProxy.userGroups.filter(function (group) {
        return group.assignedFolder;
      }).map(function (group) {
        documentContainerOptions = group.options;
        return group.assignedFolder;
      });

      controller.set('folders', assignedFolders);
      controller.set('documentContainerOptions', documentContainerOptions);
    }
  });
});
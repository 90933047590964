define("webapp/utils/wait-for-fastboot", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = waitForFastboot;
  function waitForFastboot(promise, fastboot) {
    if (fastboot.isFastBoot) {
      fastboot.deferRendering(promise);
    }
    return promise;
  }
});
define('webapp/components/account/product-comparison-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function toMap(arr, key) {
    var map = {};

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = arr[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var ob = _step.value;

        map[ob[key]] = ob;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return map;
  }

  function getProductData(map, field, fieldsData) {
    var data = map[field];
    var hasData = data ? true : false;

    if (!hasData) {
      data = fieldsData[field];
    }

    return { hasData: hasData, data: data };
  }

  function getVariantsData(variants, field, fieldsData) {
    var variantsData = [];

    if (variants) {
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = variants[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var variant = _step2.value;

          var title = variant.item.title;
          var fullFieldsMap = toMap(variant.fullFields, 'key');
          var variantData = getProductData(fullFieldsMap, field, fieldsData);
          variantData.title = title;

          variantsData.push(variantData);
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    }

    return variantsData;
  }

  function getNumericValues(variantData) {
    var hasMultiple = false;
    var hightData = undefined;
    var lowData = undefined;

    if (variantData.length >= 1) {
      hasMultiple = true;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = variantData[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var variant = _step3.value;

          var variantValue = parseInt(variant.data.value);
          var highValue = hightData ? parseInt(hightData.data.value) : hightData;
          var lowValue = lowData ? parseInt(lowData.data.value) : lowData;

          if (!highValue || variantValue > highValue) {
            hightData = variant;
          }
          if (!lowValue) {
            lowData = variant;
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }
    }

    return {
      hasMultiple: hasMultiple,
      hightData: hightData,
      lowData: lowData
    };
  }

  function getValues(productData, variantData) {
    var isNumeric = productData.data.kind === 'numeric';

    if (isNumeric) {
      return getNumericValues(variantData);
    }
  }

  exports.default = Ember.Component.extend({
    comparisonFields: Ember.computed('fields', function () {
      var comparisonFields = [];
      var fields = this.get('fields');
      var fieldsData = this.get('fieldsData');
      var product = this.get('product');

      var fullFieldsMap = toMap(product.fullFields, 'key');

      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = fields[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var field = _step4.value;

          var productData = getProductData(fullFieldsMap, field, fieldsData);
          var variantData = getVariantsData(product.variants, field, fieldsData); // TODO JD: just data for the field
          var values = getValues(productData, variantData);

          var comparisonField = {
            title: field,
            productData: productData,
            variantData: variantData,
            values: values
          };

          comparisonFields.push(comparisonField);
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      return comparisonFields;
    }),

    hasPackageLength: Ember.computed('product.packageWidth', function () {
      var packageWidth = this.get('product.packageWidth');

      return !packageWidth || packageWidth === '' ? false : true;
    }),

    hasPackageHeight: Ember.computed('product.packageHeight', function () {
      var packageHeight = this.get('product.packageHeight');

      return !packageHeight || packageHeight === '' ? false : true;
    }),

    hasPackageWidth: Ember.computed('product.packageLength', function () {
      var packageLength = this.get('product.packageLength');

      return !packageLength || packageLength === '' ? false : true;
    }),

    hasPackageMeasurements: Ember.computed('hasPackageLength', 'hasPackageHeight', 'hasPackageWidth', function () {
      var hasPackageLength = this.get('hasPackageLength');
      var hasPackageHeight = this.get('hasPackageHeight');
      var hasPackageWidth = this.get('hasPackageWidth');

      return hasPackageLength && hasPackageHeight && hasPackageWidth;
    }),

    packageMeasurements: Ember.computed('product.packageWidth', 'product.packageHeight', 'product.packageLength', function () {
      var packageWidth = this.get('product.packageWidth');
      var packageHeight = this.get('product.packageHeight');
      var packageLength = this.get('product.packageLength');

      var mesurements = '';

      if (packageWidth) {
        mesurements = '' + packageWidth;
      }

      if (packageHeight) {
        if (mesurements === '') {
          mesurements = '' + packageHeight;
        } else {
          mesurements += ' * ' + packageHeight;
        }
      }

      if (packageLength) {
        if (mesurements === '') {
          mesurements = '' + packageLength;
        } else {
          mesurements += ' * ' + packageLength;
        }
      }

      return mesurements;
    })
  });
});
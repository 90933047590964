define('webapp/components/map/marker-map', ['exports', 'webapp/components/map/base-map'], function (exports, _baseMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseMap.default.extend({
    userProxy: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    mapPopup: null,
    showCluster: Ember.computed.alias('options.showMapCluster'),

    locationMarker: null,

    init: function init() {
      this._super.apply(this, arguments);
      this._onMapData = this._onMapData.bind(this);
    },
    getLocationIcon: function getLocationIcon() {
      var markerClass = 'user-location-marker';
      var div = document.createElement('div');
      div.classList.add(markerClass);
      return div;
    },
    mapLoaded: function mapLoaded() {
      if (this.showCluster) {
        this.map.on('data', this._onMapData);
        this.map.on('move', this._onMapData);
        this.map.on('moveend', this._onMapData);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.map) {
        this.map.off('data', this._onMapData);
        this.map.off('move', this._onMapData);
        this.map.off('moveend', this._onMapData);
      }
    },
    getSingleMarker: function getSingleMarker(feature) {
      var _this = this;

      var hits = this.get('items');

      var properties = feature.properties;
      var coords = feature.geometry.coordinates;
      var element = document.createElement('div');
      element.className = 'my-marker';

      element.addEventListener('click', function () {
        var hit = hits.find(function (x) {
          return x._id === properties._id;
        });
        _this.createPopup(hit);
      });

      return new mapboxgl.Marker({
        element: element
      }).setLngLat(coords);
    },
    _onMapData: function _onMapData() {
      var _this2 = this;

      var features = this.map.querySourceFeatures('cluster-source');

      var cachedMarkers = this._cachedMarkers || {};
      var shownMarkers = {};

      var ids = [];

      features.forEach(function (feature) {
        var featureProps = feature.properties;
        var isCluster = featureProps.cluster;
        var id = isCluster ? String(featureProps.cluster_id) : featureProps._id;
        ids.push(id);
        var marker = cachedMarkers[id] || shownMarkers[id];
        if (!marker) {
          if (isCluster) {
            marker = _this2.getClusterMarker(feature);
          } else {
            marker = _this2.getSingleMarker(feature);
          }
          marker.addTo(_this2.map);
        }
        shownMarkers[id] = marker;
      });

      // remove old markers
      Object.keys(cachedMarkers).forEach(function (id) {
        if (!(id in shownMarkers)) {
          cachedMarkers[id].remove();
        }
      });

      this._cachedMarkers = shownMarkers;
    },


    _obsData: Ember.observer('items', function () {
      var _this3 = this;

      if (this.map) {
        this.initializeData();
      } else {
        this.one('mapLoaded', function () {
          _this3.initializeData();
        });
      }
    }),

    initializeData: function initializeData() {
      var _this4 = this;

      var map = this.map;
      var items = this.get('items');
      if (!items) {
        return;
      }
      var validHits = items.filter(function (hit) {
        return hit.geoPoint && hit.geoPoint.lat && hit.geoPoint.lon;
      });

      this.clearPopups();
      if (this.showCluster) {
        this.renderClusters(validHits);
      } else {
        this.renderSimpleMarkers(validHits);
      }

      if (validHits.length && !this.get('customCenter')) {
        var bounds = new mapboxgl.LngLatBounds();
        validHits.forEach(function (hit) {
          return bounds.extend(_this4.convertGeopoint(hit.geoPoint));
        });
        map.fitBounds(bounds, { padding: 40, maxZoom: 17, easing: function easing(t) {
            return t;
          }, duration: 500 });
      }
    }
  });
});
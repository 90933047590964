define('webapp/utils/fetch-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.downloadBlob = downloadBlob;
  exports.fetchBlob = fetchBlob;
  exports.fetchPostBlob = fetchPostBlob;
  function downloadBlob(blob, filename) {
    var dataUri = URL.createObjectURL(blob);
    var ahref = document.createElement('a');
    ahref.href = dataUri;
    ahref.download = filename;
    ahref.style.display = 'none';
    document.body.appendChild(ahref);
    ahref.click();
    document.body.removeChild(ahref);
    URL.revokeObjectURL(dataUri);
  }

  function handleRes(res) {
    if (res.ok) {
      return res.blob();
    } else {
      return res.text().then(function (errorText) {
        throw new Error(errorText);
      });
    }
  }

  function fetchBlob(url, token) {
    return fetch(url, { headers: { token: token } }).then(function (res) {
      return handleRes(res);
    });
  }

  function fetchPostBlob(url, blob, token) {
    return fetch(url, {
      method: 'POST',
      body: blob,
      headers: { 'content-type': 'application/octet-stream', token: token }
    }).then(function (res) {
      return handleRes(res);
    });
  }
});
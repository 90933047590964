define('webapp/components/account/widget-opening-edit', ['exports', 'webapp/models/opening-config'], function (exports, _openingConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    config: _openingConfig.default,
    data: Ember.computed.alias('openingHoursData.data')
  });
});
define('webapp/components/widgets/widget-category-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function sortCats(a, b) {
    return a._id.localeCompare(b._id);
  }

  function mapCategory(cat, andOnlyCategories, childCatIds) {
    var andOnlySlugs = void 0;
    var andOnlyExclusive = void 0;

    if (childCatIds) {
      andOnlyExclusive = [].concat(_toConsumableArray(andOnlyCategories.filter(function (x) {
        return !childCatIds.includes(x._id);
      }).map(function (x) {
        return x.slug;
      })), [cat.slug]);
    }
    if (andOnlyCategories.find(function (x) {
      return x._id === cat._id;
    })) {
      andOnlySlugs = andOnlyCategories.filter(function (x) {
        return x._id !== cat._id;
      }).map(function (x) {
        return x.slug;
      });
    } else {
      andOnlySlugs = [].concat(_toConsumableArray(andOnlyCategories.map(function (x) {
        return x.slug;
      })), [cat.slug]);
    }

    return _extends({}, cat, {
      andOnlyPart: andOnlySlugs.join('/'),
      andOnlyExclusivePart: andOnlyExclusive && andOnlyExclusive.join('/')
    });
  }

  exports.default = Ember.Component.extend({
    cachedData: Ember.inject.service(),
    router: Ember.inject.service(),
    categoryContainer: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    mobileFilterVisible: false,

    categories: [],

    categoryContainerPage: Ember.computed('cmsService.activePage', function () {
      var activePage = this.get('cmsService.activePage');
      if (activePage && activePage.type === 'categoryContainer') {
        return activePage;
      } else {
        return undefined;
      }
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      var cachedData = this.get('cachedData');
      var categoryIds = this.get('data.options.categories');
      cachedData.getFlatCategories().then(function (flatCategories) {
        var shownCategories = categoryIds.map(function (catId) {
          return flatCategories.find(function (x) {
            return x._id === catId;
          });
        }).filter(Boolean).sort(function (a, b) {
          return a.order - b.order;
        });
        _this.set('categories', shownCategories);
      });
    },


    sortedAndOnlyCategories: Ember.computed('categoryContainer.andOnlyCategories', function () {
      return this.get('categoryContainer.andOnlyCategories').sort(sortCats);
    }),

    selectedAndOnlyPart: Ember.computed('sortedAndOnlyCategories', function () {
      var andOnlyCategories = this.get('sortedAndOnlyCategories');
      return andOnlyCategories.map(function (x) {
        return x.slug;
      }).join('/');
    }),

    categoriesWithLink: Ember.computed('categories', 'categoryContainer.activeCategory', 'sortedAndOnlyCategories', function () {
      var categories = this.get('categories');
      var andOnlyCategories = this.get('sortedAndOnlyCategories');

      return categories.map(function (cat) {
        var mappedCategory = mapCategory(cat, andOnlyCategories);

        if (mappedCategory.children) {
          var childCatIds = mappedCategory.children.map(function (x) {
            return x._id;
          });
          mappedCategory.children = mappedCategory.children.map(function (childCat) {
            return mapCategory(childCat, andOnlyCategories, childCatIds);
          });

          mappedCategory.selectedChild = mappedCategory.children.find(function (child) {
            return andOnlyCategories.find(function (x) {
              return child._id === x._id;
            });
          });

          var childrenSelected = andOnlyCategories.some(function (x) {
            return childCatIds.includes(x._id);
          });
          if (childrenSelected) {
            mappedCategory.clearAndOnlyPart = andOnlyCategories.filter(function (x) {
              return !childCatIds.includes(x._id);
            }).map(function (x) {
              return x.slug;
            }).join('/');
          } else {
            mappedCategory.clearAndOnlyPart = null;
          }
        }

        return mappedCategory;
      });
    }),

    otherAndOnlyParts: Ember.computed('categories', 'sortedAndOnlyCategories', function () {
      var sortedAndOnlyCategories = this.get('sortedAndOnlyCategories');
      var categories = this.get('categories');
      var categoryIds = [];
      categories.forEach(function (cat) {
        categoryIds.push(cat._id);
        cat.children && cat.children.forEach(function (child) {
          return categoryIds.push(child._id);
        });
      });

      return sortedAndOnlyCategories.filter(function (x) {
        return !categoryIds.includes(x._id);
      }).map(function (x) {
        return x.slug;
      }).join('/');
    }),

    actions: {
      transitionTo: function transitionTo(link) {
        this.get('router').transitionTo('page', link);
      },
      showMobileFilter: function showMobileFilter() {
        this.set('mobileFilterVisible', true);
      },
      hideMobileFilter: function hideMobileFilter() {
        this.set('mobileFilterVisible', false);
      }
    }
  });
});
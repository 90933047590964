define('webapp/routes/account/documents/folder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),

    getFiles: function getFiles(folder) {
      var myStorage = this.get('myStorage');
      return myStorage.media.ajaxPost({
        param: 'public_folder',
        data: { folder: folder, onlyFiles: false }
      });
    },
    getSegments: function getSegments(path) {
      var split = path.split('/');
      var segments = [];
      var newSegment = [];
      for (var i = 0; i < split.length; i++) {
        newSegment.push(split[i]);
        segments.push({ name: split[i], path: newSegment.join('/') });
      }
      return segments;
    },
    model: function model(params) {
      var userProxy = this.get('userProxy');
      var controller = this.controllerFor(this.routeName);

      var documentContainerOptions = {};
      var assignedFolders = userProxy.userGroups.filter(function (group) {
        return group.assignedFolder;
      }).map(function (group) {
        documentContainerOptions = group.options;
        return { routingPath: group.assignedFolder };
      });

      controller.set('folders', assignedFolders);
      controller.set('documentContainerOptions', documentContainerOptions);

      var parentIdx = decodeURIComponent(params.path).lastIndexOf('/');
      if (parentIdx === -1) {
        controller.set('parentRoute', null);
      } else {
        var parentRoute = decodeURIComponent(params.path).substring(0, parentIdx);
        controller.set('parentRoute', parentRoute);
      }

      var segments = this.getSegments(decodeURIComponent(params.path));
      controller.set('pathSegments', segments);

      return this.getFiles(decodeURIComponent(params.path)).then(function (res) {
        // console.log(res);
        res.folders.forEach(function (folder) {
          folder.routingPath = decodeURIComponent(params.path + '/' + folder.name);
        });

        return res;
      });
    }
  });
});
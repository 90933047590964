define('webapp/routes/account/profile/activities', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);
      var myStorage = this.get('myStorage');
      myStorage.transactions.ajaxGet({ param: 'total' }).then(function (res) {
        _this.controllerFor(_this.routeName).set('totalPoints', res.score);
      });
    },
    model: function model() {
      var _this2 = this;

      return this.get('userProxy').fetchOwnEntries().then(function (entries) {
        _this2.controllerFor(_this2.routeName).set('selectedEntry', entries[0]);
        return entries;
      });
    }
  });
});
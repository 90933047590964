define('webapp/routes/password-reset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    cachedData: Ember.inject.service(),

    model: function model(params) {
      this.set('verificationToken', params.verification_token);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties({
        password: ''
      });
    },


    actions: {
      toggleVisibility: function toggleVisibility(id) {
        var controller = this.controllerFor(this.routeName);

        var field = document.getElementById(id);
        if (field.type === 'password') {
          field.type = 'text';
          controller.set('visibleField' + id, true);
        } else if (field.type === 'text') {
          field.type = 'password';
          controller.set('visibleField' + id, false);
        }
      },
      resetPassword: function resetPassword(password) {
        var _this = this;

        var message = this.get('message');
        var myStorage = this.get('myStorage');
        var verificationToken = this.get('verificationToken');
        var siteId = this.get('cachedData.cachedSite._id');

        if (password && verificationToken) {
          myStorage.user.ajaxPost({
            param: 'reset_password',
            data: {
              password: password,
              verificationToken: verificationToken,
              siteId: siteId
            }
          }).then(function () {
            message.infoT('user.ui.resetPasswordSuccess');
            _this.replaceWith('login');
          }).catch(function (err) {
            message.error(err.payload && err.payload.message);
          });
        }
      }
    }
  });
});
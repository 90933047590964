define('webapp/helpers/multiply', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var multiplicand = parseFloat(params[0]) || 1;
    var multiplier = parseFloat(params[1]) || 1;
    var power = parseFloat(params[2]) || 1;
    var result = multiplicand * multiplier * power;
    var digits = params[3] || 0;

    try {
      return result < 0.1 ? '< 0,1' : result.toFixed(digits).replace('.', ',');
    } catch (e) {
      return number;
    }
  });
});
define('webapp/helpers/opening-hours-text', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var TWO_HOURS = 1000 * 3600 * 2;

  function compareDate(date1, date2) {
    var diff = date1.getFullYear() - date2.getFullYear();
    if (diff !== 0) {
      return diff;
    }
    diff = date1.getMonth() - date2.getMonth();
    if (diff !== 0) {
      return diff;
    }
    diff = date1.getDate() - date2.getDate();
    return diff;
  }

  exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),

    _obsLocale: Ember.observer('i18n.locale', function () {
      this.recompute();
    }),

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          openingHours = _ref2[0];

      var i18n = this.get('i18n');

      var tNowClosed = i18n.t('openingHours.tNowClosed').toString();
      var tOpeningHoursNotKnown = i18n.t('openingHours.tOpeningHoursNotKnown').toString();
      var tNowOpen = i18n.t('openingHours.tNowOpen').toString();
      var tNowStillOpen = i18n.t('openingHours.tNowStillOpen').toString();
      var tNextOpeningHourUnknown = i18n.t('openingHours.tNextOpeningHourUnknown').toString();
      var tSoonOpenFrom = i18n.t('openingHours.tSoonOpenFrom').toString();
      var tFrom = i18n.t('openingHours.tFrom').toString();
      var tUntil = i18n.t('openingHours.tUntil').toString();
      var tOpenAgainFrom = i18n.t('openingHours.tOpenAgainFrom').toString();
      var tOpenAgainFrom2 = i18n.t('openingHours.tOpenAgainFrom2').toString();

      var now = new Date();
      var events = openingHours && openingHours.events || [];

      var serverTimezoneOffset = openingHours.timezoneOffset;
      var localTimezoneOffset = new Date().getTimezoneOffset();
      var diff = localTimezoneOffset - serverTimezoneOffset;

      if (diff) {
        events = events.map(function (_ref3) {
          var from = _ref3.from,
              to = _ref3.to;

          return {
            from: new Date(new Date(from).getTime() + 60000 * diff),
            to: new Date(new Date(to).getTime() + 60000 * diff)
          };
        });
      }

      var data = events.map(function (evt) {
        return [new Date(evt.from), new Date(evt.to)];
      });

      if (data.length === 0) {
        return [tNowClosed, tOpeningHoursNotKnown].join(', ');
      }
      var open = false;
      var nowT = now.getTime();
      var idx = 0;
      var firstText = void 0,
          secondText = void 0;

      while (idx < data.length && nowT >= data[idx][0].getTime()) {
        if (data[idx][1].getTime() >= nowT) {
          open = true;
        }
        idx++;
      }
      if (open) {
        idx--;
        if (data[idx][1].getTime() - nowT <= TWO_HOURS) {
          firstText = tNowStillOpen;
        } else {
          firstText = tNowOpen;
        }

        secondText = tUntil + ' ' + (0, _moment.default)(data[idx][1]).format('LT');
      } else {
        firstText = tNowClosed;
        if (idx === data.length) {
          secondText = tNextOpeningHourUnknown;
        } else {
          var nextM = (0, _moment.default)(data[idx][0]);
          if (data[idx][0].getTime() - nowT <= TWO_HOURS) {
            secondText = tSoonOpenFrom + ' ' + nextM.format('LT');
          }
          //uebermorgen wieder geoeffnet ab blabla im element[2]
          else if (compareDate(now, data[idx][0]) === 0) {
              secondText = tOpenAgainFrom + ' ' + nextM.format('LT');
            } else {
              secondText = tOpenAgainFrom2 + ' ' + nextM.format('dddd') + ', ' + nextM.format('l') + ' ' + tFrom + ' ' + nextM.format('LT');
            }
        }
      }

      var text = [firstText, secondText].join(', ');
      return { text: text, isOpen: open };
    }
  });
});
define('webapp/components/multi-description', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    hasProgress: false,
    shownItems: Ember.computed('descriptions.[]', function () {
      var descriptions = this.get('descriptions') || [];

      var prevItem = void 0;

      return descriptions.map(function (item) {
        var prevProgress = prevItem ? prevItem.progress : 0;
        prevItem = item;
        return {
          item: item,
          min: prevProgress
        };
      });
    }),

    getLastItem: function getLastItem() {
      var items = this.get('descriptions');
      return items.length && items[items.length - 1];
    },
    getLastProgress: function getLastProgress() {
      var lastItem = this.getLastItem();
      if (lastItem && lastItem.progress) {
        return lastItem.progress;
      } else {
        return 0;
      }
    },
    getEmptyItem: function getEmptyItem() {
      return {
        createdAt: new Date(),
        title: '',
        content: '',
        code: '',
        images: [],
        progress: this.getLastProgress()
      };
    },


    actions: {
      removeItem: function removeItem(item) {
        var descriptions = this.get('descriptions');
        descriptions.removeObject(item);
      },
      clearDescriptions: function clearDescriptions() {
        this.set('descriptions', []);
      },
      addItemBefore: function addItemBefore() {
        var descriptions = this.get('descriptions');
        descriptions.unshiftObject(this.getEmptyItem());
      },
      addItem: function addItem() {
        var descriptions = this.get('descriptions');
        descriptions.pushObject(this.getEmptyItem());
      },
      changeProgress: function changeProgress(item, event) {
        var value = event.target.value;
        Ember.set(item, 'progress', value);
        var descriptions = this.get('descriptions') || [];
        var lastStep = descriptions[descriptions.length - 1];

        this.sendAction('changeMainProgress', lastStep.progress);
      }
    }
  });
});
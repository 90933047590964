define('webapp/components/social-feed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    relType: 'Entry',

    didInsertElement: function didInsertElement() {
      var _this = this;

      var myStorage = this.get('myStorage');
      myStorage.interfaces.ajaxPost({
        param: 'facebook/get_feed',
        data: {
          rel: this.get('rel'),
          relType: this.get('relType')
        }
      }).then(function (res) {
        _this.set('posts', res.posts);
      });
    }
  });
});
define('webapp/helpers/max-array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.maxArray = maxArray;
  function maxArray(args) {
    // window.console.log('helpers/max-array.js minArray');

    var array = args[0];
    var parent = args[1];
    var key = args[2];

    var highest = Number.NEGATIVE_INFINITY;

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = array[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var obj = _step.value;

        if (obj[parent]) {
          var value = obj[parent][key];

          if (value > highest) {
            highest = value;
          }
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    if (highest === Number.NEGATIVE_INFINITY) {
      highest = 0;
    }

    return highest;
  }

  exports.default = Ember.Helper.helper(maxArray);
});
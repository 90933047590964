define('webapp/routes/account/company', ['exports', 'webapp/utils/defaults-deep', 'ember-cp-validations', 'webapp/utils/clone-deep', 'webapp/utils/misc', 'webapp/utils/validation-utils'], function (exports, _defaultsDeep, _emberCpValidations, _cloneDeep, _misc, _validationUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var Validations = (0, _emberCpValidations.buildValidations)({
    // title: presenceValidation('user.company.companyName'),
    'address.firstName': (0, _validationUtils.presenceValidation)('user.ui.firstName'),
    'address.lastName': (0, _validationUtils.presenceValidation)('user.ui.lastName'),
    'address.street': (0, _validationUtils.presenceValidation)('user.ui.street'),
    'address.houseNumber': (0, _validationUtils.presenceValidation)('entries.entry.fields.address.houseNumber'),
    'address.zip': (0, _validationUtils.presenceValidation)('user.ui.zip'),
    'address.city': (0, _validationUtils.presenceValidation)('user.ui.city'),
    'address.countryCode': (0, _validationUtils.presenceValidation)('user.ui.country')

    // if no contact detail is shown
    // 'contact.phone': validator('presence', {
    //   presence: true,
    //   ignoreBlank: true,
    //   descriptionKey: 'user.ui.phone'
    // }),
    // 'contact.email': validator('format', {
    //   presence: true,
    //   type: 'email',
    //   descriptionKey: 'user.ui.email'
    // })
  });

  function defaultCustomer(data, ownerInjection) {
    return Ember.Object.extend(Validations).create(ownerInjection, (0, _defaultsDeep.default)(_extends({}, data), {
      title: '',
      address: {
        street: '',
        houseNumber: '',
        city: '',
        zip: '',
        country: ''
      },
      contact: {
        phone: '',
        email: ''
      },
      documents: []
    }));
  }

  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    viewService: Ember.inject.service(),

    model: function model() {
      var _this = this;

      var myStorage = this.get('myStorage');
      var controller = this.controllerFor(this.routeName);

      return myStorage.customer.ajaxGet().then(function (customer) {
        // customer doesnt exist yet
        if (customer === null) {
          controller.set('isNew', true);
        } else {
          controller.set('isNew', false);
        }

        return defaultCustomer(customer || {}, Ember.getOwner(_this).ownerInjection());
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('addressModalShown', false);
    },


    actions: {
      saveCustomer: function saveCustomer(data) {
        var _this2 = this;

        data = (0, _misc.omit)((0, _cloneDeep.default)(data), ['users']);
        var myStorage = this.get('myStorage');
        var message = this.get('message');
        var controller = this.controllerFor(this.routeName);
        var isNew = controller.get('isNew');

        var promise = void 0;

        if (isNew) {
          if (!data.title) {
            data.title = data.address.firstName + ' ' + data.address.lastName;
          }
          promise = myStorage.customer.ajaxPost({ data: data });
        } else {
          promise = myStorage.customer.ajaxPut({ data: data });
        }

        promise.then(function () {
          message.info('Kunde gespeichert');
          _this2.set('viewService.skipScroll', true);
          _this2.refresh();
          _this2.get('userProxy').fetchCustomer();
        }).catch(function (err) {
          message.info('Fehler beim speichern des Kunden </br> ' + (err.payload && err.payload.message));
        });
      }
    }
  });
});
define('webapp/components/widgets/widget-similar-entries', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    entryContainer: Ember.inject.service(),
    productContainer: Ember.inject.service(),
    articleContainer: Ember.inject.service(),
    languageService: Ember.inject.service(),
    cachedData: Ember.inject.service(),

    items: Ember.computed(function () {
      return [];
    }),

    pageType: Ember.computed.oneWay('cmsService.activePage.type'),

    getPerPage: function getPerPage() {
      var perPage = Number(this.get('data.options.perPage')) || 10;
      return perPage;
    },
    fetchEntriesByTags: function fetchEntriesByTags(tags) {
      var myStorage = this.get('myStorage');

      return myStorage.search.ajaxPost({
        query: {
          lang: this.get('languageService.selectedLanguage'),
          site: this.get('cachedData.cachedSite._id')
        },
        data: {
          itemTypes: ['entry'],
          tagFilter: tags,
          size: this.getPerPage()
        }
      }).then(function (res) {
        return res.hits;
      });
    },
    getContainerDetail: function getContainerDetail() {
      var pageType = this.get('pageType');

      if (pageType === 'articleContainer') {
        return this.get('articleContainer.detail');
      } else if (pageType === 'entryContainer') {
        return this.get('entryContainer.detail');
      } else if (pageType === 'productContainer') {
        return this.get('productContainer.detail');
      }
    },
    fetchSimilarEntries: function fetchSimilarEntries(detail) {
      var tags = detail.tags;
      var detailId = detail._id;

      if (tags && tags.length) {
        return this.fetchEntriesByTags(tags).then(function (items) {
          return items.filter(function (item) {
            return item._id !== detailId;
          });
        });
      } else {
        return Ember.RSVP.resolve([]);
      }
    },
    fetchSimilarProducts: function fetchSimilarProducts(detail) {
      var primaryCategoryId = detail.primaryCategory && detail.primaryCategory._id;
      var myStorage = this.get('myStorage');

      var queryClauses = [{
        more_like_this: {
          fields: ['title', 'shortDescription', 'description'],
          like: [{
            doc: {
              title: detail.title,
              shortDescription: detail.shortDescription,
              description: detail.description
            }
          }]
        }
      }];

      if (primaryCategoryId) {
        queryClauses.push({
          term: {
            'categories._id': primaryCategoryId
          }
        });
      }

      return myStorage.search.ajaxPost({
        query: {
          lang: this.get('languageService.selectedLanguage'),
          site: this.get('cachedData.cachedSite._id')
        },
        data: {
          itemType: ['product'],
          customQuery: {
            bool: {
              must: queryClauses,
              must_not: { ids: { values: [detail._id] } }
            }
          },
          size: this.getPerPage()
        }
      }).then(function (res) {
        return res.hits;
      });
    },
    fetchSimilarArticles: function fetchSimilarArticles(article) {
      var myStorage = this.get('myStorage');

      var searchData = {
        search: {
          bool: {
            must: {
              more_like_this: {
                fields: ['title', 'description'],
                like: [{
                  doc: {
                    title: article.title,
                    description: article.description
                  }
                }]
              }
            },
            must_not: { ids: { values: [article._id] } }
          }
        },
        page: 1,
        perPage: this.getPerPage(),
        aggregations: false
      };

      return myStorage.article.ajaxPost({
        param: 'search_public',
        data: searchData,
        query: { lang: this.get('languageService.selectedLanguage') }
      }).then(function (res) {
        return res.model;
      });
    },
    fetchSimilarItems: function fetchSimilarItems() {
      var _this = this;

      if (this.isDestroyed) {
        return;
      }
      var pageType = this.get('pageType');
      var itemType = this.get('data.options.itemType');

      if (!itemType) {
        // infer itemtype from active container (show products on productsContainer)
        if (pageType === 'entryContainer') {
          itemType = 'entry';
        } else if (pageType === 'productContainer') {
          itemType = 'product';
        } else if (pageType === 'articleContainer') {
          itemType = 'article';
        }
      }

      var detail = this.getContainerDetail();
      if (!detail) {
        this.set('items', []);
        return;
      }

      var itemsP = void 0;
      if (itemType === 'entry') {
        itemsP = this.fetchSimilarEntries(detail);
      } else if (itemType === 'product') {
        itemsP = this.fetchSimilarProducts(detail);
      } else if (itemType === 'article') {
        itemsP = this.fetchSimilarArticles(detail);
      } else {
        itemsP = Ember.RSVP.resolve([]);
      }

      return itemsP.then(function (items) {
        if (!_this.isDestroyed) {
          _this.set('items', items);
        }
      });
    },


    _obsChanges: Ember.observer('cmsService.activePage', 'entryContainer.detail', 'productContainer.detail', 'articleContainer.detail', function () {
      Ember.run.debounce(this, this.fetchSimilarItems, 100);
    }),

    didInsertElement: function didInsertElement() {
      Ember.run.debounce(this, this.fetchSimilarItems, 100);
    }
  });
});
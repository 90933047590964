define('webapp/components/account/company-users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    cachedData: Ember.inject.service(),

    isReady: false,
    isSaving: false,
    addUserModalShown: false,
    userData: {},

    loadCustomer: function loadCustomer() {
      var _this = this;

      var myStorage = this.get('myStorage');
      return myStorage.customer.ajaxGet().then(function (customer) {
        _this.set('customer', customer);
      });
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.loadCustomer().then(function () {
        _this2.set('isReady', true);
      });
    },


    actions: {
      showAddUserModal: function showAddUserModal() {
        this.set('addUserModalShown', true);
        this.set('userData', {
          firstName: '',
          lastName: '',
          email: '',
          userName: '',
          type: 'default'
        });
      },
      closeAddUserModal: function closeAddUserModal() {
        this.set('addUserModalShown', false);
      },
      removeUser: function removeUser(user) {
        var _this3 = this;

        if (this.get('isSaving')) {
          return;
        }
        var customer = this.get('customer');
        var users = customer.users.filter(function (x) {
          return x.item._id !== user._id;
        }).map(function (x) {
          return { type: x.type, item: x.item._id };
        });

        var myStorage = this.get('myStorage');
        UIkit.modal.confirm('Benutzer ' + user.firstName + ' ' + user.lastName + ' wirklich entfernen?').then(function () {
          _this3.set('isSaving', true);
          myStorage.customer.ajaxPut({ data: { users: users } }).then(function () {
            return _this3.loadCustomer().then(function () {
              _this3.set('isSaving', false);
            });
          }).catch(function (err) {
            var message = _this3.get('message');
            message.error(err.payload.message);
          });
        });
      },
      addUser: function addUser() {
        var _this4 = this;

        var userData = this.get('userData');
        var myStorage = this.get('myStorage');
        var message = this.get('message');
        this.set('isSaving', true);
        return myStorage.customer.ajaxPost({ param: 'create_user', data: userData }).then(function () {
          _this4.loadCustomer().then(function () {
            _this4.set('isSaving', false);
            _this4.set('addUserModalShown', false);
          });
        }).catch(function (err) {
          message.error(err.payload.message);
          _this4.set('isSaving', false);
        });
      },
      sendInviteMail: function sendInviteMail(user) {
        var _this5 = this;

        var myStorage = this.get('myStorage');
        var message = this.get('message');
        return myStorage.customer.ajaxPost({
          param: 'invite_user',
          data: {
            userId: user._id,
            siteId: this.get('cachedData.cachedSite._id')
          }
        }).then(function () {
          _this5.loadCustomer().then(function () {
            message.info('Einladungsmail wird verchickt');
          });
        }).catch(function (err) {
          message.error(err.payload.message);
        });
      }
    }
  });
});
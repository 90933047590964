define('webapp/components/account/user-login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    cmsService: Ember.inject.service(),

    email: '',
    password: '',

    showStayLoggedIn: true,

    buttonDisabled: Ember.computed('email', 'password', function () {
      return !(this.get('email').trim() && this.get('password').trim());
    }),

    actions: {
      login: function login() {
        var _this = this;

        var userStorage = this.get('myStorage.user');
        var userProxy = this.get('userProxy');
        var data = this.getProperties(['email', 'password', 'staySignedIn']);

        this.set('loginError', false);

        userStorage.ajaxPost({
          param: 'login',
          data: data,
          query: {
            sessionId: this.get('cmsService.sessionId')
          }
        }).then(function (data) {
          userProxy._login(data);
          _this.sendAction('afterLogin', null);
        }).catch(function (error) {
          _this.sendAction('afterLogin', error);
        });
      },
      togglePasswordVisibility: function togglePasswordVisibility() {
        var visibleFieldpass = this.get('visibleFieldpass');
        this.set('visibleFieldpass', !visibleFieldpass);
        var passwordField = document.getElementById('password');
        if (passwordField.type === 'password') {
          passwordField.type = 'text';
        } else if (passwordField.type === 'text') {
          passwordField.type = 'password';
        }
      }
    }
  });
});
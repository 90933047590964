define('webapp/helpers/is-long-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var text = params[0];
    var limit = params[1] || 800;
    return text && text.length >= limit;
  });
});
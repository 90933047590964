define('webapp/components/widgets/widget-slideshow', ['exports', 'webapp/components/html-wrapper'], function (exports, _htmlWrapper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function optionsToUikitString() {
    var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return Object.keys(opts).filter(function (key) {
      return opts[key] !== undefined && opts[key] !== '';
    }).map(function (key) {
      return key + ': ' + opts[key];
    }).join('; ');
  }

  exports.default = _htmlWrapper.default.extend({
    slideshowOptionsFlat: Ember.computed('data.options.slideshowOptions', function () {
      var slideshowOptions = this.get('data.options.slideshowOptions');

      return optionsToUikitString(slideshowOptions);
    }),

    viewportOptionsFlat: Ember.computed('data.options.viewportOptions', function () {
      var enableViewport = this.get('data.options.enableViewport');

      if (enableViewport) {
        return optionsToUikitString(this.get('data.options.viewportOptions'));
      } else {
        return false;
      }
    })
  });
});
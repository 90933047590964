define('webapp/utils/head-tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function generateHeadTag(name, content) {
    var _attrs;

    var nameKey = name.indexOf('og:') === 0 || name.indexOf('article:') === 0 ? 'property' : 'name';

    return {
      type: 'meta',
      tagId: name.replace(/:/g, '-'),
      attrs: (_attrs = {}, _defineProperty(_attrs, nameKey, name), _defineProperty(_attrs, 'content', content), _attrs)
    };
  }

  function generateHeadTags(names, content) {
    return names.map(function (name) {
      return generateHeadTag(name, content);
    });
  }

  // later tags overwrite earlier
  function removeDuplicates(headTags) {
    var filteredTags = [];
    var assignedIds = [];
    var reversedTags = headTags.slice().reverse();

    reversedTags.forEach(function (tag) {
      if (assignedIds.indexOf(tag.tagId) === -1) {
        filteredTags.push(tag);
        assignedIds.push(tag.tagId);
      }
    });

    return filteredTags.reverse();
  }

  exports.generateHeadTag = generateHeadTag;
  exports.generateHeadTags = generateHeadTags;
  exports.removeDuplicates = removeDuplicates;
});
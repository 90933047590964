define('webapp/components/comment-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    i18n: Ember.inject.service(),
    message: Ember.inject.service(),

    classNames: ['comment-form'],

    isLoggedIn: Ember.computed.alias('userProxy.isLoggedIn'),
    showForm: false,
    showThrobber: false,

    canComment: false,
    mustLogin: false,

    user: null,
    userName: '',
    title: '',
    content: '',
    replyTo: '',
    parentType: '',
    parent: '',

    init: function init() {
      this._super.apply(this, arguments);

      var mainPostContainer = this.get('mainPostContainer');
      if (mainPostContainer) {
        mainPostContainer.set('commentForm', this);
      }

      var userProxy = this.get('userProxy');
      var commentOptions = this.get('commentOptions');

      var canComment = false;
      var mustLogin = false;

      if (commentOptions.registeredUsersOnly) {
        if (userProxy._isLoggedIn()) {
          canComment = true;
          mustLogin = false;
        } else {
          canComment = false;
          mustLogin = true;
        }
      } else {
        canComment = true;
        mustLogin = false;
      }

      this.setProperties({ canComment: canComment, mustLogin: mustLogin });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var mainPostContainer = this.get('mainPostContainer');
      if (mainPostContainer) {
        mainPostContainer.set('commentForm', null);
      }
    },


    userNameDisabled: Ember.computed('userProxy.isLoggedIn', function () {
      return this.get('userProxy.isLoggedIn');
    }),

    clear: function clear() {
      this.setProperties({
        user: null,
        userName: '',
        title: '',
        content: ''
      });
    },
    afterSave: function afterSave(success) {
      var i18n = this.get('i18n');
      var message = this.get('message');
      var publishImmediately = this.get('commentOptions.publishImmediately');

      if (success) {
        // trigger reloadComments action on postContainer if available
        var postContainer = this.get('mainPostContainer');
        if (postContainer) {
          postContainer.send('reloadComments');
        }

        var translation = publishImmediately ? 'comments.message.afterSaveSuccess' : 'comments.message.afterSaveUnlock';
        message.info(i18n.t(translation).toString());
      } else {
        message.error(i18n.t('comments.message.afterSaveError').toString());
      }
    },


    actions: {
      abort: function abort() {
        this.clear();
        this.set('showForm', false);
      },
      save: function save() {
        var _this = this;

        var comments = this.get('myStorage.comments');
        var comment = this.getProperties(['user', 'userName', 'title', 'content', 'replyTo', 'parent', 'parentType']);

        if (!comment.replyTo) {
          comment.replyTo = undefined;
        }

        this.set('showThrobber', true);
        comments.ajaxPost({ data: comment }).then(function (data) {
          _this.set('showThrobber', false);
          _this.set('showForm', false);
          _this.clear();
          _this.afterSave(true);
        }).catch(function () {
          _this.set('showThrobber', false);
          _this.afterSave(false);
        });
      },
      add: function add() {
        var i18n = this.get('i18n');
        var userProxy = this.get('userProxy');

        var user = null;
        var userName = '';
        var showForm = true;

        if (userProxy._isLoggedIn()) {
          user = userProxy.get('_id');
          userName = userProxy.get('shownName');
        } else {
          userName = i18n.t('comments.anonymous-user-name').toString();
        }

        this.setProperties({ user: user, userName: userName, showForm: showForm });
      }
    }
  });
});
define('webapp/components/detail/article-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var popupModalClosed = false;

  exports.default = Ember.Component.extend({
    media: Ember.inject.service(),
    articleContainer: Ember.inject.service(),
    gdprService: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    router: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    shareService: Ember.inject.service(),
    detail: Ember.computed.oneWay('articleContainer.detail'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('popupModalClosed', popupModalClosed);
      var isMobile = this.get('media.isMobile');

      if (isMobile) {
        this._scrollHandler = function () {
          return _this.scrollHandler();
        };
        window.addEventListener('scroll', this._scrollHandler);
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this._scrollHandler) {
        window.removeEventListener('scroll', this._scrollHandler);
      }
    },
    scrollHandler: function scrollHandler() {
      if (!this.popupModalClosed && !this.popupModalShown && window.scrollY > 500) {
        this.set('popupModalShown', true);
      }
    },


    maxImageChars: Ember.computed('detail.images', function () {
      var maxSize = 0;
      var images = this.get('detail.images');
      images.forEach(function (img) {
        var currentSize = 0;
        if (img.title) {
          currentSize += 2 * img.title.length;
        }
        if (img.description) {
          currentSize += img.description.length;
        }
        if (currentSize > maxSize) {
          maxSize = currentSize;
        }
      });
      return maxSize;
    }),

    slideshowHeight: Ember.computed('media.isMobile', 'maxImageChars', function () {
      var isMobile = this.get('media.isMobile');
      if (isMobile) {
        var maxImageChars = this.get('maxImageChars');
        if (maxImageChars > 400) {
          return 500;
        }
        if (maxImageChars < 100) {
          return 300;
        }
      }
      return 400;
    }),

    isVideo: Ember.computed('detail', 'articleContainer.flatCategories', function () {
      var detail = this.get('detail');

      if (detail.videos && detail.videos.length && detail.primaryCategory) {
        var primaryCategoryId = detail.primaryCategory._id;
        var flatCategories = this.get('articleContainer.flatCategories');
        var vidCat = flatCategories.find(function (x) {
          return x.internalName === 'videos';
        });

        return !!(vidCat && (vidCat._id === primaryCategoryId || vidCat.children.find(function (cat) {
          return cat._id === primaryCategoryId;
        })));
      }

      return false;
    }),

    filteredImages: Ember.computed('detail.images', function () {
      var images = this.get('detail.images') || [];
      if (images.length <= 1) {
        return images;
      } else {
        return images.filter(function (img) {
          return img.displayType !== 'cover';
        });
      }
    }),

    rootCategory: Ember.computed('articleContainer.flatCategories', function () {
      var rootCategory = this.get('articleContainer.flatCategories').find(function (cat) {
        return cat.slug === 'article';
      });
      if (rootCategory) {
        return _extends({}, rootCategory, {
          children: rootCategory.children && rootCategory.children.filter(function (child) {
            return !child.noDisplay;
          })
        });
      }
    }),

    embeddedVideos: Ember.computed('detail.embeddedVideos', function () {
      var embeddedVideos = this.get('detail.embeddedVideos');
      return embeddedVideos.filter(function (vid) {
        return vid.playerType === 'code' && vid.code || vid.playerType !== 'code' && vid.videoId;
      });
    }),

    didRender: function didRender() {
      if (window && window.twttr) {
        window.twttr.widgets.load();
      }
    },


    actions: {
      shareArticle: function shareArticle(type, refArticle) {
        var router = this.get('router');
        var shareService = this.get('shareService');

        if (!this.get('fastboot.isFastBoot')) {
          shareService.shareLinkBuilder(type, refArticle.title, router.currentURL, refArticle.shortDescription);
        }
      },
      closePopup: function closePopup() {
        this.set('popupModalShown', false);
        this.set('popupModalClosed', true);
        popupModalClosed = true;
      }
    }
  });
});
define('webapp/components/detail/document-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    documentContainer: Ember.inject.service(),
    router: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    fingerprintService: Ember.inject.service(),

    parentRoute: Ember.computed('documentContainer.detail.parentFolder', 'page.parentFolder', function () {
      var page = this.get('page');
      var parentFolder = this.get('documentContainer.parentFolder');

      var parentRoute = parentFolder.replace(page.options.folder + '/', '');
      if (parentRoute === page.options.folder) {
        return undefined;
      } else {
        return parentRoute;
      }
    }),

    actions: {
      download: function download(file) {

        // this.set('mailRequired', config.document.mustEnterMail);
        this.set('downloadFile', file);
        this.set('openEmailDialog', true);
      },
      cancel: function cancel() {
        this.set('openEmailDialog', false);
      },
      confirm: function confirm(file) {
        var user = this.get('userProxy');
        var myStorage = this.get('myStorage');
        var fingerPrint = this.get('fingerprintService');
        myStorage.media.ajaxPost({
          param: 'public_download',
          data: { media: file, fingerPrint: fingerPrint, user: user }
        });
        this.set('openEmailDialog', false);
      }
    }
  });
});
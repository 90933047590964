define('webapp/components/multi-fields-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function createArray(size, idx, value) {
    var array = new Array(size).fill(null);
    array[idx] = value;
    return array;
  }

  exports.default = Ember.Component.extend({
    hasChildRelations: Ember.computed('relations', function () {
      var relations = this.get('relations');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = relations[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var rel = _step.value;

          if (rel.childRelations && rel.childRelations.length) {
            return true;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return false;
    }),

    mergedFields: Ember.computed('relations', function () {
      var relations = this.get('relations');
      if (!relations) {
        return;
      }

      var fields = [];

      relations.forEach(function (_ref, idx) {
        var item = _ref.item;

        if (item.fullFields) {
          item.fullFields.forEach(function (field) {
            var bucketField = fields.find(function (f) {
              return f.key === field.key;
            });
            if (bucketField) {
              bucketField.values[idx] = field.value;
            } else {
              fields.push({
                key: field.key,
                kind: field.kind,
                multi: field.multi,
                name: field.name,
                options: field.options,
                categorySlug: field.categorySlug,
                values: createArray(relations.length, idx, field.value)
              });
            }
          });
        }
      });

      return fields;
    })
  });
});
define('webapp/routes/account/subscriptions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var myStorage = this.get('myStorage');
      var controller = this.controllerFor(this.routeName);

      myStorage.subscription.ajaxGet({ param: 'list' }).then(function (subscriptions) {
        subscriptions.forEach(function (sub) {
          if (sub.isDefault) {
            controller.set('selectedSubscription', sub);
          }
        });
        controller.set('subscriptions', subscriptions);
      });
    }
  });
});
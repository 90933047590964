define('webapp/components/search/search-paging', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    pagingSize: 10,
    perPage: 10,
    totalHits: null,

    totalPages: Ember.computed('totalHits', 'perPage', function () {
      var totalHits = this.get('totalHits');
      var perPage = this.get('perPage');
      return Math.ceil(totalHits / perPage);
    }),

    pagingIdx: Ember.computed('currentPage', function () {
      var currentPage = this.get('currentPage');
      return currentPage - 1;
    }),

    maximumReached: Ember.computed('totalHits', 'perPage', 'pagingIdx', function () {
      var props = this.getProperties('totalHits', 'perPage', 'pagingIdx');
      return props.totalHits / props.perPage > 1000 && props.pagingIdx === 999;
    }),

    startIdx: Ember.computed('totalPages', 'pagingIdx', function () {
      var totalPages = this.get('totalPages');
      var pagingIdx = this.get('pagingIdx');
      var pagingSize = this.get('pagingSize');
      var biggestPossibleStart = totalPages - pagingSize;

      var startIdx = pagingIdx - Math.floor(pagingSize / 2);
      if (startIdx > biggestPossibleStart) {
        startIdx = biggestPossibleStart;
      }
      if (startIdx < 0) {
        startIdx = 0;
      }
      return startIdx;
    }),

    endIdx: Ember.computed('startIdx', 'totalPages', 'pagingIdx', function () {
      var startIdx = this.get('startIdx');
      var totalPages = this.get('totalPages');
      var pagingSize = this.get('pagingSize');

      var endIdx = startIdx + pagingSize;
      if (endIdx > totalPages) {
        endIdx = totalPages;
      }
      return endIdx;
    }),

    pagesAvailable: Ember.computed('endIdx', 'totalPages', function () {
      return this.get('totalPages') > this.get('endIdx');
    }),

    pagingElements: Ember.computed('startIdx', 'endIdx', function () {
      var startIdx = this.get('startIdx');
      var endIdx = this.get('endIdx');
      var pagingIdx = this.get('pagingIdx');

      var elems = [];

      for (var i = startIdx; i < endIdx; i++) {
        elems.push({
          idx: i,
          text: i + 1,
          isActive: i === pagingIdx
        });
      }

      return elems;
    }),

    isStart: Ember.computed('pagingIdx', function () {
      return this.get('pagingIdx') === 0;
    }),

    isEnd: Ember.computed('pagingIdx', 'totalPages', function () {
      return this.get('pagingIdx') === this.get('totalPages') - 1;
    }),

    changePagingIdx: function changePagingIdx(pagingIdx) {
      this.sendAction('setPage', pagingIdx);
      var scrollToTop = this.get('scrollToTop');
      if (scrollToTop) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          // UIkit.Utils.scrollToElement($('body'));
        });
      }
    },


    actions: {
      setPagingIdx: function setPagingIdx(pagingIdx) {
        this.changePagingIdx(pagingIdx);
      },
      moveBack: function moveBack() {
        this.changePagingIdx(this.get('pagingIdx') - 1);
      },
      moveForward: function moveForward() {
        this.changePagingIdx(this.get('pagingIdx') + 1);
      },
      moveToStart: function moveToStart() {
        this.changePagingIdx(0);
      },
      moveToEnd: function moveToEnd() {
        this.changePagingIdx(this.get('totalPages') - 1);
      }
    }
  });
});
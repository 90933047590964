define('webapp/components/content-builder-view/elements/empty', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['data.options.class', 'containerProperties'],

    options: Ember.computed.oneWay('data.options')
  });
});
define('webapp/utils/request', ['exports', 'ember-ajax/request', 'webapp/utils/wait-for-fastboot'], function (exports, _request, _waitForFastboot) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // default headers
  var defHead = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  /*
   * posible (opt)ions
   *
   * data => the data that gets handed to the api by post
   * query => the data that gets handed to the api as query string (get)
   * header => object of headers options that get merged with the default headers
   * param => additional info that gets added to the URL
   * token => token for the header
   */

  var Request = Ember.Object.extend({
    _getUrl: function _getUrl(opt) {
      var uri = this.config.host;
      if (opt && opt.path) {
        uri += '/' + opt.path;
      } else if (this.modelName) {
        uri += '/' + this.modelName;
      }
      if (opt.param) {
        uri += '/' + opt.param;
      }

      var queryString = this._getQueryString(opt);
      if (queryString) {
        uri += '?' + queryString;
      }
      return uri;
    },
    fetchJSON: function fetchJSON(url, opt) {
      var fb = this.get('fastboot');
      opt.data = opt.body;
      opt.contentType = 'application/json';
      return (0, _waitForFastboot.default)((0, _request.default)(url, opt), fb);
      // return request(url, opt);
    },
    _getBasicAjaxConf: function _getBasicAjaxConf() {
      var opt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var url = this._getUrl(opt);
      var headers = Ember.assign({}, defHead, opt.headers);
      var body = opt.data ? opt.data : undefined;

      if (opt.token && !headers['Token']) {
        headers['Token'] = opt.token;
      }

      // if no token header exists, try to get it from userProxy
      if (!headers['Token']) {
        var token = this.get('userProxy.token');
        if (token) {
          headers['Token'] = token;
        }
      }

      return { url: url, headers: headers, body: body };
    },
    _getQueryString: function _getQueryString(opt) {
      var queryString = void 0;
      var query = opt.query;
      if (query) {
        queryString = Object.keys(query).filter(function (key) {
          return query[key] !== undefined;
        }).map(function (key) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);
        }).join('&');
      }
      return queryString;
    },
    ajaxGet: function ajaxGet(opt) {
      var conf = this._getBasicAjaxConf(opt);
      conf.method = 'GET';
      return this.fetchJSON(conf.url, conf);
    },
    ajaxPut: function ajaxPut(opt) {
      var conf = this._getBasicAjaxConf(opt);
      conf.method = 'PUT';
      if (opt.data) {
        conf.body = JSON.stringify(opt.data);
      }
      return this.fetchJSON(conf.url, conf);
    },
    ajaxPost: function ajaxPost(opt) {
      var conf = this._getBasicAjaxConf(opt);
      conf.method = 'POST';
      if (opt.data) {
        conf.body = JSON.stringify(opt.data);
      }
      return this.fetchJSON(conf.url, conf);
    },
    ajaxDelete: function ajaxDelete(opt) {
      var conf = this._getBasicAjaxConf(opt);
      conf.method = 'DELETE';
      if (opt.data) {
        conf.body = JSON.stringify(opt.data);
      }
      return this.fetchJSON(conf.url, conf);
    },
    update: function update(opt) {
      return this.ajaxPut(opt);
    },
    create: function create(opt) {
      return this.ajaxPost(opt);
    },
    remove: function remove(opt) {
      return this.ajaxDelete(opt);
    }
  });

  exports.default = Request;
});
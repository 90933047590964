define('webapp/routes/index', ['exports', 'webapp/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    cmsService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    searchService: Ember.inject.service(),
    viewService: Ember.inject.service(),
    languageService: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    fingerprintService: Ember.inject.service(),

    firstVisit: true,

    pageHandlers: Ember.computed(function () {
      return {
        search: 'searchService'
        // TODO only search required right now for bodystreet jobs. Those parts are copies from routes/page.js
      };
    }),

    setupPage: function setupPage(page, params) {
      var _this = this;

      var pageHandlers = this.get('pageHandlers');
      var handlerService = pageHandlers[page.type];
      this.set('currentHandlerService', handlerService && this.get(handlerService));
      this.set('page', page);

      if (handlerService) {
        return this.get(handlerService).setupPage(this, page, params).then(function (detail) {
          _this.set('cmsService.activeDetail', detail);
          return page;
        });
      } else {
        this.set('cmsService.activeDetail', null);
      }
      return Ember.RSVP.resolve(page);
    },
    maybeSwitchToLanguageHomePage: function maybeSwitchToLanguageHomePage() {
      if (this.get('fastboot.isFastBoot')) {
        return;
      }
      var languageService = this.get('languageService');
      if (languageService.multiLanguage && !(languageService.defaultLanguageOnRoot && languageService.languageIsDefault(languageService.selectedLanguage))) {
        this.transitionTo('page', languageService.selectedLanguage);
      } else {
        return false;
      }
    },
    beforeModel: function beforeModel(transition) {
      var fingerprintService = this.get('fingerprintService');
      return Ember.RSVP.all([fingerprintService.handleBeforeTransition(transition)]);
    },
    getHomePage: function getHomePage(params) {
      var _this2 = this;

      return this.get('cmsService').getHomePage().then(function (page) {
        if (page.type === 'search') {
          return _this2.setupPage(page, params);
        } else {
          return page;
        }
      });
    },
    model: function model(params) {
      var searchService = this.get('searchService');
      searchService.resetSearch();

      this.get('viewService').set('regionPage', null);

      if (this.get('firstVisit')) {
        this.set('firstVisit', false);
        if (!this.maybeSwitchToLanguageHomePage()) {
          return this.getHomePage(params);
        }
      } else {
        return this.getHomePage(params);
      }
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      var headTags = this.getPageHeadTags(model);

      this.set('headTags', headTags);
    }
  });
});
define('webapp/routes/redeem-code', ['exports', 'webapp/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      return this.get('cmsService').getAndSetActivePage('redeem-code').catch(function () {
        return _this.get('cmsService').getAndSetActivePage('login');
      }).then(function () {
        var myStorage = _this.get('myStorage');
        return myStorage.redemptionCode.ajaxGet({ param: params.code });
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var accessPermissions = this.get('userProxy.accessPermissions') || [];

      // TODO check if target entries in acl list, if only one, set this, else dropdown

      controller.setProperties({
        success: false,
        verificationEmail: '',
        target: null,
        entries: []
      });

      var entryIds = accessPermissions.filter(function (x) {
        return !x.allItems && x.targetModel === 'Entry' && x.target;
      }).map(function (x) {
        return x.target;
      });

      if (entryIds.length) {
        var myStorage = this.get('myStorage');
        Ember.RSVP.all(entryIds.map(function (id) {
          return myStorage.entryAdmin.ajaxGet({ param: id });
        })).then(function (entries) {
          if (entries.length) {
            controller.setProperties({ entries: entries, target: entries[0]._id });
          }
        });
      }
    }
  });
});
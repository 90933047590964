define('webapp/components/shopping/sepa-form', ['exports', 'webapp/config/environment', 'webapp/utils/inject-script'], function (exports, _environment, _injectScript) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),
    shoppingCart: Ember.computed.alias('shoppingCartService.shoppingCart'),
    paymentService: Ember.inject.service(),
    message: Ember.inject.service(),
    stripe: undefined,
    iban: undefined,
    clientSecret: undefined,
    tagName: 'form',
    elementId: 'sepa-payment-form',
    paymentMethod: 'sepa_debit',
    name: undefined,
    email: undefined,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var userProxy = this.get('userProxy');
      var firstName = userProxy.get('firstName');
      var lastName = userProxy.get('lastName');
      var name = firstName + ' ' + lastName;
      var email = userProxy.get('email');

      this.set('name', name);
      this.set('email', email);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _injectScript.default)('https://js.stripe.com/v3/').then(function () {
        if (window.Stripe) {
          _this.setupSepaForm();
        }
      });
    },
    setupSepaForm: function setupSepaForm() {
      if (this.isDestroyed) {
        return;
      }

      var stripe = this.get('stripe');
      if (stripe === undefined) {
        stripe = window.Stripe(_environment.default.stripePublishableKey);
        this.set('stripe', stripe);
      }

      // info for styling: https://stripe.dev/elements-examples/
      //                   https://stripe.com/docs/stripe-js
      var invoiceAddress = this.get('shoppingCartService.shoppingCart.invoiceAddress');
      var country = invoiceAddress.countryCode;
      var elements = stripe.elements();
      var style = {};
      var options = {
        style: style,
        supportedCountries: ['SEPA'],
        // If you know the country of the customer, you can optionally pass it to
        // the Element as placeholderCountry. The example IBAN that is being used
        // as placeholder reflects the IBAN format of that country.
        placeholderCountry: country
      };
      var iban = elements.create('iban', options);
      iban.mount('#iban-element');
      this.set('iban', iban);
      this.changeLoadingState(false);
    },
    changeLoadingState: function changeLoadingState(isLoading) {
      if (this.isDestroyed || !this.element) {
        return;
      }

      var button = this.element.querySelector('button');
      var buttonText = document.getElementById('button-text');
      var spinner = document.getElementById('spinner');

      if (isLoading) {
        button.disabled = true;
        buttonText.classList.add('uk-hidden');
        spinner.classList.remove('uk-hidden');
      } else {
        button.disabled = false;
        buttonText.classList.remove('uk-hidden');
        spinner.classList.add('uk-hidden');
      }
    },
    orderComplete: function orderComplete(clientSecret) {
      var _this2 = this;

      var paymentService = this.get('paymentService');
      var paymentMethod = this.get('paymentMethod');
      var stripe = this.get('stripe');

      stripe.retrievePaymentIntent(clientSecret).then(function (data) {
        var paymentIntent = data.paymentIntent;
        paymentService.saveStripePaymentInformations(paymentMethod, paymentIntent).then(function () {
          _this2.changeLoadingState(false);
          if (_this2.get('afterApprove')) {
            _this2.get('afterApprove')();
          }
        });
      });
    },
    submit: function submit(e) {
      var _this3 = this;

      e.preventDefault();

      var paymentService = this.get('paymentService');
      var paymentMethod = this.get('paymentMethod');
      var message = this.get('message');
      var stripe = this.get('stripe');
      var iban = this.get('iban');
      var name = this.get('name');
      var email = this.get('email');

      if (!e.target.reportValidity()) {
        // TODO: look into posible errors here
        var errorMessage = 'no valid';
        message.error(errorMessage);
        return;
      }

      this.changeLoadingState(true);
      paymentService.setupStripePaymentIntent(paymentMethod).then(function (data) {
        var clientSecret = data.clientSecret;
        _this3.set('clientSecret', clientSecret);
        stripe.confirmSepaDebitPayment(clientSecret, {
          payment_method: {
            sepa_debit: iban,
            billing_details: { name: name, email: email }
          }
        }).then(function (result) {
          if (result.error) {
            // TODO: probably need to look up a list of errors
            //       and add them to the translations
            message.error(result.error.message);
            _this3.changeLoadingState(false);
          } else {
            _this3.orderComplete(result.paymentIntent.client_secret);
          }
        });
      });
    }
  });
});
define('webapp/controllers/account/job-applications/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    applicationDetailItem: null,
    notesItem: null,

    resetState: function resetState() {
      this.setProperties({
        applicationDetailItem: null,
        notesItem: null
      });
    },


    actions: {
      showApplicationDetailModal: function showApplicationDetailModal(item) {
        this.set('applicationDetailItem', item);
      },
      closeApplicationDetailModal: function closeApplicationDetailModal() {
        this.set('applicationDetailItem', null);
      },
      showNotesModal: function showNotesModal(item) {
        this.set('notesItem', item);
      },
      closeNotesModal: function closeNotesModal() {
        this.set('notesItem', null);
      },
      resetFilters: function resetFilters() {
        this.setProperties({
          selectedCategories: [],
          filterJobAllocations: [],
          filterStatusQualifying: false,
          filterStatusHR: false,
          filterStatusDecision: null,
          filterLastStatusChange: false,
          jobId: null
        });
        this.set('page', 1);
        this.search(false);
      },
      toggleStatusFilterDecision: function toggleStatusFilterDecision(status) {
        var filterStatusDecision = this.get('filterStatusDecision');

        if (filterStatusDecision === status) {
          this.set('filterStatusDecision', null);
        } else {
          this.set('filterStatusDecision', status);
        }

        this.set('page', 1);
        this.search();
      }
    }
  });
});
define('webapp/components/password-form-block', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    pass: '',
    passConf: '',
    passOld: '',
    grid: '',
    quality: '',
    message: '',
    attributeBindings: 'uk-grid',

    _obsPass: Ember.observer('pass', 'passConf', 'passOld', function () {
      this.passwordChange();
    }),

    passwordChange: function passwordChange() {
      var i18n = this.get('i18n');

      var pass = this.get('pass');
      var passConf = this.get('passConf');
      var isRequired = this.get('isRequired');

      var isValid = false;
      var mustMatch = false;
      if (pass && pass !== '') {
        if (pass && passConf !== '') {
          if (pass === passConf) {
            isValid = true;
          } else {
            // passwords must match
            mustMatch = true;
          }
        } else {
          // passwords must match
          mustMatch = true;
        }
      }

      var message = '';
      var value = '';

      if (isValid) {
        value = pass;
      }

      if (mustMatch) {
        message = i18n.t('password.errors.password-and-conf-do-not-match').toString();
      }

      this.setProperties({ value: value, message: message });

      if (isRequired && (value !== '' || pass === '')) {
        this.set('canSubmit', true);
      } else {
        this.set('canSubmit', false);
      }
    }
  });
});
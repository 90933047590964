define('webapp/components/widgets/widget-person', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    showContact: Ember.computed.oneWay('data.options.showContact'),
    showGreeting: Ember.computed.oneWay('data.options.showGreeting'),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var personId = this.get('data.options.personId');
      var myStorage = this.get('myStorage');

      if (personId) {
        myStorage.person.ajaxGet({ param: personId }).then(function (res) {
          _this.set('person', res);
        });
      }
    }
  });
});
define('webapp/components/social-story', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      var story = this.get('story');

      // gets a storyobject containing:
      // the displayed text: text,
      // storytags consisting of: offset (index with relation to text), length
      var text = story.text;
      var storyArray = [];
      var index = 0;

      story.story_tags.forEach(function (storyTag) {
        if (index <= storyTag.offset) {
          storyArray.push({ text: text.slice(index, storyTag.offset), type: 'text' });
          index = storyTag.offset;
        }

        if (storyTag.offset === index) {
          storyArray.push({
            text: text.slice(index, index + storyTag.length),
            type: storyTag.type,
            id: storyTag.id
          });
          index = index + storyTag.length;
        }
      });
      if (index < text.length) {
        storyArray.push({ text: text.slice(index, text.length), type: 'text' });
      }

      this.set('storyArray', storyArray);
    }
  });
});
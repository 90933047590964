define('webapp/components/content-builder-view/containers/flex', ['exports', 'webapp/components/content-builder-view/containers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    classNameBindings: ['flexClass', 'horizontalClass', 'verticalClass'],

    horizontalClass: Ember.computed('options.horizontalAlign', function () {
      var horizontal = this.get('options.horizontalAlign');
      return 'uk-flex-' + horizontal;
    }),

    verticalClass: Ember.computed('options.verticalAlign', function () {
      var vertical = this.get('options.verticalAlign');
      return 'uk-flex-' + vertical;
    }),

    flexClass: "uk-flex"
  });
});
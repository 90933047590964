define('webapp/controllers/account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    userProxy: Ember.inject.service(),
    router: Ember.inject.service(),
    countService: Ember.inject.service(),
    accountRoutes: Ember.computed.alias('userProxy.accountRoutes')

  });
});
define('webapp/controllers/account/jobs/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    availableTemplateJobs: Ember.computed('templateJobs', 'model', function () {
      // const model = this.get('model');
      var templateJobs = this.get('templateJobs');
      return templateJobs;
      // const duplicatesOf = model.map(item => item.duplicateOf).filter(Boolean);

      // return templateJobs.filter(templateJob => !duplicatesOf.includes(templateJob._id));
    })
  });
});
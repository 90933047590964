define('webapp/services/newsletter-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    myStorage: Ember.inject.service(),

    setupPage: function setupPage(pageRoute, page) {
      var _this = this;

      var myStorage = this.get('myStorage');
      if (page.restSlug) {
        return myStorage.newsletter.ajaxGet({ param: page.restSlug }).then(function (detail) {
          _this.set('detail', detail);
          return detail;
        });
      } else {
        return Ember.RSVP.resolve(page);
      }
    }
  });
});
define('webapp/components/opening-hours', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function mapEvents(events, type) {
    return events.map(function (x) {
      return {
        from: (0, _moment.default)(x.from),
        to: (0, _moment.default)(x.to),
        type: type
      };
    });
  }

  function bucketifyEvents(events) {
    var startOfDay = (0, _moment.default)().startOf('week');
    var buckets = [];

    var timeMap = {};

    for (var i = 0; i < 7; i++) {
      var bucket = {
        date: startOfDay,
        events: []
      };
      timeMap[startOfDay.toDate().getTime()] = bucket;
      buckets.push(bucket);
      startOfDay = (0, _moment.default)(startOfDay).add(1, 'day');
    }

    var minHour = 9;
    var maxHour = 18;

    events.forEach(function (event) {
      var time = +(0, _moment.default)(event.from).startOf('day');
      var bucket = timeMap[time];
      var fromHour = void 0,
          toHour = void 0;
      if (bucket) {
        fromHour = event.from.hour();
        toHour = event.to.hour();
        if (event.to.minute() > 0) {
          toHour += 1;
        }
        if (fromHour < minHour) {
          minHour = fromHour;
        }
        if (toHour > maxHour) {
          maxHour = toHour;
        }

        bucket.events.push(event);
      }
    });

    if (minHour < 6) {
      minHour = 6;
    }
    if (maxHour > 22) {
      maxHour = 22;
    }

    var hoursWidth = (100 / (maxHour - minHour)).toFixed(1);
    var hours = [];
    for (var _i = minHour; _i < maxHour; _i++) {
      var left = (100 * (_i - minHour) / (maxHour - minHour)).toFixed(1);
      hours.push({
        hour: _i,
        style: Ember.String.htmlSafe('left: ' + left + '%; width: ' + hoursWidth + '%;')
      });
    }

    return { buckets: buckets, minHour: minHour, maxHour: maxHour, hours: hours };
  }

  function calculatePercentages(bucketData) {
    var buckets = bucketData.buckets;
    var minHour = bucketData.minHour;
    var maxHour = bucketData.maxHour;

    var now = (0, _moment.default)();
    var nowStartOfDay = +(0, _moment.default)(now).startOf('day');

    var nowHour = now.hour();
    var showNow = nowHour >= minHour && nowHour <= maxHour;

    buckets.forEach(function (bucket) {
      var events = bucket.events;
      var startOfDay = +(0, _moment.default)(bucket.date).hour(minHour);
      var diff = 1000 * 3600 * (maxHour - minHour);

      if (showNow && nowStartOfDay == +bucket.date) {
        var leftNow = (100 * (now - startOfDay) / diff).toFixed(1);
        bucket.now = {
          date: now,
          style: Ember.String.htmlSafe('left: ' + leftNow + '%;')
        };
      }

      events.forEach(function (event) {
        var fromPercent = (100 * (event.from - startOfDay) / diff).toFixed(1);
        var toPercent = (100 * (event.to - startOfDay) / diff).toFixed(1);
        if (fromPercent < 0) {
          fromPercent = 0;
        }
        if (toPercent > 100) {
          toPercent = 100;
        }
        event.fromPercent = fromPercent;
        event.toPercent = toPercent;
        event.style = Ember.String.htmlSafe('left: ' + fromPercent + '%; width: ' + (toPercent - fromPercent) + '%;');
      });
    });

    return bucketData;
  }

  function getEvents(openingHours) {
    if (!openingHours) {
      return [];
    }
    var sure = openingHours.events || [];
    // var maybe = openingHours.eventsMaybe || [];

    var serverTimezoneOffset = openingHours.timezoneOffset;
    var localTimezoneOffset = new Date().getTimezoneOffset();
    var diff = localTimezoneOffset - serverTimezoneOffset;

    if (diff) {
      sure = sure.map(function (_ref) {
        var from = _ref.from,
            to = _ref.to;

        return {
          from: new Date(new Date(from).getTime() + 60000 * diff),
          to: new Date(new Date(to).getTime() + 60000 * diff)
        };
      });
    }

    sure = mapEvents(sure, 'sure');
    // maybe = mapEvents(maybe, 'maybe');

    // var events = sure.concat(maybe);
    var events = sure;

    events.sort(function (a, b) {
      return a.from - b.from;
    });

    return calculatePercentages(bucketifyEvents(events));
  }

  exports.default = Ember.Component.extend({
    data: Ember.computed('openingHours', function () {
      var openingHours = this.get('openingHours');
      var events = getEvents(openingHours);
      return events;
    })
  });
});
define('webapp/components/menu-component', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    languageService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    attributeBindings: ['role', 'aria-label'],
    trackingService: Ember.inject.service(),
    userProxy: Ember.inject.service(),

    siteId: Ember.computed.alias('cachedData.cachedSite._id'),

    translatedPages: Ember.computed('pages', 'languageService.selectedLanguage', function () {
      var languageService = this.get('languageService');
      var pages = this.get('pages');

      // TODO whaat? no tree returned
      return (0, _treeUtils.buildTree)(pages.map(function (page) {
        Ember.set(page.page, 'translatedMenuTitle', languageService.getTranslatedMenuTitle(page.page));
        return _extends({}, page, { children: [] });
      }), function (x) {
        return Ember.get(x, 'page._id');
      }, function (x) {
        return Ember.get(x, 'parentPage');
      });
    }),

    click: function click(e) {
      // active links dont trigger a route change, so offcanvas isnt closed, needs to be done manually
      var target = e.target;
      var trackingService = this.get('trackingService');
      trackingService.gaCustomEvent('menu_navigation', target);

      if (target.classList.contains('active')) {
        var offcanvasElements = ['#offcanvas', '#additional-offcanvas'];
        offcanvasElements.forEach(function (element) {
          var offC = window.UIkit.offcanvas(element);
          if (offC) {
            offC.hide();
          }
        });
      }
    }
  });
});
define('webapp/components/shopping/address-tile', ['exports', 'webapp/utils/countries'], function (exports, _countries) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userProxy: Ember.inject.service(),
    countries: Ember.computed(function () {
      return _countries.DEFAULT_COUNTRIES;
    }),

    click: function click() {
      this.get('selectTile')();
    }
  });
});
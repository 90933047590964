define('webapp/services/message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var defaultConf = {
    pos: 'bottom-center',
    timeout: 10000
  };
  var infoConf = Ember.assign({ status: 'success' }, defaultConf);
  var errorConf = Ember.assign({ status: 'warning' }, defaultConf);

  exports.default = Ember.Service.extend({
    infoMessages: [],
    fastboot: Ember.inject.service(),
    i18n: Ember.inject.service(),

    info: function info(msg) {
      if (!this.get('fastboot.isFastBoot')) {
        UIkit.notification(msg, infoConf);
      }
    },
    infoT: function infoT(msg) {
      var i18n = this.get('i18n');
      this.info(i18n.t(msg).toString());
    },


    // send multiple unique messages in the next runloop
    infoUnique: function infoUnique(msg) {
      if (this.infoMessages.indexOf(msg) < 0) {
        this.infoMessages.push(msg);
      }
      Ember.run.scheduleOnce('afterRender', this, this.sendInfoMsgs);
    },
    sendInfoMsgs: function sendInfoMsgs() {
      this.info(this.infoMessages.join('<br>'));
      this.infoMessages = [];
    },
    error: function error(msg) {
      if (!this.get('fastboot.isFastBoot')) {
        UIkit.notification(msg, errorConf);
      }
    },
    errorT: function errorT(msg) {
      var i18n = this.get('i18n');
      this.error(i18n.t(msg).toString());
    }
  });
});
define("webapp/locales/nl/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.entryBannerImageNew": "Specificeer een hoofdafbeelding voor uw invoer.",
    "account.ui.profileBannerImageNew": "Stel een hoofdafbeelding in voor uw profiel.",
    "account.ui.register": "register",
    "account.ui.personate": "Imiteer gebruiker",
    "account.ui.registeredAs": "U bent momenteel geregistreerd als",
    "account.ui.userNameNotEditableTooltip": "Uw gebruikersnaam. U kunt de opgegeven gebruikersnaam in de instellingen wijzigen. Belangrijk: Na de wijziging moet u zich opnieuw aanmelden met de nieuwe gebruikersnaam.",
    "accountTypes.aussteller": "exposant",
    "accountTypes.gastgeber": "gastheren",
    "accountTypes.veranstalter": "organisator",
    "application.beta.infotext": "Deze functie wordt alleen gevisualiseerd en is niet beschikbaar in de bètaversie.",
    "application.breadcrumbsHome": "Thuis",
    "application.month": "per maand",
    "application.newsletter.alreadySubscribed": "U bent al geabonneerd op de {{lijst}} Nieuwsbrieven. U heeft toegang tot de abonnementen op de nieuwsbrief in uw account.",
    "application.newsletter.alreadySubscribedNoList": "U heeft zich al ingeschreven op de nieuwsbrief",
    "application.newsletter.goToList": "Mailinglijst",
    "application.newsletter.subscribeButton": "Vul uw e-mailadres in om u te abonneren op de nieuwsbrief",
    "application.newsletter.subscribeButtonMobile": "E-mail",
    "application.newsletter.noAccess": "U kunt zich niet abonneren op de aangeboden nieuwsbrief met uw toestemming.",
    "application.newsletter.subscribe": "ondertekenen",
    "application.newsletter.subscribeList": "U schrijft zich in voor deze nieuwsbrief:",
    "application.newsletter.subscribeThanks": "Bedankt voor uw abonnement op de nieuwsbrief. Voor activering ontvangt u een bevestigingsmail voor de opt-in.",
    "application.newsletter.subscribeWithAccount": "Schrijf u in met uw huidige ingelogde gebruiker.",
    "application.noCosts": "Gratis",
    "application.placeholderImageText": "Zonder illustratie",
    "application.searchAllGroups": "in totaal",
    "application.searchAllRegions": "op alle plaatsen",
    "application.ui.access": "oproep",
    "application.ui.action": "actie",
    "application.ui.addDocument": "Document toevoegen",
    "application.ui.addRelation": "Verbinding toevoegen",
    "application.ui.addImage": "Afbeelding toevoegen",
    "application.ui.addImages": "Foto's toevoegen",
    "application.ui.addMedia": "Foto's/video's toevoegen",
    "application.ui.addVideo": "Video toevoegen",
    "application.ui.advancedEdit": "Uitgebreid",
    "application.ui.all": "alle",
    "application.ui.alphabetical": "Alfabetisch",
    "application.ui.at": "am",
    "application.ui.back": "achter",
    "application.ui.buy": "aanschaf",
    "application.ui.certificate": "getuigschriften",
    "application.ui.confirmDeleteEntry": "De vermelding {{titel}} onherroepelijk verwijderen?",
    "application.ui.count": "Nummer",
    "application.ui.currentlyActive": "Loopt rechtdoor",
    "application.ui.deleteEntry": "doorhalen",
    "application.ui.discardUnsavedChanges": "Veranderingen weggooien en doorgaan met",
    "application.ui.discardUnsavedChangesMobile": "weggooiend",
    "application.ui.distance": "onthechting",
    "application.ui.distant": "verwijderd",
    "application.ui.dreampoints": "Droompunten",
    "application.ui.dreampointsAllDreams": "Droompunten van uw inzendingen",
    "application.ui.dreampointsDonate": "Droompunten om te doneren",
    "application.ui.dreampointsTooltip": "U kunt Dreampoints verzamelen met uw inzending",
    "application.ui.edit": "wijzigen",
    "application.ui.editDescription": "Beschrijving bewerken",
    "application.ui.editEntry": "werk",
    "application.ui.editEvent": "Evenement bewerken",
    "application.ui.editImage": "Afbeelding bewerken",
    "application.ui.editPoi": "Locatie bewerken",
    "application.ui.editProduct": "Product bewerken",
    "application.ui.editProfile": "Mijn profiel bewerken",
    "application.ui.editShownProfile": "Wijzig mijn profiel advertentie",
    "application.ui.entriesFocus": "Geselecteerde items in Focus:",
    "application.ui.entriesLatest": "Recente inzendingen in Focus:",
    "application.ui.for": "in ruil voor",
    "application.ui.fullView": "algemene visie",
    "application.ui.gallery": "afbeeldingen",
    "application.ui.galleryImages": "naar de galerij...",
    "application.ui.gavePoints": "u {{points}} punten heeft gegeven.",
    "application.ui.goToEntry": "Oproepen",
    "application.ui.goToEvent": "Bel evenement",
    "application.ui.goToPoi": "Belplaats",
    "application.ui.goToProduct": "Bekijk product",
    "application.ui.hasBeen": "was",
    "application.ui.here": "Dichtbij",
    "application.ui.invalidEmbed": "Deze URL kan niet worden ingebed.",
    "application.ui.isActive": "actief",
    "application.ui.isNotActive": "Gedeactiveerd",
    "application.ui.knowMore": "Meer informatie over {{about}}",
    "application.ui.language.de": "Deutsch",
    "application.ui.language.en": "English",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "Italiano",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "Huidige geselecteerde taal",
    "application.ui.language.switchTo": "Taal wijzigen in ",
    "application.ui.mailBodyPre": "Hallo, ik heb iets interessants gevonden:",
    "application.ui.mailSubject": "Ik heb een interessante link gevonden",
    "application.ui.mandatory": "verplicht veld",
    "application.ui.memberSince": "Lid sinds",
    "application.ui.mobileRotate": "Roteer voor volledige schermweergave",
    "application.ui.more": "veeleer",
    "application.ui.name": "Naam",
    "application.ui.navigateOneLevelUp": "Terug naar de bovenliggende map",
    "application.ui.new": "Nieuw",
    "application.ui.newEntry": "Nieuw item aanmaken",
    "application.ui.newEvent": "Creëer een nieuw bedrijfsevenement",
    "application.ui.newPoi": "Maak een nieuwe locatie aan",
    "application.ui.newProduct": "Maak een nieuw product",
    "application.ui.noEntry": "U heeft nog geen invoer gemaakt. Maak nu de eerste vermelding aan",
    "application.ui.noEntryProfile": "heeft nog geen invoer gemaakt.",
    "application.ui.noEvent": "U heeft nog geen bedrijfsevenement aangemaakt. Creëer nu uw eerste evenement",
    "application.ui.noPoi": "Ze hebben nog geen site ingericht. Creëer nu uw eerste locatie",
    "application.ui.noJob": "Je hebt nog geen job aangemaakt. Maak nu je eerste baan aan...",
    "application.ui.noProduct": "U heeft nog geen product gemaakt. Creëer nu uw eerste product",
    "application.ui.notEditable": "kan niet worden gewijzigd",
    "application.ui.nothingFound": "We konden geen resultaten vinden voor deze zoektocht.",
    "application.ui.offer": "offerandes",
    "application.ui.only": "Alleen",
    "application.ui.openDocument": "Bestand openen",
    "application.ui.or": "of",
    "application.ui.playVideo": "Video afspelen",
    "application.ui.readMore": "Lees meer",
    "application.ui.region": "regio",
    "application.ui.relevance": "Relevant",
    "application.ui.reset": "Reset",
    "application.ui.routingDescription": "Hoe ons te vinden",
    "application.ui.saveShownProfile": "Profielwijzigingen opslaan",
    "application.ui.saveUnsavedChanges": "Opslaan",
    "application.ui.showAllEntries": "Toon alle inzendingen",
    "application.ui.showAllSearch": "Toon alle",
    "application.ui.showMoreSearch": "Toon meer zoekresultaten",
    "application.ui.resetSearch": "Reset selectie en zoek opnieuw",
    "application.ui.sort": "sortering",
    "application.ui.stockPhotoLinks": "Koppelingen naar beelddatabases",
    "application.ui.suitableFor": "Aanbevolen voor",
    "application.ui.total": "totaal",
    "application.ui.totalDreampoints": "Droompunten van al uw inzendingen",
    "application.ui.trySearch": "Wilt u andere zoektermen uitproberen, zoals",
    "application.ui.type": "type",
    "application.ui.unsavedChanges": "Niet-opgeslagen wijzigingen",
    "application.ui.updated": "bijgewerkt",
    "application.ui.uploadedFileFor": "Geüpload bestand voor:",
    "application.ui.userName": "inlognaam",
    "application.ui.visibleForAll": "Zichtbaar voor alle gebruikers",
    "application.ui.visibleForFollower": "Zichtbaar voor volgelingen",
    "application.ui.visibleForJoiner": "Zichtbaar voor Schrijnwerker",
    "application.ui.visibleForNone": "Zichtbaar voor niemand",
    "application.ui.visibleForSupporter": "Zichtbaar voor supporters",
    "application.ui.writeUs": "Schrijf ons...",
    "application.ui.years": "jaren",
    "application.ui.yearsFew": "Babyalter",
    "application.widgets.highestRatedEntries": "Meest Droompunten",
    "application.widgets.inFocus": "In Focus",
    "application.widgets.newestEntries": "De nieuwste inzendingen",
    "blog.ui.category": "Categorie",
    "blog.ui.categoryHeading": "Uit de categorie",
    "blog.ui.lastUpdated": "Laatst bijgewerkt op",
    "blog.ui.openSinglePost": "Naar de artikelweergave",
    "blog.ui.publishedIn": "Gepubliceerd in",
    "blog.ui.subCategories": "subcategorieën",
    "blog.ui.written": "Geschreven",
    "blog.ui.writtenBy": "Geschreven door",
    "buttons.alreadyMeToo": "Ik ben blij dat je die inzending ook droomt.",
    "buttons.alreadyMeTooMobile": "Ik ben blij dat je die inzending ook droomt.",
    "buttons.cancel": "afbreken",
    "buttons.createAccount": "Stel nu uw EntryerAccount in",
    "buttons.delete": "doorhalen",
    "buttons.deleteFavorite": "Niet volgen",
    "buttons.deleteLike": "Niet meer leuk vinden",
    "buttons.meToo": "Dat wil ik ook doen.",
    "buttons.newPassword": "Nieuw wachtwoord aanvragen",
    "buttons.ok": "Ok",
    "buttons.ready": "Klaar",
    "buttons.save": "Wijzigingen opslaan",
    "buttons.saveMobile": "Opslaan",
    "buttons.selectThis": "Selecteer deze",
    "buttons.tryAgain": "Probeer het opnieuw",
    "category.ui.hideCategories": "Subcategorieën verbergen",
    "category.ui.oneLevelUp": "Terug naar de categorie superordinaat",
    "category.ui.showCategories": "Subcategorieën tonen",
    "categoryFields.additionalGauge": "",
    "categoryFields.additionalWeight": "",
    "categoryFields.attachmentWidth": "Bevestigingsbreedte",
    "categoryFields.clutch": "Koppeling",
    "categoryFields.companyName": "Bedrijfsnaam",
    "categoryFields.dealerInfo": "Dealerinformatie",
    "categoryFields.diameter": "Banddiameters",
    "categoryFields.displayNetPrice": "Nettoprijs",
    "categoryFields.electricalPower": "Elektrisch vermogen",
    "categoryFields.engine": "Motor",
    "categoryFields.engineDescription": "Motoruitrusting",
    "categoryFields.ERPname": "Naam in ERP",
    "categoryFields.horsePower": "Motorvermogen",
    "categoryFields.netWidth": "Spoorbreedte zonder banden",
    "categoryFields.packingUnit": "Verpakkingseenheid",
    "categoryFields.PTO": "PTO",
    "categoryFields.relativeGauge": "Verbreed het spoor",
    "categoryFields.sectionWidth": "",
    "categoryFields.vendorAcronym": "Verkorte aanduiding",
    "categoryFields.volume": "Volumen",
    "categoryFields.weight": "Gewicht",
    "categoryFields.wheelLock": "Standwielvergrendeling",
    "comments.blockComments": "De opmerkingen voor deze post zijn voltooid.",
    "comments.comment.anonymous": "Anoniem",
    "comments.comment.button": "Schrijf een nieuw commentaar",
    "comments.comment.buttonReply": "replieken",
    "comments.comment.cancel": "afbreken",
    "comments.comment.content": "Uw commentaar",
    "comments.comment.hasCommented": "geannoteerd",
    "comments.comment.login": "Log in om een reactie te plaatsen",
    "comments.comment.past": "heeft",
    "comments.comment.replyFrom": "Antwoord op",
    "comments.comment.showReplies": "Antwoorden tonen",
    "comments.comment.showReply": "Toon antwoord",
    "comments.comment.submit": "verzending",
    "comments.comment.title": "commentaar titel",
    "comments.comment.userName": "Uw gebruikersnaam",
    "comments.heading": "Suggesties, opmerkingen, opmerkingen, commentaar",
    "comments.hierarchy.flat": "Plat",
    "comments.hierarchy.nested": "Genezen",
    "comments.hierarchy.title": "Commentaar bekijken:",
    "comments.message.afterSaveError": "Uw opmerking kan op dit moment niet worden opgeslagen!",
    "comments.message.afterSaveSuccess": "Uw commentaar is opgeslagen.",
    "comments.message.afterSaveUnlock": "Uw opmerking is opgeslagen, maar moet worden beoordeeld door een beheerder.",
    "comments.noComments": "Er zijn nog geen opmerkingen gemaakt.",
    "comments.reply": "AN:",
    "comments.show": "Toon opmerkingen",
    "comments.title": "kanttekeningen",
    "entries.edit": "Invoer <strong>{{titel}}</strong> bewerken",
    "entries.entries": "inzendingen",
    "entries.entry.fields.address.city": "stadje",
    "entries.entry.fields.address.country": "land",
    "entries.entry.fields.address.houseNumber": "huisnummer",
    "entries.entry.fields.address.street": "straat",
    "entries.entry.fields.address.zip": "POSTCODE",
    "entries.entry.fields.prefixMissing": "De volgende velden zijn niet ingevuld:",
    "entries.entry.profile.edit": "Profiel bewerken",
    "entries.entry.profile.publicProfile": "naar mijn openbare profielpagina",
    "entries.entry.tooltip.alreadyCopied": "Je droomt al van deze inzending.",
    "entries.entry.tooltip.alreadyFollow": "U volgt deze vermelding al op de voet",
    "entries.entry.tooltip.alreadyLiked": "U vindt deze vermelding al leuk",
    "entries.entry.tooltip.copied": "Ik ben blij dat je deze inzending ook wilt dromen. Uw profielfoto is toegevoegd aan dit item.",
    "entries.entry.tooltip.filterComments": "Klik hier om alleen commentaar weer te geven in de Entryline",
    "entries.entry.tooltip.filterFollows": "Klik hier om alleen Volgt in de inschrijflijn weer te geven.",
    "entries.entry.tooltip.filterLikes": "Klik hier om alleen Likes in de Entryline weer te geven.",
    "entries.entry.tooltip.filterShares": "Klik hier om alleen de aandelen van de Entryline te bekijken.",
    "entries.entry.tooltip.follow": "Klik hier om deze vermelding te volgen",
    "entries.entry.tooltip.like": "Klik hier om deze inzending een Like te geven.",
    "entries.entry.tooltip.noFollow": "Volg alleen werken voor externe vermeldingen",
    "entries.entry.tooltip.noFollowNotLoggedIn": "U moet ingelogd zijn om dit bericht te kunnen volgen.",
    "entries.entry.tooltip.noLike": "Zoals alleen werken met buitenlandse inzendingen...",
    "entries.entry.ui.access": "Kan alleen deze vermelding zien",
    "entries.entry.ui.added": "...werd toegevoegd.",
    "entries.entry.ui.addEntry": "Invoeringen toevoegen",
    "entries.entry.ui.addOpening": "Openingstijd toevoegen",
    "entries.entry.ui.address": "Mijn adres",
    "entries.entry.ui.addStep": "Een stap toevoegen",
    "entries.entry.ui.agreementHours": "Geopend op afspraak",
    "entries.entry.ui.allAccess": "Iedereen kan deze inzending zien",
    "entries.entry.ui.assignedCategories": "toegewezen categorieën",
    "entries.entry.ui.assignedCertificates": "Mijn certificaten",
    "entries.entry.ui.assignedProducts": "toegewezen producten",
    "entries.entry.ui.businessContact": "Contactgegevens van het bedrijf",
    "entries.entry.ui.categories": "Categorieën waaronder ik gevonden kan worden",
    "entries.entry.ui.categoryFields": "Meer informatie",
    "entries.entry.ui.commercialTitle": "Bedrijfsinformatie over de inschrijving",
    "entries.entry.ui.companyTitle": "Mijn bedrijfsgegevens",
    "entries.entry.ui.contactPerson": "Mijn contactgegevens",
    "entries.entry.ui.cssClass": "CSS-klasse voor de inhoud",
    "entries.entry.ui.deliveryInfo": "Informatie over de bezorgdienst",
    "entries.entry.ui.descriptionInfo": "Tip: Als u woorden selecteert, kunt u stijlen toewijzen.",
    "entries.entry.ui.descriptionPlaceholder": "Beschrijving met HTML. Dubbelklik op tekst voor HTML-functiebalk (tot 600 tekens).",
    "entries.entry.ui.editEntry": "Invoer bewerken",
    "entries.entry.ui.editEntryNew": "Maak invoer aan",
    "entries.entry.ui.exclusionHours": "uitsluitingsperioden",
    "entries.entry.ui.galleryImages": "Fotogalerij (2 of 3 foto's afhankelijk van de opstelling)",
    "entries.entry.ui.globalTitle": "naam",
    "entries.entry.ui.hasKiosk": "Ik heb een verkoopcabine...",
    "entries.entry.ui.imagesHorizontal": "Horizontaal",
    "entries.entry.ui.imagesInfo": "Afbeeldingen kunnen worden bewerkt onder \"Afbeeldingen/Video's/Documenten\".",
    "entries.entry.ui.imagesOrientation": "beelduitlijning",
    "entries.entry.ui.imagesSquared": "Vierkant",
    "entries.entry.ui.imagesVertical": "Verticaal",
    "entries.entry.ui.isListImage": "Dit scherm is het lijstscherm.",
    "entries.entry.ui.isMainImage": "Deze foto is het belangrijkste beeld",
    "entries.entry.ui.isMainImageAndListImage": "Dit scherm verschijnt als het hoofdscherm van de invoer.",
    "entries.entry.ui.kioskDescription": "Beschrijving van uw stand",
    "entries.entry.ui.listImage": "lijstafbeelding",
    "entries.entry.ui.logo": "embleem",
    "entries.entry.ui.longDescription": "Lange beschrijving tekst",
    "entries.entry.ui.mainImage": "hoofdbeeld",
    "entries.entry.ui.makeListImage": "Definieer dit scherm als een lijstscherm",
    "entries.entry.ui.makeListImageTooltip": "De zoekresultaten worden weergegeven als een lijst met het lijstscherm. Als er geen lijstscherm is geselecteerd, wordt het hoofdscherm gebruikt.",
    "entries.entry.ui.makeMainImage": "Definieer dit scherm als het hoofdscherm",
    "entries.entry.ui.makeMainImageTooltip": "Het hoofdscherm wordt boven aan het profiel weergegeven. Afbeeldingen die niet als hoofd- of lijstafbeelding zijn geselecteerd, worden weergegeven in de fotogalerij.",
    "entries.entry.ui.metaDescription": "beknopte beschrijving",
    "entries.entry.ui.metaDescriptionPlaceholder": "Korte beschrijving met een maximum van 200 tekens",
    "entries.entry.ui.multiCode": "Extra code voor deze stap",
    "entries.entry.ui.multiDescription": "Beschrijving van deze stap",
    "entries.entry.ui.multiImages": "Foto's voor deze stap",
    "entries.entry.ui.multiProgress": "vooruitbetaling",
    "entries.entry.ui.multiTitle": "Titel voor deze stap",
    "entries.entry.ui.multiTitlePlaceholder": "Titel voor deze stap (maximaal 50 tekens)",
    "entries.entry.ui.noDescriptions": "De inzending heeft nog geen beschrijvingsteksten.",
    "entries.entry.ui.noEntries": "Geen inzendingen gevonden.",
    "entries.entry.ui.onlyVisibleForCustomers": "Deze vermelding is alleen zichtbaar voor geregistreerde klanten.",
    "entries.entry.ui.openAt": "Open",
    "entries.entry.ui.openingHours": "openingstijden",
    "entries.entry.ui.openingInfo": "Opmerkingen over de openingstijden",
    "entries.entry.ui.openingReligious": "Adres in overwegend katholieke gemeenschap",
    "entries.entry.ui.overallProgress": "Deze invoer wordt gerealiseerd bij {{value}}%.",
    "entries.entry.ui.parking": "parkeerfaciliteiten",
    "entries.entry.ui.parkingLots": "nummer",
    "entries.entry.ui.parkingType": "wijze",
    "entries.entry.ui.personImage": "Foto van de contactpersoon",
    "entries.entry.ui.relatedEvents": "Gerelateerde gebeurtenissen",
    "entries.entry.ui.rentKiosk": "Ik wil graag een stand huren",
    "entries.entry.ui.resolveAddress": "Coördinaten bepalen",
    "entries.entry.ui.resolveAddressInfo": "Bepaal het Geopoint (positie op de kaart) vanaf uw adres. Zonder een correct geopoint zijn ze misschien niet goed te vinden.",
    "entries.entry.ui.resolveGeolocation": "Adres bepalen",
    "entries.entry.ui.routingPlaceholder": "Vul hier uw routebeschrijving met HTML in.",
    "entries.entry.ui.selectEvent": "Selecteer gebeurtenissen",
    "entries.entry.ui.shortDescriptionInfo": "Is tegelijkertijd de korte beschrijving",
    "entries.entry.ui.sharing": "Deel deze pagina op {{sharing}}",
    "entries.entry.ui.shareMail": "Deel deze pagina per e-mail",
    "entries.entry.ui.skillsNeeded": "Voor mijn inzending kan ik echt gebruik maken van....",
    "entries.entry.ui.sortword": "Alternatieve titel voor sorteren",
    "entries.entry.ui.step": "trede",
    "entries.entry.ui.stepInDream": "In welke fase van de inschrijving is deze stap?",
    "entries.entry.ui.stepInDreamAfter": "na realisatie",
    "entries.entry.ui.stepInDreamBefore": "voor de realisatie",
    "entries.entry.ui.stepInDreamDuring": "tijdens de realisatie",
    "entries.entry.ui.subtitle": "Beschrijvende ondertitel",
    "entries.entry.ui.subtitlePlaceholder": "Ondertiteling invoeren (maximaal 60 tekens)",
    "entries.entry.ui.tabCategories": "categorieën",
    "entries.entry.ui.tabCategoriesMobile": "Kat.",
    "entries.entry.ui.tabCompany": "bedrijfsinformatie",
    "entries.entry.ui.tabDescription": "Beschrijving van de",
    "entries.entry.ui.tabDescriptionMobile": "Beperkt",
    "entries.entry.ui.tabExhibitor": "exposantgegevens",
    "entries.entry.ui.tabImages": "Afbeeldingen/video's/Documenten",
    "entries.entry.ui.tabImagesMobile": "Beelden/video's/bestanden",
    "entries.entry.ui.tabRelations": "verbanden",
    "entries.entry.ui.tags": "etiketten",
    "entries.entry.ui.title": "De vermelding verschijnt onder de volgende namen",
    "entries.entry.ui.titleError": "De vermelding moet worden genoemd voordat u de gegevens opslaat.",
    "entries.entry.ui.titlePlaceholder": "Titel invoeren (maximaal 50 tekens)",
    "entries.entry.ui.visible": "Dit item is zichtbaar",
    "entries.entry.ui.promotion": "Deelname aan acties voor deze vermelding",
    "entries.entry.ui.regions": "Regio-categorieën voor deze vermelding",
    "entries.job.ui.promotion": "Deelname aan acties voor deze functie",
    "entries.event.edit": "Gebeurtenis bewerken <strong>{{titel}}</strong>",
    "entries.event.ui.addEvent": "Evenement toevoegen",
    "entries.event.ui.cooperation": "Kooperationspartner",
    "entries.event.ui.directions": "Hoe ons te vinden",
    "entries.event.ui.editEvent": "Evenement bewerken",
    "entries.event.ui.editEventNew": "Creëer een bedrijfsevenement",
    "entries.event.ui.endDate": "Einde evenement (dag)",
    "entries.event.ui.eventDone": "vakbeursrapport",
    "entries.event.ui.noEvents": "Geen evenementen gevonden",
    "entries.event.ui.openEvent": "Bel evenement",
    "entries.event.ui.opening": "openingstijden",
    "entries.event.ui.startDate": "Begin van het evenement (dag)",
    "entries.event.ui.tabCategories": "categorieën",
    "entries.event.ui.tabDescription": "Beschrijving van de",
    "entries.event.ui.tabOpening": "Datum en tijden",
    "entries.event.ui.tabRelations": "verbanden",
    "entries.event.ui.title": "De gebeurtenis verschijnt onder de volgende namen",
    "entries.event.ui.upcoming": "binnenkort",
    "entries.event.ui.moreEvents": "Andere evenementen",
    "entries.open": "Oproepen van een ingang in de Frontend",
    "entries.person.ui.company": "standvastig",
    "entries.person.ui.female": "vrouw",
    "entries.person.ui.fullName": "Voor- en achternaam",
    "entries.person.ui.fullSize": "Volledig formaat",
    "entries.person.ui.gender": "sekse",
    "entries.person.ui.greeting": "groet",
    "entries.person.ui.male": "baas",
    "entries.person.ui.open": "Rechtstreeks naar de ingang van deze persoon",
    "entries.person.ui.personAddress": "Adres van de persoon",
    "entries.person.ui.personContact": "Persoonlijke contactgegevens",
    "entries.person.ui.personImage": "persoonsimago",
    "entries.person.ui.position": "Positie/Type",
    "entries.person.ui.quote": "Citaat/illustratie ondertiteling",
    "entries.person.ui.signature": "typisch beeld",
    "entries.poi.ui.title": "De locatie verschijnt onder de volgende namen",
    "entries.product.edit": "Product bewerken <strong>{{titel}}</strong>",
    "entries.product.fields.engine": "motor",
    "entries.product.fields.horsePower": "vermogen",
    "entries.product.menu.allAttachments": "Overzicht bijlagen",
    "entries.product.menu.allCarriers": "Overzicht dragers",
    "entries.product.menu.attachments": "Bevestigingsapparaat",
    "entries.product.menu.carriers": "Draagvoertuig",
    "entries.product.tags.eco": "eco",
    "entries.product.tags.neu": "Nieuw",
    "product.ui.content": "Inhoud",
    "product.ui.ingredients": "Bestanddelen",
    "product.ui.ingredientsFood": "Ingrediënten",
    "product.ui.nonPhysical": "Elektronisch product downloaden",
    "entries.product.ui.addProduct": "Product toevoegen",
    "entries.product.ui.equipmentVariants": "uitrustingsvarianten",
    "entries.product.ui.variants": "Varianten",
    "entries.product.ui.gauge": "Breedte in cm",
    "entries.product.ui.gaugeMin": "Minimale spoorbreedte in cm",
    "entries.product.ui.inclusive": "Inbegrepen",
    "entries.product.ui.live": "Ervaring live",
    "entries.product.ui.netPrize": "Nettoprijs plus BTW. Zonder speciale uitrusting en accessoires. Af fabriek Entschenreuth, plus kosten voor verpakking, vracht, transportverzekering, montage en inbedrijfstelling.",
    "entries.product.ui.noProducts": "Geen producten gevonden",
    "entries.product.ui.noPurchaseOnThisPage": "Indien het niet mogelijk is om een bestelling te plaatsen op deze pagina, gelieve het product op te roepen.",
    "entries.product.ui.onlyBasicVariant": "Alleen in basisversie",
    "entries.product.ui.onlyWithCarrier": "Alleen beschikbaar bij aankoop van een basiseenheid",
    "entries.product.ui.order": "Toevoegen aan winkelwagen",
    "entries.product.ui.overview": "Overzicht van de",
    "entries.product.ui.prizeFrom": "vanaf ",
    "entries.product.ui.noPrize": "Vraag je contactpersoon naar de prijs.",
    "entries.product.ui.required": "Alleen met",
    "entries.product.ui.suitableFor": "Geschikt voor",
    "entries.product.ui.suitableForAttachments": "Geschikt voor de volgende hulpstukken",
    "entries.product.ui.suitableForCarriers": "Geschikt voor de volgende draagvoertuigen",
    "entries.product.ui.title": "Product",
    "entries.product.ui.variant": "Variatie/Bundel",
    "entries.product.ui.variantNotOrderable": "De geselecteerde variant kan niet worden besteld",
    "entries.product.ui.chooseVariant": "Variant kiezen",
    "entries.product.ui.chooseVariantMobile": "Kies variant",
    "entries.product.ui.bundleparts": "bestaande uit",
    "entries.product.ui.combinationNotAvailable": "Combinatie niet beschikbaar",
    "entries.progress.title": "Hoe kunt u uw inschrijving verbeteren",
    "entries.tabbar.basics": "basisgegevens",
    "entries.tabbar.entry": "invoergegevens",
    "entries.tabbar.gallery": "afbeeldingen",
    "entries.tabbar.layout": "inrichting",
    "entries.tabbar.openingHours": "openingstijden",
    "entries.tabbar.order": "bestelinformatie",
    "entries.tabbar.tooltip.entryNotSavedDetail": "Invoergegevens kunnen pas na het opslaan van de invoer met titel worden bewerkt.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "Lay-outgegevens kunnen pas worden bewerkt nadat de invoer met een titel is opgeslagen.",
    "entries.types.poi": "poi",
    "entries.types.standard": "Standaard",
    "entries.ui.groupDetailName": "Naam voor groepsvermeldingen",
    "entries.ui.groupName": "Naam voor de groepen (regio)",
    "entryTypes.entry": "inzending",
    "entryTypes.event": "Evenement",
    "entryTypes.poi": "plaats",
    "errors.blank": "moet worden ingevuld....",
    "errors.description": "Dit veld",
    "errors.email": "moet een geldig emailadres zijn",
    "errors.empty": "kan niet leeg zijn...",
    "errors.exclusion": "is gereserveerd...",
    "errors.inclusion": "is niet opgenomen in de lijst...",
    "errors.invalid": "is ongeldig...",
    "errors.slug": "Slug mag alleen kleine letters, cijfers en \"-\" bevatten.",
    "forms.ui.deleteFile": "Bestand verwijderen",
    "forms.ui.isSubmitted": "Uw verzoek is ingediend. Wij nemen zo spoedig mogelijk contact met u op.",
    "forms.ui.uploadSuccess": "{{file}} succesvol geüpload",
    "media.documents.search.noResult": "Voer een zoekterm in of selecteer een categorie.",
    "media.documents.search.placeholder": "Zoeken op serienummer of type",
    "media.documents.search.reset": "Reset zoekresultaat",
    "media.ui.copyright": "Auteursrecht",
    "media.ui.cropFormat": "standaardindeling",
    "media.ui.cropImage": "knipsel",
    "media.ui.cropImageDuplicate": "Dupliceren en bijsnijden",
    "media.ui.description": "Beschrijving van de",
    "media.ui.filename": "bestandsnaam",
    "media.ui.imageSaved": "Beeld opgeslagen",
    "media.ui.processingImage": "Het beeld zal worden gesneden, wacht alstublieft.",
    "media.ui.resetCrop": "Reset blanco",
    "media.ui.resetImage": "Terug naar het origineel",
    "media.ui.rotateImage": "Afbeelding 90° met de klok mee draaien",
    "media.ui.title": "benaming",
    "openingHours.additionalInfo": "Informatie over openingstijden",
    "openingHours.addOpening": "Openingstijd toevoegen",
    "openingHours.date.day.di": "Di",
    "openingHours.date.day.do": "Do",
    "openingHours.date.day.fr": "Fr",
    "openingHours.date.day.mi": "Mi",
    "openingHours.date.day.mo": "Mo",
    "openingHours.date.day.sa": "Sa",
    "openingHours.date.day.so": "So",
    "openingHours.dateS": "afspraak",
    "openingHours.delete": "Verwijderen",
    "openingHours.from": "door",
    "openingHours.options": "aanbod",
    "openingHours.time": "keer",
    "openingHours.to": "tegen de tijd dat",
    "openingHours.type": "Soort openingstijden",
    "opt-in.error-can-not-update-member": "De wijzigingen in de ledengegevens voor {{email}} konden niet worden aangebracht.",
    "opt-in.error-list-does-not-exist": "Er is geen lijst gevonden voor de gespecificeerde gegevens.",
    "opt-in.error-list-does-not-need-validation": "De gespecificeerde lijst heeft geen opt-int.",
    "opt-in.error-member-not-in-list": "Geen enkel lid van een lijst werd gevonden voor de gegeven gegevens.",
    "opt-in.success-member-verified": "De {{email}} is toegevoegd aan de {{lastName}} lijst.",
    "opt-out.error-can-not-update-member": "De wijzigingen in de ledengegevens voor {{email}} konden niet worden aangebracht.",
    "opt-out.error-list-does-not-exist": "Er is geen lijst gevonden voor de gespecificeerde gegevens.",
    "opt-out.error-list-does-not-have-opt-out": "De gespecificeerde lijst heeft geen opt-out.",
    "opt-out.error-member-not-in-list": "Geen enkel lid van een lijst werd gevonden voor de gegeven gegevens.",
    "opt-out.success-membership-terminated": "De {{email}} is verwijderd uit de {{lastName}}.",
    "password.errors.not-secure-enough": "Wij raden een wachtwoord aan met minstens 8 tekens, hoofd- en kleine letters, cijfers en speciale tekens ($@$!%*#? &amp;).",
    "password.errors.password-and-conf-do-not-match": "De twee wachtwoorden moeten identiek zijn.",
    "password.quality.0": "Zeer slecht",
    "password.quality.1": "Slecht",
    "password.quality.2": "Voldoende",
    "password.quality.3": "fijn",
    "password.quality.4": "Zeer goed",
    "profile.entries.copycats": "Inzendingen {{userName}} wil ook een",
    "profile.entries.donates": "Invoer ondersteund door {{userName}}",
    "profile.entries.entries": "Inzendingen van {{userName}}",
    "profile.entries.follows": "Inzendingen gevolgd door {{userName}}",
    "profile.entries.joins": "Inzendingen waaraan {{userName}} deelneemt",
    "profile.marketplace.fields.energyRequired": "elektriciteitsbehoeften",
    "profile.marketplace.fields.livingAnimals": "Vervoer van levende dieren",
    "profile.marketplace.fields.loadingUnloading": "Verplicht bij aankomst/vertrek",
    "profile.marketplace.fields.maxRange": "Voor mijn aanbod ben ik al zo veel kilometers te gaan rijden",
    "profile.marketplace.fields.placeRequired": "Vereisten voor de parkeerplaats",
    "profile.marketplace.fields.securityRequired": "Bedarf een beveiliging",
    "profile.marketplace.fields.shows": "scala aan programma's",
    "profile.marketplace.fields.spaceRequired": "Benodigde ruimte in m²",
    "profile.marketplace.fields.waterRequired": "Waterbehoefte in m³",
    "profile.marketplace.new": "Nieuw aanbod of nieuwe vraag",
    "profile.marketplace.offer.delete": "Verwijderen",
    "profile.marketplace.offer.description": "Beschrijving van de",
    "profile.marketplace.offer.descriptionPlaceholder": "Betekenisvolle beschrijving, zodat een andere gebruiker er iets mee kan doen.",
    "profile.marketplace.offer.new": "Maak een nieuw aanbod",
    "profile.marketplace.offer.relatedEntry": "Toegewezen item",
    "profile.marketplace.offer.tags": "Tags voor het aanbod/verzoek (zal beter worden gevonden met het)",
    "profile.marketplace.offer.title": "Ik bied",
    "profile.marketplace.request.delete": "Aanvraag verwijderen",
    "profile.marketplace.request.new": "Maak een nieuwe aanvraag",
    "profile.marketplace.request.title": "Ik heb het nodig",
    "profile.messages.approve": "Bevestigen",
    "profile.messages.as": "terwijl",
    "profile.messages.commentPre": "heeft",
    "profile.messages.commentSuff": "geannoteerd",
    "profile.messages.decided": "besloten om dit te doen",
    "profile.messages.deletedUser": "Verwijderde gebruiker",
    "profile.messages.donateDream": "Hartelijk dank voor het ondersteunen van deze deelnemer met woorden en daden om zijn inzending te realiseren. Hij zal uw bericht onmiddellijk ontvangen en zal hopelijk contact met u opnemen en uw hulp accepteren.",
    "profile.messages.dreamer": "enige eigenaar",
    "profile.messages.dreamToo": "om ook te dromen",
    "profile.messages.enter": "toegetreden",
    "profile.messages.favoriteDream": "Je volgt deze ingang nu.",
    "profile.messages.follow": "volgt",
    "profile.messages.has": "is",
    "profile.messages.is": "is",
    "profile.messages.join": "verenigd",
    "profile.messages.joinDream": "Je wilt deze inzending mee dromen.",
    "profile.messages.likeDream": "Je vindt deze inzending nu leuk.",
    "profile.messages.sendText": "Uw bericht is verzonden.",
    "profile.messages.shareDream": "U heeft dit bericht met succes gedeeld.",
    "profile.messages.supporter": "geldschieters",
    "profile.messages.want": "willen",
    "profile.messages.youAre": "U bent",
    "profile.messages.youWant": "U wilt",
    "profile.newsletter.notSubscribed": "ondertekenen",
    "profile.newsletter.privacyInformation": "Door op \"inschrijven\" te klikken gaat u ermee akkoord dat wij u de toekomstige nieuwsbrieven van dit abonnement (opt-in) toesturen. U kunt zich op elk moment afmelden voor de nieuwsbrief door opnieuw op \"inschrijven\" te klikken (opt-out).",
    "profile.newsletter.subscribed": "abonniert",
    "profile.press.articleDetail": "item detail",
    "profile.press.downloadImage": "Download afbeelding",
    "profile.press.downloadImages": "Afbeeldingen downloaden",
    "profile.press.downloadText": "Tekst exportiers",
    "profile.press.filterAuthor": "Filter op auteur",
    "profile.press.hide": "maskeren",
    "profile.press.show": "vervagen",
    "profile.progress.title": "Hoe uw profiel te verbeteren",
    "register.ui.addressData": "adresgegevens",
    "register.ui.addressInfo": "U hoeft uw persoonlijke adres niet in te vullen. Dit leidt echter tot correcte route- en afstandsberekeningen.",
    "register.ui.companyData": "uw bedrijfsgegevens",
    "register.ui.companyDataMandatory": "U moet uw bedrijfsnaam invoeren.",
    "register.ui.emptyFirstName": "U moet uw voornaam invoeren",
    "register.ui.emptyLastName": "U moet uw achternaam invoeren.",
    "register.ui.emptyMail": "U moet een geldig e-mailadres invoeren.",
    "register.ui.emptyUserName": "U moet een gebruikersnaam opgeven",
    "register.ui.language": "Taal",
    "register.ui.loginData": "referenties",
    "register.ui.password": "Wachtwoord",
    "register.ui.passwordRepeat": "Herhaal wachtwoord",
    "register.ui.personalData": "Uw persoonlijke gegevens",
    "register.ui.takenEmail": "Deze e-mail is al door een gebruiker ingevoerd.",
    "register.ui.takenUserName": "Deze gebruikersnaam is al toegewezen",
    "register.ui.timezone": "tijdzone",
    "register.ui.typeMinimum": "U moet ten minste één type selecteren",
    "register.ui.usernameInfo": "Belangrijk: Dit is uw gebruikersnaam voor het inloggen.",
    "search.allDistances": "Alle afstanden",
    "search.andFilter": "en filterinstellingen",
    "search.closeTags": "Verberg tags",
    "search.for": "in de nasleep van",
    "search.hits": "slag",
    "search.openTags": "Tags tonen",
    "search.removeDatefilter": "Verwijder de datumfilter",
    "search.resulted": "geresulteerd in",
    "search.resultType.category": "Rubriek",
    "search.resultType.entry": "Dealer",
    "search.resultType.event": "Evenement",
    "search.resultType.group": "Groep",
    "search.resultType.page": "Zijde",
    "search.resultType.person": "Persoon",
    "search.resultType.post": "Post",
    "search.resultType.product": "Product",
    "search.search": "Zoeken",
    "search.searchBarPlaceholder": "doorzoekbaar",
    "search.searchBarPlaceholderEntries": "Dealers zoeken. ",
    "search.searchBarPlaceholderEntriesPosition": "Positie voor het zoeken naar straal opgeven.",
    "search.searchBarPlaceholderGeoSearch": "Vul uw adres in....",
    "search.searchBarPlaceholderMobile": "doorzoekbaar",
    "search.searchBarPlaceholderProducts": "Producten zoeken",
    "search.searchBarPositionTooltip": "Positie bepalen of locatie met adres invoeren",
    "search.showAll": "Toon alle",
    "social.comment": "commentaar",
    "social.Comment": "commentaar",
    "social.CopycatJoiner": "Droom uw inzending",
    "social.dream.donateAmount": "Ondersteuning met een som geld",
    "social.dream.donateDreampoints": "Ondersteuning met Dreampoints",
    "social.dream.donateInfo": "Het bericht is privé en kan alleen door de eigenaar van de invoer worden gelezen.",
    "social.dream.donateTip": "Ondersteuning met advies en actie",
    "social.dream.done": "GEDAAN",
    "social.dream.edit": "Invoer bewerken",
    "social.dream.findDreams": "Vind hier kleine en grote inzendingen en maak uw inzending waarheidsgetrouw en/of help anderen om hun inzendingen te realiseren.",
    "social.dream.followedDreams": "Inzendingen die u volgt",
    "social.dream.giveEmail": "Mijn e-mail adres delen",
    "social.dream.givePhone": "Geef mijn telefoonnummer",
    "social.dream.joinerApproved": "Je bent betrokken bij deze inzending.",
    "social.dream.joinerButton": "Doe mee met deze inzending",
    "social.dream.joinerButtonMobile": "Deelnemen",
    "social.dream.joinerDream": "groepsdroom",
    "social.dream.joinerInfo": "Als de eigenaar van de inschrijving uw deelname bevestigt, wordt deze inschrijving een groepsdroom, waarbij u meer rechten heeft.",
    "social.dream.joinerMessage": "Voer hier uw bericht voor de invoereigenaar in:",
    "social.dream.joinerPending": "De eigenaar van de inzending moet nog steeds uw deelname bevestigen.",
    "social.dream.joinerSupport": "U ondersteunt dit bericht",
    "social.dream.joinerSupportButton": "Steun dit bericht",
    "social.dream.joinerSupportButtonMobile": "ondersteuning",
    "social.dream.joinerSupportInfo": "We werken voortdurend aan verbeteringen, bijvoorbeeld met geld of Entrypoints ter ondersteuning. Blijf op de hoogte.",
    "social.dream.joinerSupportPending": "De eigenaar van de inzending moet uw steun alsnog toestaan",
    "social.dream.member": "lid",
    "social.dream.members": "leden",
    "social.dream.meToo": "Ik wil dat ook doen.",
    "social.dream.meTooInfo": "Laat de eigenaar van de inzending zien dat u de inzending geweldig vindt en dat u ook deze inzending wilt maken.",
    "social.dream.noFollow": "Je volgt geen ingang!",
    "social.dream.noJoiner": "U bent nog geen lid van een groepsdroom.",
    "social.dream.noLikes": "Je houdt nog niet van een vermelding!",
    "social.dream.noSupport": "U ondersteunt geen toegang tot een vermelding",
    "social.dream.registerFirstText": "Om een invoer te kunnen wissen, de invoer te ondersteunen of aan te geven dat u deze wilt uitvoeren, moet u ingelogd zijn. Registreren voor delen is snel en gratis. Klik gewoon op de \"Registreer\" knop.",
    "social.dream.registerFirstTitle": "Droom verder, steun, ook mee?",
    "social.dream.settings": "invoerinstellingen",
    "social.dream.shareTitle": "Deel de droom",
    "social.dream.similar": "Gerelateerde inzendingen",
    "social.dreamline.addImageToDream": "Voeg deze afbeelding toe aan uw inzending",
    "social.dreamline.addMedia": "Afbeelding/video toevoegen",
    "social.dreamline.allActivities": "Alle Dreamline activiteiten",
    "social.dreamline.anonymous": "Anoniem",
    "social.dreamline.commentButton": "commentaar",
    "social.dreamline.comments": "schrijft:",
    "social.dreamline.depublished": "Deze opmerking werd verwijderd vanwege ongepast gedrag.",
    "social.dreamline.dreampointsInfo": "Dit zijn de Dreampoints van de inzending. Meer informatie over Dreampoints vindt u in uw profiel.",
    "social.dreamline.fewDreampoints": "heeft maar een paar Dreampoints. Commentaar, zoals of deel de inzending.",
    "social.dreamline.filterBySteps": "Nach Tredenfiltern",
    "social.dreamline.filterComment": "kanttekeningen",
    "social.dreamline.filteredBy": "Activiteiten gefilterd door",
    "social.dreamline.filteredBySteps": "Gefilterd door stappen",
    "social.dreamline.filterFavorite": "Volgt",
    "social.dreamline.filterLike": "Houdt van",
    "social.dreamline.filterShare": "Aandelen",
    "social.dreamline.filterStep": "etappes",
    "social.dreamline.flagged": "Dit bericht is gerapporteerd",
    "social.dreamline.isComment": "geannoteerd",
    "social.dreamline.isCopycatJoiner": "ook dromen",
    "social.dreamline.isFirstUpdate": "gecreëerd",
    "social.dreamline.isShare": "gescheiden",
    "social.dreamline.isStep": "een nieuwe stap gezet",
    "social.dreamline.isUpdate": "bijgewerkt",
    "social.dreamline.lastEntry": "Laatste update",
    "social.dreamline.locked": "Deze inhoud is alleen zichtbaar voor andere dromers.",
    "social.dreamline.lockedCTA": "Word nu lid van deze inzending",
    "social.dreamline.noMessages": "Deze inzending heeft nog geen activiteit. Schrijf nu je eerste commentaar.",
    "social.dreamline.noMessagesFiltered": "Geen activiteit met deze filterinstellingen voor dit item.",
    "social.dreamline.nowFollower": "volgt deze ingang!",
    "social.dreamline.nowJoiner": "is nu een aanhanger...",
    "social.dreamline.nowLikes": "vond deze inzending nu leuk!",
    "social.dreamline.nowSharing": "deelde deze inzending!",
    "social.dreamline.onlyJoinerVisible": "Alleen zichtbaar voor mededromers/dromers / supporters",
    "social.dreamline.prefixComment": "heeft deze inzending al eerder gedaan",
    "social.dreamline.prefixCopycatJoiner": "wenst deze vermelding te ontvangen aangezien",
    "social.dreamline.prefixFavorite": "volgt deze vermelding aangezien",
    "social.dreamline.prefixJoiner": "ondersteunt deze vermelding aangezien",
    "social.dreamline.prefixLike": "houdt van deze inzending omdat",
    "social.dreamline.prefixShare": "heeft deze inzending al eerder gedaan",
    "social.dreamline.prefixStep": "heeft gepland",
    "social.dreamline.prefixUpdate": "heeft deze inzending al eerder gedaan",
    "social.dreamline.profile": "Profiel",
    "social.dreamline.provideImage": "Ik stel deze foto beschikbaar aan de eigenaar van de inzending",
    "social.dreamline.resetFilter": "Filter resetten",
    "social.dreamline.sendComment": "Verzenden",
    "social.dreamline.sharePart": "uitwisseling",
    "social.dreamline.toggleStream": "kanttekeningen",
    "social.dreamline.toggleStreamSingle": "commentaar",
    "social.dreamline.writeComment": "Schrijf een opmerking...",
    "social.Favorite": "Volg",
    "social.follow": "Volg",
    "social.Follow": "Volg",
    "social.hasLiked": "Je vindt deze inzending al leuk.",
    "social.join": "verbinding",
    "social.Join": "verbinding",
    "social.Joiner": "dromend",
    "social.like": "Zoals",
    "social.Like": "Zoals",
    "social.report.headlinePrefix": "U bent in",
    "social.report.headlineSuffix": "om te rapporteren.",
    "social.report.login": "U moet ingelogd zijn om het bericht te melden.",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "Andere aanstootgevende inhoud",
    "social.report.reportScam": "Verkeerde voorstelling, pesten.",
    "social.report.reportSex": "Seksuele inhoud",
    "social.report.reportSpam": "Dit is spam",
    "social.report.reportViolence": "weergave van geweld",
    "social.report.send": "Stuur een bericht",
    "social.report.title": "rapportage",
    "social.share": "Aandeel",
    "social.Share": "Aandeel",
    "syd.ui.dreamerCommercial": "B2B-standaard",
    "syd.ui.userCommercial": "B2B Dromer",
    "user.company.additionalWebsite": "Verdere website van het bedrijf (alleen domein zonder https://)",
    "user.company.companyName": "bedrijfsnaam",
    "user.company.copyContact": "Kopieer de contactgegevens van de bedrijfsgegevens voor de invoer.",
    "user.company.email": "E-mail om contact met ons op te nemen",
    "user.company.facebook": "Facebook-account van het bedrijf (alleen accountnaam)",
    "user.company.fax": "Telefaxnummer voor schriftelijk contact",
    "user.company.google": "Google+ account van het bedrijf (alleen accountnaam)",
    "user.company.instagram": "Bedrijf Instagram account (alleen accountnaam)",
    "user.company.linkedin": "LinkedIn account van het bedrijf (alleen accountnaam)",
    "user.company.newCustomerAccount": "Een zakelijk account aanmaken",
    "user.company.noCustomerValidation": "Uw bedrijfsaccount is nog niet geactiveerd.",
    "user.company.noCustomerYet": "Uw registratie is nog niet toegewezen aan een zakelijke account.",
    "user.company.phone": "Telefoonnummer om contact met ons op te nemen",
    "user.company.pinterest": "Renterekening van het bedrijf (alleen de naam van de rekening)",
    "user.company.remarks": "aantekeningen",
    "user.company.twitter": "Twitter account van het bedrijf (alleen accountnaam zonder @)",
    "user.company.website": "Website van het bedrijf (alleen domein zonder https://)",
    "user.company.xing": "Xing rekening van de onderneming (alleen de naam van de rekening)",
    "user.company.youtube": "Bedrijf Youtube account (alleen accountnaam)",
    "user.message.after-save-error": "Uw wijzigingen konden niet worden opgeslagen.",
    "user.message.after-save-success": "Uw wijzigingen zijn opgeslagen.",
    "user.message.can-not-login": "Met deze gebruikers- en wachtwoordaanmeldingen kunnen wij u niet aanmelden! Is de gebruikersnaam correct gespeld? Het wachtwoord correct ingevoerd? Misschien ging er iets mis met de registratie.",
    "user.message.can-not-logout": "We konden je niet afmelden!",
    "user.message.conflict": "Deze gebruikersnaam bestaat al, kies een andere!",
    "user.message.fileExistsError": "Upload mislukt. Bestand bestaat al",
    "user.message.fileSizeError": "Upload mislukt. Bestand is te groot",
    "user.message.loginFailureBottom": "Misschien heb je nog geen Entryer account?",
    "user.message.loginFailureTop": "Is uw gebruikersnaam correct gespeld? Heeft u uw wachtwoord correct ingevoerd?",
    "user.message.registration-error": "Er is een fout opgetreden bij de registratie!",
    "user.message.registration-success": "U zou binnenkort een bevestigingsmail van ons moeten ontvangen.",
    "user.message.unknownError": "Onbekende fout bij het uploaden van het bestand",
    "user.message.upload-success": "Upload succesvol",
    "user.message.verify-error": "Uw e-mailadres kon niet worden geverifieerd. Neem contact op met de beheerder.",
    "user.message.verify-success": "Uw e-mailadres is bevestigd. U kunt zich nu registreren:",
    "user.ui.accountGreeting": "Hallo,",
    "user.ui.accountMenuActivities": "Droompunten",
    "user.ui.accountMenuCompanySettings": "bedrijfsgegevens",
    "user.ui.accountMenuEntries": "Mijn inzendingen",
    "user.ui.accountMenuEntryPre": "Inzendingen",
    "user.ui.accountMenuEvents": "Mijn evenementen",
    "user.ui.accountMenuFavorites": "Mijn favorieten",
    "user.ui.accountMenuFollows": "Inzendingen die ik volg",
    "user.ui.accountMenuImages": "Afbeeldingen/video's/Documenten",
    "user.ui.accountMenuJoin": "Mijn groepsdromen",
    "user.ui.accountMenuLikes": "Inzendingen die ik leuk vind",
    "user.ui.accountMenuMessages": "nieuws",
    "user.ui.accountMenuNewsletter": "Abonnementen op mijn nieuwsbrief",
    "user.ui.accountMenuPoi": "Mijn plaatsen",
    "user.ui.accountMenuPress": "Mijn artikelen",
    "user.ui.accountMenuProducts": "Mijn producten",
    "user.ui.accountMenuProfile": "Profiel",
    "user.ui.accountMenuRatings": "beoordelingen",
    "user.ui.accountMenuRegistrationData": "referenties",
    "user.ui.accountMenuReviews": "herzieningen",
    "user.ui.accountMenuSettings": "omgevingen",
    "user.ui.accountMenuSkillsOffer": "Ik bied...",
    "user.ui.accountMenuSkillsRequest": "Ik moet...",
    "user.ui.accountMenuSkillsSearch": "Aanvragen en aanbiedingen",
    "user.ui.accountMenuSubscriptions": "Abonnementen",
    "user.ui.accountMenuSubscritions": "Mijn abonnement",
    "user.ui.accountMenuSupport": "Ondersteunde inzendingen",
    "user.ui.accountMenuSupports": "Inzendingen die ik steun",
    "user.ui.accountNoMessages": "U heeft nog geen berichten ontvangen.",
    "user.ui.accountProgressbar": "Uw profiel is te {{value}}% compleet.",
    "user.ui.accountProgressbarEntry": "Uw invoer is te {{value}}% compleet.",
    "user.ui.accountSettings": "Overige instellingen",
    "user.ui.accountCookieSettings": "Klik hier om uw instellingen aan te passen.",
    "user.ui.accountCookieEssential": "Essentieel",
    "user.ui.accountCookieAnalysis": "Analyse",
    "user.ui.accountCookieSocial": "Sociale media",
    "user.ui.accountCookieReviews": "Recensies",
    "user.ui.accountToolbarLogin": "Aanmelden",
    "user.ui.accountToolbarLoginTooltip": "Registreer je gratis en maak je eigen entree aan",
    "user.ui.accountToolbarLogout": "Uitloggen",
    "user.ui.adminPreview": "Voorbeeld",
    "user.ui.backToLogin": "Terug naar de inlogpagina",
    "user.ui.birthday": "Verjaardag",
    "user.ui.cannotLogin": "U kunt niet inloggen? Klik hier om uw wachtwoord opnieuw in te stellen.",
    "user.ui.changePassword": "Ik wil mijn wachtwoord wijzigen",
    "user.ui.changeUserGroup": "Ander abonnement",
    "user.ui.city": "ligging",
    "user.ui.companyName": "bedrijfsnaam",
    "user.ui.companyNameTooltip": "Belangrijk: Uw vermelding zal dan ook onder deze bedrijfsnamen verschijnen.",
    "user.ui.country": "land",
    "user.ui.createNewAccount": "Maak een nieuw gebruikersaccount aan",
    "user.ui.dataDelete": "wordt gebruikt voor verdere invoer. Wilt u het bestand permanent verwijderen? Waar dit bestand wordt gebruikt, wordt het vervangen door een plaatsbewaarder.",
    "user.ui.dataUsage": "Het bestand wordt opgeslagen in",
    "user.ui.dataUsageNone": "Het bestand wordt nog niet gebruikt. Wilt u het bestand permanent verwijderen?",
    "user.ui.deleteImage": "Afbeelding verwijderen",
    "user.ui.description": "Vul hier een algemene beschrijving van de persoon of het bedrijf in",
    "user.ui.documents": "Documenten",
    "user.ui.documentSearch": "Documentonderzoek",
    "user.ui.download": "Download {{title}}",
    "user.ui.email": "e-mailadres",
    "user.ui.fillOutForm": "Om te registreren, moeten alle verplichte velden volledig worden ingevuld.",
    "user.ui.firstName": "voornaam",
    "user.ui.academicTitle": "Academische titel",
    "user.ui.gender": "M/W",
    "user.ui.genderFemale": "vrouwelijk",
    "user.ui.genderMale": "mannelijk",
    "user.ui.genderThird": "niet gespecificeerd",
    "user.ui.housenumber": "Nee.",
    "user.ui.iAmCustomer": "Ik wil graag een SHARE YOUR DREAM B2B Account aanmaken.",
    "user.ui.internalInfo": "We hebben nieuws voor u. Klik hier.",
    "user.ui.lastLogin": "Laatste login",
    "user.ui.lastName": "Achternaam",
    "user.ui.loginButton": "Aanmelden",
    "user.ui.loginTitle": "gebruikerslogin",
    "user.ui.languageAvailable": "Beschikbaar in uw taal",
    "user.ui.languageMissing": "Alleen beschikbaar in",
    "user.ui.memberSince": "Lid sinds",
    "user.ui.mobileNumber": "mobiel nummer",
    "user.ui.myAddedStorage": "Foto's beschikbaar gesteld",
    "user.ui.myStorage": "Mijn directory",
    "user.ui.noRegisterMessage": "De automatische gebruikersregistratie is niet actief. Voor een gebruikersaccount kunt u contact opnemen met de opgegeven contactadressen.",
    "user.ui.oldPassword": "Vorige wachtwoord",
    "user.ui.password": "Wachtwoord",
    "user.ui.pleaseSelect": "sekse",
    "user.ui.registerButton": "Maak nu een account aan",
    "user.ui.registerMessage": "Schrijf je nu in en ga meteen aan de slag. Het is snel en gemakkelijk.",
    "user.ui.registerTitle": "Nieuw bij ons?",
    "user.ui.requestPasswordReset": "Nieuw wachtwoord aanvragen en oud wachtwoord verwijderen",
    "user.ui.resetPassword": "Wachtwoord opnieuw instellen",
    "user.ui.resetPasswordPage": "Reset uw wachtwoord",
    "user.ui.resetPasswordSuccess": "Uw wachtwoord is gereset, u kunt nu inloggen.",
    "user.ui.selectCustomerType": "Ze zijn.... selecteer",
    "user.ui.stayLoggedin": "Blijf ingelogd",
    "user.ui.stayLoggedinInfo": "Als dit een publiek toegankelijk apparaat is, deactiveert u deze optie en logt u aan het einde weer uit.",
    "user.ui.street": "straat",
    "user.ui.uploadDrop": "Om te uploaden, sleept u hier een bestand naartoe of",
    "user.ui.uploadSelectFile": "een bestand selecteren",
    "user.ui.uploadToMyStorage": "Nieuwe foto's uploaden naar mijn directory",
    "user.ui.uploadUserImage": "Selecteer het gebruikersscherm",
    "user.ui.username": "Gebruikersnaam (e-mailadres)",
    "user.ui.userName": "inlognaam",
    "user.ui.visibilityInfo": "Tip: U bepaalt zelf wat er openbaar wordt weergegeven vanuit uw profiel! Klik op het oogpictogram om de zichtbaarheid van uw invoer aan te passen:",
    "user.ui.zip": "postcode",
    "user.user": "enige eigenaar",
    "user.users": "dragers",

    // auto translated new
    "account.ui.additionalDeliveryAddresses": "U heeft verschillende afleveradressen opgegeven.",
    "account.ui.editDeliveryAddresses": "Wijzig afleveradres",
    "account.ui.editInvoiceAddress": "Wijzig adres bedrijf",
    "account.ui.noDeliveryAddresses": "U heeft nog geen ander afleveradres opgegeven.",
    "account.ui.addDeliveryAddresses": "Voeg een {{further}} Een ander afleveradres toevoegen",
    "account.ui.addDeliveryAddressTitle": "Afleveradres toevoegen",
    "account.ui.editDeliveryAddressTitle": "Wijzig afleveradres",
    "account.ui.isDefaultAddress": "Dit is het standaard afleveradres",
    "account.ui.saveToApplyChanges": "U moet de wijziging opslaan, daarna kunt u bijvoorbeeld andere afleveradressen opgeven.",
    "application.ui.and": "en",
    "application.ui.in": "op",
    "application.ui.atHour": "op",
    "application.ui.hour": "Kijk op",
    "application.ui.contact": "Neem contact op met",
    "application.ui.email": "E-mail",
    "application.ui.goToMeasuring": "Meetpunten",
    "application.ui.imprintLink": "Verantwoordelijk en wettelijk verantwoordelijk voor de inhoud van deze subpagina {{entry}} - Afdruk",
    "application.ui.select": "Gelieve te kiezen",
    "application.ui.shop": "Winkelen",
    "buttons.confirm": "Bevestig",
    "checkout.cart.title": "Mijn winkelwagen",
    "checkout.cart.item": "Artikel",
    "checkout.cart.amount": "Nummer",
    "checkout.cart.edit": "Verander",
    "checkout.cart.price": "Prijs",
    "checkout.cart.singlePrice": "Prijs per eenheid",
    "checkout.cart.product.isNonPhysical": "Product wordt niet verzonden (kan beschikbaar zijn voor download)",
    "checkout.cart.product.requiresExpressDelivery": "Vers product. Moet EXPRESS verzonden worden.",
    "checkout.cart.product.deliveryProblem": "Het geselecteerde product kan vertraging oplopen bij de levering.",
    "checkout.cart.subtotal": "Subtotaal",
    "checkout.cart.deliveryCosts": "Verzendingskosten",
    "checkout.cart.total": "Totaal",
    "checkout.cart.help": "Heeft u vragen over het bestelproces? Wij hebben belangrijke informatie voor u samengesteld.",
    "checkout.cart.subtotalInfo": "incl. BTW",
    "checkout.cart.subtotalShipment": "plus afwijkende verzendkosten",
    "checkout.cart.checkout": "Naar de kassa",
    "checkout.cart.checkoutWithoutUser": "Afrekenen zonder klantenrekening",
    "checkout.cart.checkoutFast": "Snel afrekenen",
    "checkout.cart.deliveryInfo": "Geschatte leveringsdatum voor de artikelen in de winkelwagen",
    "checkout.cart.orderSuccess": "Uw bestelling is geslaagd.",
    "checkout.cart.orderCommitting": "Uw bestelling wordt verwerkt - gelieve te wachten",
    "checkout.cart.myOrder": "Mijn bestelling",
    "checkout.checkout.orderNumber": "Bestelnummer",
    "checkout.checkout.account": "Klantenrekening",
    "checkout.checkout.andOrder": "en orde",
    "checkout.checkout.delivery": "Levering en leveringsadres",
    "checkout.checkout.delivery.type": "De verzending vindt plaats met",
    "checkout.checkout.delivery.street": "Adresregel 1",
    "checkout.checkout.delivery.houseNumber": "Nee.",
    "checkout.checkout.delivery.co": "Achtervoegsel van het adres (c/o)",
    "checkout.checkout.delivery.city": "Stad",
    "checkout.checkout.delivery.selectCountry": "Selecteer een land",
    "checkout.checkout.delivery.addressBoth": "Factuur en leveringsadres",
    "checkout.checkout.delivery.addressInvoice": "Factuuradres",
    "checkout.checkout.delivery.addressDelivery": "Afleveradres",
    "checkout.checkout.delivery.default": "Stel dit adres in als mijn standaard afleveradres",
    "checkout.checkout.delivery.otherAddress": "Het leveringsadres verschilt van het facturatieadres",
    "checkout.checkout.delivery.dhlNr": "Uw DHL-postnummer",
    "checkout.checkout.delivery.packingStation": "Nummer pakstation",
    "checkout.checkout.delivery.showPackingStations": "Toon pakstations in de buurt van mijn adres",
    "checkout.checkout.delivery.wantExpressShipping": "Express levering",
    "checkout.checkout.delivery.isPickUp": "inzameling ter plaatse",
    "checkout.checkout.delivery.specialCountryInfo": "Uw land kan niet gekozen worden?",
    "checkout.checkout.delivery.specialCountry": "Uw land valt buiten onze standaard verzending",
    "checkout.checkout.delivery.specialCountryMessage": "Afzonderlijke producten in uw winkelwagen kunnen niet naar alle landen worden verzonden. Verwijder enkele producten uit uw bestelling totdat uw land kan worden geselecteerd of neem contact met ons op.",
    "checkout.checkout.payment": "Betaalmethode",
    "checkout.checkout.paymentProblem": "Er is een fout opgetreden bij het uitvoeren van de betaling",
    "checkout.checkout.backToPayment": "Terug naar de betalingsinstellingen.",
    "checkout.checkout.paymentTitle": "Hoe bent u van plan te betalen?",
    "checkout.checkout.paymentSelected": "Geselecteerde betalingsmethode",
    "checkout.checkout.payment.cc": "Kredietkaart",
    "checkout.checkout.payment.ccInfo": "Voor kaartbetalingen binnen de EU moet uw identiteit worden bevestigd telkens wanneer u online betaalt. Na de bestelling wordt u door de bevestiging geleid - verstrekt door uw bank.",
    "checkout.checkout.payment.complimentary": "gratis",
    "checkout.checkout.payment.paypal": "PayPal",
    "checkout.checkout.payment.paypalInfo": "U wordt doorgestuurd naar PayPal om het betalingsproces te voltooien. Uw PayPal-rekening wordt niet belast totdat u de bestelling in de volgende stap bevestigt.",
    "checkout.checkout.payment.sepa": "Automatische incasso (SEPA)",
    "checkout.checkout.payment.sepaLegal": "Ik machtig {{siteOwner}} {{siteOwnerAddress}} (Creditor ID: {{siteOwnerCreditorID}}) om betalingen via automatische incasso van mijn rekening te innen. Tegelijkertijd geef ik mijn kredietinstelling opdracht de automatische incasso's te honoreren {{siteOwner}} op mijn rekening.",
    "checkout.checkout.payment.noSepa": "Uw factuur- of verzendadres ligt buiten de EU. Daarom kunnen wij u geen betaling via automatische incasso aanbieden.",
    "checkout.checkout.payment.sepaInfo": "Snel en ongecompliceerd. Bij het afronden van uw bestelling (volgende stap) zal u gevraagd worden uw bankgegevens (IBAN) in te voeren. Houd ze alstublieft klaar. Wanneer de overschrijving is voltooid, ontvangt u een bevestiging.",
    "checkout.checkout.payment.prepayment": "Vooruitbetaling (bankoverschrijving)",
    "checkout.checkout.payment.prepaymentInfo": "Nadat u de bestelling heeft geplaatst, sturen wij u de bankoverschrijving informatie per e-mail. Wij kunnen de artikelen in uw bestelling slechts maximaal 7 dagen reserveren. Houd dit tijdvenster aan voor de bankoverschrijving. Zodra wij de betaling hebben ontvangen, wordt de bestelling verzonden.",
    "checkout.checkout.payment.invoice": "Factuur",
    "checkout.checkout.payment.invoiceInfo": "Het factuurbedrag is betaalbaar op de betaaldatum vermeld in de verzendbevestigingsmail.",
    "checkout.checkout.payment.noInvoice": "Helaas kunnen wij u voor deze bestelling geen betaling per factuur aanbieden. Kies een van de andere betalingswijzen.",
    "checkout.checkout.payment.sofort": "Sofortüberweisung",
    "checkout.checkout.payment.sofortInfo": "Bij het afronden van uw bestelling (volgende stap) zal u gevraagd worden uw bankgegevens (IBAN) in te voeren. Hou deze alsjeblieft klaar. Wanneer de overschrijving is voltooid, ontvangt u een bevestiging.",
    "checkout.checkout.payment.accountName": "Rekeninghouder",
    "checkout.checkout.payment.accountEmail": "Bevestiging e-mail adres",
    "checkout.checkout.sepaAndOrder": "Domiciliëringsmandaat voor {{totalAmount}} € bevestigen en bestellen met kosten",
    "checkout.checkout.sofortAndOrder": "Overdrachtsmandaat voor {{totalAmount}} € bevestigen en bestellen met kosten",
    "checkout.checkout.title": "Kassa",
    "checkout.checkout.next": "lees meer",
    "checkout.checkout.order": "Nu kopen",
    "checkout.checkout.orderConsentPre": "Met het bevel je te verantwoorden met onze",
    "checkout.checkout.orderConsentPost": "afgesproken.",
    "checkout.checkout.noAccount": "Maak geen klantenrekening aan",
    "checkout.redirect.countryPriceChange": "De prijzen zijn verschillend in uw land, gelieve rekening te houden met de prijswijziging.",
    "entries.entry.fields.language": "Taal",
    "entries.entry.ui.relatedJobs": "Verwante banen",
    "entries.entry.ui.relatedArticles": "Verwante artikelen",
    "entries.entry.ui.relatedCampaigns": "Verwante campagnes",
    "entries.entry.ui.selectJob": "Selecteer baan",
    "entries.entry.ui.selectArticle": "Kies artikel",
    "entries.entry.ui.selectCampaign": "Campagne kiezen",
    "entries.person.ui.diverse": "Zonder",
    "entries.poi.ui.poiEvents": "Evenementen op deze locatie",
    "entries.product.ui.choose": "kies",
    "entries.product.ui.discount": "Korting",
    "entries.product.ui.discountSelect": "% korting voordeel",
    "entries.product.ui.netPrizeDefault": "inclusief belasting over de toegevoegde waarde, plus",
    "entries.product.ui.deliveryTerms": "Verzendingskosten",
    "entries.product.ui.notOrderable": "Gelieve een variant te kiezen.",
    "entries.product.ui.prizeDecimalPoint": ",",
    "entries.product.ui.item": "Stuk",
    "entries.product.ui.prizePerItem": "Prijs per stuk",
    "entries.product.ui.size": "Maat",
    "errors.mandatory": "{{field}} moet worden ingevuld",
    "errors.mandatoryCheckbox": "Moet bevestigd worden.",
    "errors.mandatorySubmit": "U kunt het formulier pas verzenden als alle verplichte velden zijn ingevuld.",
    "forms.ui.formErr": "Fout bij het verzenden van het formulier",
    "forms.ui.formSent": "Formulier verzonden",
    "forms.ui.noForm": "Geen vorm toegewezen",
    "forms.ui.invalidMail": "Voer een geldig e-mail adres in.",
    "forms.ui.missingFields": "De volgende velden moeten worden ingevuld:",
    "forms.ui.fieldBuilder.street": "Straat",
    "forms.ui.fieldBuilder.houseNumber": "Nee.",
    "forms.ui.fieldBuilder.zip": "POSTCODE",
    "forms.ui.fieldBuilder.city": "Stad",
    "forms.ui.fieldBuilder.country": "Land",
    "forms.ui.fieldBuilder.firstName": "Voornaam",
    "forms.ui.fieldBuilder.lastName": "Achternaam",
    "forms.ui.privacyRead": "Ik heb kennis genomen van het privacybeleid",
    "jobs.ui.employmentType.FULL_TIME": "Voltijd",
    "jobs.ui.employmentType.PART_TIME": "Deeltijds",
    "jobs.ui.employmentType.APPRENTICE": "Stagiair",
    "jobs.ui.employmentType.INTERN": "Stage",
    "jobs.ui.employmentType.CONTRACTOR": "Freelance werk",
    "jobs.ui.employmentType.TRAINEE": "Stagiair, deeltijdse studie",
    "jobs.ui.employmentType.VOLUNTEER": "Vrijwilliger",
    "jobs.ui.employmentType.OTHER": "",
    "jobs.ui.location": "Baan beschikbaar bij",
    "jobs.ui.role.male": "M",
    "jobs.ui.role.female": "W",
    "jobs.ui.role.divers": "D",
    "jobs.ui.startDateNow": "Vanaf nu",
    "jobs.ui.startFrom": "Van",
    "name": "Naam",
    "profile.marketplace.request.missingCredentials": "U kunt pas een query starten als u ten minste één locatie en één meetpunt hebt aangemaakt.",
    "profile.newsletter.notSubscribedAny": "U bent nog niet ingeschreven op een nieuwsbrieflijst.",
    "register.ui.privacyStatement": "Ik ga ermee akkoord dat mijn persoonlijke gegevens worden opgeslagen. Mijn persoonlijke gegevens zullen niet aan derden worden doorgegeven. Ik kan deze toestemming voor gegevensbescherming te allen tijde met werking voor de toekomst herroepen.",
    "register.ui.oauthInfo": "U wordt doorverwezen naar de FRM login pagina om in te loggen met uw gebruikersinformatie en vervolgens terug te keren naar de website.",
    "search.resetPosition": "Positie resetten",
    "search.geoPositionBrowser": "Positie bepalen per browser",
    "search.geoPositionFault": "Positiebepaling door de browser is niet mogelijk",
    "search.geoPositionTitle": "Stel de positie in voor een zoekopdracht in de nabijheid",
    "search.selectedFilters": "Geselecteerde filters",
    "search.selectFilters": "Zoekopdracht filteren op categorie",
    "search.resultType.job": "Job",
    "search.resultType.recipe": "Recept",
    "user.company.addUser": "Maak extra gebruikers voor",
    "user.company.inviteUser": "Uitnodigingsmail sturen",
    "user.company.userIsInvited": "De uitnodigingsmail is verstuurd",
    "user.company.userIsActive": "Gebruiker is actief",
    "user.company.userIsNotActive": "Gebruiker is nog niet geactiveerd",
    "user.company.contactFor": "Contactgegevens voor",
    "user.company.contactForDefault": "algemeen contact",
    "user.company.notAllowed": "U kunt geen wijzigingen aanbrengen omdat uw gebruikersstatus ({{type}}) staat dit niet toe.",
    "user.company.registerNumber": "Handelsregisternummer",
    "user.company.registerAuth": "Lokale rechtbank",
    "user.company.acerCode": "ACER-code van uw energievoorziening",
    "user.company.taxNumber": "Fiscaal nummer",
    "user.company.type.default": "Standaard",
    "user.company.type.procurator": "Bevoegde ondertekenaar",
    "user.company.type.manager": "Directeur",
    "user.company.ustid": "UStID",
    "user.company.ustid.notValid": "Geef een geldig identificatienummer voor de omzetbelasting",
    "user.ui.accountMenuMeasuring": "Mijn meetlocaties en gegevens",
    "user.ui.accountMenuOffer": "Mijn aanbiedingen",
    "user.ui.accountMenuRequest": "Mijn verzoeken",
    "user.ui.accountMenuTender": "Aanbestedingen",
    "user.ui.accountMenuOrders": "Mijn orders",
    "user.ui.accountMenuJobs": "Mijn job biedt",
    "user.ui.genderFemaleSalutation": "Mevrouw.",
    "user.ui.genderMaleSalutation": "Mr.",
    "user.ui.genderDiverse": "diverse",
    "user.ui.pleaseSelectSalutation": "Selecteer een aanhef",
    "user.ui.requestPasswordSuccess": "U zou binnenkort een e-mail met een reset-link moeten ontvangen.",
    "user.ui.requestPasswordError": "Onbekende fout bij het resetten van het wachtwoord.",
    "user.ui.setPassword": "Wachtwoord instellen",
    "user.ui.setPasswordPage": "Stel uw wachtwoord in",
    "user.ui.setPasswordSuccess": "Uw wachtwoord is ingesteld, u kunt nu inloggen",
    "user.ui.salutation": "Salutatie",
    "user.ui.title": "Titel",
    "user.ui.thankYouForSubscribing": "Dank u voor het abonneren op onze nieuwsbrief.",
    "user.ui.errorAtSubscribing": "Fout bij het abonneren op de nieuwsbrief",
    "user.ui.unsubscribe": "uitloggen",
    "user.ui.unsubscribePage": "Weet u zeker dat u zich wilt afmelden voor onze nieuwsbrief?",
    "user.ui.phone": "Telefoon",
    "user.product.addedToFavorites": "Het artikel werd toegevoegd aan uw favorieten",
    "order.status.zero": "bestelling ontvangen",
    "order.status.one": "In behandeling",
    "order.status.two": "Bestelling is verzonden",
    "order.status.three": "Geraadpleegd op",
    "order.status.four": "Terug",
    "order.status.default": "Onbekende beroep staat",
    "country.germany": "Duitsland",
    "country.austria": "Oostenrijk",
    "country.belgium": "België",
    "country.denmark": "Denemarken",
    "country.bulgaria": "Bulgarije",
    "country.croatia": "Kroatië",
    "country.cyprus": "Cyprus",
    "country.czechia": "Tsjechische Republiek",
    "country.estonia": "Estland",
    "country.finland": "Finland",
    "country.france": "Frankrijk",
    "country.greece": "Griekenland",
    "country.hungary": "Hongarije",
    "country.ireland": "Ierland",
    "country.italy": "Italië",
    "country.latvia": "Letland",
    "country.lithuania": "Litouwen",
    "country.luxembourg": "Luxemburg",
    "country.lu": "Luxemburg",
    "country.malta": "Malta",
    "country.netherlands": "Nederland",
    "country.poland": "Polen",
    "country.portugal": "Portugal",
    "country.romania": "Roemenië",
    "country.slovakia": "Slowakije",
    "country.slovenia": "Slovenië",
    "country.spain": "Spanje",
    "country.sweden": "Zweden",
    "country.norway": "Noorwegen",
    "country.switzerland": "Zwitserland",
    "country.turkey": "Turkije",
    "country.unitedKingdom": "Verenigd Koninkrijk",
    "openingHours.tNowClosed": "Nu gesloten",
    "openingHours.tOpeningHoursNotKnown": "verdere openingstijden niet bekend",
    "openingHours.tNowOpen": "Nu open",
    "openingHours.tNowStillOpen": "Nu nog open",
    "openingHours.tNextOpeningHourUnknown": "volgende openingsdatum onbekend",
    "openingHours.tSoonOpenFrom": "binnenkort weer open van",
    "openingHours.tFrom": "van",
    "openingHours.tUntil": "Door",
    "openingHours.tOpenAgainFrom": "weer open van",
    "openingHours.tOpenAgainFrom2": "weer geopend op"
  };
});
define('webapp/components/forms-upload-button', ['exports', 'webapp/components/file-upload-base'], function (exports, _fileUploadBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fileUploadBase.default.extend({
    actions: {
      removeFile: function removeFile(file, key) {
        this.get('removeFile')(file, key);
      }
    }
  });
});
define('webapp/helpers/group-by-category', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.groupByCategory = groupByCategory;

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function groupByCategory(_ref) /*, hash*/{
    var _ref2 = _slicedToArray(_ref, 2),
        items = _ref2[0],
        catField = _ref2[1];

    var catMap = {};

    var unassigned = [];

    items.forEach(function (item) {
      var cat = Ember.get(item, catField);
      if (cat) {
        var id = cat._id.toString();
        if (id in catMap) {
          catMap[id].items.push(item);
        } else {
          catMap[id] = _extends({}, cat, { items: [item] });
        }
      } else {
        unassigned.push(item);
      }
    });

    var categories = Object.values(catMap).sort(function (a, b) {
      return a.order - b.order;
    });

    if (unassigned.length) {
      categories.push({
        title: 'unassigned',
        items: unassigned
      });
    }

    return categories;
  }

  exports.default = Ember.Helper.helper(groupByCategory);
});
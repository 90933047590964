define('webapp/utils/tree-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function mapTree(tree, fn, parent, level) {
    level = level || 0;
    return tree.map(function (elem) {
      var newElem = fn(elem, parent, level);
      if (newElem) {
        newElem.children = elem.children && mapTree(elem.children, fn, newElem, level + 1).filter(function (x) {
          return !!x;
        });
      }
      return newElem;
    }).filter(function (x) {
      return !!x;
    });
  }

  function walkTree(tree, fn, parent, level) {
    level = level || 0;
    tree.map(function (elem) {
      fn(elem, parent, level);
      if (elem.children) {
        walkTree(elem.children, fn, elem, level + 1);
      }
    });
  }

  function mapTreeBottomUp(tree, fn, parent, level) {
    level = level || 0;
    return tree.map(function (elem) {
      elem.children = elem.children && mapTreeBottomUp(elem.children, fn, elem, level + 1);
      return fn(elem, parent, level);
    });
  }

  function filterTree(tree, fn, parent) {
    var newElems = [];
    tree.forEach(function (elem) {
      var filterCond = fn(elem, parent);
      if (filterCond) {
        var newElem = Ember.assign({}, elem);
        if (elem.children) {
          newElem.children = filterTree(elem.children, fn, elem);
        }
        newElems.push(newElem);
      }
    });
    return newElems;
  }

  function flattenTree(tree) {
    var maxLevel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;

    var res = [];
    tree.forEach(function (elem) {
      if (maxLevel >= 1) {
        res.push(elem);
        if (elem.children) {
          res = res.concat(flattenTree(elem.children, maxLevel - 1));
        }
      }
    });
    return res;
  }

  function findInTree(tree, fn, parent) {
    var found = void 0;
    for (var i = 0; i < tree.length && !found; i++) {
      var elem = tree[i];
      if (fn(elem, parent)) {
        found = elem;
      } else if (elem.children && elem.children.length) {
        found = findInTree(elem.children, fn, elem);
      }
    }

    return found;
  }

  function buildTree(list, keyGetter, parentKeyGetter) {
    var tree = [];
    list.forEach(function (listItem) {
      var parentId = parentKeyGetter(listItem);
      if (parentId) {
        var parent = void 0;
        for (var i = 0; i < list.length; i++) {
          if (keyGetter(list[i]) === parentId) {
            parent = list[i];
            break;
          }
        }
        if (parent) {
          if (!parent.children) {
            parent.children = [];
          }
          parent.children.push(listItem);
        } else {
          tree.push(listItem);
        }
      } else {
        tree.push(listItem);
      }
    });
    return tree;
  }

  exports.mapTree = mapTree;
  exports.walkTree = walkTree;
  exports.filterTree = filterTree;
  exports.flattenTree = flattenTree;
  exports.findInTree = findInTree;
  exports.mapTreeBottomUp = mapTreeBottomUp;
  exports.buildTree = buildTree;
});
define("webapp/helpers/join", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.join = join;
  function join(params) {
    return params.filter(Boolean).join(" ");
  }

  exports.default = Ember.Helper.helper(join);
});
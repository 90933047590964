define('webapp/components/lightbox-slideshow', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var resizePanel = function resizePanel() {
    var panel = document.querySelector('.uk-lightbox.syd--uk-lightbox');
    var captionElem = document.querySelector('.uk-lightbox-toolbar.uk-lightbox-caption');
    var activeImage = document.querySelector('ul.uk-lightbox-items li.uk-active img');
    var imageHeight = activeImage.offsetHeight;

    var maxHeight = panel.offsetHeight - imageHeight - 35;
    captionElem.style.maxHeight = maxHeight + 'px';
  };

  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    lightBoxTemplate: Ember.computed(function () {
      var i18n = this.get('i18n');
      return ('<div class="uk-lightbox syd--uk-lightbox uk-overflow-hidden">\n    <ul class="uk-lightbox-items"></ul>\n    <div class="uk-lightbox-toolbar syd--toolbar-close uk-position-top uk-text-right uk-transition-slide-top">\n        <button class="uk-lightbox-toolbar-icon uk-close-large" type="button" uk-close></button>\n     </div>\n    <a class="uk-lightbox-button uk-position-center-left uk-position-medium uk-transition-fade" href="#" uk-slidenav-previous uk-lightbox-item="previous"></a>\n    <a class="uk-lightbox-button uk-position-center-right uk-position-medium uk-transition-fade" href="#" uk-slidenav-next uk-lightbox-item="next"></a>\n    <div class="uk-lightbox-toolbar uk-lightbox-caption uk-position-bottom uk-text-center">\n    </div><div class="uk-text-center uk-hidden-medium syd--rotate-caption"><span uk-icon="syd-mobile-rotate"></span>' + i18n.t('application.ui.mobileRotate').toString() + '</div>\n    </div>').replace(/\n/g, '');
    }),

    listenerBound: false,

    didInsertElement: function didInsertElement() {
      var element = this.element;
      UIkit.lightbox(element, { template: this.get('lightBoxTemplate') });

      UIkit.util.on('.uk-lightbox', 'show', function () {
        alert('Show');
      });
    },
    willDestroyElement: function willDestroyElement() {
      UIkit.lightbox(this.element).hide();
    },


    actions: {
      showLightBox: function showLightBox() {
        var isMobile = this.get('media.isMobile');
        if (isMobile) {
          setTimeout(function () {
            resizePanel();
          }, 10);

          if (!this.listenerBound) {
            setTimeout(function () {
              var panel = document.querySelector('.uk-lightbox.syd--uk-lightbox');
              UIkit.util.on(panel, 'itemshow', resizePanel);
            }, 10);
            this.listenerBound = true;
          }
        }
      }
    }
  });
});
define('webapp/components/shopping/cart-summary', ['exports', 'webapp/utils/price-calculations'], function (exports, _priceCalculations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    shoppingCartService: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    shoppingCart: Ember.computed.alias('shoppingCartService.shoppingCart'),

    // TODO: 1 alias shoppingCart.additionalDetails to additionalDetails if it is not set
    //       2 set a variable to let me know what the case is
    //       3 use that in computed progerties

    finalNet: Ember.computed('shoppingCart.finalNet', 'additionalDetails.@each.totalNet', function () {
      var finalNet = this.get('shoppingCart.finalNet');

      if (finalNet === undefined) {
        finalNet = 0;
      }

      var additionalDetails = this.get('additionalDetails');
      if (Array.isArray(additionalDetails)) {
        var additionalTotalNet = (0, _priceCalculations.sumField)(additionalDetails, 'totalNet');
        finalNet = finalNet + additionalTotalNet;
      }

      return finalNet;
    }),

    finalGross: Ember.computed('shoppingCart.finalGross', 'additionalDetails.@each.totalGross', function () {
      var finalGross = this.get('shoppingCart.finalGross');

      if (finalGross === undefined) {
        finalGross = 0;
      }

      var additionalDetails = this.get('additionalDetails');
      if (Array.isArray(additionalDetails)) {
        var additionalTotalGross = (0, _priceCalculations.sumField)(additionalDetails, 'totalGross');
        finalGross = finalGross + additionalTotalGross;
      }

      return finalGross;
    })
  });
});
define('webapp/components/image-transformation', ['exports', 'webapp/utils/deep-equal'], function (exports, _deepEqual) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function rotate(_ref, width, height, rotation) {
    var _ref2 = _slicedToArray(_ref, 2),
        x = _ref2[0],
        y = _ref2[1];

    if (rotation === 0) {
      return [x, y];
    } else if (rotation === 90) {
      return [y, height - x];
    } else if (rotation === 180) {
      return [width - x, height - y];
    } else if (rotation === 270) {
      return [width - y, x];
    }
  }

  function rotateInv(_ref3, width, height, rotation) {
    var _ref4 = _slicedToArray(_ref3, 2),
        x = _ref4[0],
        y = _ref4[1];

    if (rotation === 0) {
      return [x, y];
    } else if (rotation === 90) {
      return [height - y, x];
    } else if (rotation === 180) {
      return [width - x, height - y];
    } else if (rotation === 270) {
      return [y, width - x];
    }
  }

  function rotateWidthHeight(_ref5, rotation) {
    var _ref6 = _slicedToArray(_ref5, 2),
        width = _ref6[0],
        height = _ref6[1];

    if (rotation % 180 === 90) {
      return [height, width];
    } else {
      return [width, height];
    }
  }

  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    i18n: Ember.inject.service(),
    scale: 1,
    format: '16:9',
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    rotation: 0,

    initialized: false,
    isProcessing: true,

    standardFormats: Ember.computed(function () {
      return ['4:3', '16:9', '1:1'];
    }),

    showDuplicate: false,
    showModalClose: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.docListeners = [];
    },
    loadAndInitialize: function loadAndInitialize(img) {
      var width = img.offsetWidth;
      var height = img.offsetHeight;
      this.set('width', width);
      this.set('height', height);
      this.set('initialized', true);
      this.setInitialImagePosition(this.get('image.transformations'));
      this.refit();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.sendAction('registerComp', this); // needed for communication with images chooser

      var img = this.element.querySelector('.crop-image');
      if (img.offsetWidth) {
        this.loadAndInitialize(img);
      } else {
        img.addEventListener('load', function () {
          return _this.loadAndInitialize(img);
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.sendAction('unregisterComp', this); //needed for communication with images chooser
      this.docListeners.forEach(function (_ref7) {
        var _ref8 = _slicedToArray(_ref7, 2),
            evt = _ref8[0],
            listener = _ref8[1];

        document.removeEventListener(evt, listener);
      });
    },
    addDocumentEventListener: function addDocumentEventListener(evts, listener) {
      var _this2 = this;

      evts.forEach(function (evt) {
        _this2.docListeners.push([evt, listener]);
        document.addEventListener(evt, listener);
      });
    },
    removeDocumentEventListener: function removeDocumentEventListener(evts, listener) {
      var _this3 = this;

      evts.forEach(function (evt) {
        _this3.docListeners = _this3.docListeners.filter(function (_ref9) {
          var _ref10 = _slicedToArray(_ref9, 2),
              e = _ref10[0],
              l = _ref10[1];

          return !(e === evt && l === listener);
        });
        document.removeEventListener(evt, listener);
      });
    },


    originalFormat: Ember.computed('image.metadata.width', 'image.metadata.height', function () {
      var metadata = this.get('image.metadata');
      return metadata && metadata.width + ':' + metadata.height;
    }),

    calcCropBoxDims: function calcCropBoxDims(w, h) {
      var border = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 30;

      var twoBorder = 2 * border;

      var _getContainerRect = this.getContainerRect(),
          containerHeight = _getContainerRect.height,
          containerWidth = _getContainerRect.width;

      var cropBoxHeight = void 0;
      var cropBoxWidth = void 0;
      if ((containerWidth - twoBorder) / (containerHeight - twoBorder) > w / h) {
        cropBoxHeight = containerHeight - twoBorder;
        cropBoxWidth = cropBoxHeight * w / h;
      } else {
        cropBoxWidth = containerWidth - twoBorder;
        cropBoxHeight = cropBoxWidth * h / w;
      }
      return {
        top: (containerHeight - cropBoxHeight) / 2,
        left: (containerWidth - cropBoxWidth) / 2,
        width: cropBoxWidth,
        height: cropBoxHeight
      };
    },


    cropBoxDims: Ember.computed('format', function () {
      var format = this.get('format');

      var _format$split$map = format.split(':').map(function (x) {
        return parseInt(x);
      }),
          _format$split$map2 = _slicedToArray(_format$split$map, 2),
          w = _format$split$map2[0],
          h = _format$split$map2[1];

      return this.calcCropBoxDims(w, h);
    }),

    cropCoords: Ember.computed('top', 'left', 'scale', function () {
      var top = this.get('top');
      var left = this.get('left');
      var scale = this.get('scale');
      var cropBoxDims = this.get('cropBoxDims');

      return {
        left: (cropBoxDims.left - left) / scale,
        top: (cropBoxDims.top - top) / scale,
        width: cropBoxDims.width / scale,
        height: cropBoxDims.height / scale
      };
    }),

    imgStyle: Ember.computed('rotation', 'scale', 'top', 'left', function () {
      var rotation = this.get('rotation');
      var scale = this.get('scale');
      var top = this.get('top');
      var left = this.get('left');
      var height = this.get('height');
      var width = this.get('width');

      var leftPrime = left;
      var topPrime = top;

      if (rotation === 90) {
        leftPrime += scale * height;
      } else if (rotation === 180) {
        leftPrime += scale * width;
        topPrime += scale * height;
      } else if (rotation === 270) {
        topPrime += scale * width;
      }

      return Ember.String.htmlSafe(['top: ' + topPrime + 'px;', 'left: ' + leftPrime + 'px;', 'transform-origin: top left;', 'transform: rotate(' + rotation + 'deg) scale(' + scale + ');'].join(' '));
    }),

    getContainerRect: function getContainerRect() {
      var container = this.element.querySelector('.image-container');
      return {
        width: container.offsetWidth,
        height: container.offsetHeight
      };
    },


    cropBoxStyle: Ember.computed('cropBoxDims', function () {
      var cropBox = this.get('cropBoxDims');
      var style = ['width: ' + cropBox.width + 'px;', 'height: ' + cropBox.height + 'px;', 'border-width: ' + cropBox.top + 'px ' + cropBox.left + 'px;'].join(' ');

      return Ember.String.htmlSafe(style);
    }),

    getRotatedWidthHeight: function getRotatedWidthHeight() {
      var rotation = this.get('rotation');
      if (rotation % 180 === 90) {
        return { width: this.get('height'), height: this.get('width') };
      } else {
        return { width: this.get('width'), height: this.get('height') };
      }
    },
    setTopLeft: function setTopLeft(top, left) {
      var scale = this.get('scale');
      var cropBoxDims = this.get('cropBoxDims');

      var _getRotatedWidthHeigh = this.getRotatedWidthHeight(),
          width = _getRotatedWidthHeigh.width,
          height = _getRotatedWidthHeigh.height;

      var fixedTop = Math.max(Math.min(top, cropBoxDims.top), cropBoxDims.height + cropBoxDims.top - scale * height);
      var fixedLeft = Math.max(Math.min(left, cropBoxDims.left), cropBoxDims.width + cropBoxDims.left - scale * width);

      this.set('top', fixedTop);
      this.set('left', fixedLeft);
    },
    setScale: function setScale(scale) {
      var _getRotatedWidthHeigh2 = this.getRotatedWidthHeight(),
          width = _getRotatedWidthHeigh2.width,
          height = _getRotatedWidthHeigh2.height;

      var cropBoxDims = this.get('cropBoxDims');

      // modify top left to make scale origin center of crop box
      var scaleDelta = scale - this.get('scale');

      var _getContainerRect2 = this.getContainerRect(),
          containerHeight = _getContainerRect2.height,
          containerWidth = _getContainerRect2.width;

      var topOffset = scaleDelta * containerHeight / 2;
      var leftOffset = scaleDelta * containerWidth / 2;

      var minScale = Math.max(cropBoxDims.width / width, cropBoxDims.height / height);
      this.set('scale', Math.max(scale, minScale));
      this.setTopLeft(this.get('top') - topOffset, this.get('left') - leftOffset); // refit if out of bounds
    },
    refit: function refit() {
      this.setTopLeft(this.get('top'), this.get('left'));
      this.setScale(this.get('scale'));
    },
    setInitialFormat: function setInitialFormat(width, height) {
      var ratio = width / height;
      var formats = this.get('standardFormats').concat(this.get('originalFormat'));

      var closestFormat = formats[0];
      var dist = Infinity;

      formats.forEach(function (format) {
        var fSplit = format.split(':').map(function (val) {
          return parseInt(val);
        });
        var fRatio = fSplit[0] / fSplit[1];
        var fDist = Math.abs(fRatio - ratio);
        if (fDist < dist) {
          dist = fDist;
          closestFormat = format;
        }
      });

      this.set('format', closestFormat);
    },
    setInitialImagePosition: function setInitialImagePosition() {
      var transformations = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      var image = this.get('image');
      var width = this.get('width');
      var height = this.get('height');

      var metadata = image.metadata;
      var scaleFactor = metadata.width / width;

      var cropTrafo = transformations.find(function (trafo) {
        return trafo.name === 'crop';
      });
      var rotateTrafo = transformations.find(function (trafo) {
        return trafo.name === 'rotate';
      });
      var rotation = rotateTrafo ? rotateTrafo.options.angle : 0;

      var cropOptions = cropTrafo ? cropTrafo.options : {
        top: 0,
        left: 0,
        width: metadata.width,
        height: metadata.height
      };

      var optionsLeft = cropOptions.left / scaleFactor;
      var optionsTop = cropOptions.top / scaleFactor;
      var optionsWidth = cropOptions.width / scaleFactor;
      var optionsHeight = cropOptions.height / scaleFactor;

      var _rotateInv = rotateInv([optionsLeft, optionsTop], width, height, rotation),
          _rotateInv2 = _slicedToArray(_rotateInv, 2),
          realLeft = _rotateInv2[0],
          realHeight = _rotateInv2[1];

      var _rotateWidthHeight = rotateWidthHeight([optionsWidth, optionsHeight], rotation),
          _rotateWidthHeight2 = _slicedToArray(_rotateWidthHeight, 2),
          cropWidth = _rotateWidthHeight2[0],
          cropHeight = _rotateWidthHeight2[1];

      this.setInitialFormat(cropWidth, cropHeight);
      var cropBoxDims = this.get('cropBoxDims');

      var cropLeft = rotation === 90 || rotation === 180 ? realLeft - cropWidth : realLeft;
      var cropTop = rotation === 180 || rotation === 270 ? realHeight - cropHeight : realHeight;

      var scale = cropBoxDims.width / cropWidth;

      this.setProperties({
        scale: scale,
        left: cropBoxDims.left - scale * cropLeft,
        top: cropBoxDims.top - scale * cropTop,
        rotation: rotation
      });
    },
    coordsToTransformations: function coordsToTransformations() {
      var image = this.get('image');
      var width = this.get('width');
      var height = this.get('height');
      var metadata = image.metadata;
      var scaleFactor = metadata.width / width;
      var cropCoords = this.get('cropCoords');
      var rotation = this.get('rotation');

      var realLeft = rotation === 90 || rotation === 180 ? cropCoords.left + cropCoords.width : cropCoords.left;

      var realTop = rotation === 180 || rotation === 270 ? cropCoords.top + cropCoords.height : cropCoords.top;

      var _rotateWidthHeight3 = rotateWidthHeight([cropCoords.width, cropCoords.height], rotation),
          _rotateWidthHeight4 = _slicedToArray(_rotateWidthHeight3, 2),
          realWidth = _rotateWidthHeight4[0],
          realHeight = _rotateWidthHeight4[1];

      var _rotate = rotate([realLeft, realTop], width, height, rotation),
          _rotate2 = _slicedToArray(_rotate, 2),
          rotatedLeft = _rotate2[0],
          rotatedTop = _rotate2[1];

      var cropOptions = {
        left: Math.max(0, Math.round(rotatedLeft * scaleFactor)),
        top: Math.max(0, Math.round(rotatedTop * scaleFactor)),
        width: Math.min(metadata.width, Math.round(realWidth * scaleFactor)),
        height: Math.min(metadata.height, Math.round(realHeight * scaleFactor))
      };

      var transformations = [];

      // skip crop trafo if full image is selected
      if (!(cropOptions.left === 0 && cropOptions.top === 0 && cropOptions.width === metadata.width && cropOptions.height === metadata.height)) {
        transformations.push({
          name: 'crop',
          options: cropOptions
        });
      }

      if (rotation !== 0) {
        transformations.push({
          name: 'rotate',
          options: {
            angle: rotation
          }
        });
      }

      return transformations;
    },
    notifyProcessing: function notifyProcessing() {
      var message = this.get('message');
      message.infoT('media.ui.processingImage');
      this.set('isProcessing', true);
    },
    finishProcessing: function finishProcessing() {
      this.set('isProcessing', false);
    },
    transformAndSave: function transformAndSave() {
      var _this4 = this;

      var duplicate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var myStorage = this.get('myStorage');
      var image = this.get('image');
      var transformations = this.coordsToTransformations();
      var oldTransformations = image.transformations || [];

      if (duplicate) {
        var i18n = this.get('i18n');
        return UIkit.modal.prompt(i18n.t('media.ui.filename').toString(), image.name + '-copy', { stack: true }).then(function (name) {
          _this4.notifyProcessing();
          return myStorage.mediaAdmin.ajaxPost({
            param: 'duplicate/' + image._id,
            data: {
              transformations: transformations,
              filename: name
            }
          }).then(function () {
            _this4.set('timeStamp', Date.now());
            _this4.sendAction('afterTransform');
          }).finally(function () {
            _this4.finishProcessing();
          });
        });
      } else {
        var trafosEqual = (0, _deepEqual.default)(transformations, oldTransformations, function (key) {
          return key !== '_id';
        });
        if (trafosEqual) {
          this.sendAction('afterTransform');
          return Ember.RSVP.resolve();
        } else {
          this.notifyProcessing();
          return myStorage.mediaAdmin.ajaxPut({
            param: image._id,
            data: {
              transformations: transformations
            }
          }).then(function () {
            _this4.set('timeStamp', Date.now());
            _this4.sendAction('afterTransform');
          }).finally(function () {
            _this4.finishProcessing();
          });
        }
      }
    },
    reset: function reset() {
      this.set('format', this.get('originalFormat'));
      this.setInitialImagePosition([]);
    },


    actions: {
      mouseDown: function mouseDown(e) {
        var _this5 = this;

        e.preventDefault();
        if (e.touches) {
          e = e.touches[0];
        }
        var initialX = e.clientX;
        var initialY = e.clientY;
        var initalTop = this.get('top');
        var initalLeft = this.get('left');

        var mouseMoveListener = function mouseMoveListener(e) {
          if (e.touches) {
            e = e.touches[0];
          } else {
            e.preventDefault();
          }
          var deltaX = e.clientX - initialX;
          var deltaY = e.clientY - initialY;
          _this5.setTopLeft(initalTop + deltaY, initalLeft + deltaX);
        };

        this.addDocumentEventListener(['mousemove', 'touchmove'], mouseMoveListener);

        var mouseUpListener = function mouseUpListener() {
          _this5.removeDocumentEventListener(['mousemove', 'touchmove'], mouseMoveListener);
          _this5.removeDocumentEventListener(['mouseup', 'touchend', 'touchcancel'], mouseUpListener);
        };
        this.addDocumentEventListener(['mouseup', 'touchend', 'touchcancel'], mouseUpListener);
      },
      onWheel: function onWheel(e) {
        e.preventDefault();
        var factor = 1 + Math.abs(e.deltaY) / 500;
        if (e.deltaY < 0) {
          factor = 1 / factor;
        }
        this.setScale(this.get('scale') * factor);
      },
      scaleImage: function scaleImage(e) {
        this.setScale(parseFloat(e.target.value));
      },
      resetScale: function resetScale() {
        this.setScale(1);
      },
      setFormat: function setFormat(format) {
        this.set('format', format);
        this.refit();
      },
      rotateImage: function rotateImage() {
        var rotation = this.get('rotation');
        rotation += 90;
        if (rotation === 360) {
          rotation = 0;
        }
        this.set('rotation', rotation);

        // refit image if out of bounds
        this.setScale(this.get('scale'));
        this.setTopLeft(this.get('top'), this.get('left'));
      },
      close: function close() {
        this.sendAction('close');
      },
      save: function save() {
        this.transformAndSave(false);
      },
      reset: function reset() {
        this.reset();
      },
      duplicateAndSave: function duplicateAndSave() {
        this.transformAndSave(true);
      },
      resetAndSave: function resetAndSave() {
        var _this6 = this;

        var myStorage = this.get('myStorage');
        var image = this.get('image');

        myStorage.mediaAdmin.ajaxPut({
          param: image._id,
          data: {
            transformations: []
          }
        }).then(function () {
          _this6.set('timeStamp', Date.now());
          _this6.sendAction('afterTransform');
        });
      }
    }
  });
});
define('webapp/services/customer-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    cmsService: Ember.inject.service(),

    getOrders: function getOrders() {
      var myStorage = this.get('myStorage');
      var param = 'orders';
      var query = { sessionId: this.get('cmsService.sessionId') };

      return myStorage.customer.ajaxGet({ param: param, query: query });
    },
    getOrder: function getOrder(orderId) {
      var myStorage = this.get('myStorage');
      var param = 'order/' + orderId;
      var query = { sessionId: this.get('cmsService.sessionId') };

      return myStorage.customer.ajaxGet({ param: param, query: query });
    }
  });
});
define('webapp/components/track-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',
    attributeBindings: ['href', 'target'],

    target: '_blank',

    click: function click(e) {
      if (window._paq) {
        window._paq.push(['trackLink', this.get('href'), 'link']);
      }
      e.stopPropagation();
    }
  });
});
define('webapp/components/hyphen-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function replaceShy(text) {
    return text.replace(/\(-\)/g, '\xAD');
  }

  function isUpper(c) {
    return c.toUpperCase() === c;
  }

  function upperFirst(text) {
    return text.slice(0, 1).toUpperCase() + text.slice(1);
  }

  function hyphenate(text, hyphenTable) {
    var _loop = function _loop(_ref) {
      var word = _ref.word,
          replacement = _ref.replacement;

      text = text.replace(word, function (match) {
        if (isUpper(match[0])) {
          return upperFirst(replacement);
        } else {
          return replacement;
        }
      });
    };

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = hyphenTable[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _ref = _step.value;

        _loop(_ref);
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return text;
  }

  var hyphenTable = null;

  exports.default = Ember.Component.extend({
    cachedData: Ember.inject.service(),

    tagName: 'span',
    classNameBindings: ['didReplace:manual-hyphen'],

    getHyphenTable: function getHyphenTable() {
      if (!hyphenTable) {
        var hyphenation = this.get('cachedData.cachedSite.hyphenation') || [];
        hyphenTable = hyphenation.map(function (x) {
          return {
            word: new RegExp(x.word, 'ig'),
            replacement: replaceShy(x.replacement)
          };
        });
      }
      return hyphenTable;
    },


    text: Ember.computed('params', function () {
      var _get = this.get('params'),
          _get2 = _slicedToArray(_get, 1),
          originalText = _get2[0];

      if (typeof originalText !== 'string') {
        return originalText;
      }

      var hyphenTable = this.getHyphenTable();
      return hyphenate(originalText, hyphenTable);
    }),

    didReplace: Ember.computed('params', 'text', function () {
      var _get3 = this.get('params'),
          _get4 = _slicedToArray(_get3, 1),
          originalText = _get4[0];

      var text = this.get('text');

      return text !== originalText;
    })
  }).reopenClass({
    positionalParams: 'params'
  });
});
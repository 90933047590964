define('webapp/routes/account/likes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),

    model: function model() {
      var myStorage = this.get('myStorage');
      return myStorage.like.ajaxGet({ param: 'list' }).then(function (res) {
        return res;
      });
    }
  });
});
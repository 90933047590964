define('webapp/components/widgets/widget-shopping-cart-content', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    shoppingCartService: Ember.inject.service(),
    shoppingCart: Ember.computed.alias('shoppingCartService.shoppingCart'),
    router: Ember.inject.service(),

    hasItems: Ember.computed('shoppingCart.items', function () {
      var rawItems = this.get('shoppingCart.items');
      return rawItems && rawItems.length > 0;
    })
  });
});
define('webapp/components/page-link-to', ['exports', 'webapp/utils/misc'], function (exports, _misc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  // TODO this should be the one and only final link component

  /**
   * {{page-link-to param1 param2}}
   *
   * param1 is either a full page object, or a containerName e.g. "productContainer"
   * param2 is the slug of the item displayed in the container page
   *
   */

  var CONTAINER_TYPES = ['search', 'entryContainer', 'poiContainer', 'eventContainer', 'productContainer', 'categoryContainer', 'profileContainer', 'documentContainer', 'articleContainer', 'jobContainer', 'recipeContainer', 'campaignContainer', 'globalSearchContainer', 'comparisonContainer'];

  var PageLinkTo = Ember.Component.extend({
    cachedData: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    languageService: Ember.inject.service(),
    tagName: '',

    getPageRouteParams: function getPageRouteParams(page) {
      var cmsService = this.get('cmsService');
      var pageRouteParams = cmsService.getPageRouteParams(page, this.get('params'));
      var queryParams = this.get('queryParams');
      if (queryParams) {
        return [].concat(_toConsumableArray(pageRouteParams), [queryParams]);
      } else {
        return pageRouteParams;
      }
    },
    getContainerPage: function getContainerPage(containerType) {
      if (!CONTAINER_TYPES.includes(containerType)) {
        throw new Error('invalid container ' + containerType);
      }
      var fullDynamicPageRoutes = this.get('cmsService.fullDynamicPageRoutes');
      return fullDynamicPageRoutes[containerType];
    },


    page: Ember.computed('params', function () {
      var params = this.get('params');
      var pageParam = params[0];
      if (!pageParam) {
        throw new Error('page-link-to helper needs at least one arguments');
      }
      if (typeof pageParam === 'string') {
        // container page
        return this.getContainerPage(pageParam);
      } else {
        // full page object
        return pageParam;
      }
    }),

    linkToParams: Ember.computed('page', 'languageService.selectedLanguage', function () {
      var page = this.get('page');
      return this.getPageRouteParams(page);
    }),

    isExternal: Ember.computed('page', function () {
      return this.get('page.site._id') !== this.get('cachedData.cachedSite._id');
    }),

    externalLink: Ember.computed('page', function () {
      var siteURL = this.get('page.site.url');
      var fullSlug = this.get('page.fullSlug');
      var params = this.get('params');
      if (params.length > 1) {
        return _misc.joinRoutes.apply(undefined, [siteURL.replace(/\/$/, ''), fullSlug].concat(_toConsumableArray(params.slice(1))));
      } else {
        return (0, _misc.joinRoutes)(siteURL.replace(/\/$/, ''), fullSlug);
      }
    })
  }).reopenClass({
    positionalParams: 'params'
  });

  exports.default = PageLinkTo;
});
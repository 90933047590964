define('webapp/routes/azure-oauth', ['exports', 'webapp/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    i18n: Ember.inject.service(),

    queryParams: {
      code: {
        refreshModel: false
      }
    },

    loginUser: function loginUser(code) {
      var myStorage = this.get('myStorage');
      var userProxy = this.get('userProxy');

      return myStorage.azureOAuth.ajaxPost({ param: 'login', data: { code: code } }).then(function (userResult) {
        userProxy._login(userResult);
      });
    },
    beforeModel: function beforeModel(_ref) {
      var _this = this;

      var queryParams = _ref.queryParams;

      return this.loginUser(queryParams.code).then(function () {
        _this.replaceWith('account');
      }).catch(function (err) {
        // continue with model on error (error message is page content)
        console.error(err);
      });
    },
    model: function model(params) {
      var code = params.code;

      if (!code) {
        throw new Error('no code parameter given');
      }

      return this.get('cmsService').getAndSetActivePage('azure-oauth');
    },
    getBreadcrumbs: function getBreadcrumbs() {
      return [{
        route: 'azure-oauth',
        title: 'Oauth'
      }];
    }
  });
});
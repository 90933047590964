define('webapp/components/social-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var text = this.get('content');

      // split text into paragraphs
      var paragraphArray = text.split('\n\n');

      // inserts <br> at line breaks
      var formatedArray = paragraphArray.map(function (paragraph) {
        return paragraph.replace(/\n/g, '<br>');
      });

      // regex: url´s starting with www or https
      var re = /http(s)?:\/\/\S+|w{3}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

      //regex: look for words that are at least 3 characters long and begin with a hash
      var reHash = /\B#([a-zA-Z0-9ä-üÄ-Ü_ßẞ]{2,})(?![~!@#$%^&*()=+`\-\|\/'\[\]\{\}]|[?.,]*\w)/g;

      //regex: look for words that are at least 3 characters long and begin with a @
      var reAt = /\B@([a-zA-Z0-9_]{3,})(?![~!@#$%^&*()=+`\-\|\/'\[\]\{\}]|[?.,]*\w)/g;

      var messageArray = formatedArray.map(function (paragraph) {
        // looks for urls
        var segs = _this.findRegex(paragraph, re, 'link');

        //looks for hashes
        segs.forEach(function (element) {
          if (element.type === 'text') {
            var idx = segs.indexOf(element);
            var regexArray = _this.findRegex(element.value, reHash, 'hash');
            segs.splice(idx, 1);
            regexArray.forEach(function (item) {
              segs.splice(idx, 0, item);
              idx++;
            });
          }
        });

        //looks for @
        segs.forEach(function (element) {
          if (element.type === 'text') {
            var idx = segs.indexOf(element);
            var regexArray = _this.findRegex(element.value, reAt, 'at');
            segs.splice(idx, 1);
            regexArray.forEach(function (item) {
              segs.splice(idx, 0, item);
              idx++;
            });
          }
        });

        return segs;
      });

      this.set('messageArray', messageArray);
    },
    findRegex: function findRegex(string, regex, hash) {
      var regexArray = [];
      var match = void 0;
      var lastIndex = 0;
      while ((match = regex.exec(string)) !== null) {
        if (match.index !== lastIndex) {
          regexArray.push({ type: 'text', value: string.slice(lastIndex, match.index) });
        }
        regexArray.push({ type: hash, value: match[0] });
        lastIndex = match.index + match[0].length;
      }
      if (string && lastIndex < string.length) {
        regexArray.push({ type: 'text', value: string.slice(lastIndex) });
      }

      return regexArray;
    }
  });
});
define('webapp/components/shopping/paypal-button', ['exports', 'webapp/config/environment', 'webapp/utils/inject-script'], function (exports, _environment, _injectScript) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    shoppingCartService: Ember.inject.service(),
    shoppingCart: Ember.computed.alias('shoppingCartService.shoppingCart'),
    paymentService: Ember.inject.service(),

    /*
     * Integrate frontend paypal sdk and call the function that creates the paypal button
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (_environment.default.payPalClientID && _environment.default.payPalCurrency) {
        var payPayButtonURL = 'https://www.paypal.com/sdk/js?client-id=' + _environment.default.payPalClientID + '&currency=' + _environment.default.payPalCurrency;
        (0, _injectScript.default)(payPayButtonURL).then(function () {
          if (window.paypal) {
            _this.createPayPalButtons();
          } else {
            //logError("Couldn't load PayPal script with injectScript in paypal-button component!");
          }
        });
      }
    },


    /*
     * Tear down of the paypal button
     */
    willDestroyElement: function willDestroyElement() {
      if (this.btnComponent) {
        this.btnComponent.close();
      }
    },


    /*
     * Create the paypal button and integrate it with the payment service and thereby the API
     */
    createPayPalButtons: function createPayPalButtons() {
      var _this2 = this;

      if (this.isDestroyed) {
        return;
      }
      var paymentService = this.get('paymentService');

      var btnComponent = window.paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'horizontal',
          label: 'paypal'
        },

        createOrder: function createOrder(data) {
          return paymentService.createOrderWithPayPal(data).then(function (res) {
            return res.orderID;
          });
        },

        onApprove: function onApprove(data) {
          return paymentService.approveOrderWithPayPal(data).then(function () {
            if (_this2.get('afterApprove')) {
              _this2.get('afterApprove')();
            }
          });
        }
      });

      btnComponent.render(this.element);
      this.btnComponent = btnComponent;
    }
  });
});
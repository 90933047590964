define("webapp/helpers/first-word", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var text = params[0] || "";
    var separator = params[1] || " ";
    var splittedText = text.trim().split(separator);
    var firstWord = splittedText.shift();

    return (splittedText.length > 0 ? "<span class='text-primary'>" + firstWord + separator + "</span>" : firstWord) + splittedText.join(" ");
  });
});
define('webapp/utils/validation-utils', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.AddressValidation = exports.addressFields = undefined;
  exports.presenceValidation = presenceValidation;
  exports.makeValidatedAddress = makeValidatedAddress;
  exports.emailValid = emailValid;

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function presenceValidation(descriptionKey) {
    return (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      descriptionKey: descriptionKey
    });
  }

  var addressFields = exports.addressFields = ['gender', 'firstName', 'lastName', 'street', 'houseNumber', 'zip', 'city', 'countryCode'];

  function makeValidatedAddress(address, ownerInjection) {
    if (!address) {
      address = {};
    }
    return Ember.Object.extend(AddressValidation).create(ownerInjection, _extends({}, address, {
      gender: address.gender || 'diverse'
    }));
  }

  var AddressValidation = exports.AddressValidation = (0, _emberCpValidations.buildValidations)({
    firstName: presenceValidation('user.ui.firstName'),
    lastName: presenceValidation('user.ui.lastName'),
    street: presenceValidation('user.ui.street'),
    zip: presenceValidation('user.ui.zip'),
    city: presenceValidation('user.ui.city'),
    countryCode: presenceValidation('user.ui.country')
    // email: presenceValidation('user.ui.email'),
    // phone: presenceValidation('user.ui.city'),
  });

  function emailValid(email) {
    return email && email.match(/^\S+@\S+$/);
  }
});
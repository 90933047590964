define('webapp/helpers/number-to-human', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.numberToHuman = numberToHuman;
  function numberToHuman(value /*, hash*/) {
    var base = Math.floor(Math.log(Math.abs(value)) / Math.log(1000));
    var suffix = 'kmb'[base - 1];
    var newNumber = suffix ? String(value / Math.pow(1000, base)).substring(0, 4) + suffix : '' + value;
    if (newNumber[3] === '.') {
      newNumber = newNumber.replace('.', '');
    }
    if (newNumber[2] === '.' && newNumber[3] === '0') {
      newNumber = newNumber.replace('.0', '');
    }
    return newNumber;
  }

  exports.default = Ember.Helper.helper(numberToHuman);
});
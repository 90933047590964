define('webapp/components/jobs/application-detail', ['exports', 'webapp/config/environment', 'webapp/utils/fetch-utils', 'webapp/utils/object-utils'], function (exports, _environment, _fetchUtils, _objectUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _objectWithoutProperties(obj, keys) {
    var target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function itemEquals(item1, item2) {
    return item1.statusDecision === item2.statusDecision && item1.statusQualifying === item2.statusQualifying && (0, _objectUtils.arraysEqual)(item1.statusAssessment, item2.statusAssessment);
  }

  var statusFields = ['statusDecision', 'statusQualifying', 'statusAssessment'];

  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    i18n: Ember.inject.service(),
    itemStorage: Ember.computed.alias('myStorage.jobApplicationAdmin'),

    didReceiveAttrs: function didReceiveAttrs() {
      // set local item to a clone, so the local changes are not reflected in the list
      var item = this.get('originalItem');
      this.set('item', _extends({}, item));
    },


    allFiles: Ember.computed(function () {
      var formData = this.get('item.formData');
      var fileFields = ['certificates', 'coverNote', 'cv'];

      var allFiles = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = fileFields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var field = _step.value;

          if (Array.isArray(formData[field])) {
            allFiles.push.apply(allFiles, _toConsumableArray(formData[field]));
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return allFiles;
    }),

    updateApplication: function updateApplication() {
      var _this = this;

      var item = this.get('item');
      var itemStorage = this.get('itemStorage');
      var i18n = this.get('i18n');

      var _id = item._id,
          data = _objectWithoutProperties(item, ['_id']);

      var itemsEqual = itemEquals(item, this.get('originalItem'));

      if (itemsEqual) {
        console.log('no changes');
        return;
      }

      var userProxy = this.get('userProxy');
      var logItem = {
        createdAt: new Date().toISOString(),
        type: 'save',
        user: Ember.getProperties(userProxy, ['_id', 'firstName', 'lastName']),
        before: Ember.getProperties(Ember.get(this, 'originalItem'), statusFields),
        after: Ember.getProperties(data, statusFields)
      };

      var update = _extends({}, (0, _objectUtils.getChanges)(data, this.get('originalItem')), {
        lastStatusChange: new Date().toISOString(),
        notes: [].concat(_toConsumableArray(data.notes || []), [logItem])
      });

      Ember.setProperties(item, update);

      return itemStorage.ajaxPut({
        param: _id,
        data: update
      }).then(function () {
        _this.get('message').info(i18n.t('dialog.saved.status').toString());
        var originalItem = _this.get('originalItem');
        Ember.setProperties(originalItem, update);
        // set local item to a clone, so the local changes are not reflected in the list
        _this.set('item', _extends({}, originalItem));
      }).catch(function (err) {
        _this.get('message').error(err.message);
        // setProperties(item, snapShotBefore);
      });
    },
    sendDenyWorkflowMail: function sendDenyWorkflowMail() {
      var _this2 = this;

      var itemStorage = this.get('itemStorage');

      return itemStorage.ajaxPost({
        param: 'send_workflow_mail/' + this.get('item._id'),
        data: {
          type: 'applicationDenied'
        }
      }).catch(function (err) {
        _this2.get('message').error(err.message);
      });
    },


    actions: {
      setItemProperty: function setItemProperty(item, property, value) {
        Ember.set(item, property, value);
      },
      toggleStatusAssessment: function toggleStatusAssessment(item, value) {
        var statusAssessment = Ember.get(item, 'statusAssessment');

        var newStatus = [].concat(_toConsumableArray(statusAssessment));

        if (statusAssessment.includes(value)) {
          newStatus = statusAssessment.filter(function (status) {
            return status !== value;
          });
        } else {
          newStatus.push(value);
        }

        Ember.set(item, 'statusAssessment', newStatus);
      },
      toggleStatusDecision: function toggleStatusDecision(item) {
        var statusDecision = Ember.get(item, 'statusDecision');

        var newStatus = void 0;
        if (statusDecision === null) {
          newStatus = true;
        } else if (statusDecision === true) {
          newStatus = false;
        } else {
          newStatus = null;
        }

        Ember.set(item, 'statusDecision', newStatus);
      },
      save: function save() {
        this.updateApplication();
      },
      acceptApplication: function acceptApplication() {
        var _this3 = this;

        var i18n = this.get('i18n');

        UIkit.modal.confirm(i18n.t('dialog.promptAcceptJobApplication').toString()).then(function () {
          _this3.set('item.statusDecision', true);
          _this3.updateApplication().then(function () {
            _this3.get('closeModal')();
          });
        });
      },
      denyApplication: function denyApplication() {
        var _this4 = this;

        var i18n = this.get('i18n');

        UIkit.modal.confirm(i18n.t('dialog.promptRejectJobApplication').toString()).then(function () {
          _this4.set('item.statusDecision', false);
          _this4.updateApplication().then(function () {
            _this4.sendDenyWorkflowMail();
            _this4.get('closeModal')();
          });
        });
      },
      downloadAll: function downloadAll() {
        var message = this.get('message');
        var allFiles = this.get('allFiles');
        var firstFile = allFiles[0];
        if (!firstFile) {
          return;
        }

        var folder = firstFile.path.split('/').slice(0, -1).join('/');
        var formData = this.get('item.formData');
        var filename = 'Bewerbung ' + formData.lastName + ' ' + formData.firstName + '.zip';

        // /v1/admin/media/download_zip

        fetch(_environment.default.apiEndpoints.mediaAdmin + '/download_zip', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Token: this.get('userProxy.token')
          },
          body: JSON.stringify({ folder: folder })
        }).then(function (res) {
          if (!res.ok) {
            res.text().then(function (text) {
              message.error(text);
            });
            return;
          }

          res.blob().then(function (blob) {
            (0, _fetchUtils.downloadBlob)(blob, filename);
          });
        });
      }
    }
  });
});
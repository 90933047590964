define('webapp/components/string-to-paragraph', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    paragraphs: Ember.computed('content', function () {
      var content = this.get('content');
      if (!content) {
        return [];
      }
      return content.trim().split(/\s*\n\s*/);
    })
  });
});
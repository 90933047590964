define('webapp/components/account/image-chooser', ['exports', 'webapp/mixins/account/media-edit'], function (exports, _mediaEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function calcPath(item) {
    var path = item.name + '.' + (item.generatedExtension || item.extension);
    if (item.directory) {
      path = item.directory + '/' + path;
    }
    return path;
  }

  exports.default = Ember.Component.extend(_mediaEdit.default, {
    showAddImageModal: false,

    actions: {
      addImage: function addImage() {
        this.set('showAddImageModal', true);
      },
      cancelAddImage: function cancelAddImage() {
        this.set('showAddImageModal', false);
      },
      confirmAddImage: function confirmAddImage(image) {
        this.set('image', {
          path: calcPath(image),
          mediaId: image._id,
          description: Ember.get(image, 'infos.description') || '',
          copyright: Ember.get(image, 'infos.copyright') || ''
        });

        this.set('showAddImageModal', false);
      },
      removeImage: function removeImage() {
        this.set('image', null);
      }
    }
  });
});
define('webapp/components/jobs/application-notes', ['exports', 'webapp/utils/object-utils'], function (exports, _objectUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _objectWithoutProperties(obj, keys) {
    var target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    i18n: Ember.inject.service(),
    itemStorage: Ember.computed.alias('myStorage.jobApplicationAdmin'),

    didReceiveAttrs: function didReceiveAttrs() {
      // set local item to a clone, so the local changes are not reflected in the list
      var item = this.get('originalItem');
      this.set('item', _extends({}, item));
    },
    insertNewNote: function insertNewNote() {
      var newNote = this.get('newNote');
      if (newNote && newNote.text.trim()) {
        this.set('item.notes', [].concat(_toConsumableArray(this.get('item.notes') || []), [_extends({}, newNote, {
          createdAt: new Date().toISOString()
        })]));
      }
    },
    updateApplication: function updateApplication() {
      var _this = this;

      this.insertNewNote();

      var item = this.get('item');
      var itemStorage = this.get('itemStorage');
      var i18n = this.get('i18n');

      var _id = item._id,
          data = _objectWithoutProperties(item, ['_id']);

      var update = (0, _objectUtils.getChanges)(data, this.get('originalItem'));
      if (Object.keys(update).length === 0) {
        console.info('no changes');
        return;
      }

      return itemStorage.ajaxPut({
        param: _id,
        data: update
      }).then(function () {
        _this.get('message').info(i18n.t('dialog.saved.status').toString());
        var originalItem = _this.get('originalItem');
        Ember.setProperties(originalItem, update);
        // set local item to a clone, so the local changes are not reflected in the list
        _this.set('item', _extends({}, originalItem));
        _this.set('newNote', null);
      }).catch(function (err) {
        _this.get('message').error(err.message);
        // setProperties(item, snapShotBefore);
      });
    },


    actions: {
      save: function save() {
        this.updateApplication();
      },
      addNote: function addNote() {
        this.insertNewNote();
        var userProxy = this.get('userProxy');

        var newNote = {
          type: 'note',
          createdAt: new Date().toISOString(),
          user: Ember.getProperties(userProxy, ['_id', 'firstName', 'lastName']),
          text: ''
        };

        this.set('newNote', newNote);
      }
    }
  });
});
define('webapp/components/widgets/widget-account-banner', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userProxy: Ember.inject.service(),
    viewService: Ember.inject.service(),
    router: Ember.inject.service(),
    profileContainer: Ember.inject.service(),
    widgetInteractions: Ember.inject.service(),
    templateSettings: Ember.inject.service(),

    shownImage: Ember.computed('userProxy.banner', 'router.currentRouteName', 'profileContainer.detail.banner', 'viewService.editedEntry.mainImage', 'widgetInteractions.changeCounter', function () {
      var currentRouteName = this.get('router.currentRouteName');
      if (currentRouteName === 'account.entries.edit') {
        return this.get('viewService.editedEntry.mainImage');
      } else if (currentRouteName.split('.')[0] === 'account') {
        return this.get('userProxy.banner');
      } else {
        return this.get('profileContainer.detail.banner');
      }
    }),

    canEditImage: Ember.computed('router.currentRouteName', 'profileContainer.detail.userName', 'profileContainer.showEdit', 'templateSettings.currentPageType', function () {
      var currentRouteName = this.get('router.currentRouteName');
      var userName = this.get('profileContainer.detail.userName');
      var currentPageType = this.get('templateSettings.currentPageType');
      var showEdit = this.get('profileContainer.showEdit');

      return currentRouteName === 'account.profile.index' || currentRouteName === 'account.entries.edit' || currentRouteName === 'page' && currentPageType === 'profileContainer' && showEdit && userName === this.get('userProxy.userName');
    })
  });
});
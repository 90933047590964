define('webapp/routes/account/ratings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var endPoints = {
    entry: 'entryAdmin',
    event: 'eventAdmin',
    poi: 'poiAdmin'
  };

  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('expandedItem', null);
    },
    fetchItems: function fetchItems(type) {
      var myStorage = this.get('myStorage');
      var userId = this.get('userProxy._id');

      return myStorage[endPoints[type]].ajaxPost({
        param: 'search',
        query: { showRatings: true },
        data: { filter: { owner: userId } }
      }).then(function (res) {
        return res.model.map(function (item) {
          return Ember.assign({}, item, { type: type });
        });
      });
    },
    model: function model() {
      var _this = this;

      return Ember.RSVP.all(Object.keys(endPoints).map(function (key) {
        return _this.fetchItems(key);
      })).then(function (groups) {
        var items = [];
        groups.forEach(function (elem) {
          items = items.concat(elem);
        });
        return items;
      });
    }
  });
});
define('webapp/components/file-upload-old', ['exports', 'webapp/config/environment', 'webapp/settings/media-extensions'], function (exports, _environment, _mediaExtensions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // convert FileList Object to array
  function getFilesInfo(fileList) {
    if (fileList) {
      var ret = [];
      for (var i = 0; i < fileList.length; i++) {
        ret.push(fileList[i]);
      }
      return ret;
    } else {
      return [];
    }
  }

  exports.default = Ember.Component.extend({
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    uploadPath: 'upload',
    classNames: ['upload-drop'],

    allowedExtensions: [].concat(_mediaExtensions.default.IMAGE_EXTENSIONS, _mediaExtensions.default.VIDEO_EXTENSIONS, _mediaExtensions.default.DOCUMENT_EXTENSIONS, _mediaExtensions.default.AUDIO_EXTENSIONS),

    _updateUploadStatus: function _updateUploadStatus(status) {
      this._uploadStatus = status;
      this.sendAction('updateStatus', status);
      // console.log(JSON.stringify(status, null, "  "));
    },


    didRender: function didRender() {
      var host = void 0;
      if (this.get('isUser')) {
        host = _environment.default.apiEndpoints.userImage;
      } else {
        host = _environment.default.apiEndpoints.mediaAdmin;
      }

      var currentPath = this.get('currentPath');
      var uploadPath = this.get('uploadPath');

      var path = host + '/' + uploadPath;
      if (currentPath) {
        //admin can upload to any path
        path += "?path=" + encodeURIComponent(currentPath);
      }

      var isInitialized = this.get('_isInitialized');
      this.set('uploadedFilePath', currentPath);

      if (isInitialized) {
        this._changeUploadPath(path);
      } else {
        // this._createUploadElement(path);
      }
    },

    _changeUploadPath: function _changeUploadPath(uploadPath) {
      var _getProperties = this.getProperties('_uikitSelect', '_uikitDrop'),
          _uikitSelect = _getProperties._uikitSelect,
          _uikitDrop = _getProperties._uikitDrop;

      _uikitSelect.options.action = uploadPath;
      _uikitDrop.options.action = uploadPath;
    },

    _reset: function _reset() {
      this.setProperties({
        mediaIds: []
      });
    },


    _createUploadElement: function _createUploadElement(uploadPath) {
      var _this = this;

      var message = this.get('message');
      var allowedExtensions = this.get('allowedExtensions');
      var _uploadInput = this.element.querySelector('.upload-select');

      // const token;
      // if (this.storage.user.isSignedIn) token = this.storage.user.user.get('token');

      var settings = {

        action: uploadPath, // upload url

        allow: '*.(' + allowedExtensions.join('|') + ')',

        beforeAll: function beforeAll(files) {
          _this._updateUploadStatus({
            isUploading: true,
            currentIdx: -1,
            files: getFilesInfo(files).map(function (x) {
              return { name: x.name, progress: 0 };
            }),
            allComplete: false,
            uploadedFiles: []
          });
        },

        loadstart: function loadstart() {
          console.time('load');
          _this._updateUploadStatus(Ember.assign({}, _this._uploadStatus, {
            currentIdx: _this._uploadStatus.currentIdx + 1
          }));
        },

        complete: function complete(response) {
          console.timeEnd('load');
          response = response && JSON.parse(response);
          // console.log('complete', response);
        },

        progress: function progress(percent) {
          var status = Ember.assign({}, _this._uploadStatus);
          var currentIdx = status.currentIdx;
          status.files = status.files.map(function (x) {
            return Ember.assign({}, x);
          });
          status.files[currentIdx].progress = percent;

          _this._updateUploadStatus(status);
          _this.sendAction('progressChanged', percent);
        },

        allcomplete: function allcomplete(response, xhr) {
          response = response && JSON.parse(response);
          _this._updateUploadStatus({
            isUploading: false,
            progress: 100,
            allComplete: true
          });
          _this.sendAction('uploadCompleted', response);

          if (xhr.status === 200) {
            message.info("Upload erfolgreich");
          } else {
            if (xhr.status === 409) {
              message.error("Upload fehlgeschlagen. Datei existiert bereits");
            } else {
              message.error("Upload fehlgeschlagen " + (response && JSON.stringify(response)));
            }
          }
        },

        error: function error(response) {
          this._updateUploadStatus({ isUploading: false, error: true });
          message.error("Upload fehlgeschlagen");
        },

        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader("token", _this.get('userProxy.token'));
        }
      };

      var _uikitSelect = UIkit.uploadSelect(_uploadInput, settings);
      var _uikitDrop = UIkit.uploadDrop(this.element, settings);

      this.setProperties({
        _isInitialized: true,
        _uikitSelect: _uikitSelect,
        _uikitDrop: _uikitDrop,
        _uploadInput: _uploadInput
      });
    }
  });
});
define('webapp/components/widgets/widget-breadcrumbs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    searchService: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    isSearch: Ember.computed.oneWay('searchService.isSearchRoute'),
    isSubSearch: Ember.computed.oneWay('searchService.isSubSearch'),
    searchRoute: Ember.computed.oneWay('cmsService.dynamicPageRoutes.search'),
    breadcrumbService: Ember.inject.service(),
    expandBreadcrumbs: false,

    breadcrumbs: Ember.computed.oneWay('breadcrumbService.breadcrumbs'),

    actions: {
      mobileExpand: function mobileExpand() {
        this.toggleProperty('expandBreadcrumbs');
      }
    }
  });
});
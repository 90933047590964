define('webapp/components/lazy-loader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fastboot: Ember.inject.service(),
    showContent: false,
    offsetLimit: 200,

    init: function init() {
      this._super.apply(this, arguments);
      var isFastboot = this.get('fastboot.isFastBoot');
      this.set('showContent', isFastboot);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._scrollListener = function (e) {
        var top = _this.element.getBoundingClientRect().top - window.innerHeight;

        if (!_this.showContent && top < _this.offsetLimit) {
          _this.set('showContent', true);
        }
      };
      window.addEventListener('scroll', this._scrollListener);
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('scroll', this._scrollListener);
    }
  });
});
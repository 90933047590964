define('webapp/utils/image-watermark', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (medium) {
    var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1024;
    var timestamp = arguments[2];

    if (!medium) {
      return;
    }

    var id = medium.mediaId ? medium.mediaId._id || medium.mediaId : medium._id;
    var path = medium.path;
    var ext = path.slice(path.lastIndexOf('.'));

    var endpoint = _environment.default.apiUrl + '/static/md';
    var targetPath = id + '_' + size + ext;
    if (timestamp) {
      targetPath += '?timestamp=' + timestamp;
    }

    return endpoint + '/' + targetPath;
  };
});
define('webapp/components/shopping/shopping-cart-content', ['exports', 'webapp/utils/product-utils', 'webapp/utils/price-calculations'], function (exports, _productUtils, _priceCalculations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),
    router: Ember.inject.service(),
    message: Ember.inject.service(),
    shoppingCart: Ember.computed.alias('shoppingCartService.shoppingCart'),

    init: function init() {
      this._super.apply(this, arguments);

      var userProxy = this.get('userProxy');
      if (userProxy.impersonatedBy) {
        this._setupForAdditionalDetails();
        this.set('offerMeta', {
          canExpire: true,
          otherExpirationDate: false,
          expiresAt: undefined
        });
      }
    },
    _setupForAdditionalDetails: function _setupForAdditionalDetails() {
      var _this = this;

      var param = 'tax_rate';
      var userProxy = this.get('userProxy');
      var myStorage = this.get('myStorage');
      var customerCountryCode = userProxy.getCustomerCountryCode();
      if (typeof customerCountryCode === 'string') {
        param = param + '/' + customerCountryCode;
      } else {
        param = param + '/default';
      }

      return myStorage.country.ajaxGet({ param: param }).then(function (taxData) {
        _this.set('taxData', taxData);
      });
    },


    items: Ember.computed('shoppingCart.items', function () {
      var rawItems = this.get('shoppingCart.items');
      if (!rawItems) {
        return;
      }

      return rawItems.map(function (item) {
        var product = item.ref;
        return _extends({}, item, {
          variationFields: product.fullFields && product.fullFields.filter(function (x) {
            return x.isVariationProperty;
          }).map(function (fieldDesc) {
            var unit = (0, _productUtils.getUnit)(product, (0, _productUtils.getPlaceholderField)(fieldDesc));
            return _extends({}, fieldDesc, {
              unit: unit || fieldDesc.options && fieldDesc.options.unit
            });
          })
        });
      });
    }),

    _updateItemUnitPrice: function _updateItemUnitPrice(index, prices) {
      var shoppingCartService = this.get('shoppingCartService');
      var oldShoppingCart = this.get('shoppingCart');
      var origItems = this.get('shoppingCart.items');
      var items = [];

      var i = 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = origItems[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;

          if (i === index) {
            item = Object.assign(item, prices);
          }
          items.push(item);
          ++i;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      shoppingCartService.updateShoppingCart(_extends({}, oldShoppingCart, { items: items }));
    },
    _updatedUnitPriceNet: function _updatedUnitPriceNet(args) {
      var unitPriceNet = args[0];
      var index = parseInt(args[1]);
      var origItems = this.get('shoppingCart.items');
      var item = origItems[index];
      var suggestedUnitPriceNet = item.suggestedUnitPriceNet;
      var rawTaxRate = item.ref.taxRate;
      var taxRate = (0, _priceCalculations.calcFinalTaxes)(rawTaxRate, 10);
      var unitPriceGross = (0, _priceCalculations.calcGrossPrice)(unitPriceNet, taxRate);
      var hasOwnPrice = suggestedUnitPriceNet !== unitPriceNet;

      this._updateItemUnitPrice(index, {
        unitPriceGross: unitPriceGross,
        unitPriceNet: unitPriceNet,
        hasOwnPrice: hasOwnPrice,
        totalGross: item.quantity * unitPriceGross,
        totalNet: item.quantity * unitPriceNet
      });
    },
    _updatedUnitPriceGross: function _updatedUnitPriceGross(args) {
      var unitPriceGross = args[0];
      var index = parseInt(args[1]);
      var origItems = this.get('shoppingCart.items');
      var item = origItems[index];
      var suggestedUnitPriceGross = item.suggestedUnitPriceGross;
      var rawTaxRate = item.ref.taxRate;
      var taxRate = (0, _priceCalculations.calcFinalTaxes)(rawTaxRate, 10);
      var unitPriceNet = (0, _priceCalculations.calcNetPrice)(unitPriceGross, taxRate);
      var hasOwnPrice = suggestedUnitPriceGross !== unitPriceGross;

      this._updateItemUnitPrice(index, {
        unitPriceGross: unitPriceGross,
        unitPriceNet: unitPriceNet,
        hasOwnPrice: hasOwnPrice,
        totalGross: item.quantity * unitPriceGross,
        totalNet: item.quantity * unitPriceNet
      });
    },
    _cleanupAfterSuccessfulTurnIntoOffer: function _cleanupAfterSuccessfulTurnIntoOffer() {
      var shoppingCartService = this.get('shoppingCartService');

      shoppingCartService.fetchShoppingCart();
      this.set('additionalDetails', []);
      this.set('offerMeta', {
        canExpire: true,
        otherExpirationDate: false,
        expiresAt: undefined
      });
    },
    _clearShoppingCart: function _clearShoppingCart() {
      var shoppingCartService = this.get('shoppingCartService');

      if (shoppingCartService) {
        shoppingCartService.clearShoppingCart().then(function () {
          shoppingCartService.reset();
          shoppingCartService.fetchShoppingCart();
        });
      }
    },


    actions: {
      removeFromShoppingCart: function removeFromShoppingCart(itemId) {
        this.get('shoppingCartService').removeFromShoppingCart(itemId);
      },
      changeItemQuantityEvent: function changeItemQuantityEvent(itemId, event) {
        this.get('shoppingCartService').changeItemQuantity(itemId, Number(event.target.value));
      },
      changeItemQuantity: function changeItemQuantity(itemId, quantity) {
        var shoppingCartService = this.get('shoppingCartService');
        if (quantity <= 0) {
          shoppingCartService.removeFromShoppingCart(itemId);
        } else {
          shoppingCartService.changeItemQuantity(itemId, Number(quantity));
        }
      },
      checkout: function checkout() {
        this.get('router').transitionTo('checkout.delivery');
      },
      addAdditionalDetails: function addAdditionalDetails() {
        var additionalDetails = this.get('additionalDetails');
        var additionalDetail = {
          itemType: '',
          item: undefined,

          isCalculated: false,
          percentage: 0,

          quantity: 1,

          description: '',
          taxRate: 0,
          unitPriceGross: 0,
          unitPriceNet: 0,
          totalGross: 0,
          totalNet: 0
        };

        this.set('additionalDetails', [].concat(_toConsumableArray(additionalDetails), [additionalDetail]));
      },
      removeAdditionalDetails: function removeAdditionalDetails(i) {
        var additionalDetails = this.get('additionalDetails');
        var newAdditionalDetails = [];
        for (var j = 0; j < additionalDetails.length; j++) {
          if (i !== j) {
            newAdditionalDetails.push(additionalDetails[j]);
          }
        }
        this.set('additionalDetails', newAdditionalDetails);
      },
      turnIntoOffer: function turnIntoOffer() {
        var _this2 = this;

        var router = this.get('router');
        var message = this.get('message');
        var additionalDetails = this.get('additionalDetails');
        this.set('shoppingCart.additionalDetails', additionalDetails);
        var shoppingCartService = this.get('shoppingCartService');
        var canExpire = this.get('offerMeta.canExpire');
        var otherExpirationDate = this.get('offerMeta.otherExpirationDate');
        var expiresAt = this.get('offerMeta.expiresAt');

        if (canExpire && otherExpirationDate && expiresAt) {
          this.set('shoppingCart.canExpire', canExpire);
          this.set('shoppingCart.expiresAt', expiresAt);
        }

        shoppingCartService.turnIntoOffer().then(function () {
          _this2._cleanupAfterSuccessfulTurnIntoOffer();
          message.infoT('offer.ui.offerSent');
          _this2._clearShoppingCart();
          if (router) {
            router.transitionTo('/');
          }
        });
      },
      clearShoppingCart: function clearShoppingCart() {
        this._clearShoppingCart();
      },
      updatedUnitPriceNet: function updatedUnitPriceNet(value, $e) {
        var index = $e.getAttribute('attr-data');
        Ember.run.debounce(this, this._updatedUnitPriceNet, [value, index], 500);
      },
      updatedUnitPriceGross: function updatedUnitPriceGross(value, $e) {
        var index = $e.getAttribute('attr-data');
        Ember.run.debounce(this, this._updatedUnitPriceGross, [value, index], 500);
      },
      removeAdditionalDetailFromShoppingCart: function removeAdditionalDetailFromShoppingCart(i) {
        var oldShoppingCart = this.get('shoppingCart');
        var shoppingCartService = this.get('shoppingCartService');
        var additionalDetails = Ember.get(oldShoppingCart, 'additionalDetails');
        var newAdditionalDetails = [];
        for (var j = 0; j < additionalDetails.length; j++) {
          if (i !== j) {
            newAdditionalDetails.push(additionalDetails[j]);
          }
        }
        Ember.set(oldShoppingCart, 'additionalDetails', newAdditionalDetails);
        shoppingCartService.updateShoppingCart(oldShoppingCart);
      }
    }
  });
});
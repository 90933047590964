define('webapp/components/widgets/widget-product', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    languageService: Ember.inject.service(),

    language: Ember.computed('languageService.languageSlug', function () {
      var langSlug = this.get('languageService.languageSlug');

      if (langSlug) {
        return langSlug;
      } else {
        return 'de';
      }
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var id = this.get('data.options.productId');
      var myStorage = this.get('myStorage');
      var lang = this.get('language');

      if (id) {
        myStorage.product.ajaxGet({ param: 'id/' + id, query: { lang: lang } }).then(function (res) {
          _this.set('item', res);
        });
      }
    }
  });
});
define('webapp/routes/account/jobs/edit', ['exports', 'webapp/mixins/edit-route', 'webapp/utils/slugify', 'webapp/config/environment', 'webapp/utils/tree-utils'], function (exports, _editRoute, _slugify, _environment, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _editRoute.default.extend({
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    widgetInteractions: Ember.inject.service(),
    itemStorage: Ember.computed.alias('myStorage.jobAdmin'),
    modelName: 'Job',

    defaultItem: function defaultItem(item) {
      item.categories = item.categories || [];
      item.tags = item.tags || [];
      item.images = item.images || [];
      item.visibleForGroups = item.visibleForGroups || [];
      item.roles = item.roles || [];
      item.translations = item.translations || {};
      item.entries = item.entries || [];

      return item;
    },
    onBeforeUnload: function onBeforeUnload(e) {
      if (this.modelIsDirty()) {
        var message = this.get('message');
        var messageString = 'Ungespeicherte Änderungen.';
        message.info(messageString);
        e.returnValue = messageString;
        return messageString;
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.onBeforeUnload = this.onBeforeUnload.bind(this);
    },
    activate: function activate() {
      this.onBeforeUnload = this.onBeforeUnload.bind(this);

      if (_environment.default.dirtyModelCheck) {
        window.addEventListener('beforeunload', this.onBeforeUnload);
      }

      return this._super.apply(this, arguments);
    },
    deactivate: function deactivate() {
      if (_environment.default.dirtyModelCheck) {
        window.removeEventListener('beforeunload', this.onBeforeUnload);
      }

      return this._super.apply(this, arguments);
    },
    serializeItem: function serializeItem(item) {
      item = JSON.parse(JSON.stringify(item));

      var slugPrefix = _environment.default.jobSlugPrefix;

      if (item.entries && item.entries[0] && item.entries[0].item) {
        var firstEntry = item.entries[0].item;
        var availableCategories = this.controller.get('availableCategories');
        var regionCategories = (0, _treeUtils.flattenTree)(availableCategories).filter(function (x) {
          return x.type === 'region';
        });
        var regionCategoryIds = regionCategories.map(function (x) {
          return x._id;
        });
        var itemRegionCategories = firstEntry.categories.filter(function (x) {
          return regionCategoryIds.includes(x);
        });

        item.geoPoint = firstEntry.geoPoint;

        var jobCategories = [].concat(_toConsumableArray(item.categories.filter(function (x) {
          return !regionCategoryIds.includes(x);
        })), _toConsumableArray(itemRegionCategories));
        item.categories = jobCategories;
      }

      if (slugPrefix) {
        var replaceRe = new RegExp('^' + slugPrefix + '-');
        var entrySlugs = (item.entries || []).filter(function (x) {
          return x.item;
        }).map(function (entry) {
          return entry.item.slug.replace(replaceRe, '');
        }).sort();

        var originalSlug = item.slug;
        var idMatch = originalSlug.match(/-([a-z0-9]{6})$/);
        var slugId = idMatch && idMatch[1];

        var slugParts = [(0, _slugify.default)(item.title)];

        if (entrySlugs.length) {
          slugParts.push(slugPrefix);
          slugParts.push.apply(slugParts, _toConsumableArray(entrySlugs));
        }

        if (slugId) {
          slugParts.push(slugId);
        }
        item.slug = slugParts.join('-');
      }

      delete item._id;
      return item;
    },


    // overridden from EditRoute
    beforeModel: function beforeModel() {
      var _this = this;

      var cachedData = this.get('cachedData');
      var userProxy = this.get('userProxy');

      var controller = this.controllerFor(this.routeName);
      return Ember.RSVP.all([cachedData.getCategories(), userProxy.fetchOwnEntries()]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            cats = _ref2[0],
            entries = _ref2[1];

        _this.set('availableCategories', cats);
        controller.set('availableCategories', cats);
        controller.set('ownEntries', entries);
      });
    }
  });
});
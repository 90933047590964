define('webapp/components/widgets/widget-promotion', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    languageService: Ember.inject.service(),
    countService: Ember.inject.service(),
    promotionUrls: Ember.computed.oneWay('data.options.promotionUrls'),
    searchOptions: Ember.computed.oneWay('data.options.searchOptions'),

    init: function init() {
      this._super.apply(this, arguments);
      this.showPromotions();
    },


    _obsProms: Ember.observer('promotionUrls', 'searchOptions', function () {
      this.showPromotions();
    }),

    _parseSearchOptions: function _parseSearchOptions() {
      var searchOptions = (this.get('searchOptions') || '').trim();

      if (searchOptions) {
        try {
          return JSON.parse(searchOptions);
        } catch (err) {
          // console.log('invalid JSON in widget-promation');
          // console.log(searchOptions);
          return {};
        }
      } else {
        return {};
      }
    },
    showPromotions: function showPromotions() {
      var _this = this;

      var myStorage = this.get('myStorage');
      var promotionUrls = this.get('promotionUrls') || [];

      var defaultSearchOptions = { size: 30, showSocialCounts: true };
      var searchOptions = Ember.assign(defaultSearchOptions, this._parseSearchOptions());

      if (promotionUrls && promotionUrls.length) {
        searchOptions.slugFilter = promotionUrls;
      }

      myStorage.search.ajaxPost({
        data: searchOptions,
        query: { lang: this.get('languageService.selectedLanguage') }
      }).then(function (res) {
        if (!_this.get('isDestroyed')) {
          var orderedHits = res.hits;
          if (!searchOptions.sort && promotionUrls.length) {
            orderedHits = promotionUrls.map(function (slug) {
              return res.hits.find(function (hit) {
                return hit.slug === slug;
              });
            }).filter(Boolean);
          }
          _this.set('allResults', orderedHits);
        }
      });
    }
  });
});
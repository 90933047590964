define('webapp/components/comment-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',

    isHighlighted: Ember.computed('comment._id', 'postContainer.highlightedComment', function () {
      return this.get('comment._id') === this.get('postContainer.highlightedComment');
    }),
    level: Ember.computed('nestLevel', function () {
      return this.get('nestLevel') - 1;
    }),

    didInsertElement: function didInsertElement() {
      var postContainer = this.get('postContainer');
      if (postContainer) {
        postContainer.registerComment(this.get('comment._id'), this);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var postContainer = this.get('postContainer');
      if (postContainer) {
        postContainer.unregisterComment(this.get('comment._id'));
      }
    },

    actions: {
      toggleShowMore: function toggleShowMore() {
        var showMore = this.get("showMore");
        this.set("showMore", !showMore);
      }
    }
  });
});
define('webapp/components/shopping/shopping-order', ['exports', 'webapp/utils/inject-script'], function (exports, _injectScript) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    shoppingCartService: Ember.inject.service(),
    languageService: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    paymentService: Ember.inject.service(),
    shoppingCart: Ember.computed.alias('shoppingCartService.shoppingCart'),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    router: Ember.inject.service(),
    i18n: Ember.inject.service(),

    isSepa: Ember.computed('shoppingCart.paymentMethod', function () {
      var paymentMethod = this.get('shoppingCart.paymentMethod');
      return paymentMethod === 'sepa_debit';
    }),

    isSofort: Ember.computed('shoppingCart.paymentMethod', function () {
      var paymentMethod = this.get('shoppingCart.paymentMethod');
      return paymentMethod === 'sofort';
    }),

    isPaymentInOrder: Ember.computed('shoppingCart.paymentMethod', function () {
      var paymentMethod = this.get('shoppingCart.paymentMethod');
      return ['sofort', 'sepa_debit'].includes(paymentMethod);
    }),

    hasFailedRedirectStatus: Ember.computed('redirectStatus', function () {
      var redirectStatus = this.get('redirectStatus');
      return redirectStatus === 'failed';
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var paymentIntentClientSecret = this.get('paymentIntentClientSecret');
      if (paymentIntentClientSecret) {
        (0, _injectScript.default)('https://js.stripe.com/v3/').then(function () {
          if (window.Stripe) {
            _this.setupStripePaymentIntentCheck();
          }
        });
      } else {
        this.enableButton();
      }
    },
    setupStripePaymentIntentCheck: function setupStripePaymentIntentCheck() {
      var _this2 = this;

      var paymentIntent = this.get('paymentIntent');
      var paymentService = this.get('paymentService');
      var message = this.get('message');

      paymentService.retrieveStripePaymentIntent('sofort', paymentIntent).then(function (data) {
        if (data.success) {
          _this2.commit();
        } else if (data.info) {
          _this2.set('redirectStatus', 'failed');
        } else if (data.error) {
          message.error(data.error);
        }
      });
    },
    enableButton: function enableButton() {
      var $e = window.document.getElementById('checkout-order-button');

      if ($e) {
        $e.disabled = false;
      }
    },
    commit: function commit() {
      var _this3 = this;

      var message = this.get('message');
      var shoppingCartService = this.get('shoppingCartService');
      var cmsService = this.get('cmsService');

      message.infoT('checkout.cart.orderCommitting');
      this.set('isCommitting', true);

      shoppingCartService.commit().then(function () {
        _this3.set('isCommitting', false);
        var languageService = _this3.get('languageService');
        message.infoT('checkout.cart.orderSuccess');
        var redirectTo = _this3.get('checkoutOptions.redirectTo');
        if (redirectTo) {
          cmsService.redirectToPageById(redirectTo);
        } else {
          languageService.transitionToHome();
        }
      }).catch(function (err) {
        _this3.set('isCommitting', false);
        message.error(err.payload.message);
      });
    },


    actions: {
      commit: function commit() {
        this.commit();
      }
    }
  });
});
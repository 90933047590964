define('webapp/helpers/format-percent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var value = params[0];
    return Math.round(100 * value);
  });
});
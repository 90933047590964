define('webapp/components/content-builder-view/elements/image', ['exports', 'webapp/components/content-builder-view/elements/empty'], function (exports, _empty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _empty.default.extend({
    showOverlay: Ember.computed('options.overlay', 'options.imageTitle', 'options.imageDescription', 'options.imageCopyright', 'options.link', function () {
      return !!(this.get('options.overlay') && (this.get('options.imageTitle') || this.get('options.imageDescription') || this.get('options.imageCopyright') || this.get('options.link')));
    }),

    classNameBindings: ['showOverlayWrapperClass', 'data.options.presetClass'],

    showOverlayWrapperClass: Ember.computed('showOverlay', function () {
      return this.get('showOverlay') ? 'uk-overlay' : '';
    }),

    // image-data element has imagePath mapped to options.imagePath
    imagePath: Ember.computed('options.image.path', 'options.imagePath', function () {
      return Ember.get(this, 'options.image.path') || Ember.get(this, 'options.imagePath');
    })
  });
});
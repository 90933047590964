define('webapp/services/entry-container', ['exports', 'webapp/services/abstract-container'], function (exports, _abstractContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractContainer.default.extend({
    containerName: 'entryContainer',

    mapItem: function mapItem(item) {
      item.descriptions = item.descriptions && item.descriptions.map(function (desc) {
        if (desc.galleryRef) {
          desc.gallery = item.galleries && item.galleries.find(function (gal) {
            return gal.internalId === desc.galleryRef;
          });
        }
        return desc;
      });
      return item;
    },
    fetchDetail: function fetchDetail(detailSlug) {
      var _this = this;

      var myStorage = this.get('myStorage');

      return myStorage.entry.ajaxGet({
        param: 'entryslug/' + detailSlug,
        query: { lang: this.get('languageService.selectedLanguage') }
      }).then(function (res) {
        return _this.mapItem(res);
      });
    },
    getTitleToken: function getTitleToken() {
      return this.get('detail.title');
    }
  });
});
define('webapp/components/content-builder-view/containers/list', ['exports', 'webapp/components/content-builder-view/containers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    classNameBindings: ['listClass', 'decorationClass', 'typeClass', 'largeClass'],

    tagName: 'ul',

    decorationClass: Ember.computed('options.listDecoration', function () {
      var decoration = this.get('options.listDecoration');
      return 'uk-list-' + decoration;
    }),

    typeClass: Ember.computed('options.listType', function () {
      var listType = this.get('options.listType');
      return 'uk-list-' + listType;
    }),

    largeClass: Ember.computed('options.listSpace', function () {
      if (this.get('options.listSpace')) {
        return 'uk-list-space';
      }
    }),

    nestedElementsGrid: Ember.computed('options.nestedElementsInGrid', function () {
      if (this.get('options.nestedElementsInGrid')) {
        var nestedElementsGrid = 'uk-grid';
        return nestedElementsGrid;
      }
    }),

    listClass: "uk-list"
  });
});
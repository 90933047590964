define('webapp/helpers/format-number-fixed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.helper(function (_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
        value = _ref3[0];

    var _ref2$precision = _ref2.precision,
        precision = _ref2$precision === undefined ? 2 : _ref2$precision,
        _ref2$locale = _ref2.locale,
        locale = _ref2$locale === undefined ? 'de-DE' : _ref2$locale;

    var multiplier = Math.pow(10, precision);

    if (value === undefined) {
      value = 0;
    }

    return new Intl.NumberFormat(locale, {
      maximumFractionDigits: precision,
      minimumFractionDigits: precision
    }).format(Number(value) / multiplier);
  });
});
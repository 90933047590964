define('webapp/components/widgets/widget-region-welcome', ['exports', 'webapp/utils/find-by'], function (exports, _findBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    region: Ember.computed.alias('pageModel.region'),

    primaryPerson: Ember.computed('region.people', function () {
      var people = this.get('pageModel.region.people') || [];
      return (0, _findBy.default)(people, 'isPrimary', true);
    })

  });
});
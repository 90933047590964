define('webapp/services/social-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var callbacks = [];

  function addScript(src, cb) {
    if (callbacks.length === 0) {
      callbacks.push(cb);
      var s = document.createElement('script');
      s.setAttribute('src', src);
      s.onload = function () {
        return callbacks.forEach(function (cb) {
          return cb();
        });
      };
      document.body.appendChild(s);
    } else {
      callbacks.push(cb);
    }
  }

  exports.default = Ember.Service.extend({
    initTwitter: function initTwitter() {
      var renderTweet = function renderTweet() {
        window.twttr.ready().then(function (_ref) {
          var widgets = _ref.widgets;

          widgets.load();
        });
      };
      if (!window.twttr) {
        var isLocal = window.location.protocol.indexOf('file') >= 0;
        var protocol = isLocal ? this.protocol : '';
        addScript(protocol + '//platform.twitter.com/widgets.js', function () {});
      } else {
        renderTweet();
      }
    }
  });
});
define('webapp/components/account/skill-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    addModalShown: false,
    editedSkill: null, // tmp data for adding skill
    isLoading: true,

    loadSkills: function loadSkills() {
      var myStorage = this.get('myStorage');
      return myStorage.skill.ajaxGet({ param: 'own/' + this.get('skillType') });
    },
    loadEntries: function loadEntries() {
      var userProxy = this.get('userProxy');
      return userProxy.fetchOwnEntries();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.RSVP.hash({
        skills: this.loadSkills(),
        entries: this.loadEntries()
      }).then(function (_ref) {
        var skills = _ref.skills,
            entries = _ref.entries;

        _this.setProperties({ skills: skills, entries: entries, isLoading: false });
      });
    },
    reloadSkills: function reloadSkills() {
      var _this2 = this;

      this.loadSkills().then(function (skills) {
        return _this2.set('skills', skills);
      });
    },


    actions: {
      addSkill: function addSkill() {
        var _this3 = this;

        var editedSkill = this.get('editedSkill');
        var myStorage = this.get('myStorage');
        myStorage.skill.ajaxPost({ param: 'own', data: editedSkill }).then(function () {
          _this3.reloadSkills();
          _this3.set('addModalShown', false);
        });
      },
      removeSkill: function removeSkill(skill) {
        var _this4 = this;

        var myStorage = this.get('myStorage');
        myStorage.skill.ajaxDelete({ param: 'own/' + skill._id }).then(function () {
          _this4.reloadSkills();
        });
      },
      showAddModal: function showAddModal() {
        this.set('editedSkill', {
          description: '',
          tags: [],
          skillType: this.get('skillType')
        });
        this.set('addModalShown', true);
      },
      closeAddModal: function closeAddModal() {
        this.set('editedSkill', null);
        this.set('addModalShown', false);
      },
      selectEntry: function selectEntry(entryId) {
        this.set('editedSkill.item', entryId);
        this.set('editedSkill.itemType', 'Entry');
      }
    }
  });
});
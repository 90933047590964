define('webapp/components/shopping/shopping-navigation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userProxy: Ember.inject.service(),
    shoppingCartService: Ember.inject.service()
  });
});
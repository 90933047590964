define('webapp/helpers/file-preview', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var path = params[0];
    var extIndex = path.lastIndexOf('.');
    var baseName = path.slice(0, extIndex);

    return _environment.default.apiUrl + '/static/images/' + baseName + '_preview.jpg';
  });
});
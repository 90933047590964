define('webapp/components/account/category-selector-list', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    flatCategories: Ember.computed('availableCategories', 'selectedCategories.length', 'primaryCategory', function () {
      var availableCategories = this.get('availableCategories') || [];
      var selectedCategories = this.get('selectedCategories') || [];
      var primaryCategory = this.get('primaryCategory');

      var flatCategories = (0, _treeUtils.flattenTree)(availableCategories);

      return flatCategories.map(function (x) {
        return Ember.assign({}, x, {
          isSelected: selectedCategories.indexOf(x._id) >= 0,
          isPrimary: x._id === primaryCategory
        });
      });
    }),

    toggleCategory: function toggleCategory(cat) {
      var selectedCategories = this.get('selectedCategories');
      var catId = Ember.get(cat, '_id');

      if (selectedCategories.indexOf(catId) >= 0) {
        selectedCategories.removeObject(catId);
      } else {
        selectedCategories.pushObject(catId);
      }
    },


    actions: {
      toggleCategory: function toggleCategory(cat) {
        this.toggleCategory(cat);
      },
      togglePrimaryCategory: function togglePrimaryCategory(cat) {
        var selectedCategories = this.get('selectedCategories');
        var catId = Ember.get(cat, '_id');

        var primaryCategory = this.get('primaryCategory');
        if (catId === primaryCategory) {
          this.set('primaryCategory', null);
        } else {
          this.set('primaryCategory', catId);
        }

        if (selectedCategories.indexOf(catId) < 0) {
          selectedCategories.pushObject(catId);
        }
      }
    }
  });
});
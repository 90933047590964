define('webapp/components/account/opening-edit/value-select', ['exports', 'webapp/utils/find-by'], function (exports, _findBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var optionId = this.get('option.id');
      var selectedValue = this.get('entry')[optionId];
      this.set('selectedValue', { id: selectedValue });
    },


    actions: {
      valueChange: function valueChange(val) {
        var optionValues = this.get('option.values');
        var foundValue = (0, _findBy.default)(optionValues, 'id', val);
        var option = this.get('option');
        this.sendAction('action', foundValue, option);
      }
    }
  });
});
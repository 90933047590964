define('webapp/services/abstract-container', ['exports', 'webapp/utils/head-tags', 'webapp/utils/image-path'], function (exports, _headTags, _imagePath) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    detail: null,
    cmsService: Ember.inject.service(),
    router: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    languageService: Ember.inject.service(),
    fastboot: Ember.inject.service(),

    containerName: Ember.computed(function () {
      throw new Error('overrid me');
    }),

    /**
     * @abstract
     * @param detailSlug
     */
    fetchDetail: function fetchDetail(detailSlug) {
      throw new Error('implement me');
    },
    setupPage: function setupPage(pageRoute, page, params) {
      var _this = this;

      this.set('page', page);
      var languageService = this.get('languageService');
      var path = params.path;
      var translatedSlug = languageService.translateSlug(page, page.detectedLanguage);
      var detailSlug = path.slice(translatedSlug.length + 1);

      if (detailSlug) {
        return this.fetchDetail(detailSlug).then(function (detail) {
          _this.set('detail', detail);
          return detail;
        });
      } else {
        console.warn('no detail for ' + page.type + ' ' + path);
        this.set('detail', null);
        return Ember.RSVP.resolve();
      }
    },
    getBreadcrumbs: function getBreadcrumbs(baseBreadcrumbs) {
      var breadcrumbs = baseBreadcrumbs.slice();

      // make detail route not clickable
      var detailBC = breadcrumbs[breadcrumbs.length - 1];
      breadcrumbs[breadcrumbs.length - 1] = { title: detailBC.title };

      var detail = this.detail;
      breadcrumbs.push({
        route: 'page',
        title: detail.title,
        routeParams: this.getDetailRoute(detail.slug)
      });

      return breadcrumbs;
    },
    getDetailRoute: function getDetailRoute(detailSlug) {
      var message = this.get('message');
      var languageService = this.get('languageService');
      var dynamicPageRoutes = this.get('cmsService.fullDynamicPageRoutes');
      var containerName = this.get('containerName');
      var route = dynamicPageRoutes[containerName] && languageService.translateSlug(dynamicPageRoutes[containerName]);

      if (!route) {
        message.infoUnique('no container defined for ' + containerName);
      }

      if (detailSlug) {
        route += '/' + detailSlug;
      }
      return route;
    },
    getHeadTags: function getHeadTags() {
      var detail = this.get('detail');
      if (!detail) {
        return [];
      }
      var user = this.get('userProxy');
      var headTags = [];

      headTags.pushObject((0, _headTags.generateHeadTag)('twitter:card', 'summary'));
      if (user.twitter) {
        headTags.pushObject((0, _headTags.generateHeadTag)('twitter:creator', user.twitter));
      }

      headTags.pushObjects((0, _headTags.generateHeadTags)(['name', 'twitter:title', 'og:title'], detail.title));
      headTags.pushObjects((0, _headTags.generateHeadTags)(['description', 'twitter:description', 'og:description'], detail.metaDescription || detail.description));

      if (detail.mainImage) {
        headTags.pushObjects((0, _headTags.generateHeadTags)(['og:image', 'twitter:image'], (0, _imagePath.default)(detail.mainImage.path, 'medium')));
        headTags.pushObject((0, _headTags.generateHeadTag)('og:image:width', '1024'));
        headTags.pushObject((0, _headTags.generateHeadTag)('og:image:height', '768'));
      }

      if (detail.excludeFromRobots) {
        headTags.push({
          type: 'meta',
          tagId: 'noindex',
          attrs: {
            name: 'robots',
            content: 'noindex'
          }
        });
      }

      return headTags;
    }
  });
});
define('webapp/components/shopping/shopping-cart', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    shoppingCartService: Ember.inject.service(),
    promocodeService: Ember.inject.service(),
    router: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    shoppingCart: Ember.computed.alias('shoppingCartService.shoppingCart'),
    promocode: undefined,
    invalidPromocode: false,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('additionalDetails', []);
    },
    showError: function showError() {
      this.setProperties({
        invalidPromocode: true,
        minOrderValueNotSatisfied: false
      });
    },


    hasPromocode: Ember.computed('shoppingCart.additionalDetails', function () {
      var has = false;

      var additionalDetails = this.get('shoppingCart.additionalDetails');
      if (additionalDetails && additionalDetails.length >= 1) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = additionalDetails[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var additionalDetail = _step.value;

            if (additionalDetail.itemType === 'Promocode') {
              has = true;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      return has;
    }),

    cartIsEmpty: Ember.computed('shoppingCart.items', function () {
      var items = this.get('shoppingCart.items');
      if (items) {
        return items.length === 0;
      }

      return true;
    }),

    handleValidPromocode: function handleValidPromocode(promoCodeData) {
      var shoppingCartService = this.get('shoppingCartService');

      shoppingCartService.redeemPromocode(promoCodeData.promocode);
      this.setProperties({
        promocode: '',
        invalidPromocode: false,
        minOrderValueNotSatisfied: false
      });
    },


    actions: {
      checkout: function checkout() {
        this.get('router').transitionTo('checkout.delivery');
      },
      register: function register() {
        this.get('router').transitionTo('checkout.register');
      },
      redeemPromocode: function redeemPromocode() {
        var _this = this;

        var promocodeService = this.get('promocodeService');
        var promocode = this.get('promocode');

        if (promocode === undefined || promocode === '') {
          this.showError();
        } else {
          promocodeService.isValidPromocode(promocode).then(function (promoCodeData) {
            if (promoCodeData.isValid) {
              var totalGross = _this.get('shoppingCartService.shoppingCart.totalGross');
              var minOrderValue = promoCodeData.promocode.minOrderValue;

              if (!minOrderValue || totalGross >= minOrderValue) {
                _this.handleValidPromocode(promoCodeData);
              } else {
                _this.setProperties({
                  invalidPromocode: false,
                  minOrderValueNotSatisfied: true,
                  promoCodeMinValue: minOrderValue
                });
              }
            } else {
              _this.showError();
            }
          }).catch(function () {
            //window.console.log('error is ');
            //window.console.log(error);
            _this.showError();
          }).finally(function () {
            promocodeService._fetchPromocodeInfo();
          });
        }
      }
    }
  });
});
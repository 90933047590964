define('webapp/components/bsport-widget', ['exports', 'webapp/utils/inject-script'], function (exports, _injectScript) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var companyId = this.get('companyId');
      var privateGroup = Number(this.get('privateGroup')) || 3786;
      (0, _injectScript.default)('https://cdn.bsport.io/scripts/widget.js').then(function () {
        BsportWidget.mount({
          parentElement: 'bsport-widget-' + companyId,
          companyId: Number(companyId),
          franchiseId: null,
          dialogMode: 1,
          widgetType: 'privateService',
          language: 'en-GB',
          showFab: false,
          fullScreenPopup: false,
          styles: undefined,
          config: {
            privateService: { type: 'list', privateGroups: [privateGroup] }
          }
        });
      });
    }
  });
});
define("webapp/locales/fi/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.entryBannerImageNew": "Aseta pääkuva merkinnällesi.",
    "account.ui.profileBannerImageNew": "Aseta profiilin pääkuva.",
    "account.ui.register": "Rekisteröi",
    "account.ui.registeredAs": "Olet tällä hetkellä rekisteröitynyt",
    "account.ui.personate": "Käyttäjän identiteetin muuttaminen",
    "account.ui.userNameNotEditableTooltip": "Käyttäjätunnuksesi. Et voi muuttaa syöttämääsi käyttäjänimeä. Jos haluat muuttaa käyttäjätunnusta, ota yhteyttä yhteyshenkilöösi. Tärkeää: Muutoksen jälkeen sinun on kirjauduttava uudelleen uudella käyttäjänimellä.",
    "account.ui.additionalDeliveryAddresses": "Olet antanut eri toimitusosoitteet.",
    "account.ui.editDeliveryAddresses": "Muokkaa toimitusosoitetta",
    "account.ui.editInvoiceAddress": "Muokkaa yrityksen osoitetta",
    "account.ui.noDeliveryAddresses": "Et ole vielä antanut eri toimitusosoitetta.",
    "account.ui.addDeliveryAddresses": "Lisää {{further}} Lisää eri toimitusosoite",
    "account.ui.addDeliveryAddressTitle": "Lisää toimitusosoite",
    "account.ui.editDeliveryAddressTitle": "Muokkaa toimitusosoitetta",
    "account.ui.isDefaultAddress": "Tämä on vakiotoimitusosoite",
    "account.ui.saveToApplyChanges": "Sinun on tallennettava muutos, minkä jälkeen voit esimerkiksi määrittää eri toimitusosoitteet.",
    "accountTypes.aussteller": "Näytteilleasettaja",
    "accountTypes.gastgeber": "Isäntä",
    "accountTypes.veranstalter": "Järjestäjä",
    "application.beta.infotext": "Tilauksia ei ole luotu.",
    "application.breadcrumbsHome": "Etusivu",
    "application.month": "kuukaudessa",
    "application.newsletter.alreadySubscribed": "Olet jo tilannut {{list}} uutiskirjeelle. Voit tarkastella uutiskirjeen tilauksia tililläsi.",
    "application.newsletter.alreadySubscribedNoList": "Olet jo rekisteröitynyt uutiskirjeeseen",
    "application.newsletter.goToList": "Soita postituslistalle",
    "application.newsletter.noAccess": "Tarjottua uutiskirjettä ei voi tilata luvillasi.",
    "application.newsletter.subscribeButton": "Kirjoita sähköpostiosoitteesi tilataksesi uutiskirjeen.",
    "application.newsletter.subscribeButtonMobile": "Sähköposti",
    "application.newsletter.subscribe": "Tilaa",
    "application.newsletter.subscribeList": "Olet tilaamassa tätä uutiskirjettä:",
    "application.newsletter.subscribeThanks": "Kiitos, että tilasit uutiskirjeemme. Saat uusimman uutiskirjeemme pian.",
    "application.newsletter.subscribeThanksOptIn": "Kiitos, että tilasit uutiskirjeemme. Saat toisen vahvistussähköpostiviestin aktivointia varten.",
    "application.newsletter.subscribeWithAccount": "Tilaa nykyisellä kirjautuneella käyttäjälläsi.",
    "application.noCosts": "Maksuton",
    "application.placeholderImageText": "Ilman kuvitusta",
    "application.searchAllGroups": "kaikissa",
    "application.searchAllRegions": "joka paikassa",
    "application.ui.access": "pääsy",
    "application.ui.action": "Toiminta",
    "application.ui.addDocument": "Lisää asiakirja",
    "application.ui.addRelation": "Lisää yhteys",
    "application.ui.addImage": "Lisää kuva",
    "application.ui.addImages": "Lisää kuvia",
    "application.ui.addMedia": "Lisää kuvia/videoita",
    "application.ui.addVideo": "Lisää video",
    "application.ui.advancedEdit": "Laajennettu",
    "application.ui.all": "Kaikki",
    "application.ui.alreadyFavorite": "Tämä tuote on tarkkailulistallasi",
    "application.ui.makeFavorite": "Lisää tämä tuote tarkkailulistallesi",
    "application.ui.also": "Myös",
    "application.ui.alphabetical": "Aakkosjärjestys",
    "application.ui.and": "ja",
    "application.ui.at": "osoitteessa",
    "application.ui.in": "osoitteessa",
    "application.ui.atHour": "osoitteessa",
    "application.ui.hour": "Kello",
    "application.ui.back": "takaisin",
    "application.ui.buy": "osta",
    "application.ui.certificate": "Todistukset",
    "application.ui.confirmDeleteEntry": "Poista merkintä {{title}} poistaa peruuttamattomasti?",
    "application.ui.contact": "Ota yhteyttä",
    "application.ui.count": "Numero",
    "application.ui.currentlyActive": "Käynnissä juuri nyt",
    "application.ui.deleteEntry": "poista",
    "application.ui.discardUnsavedChanges": "Hylkää muutokset ja jatka",
    "application.ui.discardUnsavedChangesMobile": "Hävitä",
    "application.ui.distance": "Etäisyys",
    "application.ui.distant": "poistettu",
    "application.ui.dreampoints": "Dreampoints",
    "application.ui.dreampointsAllDreams": "Unelmapisteet merkintöjäsi",
    "application.ui.dreampointsDonate": "Dreampoints lahjoittaa",
    "application.ui.dreampointsTooltip": "Voit kerätä Dreampoints-pisteitä osallistumalla",
    "application.ui.edit": "muutos",
    "application.ui.editDescription": "Muokkaa kuvausta",
    "application.ui.editEntry": "muokkaa",
    "application.ui.editEvent": "Muokkaa tapahtumaa",
    "application.ui.editImage": "Muokkaa kuvaa",
    "application.ui.editPoi": "Muokkaa sijaintia",
    "application.ui.editProduct": "Muokkaa tuotetta",
    "application.ui.editProfile": "Muokkaa profiiliani",
    "application.ui.editShownProfile": "Muuta profiilini näyttöä",
    "application.ui.entriesFocus": "Valitut merkinnät artikkelissa Focus:",
    "application.ui.entriesLatest": "Viimeisimmät merkinnät artikkelissa Focus:",
    "application.ui.email": "Sähköposti",
    "application.ui.for": "osoitteessa",
    "application.ui.from": "osoitteesta",
    "application.ui.fullView": "Yleisnäkymä",
    "application.ui.gallery": "Kuvat",
    "application.ui.galleryImages": "galleriaan...",
    "application.ui.gavePoints": "on antanut sinulle {{points}} pisteitä.",
    "application.ui.goToEntry": "Soita sisäänkirjautuminen",
    "application.ui.goToEvent": "Soita tapahtuma",
    "application.ui.goToPoi": "Soitinpaikka",
    "application.ui.goToMeasuring": "Mittauspisteet",
    "application.ui.goToProduct": "Soita tuote",
    "application.ui.hasBeen": "oli",
    "application.ui.here": "Lähellä",
    "application.ui.invalidEmbed": "Tätä URL-osoitetta ei voi upottaa.",
    "application.ui.imprintLink": "Vastuussa ja oikeudellisesti vastuussa tämän alasivun sisällöstä {{entry}} - Jälki",
    "application.ui.isActive": "Aktiivinen",
    "application.ui.isNotActive": "Vammaiset",
    "application.ui.knowMore": "Kaikki {{aboutPre}} {{about}}",
    "application.ui.language.de": "Saksan",
    "application.ui.language.en": "Englanti",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "Italiano",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "Tällä hetkellä valittu kieli",
    "application.ui.language.switchTo": "Vaihda kieli ",
    "application.ui.mailBodyPre": "Hei, olen löytänyt jotain mielenkiintoista:",
    "application.ui.mailSubject": "Olen löytänyt mielenkiintoisen linkin:",
    "application.ui.mandatory": "Pakollinen kenttä",
    "application.ui.memberSince": "Jäsen vuodesta",
    "application.ui.mobileRotate": "Kierrä koko näytön näkymää varten",
    "application.ui.more": "lisää",
    "application.ui.name": "Nimi",
    "application.ui.navigateOneLevelUp": "Takaisin vanhempien kansioon",
    "application.ui.new": "Uusi",
    "application.ui.newEntry": "Luo uusi merkintä",
    "application.ui.newEvent": "Luo uusi tapahtuma",
    "application.ui.newPoi": "Luo uusi sijainti",
    "application.ui.newProduct": "Luo uusi tuote",
    "application.ui.noEntry": "Et ole vielä luonut merkintää. Luo ensimmäinen merkintäsi heti...",
    "application.ui.noEntryProfile": "ei ole vielä luonut merkintää.",
    "application.ui.noEvent": "Et ole vielä luonut tapahtumaa. Luo ensimmäinen tapahtumasi heti...",
    "application.ui.noPoi": "Et ole vielä luonut sijaintia. Luo ensimmäinen sijaintisi heti...",
    "application.ui.noJob": "Et ole vielä luonut työpaikkaa. Luo ensimmäinen työsi nyt...",
    "application.ui.noProduct": "Et ole vielä luonut tuotetta. Luo ensimmäinen tuotteesi heti...",
    "application.ui.notEditable": "ei voi muuttaa",
    "application.ui.nothingFound": "Emme löytäneet yhtään tulosta tästä hausta.",
    "application.ui.offer": "Tarjoukset",
    "application.ui.offer.offers": "Tarjoukset",
    "application.ui.offer.acceptOffer": "Hyväksy tarjous",
    "application.ui.offer.rejectOffer": "Hylkää tarjous",
    "application.ui.offer.noOffers": "Ei tarjouksia",
    "application.ui.offer.expiresAt": "Tarjous on voimassa asti",
    "application.ui.offer.offerExpires": "Tarjous päättyy",
    "application.ui.offer.setOtherExpirationDate": "Aseta uusi päivämäärä",
    "application.ui.offer.inXDays": "30 päivässä",
    "application.ui.only": "Vain",
    "application.ui.openDocument": "Avaa tiedosto",
    "application.ui.or": "tai",
    "application.ui.playVideo": "Toista video",
    "application.ui.readMore": "Lue lisää",
    "application.ui.region": "Alue",
    "application.ui.relevance": "Merkitys",
    "application.ui.reset": "Nollaa kaikki suodattimet",
    "application.ui.routingDescription": "Ohjeet",
    "application.ui.saveShownProfile": "Tallenna profiilin muutokset",
    "application.ui.saveUnsavedChanges": "Tallenna",
    "application.ui.searchEnter": "Kirjoita hakusana ja paina ⏎.",
    "application.ui.select": "Valitse",
    "application.ui.shop": "Kauppa",
    "application.ui.showAllEntries": "Näytä kaikki merkinnät",
    "application.ui.showAllSearch": "Näytä kaikki hakutulokset",
    "application.ui.showMoreSearch": "Näytä lisää hakutuloksia",
    "application.ui.resetSearch": "Nollaa valinta ja hae uudelleen",
    "application.ui.sort": "Lajittelu",
    "application.ui.stockPhotoLinks": "Linkit kuvatietokantoihin",
    "application.ui.suitableFor": "Suositellaan",
    "application.ui.total": "Yhteensä",
    "application.ui.totalDreampoints": "Unelmapisteet kaikista merkinnöistäsi",
    "application.ui.trySearch": "Haluatko kokeilla muita hakusanoja, kuten esim.",
    "application.ui.type": "Tyyppi",
    "application.ui.unsavedChanges": "Tallentamattomat muutokset",
    "application.ui.updated": "päivitetty",
    "application.ui.uploadedFileFor": "Ladattu tiedosto:",
    "application.ui.userName": "Käyttäjätunnus",
    "application.ui.viewAsList": "Luettelonäkymä",
    "application.ui.viewAsTable": "Taulukkonäkymä",
    "application.ui.visibleForAll": "Näkyy kaikille käyttäjille",
    "application.ui.visibleForFollower": "Näkyy seuraajille",
    "application.ui.visibleForJoiner": "Näkyvissä liitostyöntekijöille",
    "application.ui.visibleForNone": "Ei näy kenellekään",
    "application.ui.visibleForSupporter": "Näkyvissä kannattajille",
    "application.ui.years": "Vuodet",
    "application.ui.yearsFew": "Vauvan ikä",
    "application.widgets.highestRatedEntries": "Eniten Dreampoints",
    "application.widgets.inFocus": "Kohdennettu",
    "application.widgets.newestEntries": "Viimeisin {{size}} Merkinnät",
    "application.widgets.noNewestEntries": "Uusia merkintöjä ei löytynyt...",
    "application.widgets.showCategories": "Näytä luokkasuodatin",
    "application.widgets.showFilter": "Näytä lisää suodattimia",
    "application.ui.writeUs": "Kirjoita meille...",
    "blog.ui.category": "Luokka",
    "blog.ui.categoryHeading": "Luokasta",
    "blog.ui.lastUpdated": "Viimeksi päivitetty",
    "blog.ui.openSinglePost": "Lue lisää",
    "blog.ui.publishedIn": "Julkaistu osoitteessa",
    "blog.ui.subCategories": "Alaluokat",
    "blog.ui.written": "Kirjoitettu",
    "blog.ui.writtenBy": "Kirjoittanut",
    "buttons.alreadyMeToo": "Olen iloinen, että sinäkin unelmoit tästä kirjoituksesta.",
    "buttons.alreadyMeTooMobile": "Olen iloinen, että sinäkin unelmoit tästä kirjoituksesta.",
    "buttons.cancel": "Peruuta",
    "buttons.confirm": "Vahvista",
    "buttons.createAccount": "Aseta EntryerAccount nyt",
    "buttons.delete": "poista",
    "buttons.deleteFavorite": "Ei enää seuraa",
    "buttons.deleteLike": "Ei enää samankaltaisia",
    "buttons.meToo": "Minäkin haluaisin tehdä sen",
    "buttons.newPassword": "Pyydä uusi salasana",
    "buttons.ok": "Ok",
    "buttons.ready": "Valmis",
    "buttons.save": "Tallenna muutokset",
    "buttons.saveMobile": "Tallenna",
    "buttons.selectThis": "Valitse tämä",
    "buttons.tryAgain": "Yritä uudelleen",
    "category.ui.hideCategories": "Piilota alaluokat",
    "category.ui.oneLevelUp": "Takaisin ylempään luokkaan",
    "category.ui.showCategories": "Näytä alaluokat",
    "categoryFields.attachmentWidth": "Toteuta leveys",
    "categoryFields.clutch": "Kytkentä",
    "categoryFields.companyName": "Yrityksen nimi",
    "categoryFields.dealerInfo": "Jälleenmyyjän tiedot",
    "categoryFields.diameter": "Halkaisija",
    "categoryFields.displayNetPrice": "Nettohinta",
    "categoryFields.electricalPower": "Sähköteho",
    "categoryFields.engine": "Moottori",
    "categoryFields.engineDescription": "Moottorin varusteet",
    "categoryFields.ERPname": "Nimi tavarahallinnossa",
    "categoryFields.horsePower": "Teho",
    "categoryFields.netWidth": "Raideleveys ilman renkaita",
    "categoryFields.packingUnit": "Pakkausyksikkö",
    "categoryFields.PTO": "PTO",
    "categoryFields.relativeGauge": "Levittää rataa",
    "categoryFields.vendorAcronym": "Lyhyt nimitys",
    "categoryFields.volume": "Volume",
    "categoryFields.weight": "Paino",
    "categoryFields.wheelLock": "Telineen pyörän lukitus",
    "checkout.cart.title": "Ostoskorini",
    "checkout.cart.item": "Artikkeli",
    "checkout.cart.amount": "Numero",
    "checkout.cart.edit": "Muuta",
    "checkout.cart.price": "Hinta",
    "checkout.cart.singlePrice": "Yksikköhinta",
    "checkout.cart.product.isNonPhysical": "Tuote lähetetään sähköisesti/lataamalla.",
    "checkout.cart.product.requiresExpressDelivery": "Tuore tuote EXPRESS-toimituksella. Vain sisällä D.",
    "checkout.cart.product.deliveryProblem": "Valitun tuotteen toimitus saattaa viivästyä.",
    "checkout.cart.subtotal": "Yhteensä",
    "checkout.cart.deliveryCosts": "Toimituskulut",
    "checkout.cart.total": "Yhteensä",
    "checkout.cart.help": "Onko sinulla kysyttävää tilausprosessista? Olemme koonneet sinulle tärkeitä tietoja.",
    "checkout.cart.subtotalInfo": "sis. alv",
    "checkout.cart.subtotalShipment": "sekä arvioidut toimituskulut",
    "checkout.cart.checkout": "Kassa",
    "checkout.cart.checkoutWithoutUser": "Kassalle ilman asiakastiliä",
    "checkout.cart.checkoutFast": "Nopea kassa",
    "checkout.cart.deliveryInfo": "Ostoskorissa olevien tuotteiden toimituspäivä",
    "checkout.cart.orderSuccess": "Tilauksesi onnistui.",
    "checkout.cart.orderCommitting": "Tilaustasi käsitellään parhaillaan - odota",
    "checkout.cart.myOrder": "Tilaukseni",
    "checkout.cart.leftover": "vasen",
    "checkout.checkout.orderNumber": "Tilausnumero",
    "checkout.checkout.account": "Asiakastili",
    "checkout.checkout.alreadyAccount": "Oletko jo asiakas? Klikkaa tästä rekisteröityäksesi.",
    "checkout.checkout.andOrder": "ja järjestys",
    "checkout.checkout.delivery.title": "Osoitteet",
    "checkout.checkout.delivery": "Toimitus ja toimitusosoite",
    "checkout.checkout.delivery.type": "Lähetys tapahtuu",
    "checkout.checkout.delivery.street": "Osoiterivi 1",
    "checkout.checkout.delivery.houseNumber": "Ei.",
    "checkout.checkout.delivery.co": "Osoitteen loppuliite (c/o)",
    "checkout.checkout.delivery.city": "Kaupunki",
    "checkout.checkout.delivery.selectCountry": "Valitse maa",
    "checkout.checkout.delivery.addressBoth": "Laskutus- ja toimitusosoite",
    "checkout.checkout.delivery.addressInvoice": "Laskun osoite",
    "checkout.checkout.delivery.addressDelivery": "Toimitusosoite",
    "checkout.checkout.delivery.default": "Aseta tämä osoite oletusarvoiseksi toimitusosoitteeksi",
    "checkout.checkout.delivery.otherAddress": "Toimitusosoite poikkeaa laskutusosoitteesta.",
    "checkout.checkout.delivery.dhlNr": "DHL Postin numero",
    "checkout.checkout.delivery.packingStation": "Pakkausaseman numero",
    "checkout.checkout.delivery.showPackingStations": "Näytä pakkaamot osoitteeni lähistöllä",
    "checkout.checkout.delivery.wantExpressShipping": "Pikatoimitus",
    "checkout.checkout.delivery.isPickUp": "Keräys paikan päällä",
    "checkout.checkout.delivery.addressNotVerified": "Valitsemasi osoite ei ole täydellinen tai sitä ei ole vielä vahvistettu. Emme voi toimittaa sinulle. Täydentääksesi osoitettasi, klikkaa osoitettasi kynäsymbolia. Jos sinulla on kysyttävää tilausprosessista, asiakastukitiimimme auttaa mielellään.",
    "checkout.checkout.delivery.specialCountryInfo": "Maasi ei ole valittavissa?",
    "checkout.checkout.delivery.specialCountry": "Maasi on vakiotoimituksen ulkopuolella",
    "checkout.checkout.delivery.specialCountryMessage": "Ostoskorissasi olevia yksittäisiä tuotteita ei voida toimittaa kaikkiin maihin. Poista joitakin tuotteita tilauksestasi, kunnes maasi voidaan valita, tai ota meihin yhteyttä.",
    "checkout.checkout.payment": "Maksutapa",
    "checkout.checkout.paymentProblem": "Maksua suoritettaessa tapahtui virhe",
    "checkout.checkout.backToPayment": "Takaisin maksuasetuksiin.",
    "checkout.checkout.paymentTitle": "Maksat kanssamme ...",
    "checkout.checkout.paymentSelected": "Valittu maksutapa",
    "checkout.checkout.payment.cc": "Luottokortti",
    "checkout.checkout.payment.ccInfo": "EU:n alueella tapahtuvissa korttimaksuissa henkilöllisyytesi on vahvistettava aina, kun maksat verkossa. Kun olet tehnyt tilauksen, sinua ohjataan pankkisi vahvistusprosessin läpi.",
    "checkout.checkout.payment.complimentary": "maksutta",
    "checkout.checkout.payment.paypal": "Paypal, pankkisiirto tai luottokortti",
    "checkout.checkout.payment.paypalButton": "muiden maksutapojen kanssa, jopa ilman Paypal-tiliä",
    "checkout.checkout.payment.paypalInfo": "Sinut ohjataan PayPaliin maksuprosessin suorittamista varten. PayPal-tiliäsi veloitetaan vasta, kun vahvistat tilauksen seuraavassa vaiheessa.",
    "checkout.checkout.payment.paypalInfoExtended": "Sinut ohjataan PayPaliin maksuprosessin suorittamista varten. Tiliäsi tai PayPal-tiliäsi veloitetaan vasta, kun vahvistat tilauksen seuraavassa vaiheessa.",
    "checkout.checkout.payment.sepa": "Suoraveloitus (SEPA)",
    "checkout.checkout.payment.sepaLegal": "Annan luvan {{siteOwner}}, {{siteOwnerAddress}} (velkojan tunnus: {{siteOwnerCreditorID}}) perimään maksuja tililtäni suoraveloituksena. Samalla kehotan pankkiäni maksamaan seuraavat suoraveloitukset {{siteOwner}} tililleni suoraveloituksena.",
    "checkout.checkout.payment.noSepa": "Laskutus- tai toimitusosoitteesi on EU:n ulkopuolella. Siksi emme voi tarjota sinulle maksua suoraveloituksena.",
    "checkout.checkout.payment.sepaInfo": "Nopea ja mutkaton. Tilausta viimeistellessäsi (seuraava vaihe) sinua pyydetään antamaan pankkitietosi (IBAN). Ole hyvä ja pidä ne valmiina. Kun tilisiirto on suoritettu, saat vahvistuksen.",
    "checkout.checkout.payment.prepayment": "Ennakkomaksu (pankkisiirto)",
    "checkout.checkout.payment.prepaymentInfo": "Kun olet tehnyt tilauksen, lähetämme sinulle pankkisiirtotiedot sähköpostitse. Voimme varata tilauksesi kohteet enintään 7 päivän ajaksi. Pidä tämä aikaikkuna pankkisiirtoa varten. Heti kun olemme saaneet maksun, tilaus lähetetään.",
    "checkout.checkout.payment.invoice": "Lasku",
    "checkout.checkout.payment.invoiceInfo": "Laskun summa erääntyy maksettavaksi sähköpostitse lähetysvahvistuksessa ilmoitettuna maksupäivänä.",
    "checkout.checkout.payment.noInvoice": "Valitettavasti emme voi tarjota sinulle laskulla maksamista tämän tilauksen osalta. Valitse jokin muu maksutapa.",
    "checkout.checkout.payment.sofort": "Välitön pankkisiirto",
    "checkout.checkout.payment.sofortInfo": "Tilausta viimeistellessäsi (seuraava vaihe) sinua pyydetään antamaan pankkitietosi (IBAN). Ole hyvä ja pidä ne valmiina. Kun tilisiirto on suoritettu, saat vahvistuksen.",
    "checkout.checkout.payment.accountName": "Tilinomistaja",
    "checkout.checkout.payment.accountEmail": "Vahvistussähköpostiosoite",
    "checkout.checkout.sepaAndOrder": "Suoraveloitusvaltuutus {{totalAmount}} € Vahvista ja tilaa oikeudenkäyntikuluineen",
    "checkout.checkout.sofortAndOrder": "Siirtomandaatti {{totalAmount}} € vahvistamaan ja määräämään oikeudenkäyntikulut",
    "checkout.checkout.title": "Kassa",
    "checkout.checkout.next": "lisää",
    "checkout.checkout.orderPre": "Klikkaa \"Osta nyt\" tehdessäsi tilauksen loppuun.",
    "checkout.checkout.order": "Osta nyt",
    "checkout.checkout.orderConsentPre": "Tilauksen tekemällä hyväksyt",
    "checkout.checkout.orderConsentPost": "samaa mieltä.",
    "checkout.checkout.noAccount": "Älä luo asiakastiliä",
    "checkout.redirect.countryPriceChange": "Maasi hinnat poikkeavat vakiohinnasta. Vahvistakaa hinnanmuutos.",
    "comments.blockComments": "Tämän viestin kommentointi on päättynyt.",
    "comments.comment.anonymous": "Anonyymi",
    "comments.comment.button": "Kirjoita uusi kommentti",
    "comments.comment.buttonReply": "Vastaukset",
    "comments.comment.cancel": "Peruuta",
    "comments.comment.content": "Kommenttisi",
    "comments.comment.hasCommented": "kommentit",
    "comments.comment.login": "Kirjaudu sisään kommentoidaksesi",
    "comments.comment.past": "on",
    "comments.comment.replyFrom": "Vastaus",
    "comments.comment.showReplies": "Näytä vastaukset",
    "comments.comment.showReply": "Näytä vastaus",
    "comments.comment.submit": "Lähetä",
    "comments.comment.title": "Kommentin otsikko",
    "comments.comment.userName": "Käyttäjätunnuksesi",
    "comments.heading": "Ehdotukset, huomautukset, kommentit",
    "comments.hierarchy.flat": "Litteä",
    "comments.hierarchy.nested": "Genested",
    "comments.hierarchy.title": "Kommentti näkymä:",
    "comments.message.afterSaveError": "Kommenttiasi ei voida tallentaa tällä hetkellä!",
    "comments.message.afterSaveSuccess": "Kommenttisi on tallennettu.",
    "comments.message.afterSaveUnlock": "Kommenttisi on tallennettu, mutta ylläpitäjän on vielä tarkistettava se.",
    "comments.noComments": "Huomautuksia ei ole vielä tehty",
    "comments.reply": "AN:",
    "comments.show": "Näytä kommentit",
    "comments.title": "Kommentit",
    "entries.edit": "Sisäänkirjautuminen <strong>{{title}}</strong> muokkaa",
    "entries.entries": "Merkinnät",
    "entries.product.ui.chooseVariant": "Valitse vaihtoehto",
    "entries.product.ui.chooseVariantMobile": "Valitse vaihtoehto",
    "entries.product.ui.combinationNotAvailable": "Yhdistäminen ei ole mahdollista",
    "entries.entry.fields.address.city": "Kaupunki",
    "entries.entry.fields.address.country": "Maa",
    "entries.entry.fields.address.houseNumber": "Talon numero",
    "entries.entry.fields.address.street": "Street",
    "entries.entry.fields.address.zip": "POSTINUMERO",
    "entries.entry.fields.language": "Kieli",
    "entries.entry.fields.prefixMissing": "Seuraavia kenttiä ei ole täytetty:",
    "entries.entry.profile.edit": "Muokkaa profiilia",
    "entries.entry.profile.publicProfile": "julkiselle profiilisivulleni",
    "entries.entry.tooltip.alreadyCopied": "Näet jo unta tästä merkinnästä.",
    "entries.entry.tooltip.alreadyFollow": "Seuraat jo tätä merkintää",
    "entries.entry.tooltip.alreadyLiked": "Pidät jo tästä merkinnästä",
    "entries.entry.tooltip.copied": "On mukavaa, että sinäkin haluat unelmoida tästä kirjoituksesta. Profiilikuvasi on lisätty tähän merkintään.",
    "entries.entry.tooltip.filterComments": "Klikkaa tästä näyttääksesi vain kommentit syöttölinjalla.",
    "entries.entry.tooltip.filterFollows": "Napsauta tästä näyttääksesi vain seuraajat Entryline-ruudussa.",
    "entries.entry.tooltip.filterLikes": "Klikkaa tästä näyttääksesi vain tykkäykset merkintärivillä",
    "entries.entry.tooltip.filterShares": "Klikkaa tästä näyttääksesi vain osakkeet tulorivillä.",
    "entries.entry.tooltip.follow": "Klikkaa tästä seurataksesi tätä merkintää",
    "entries.entry.tooltip.like": "Klikkaa tästä antaaksesi tälle merkinnälle Tykkää",
    "entries.entry.tooltip.noFollow": "Seuraa vain muiden merkintöjä",
    "entries.entry.tooltip.noFollowNotLoggedIn": "Sinun täytyy olla kirjautuneena sisään seurataksesi tätä merkintää",
    "entries.entry.tooltip.noLike": "Like toimii vain muiden ihmisten merkinnöissä",
    "entries.entry.ui.access": "Vain seuraavat voivat nähdä tämän merkinnän",
    "entries.entry.ui.added": "...lisättiin.",
    "entries.entry.ui.addEntry": "Lisää merkintöjä",
    "entries.entry.ui.addOpening": "Lisää aukioloaika",
    "entries.entry.ui.address": "Osoitetiedot",
    "entries.entry.ui.addStep": "Lisää vaihe",
    "entries.entry.ui.agreementHours": "Avoinna sopimuksen mukaan",
    "entries.entry.ui.allAccess": "Kaikki voivat nähdä tämän merkinnän",
    "entries.entry.ui.assignedCategories": "osoitetut luokat",
    "entries.entry.ui.assignedCertificates": "Sertifikaattini",
    "entries.entry.ui.assignedProducts": "Myönnetyt tuotteet",
    "entries.entry.ui.businessContact": "Yrityksen yhteystiedot",
    "entries.entry.ui.categories": "Tämän merkinnän kategoriat",
    "entries.entry.ui.categoryFields": "Lisätietoja",
    "entries.entry.ui.commercialTitle": "Yrityksen tiedot merkinnässä",
    "entries.entry.ui.companyTitle": "Yritykseni tiedot",
    "entries.entry.ui.contactPerson": "Yhteystietoni",
    "entries.entry.ui.cssClass": "Sisällön CSS-luokka",
    "entries.entry.ui.deliveryInfo": "Toimituspalvelun tiedot",
    "entries.entry.ui.descriptionInfo": "Vihje: Kun sanat on merkitty, tyylejä voidaan määrittää.",
    "entries.entry.ui.descriptionPlaceholder": "Kuvaus HTML:llä. Kaksoisnapsauta tekstiä HTML-toimintopalkkia varten (enintään 600 merkkiä).",
    "entries.entry.ui.editEntry": "Muokkaa merkintää",
    "entries.entry.ui.editEntryNew": "Luo merkintä",
    "entries.entry.ui.exclusionHours": "Poissulkemisajat",
    "entries.entry.ui.galleryImages": "Kuvagalleria (2 tai 3 kuvaa järjestelystä riippuen)",
    "entries.entry.ui.globalTitle": "Nimi",
    "entries.entry.ui.hasKiosk": "Minulla on koju",
    "entries.entry.ui.imagesHorizontal": "Vaakasuora",
    "entries.entry.ui.imagesInfo": "Kuvia voi muokata kohdassa \"Kuvat/Videot/Dokumentit\".",
    "entries.entry.ui.imagesOrientation": "Kuvan kohdistaminen",
    "entries.entry.ui.imagesSquared": "Neliö",
    "entries.entry.ui.imagesVertical": "Pystysuora",
    "entries.entry.ui.isListImage": "Tämä kuva on luettelon kuva",
    "entries.entry.ui.isMainImage": "Tämä kuva on pääkuva",
    "entries.entry.ui.isMainImageAndListImage": "Tämä kuva näkyy merkinnän pääkuvana.",
    "entries.entry.ui.jobApply": "Hae nyt",
    "entries.entry.ui.kioskDescription": "Kuvaus myyntikojusta",
    "entries.entry.ui.listImage": "Luettelo kuva",
    "entries.entry.ui.logo": "Logo",
    "entries.entry.ui.longDescription": "Pitkä kuvausteksti",
    "entries.entry.ui.mainImage": "Tärkein kuva",
    "entries.entry.ui.makeListImage": "Aseta tämä kuva luettelon kuvaksi",
    "entries.entry.ui.makeListImageTooltip": "Hakutulokset näytetään luettelona, jossa on luettelon kuva. Jos luettelokuvaa ei ole valittu, käytetään pääkuvaa.",
    "entries.entry.ui.makeMainImage": "Aseta tämä kuva pääkuvaksi",
    "entries.entry.ui.makeMainImageTooltip": "Pääkuva näkyy profiilin yläosassa. Kuvat, joita ei ole valittu pää- tai luettelokuvaksi, näytetään kuvagalleriassa.",
    "entries.entry.ui.metaDescription": "Lyhyt kuvaus",
    "entries.entry.ui.metaDescriptionPlaceholder": "Lyhyt kuvaus, enintään 200 merkkiä",
    "entries.entry.ui.multiCode": "Lisäkoodi tätä vaihetta varten",
    "entries.entry.ui.multiDescription": "Tämän vaiheen kuvaus",
    "entries.entry.ui.multiImages": "Kuvat tätä vaihetta varten",
    "entries.entry.ui.multiProgress": "Progress",
    "entries.entry.ui.multiTitle": "Tämän vaiheen otsikko",
    "entries.entry.ui.multiTitlePlaceholder": "Tämän vaiheen otsikko (enintään 50 merkkiä)",
    "entries.entry.ui.noDescriptions": "Merkinnällä ei ole vielä kuvailevaa tekstiä.",
    "entries.entry.ui.noEntries": "Merkintöjä ei löytynyt.",
    "entries.entry.ui.onlyVisibleForCustomers": "Tämä merkintä näkyy vain rekisteröityneille asiakkaille",
    "entries.entry.ui.openAt": "Avaa",
    "entries.entry.ui.openingHours": "Aukioloajat",
    "entries.entry.ui.openingInfo": "Aukioloaikoja koskevat huomautukset",
    "entries.entry.ui.openingReligious": "Osoite pääasiassa katolisessa seurakunnassa",
    "entries.entry.ui.overallProgress": "Tämä merkintä on {{value}}% toteutunut",
    "entries.entry.ui.parking": "Pysäköintitilat",
    "entries.entry.ui.parkingLots": "Numero",
    "entries.entry.ui.parkingType": "Art",
    "entries.entry.ui.personImage": "Yhteyshenkilön kuva",
    "entries.entry.ui.relatedEvents": "Liitännäistapahtumat",
    "entries.entry.ui.relatedJobs": "Yhdistetyt työpaikat",
    "entries.entry.ui.relatedArticles": "Liittyvät artikkelit",
    "entries.entry.ui.relatedCampaigns": "Liitännäiskampanjat",
    "entries.entry.ui.rentKiosk": "Haluaisin vuokrata myyntikojun",
    "entries.entry.ui.resolveAddress": "Koordinaattien määrittäminen",
    "entries.entry.ui.resolveAddressInfo": "Määritä geopiste (sijainti kartalla) osoitteesi perusteella. Ilman oikeaa geopistettä niitä ei välttämättä löydetä oikein.",
    "entries.entry.ui.resolveGeolocation": "Hae osoite",
    "entries.entry.ui.routingPlaceholder": "Kirjoita ohjeet HTML:llä tähän.",
    "entries.entry.ui.selectEvent": "Valitse tapahtuma",
    "entries.entry.ui.selectJob": "Valitse työ",
    "entries.entry.ui.selectArticle": "Valitse artikkeli",
    "entries.entry.ui.selectCampaign": "Valitse kampanja",
    "entries.entry.ui.sharing": "Tämä sivu {{sharing}} osake",
    "entries.entry.ui.shareMail": "Jaa tämä sivu postitse",
    "entries.entry.ui.shortDescriptionInfo": "On samalla lyhyt kuvaus",
    "entries.entry.ui.skillsNeeded": "Voisin käyttää sitä hyväkseni merkinnässäni.....",
    "entries.entry.ui.sortword": "Vaihtoehtoiset nimikkeet sanalle sorting",
    "entries.entry.ui.step": "Vaihe",
    "entries.entry.ui.stepInDream": "Mihin vaiheeseen tämä vaihe kuuluu?",
    "entries.entry.ui.stepInDreamAfter": "toteutuksen jälkeen",
    "entries.entry.ui.stepInDreamBefore": "ennen toteutusta",
    "entries.entry.ui.stepInDreamDuring": "toteutuksen aikana",
    "entries.entry.ui.subtitle": "Kuvaileva alaotsikko",
    "entries.entry.ui.subtitlePlaceholder": "Kirjoita alaotsikko (enintään 60 merkkiä)",
    "entries.entry.ui.tabCategories": "Kategoriat",
    "entries.entry.ui.tabCategoriesMobile": "Kissa.",
    "entries.entry.ui.tabCompany": "Yrityksen tiedot",
    "entries.entry.ui.tabDescription": "Kuvaus",
    "entries.entry.ui.tabDescriptionMobile": "Beschr.",
    "entries.entry.ui.tabExhibitor": "Näytteilleasettajan tiedot",
    "entries.entry.ui.tabImages": "Kuvat/Videot/Dokumentit",
    "entries.entry.ui.tabImagesMobile": "Kuvat/videot/tiedostot",
    "entries.entry.ui.tabRelations": "Yhteydet",
    "entries.entry.ui.tags": "Tunnisteet",
    "entries.entry.ui.title": "Merkintä näkyy seuraavalla nimellä",
    "entries.entry.ui.titleError": "Merkintä on nimettävä ennen tallentamista",
    "entries.entry.ui.titlePlaceholder": "Kirjoita otsikko (enintään 50 merkkiä)",
    "entries.entry.ui.visible": "Tämä merkintä on näkyvissä",
    "entries.entry.ui.promotion": "Osallistuminen tätä merkintää koskeviin toimiin",
    "entries.entry.ui.regions": "Tämän merkinnän alueluokat",
    "entries.job.ui.promotion": "Osallistuminen tähän työhön liittyviin toimiin",
    "entries.event.edit": "Tapahtuma <strong>{{title}}</strong> muokkaa",
    "entries.event.ui.addEvent": "Lisää tapahtuma",
    "entries.event.ui.cooperation": "Yhteistyökumppani",
    "entries.event.ui.directions": "Ohjeet",
    "entries.event.ui.editEvent": "Muokkaa tapahtumaa",
    "entries.event.ui.editEventNew": "Luo tapahtuma",
    "entries.event.ui.endDate": "Tapahtuman loppu (päivä)",
    "entries.event.ui.eventDone": "Messuraportti",
    "entries.event.ui.noEvents": "Tapahtumia ei löytynyt",
    "entries.event.ui.openEvent": "Soita tapahtuma",
    "entries.event.ui.opening": "Aukioloajat",
    "entries.event.ui.startDate": "Tapahtuman alku (päivä)",
    "entries.event.ui.tabCategories": "Kategoriat",
    "entries.event.ui.tabDescription": "Kuvaus",
    "entries.event.ui.tabOpening": "Päivämäärä ja kellonajat",
    "entries.event.ui.tabRelations": "Yhteydet",
    "entries.event.ui.title": "Tapahtuma näkyy seuraavilla nimillä",
    "entries.event.ui.upcoming": "Pähkinänkuoressa",
    "entries.event.ui.current": "Tällä hetkellä käynnissä",
    "entries.event.ui.history": "On jo tapahtunut",
    "entries.event.ui.moreEvents": "Muut tapahtumat",
    "entries.open": "Kutsu ylös merkintä etusivulla",
    "entries.person.ui.company": "Yritys",
    "entries.person.ui.female": "Ms",
    "entries.person.ui.diverse": "Ilman",
    "entries.person.ui.fullName": "Etu- ja sukunimi",
    "entries.person.ui.fullSize": "Täysi koko",
    "entries.person.ui.gender": "Sukupuoli",
    "entries.person.ui.greeting": "Tervehdys",
    "entries.person.ui.male": "Herra",
    "entries.person.ui.open": "Suoraan tämän henkilön nimikkeeseen",
    "entries.person.ui.personAddress": "Henkilön osoite",
    "entries.person.ui.personContact": "Henkilökohtaiset yhteystiedot",
    "entries.person.ui.personImage": "Henkilökohtainen valokuva",
    "entries.person.ui.position": "Asema",
    "entries.person.ui.quote": "Lainaus/kuvateksti",
    "entries.person.ui.signature": "Allekirjoituskuva",
    "entries.poi.ui.poiEvents": "Tapahtumat tässä paikassa",
    "entries.poi.ui.title": "Paikka esiintyy seuraavilla nimillä",
    "entries.product.edit": "Tuote <strong>{{title}}</strong> muokkaa",
    "entries.product.fields.engine": "Moottori",
    "entries.product.fields.horsePower": "Teho",
    "entries.product.menu.allAttachments": "Kaikki liitteet yhdellä silmäyksellä",
    "entries.product.menu.allCarriers": "Kaikki yleiskatsauksessa olevat kuljetusajoneuvot",
    "entries.product.menu.attachments": "Liitteet",
    "entries.product.menu.carriers": "Kuljetusajoneuvot",
    "entries.product.tags.eco": "eco",
    "entries.product.tags.neu": "uusi",
    "entries.product.ui.addProduct": "Lisää tuote",
    "entries.product.ui.choose": "valitse",
    "entries.product.ui.discount": "Alennus",
    "entries.product.ui.discountSelect": "Käytä % alennusetu",
    "entries.product.ui.equipmentVariants": "Laitevaihtoehdot",
    "entries.product.ui.variants": "Vaihtoehdot",
    "entries.product.ui.gauge": "Leveys cm",
    "entries.product.ui.gaugeMin": "Pienin raideleveys cm",
    "entries.product.ui.inclusive": "Mukaan lukien",
    "entries.product.ui.live": "{{name}} kokea livenä",
    "entries.product.ui.netPrize": "mukaan lukien. {{tax}}% alv, plus.",
    "entries.product.ui.netPrizeDefault": "sis. lakisääteisen arvonlisäveron, sekä",
    "entries.product.ui.netPrizeDefaultNonPhysical": "sis. lakisääteisen arvonlisäveron.",
    "entries.product.ui.deliveryTerms": "Toimituskulut",
    "entries.product.ui.noProducts": "Tuotteita ei löytynyt",
    "entries.product.ui.noPurchaseOnThisPage": "Ei voi tilata tältä sivulta, soita tuotetta varten.",
    "entries.product.ui.onlyBasicVariant": "Ainoastaan peruslaitteet",
    "entries.product.ui.onlyWithCarrier": "Saatavilla vain perusyksikön oston yhteydessä",
    "entries.product.ui.order": "Lisää ostoskoriin",
    "entries.product.ui.notOrderable": "Valitse vaihtoehto.",
    "entries.entry.ui.products.delivery.red": "Tuote ei ole tällä hetkellä saatavilla",
    "entries.entry.ui.products.delivery.yellow": "Tuote varastossa. Mahdollisesti pidempi toimitusaika.",
    "entries.entry.ui.products.delivery.green": "Tuotetta on varastossa riittävä määrä",
    "entries.products.ui.combinationNotAvailable": "Tuote ei ole saatavilla tässä yhdistelmässä",
    "product.ui.content": "Sisältö",
    "product.ui.ingredients": "Sisältö",
    "product.ui.ingredientsFood": "Ainesosat",
    "product.ui.nonPhysical": "Sähköisen tuotteen lataaminen",
    "entries.product.ui.overview": "Yleiskatsaus",
    "entries.product.per": "The",
    "entries.product.perServing": "Annosta kohti",
    "entries.product.reference": "Viitemäärä",
    "entries.product.nutritionTable": "Ravitsemukselliset tiedot",
    "entries.product.nutrientsTable": "Ravintoaineet, kivennäisaineet ja vitamiinit",
    "entries.product.bio": "Valvotusta luonnonmukaisesta viljelystä peräisin olevat ainesosat",
    "entries.product.ui.prizeDecimalPoint": ",",
    "entries.product.ui.prizeFrom": "osoitteesta",
    "entries.product.ui.item": "Kappale",
    "entries.product.ui.prizePerItem": "Hinta per kappale",
    "entries.product.ui.noPrize": "Kysy hinta yhteyshenkilöltäsi.",
    "entries.product.ui.size": "Koko",
    "entries.product.ui.required": "Vain",
    "entries.product.ui.suitableFor": "Sopii",
    "entries.product.ui.suitableForAttachments": "Sopii seuraaville lisälaitteille",
    "entries.product.ui.suitableForCarriers": "Sopii seuraaviin kuljetusajoneuvoihin",
    "entries.product.ui.title": "Tuote",
    "entries.product.ui.variant": "Vaihtoehto/nippu",
    "entries.product.ui.variantNotOrderable": "Valittua vaihtoehtoa ei voi tilata",
    "entries.product.ui.inVariants": "tuotevaihtoehtojen osalta",
    "entries.product.ui.bundleparts": "Koostuu seuraavista osista",
    "entries.progress.title": "Miten parantaa merkintääsi",
    "entries.tabbar.basics": "Perustiedot",
    "entries.tabbar.entry": "Kirjautumistiedot",
    "entries.tabbar.gallery": "Kuvat",
    "entries.tabbar.layout": "Asettelu",
    "entries.tabbar.openingHours": "Aukioloajat",
    "entries.tabbar.order": "Tilaustiedot",
    "entries.tabbar.tooltip.entryNotSavedDetail": "Merkinnän tietoja voidaan muokata vasta, kun merkintä on tallennettu otsikolla.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "Asettelutietoja voidaan muokata vasta, kun merkintä on tallennettu otsikolla.",
    "entries.types.poi": "Poi",
    "entries.types.standard": "Standardi",
    "entries.ui.groupDetailName": "Ryhmämerkintöjen nimi",
    "entries.ui.groupName": "Ryhmien nimi (alue)",
    "entryTypes.entry": "Sisäänkirjautuminen",
    "entryTypes.event": "Tapahtuma",
    "entryTypes.poi": "Tapahtumapaikka",
    "errors.blank": "{{description}} on täytettävä",
    "errors.description": "Tämä kenttä",
    "errors.email": "Kirjoita voimassa oleva {{description}}-osoite",
    "errors.empty": "{{description}} ei saa olla tyhjä",
    "errors.exclusion": "Tämä {{description}} on jo varattu",
    "errors.inclusion": "Tämä {{description}} ei sisälly valintaan",
    "errors.invalid": "{{description}} on virheellinen",
    "errors.mandatory": "{{field}} on täytettävä",
    "errors.mandatoryCheckbox": "On vahvistettava.",
    "errors.mandatorySubmit": "Voit lähettää lomakkeen vasta, kun kaikki pakolliset kentät on täytetty.",
    "errors.slug": "Slug saa sisältää vain pieniä kirjaimia, numeroita ja \"-\".",
    "forms.ui.deleteFile": "Poista tiedosto",
    "forms.ui.formErr": "Virhe lomaketta lähetettäessä",
    "forms.ui.formSent": "Lähetetty lomake",
    "forms.ui.noForm": "Lomaketta ei ole määritetty",
    "forms.ui.invalidMail": "Kirjoita voimassa oleva sähköpostiosoite.",
    "forms.ui.isSubmitted": "Lomake on lähetetty. Otamme sinuun yhteyttä mahdollisimman pian.",
    "forms.ui.missingFields": "Seuraavat kentät on täytettävä:",
    "forms.ui.uploadSuccess": "{{file}} ladattiin",
    "forms.ui.fieldBuilder.street": "Street",
    "forms.ui.fieldBuilder.houseNumber": "Ei.",
    "forms.ui.fieldBuilder.zip": "POSTINUMERO",
    "forms.ui.fieldBuilder.city": "Kaupunki",
    "forms.ui.fieldBuilder.country": "Maa",
    "forms.ui.fieldBuilder.firstName": "Etunimi",
    "forms.ui.fieldBuilder.lastName": "Sukunimi",
    "forms.ui.privacyRead": "Olen tutustunut tietosuojakäytäntöön",
    "forms.ui.privacy": "Tietosuojakäytäntö",
    "jobs.ui.employmentType": "Työn tyyppi",
    "jobs.ui.employmentType.FULL_TIME": "Kokopäiväinen",
    "jobs.ui.employmentType.PART_TIME": "Osa-aikainen",
    "jobs.ui.employmentType.APPRENTICE": "Koulutus",
    "jobs.ui.employmentType.INTERN": "Harjoittelu",
    "jobs.ui.employmentType.CONTRACTOR": "Freelance-työ",
    "jobs.ui.employmentType.TRAINEE": "Harjoittelija, osa-aikainen opiskelu",
    "jobs.ui.employmentType.VOLUNTEER": "Vapaaehtoinen",
    "jobs.ui.employmentType.OTHER": "",
    "jobs.ui.location": "Työpaikka saatavilla osoitteessa",
    "jobs.ui.role.male": "M",
    "jobs.ui.role.female": "W",
    "jobs.ui.role.divers": "D",
    "jobs.ui.startDateNow": "Tällä hetkellä",
    "jobs.ui.startFrom": "Osoitteesta",
    "media.documents.search.noResult": "Anna sarjanumero tai valitse luokka.",
    "media.documents.search.placeholder": "Haku yksikön sarjanumeron tai tyyppinumeron mukaan",
    "media.documents.search.reset": "Nollaa hakutulos",
    "media.ui.copyright": "Tekijänoikeus",
    "media.ui.cropFormat": "Formaattispesifikaatio",
    "media.ui.cropImage": "Trimmaus",
    "media.ui.cropImageDuplicate": "Kopioi ja leikkaa",
    "media.ui.description": "Kuvaus",
    "media.ui.filename": "Tiedoston nimi",
    "media.ui.imageSaved": "Kuva tallennettu",
    "media.ui.processingImage": "Kuvaa rajataan, odota",
    "media.ui.resetCrop": "Nollaa leikkaus",
    "media.ui.resetImage": "Takaisin alkuperäiseen",
    "media.ui.rotateImage": "Käännä kuvaa 90° myötäpäivään",
    "media.ui.title": "Otsikko",
    "name": "Nimi",
    "openingHours.additionalInfo": "Aukioloaikoja koskevat tiedot",
    "openingHours.addOpening": "Lisää aukioloaika",
    "openingHours.date.day.di": "Ti",
    "openingHours.date.day.do": "Tee",
    "openingHours.date.day.fr": "Pe",
    "openingHours.date.day.mi": "Mi",
    "openingHours.date.day.mo": "Mo",
    "openingHours.date.day.sa": "La",
    "openingHours.date.day.so": "Joten",
    "openingHours.dateS": "Päivämäärä",
    "openingHours.delete": "Poista",
    "openingHours.from": "osoitteesta",
    "openingHours.options": "Alue",
    "openingHours.time": "Aika",
    "openingHours.to": "osoitteeseen",
    "openingHours.type": "Aukioloajan tyyppi",
    "opt-in.error-can-not-update-member": "Muutokset jäsentietoihin seuraavilla aloilla {{email}} ei voitu suorittaa.",
    "opt-in.error-list-does-not-exist": "Luetteloa ei löytynyt ilmoitetuilta päivämääriltä.",
    "opt-in.error-list-does-not-need-validation": "Määritetyssä luettelossa ei ole opt-int.",
    "opt-in.error-member-not-in-list": "Luettelon jäsentä ei löytynyt annetuilta päivämääriltä.",
    "opt-in.success-member-verified": "The {{email}} lisättiin {{listname}} lisätty luetteloon.",
    "opt-out.error-can-not-update-member": "Muutokset jäsentietoihin seuraavilla aloilla {{email}} ei voitu suorittaa.",
    "opt-out.error-list-does-not-exist": "Luetteloa ei löytynyt ilmoitetuilta päivämääriltä.",
    "opt-out.error-list-does-not-have-opt-out": "Määritetyssä luettelossa ei ole opt-out-mahdollisuutta.",
    "opt-out.error-member-not-in-list": "Luettelon jäsentä ei löytynyt annetuilta päivämääriltä.",
    "opt-out.success-membership-terminated": "The {{email}} poistettiin {{listname}} poistettu.",
    "password.errors.not-secure-enough": "Suosittelemme vähintään 8 merkin pituista salasanaa, jossa on isoja ja pieniä kirjaimia, numeroita ja erikoismerkkejä ($@$!%*#?&amp;).",
    "password.errors.password-and-conf-do-not-match": "Näiden kahden salasanan on oltava identtiset.",
    "password.quality.0": "Erittäin huono",
    "password.quality.1": "Bad",
    "password.quality.2": "Riittävästi",
    "password.quality.3": "Hyvä",
    "password.quality.4": "Erittäin hyvä",
    "profile.entries.copycats": "Merkinnät, jotka {{userName}} haluaa myös tehdä",
    "profile.entries.donates": "Merkinnät, jotka {{userName}} tukee",
    "profile.entries.entries": "Merkinnät alkaen {{userName}}",
    "profile.entries.follows": "Merkinnät, jotka {{userName}} seuraa",
    "profile.entries.joins": "Merkinnät, joissa {{userName}} osallistuu",
    "profile.marketplace.fields.energyRequired": "Sähkön kysyntä",
    "profile.marketplace.fields.livingAnimals": "Elävien eläinten ottaminen",
    "profile.marketplace.fields.loadingUnloading": "Tarvitaan saapumista/lähtöä varten",
    "profile.marketplace.fields.maxRange": "Minun tarjoukseni olen jo niin monta km ajaa",
    "profile.marketplace.fields.placeRequired": "Kenttää koskevat vaatimukset",
    "profile.marketplace.fields.securityRequired": "Turvallisuuden tarve",
    "profile.marketplace.fields.shows": "Ohjelmatarjous",
    "profile.marketplace.fields.spaceRequired": "Tilantarve m²",
    "profile.marketplace.fields.waterRequired": "Vedentarve m³",
    "profile.marketplace.new": "Uusi tarjous tai tarve",
    "profile.marketplace.offer.delete": "Poista",
    "profile.marketplace.offer.description": "Kuvaus",
    "profile.marketplace.offer.descriptionPlaceholder": "Mielekäs kuvaus, jotta toinen käyttäjä voi tehdä sillä jotain.",
    "profile.marketplace.offer.new": "Luo uusi tarjous",
    "profile.marketplace.offer.relatedEntry": "Myönnetty merkintä",
    "profile.marketplace.offer.tags": "Tunnisteet tarjousta/pyyntöä varten (löytyvät paremmin)",
    "profile.marketplace.offer.title": "Tarjoan",
    "profile.marketplace.request.delete": "Poista pyyntö",
    "profile.marketplace.request.new": "Luo uusi pyyntö",
    "profile.marketplace.request.missingCredentials": "Voit aloittaa kyselyn vasta, kun olet luonut vähintään yhden sijainnin ja yhden mittauspisteen.",
    "profile.marketplace.request.title": "Tarvitsen",
    "profile.messages.approve": "Vahvista",
    "profile.messages.as": "kuten",
    "profile.messages.commentPre": "on",
    "profile.messages.commentSuff": "kommentit",
    "profile.messages.decided": "päätti",
    "profile.messages.deletedUser": "Poistettu käyttäjä",
    "profile.messages.donateDream": "Paljon kiitoksia siitä, että autoitte tätä ilmoittautujaa toteuttamaan ilmoittautumisensa. Hän saa viestisi välittömästi ja toivottavasti ottaa sinuun yhteyttä ja hyväksyy apusi.",
    "profile.messages.dreamer": "Osallistuja",
    "profile.messages.dreamToo": "myös unelmoida",
    "profile.messages.enter": "liittyi",
    "profile.messages.favoriteDream": "Seuraat tätä merkintää nyt.",
    "profile.messages.follow": "seuraa",
    "profile.messages.has": "on",
    "profile.messages.is": "on",
    "profile.messages.join": "JOIN",
    "profile.messages.joinDream": "Haluat unelmoida yhdessä tämän merkinnän kanssa.",
    "profile.messages.likeDream": "Pidät tästä merkinnästä nyt.",
    "profile.messages.sendText": "Viestisi on lähetetty.",
    "profile.messages.shareDream": "Olet onnistuneesti jakanut tämän merkinnän.",
    "profile.messages.supporter": "Kannattajat",
    "profile.messages.want": "haluaisi",
    "profile.messages.youAre": "Sinä olet",
    "profile.messages.youWant": "Haluaisitko",
    "profile.newsletter.notSubscribed": "Tilaa",
    "profile.newsletter.notSubscribedAny": "Et ole vielä tilannut uutiskirjelistaa.",
    "profile.newsletter.privacyInformation": "Klikkaamalla \"Tilaa\" hyväksyt, että lähetämme sinulle tämän tilauksen uutiskirjeitä tulevaisuudessa (opt-in). Voit peruuttaa uutiskirjeen tilauksen milloin tahansa klikkaamalla uudelleen \"tilaa\" (opt-out).",
    "profile.newsletter.subscribed": "tilattu",
    "profile.press.articleDetail": "Artikkelin yksityiskohdat",
    "profile.press.downloadImage": "Lataa kuva",
    "profile.press.downloadImages": "Lataa kuvia",
    "profile.press.downloadText": "Vie tekstiä",
    "profile.press.filterAuthor": "Suodata tekijän mukaan",
    "profile.press.hide": "piilota",
    "profile.press.show": "häivyttää",
    "profile.progress.title": "Kuinka parantaa profiiliasi",
    "register.ui.addressData": "Osoitetiedot",
    "register.ui.addressInfo": "Heidän ei tarvitse antaa henkilökohtaista osoitettaan. Tämä johtaa kuitenkin oikeisiin reitti- ja etäisyyslaskelmiin.",
    "register.ui.companyData": "Yrityksesi tiedot",
    "register.ui.companyDataMandatory": "Sinun on annettava yrityksesi nimi",
    "register.ui.emptyFirstName": "Sinun on annettava etunimesi",
    "register.ui.emptyLastName": "Sinun on annettava sukunimesi",
    "register.ui.emptyMail": "Sinun on annettava voimassa oleva sähköpostiosoite",
    "register.ui.emptyUserName": "Sinun on määritettävä käyttäjänimi",
    "register.ui.language": "Kieli",
    "register.ui.loginData": "Kirjautumistiedot",
    "register.ui.password": "Salasana",
    "register.ui.passwordRepeat": "Toista salasana",
    "register.ui.personalData": "Henkilötietosi",
    "register.ui.privacyStatement": "Annan suostumukseni henkilötietojeni tallentamiseen. Henkilötietojani ei luovuteta kolmansille osapuolille. Voin milloin tahansa peruuttaa tämän tietosuojaa koskevan suostumukseni tulevaisuutta varten.",
    "register.ui.takenEmail": "Käyttäjä on jo määritellyt tämän sähköpostin.",
    "register.ui.takenUserName": "Tämä käyttäjätunnus on jo varattu",
    "register.ui.timezone": "Aikavyöhyke",
    "register.ui.typeMinimum": "Sinun on valittava vähintään yksi tyyppi",
    "register.ui.usernameInfo": "Tärkeää: Tämä on käyttäjätunnuksesi kirjautumista varten.",
    "register.ui.oauthInfo": "Sinut ohjataan FRM:n kirjautumissivulle, jossa voit kirjautua sisään käyttäjätiedoillasi ja palata sitten verkkosivustolle.",
    "search.allDistances": "Kaikki etäisyydet",
    "search.andFilter": "ja suodatinasetukset",
    "search.closeTags": "Piilota tunnisteet",
    "search.for": "osoitteeseen",
    "search.resetPosition": "Nollausasento",
    "search.zipCountries": "Postinumerot alkaen",
    "search.geoPositionBrowser": "Aseman määrittäminen selaimen kautta",
    "search.geoPositionFault": "Sijainnin määrittäminen selaimella ei ole mahdollista",
    "search.geoPositionTitle": "Aseta sijainti läheisyyshakua varten",
    "search.selectedFilters": "Valitut suodattimet",
    "search.selectFilters": "Suodata haku luokkien mukaan",
    "search.hits": "Hit",
    "search.openTags": "Näytä tunnisteet",
    "search.removeDatefilter": "Poista päivämäärä suodatin",
    "search.resulted": "paljasti",
    "search.resultType.category": "Luokka",
    "search.resultType.entry": "Sisäänkirjautuminen",
    "search.resultType.event": "Tapahtuma",
    "search.resultType.group": "Ryhmä",
    "search.resultType.page": "Sivu",
    "search.resultType.person": "Henkilö",
    "search.resultType.post": "Lähetä",
    "search.resultType.product": "Tuote",
    "search.resultType.article": "Artikkeli",
    "search.resultType.job": "Työ",
    "search.resultType.recipe": "Resepti",
    "search.search": "Etsi",
    "search.result": "Tulos",
    "search.results": "Tulokset",
    "search.searchBarPlaceholder": "Etsi...",
    "search.searchBarPlaceholderEntries": "Etsi jälleenmyyjän nimen tai jälleenmyyjän osoitteen perusteella.",
    "search.searchBarPlaceholderEntriesPosition": "Oman sijainnin (esim. kaupungin) ympärysmittainen haku...",
    "search.searchBarPlaceholderEntriesPositionZip": "...tai postinumero",
    "search.searchBarPlaceholderGeoSearch": "Kirjoita osoitteesi...",
    "search.searchBarPlaceholderMobile": "Etsi...",
    "search.searchBarPlaceholderProducts": "Etsi tuotteita",
    "search.searchBarPositionTooltip": "Sijainnin määrittäminen tai sijainnin ja osoitteen syöttäminen",
    "search.showAll": "Näytä kaikki",
    "search.globalSearch": "Hakusivu",
    "social.comment": "Kommentti",
    "social.Comment": "Kommentti",
    "social.CopycatJoiner": "Unelmoi merkintäsi",
    "social.dream.donateAmount": "Tuki rahasummalla",
    "social.dream.donateDreampoints": "Tuki Dreampointsin kanssa",
    "social.dream.donateInfo": "Viesti on yksityinen ja sen voi lukea vain merkinnän omistaja.",
    "social.dream.donateTip": "Tuki neuvojen ja toimien avulla",
    "social.dream.done": "DONE",
    "social.dream.edit": "Muokkaa merkintää",
    "social.dream.findDreams": "Löydä täältä pieniä ja suuria merkintöjä ja tee merkinnästäsi todellinen ja/tai auta muita toteuttamaan merkintöjään.",
    "social.dream.followedDreams": "Seuraamasi merkinnät",
    "social.dream.giveEmail": "Jaa sähköpostiosoitteeni",
    "social.dream.givePhone": "Jaa puhelinnumeroni",
    "social.dream.joinerApproved": "Osallistut tähän merkintään",
    "social.dream.joinerButton": "Liity tähän merkintään",
    "social.dream.joinerButtonMobile": "Osallistu",
    "social.dream.joinerDream": "Ryhmän unelma",
    "social.dream.joinerInfo": "Jos merkinnän omistaja vahvistaa osallistumisesi, tästä merkinnästä tulee ryhmähuone, jossa sinulla on lisäoikeuksia.",
    "social.dream.joinerMessage": "Kirjoita tähän viestisi merkinnän omistajalle:",
    "social.dream.joinerPending": "Osallistumisen on vielä vahvistettava osallistumisesi.",
    "social.dream.joinerSupport": "Tuet tätä merkintää",
    "social.dream.joinerSupportButton": "Tue tätä merkintää",
    "social.dream.joinerSupportButtonMobile": "Tuki",
    "social.dream.joinerSupportInfo": "Teemme jatkuvasti parannuksia, kuten rahan tai sisäänpääsypisteiden tukemista. Pysy kuulolla.",
    "social.dream.joinerSupportPending": "Merkinnän omistajan on edelleen sallittava tukesi",
    "social.dream.member": "Jäsen",
    "social.dream.members": "Jäsenet",
    "social.dream.meToo": "Minäkin haluan tehdä sen",
    "social.dream.meTooInfo": "Näytä merkinnän omistajalle, että pidät merkintää hienona ja että haluat tehdä tämän merkinnän.",
    "social.dream.noFollow": "Et seuraa mitään merkintää!",
    "social.dream.noJoiner": "Et ole vielä jäsenenä ryhmässä unelma",
    "social.dream.noLikes": "Et pidä vielä yhdestäkään merkinnästä!",
    "social.dream.noSupport": "Et tue merkintää",
    "social.dream.registerFirstText": "Jotta voit jakaa merkinnän, tukea merkintää tai ilmoittaa, että haluat myös tehdä tämän merkinnän, sinun on oltava kirjautuneena sisään. Rekisteröityminen ja merkinnän jakaminen on nopeaa ja ilmaista. Klikkaa vain \"Rekisteröidy\" -painiketta.",
    "social.dream.registerFirstTitle": "Unelmoimalla mukana, tukemalla, jopa tekemällä?",
    "social.dream.settings": "Kirjautumisasetukset",
    "social.dream.shareTitle": "Jaa unelma",
    "social.dream.similar": "Samankaltaiset merkinnät",
    "social.dreamline.addImageToDream": "Lisää tämä kuva kirjoitukseesi",
    "social.dreamline.addMedia": "Lisää kuva / video",
    "social.dreamline.allActivities": "Kaikki Dreamline-toiminta",
    "social.dreamline.anonymous": "Anonyymi",
    "social.dreamline.commentButton": "Kommentti",
    "social.dreamline.comments": "kirjoittaa:",
    "social.dreamline.depublished": "Tämä kommentti on poistettu sopimattoman käytöksen vuoksi.",
    "social.dreamline.dreampointsInfo": "Nämä ovat merkinnän unelmapisteet. Löydät lisätietoja Dreampointsista profiilistasi.",
    "social.dreamline.fewDreampoints": "on vain muutama Unelmapiste. Kommentoi, tykkää tai jaa merkintä.",
    "social.dreamline.filterBySteps": "Suodata vaiheiden mukaan",
    "social.dreamline.filterComment": "Kommentit",
    "social.dreamline.filteredBy": "Toiminnot suodattaa",
    "social.dreamline.filteredBySteps": "Suodatettu vaiheiden mukaan",
    "social.dreamline.filterFavorite": "Seuraa",
    "social.dreamline.filterLike": "Tykkää",
    "social.dreamline.filterShare": "Osakkeet",
    "social.dreamline.filterStep": "Askeleet",
    "social.dreamline.flagged": "Tämä viesti raportoitiin",
    "social.dreamline.isComment": "kommentit",
    "social.dreamline.isCopycatJoiner": "myös unelma",
    "social.dreamline.isFirstUpdate": "luotu",
    "social.dreamline.isShare": "jaettu",
    "social.dreamline.isStep": "otti uuden askeleen",
    "social.dreamline.isUpdate": "päivitetty",
    "social.dreamline.lastEntry": "Viimeisen merkinnän päivitys",
    "social.dreamline.locked": "Tämä sisältö näkyy vain muille unelmoijille",
    "social.dreamline.lockedCTA": "Liity tähän merkintään nyt",
    "social.dreamline.noMessages": "Tällä nimikkeellä ei ole vielä toimintaa. Kirjoita ensimmäinen kommentti nyt.",
    "social.dreamline.noMessagesFiltered": "Ei toimintaa näillä suodatinasetuksilla tälle merkinnälle.",
    "social.dreamline.nowFollower": "seuraa tätä merkintää!",
    "social.dreamline.nowJoiner": "on nyt kannattaja",
    "social.dreamline.nowLikes": "tykkää tästä merkinnästä nyt!",
    "social.dreamline.nowSharing": "jakoi tämän merkinnän!",
    "social.dreamline.onlyJoinerVisible": "Näkyy vain muille unelmoijille/tukijoille",
    "social.dreamline.prefixComment": "on tämä merkintä ennen",
    "social.dreamline.prefixCopycatJoiner": "Haluaisin lisätä tämän merkinnän, koska",
    "social.dreamline.prefixFavorite": "on seurannut tätä merkintää vuodesta",
    "social.dreamline.prefixJoiner": "tukee tätä merkintää, koska",
    "social.dreamline.prefixLike": "tykkää tästä merkinnästä vuodesta",
    "social.dreamline.prefixShare": "on tämä merkintä ennen",
    "social.dreamline.prefixStep": "on ennen",
    "social.dreamline.prefixUpdate": "on tämä merkintä ennen",
    "social.dreamline.profile": "Profiili",
    "social.dreamline.provideImage": "Asetan tämän kuvan merkinnän omistajan käyttöön.",
    "social.dreamline.resetFilter": "Nollaa suodatin",
    "social.dreamline.sendComment": "Lähetä",
    "social.dreamline.sharePart": "Jaa",
    "social.dreamline.toggleStream": "Kommentit",
    "social.dreamline.toggleStreamSingle": "Kommentti",
    "social.dreamline.writeComment": "Kirjoita kommentti...",
    "social.Favorite": "Seuraa",
    "social.follow": "Seuraa",
    "social.Follow": "Seuraa",
    "social.hasLiked": "Pidät jo tästä merkinnästä.",
    "social.join": "Liity",
    "social.Join": "Liity",
    "social.Joiner": "Dreaming along",
    "social.like": "Kuten",
    "social.Like": "Kuten",
    "social.report.headlinePrefix": "Sinä olet siellä",
    "social.report.headlineSuffix": "raportoida.",
    "social.report.login": "Sinun täytyy olla kirjautuneena sisään ilmoittaaksesi viestistä.",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "Muu loukkaava sisältö",
    "social.report.reportScam": "Vääristely, kiusaaminen.",
    "social.report.reportSex": "Seksuaalinen sisältö",
    "social.report.reportSpam": "Tämä on roskapostia",
    "social.report.reportViolence": "Väkivallan kuvaaminen",
    "social.report.send": "Lähetä viesti",
    "social.report.title": "Raportti",
    "social.share": "Jaa",
    "social.Share": "Jaa",
    "syd.ui.dreamerCommercial": "B2B-standardi",
    "syd.ui.userCommercial": "B2B Dreamer",
    "user.company.addUser": "Luo lisäkäyttäjiä",
    "user.company.inviteUser": "Lähetä kutsupostia",
    "user.company.userIsInvited": "Kutsusähköposti lähetettiin",
    "user.company.userIsActive": "Käyttäjä on aktiivinen",
    "user.company.userIsNotActive": "Käyttäjää ei ole vielä aktivoitu",
    "user.company.additionalWebsite": "Yrityksen muu verkkosivusto (vain verkkotunnus ilman https://).",
    "user.company.companyName": "Yrityksen nimi",
    "user.company.contactFor": "Yhteystiedot",
    "user.company.contactForDefault": "Yleinen yhteyshenkilö",
    "user.company.copyContact": "Ota haltuun yhteystiedot yrityksen tiedoista merkintää varten.",
    "user.company.email": "Yhteydenotto sähköpostiosoite",
    "user.company.facebook": "Yrityksen Facebook-tili (vain tilin nimi)",
    "user.company.fax": "Faksinumero kirjallista yhteydenottoa varten",
    "user.company.google": "Yrityksen Google+-tili (vain tilin nimi)",
    "user.company.instagram": "Yrityksen Instagram-tili (vain tilin nimi)",
    "user.company.linkedin": "Yrityksen LinkedIn-tili (vain tilin nimi)",
    "user.company.newCustomerAccount": "Luo yritystili",
    "user.company.noCustomerValidation": "Yritystiliäsi ei ole vielä aktivoitu.",
    "user.company.noCustomerYet": "Rekisteröinnillesi ei ole vielä määritetty yritystiliä.",
    "user.company.notAllowed": "Et voi tehdä mitään muutoksia, koska käyttäjätilasi ({{type}}) ei salli sitä.",
    "user.company.phone": "Yhteyshenkilön puhelinnumero",
    "user.company.pinterest": "Yrityksen Pinterest-tili (vain tilin nimi)",
    "user.company.remarks": "Huomautukset",
    "user.company.registerNumber": "Kaupparekisterinumero",
    "user.company.registerAuth": "Paikallinen tuomioistuin",
    "user.company.acerCode": "Energialähteesi ACER-koodi",
    "user.company.taxNumber": "Veronumero",
    "user.company.type.default": "Standardi",
    "user.company.type.procurator": "Valtuutettu allekirjoittaja",
    "user.company.type.manager": "Toimitusjohtaja",
    "user.company.twitter": "Twitter-yritystili (vain tilin nimi ilman @)",
    "user.company.ustid": "UStID",
    "user.company.ustid.notValid": "Ilmoittakaa voimassa oleva alv-tunnistenumero<br>(jos saatavilla).",
    "user.company.website": "Yrityksen verkkosivusto (vain verkkotunnus ilman https://)",
    "user.company.xing": "Yrityksen Xing-tili (vain tilin nimi)",
    "user.company.youtube": "Yrityksen Youtube-tili (vain tilin nimi)",
    "user.message.after-save-error": "Muutoksia ei voitu tallentaa.",
    "user.message.after-save-success": "Muutoksesi on tallennettu.",
    "user.message.can-not-login": "Emme voi kirjautua sisään näillä käyttäjä- ja salasanamerkinnöillä! Onko käyttäjänimi kirjoitettu oikein? Kirjoititko salasanan oikein? Jokin on saattanut mennä pieleen rekisteröinnin aikana.",
    "user.message.can-not-logout": "Emme voineet kirjoittaa sinua ulos!",
    "user.message.conflict": "Tämä käyttäjätunnus on jo olemassa, valitse toinen!",
    "user.message.fileExistsError": "Lataus epäonnistui. Tiedosto on jo olemassa",
    "user.message.fileSizeError": "Lataus epäonnistui. Tiedosto on liian suuri",
    "user.message.loginFailureBottom": "Ehkä sinulla ei ole vielä Entryer-tiliä?",
    "user.message.loginFailureTop": "Onko käyttäjänimesi kirjoitettu oikein? Kirjoititko salasanasi oikein?",
    "user.message.registration-error": "Rekisteröinnissäsi on tapahtunut virhe!",
    "user.message.registration-success": "Kiitos rekisteröinnistä",
    "user.message.unknownError": "Tuntematon virhe tiedostoa ladattaessa",
    "user.message.upload-success": "Lataus onnistui",
    "user.message.verify-error": "Sähköpostiosoitettasi ei voitu vahvistaa. Ota yhteyttä ylläpitäjään.",
    "user.message.verify-success": "Sähköpostiosoitteesi on vahvistettu. Voit nyt rekisteröityä:",
    "user.ui.accountGreeting": "Hei,",
    "user.ui.accountMenu": "Kotisivu ja tiedot",
    "user.ui.accountMenuActivities": "Toiminta",
    "user.ui.accountMenuCompanySettings": "Yrityksen tiedot",
    "user.ui.accountMenuEntries": "Omat merkinnät",
    "user.ui.accountMenuEntryPre": "Merkinnät,",
    "user.ui.accountMenuEvents": "Tapahtumani",
    "user.ui.accountMenuFavorites": "Suosikkini",
    "user.ui.accountMenuFollows": "Seuraamani merkinnät",
    "user.ui.accountMenuImages": "Kuvat/Videot/Dokumentit",
    "user.ui.accountMenuJoin": "Ryhmäni unelmat",
    "user.ui.accountMenuLikes": "Pidän merkinnöistä",
    "user.ui.accountMenuMeasuring": "Mittauspaikat ja -tiedot",
    "user.ui.accountMenuMessages": "Uutiset",
    "user.ui.accountMenuNewsletter": "Uutiskirjeeni tilaukset",
    "user.ui.accountMenuPoi": "Sijaintini",
    "user.ui.accountMenuPress": "Artikkelini",
    "user.ui.accountMenuProducts": "Tuotteeni",
    "user.ui.accountMenuJobs": "Työni tarjoaa",
    "user.ui.accountMenuProfile": "Käyttäjäprofiili",
    "user.ui.accountMenuRatings": "Arvostelut",
    "user.ui.accountMenuRegistrationData": "Kirjautumistiedot",
    "user.ui.accountMenuReviews": "Arvostelut",
    "user.ui.accountMenuSettings": "Asetukset",
    "user.ui.accountMenuSkillsOffer": "Tarjoan...",
    "user.ui.accountMenuSkillsRequest": "Tarvitsen...",
    "user.ui.accountMenuOffer": "Tarjoukseni",
    "user.ui.accountMenuRequest": "Pyyntöni",
    "user.ui.accountMenuSkillsSearch": "Pyynnöt ja tarjoukset",
    "user.ui.accountMenuSubscriptions": "Tilaukset",
    "user.ui.accountMenuSubscritions": "Tilaukseni",
    "user.ui.accountMenuSupport": "Tuetut merkinnät",
    "user.ui.accountMenuSupports": "Tukemani merkinnät",
    "user.ui.accountMenuTender": "Tarjoukset",
    "user.ui.accountMenuOrders": "Tilaukseni",
    "user.ui.accountNoMessages": "Et ole vielä saanut yhtään viestiä.",
    "user.ui.accountProgressbar": "Profiilisi on {{value}}% täydellinen",
    "user.ui.accountProgressbarEntry": "Osallistumisesi on {{value}}% valmis",
    "user.ui.accountSettings": "Lisää asetuksia",
    "user.ui.accountCookieSettings": "Klikkaa tästä säätääksesi asetuksia.",
    "user.ui.accountCookieEssential": "Essential",
    "user.ui.accountCookieAnalysis": "Analyysitiedot",
    "user.ui.accountCookieSocial": "Sosiaalinen media",
    "user.ui.accountCookieReviews": "Arvostelut",
    "user.ui.accountToolbarLogin": "Kirjaudu sisään",
    "user.ui.accountToolbarLoginTooltip": "Rekisteröidy ilmaiseksi ja aloita",
    "user.ui.accountToolbarLogout": "Kirjaudu ulos",
    "user.ui.adminPreview": "Esikatselu",
    "user.ui.backToLogin": "Takaisin rekisteröintisivulle",
    "user.ui.birthday": "Syntymäpäivä",
    "user.ui.cannotLogin": "Etkö voi kirjautua sisään? Klikkaa tästä nollataksesi salasanasi.",
    "user.ui.changePassword": "Haluaisin vaihtaa salasanani",
    "user.ui.changeUserGroup": "Muu tilaus",
    "user.ui.city": "Kaupunki",
    "user.ui.companyName": "Yrityksen nimi",
    "user.ui.companyNameTooltip": "Tärkeää: Merkintänne näkyy myös näissä yritysten nimissä.",
    "user.ui.country": "Maa",
    "user.ui.createNewAccount": "Luo uusi käyttäjätili",
    "user.ui.dataDelete": "muut merkinnät. Haluatko poistaa tiedoston pysyvästi? Aina kun tätä tiedostoa käytetään, se korvataan paikanpitäjällä.",
    "user.ui.dataUsage": "Tiedosto tallennetaan osoitteeseen",
    "user.ui.dataUsageNone": "Tiedosto ei ole vielä käytössä. Haluatko poistaa tiedoston pysyvästi?",
    "user.ui.deleteImage": "Poista kuva",
    "user.ui.description": "Kirjoita tähän yleinen kuvaus henkilöstä tai yrityksestä.",
    "user.ui.documents": "Asiakirjat",
    "user.ui.documentSearch": "Asiakirjahaku",
    "user.ui.download": "{{title}} lataa",
    "user.ui.email": "Sähköpostiosoite",
    "user.ui.fillOutForm": "Rekisteröityminen edellyttää, että kaikki pakolliset kentät on täytetty kokonaan.",
    "user.ui.firstName": "Etunimi",
    "user.ui.academicTitle": "Akateeminen nimi",
    "user.ui.gender": "M/F",
    "user.ui.genderFemale": "nainen",
    "user.ui.genderFemaleSalutation": "Ms",
    "user.ui.genderMale": "mies",
    "user.ui.genderMaleSalutation": "Herra",
    "user.ui.genderDiverse": "monipuolinen",
    "user.ui.genderThird": "Ei määritelty",
    "user.ui.housenumber": "Ei.",
    "user.ui.iAmCustomer": "Haluaisin luoda B2B-tilin",
    "user.ui.internalInfo": "Sinulle on viestejä. Klikkaa tästä.",
    "user.ui.lastLogin": "Viimeinen rekisteröintipäivä",
    "user.ui.languageAvailable": "Saatavilla omalla kielelläsi",
    "user.ui.languageMissing": "Saatavana vain",
    "user.ui.lastName": "Sukunimi",
    "user.ui.loginButton": "Kirjaudu sisään",
    "user.ui.loginTitle": "Käyttäjän kirjautuminen",
    "user.ui.memberSince": "Jäsen vuodesta",
    "user.ui.mobileNumber": "Matkapuhelinnumero",
    "user.ui.myAddedStorage": "Toimitetut kuvat",
    "user.ui.myStorage": "Oma hakemistoni",
    "user.ui.noRegisterMessage": "Käyttäjätiliä varten ota yhteyttä ilmoitettuihin yhteysosoitteisiin. Et voi rekisteröityä itse käyttäjäksi.",
    "user.ui.oldPassword": "Edellinen salasana",
    "user.ui.password": "Salasana",
    "user.ui.phoneType": "Puhelin",
    "user.ui.phoneTypeMobile": "Mobiili",
    "user.ui.phoneTypeLandline": "Lankapuhelin",
    "user.ui.pleaseSelect": "Valitse",
    "user.ui.pleaseSelectSalutation": "Valitse tervehdys",
    "user.ui.registerButton": "Käyttäjätilin perustaminen nyt",
    "user.ui.registerMessage": "Rekisteröidy nyt ja aloita heti. Se on nopeaa ja helppoa.",
    "user.ui.registerTitle": "Oletko uusi kanssamme?",
    "user.ui.requestPasswordReset": "Pyydä uusi salasana ja poista vanha salasana",
    "user.ui.requestPasswordSuccess": "Saat pian sähköpostiviestin, jossa on nollauslinkki.",
    "user.ui.requestPasswordError": "Tuntematon virhe salasanaa nollattaessa.",
    "user.ui.resetPassword": "Nollaa salasana",
    "user.ui.setPassword": "Aseta salasana",
    "user.ui.resetPasswordPage": "Nollaa salasanasi",
    "user.ui.setPasswordPage": "Aseta salasanasi",
    "user.ui.resetPasswordSuccess": "Salasanasi on palautettu, voit nyt kirjautua sisään.",
    "user.ui.setPasswordSuccess": "Salasanasi on asetettu, voit nyt kirjautua sisään.",
    "user.ui.selectCustomerType": "Olet ... valitse",
    "user.ui.stayLoggedin": "Pysy kirjautuneena sisään",
    "user.ui.stayLoggedinInfo": "Jos kyseessä on julkisesti saatavilla oleva laite, poista tämä vaihtoehto käytöstä ja kirjaudu ulos uudelleen viimeistellessäsi.",
    "user.ui.street": "Street",
    "user.ui.salutation": "Tervehdys",
    "user.ui.title": "Otsikko",
    "user.ui.thankYouForSubscribing": "Kiitos, että tilasit uutiskirjeemme.",
    "user.ui.errorAtSubscribing": "Virhe uutiskirjeen tilaamisessa",
    "user.ui.unsubscribe": "kirjaudu ulos",
    "user.ui.unsubscribePage": "Haluatko todella peruuttaa uutiskirjeemme?",
    "user.ui.uploadDrop": "Lataa tiedosto raahaamalla se tänne tai",
    "user.ui.uploadSelectFile": "valitse tiedosto",
    "user.ui.uploadToMyStorage": "Lataa uusia kuvia hakemistooni",
    "user.ui.uploadUserImage": "Valitse käyttäjän kuva",
    "user.ui.username": "Sähköpostiosoite",
    "user.ui.userName": "Käyttäjätunnus",
    "user.ui.visibilityInfo": "Vinkki: Sinä päätät, mitä profiilistasi näytetään julkisesti! Napsauta silmäsymbolia asettaaksesi merkintöjen näkyvyyden:",
    "user.ui.zip": "Postinumero",
    "user.ui.phone": "Puhelinnumero",
    "user.user": "Osallistuja",
    "user.users": "Merkinnät",
    "widgets.shoppingCart.wishlist": "Muistio ja toivelista",
    "widgets.shoppingCart.wishlist.empty": "Sinulla ei ole vielä yhtään tuotetta muistilistallasi ja toivelistallasi.",
    "widgets.shoppingCart.cart": "Ostoskori",
    "user.product.addedToFavorites": "Tuote on lisätty suosikkeihisi",
    "order.status.zero": "Saatu tilaus",
    "order.status.one": "Käynnissä",
    "order.status.two": "Tilaus on lähetetty",
    "order.status.three": "Luettu",
    "order.status.four": "Paluu",
    "order.status.default": "Tuntematon ammatti valtio",
    "country.germany": "Saksa",
    "country.de": "Saksa",
    "country.austria": "Itävalta",
    "country.at": "Itävalta",
    "country.belgium": "Belgia",
    "country.denmark": "Tanska",
    "country.bulgaria": "Bulgaria",
    "country.croatia": "Kroatia",
    "country.cyprus": "Kypros",
    "country.czechia": "Tšekin tasavalta",
    "country.estonia": "Viro",
    "country.finland": "Suomi",
    "country.france": "Ranska",
    "country.fr": "Ranska",
    "country.greece": "Kreikka",
    "country.hungary": "Unkari",
    "country.ireland": "Irlanti",
    "country.italy": "Italia",
    "country.it": "Italia",
    "country.latvia": "Latvia",
    "country.lithuania": "Liettua",
    "country.luxembourg": "Luxemburg",
    "country.lu": "Luxemburg",
    "country.malta": "Malta",
    "country.netherlands": "Alankomaat",
    "country.poland": "Puola",
    "country.portugal": "Portugali",
    "country.romania": "Romania",
    "country.slovakia": "Slovakia",
    "country.slovenia": "Slovenia",
    "country.spain": "Espanja",
    "country.sweden": "Ruotsi",
    "country.norway": "Norja",
    "country.switzerland": "Sveitsi",
    "country.ch": "Sveitsi",
    "country.turkey": "Turkki",
    "country.unitedKingdom": "Yhdistynyt kuningaskunta",
    "country.gb": "Iso-Britannia",
    "openingHours.tNowClosed": "Nyt suljettu",
    "openingHours.tOpeningHoursNotKnown": "Muita aukioloaikoja ei tiedossa",
    "openingHours.tNowOpen": "Avaa nyt",
    "openingHours.tNowStillOpen": "Nyt vielä avoinna",
    "openingHours.tNextOpeningHourUnknown": "seuraava avajaispäivä tuntematon",
    "openingHours.tSoonOpenFrom": "pian jälleen auki alkaen",
    "openingHours.tFrom": "osoitteesta",
    "openingHours.tUntil": "osoitteeseen",
    "openingHours.tOpenAgainFrom": "avaa uudelleen",
    "openingHours.tOpenAgainFrom2": "Avaa uudelleen",
    "comparison.fields.measurements": "Massa",
    "comparison.fields.weight": "Paino",
    "application.tag.neu": "Uusi",
    "application.tag.sale": "Myynti",
    "application.tag.eco": "Eco",
    "application.tag.sonderrabatt": "Erityisalennus",
    "job.application.selectLocation": "Valitse sijaintisi",
    "job.application.location": "Saatavilla seuraavilla alueilla"
  };
});
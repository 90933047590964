define('webapp/services/recipe-container', ['exports', 'webapp/services/abstract-container'], function (exports, _abstractContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractContainer.default.extend({
    containerName: 'recipeContainer',

    fetchDetail: function fetchDetail(detailSlug) {
      var myStorage = this.get('myStorage');
      return myStorage.recipe.ajaxGet({ param: detailSlug }).then(function (res) {
        return res;
      });
    }
  });
});
define('webapp/helpers/test-regex', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    if (!params[0]) {
      return false;
    }
    return params[0].match(params[1]);
  });
});
define('webapp/routes/account/newsletter/list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),

    model: function model(params) {
      var myStorage = this.get('myStorage');
      return myStorage.mailingList.ajaxGet({ param: 'by_id/' + params.id });
    }
  });
});
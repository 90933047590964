define('webapp/routes/account/favorites/table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),
    languageService: Ember.inject.service(),
    cmsService: Ember.inject.service(),

    model: function model() {
      var myStorage = this.get('myStorage');

      var sessionId = this.get('cmsService.sessionId');
      var lang = this.get('languageService.selectedLanguage');
      var query = { sessionId: sessionId, lang: lang };
      var params = {
        path: 'compare',
        query: query
      };

      return myStorage.favorite.ajaxGet(params).then(function (res) {
        return res;
      });
    }
  });
});
define("webapp/locales/ua/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.entryBannerImageNew": "Встановіть головне зображення для свого запису.",
    "account.ui.profileBannerImageNew": "Встановіть головне зображення для свого профілю.",
    "account.ui.register": "Зареєструватися",
    "account.ui.registeredAs": "На даний момент ви зареєстровані як",
    "account.ui.personate": "Змінити обліковий запис користувача",
    "account.ui.userNameNotEditableTooltip": "Ваше ім'я користувача. Ви не можете змінити введене вами ім'я користувача. Для зміни імені користувача, будь ласка, зверніться до Вашої контактної особи. Важливо: Після зміни необхідно повторно увійти під новим ім'ям користувача.",
    "account.ui.additionalDeliveryAddresses": "Ви вказали різні адреси доставки.",
    "account.ui.editDeliveryAddresses": "Редагувати адресу доставки",
    "account.ui.editInvoiceAddress": "Редагувати адресу компанії",
    "account.ui.noDeliveryAddresses": "Ви ще не ввели іншу адресу доставки.",
    "account.ui.addDeliveryAddresses": "Додати {{further}} Додати іншу адресу доставки",
    "account.ui.addDeliveryAddressTitle": "Додати адресу доставки",
    "account.ui.editDeliveryAddressTitle": "Редагувати адресу доставки",
    "account.ui.isDefaultAddress": "Це стандартна адреса доставки",
    "account.ui.saveToApplyChanges": "Ви повинні зберегти зміни, після чого ви можете, наприклад, вказати інші адреси доставки.",
    "accountTypes.aussteller": "Експонент",
    "accountTypes.gastgeber": "Ведучий",
    "accountTypes.veranstalter": "Організатор",
    "application.beta.infotext": "Підписки не створювалися.",
    "application.breadcrumbsHome": "Головна сторінка",
    "application.month": "в місяць",
    "application.newsletter.alreadySubscribed": "Ви вже є передплатником {{list}} інформаційний бюлетень. Доступ до підписки на розсилку можна отримати в особистому кабінеті.",
    "application.newsletter.alreadySubscribedNoList": "Ви вже підписалися на розсилку новин",
    "application.newsletter.goToList": "Список розсилки дзвінків",
    "application.newsletter.subscribeButton": "Введіть свою електронну адресу, щоб підписатися на розсилку",
    "application.newsletter.subscribeButtonMobile": "E-mail",
    "application.newsletter.noAccess": "Запропонований інформаційний бюлетень не може бути підписаний з Вашими дозволами.",
    "application.newsletter.subscribe": "Підписатися",
    "application.newsletter.subscribeList": "Ви підписалися на цю розсилку:",
    "application.newsletter.subscribeThanks": "Дякуємо, що підписалися на нашу розсилку. Найближчим часом ви отримаєте останню версію бюлетеня.",
    "application.newsletter.subscribeThanksOptIn": "Дякуємо, що підписалися на нашу розсилку. Ви отримаєте ще один електронний лист з підтвердженням реєстрації для активації.",
    "application.newsletter.subscribeWithAccount": "Підпишіться під своїм поточним логіном.",
    "application.noCosts": "Безкоштовно",
    "application.placeholderImageText": "Без ілюстрації",
    "application.searchAllGroups": "всього",
    "application.searchAllRegions": "у всіх місцях",
    "application.ui.access": "доступ",
    "application.ui.action": "Дія",
    "application.ui.addDocument": "Додати документ",
    "application.ui.addImage": "Додати зображення",
    "application.ui.addImages": "Додавання фотографій",
    "application.ui.addMedia": "Додавання фото/відео",
    "application.ui.addVideo": "Додати відео",
    "application.ui.advancedEdit": "Розширений",
    "application.ui.all": "Всі",
    "application.ui.alreadyFavorite": "Цей пункт у вашому списку спостереження",
    "application.ui.makeFavorite": "Додайте цей пункт до свого списку спостереження",
    "application.ui.also": "Також",
    "application.ui.alphabetical": "За алфавітом",
    "application.ui.and": "і",
    "application.ui.at": "на",
    "application.ui.in": "в",
    "application.ui.atHour": "на",
    "application.ui.hour": "Годинник",
    "application.ui.buy": "купувати",
    "application.ui.certificate": "Сертифікати",
    "application.ui.confirmDeleteEntry": "Видалити запис {{title}} видалити безповоротно?",
    "application.ui.contact": "Контакти",
    "application.ui.count": "Кількість",
    "application.ui.currentlyActive": "Виконується прямо зараз",
    "application.ui.deleteEntry": "видалити",
    "application.ui.discardUnsavedChanges": "Відкиньте зміни і продовжуйте",
    "application.ui.discardUnsavedChangesMobile": "Викинути",
    "application.ui.distance": "Відстань",
    "application.ui.distant": "вилучено",
    "application.ui.dreampoints": "Точки мрії",
    "application.ui.dreampointsAllDreams": "Мрії, про які ви мрієте",
    "application.ui.dreampointsDonate": "Мрії про донорство",
    "application.ui.dreampointsTooltip": "Ви можете заробити бали мрії за свою участь у конкурсі",
    "application.ui.edit": "зміна",
    "application.ui.editDescription": "Редагувати опис",
    "application.ui.editEntry": "редагування",
    "application.ui.editEvent": "Редагувати подію",
    "application.ui.editImage": "Редагувати зображення",
    "application.ui.editPoi": "Редагувати місце розташування",
    "application.ui.editProduct": "Редагувати товар",
    "application.ui.editProfile": "Редагувати мій профіль",
    "application.ui.editShownProfile": "Змінити відображення мого профілю",
    "application.ui.entriesFocus": "Вибрані записи у Фокусі:",
    "application.ui.entriesLatest": "Останні записи у Фокусі:",
    "application.ui.email": "Електронна пошта",
    "application.ui.for": "для",
    "application.ui.from": "від",
    "application.ui.fullView": "Загальний вигляд",
    "application.ui.gallery": "Зображення",
    "application.ui.galleryImages": "до галереї...",
    "application.ui.gavePoints": "дала тобі {{points}} балів.",
    "application.ui.goToEntry": "Введення виклику",
    "application.ui.goToEvent": "Виклик події",
    "application.ui.goToPoi": "Місце виклику",
    "application.ui.goToMeasuring": "Вимірювальні пункти",
    "application.ui.goToProduct": "Продукт для дзвінків",
    "application.ui.hasBeen": "був",
    "application.ui.here": "Поруч",
    "application.ui.invalidEmbed": "Цей URL-адресу не можна вбудовувати.",
    "application.ui.imprintLink": "Відповідальні та несуть юридичну відповідальність за зміст цієї підсторінки {{entry}} - Відбиток",
    "application.ui.isActive": "Активний",
    "application.ui.isNotActive": "Інваліди",
    "application.ui.knowMore": "Всі {{aboutPre}} {{about}}",
    "application.ui.language.de": "Deutsch",
    "application.ui.language.en": "English",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "Italiano",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "Поточна вибрана мова",
    "application.ui.language.switchTo": "Змінити мову на ",
    "application.ui.mailBodyPre": "Доброго дня, я знайшов дещо цікаве:",
    "application.ui.mailSubject": "Я знайшов цікаве посилання:",
    "application.ui.mandatory": "Обов'язкове поле",
    "application.ui.memberSince": "Член Ради директорів з",
    "application.ui.mobileRotate": "Поверніть для повноекранного перегляду",
    "application.ui.more": "більше",
    "application.ui.name": "Ім'я та прізвище",
    "application.ui.navigateOneLevelUp": "Повернутися до батьківської папки",
    "application.ui.new": "Новий",
    "application.ui.newEntry": "Створити новий запис",
    "application.ui.newEvent": "Створити нову подію",
    "application.ui.newPoi": "Створити нову локацію",
    "application.ui.newProduct": "Створити новий продукт",
    "application.ui.noEntry": "Ви ще не створили запис. Створіть свій перший запис прямо зараз...",
    "application.ui.noEntryProfile": "ще не створив жодного запису.",
    "application.ui.noEvent": "Ви ще не створили подію. Створіть свою першу подію прямо зараз...",
    "application.ui.noPoi": "Ви ще не створили локацію. Створіть свою першу локацію прямо зараз...",
    "application.ui.noJob": "Ви ще не створили жодної вакансії. Створіть свою першу роботу зараз...",
    "application.ui.noProduct": "Ви ще не створили продукт. Створіть свій перший продукт прямо зараз...",
    "application.ui.notEditable": "не може бути змінено",
    "application.ui.nothingFound": "За цим запитом результатів не знайдено.",
    "application.ui.offer": "Пропозиції",
    "application.ui.offer.offers": "Пропозиції",
    "application.ui.offer.acceptOffer": "Прийняти пропозицію",
    "application.ui.offer.rejectOffer": "Відхилити пропозицію",
    "application.ui.offer.noOffers": "Пропозицій немає",
    "application.ui.offer.expiresAt": "Пропозиція діє до",
    "application.ui.offer.offerExpires": "Термін дії пропозиції закінчується",
    "application.ui.offer.setOtherExpirationDate": "Встановіть іншу дату",
    "application.ui.offer.inXDays": "через 30 днів",
    "application.ui.only": "Тільки",
    "application.ui.openDocument": "Відкрити файл",
    "application.ui.or": "або",
    "application.ui.playVideo": "Відтворити відео",
    "application.ui.readMore": "Читати далі",
    "application.ui.region": "Регіон",
    "application.ui.relevance": "Актуальність",
    "application.ui.reset": "Скинути всі фільтри",
    "application.ui.routingDescription": "Схема проїзду",
    "application.ui.saveShownProfile": "Зберегти зміни профілю",
    "application.ui.saveUnsavedChanges": "Зберегти",
    "application.ui.select": "Будь ласка, оберіть",
    "application.ui.shop": "Магазин",
    "application.ui.showAllEntries": "Показати всі записи",
    "application.ui.showAllSearch": "Показати всі результати пошуку",
    "application.ui.showMoreSearch": "Показати більше результатів пошуку",
    "application.ui.resetSearch": "Скиньте вибір і повторіть пошук",
    "application.ui.sort": "Сортування",
    "application.ui.stockPhotoLinks": "Посилання на бази даних зображень",
    "application.ui.suitableFor": "Рекомендовано для",
    "application.ui.total": "Всього",
    "application.ui.totalDreampoints": "Мрії всіх ваших конкурсів",
    "application.ui.trySearch": "Ви хочете спробувати інші пошукові запити, такі як",
    "application.ui.type": "Тип",
    "application.ui.unsavedChanges": "Незбережені зміни",
    "application.ui.updated": "оновлений",
    "application.ui.uploadedFileFor": "Завантажено файл для:",
    "application.ui.userName": "Ім'я користувача",
    "application.ui.viewAsList": "Перегляд списку",
    "application.ui.viewAsTable": "Вигляд таблиці",
    "application.ui.visibleForAll": "Видимий для всіх користувачів",
    "application.ui.visibleForFollower": "Видимий для підписників",
    "application.ui.visibleForJoiner": "Видимий для столярів",
    "application.ui.visibleForNone": "Нікому не видно",
    "application.ui.visibleForSupporter": "Видимий для прихильників",
    "application.ui.years": "Роки",
    "application.ui.yearsFew": "Дитячий вік",
    "application.widgets.highestRatedEntries": "Найпопулярніші місця для мрій",
    "application.widgets.inFocus": "У фокусі",
    "application.widgets.newestEntries": "Останній {{size}} Записи",
    "application.widgets.noNewestEntries": "Нових записів не знайдено...",
    "application.widgets.showCategories": "Показати фільтр категорій",
    "application.widgets.showFilter": "Показати більше фільтрів",
    "application.ui.writeUs": "Напишіть нам...",
    "blog.ui.category": "Категорія",
    "blog.ui.categoryHeading": "З категорії",
    "blog.ui.lastUpdated": "Останнє оновлення",
    "blog.ui.openSinglePost": "Читати далі",
    "blog.ui.publishedIn": "Опубліковано в",
    "blog.ui.subCategories": "Підкатегорії",
    "blog.ui.written": "Письмовий",
    "blog.ui.writtenBy": "Написано",
    "buttons.alreadyMeToo": "Я радий, що цей запис сниться і вам.",
    "buttons.alreadyMeTooMobile": "Я радий, що цей запис сниться і вам.",
    "buttons.cancel": "Скасувати",
    "buttons.confirm": "Підтвердити",
    "buttons.createAccount": "Налаштуйте EntryerAccount зараз",
    "buttons.delete": "видалити",
    "buttons.deleteFavorite": "Більше не слідкуйте",
    "buttons.deleteLike": "Немає більше подібних",
    "buttons.meToo": "Я теж хотів би це зробити",
    "buttons.newPassword": "Запитайте новий пароль",
    "buttons.ok": "Гаразд.",
    "buttons.ready": "Готові?",
    "buttons.save": "Зберегти зміни",
    "buttons.saveMobile": "Зберегти",
    "buttons.selectThis": "Виберіть це",
    "buttons.tryAgain": "Будь ласка, спробуйте ще раз",
    "category.ui.hideCategories": "Приховати підкатегорії",
    "category.ui.oneLevelUp": "Повернутися до вищої категорії",
    "category.ui.showCategories": "Показати підкатегорії",
    "categoryFields.attachmentWidth": "Ширина захвату",
    "categoryFields.clutch": "Муфта",
    "categoryFields.companyName": "Назва компанії",
    "categoryFields.dealerInfo": "Інформація для дилерів",
    "categoryFields.diameter": "Діаметр",
    "categoryFields.displayNetPrice": "Ціна нетто",
    "categoryFields.electricalPower": "Електроенергія",
    "categoryFields.engine": "Двигун",
    "categoryFields.engineDescription": "Двигунне обладнання",
    "categoryFields.ERPname": "Ім'я в товарному менеджменті",
    "categoryFields.horsePower": "Влада",
    "categoryFields.netWidth": "Ширина колії без шин",
    "categoryFields.packingUnit": "Пакувальна одиниця",
    "categoryFields.PTO": "ВАЛ ВІДБОРУ ПОТУЖНОСТІ",
    "categoryFields.relativeGauge": "Розширює колію на",
    "categoryFields.vendorAcronym": "Коротке позначення",
    "categoryFields.volume": "Обсяг",
    "categoryFields.weight": "Вага",
    "categoryFields.wheelLock": "Блокування коліс підставки",
    "checkout.cart.title": "Мій кошик",
    "checkout.cart.item": "Стаття",
    "checkout.cart.amount": "Кількість",
    "checkout.cart.edit": "Зміна",
    "checkout.cart.price": "Ціна",
    "checkout.cart.singlePrice": "Ціна за одиницю",
    "checkout.cart.product.isNonPhysical": "Продукт не відвантажується (може бути доступний для завантаження)",
    "checkout.cart.product.requiresExpressDelivery": "Свіжий продукт з ЕКСПРЕС-відправкою. Тільки в межах D.",
    "checkout.cart.product.deliveryProblem": "На обраний товар можуть бути затримки в доставці.",
    "checkout.cart.subtotal": "Підсумок",
    "checkout.cart.deliveryCosts": "Вартість доставки",
    "checkout.cart.total": "Всього",
    "checkout.cart.help": "У Вас виникли питання щодо процесу замовлення? Ми зібрали для вас важливу інформацію.",
    "checkout.cart.subtotalInfo": "в т.ч. ПДВ",
    "checkout.cart.subtotalShipment": "плюс орієнтовні витрати на доставку",
    "checkout.cart.checkout": "Оформлення замовлення",
    "checkout.cart.checkoutWithoutUser": "Оформлення замовлення без облікового запису клієнта",
    "checkout.cart.checkoutFast": "Швидке оформлення замовлення",
    "checkout.cart.deliveryInfo": "Дата відвантаження для товарів у кошику",
    "checkout.cart.orderSuccess": "Ваше замовлення успішно виконано.",
    "checkout.cart.orderCommitting": "Ваше замовлення обробляється - будь ласка, зачекайте",
    "checkout.cart.myOrder": "Моє замовлення",
    "checkout.cart.leftover": "лівий",
    "checkout.checkout.orderNumber": "Номер замовлення",
    "checkout.checkout.account": "Обліковий запис клієнта",
    "checkout.checkout.alreadyAccount": "Вже є клієнтом? Натисніть тут, щоб зареєструватися.",
    "checkout.checkout.andOrder": "і порядок",
    "checkout.checkout.delivery": "Доставка та адреса доставки",
    "checkout.checkout.delivery.type": "Відправлення відбувається з",
    "checkout.checkout.delivery.street": "Адресний рядок 1",
    "checkout.checkout.delivery.houseNumber": "Ні.",
    "checkout.checkout.delivery.co": "Адресний суфікс (c/o)",
    "checkout.checkout.delivery.city": "Місто",
    "checkout.checkout.delivery.selectCountry": "Будь ласка, оберіть країну",
    "checkout.checkout.delivery.addressBoth": "Адреса виставлення рахунку та доставки",
    "checkout.checkout.delivery.addressInvoice": "Адреса рахунку-фактури",
    "checkout.checkout.delivery.addressDelivery": "Адреса доставки",
    "checkout.checkout.delivery.default": "Встановити цю адресу як адресу доставки за замовчуванням",
    "checkout.checkout.delivery.otherAddress": "Адреса доставки відрізняється від адреси виставлення рахунку",
    "checkout.checkout.delivery.dhlNr": "Ваш поштовий номер DHL",
    "checkout.checkout.delivery.packingStation": "Номер пакувальної станції",
    "checkout.checkout.delivery.showPackingStations": "Показати пакстанції поблизу моєї адреси",
    "checkout.checkout.delivery.wantExpressShipping": "Експрес-доставка",
    "checkout.checkout.delivery.isPickUp": "Забрати на місці",
    "checkout.checkout.delivery.addressNotVerified": "Обрана Вами адреса не є повною або ще не перевірена. Ми не можемо доставити вам. Для заповнення Вашої адреси, будь ласка, натисніть на символ олівця, що позначає Вашу адресу. Якщо у Вас виникли питання щодо процесу замовлення, наша служба підтримки клієнтів буде рада допомогти.",
    "checkout.checkout.delivery.specialCountryInfo": "Ваша країна не може бути обрана?",
    "checkout.checkout.delivery.specialCountry": "Ваша країна знаходиться за межами нашої стандартної доставки",
    "checkout.checkout.delivery.specialCountryMessage": "Окремі товари у Вашому кошику не можуть бути відправлені в усі країни. Будь ласка, видаліть деякі товари з вашого замовлення, поки ваша країна не буде обрана, або зв'яжіться з нами.",
    "checkout.checkout.payment": "Спосіб оплати",
    "checkout.checkout.paymentProblem": "При виконанні платежу виникла помилка",
    "checkout.checkout.backToPayment": "Повертаємося до налаштувань платежів.",
    "checkout.checkout.paymentTitle": "Яким чином Ви бажаєте сплатити?",
    "checkout.checkout.paymentSelected": "Обраний спосіб оплати",
    "checkout.checkout.payment.cc": "Кредитна картка",
    "checkout.checkout.payment.ccInfo": "Для здійснення платежів карткою в межах ЄС необхідно підтверджувати свою особу кожного разу, коли ви здійснюєте оплату в Інтернеті. Після оформлення замовлення Ви пройдете процедуру підтвердження, надану Вашим банком.",
    "checkout.checkout.payment.complimentary": "безкоштовно",
    "checkout.checkout.payment.paypal": "PayPal",
    "checkout.checkout.payment.paypalInfo": "Ви будете перенаправлені на PayPal для завершення процесу оплати. Ваш рахунок PayPal не буде списано, поки ви не підтвердите замовлення на наступному кроці.",
    "checkout.checkout.payment.paypalInfoExtended": "<strong>Сплачуйте за допомогою PayPal навіть без облікового запису PayPal.</strong> Ви можете сплатити за безготівковим розрахунком або іншими способами. Ви будете перенаправлені на PayPal для завершення процесу оплати. Ваш рахунок PayPal буде списано тільки тоді, коли ви підтвердите замовлення на наступному кроці.",
    "checkout.checkout.payment.sepa": "Прямий дебет (SEPA)",
    "checkout.checkout.payment.sepaLegal": "Дозволяю {{siteOwner}}, {{siteOwnerAddress}} (Ідентифікатор кредитора: {{siteOwnerCreditorID}}) для стягнення платежів з мого рахунку в безготівковому порядку. Одночасно я доручаю своєму банку здійснювати пряме списання коштів з {{siteOwner}} на мій рахунок прямим дебетом.",
    "checkout.checkout.payment.noSepa": "Ваша адреса виставлення рахунку або доставки знаходиться за межами ЄС. Тому ми не можемо запропонувати Вам оплату за безготівковим розрахунком.",
    "checkout.checkout.payment.sepaInfo": "Швидко і нескладно. При оформленні замовлення (наступний крок) Вам буде запропоновано ввести Ваші банківські реквізити (IBAN). Будь ласка, приготуйте їх. Після завершення переказу ви отримаєте підтвердження.",
    "checkout.checkout.payment.prepayment": "Передоплата (безготівковий розрахунок)",
    "checkout.checkout.payment.prepaymentInfo": "Після того, як ви оформите замовлення, ми надішлемо вам інформацію про банківський переказ електронною поштою. Ми можемо зарезервувати товари у Вашому замовленні максимум на 7 днів. Будь ласка, дотримуйтесь цього часового проміжку для здійснення банківського переказу. Як тільки ми отримаємо оплату, замовлення буде відправлено.",
    "checkout.checkout.payment.invoice": "Рахунок-фактура",
    "checkout.checkout.payment.invoiceInfo": "Сума рахунку-фактури підлягає сплаті на дату платежу, зазначену в підтвердженні про відправлення по електронній пошті.",
    "checkout.checkout.payment.noInvoice": "На жаль, ми не можемо запропонувати Вам оплату за рахунком-фактурою для цього замовлення. Будь ласка, оберіть один з інших способів оплати.",
    "checkout.checkout.payment.sofort": "Миттєвий банківський переказ",
    "checkout.checkout.payment.sofortInfo": "При оформленні замовлення (наступний крок) Вам буде запропоновано ввести Ваші банківські реквізити (IBAN). Будь ласка, приготуйте їх. Після завершення переказу ви отримаєте підтвердження.",
    "checkout.checkout.payment.accountName": "Власник рахунку",
    "checkout.checkout.payment.accountEmail": "Адреса електронної пошти для підтвердження",
    "checkout.checkout.sepaAndOrder": "Мандат прямого дебетування для {{totalAmount}} € підтвердити та замовити з урахуванням витрат",
    "checkout.checkout.sofortAndOrder": "Передача мандату на {{totalAmount}} € підтвердити та замовити з урахуванням витрат",
    "checkout.checkout.title": "Оформлення замовлення",
    "checkout.checkout.next": "більше",
    "checkout.checkout.order": "Купити зараз",
    "checkout.checkout.orderConsentPre": "Оформляючи замовлення, ви погоджуєтеся з нашими",
    "checkout.checkout.orderConsentPost": "Згоден.",
    "checkout.checkout.noAccount": "Не створюйте обліковий запис клієнта",
    "checkout.redirect.countryPriceChange": "Ціни для Вашої країни відрізняються від стандартної ціни. Просимо підтвердити зміну ціни.",
    "comments.blockComments": "Коментування цієї публікації закрито.",
    "comments.comment.anonymous": "Анонімний",
    "comments.comment.button": "Написати новий коментар",
    "comments.comment.buttonReply": "Відповіді",
    "comments.comment.cancel": "Скасувати",
    "comments.comment.content": "Ваш коментар",
    "comments.comment.hasCommented": "коментарі",
    "comments.comment.login": "Увійдіть, щоб залишити коментар",
    "comments.comment.past": "має",
    "comments.comment.replyFrom": "Відповідь на",
    "comments.comment.showReplies": "Показати відповіді",
    "comments.comment.showReply": "Показати відповідь",
    "comments.comment.submit": "Надіслати",
    "comments.comment.title": "Назва коментаря",
    "comments.comment.userName": "Ваше ім'я користувача",
    "comments.heading": "Пропозиції, зауваження, коментарі",
    "comments.hierarchy.flat": "Плоский",
    "comments.hierarchy.nested": "Genested",
    "comments.hierarchy.title": "Перегляд коментарів:",
    "comments.message.afterSaveError": "Ваш коментар не може бути збережений на даний момент!",
    "comments.message.afterSaveSuccess": "Ваш коментар збережено.",
    "comments.message.afterSaveUnlock": "Ваш коментар збережено, але він ще потребує перевірки адміністратором.",
    "comments.noComments": "Наразі коментарів не надходило",
    "comments.reply": "ЕН:",
    "comments.show": "Показати коментарі",
    "comments.title": "Коментарі",
    "entries.edit": "Вступ <strong>{{title}}</strong> редагування",
    "entries.entries": "Записи",
    "entries.product.ui.chooseVariant": "Оберіть варіант",
    "entries.product.ui.chooseVariantMobile": "Оберіть варіант",
    "entries.product.ui.combinationNotAvailable": "Поєднання неможливе",
    "entries.entry.fields.address.city": "Місто",
    "entries.entry.fields.address.country": "Країна",
    "entries.entry.fields.address.houseNumber": "Номер будинку",
    "entries.entry.fields.address.street": "Вулиця",
    "entries.entry.fields.address.zip": "ПОШТОВИЙ КОД",
    "entries.entry.fields.language": "Мова",
    "entries.entry.fields.prefixMissing": "Наступні поля не заповнюються:",
    "entries.entry.profile.edit": "Редагувати профіль",
    "entries.entry.profile.publicProfile": "на сторінку мого публічного профілю",
    "entries.entry.tooltip.alreadyCopied": "Цей запис вже сниться вам уві сні.",
    "entries.entry.tooltip.alreadyFollow": "Ви вже слідкуєте за цим записом",
    "entries.entry.tooltip.alreadyLiked": "Вам вже подобається цей запис",
    "entries.entry.tooltip.copied": "Приємно, що вам теж хочеться помріяти про цей запис. До цього запису було додано Ваше зображення профілю.",
    "entries.entry.tooltip.filterComments": "Натисніть тут, щоб показати тільки коментарі в рядку введення",
    "entries.entry.tooltip.filterFollows": "Натисніть тут, щоб показати тільки фоловерів у рядку Entry",
    "entries.entry.tooltip.filterLikes": "Натисніть тут, щоб показувати тільки лайки в рядку запису",
    "entries.entry.tooltip.filterShares": "Натисніть тут, щоб показати тільки акції в рядку введення",
    "entries.entry.tooltip.follow": "Натисніть тут, щоб перейти до наступного запису",
    "entries.entry.tooltip.like": "Натисніть тут, щоб вподобати цей запис",
    "entries.entry.tooltip.noFollow": "Слідкуйте тільки за роботами на чужих конкурсах",
    "entries.entry.tooltip.noFollowNotLoggedIn": "Ви повинні увійти, щоб відслідковувати цей запис",
    "entries.entry.tooltip.noLike": "Лайк працює тільки для чужих записів",
    "entries.entry.ui.access": "Цей запис можуть бачити лише такі користувачі",
    "entries.entry.ui.added": "додав він.",
    "entries.entry.ui.addEntry": "Додати записи",
    "entries.entry.ui.addOpening": "Додати час роботи",
    "entries.entry.ui.address": "Адресні дані",
    "entries.entry.ui.addStep": "Додати крок",
    "entries.entry.ui.agreementHours": "Відкриття за домовленістю",
    "entries.entry.ui.allAccess": "Всі можуть бачити цей запис",
    "entries.entry.ui.assignedCategories": "присвоєні категорії",
    "entries.entry.ui.assignedCertificates": "Мої сертифікати",
    "entries.entry.ui.assignedProducts": "Призначена продукція",
    "entries.entry.ui.businessContact": "Контактні дані компанії",
    "entries.entry.ui.categories": "Категорії для цього запису",
    "entries.entry.ui.categoryFields": "Додаткова інформація",
    "entries.entry.ui.commercialTitle": "Інформація про компанію, що подала заявку",
    "entries.entry.ui.companyTitle": "Інформація про мою компанію",
    "entries.entry.ui.contactPerson": "Мої контактні дані",
    "entries.entry.ui.cssClass": "CSS клас для контенту",
    "entries.entry.ui.deliveryInfo": "Інформація про службу доставки",
    "entries.entry.ui.descriptionInfo": "Порада: Коли слова позначені, їм можна призначати стилі.",
    "entries.entry.ui.descriptionPlaceholder": "Опис у форматі HTML. Двічі клацніть на тексті, щоб відкрити панель функцій HTML (максимум 600 символів).",
    "entries.entry.ui.editEntry": "Редагувати запис",
    "entries.entry.ui.editEntryNew": "Створити запис",
    "entries.entry.ui.exclusionHours": "Час виключення",
    "entries.entry.ui.galleryImages": "Картинна галерея (2 або 3 картини в залежності від розташування)",
    "entries.entry.ui.globalTitle": "Ім'я та прізвище",
    "entries.entry.ui.hasKiosk": "У мене є кіоск",
    "entries.entry.ui.imagesHorizontal": "Горизонтальні",
    "entries.entry.ui.imagesInfo": "Зображення можна редагувати у розділі \"Зображення/Відео/Документи\".",
    "entries.entry.ui.imagesOrientation": "Вирівнювання зображення",
    "entries.entry.ui.imagesSquared": "Площа",
    "entries.entry.ui.imagesVertical": "Вертикальний",
    "entries.entry.ui.isListImage": "Це зображення є зображенням списку",
    "entries.entry.ui.isMainImage": "Це зображення є головним",
    "entries.entry.ui.isMainImageAndListImage": "Це зображення відображається як головне зображення запису",
    "entries.entry.ui.jobApply": "Подайте заявку зараз",
    "entries.entry.ui.kioskDescription": "Опис Вашого торгового стенду",
    "entries.entry.ui.listImage": "Картинка зі списком",
    "entries.entry.ui.logo": "Логотип",
    "entries.entry.ui.longDescription": "Довгий текст опису",
    "entries.entry.ui.mainImage": "Основне зображення",
    "entries.entry.ui.makeListImage": "Встановити це зображення як зображення списку",
    "entries.entry.ui.makeListImageTooltip": "Результати пошуку відображаються у вигляді списку із зображенням списку. Якщо не вибрано жодної картинки зі списку, використовується головна картинка.",
    "entries.entry.ui.makeMainImage": "Встановіть це зображення як головне",
    "entries.entry.ui.makeMainImageTooltip": "У верхній частині профілю відображається головне зображення. Зображення, які не обрані як головне або зображення списку, відображаються в галереї зображень.",
    "entries.entry.ui.metaDescription": "Короткий опис",
    "entries.entry.ui.metaDescriptionPlaceholder": "Короткий опис не більше 200 символів",
    "entries.entry.ui.multiCode": "Додатковий код для цього кроку",
    "entries.entry.ui.multiDescription": "Опис цього кроку",
    "entries.entry.ui.multiImages": "Зображення для цього кроку",
    "entries.entry.ui.multiProgress": "Прогрес",
    "entries.entry.ui.multiTitle": "Назва для цього кроку",
    "entries.entry.ui.multiTitlePlaceholder": "Назва для цього кроку (максимум 50 символів)",
    "entries.entry.ui.noDescriptions": "Запис поки що не має описового тексту.",
    "entries.entry.ui.noEntries": "Не знайдено жодного запису.",
    "entries.entry.ui.onlyVisibleForCustomers": "Цей запис можуть бачити тільки зареєстровані користувачі",
    "entries.entry.ui.openAt": "Відкрито",
    "entries.entry.ui.openingHours": "Графік роботи",
    "entries.entry.ui.openingInfo": "Примітки щодо графіку роботи",
    "entries.entry.ui.openingReligious": "Адреса в переважно католицькій парафії",
    "entries.entry.ui.overallProgress": "Цей запис є {{value}}% реалізовано",
    "entries.entry.ui.parking": "Парковка для автотранспорту",
    "entries.entry.ui.parkingLots": "Кількість",
    "entries.entry.ui.parkingType": "Мистецтво",
    "entries.entry.ui.personImage": "Фото контактної особи",
    "entries.entry.ui.relatedEvents": "Супутні події",
    "entries.entry.ui.relatedJobs": "Підключені робочі місця",
    "entries.entry.ui.relatedArticles": "Пов'язані статті",
    "entries.entry.ui.relatedCampaigns": "Суміжні кампанії",
    "entries.entry.ui.rentKiosk": "Я хотів би орендувати торговий стенд",
    "entries.entry.ui.resolveAddress": "Визначити координати",
    "entries.entry.ui.resolveAddressInfo": "Будь ласка, визначте географічну точку (положення на карті) з Вашої адреси. Без правильної географічної прив'язки вони не можуть бути знайдені коректно.",
    "entries.entry.ui.resolveGeolocation": "Отримати адресу",
    "entries.entry.ui.routingPlaceholder": "Введіть свої вказівки у форматі HTML тут.",
    "entries.entry.ui.selectEvent": "Виберіть подію",
    "entries.entry.ui.selectJob": "Виберіть роботу",
    "entries.entry.ui.selectArticle": "Вибрати статтю",
    "entries.entry.ui.selectCampaign": "Оберіть кампанію",
    "entries.entry.ui.sharing": "Ця сторінка на {{sharing}} частка",
    "entries.entry.ui.shareMail": "Поділіться цією сторінкою поштою",
    "entries.entry.ui.shortDescriptionInfo": "Є одночасно коротким описом",
    "entries.entry.ui.skillsNeeded": "Я міг би добре використати його для мого вступу....",
    "entries.entry.ui.sortword": "Альтернативні назви для сортування",
    "entries.entry.ui.step": "Крок",
    "entries.entry.ui.stepInDream": "На якому етапі вступу знаходиться цей крок?",
    "entries.entry.ui.stepInDreamAfter": "після реалізації",
    "entries.entry.ui.stepInDreamBefore": "до реалізації",
    "entries.entry.ui.stepInDreamDuring": "під час реалізації",
    "entries.entry.ui.subtitle": "Описовий субтитр",
    "entries.entry.ui.subtitlePlaceholder": "Введіть субтитри (максимум 60 символів)",
    "entries.entry.ui.tabCategories": "Категорії",
    "entries.entry.ui.tabCategoriesMobile": "Кет.",
    "entries.entry.ui.tabCompany": "Дані про компанію",
    "entries.entry.ui.tabDescription": "Опис",
    "entries.entry.ui.tabDescriptionMobile": "Бешр.",
    "entries.entry.ui.tabExhibitor": "Інформація про учасника",
    "entries.entry.ui.tabImages": "Зображення/Відео/Документи",
    "entries.entry.ui.tabImagesMobile": "Зображення/Відео/Файли",
    "entries.entry.ui.tabRelations": "Підключення",
    "entries.entry.ui.tags": "Теги",
    "entries.entry.ui.title": "Запис з'являється під наступною назвою",
    "entries.entry.ui.titleError": "Перед збереженням запис повинен бути названий",
    "entries.entry.ui.titlePlaceholder": "Введіть назву (максимум 50 символів)",
    "entries.entry.ui.visible": "Цей запис видно",
    "entries.entry.ui.promotion": "Участь в акціях для цього запису",
    "entries.entry.ui.regions": "Категорії регіонів для цієї роботи",
    "entries.job.ui.promotion": "Участь в акціях для цієї вакансії",
    "entries.event.edit": "Подія <strong>{{title}}</strong> редагування",
    "entries.event.ui.addEvent": "Додати подію",
    "entries.event.ui.cooperation": "Партнер по співробітництву",
    "entries.event.ui.directions": "Схема проїзду",
    "entries.event.ui.editEvent": "Редагувати подію",
    "entries.event.ui.editEventNew": "Створити подію",
    "entries.event.ui.endDate": "Закінчення заходу (дня)",
    "entries.event.ui.eventDone": "Звіт про виставку",
    "entries.event.ui.noEvents": "Подій не знайдено",
    "entries.event.ui.openEvent": "Виклик події",
    "entries.event.ui.opening": "Графік роботи",
    "entries.event.ui.startDate": "Початок заходу (день)",
    "entries.event.ui.tabCategories": "Категорії",
    "entries.event.ui.tabDescription": "Опис",
    "entries.event.ui.tabOpening": "Дата та час проведення",
    "entries.event.ui.tabRelations": "Підключення",
    "entries.event.ui.title": "Подія фігурує під наступними назвами",
    "entries.event.ui.upcoming": "У двох словах",
    "entries.event.ui.current": "Наразі відбувається",
    "entries.event.ui.history": "Вже відбувся",
    "entries.event.ui.moreEvents": "Інші події",
    "entries.open": "Виклик запису у фронтенді",
    "entries.person.ui.company": "Компанія",
    "entries.person.ui.female": "Пані",
    "entries.person.ui.diverse": "Без",
    "entries.person.ui.fullName": "Ім'я та прізвище",
    "entries.person.ui.fullSize": "Повний розмір",
    "entries.person.ui.gender": "Стать",
    "entries.person.ui.greeting": "Привітання",
    "entries.person.ui.male": "Пане",
    "entries.person.ui.open": "Безпосередньо до в'їзду цієї особи",
    "entries.person.ui.personAddress": "Адреса особи",
    "entries.person.ui.personContact": "Особисті контактні дані",
    "entries.person.ui.personImage": "Особисте фото",
    "entries.person.ui.position": "Посада",
    "entries.person.ui.quote": "Цитата/підпис",
    "entries.person.ui.signature": "Картинка для підпису",
    "entries.poi.ui.poiEvents": "Події на цій локації",
    "entries.poi.ui.title": "Місце фігурує під наступними назвами",
    "entries.product.edit": "Продукт <strong>{{title}}</strong> редагування",
    "entries.product.fields.engine": "Двигун",
    "entries.product.fields.horsePower": "Влада",
    "entries.product.menu.allAttachments": "Всі вкладення як на долоні",
    "entries.product.menu.allCarriers": "Всі транспортні засоби перевізника в огляді",
    "entries.product.menu.attachments": "Додатки",
    "entries.product.menu.carriers": "Транспортні засоби-перевізники",
    "entries.product.tags.eco": "еко",
    "entries.product.tags.neu": "новий",
    "entries.product.ui.addProduct": "Додати товар",
    "entries.product.ui.choose": "вибрати",
    "entries.product.ui.discount": "Знижка",
    "entries.product.ui.discountSelect": "Скористайтеся перевагою % знижки",
    "entries.product.ui.equipmentVariants": "Варіанти комплектації",
    "entries.product.ui.variants": "Варіанти",
    "entries.product.ui.gauge": "Ширина в см",
    "entries.product.ui.gaugeMin": "Мінімальна ширина колії в см",
    "entries.product.ui.inclusive": "У тому числі",
    "entries.product.ui.live": "{{name}} відчути наживо",
    "entries.product.ui.netPrize": "в тому числі {{tax}}% ПДВ, плюс",
    "entries.product.ui.netPrizeDefault": "в т.ч. податок на додану вартість, крім того",
    "entries.product.ui.deliveryTerms": "Вартість доставки",
    "entries.product.ui.noProducts": "Товарів не знайдено",
    "entries.product.ui.noPurchaseOnThisPage": "Не може бути замовлений на цій сторінці, для цього зателефонуйте по товару.",
    "entries.product.ui.onlyBasicVariant": "Тільки в базовій комплектації",
    "entries.product.ui.onlyWithCarrier": "Доступно тільки при покупці базового блоку",
    "entries.product.ui.order": "Додати в кошик",
    "entries.product.ui.notOrderable": "Будь ласка, оберіть варіант відповіді.",
    "entries.entry.ui.products.delivery.red": "Продукт наразі недоступний",
    "entries.entry.ui.products.delivery.yellow": "Товар в наявності на складі. Можливо, більш тривалий час доставки.",
    "entries.entry.ui.products.delivery.green": "Товар на складі в достатній кількості",
    "entries.products.ui.combinationNotAvailable": "Продукт не доступний у цій комбінації",
    "product.ui.content": "Зміст",
    "product.ui.ingredients": "Зміст",
    "product.ui.ingredientsFood": "Інгредієнти",
    "entries.product.ui.overview": "Огляд",
    "entries.product.ui.prizeDecimalPoint": ",",
    "entries.product.ui.prizeFrom": "від",
    "entries.product.ui.item": "Шматок",
    "entries.product.ui.prizePerItem": "Ціна за штуку",
    "entries.product.ui.noPrize": "Будь ласка, запитайте про ціну у вашої контактної особи.",
    "entries.product.ui.size": "Розмір",
    "entries.product.ui.required": "Тільки з",
    "entries.product.ui.suitableFor": "Підходить для",
    "entries.product.ui.suitableForAttachments": "Підходить для наступного навісного обладнання",
    "entries.product.ui.suitableForCarriers": "Підходить для наступних транспортних засобів",
    "entries.product.ui.title": "Продукт",
    "entries.product.ui.variant": "Варіант/пакет",
    "entries.product.ui.variantNotOrderable": "Обраний варіант не можна замовити",
    "entries.product.ui.inVariants": "для варіантів продукції",
    "entries.product.ui.bundleparts": "Складається з",
    "entries.progress.title": "Як покращити свій вступ",
    "entries.tabbar.basics": "Основні дані",
    "entries.tabbar.entry": "Вхідні дані",
    "entries.tabbar.gallery": "Зображення",
    "entries.tabbar.layout": "Макет",
    "entries.tabbar.openingHours": "Графік роботи",
    "entries.tabbar.order": "Інформація для замовлення",
    "entries.tabbar.tooltip.entryNotSavedDetail": "Дані запису можна редагувати лише після того, як запис буде збережено з назвою.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "Дані макета можна редагувати тільки після того, як запис буде збережено з заголовком.",
    "entries.types.poi": "Poi",
    "entries.types.standard": "Стандартний",
    "entries.ui.groupDetailName": "Назва для групових заявок",
    "entries.ui.groupName": "Назва для груп (регіон)",
    "entryTypes.entry": "Вступ",
    "entryTypes.event": "Подія",
    "entryTypes.poi": "Місце проведення",
    "errors.blank": "{{description}} обов'язково заповнюється",
    "errors.description": "Це поле",
    "errors.email": "Будь ласка, введіть дійсний {{description}}-адреса",
    "errors.empty": "{{description}} не повинно бути порожнім",
    "errors.exclusion": "Це {{description}} вже зайнято",
    "errors.inclusion": "Це {{description}} не бере участь у відборі",
    "errors.invalid": "{{description}} є недійсним",
    "errors.mandatory": "{{field}} обов'язково заповнюється",
    "errors.mandatoryCheckbox": "Треба підтвердити.",
    "errors.mandatorySubmit": "Надіслати форму можна лише після заповнення всіх обов'язкових полів.",
    "errors.slug": "Slug може містити тільки малі літери, цифри та знак \"-\".",
    "forms.ui.deleteFile": "Видалити файл",
    "forms.ui.formErr": "Помилка при відправці форми",
    "forms.ui.formSent": "Форма відправлена",
    "forms.ui.noForm": "Форма не визначена",
    "forms.ui.invalidMail": "Будь ласка, введіть дійсну адресу електронної пошти.",
    "forms.ui.isSubmitted": "Анкету надіслано. Ми зв'яжемося з Вами найближчим часом.",
    "forms.ui.missingFields": "Необхідно заповнити наступні поля:",
    "forms.ui.uploadSuccess": "{{file}} було завантажено",
    "forms.ui.fieldBuilder.street": "Вулиця",
    "forms.ui.fieldBuilder.houseNumber": "Ні.",
    "forms.ui.fieldBuilder.zip": "ПОШТОВИЙ КОД",
    "forms.ui.fieldBuilder.city": "Місто",
    "forms.ui.fieldBuilder.country": "Країна",
    "forms.ui.fieldBuilder.firstName": "Ім'я та прізвище",
    "forms.ui.fieldBuilder.lastName": "Ім'я та прізвище",
    "forms.ui.privacyRead": "Я ознайомився з політикою конфіденційності",
    "forms.ui.privacy": "Політика конфіденційності",
    "jobs.ui.employmentType": "Тип роботи",
    "jobs.ui.employmentType.FULL_TIME": "Повна зайнятість",
    "jobs.ui.employmentType.PART_TIME": "Неповна зайнятість",
    "jobs.ui.employmentType.APPRENTICE": "Навчання",
    "jobs.ui.employmentType.INTERN": "Стажування",
    "jobs.ui.employmentType.CONTRACTOR": "Фріланс",
    "jobs.ui.employmentType.TRAINEE": "Стажер, заочна форма навчання",
    "jobs.ui.employmentType.VOLUNTEER": "Волонтер",
    "jobs.ui.employmentType.OTHER": "",
    "jobs.ui.location": "Вакансія доступна за адресою",
    "jobs.ui.role.male": "M",
    "jobs.ui.role.female": "W",
    "jobs.ui.role.divers": "D",
    "jobs.ui.startDateNow": "Станом на сьогодні",
    "jobs.ui.startFrom": "Від",
    "media.documents.search.noResult": "Будь ласка, введіть серійний номер або виберіть категорію.",
    "media.documents.search.placeholder": "Пошук за серійним номером агрегату або номером типу",
    "media.documents.search.reset": "Скинути результат пошуку",
    "media.ui.copyright": "Авторське право",
    "media.ui.cropFormat": "Специфікація формату",
    "media.ui.cropImage": "Обрізка",
    "media.ui.cropImageDuplicate": "Скопіювати та вирізати",
    "media.ui.description": "Опис",
    "media.ui.filename": "Ім'я файлу",
    "media.ui.imageSaved": "Зображення збережено",
    "media.ui.processingImage": "Зображення обрізається, будь ласка, зачекайте",
    "media.ui.resetCrop": "Скидання різання",
    "media.ui.resetImage": "Повернутися до оригіналу",
    "media.ui.rotateImage": "Поворот зображення на 90° за годинниковою стрілкою",
    "media.ui.title": "Назва",
    "name": "Ім'я та прізвище",
    "openingHours.additionalInfo": "Інформація про графік роботи",
    "openingHours.addOpening": "Додати час роботи",
    "openingHours.date.day.di": "Туе",
    "openingHours.date.day.do": "Робити",
    "openingHours.date.day.fr": "Фрі",
    "openingHours.date.day.mi": "Мі",
    "openingHours.date.day.mo": "Мо",
    "openingHours.date.day.sa": "Сб",
    "openingHours.date.day.so": "Отже",
    "openingHours.dateS": "Дата",
    "openingHours.delete": "Видалити",
    "openingHours.from": "від",
    "openingHours.options": "Площа",
    "openingHours.time": "Час",
    "openingHours.to": "до",
    "openingHours.type": "Тип часу роботи",
    "opt-in.error-can-not-update-member": "Внесено зміни до відомостей про учасника для {{email}} не вдалося здійснити.",
    "opt-in.error-list-does-not-exist": "На вказані дати список не знайдено.",
    "opt-in.error-list-does-not-need-validation": "Зазначений перелік не має опціону.",
    "opt-in.error-member-not-in-list": "Жодного члена списку на вказані дати не знайдено.",
    "opt-in.success-member-verified": "На сьогоднішній день, на жаль, це не так. {{email}} було додано до {{listname}} додано до списку.",
    "opt-out.error-can-not-update-member": "Внесено зміни до відомостей про учасника для {{email}} не вдалося здійснити.",
    "opt-out.error-list-does-not-exist": "На вказані дати список не знайдено.",
    "opt-out.error-list-does-not-have-opt-out": "Зазначений перелік не має права на відмову.",
    "opt-out.error-member-not-in-list": "Жодного члена списку на вказані дати не знайдено.",
    "opt-out.success-membership-terminated": "На сьогоднішній день, на жаль, це не так. {{email}} було вилучено з {{listname}} вилучено.",
    "password.errors.not-secure-enough": "Рекомендуємо використовувати пароль довжиною не менше 8 символів, що складається з великих та малих літер, цифр та спеціальних символів ($@$!%*#?&amp;).",
    "password.errors.password-and-conf-do-not-match": "Два паролі повинні бути ідентичними.",
    "password.quality.0": "Дуже погано",
    "password.quality.1": "Погано",
    "password.quality.2": "Достатньо",
    "password.quality.3": "Добре.",
    "password.quality.4": "Дуже добре.",
    "profile.entries.copycats": "Записи, які {{userName}} також хоче зробити",
    "profile.entries.donates": "Записи, які {{userName}} опори",
    "profile.entries.entries": "Конкурсні роботи від {{userName}}",
    "profile.entries.follows": "Роботи, які {{userName}} слідує",
    "profile.entries.joins": "Записи, в яких {{userName}} бере участь",
    "profile.marketplace.fields.energyRequired": "Попит на електроенергію",
    "profile.marketplace.fields.livingAnimals": "Взяття живих тварин",
    "profile.marketplace.fields.loadingUnloading": "Необхідні для прильоту/вильоту",
    "profile.marketplace.fields.maxRange": "За свою пропозицію я вже стільки км проїхав",
    "profile.marketplace.fields.placeRequired": "Вимоги до подачі заявки",
    "profile.marketplace.fields.securityRequired": "Потреба в безпеці",
    "profile.marketplace.fields.shows": "Пропозиція програми",
    "profile.marketplace.fields.spaceRequired": "Потреба в площі в м²",
    "profile.marketplace.fields.waterRequired": "Потреба у воді в м³",
    "profile.marketplace.new": "Нова пропозиція або потреба",
    "profile.marketplace.offer.delete": "Видалити",
    "profile.marketplace.offer.description": "Опис",
    "profile.marketplace.offer.descriptionPlaceholder": "Змістовний опис, щоб інший користувач міг щось з ним зробити.",
    "profile.marketplace.offer.new": "Створити нову пропозицію",
    "profile.marketplace.offer.relatedEntry": "Присвоєний запис",
    "profile.marketplace.offer.tags": "Теги для пропозиції/запиту (буде краще знаходитись)",
    "profile.marketplace.offer.title": "Пропоную",
    "profile.marketplace.request.delete": "Запит на видалення",
    "profile.marketplace.request.new": "Створити новий запит",
    "profile.marketplace.request.missingCredentials": "Ви можете розпочати запит лише тоді, коли ви створили принаймні одне місцезнаходження та одну точку вимірювання.",
    "profile.marketplace.request.title": "Мені потрібно",
    "profile.messages.approve": "Підтвердити",
    "profile.messages.as": "як",
    "profile.messages.commentPre": "має",
    "profile.messages.commentSuff": "коментарі",
    "profile.messages.decided": "вирішили",
    "profile.messages.deletedUser": "Видалений користувач",
    "profile.messages.donateDream": "Щиро дякуємо, що допомогли цьому учаснику реалізувати його заявку. Він негайно отримає Ваше повідомлення і, сподіваємось, зв'яжеться з Вами та прийме Вашу допомогу.",
    "profile.messages.dreamer": "Абітурієнт",
    "profile.messages.dreamToo": "а також мріяти",
    "profile.messages.enter": "приєднаний",
    "profile.messages.favoriteDream": "Ви зараз стежите за цим записом.",
    "profile.messages.follow": "слідує",
    "profile.messages.has": "має",
    "profile.messages.is": "це",
    "profile.messages.join": "ПРИЄДНУЙТЕСЬ",
    "profile.messages.joinDream": "Разом з цим записом хочеться мріяти.",
    "profile.messages.likeDream": "Вам подобається цей запис зараз.",
    "profile.messages.sendText": "Ваше повідомлення надіслано.",
    "profile.messages.shareDream": "Ви успішно поділилися цим записом.",
    "profile.messages.supporter": "Прихильники",
    "profile.messages.want": "хотів би",
    "profile.messages.youAre": "Ти",
    "profile.messages.youWant": "Ви хотіли б",
    "profile.newsletter.notSubscribed": "підписатися",
    "profile.newsletter.notSubscribedAny": "Ви ще не підписалися на розсилку новин.",
    "profile.newsletter.privacyInformation": "Натискаючи на кнопку \"Підписатися\", ви погоджуєтеся з тим, що ми будемо надсилати вам майбутні інформаційні бюлетені цієї підписки (opt-in). Ви можете відписатися від розсилки у будь-який час, повторно натиснувши на кнопку \"Підписатися\" (opt-out).",
    "profile.newsletter.subscribed": "підписаний",
    "profile.press.articleDetail": "Детальний опис статті",
    "profile.press.downloadImage": "Завантажити зображення",
    "profile.press.downloadImages": "Завантажити зображення",
    "profile.press.downloadText": "Експорт тексту",
    "profile.press.filterAuthor": "Фільтр за автором",
    "profile.press.hide": "ховатися",
    "profile.press.show": "затухати",
    "profile.progress.title": "Як покращити свій профіль",
    "register.ui.addressData": "Адресні дані",
    "register.ui.addressInfo": "Їм не потрібно вказувати свою особисту адресу. Однак це призводить до коректних розрахунків маршруту та відстані.",
    "register.ui.companyData": "Інформація про вашу компанію",
    "register.ui.companyDataMandatory": "Ви повинні ввести назву вашої компанії",
    "register.ui.emptyFirstName": "Необхідно ввести своє ім'я та прізвище",
    "register.ui.emptyLastName": "Необхідно ввести своє прізвище",
    "register.ui.emptyMail": "Ви повинні вказати дійсну адресу електронної пошти",
    "register.ui.emptyUserName": "Необхідно вказати ім'я користувача",
    "register.ui.language": "Мова",
    "register.ui.loginData": "Дані для входу в систему",
    "register.ui.password": "Пароль",
    "register.ui.passwordRepeat": "Повторити пароль",
    "register.ui.personalData": "Ваші персональні дані",
    "register.ui.privacyStatement": "Я даю згоду на зберігання моїх персональних даних. Мої персональні дані не будуть передані третім особам. Я можу відкликати цю згоду на захист даних у будь-який час із збереженням чинності на майбутнє.",
    "register.ui.takenEmail": "Цей email вже був вказаний користувачем.",
    "register.ui.takenUserName": "Це ім'я користувача вже зайнято",
    "register.ui.timezone": "Часовий пояс",
    "register.ui.typeMinimum": "Ви повинні вибрати хоча б один тип",
    "register.ui.usernameInfo": "Важливо: Це ваше ім'я користувача для входу в систему",
    "register.ui.oauthInfo": "Ви будете перенаправлені на сторінку входу на ФРМ для входу з вашими даними користувача, а потім повернетесь на веб-сайт.",
    "search.allDistances": "На всі відстані",
    "search.andFilter": "та налаштування фільтрів",
    "search.closeTags": "Приховати теги",
    "search.for": "до",
    "search.zipCountries": "Поштові індекси з",
    "search.resetPosition": "Скинути позицію",
    "search.geoPositionBrowser": "Визначити місцезнаходження через браузер",
    "search.geoPositionFault": "Визначення позиції браузером неможливе",
    "search.geoPositionTitle": "Встановіть позицію для пошуку по близькості",
    "search.selectedFilters": "Вибрані фільтри",
    "search.selectFilters": "Фільтр пошуку за категоріями",
    "search.hits": "Хіт",
    "search.openTags": "Показати теги",
    "search.removeDatefilter": "Видалити фільтр за датою",
    "search.resulted": "виявлено",
    "search.resultType.category": "Категорія",
    "search.resultType.entry": "Вступ",
    "search.resultType.event": "Подія",
    "search.resultType.group": "Група",
    "search.resultType.page": "Сторінка",
    "search.resultType.person": "Особа",
    "search.resultType.post": "Пост",
    "search.resultType.product": "Продукт",
    "search.resultType.article": "Стаття",
    "search.resultType.job": "Робота",
    "search.resultType.recipe": "Рецепт",
    "search.search": "Пошук",
    "search.result": "Результат",
    "search.results": "Результати",
    "search.searchBarPlaceholder": "Обшук...",
    "search.searchBarPlaceholderEntries": "Пошук за назвою дилера або адресою дилера.",
    "search.searchBarPlaceholderEntriesPosition": "Пошук по периметру з визначенням власного місцезнаходження (наприклад, місто)...",
    "search.searchBarPlaceholderEntriesPositionZip": "...або поштовий індекс",
    "search.searchBarPlaceholderGeoSearch": "Введіть свою адресу...",
    "search.searchBarPlaceholderMobile": "Обшук...",
    "search.searchBarPlaceholderProducts": "Пошук товарів",
    "search.searchBarPositionTooltip": "Визначити місцезнаходження або ввести місцезнаходження з адресою",
    "search.showAll": "Показати всі",
    "search.globalSearch": "Сторінка пошуку",
    "social.comment": "Коментар",
    "social.Comment": "Коментар",
    "social.CopycatJoiner": "Мрійте про свій вступ",
    "social.dream.donateAmount": "Підтримка грошовою сумою",
    "social.dream.donateDreampoints": "Підтримка з Dreampoints",
    "social.dream.donateInfo": "Повідомлення є приватним і може бути прочитане тільки власником запису.",
    "social.dream.donateTip": "Підтримка порадами та діями",
    "social.dream.done": "ВИКОНАНО",
    "social.dream.edit": "Редагувати запис",
    "social.dream.findDreams": "Знайдіть тут малі та великі конкурси та зробіть свій конкурс реальним та/або допоможіть іншим реалізувати їхні конкурси.",
    "social.dream.followedDreams": "Записи, за якими ви стежите",
    "social.dream.giveEmail": "Поділіться моєю адресою електронної пошти",
    "social.dream.givePhone": "Поділіться моїм номером телефону",
    "social.dream.joinerApproved": "Ви берете участь у цьому записі",
    "social.dream.joinerButton": "Приєднатися до цього запису",
    "social.dream.joinerButtonMobile": "Беріть участь",
    "social.dream.joinerDream": "Груповий сон",
    "social.dream.joinerInfo": "Якщо власник запису підтверджує вашу участь, цей запис стає груповою кімнатою, де ви маєте додаткові права.",
    "social.dream.joinerMessage": "Введіть тут своє повідомлення для власника запису:",
    "social.dream.joinerPending": "Власник запису ще має підтвердити свою участь.",
    "social.dream.joinerSupport": "Ви підтримуєте цей запис",
    "social.dream.joinerSupportButton": "Підтримати цей запис",
    "social.dream.joinerSupportButtonMobile": "Підтримка",
    "social.dream.joinerSupportInfo": "Ми постійно працюємо над вдосконаленням, наприклад, над підтримкою грошима або точками входу. Залишайтеся з нами.",
    "social.dream.joinerSupportPending": "Власник запису все одно повинен дозволити вашу підтримку",
    "social.dream.member": "Член",
    "social.dream.members": "Члени",
    "social.dream.meToo": "Я теж хочу це зробити",
    "social.dream.meTooInfo": "Покажіть власнику заявки, що ви вважаєте заявку чудовою і що ви хочете зробити цю заявку.",
    "social.dream.noFollow": "Ви не стежите за жодним записом!",
    "social.dream.noJoiner": "Ви ще не є учасником групової мрії",
    "social.dream.noLikes": "Вам ще не сподобався жоден запис!",
    "social.dream.noSupport": "Ви не підтримуєте запис",
    "social.dream.registerFirstText": "Для того, щоб мати можливість поділитися записом, підтримати запис або вказати, що ви також хотіли б зробити цей запис, ви повинні увійти в систему. Реєстрація на share your entry є швидкою та безкоштовною. Просто натисніть на кнопку \"Зареєструватися\".",
    "social.dream.registerFirstTitle": "Мрієте, підтримуєте, навіть робите?",
    "social.dream.settings": "Налаштування входу",
    "social.dream.shareTitle": "Поділіться мрією",
    "social.dream.similar": "Пов'язані записи",
    "social.dreamline.addImageToDream": "Додайте це зображення до свого запису",
    "social.dreamline.addMedia": "Додати фото / відео",
    "social.dreamline.allActivities": "Всі заходи Dreamline",
    "social.dreamline.anonymous": "Анонімний",
    "social.dreamline.commentButton": "Коментар",
    "social.dreamline.comments": "пише:",
    "social.dreamline.depublished": "Цей коментар було видалено за неналежну поведінку.",
    "social.dreamline.dreampointsInfo": "Це омріяні точки в'їзду. Більше про Dreampoints ви можете дізнатися у своєму профілі.",
    "social.dreamline.fewDreampoints": "має лише кілька Точок Мрії. Коментуйте, ставте лайки або діліться записом.",
    "social.dreamline.filterBySteps": "Фільтр за кроками",
    "social.dreamline.filterComment": "Коментарі",
    "social.dreamline.filteredBy": "Діяльність відфільтрована за",
    "social.dreamline.filteredBySteps": "Відфільтровано за кроками",
    "social.dreamline.filterFavorite": "Слідкує за",
    "social.dreamline.filterLike": "Подобається",
    "social.dreamline.filterShare": "Акції",
    "social.dreamline.filterStep": "Кроки",
    "social.dreamline.flagged": "Про це повідомив",
    "social.dreamline.isComment": "коментарі",
    "social.dreamline.isCopycatJoiner": "також мріють",
    "social.dreamline.isFirstUpdate": "створено",
    "social.dreamline.isShare": "спільний",
    "social.dreamline.isStep": "зробили новий крок",
    "social.dreamline.isUpdate": "оновлений",
    "social.dreamline.lastEntry": "Останнє оновлення запису",
    "social.dreamline.locked": "Цей вміст видимий тільки для колег-мрійників",
    "social.dreamline.lockedCTA": "Приєднуйтесь до цього запису зараз",
    "social.dreamline.noMessages": "Цей запис ще не має активності. Напишіть перший коментар зараз.",
    "social.dreamline.noMessagesFiltered": "Ніякої активності з цими параметрами фільтрації для цього запису.",
    "social.dreamline.nowFollower": "слідує за цим записом!",
    "social.dreamline.nowJoiner": "зараз є прихильником",
    "social.dreamline.nowLikes": "як цей запис зараз!",
    "social.dreamline.nowSharing": "поділилися цим записом!",
    "social.dreamline.onlyJoinerVisible": "Видимий лише для інших мрійників/прихильників",
    "social.dreamline.prefixComment": "вже робив цей запис раніше",
    "social.dreamline.prefixCopycatJoiner": "хотів би додати цей запис, оскільки",
    "social.dreamline.prefixFavorite": "слідкує за цим записом з моменту",
    "social.dreamline.prefixJoiner": "підтримує цей запис, оскільки",
    "social.dreamline.prefixLike": "подобається цей запис з",
    "social.dreamline.prefixShare": "вже робив цей запис раніше",
    "social.dreamline.prefixStep": "мав раніше",
    "social.dreamline.prefixUpdate": "вже робив цей запис раніше",
    "social.dreamline.profile": "Профіль",
    "social.dreamline.provideImage": "Передаю цю фотографію в розпорядження власника запису",
    "social.dreamline.resetFilter": "Скидання фільтра",
    "social.dreamline.sendComment": "Надіслати",
    "social.dreamline.sharePart": "Поділитися",
    "social.dreamline.toggleStream": "Коментарі",
    "social.dreamline.toggleStreamSingle": "Коментар",
    "social.dreamline.writeComment": "Написати коментар...",
    "social.Favorite": "Слідкуйте за",
    "social.follow": "Слідкуйте за",
    "social.Follow": "Слідкуйте за",
    "social.hasLiked": "Вам вже подобається цей запис.",
    "social.join": "Приєднуйтесь",
    "social.Join": "Приєднуйтесь",
    "social.Joiner": "Мріємо разом",
    "social.like": "Наприклад",
    "social.Like": "Наприклад",
    "social.report.headlinePrefix": "Ви там.",
    "social.report.headlineSuffix": "доповідати.",
    "social.report.login": "Ви повинні увійти в систему, щоб повідомити про публікацію.",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "Інший образливий контент",
    "social.report.reportScam": "Введення в оману, знущання.",
    "social.report.reportSex": "Сексуальний зміст",
    "social.report.reportSpam": "Це спам",
    "social.report.reportViolence": "Зображення насильства",
    "social.report.send": "Надіслати повідомлення",
    "social.report.title": "Звіт",
    "social.share": "Поділитися",
    "social.Share": "Поділитися",
    "syd.ui.dreamerCommercial": "Стандарт B2B",
    "syd.ui.userCommercial": "B2B Dreamer",
    "user.company.addUser": "Створіть додаткових користувачів для",
    "user.company.inviteUser": "Надіслати лист-запрошення",
    "user.company.userIsInvited": "Відправлено запрошення на електронну пошту",
    "user.company.userIsActive": "Користувач активний",
    "user.company.userIsNotActive": "Користувач ще не активований",
    "user.company.additionalWebsite": "Подальший сайт компанії (тільки домен без https://)",
    "user.company.companyName": "Назва компанії",
    "user.company.contactFor": "Контактні дані для",
    "user.company.contactForDefault": "Загальний контакт",
    "user.company.copyContact": "Перенесіть контактні дані з реквізитів компанії для запису",
    "user.company.email": "Контактний email",
    "user.company.facebook": "Facebook-аккаунт компанії (тільки ім'я аккаунта)",
    "user.company.fax": "Номер факсу для письмового зв'язку",
    "user.company.google": "Google+ акаунт компанії (тільки назва акаунту)",
    "user.company.instagram": "Інстаграм акаунт компанії (тільки назва акаунту)",
    "user.company.linkedin": "LinkedIn акаунт компанії (тільки назва акаунту)",
    "user.company.newCustomerAccount": "Створіть бізнес-аккаунт",
    "user.company.noCustomerValidation": "Ваш бізнес-рахунок ще не активовано.",
    "user.company.noCustomerYet": "Вашій реєстрації ще не присвоєно бізнес-рахунок.",
    "user.company.notAllowed": "Ви не можете внести жодних змін, оскільки ваш статус користувача ({{type}}) не дозволяє цього зробити.",
    "user.company.phone": "Контактний номер телефону",
    "user.company.pinterest": "Акаунт компанії в Pinterest (тільки назва акаунту)",
    "user.company.remarks": "Примітки",
    "user.company.registerNumber": "Номер торгового реєстру",
    "user.company.registerAuth": "Місцевий суд",
    "user.company.acerCode": "ACER код вашого енергопостачання",
    "user.company.taxNumber": "Податковий номер",
    "user.company.type.default": "Стандартний",
    "user.company.type.procurator": "Уповноважена особа з правом підпису",
    "user.company.type.manager": "Керуючий директор",
    "user.company.twitter": "Акаунт компанії в Twitter (тільки назва акаунта без @)",
    "user.company.ustid": "UStID",
    "user.company.ustid.notValid": "Будь ласка, вкажіть дійсний ідентифікаційний номер платника ПДВ (за<br>наявності</br>)",
    "user.company.website": "Сайт компанії (тільки домен без https://)",
    "user.company.xing": "Xing-рахунок компанії (тільки назва рахунку)",
    "user.company.youtube": "Youtube акаунт компанії (тільки назва акаунту)",
    "user.message.after-save-error": "Ваші зміни не вдалося зберегти.",
    "user.message.after-save-success": "Ваші зміни збережено.",
    "user.message.can-not-login": "Ми не можемо увійти в систему з цими даними користувача і пароля! Чи правильно написано ім'я користувача? Чи правильно Ви ввели пароль? Можливо, щось пішло не так під час реєстрації.",
    "user.message.can-not-logout": "Ми не могли вас виписати!",
    "user.message.conflict": "Таке ім'я користувача вже існує, оберіть інше!",
    "user.message.fileExistsError": "Не вдалося завантажити. Файл вже існує",
    "user.message.fileSizeError": "Не вдалося завантажити. Файл занадто великий",
    "user.message.loginFailureBottom": "Можливо, у вас ще немає облікового запису учасника?",
    "user.message.loginFailureTop": "Чи правильно написано Ваше ім'я користувача? Чи правильно Ви ввели пароль?",
    "user.message.registration-error": "Під час реєстрації сталася помилка!",
    "user.message.registration-success": "Найближчим часом ви отримаєте від нас електронний лист з підтвердженням.",
    "user.message.unknownError": "Невідома помилка при завантаженні файлу",
    "user.message.upload-success": "Завантаження виконано успішно",
    "user.message.verify-error": "Вашу адресу електронної пошти не вдалося перевірити. Зверніться, будь ласка, до адміністратора.",
    "user.message.verify-success": "Ваша електронна адреса підтверджена. Зараз ви можете зареєструватися:",
    "user.ui.accountGreeting": "Привіт,",
    "user.ui.accountMenu": "Головна сторінка та інформація",
    "user.ui.accountMenuActivities": "Діяльність",
    "user.ui.accountMenuCompanySettings": "Інформація про компанію",
    "user.ui.accountMenuEntries": "Мої записи",
    "user.ui.accountMenuEntryPre": "Записи,",
    "user.ui.accountMenuEvents": "Мої події",
    "user.ui.accountMenuFavorites": "Мої улюблені",
    "user.ui.accountMenuFollows": "Записи, за якими я стежу",
    "user.ui.accountMenuImages": "Зображення/Відео/Документи",
    "user.ui.accountMenuJoin": "Мрії моєї групи",
    "user.ui.accountMenuLikes": "Записи, які мені сподобалися",
    "user.ui.accountMenuMeasuring": "Мої місця вимірювання та дані",
    "user.ui.accountMenuMessages": "Новини",
    "user.ui.accountMenuNewsletter": "Моя підписка на розсилку новин",
    "user.ui.accountMenuPoi": "Мої локації",
    "user.ui.accountMenuPress": "Мої статті",
    "user.ui.accountMenuProducts": "Моя продукція",
    "user.ui.accountMenuJobs": "Мої пропозиції роботи",
    "user.ui.accountMenuProfile": "Профіль користувача",
    "user.ui.accountMenuRatings": "Відгуки",
    "user.ui.accountMenuRegistrationData": "Дані для входу в систему",
    "user.ui.accountMenuReviews": "Відгуки",
    "user.ui.accountMenuSettings": "Налаштування",
    "user.ui.accountMenuSkillsOffer": "Я пропоную...",
    "user.ui.accountMenuSkillsRequest": "Мені потрібно...",
    "user.ui.accountMenuOffer": "Мої пропозиції",
    "user.ui.accountMenuRequest": "Мої прохання",
    "user.ui.accountMenuSkillsSearch": "Запити та пропозиції",
    "user.ui.accountMenuSubscriptions": "Підписки",
    "user.ui.accountMenuSubscritions": "Моя підписка",
    "user.ui.accountMenuSupport": "Підтримувані записи",
    "user.ui.accountMenuSupports": "Конкурсні роботи, які я підтримую",
    "user.ui.accountMenuTender": "Тендери",
    "user.ui.accountMenuOrders": "Мій наказ",
    "user.ui.accountNoMessages": "Ви ще не отримали жодного повідомлення.",
    "user.ui.accountProgressbar": "Ваш профіль {{value}}% виконано",
    "user.ui.accountProgressbarEntry": "Ваша заявка на участь у конкурсі {{value}}% виконано",
    "user.ui.accountSettings": "Більше налаштувань",
    "user.ui.accountCookieSettings": "Натисніть тут, щоб змінити свої налаштування.",
    "user.ui.accountCookieEssential": "Суттєво",
    "user.ui.accountCookieAnalysis": "Дані аналізу",
    "user.ui.accountCookieSocial": "Соціальні мережі",
    "user.ui.accountCookieReviews": "Відгуки",
    "user.ui.accountToolbarLogin": "Увійдіть в систему",
    "user.ui.accountToolbarLoginTooltip": "Зареєструйтесь безкоштовно та розпочніть роботу",
    "user.ui.accountToolbarLogout": "Вийдіть з системи",
    "user.ui.adminPreview": "Попередній перегляд",
    "user.ui.backToLogin": "Повернутися до сторінки реєстрації",
    "user.ui.birthday": "День народження",
    "user.ui.cannotLogin": "Не можете увійти? Натисніть тут, щоб скинути пароль.",
    "user.ui.changePassword": "Я хочу змінити свій пароль",
    "user.ui.changeUserGroup": "Інша підписка",
    "user.ui.city": "Місто",
    "user.ui.companyName": "Назва компанії",
    "user.ui.companyNameTooltip": "Важливо: Ваша заявка також з'явиться під цими назвами компаній",
    "user.ui.country": "Країна",
    "user.ui.createNewAccount": "Створіть новий обліковий запис користувача",
    "user.ui.dataDelete": "інші записи. Ви хочете видалити файл назавжди? Скрізь, де використовується цей файл, він замінюється заповнювачем.",
    "user.ui.dataUsage": "Файл зберігається в папці",
    "user.ui.dataUsageNone": "Файл ще не використовується. Ви хочете видалити файл назавжди?",
    "user.ui.deleteImage": "Видалити зображення",
    "user.ui.description": "Введіть тут загальний опис особи або компанії",
    "user.ui.documents": "Документи",
    "user.ui.documentSearch": "Пошук документів",
    "user.ui.download": "{{title}} завантажити",
    "user.ui.email": "Адреса електронної пошти",
    "user.ui.fillOutForm": "Для реєстрації необхідно повністю заповнити всі обов'язкові поля",
    "user.ui.firstName": "Ім'я та прізвище",
    "user.ui.academicTitle": "Вчене звання",
    "user.ui.gender": "М/Ф",
    "user.ui.genderFemale": "жінка",
    "user.ui.genderFemaleSalutation": "Пані",
    "user.ui.genderMale": "чоловік",
    "user.ui.genderMaleSalutation": "Пане",
    "user.ui.genderDiverse": "різноманітний",
    "user.ui.genderThird": "Не вказано",
    "user.ui.housenumber": "Ні.",
    "user.ui.iAmCustomer": "Я хочу створити обліковий запис B2B",
    "user.ui.internalInfo": "Для вас є послання. Натисни сюди.",
    "user.ui.lastLogin": "Остання реєстрація на",
    "user.ui.languageAvailable": "Доступно на вашій мові",
    "user.ui.languageMissing": "Доступно тільки в",
    "user.ui.lastName": "Прізвище та ім'я",
    "user.ui.loginButton": "Увійдіть в систему",
    "user.ui.loginTitle": "Логін користувача",
    "user.ui.memberSince": "Член Ради директорів з",
    "user.ui.mobileNumber": "Номер мобільного телефону",
    "user.ui.myAddedStorage": "Надані фотографії",
    "user.ui.myStorage": "Мій каталог",
    "user.ui.noRegisterMessage": "Для отримання облікового запису користувача, будь ласка, звертайтеся за вказаними контактними адресами. Ви не можете зареєструвати себе як користувача.",
    "user.ui.oldPassword": "Попередній пароль",
    "user.ui.password": "Пароль",
    "user.ui.phoneType": "Телефон",
    "user.ui.phoneTypeMobile": "Мобільний",
    "user.ui.phoneTypeLandline": "Стаціонарний телефон",
    "user.ui.pleaseSelect": "Будь ласка, оберіть",
    "user.ui.pleaseSelectSalutation": "Будь ласка, оберіть привітання",
    "user.ui.registerButton": "Налаштуйте обліковий запис користувача зараз",
    "user.ui.registerMessage": "Реєструйтеся зараз і негайно приступайте до роботи. Це швидко і просто.",
    "user.ui.registerTitle": "Ви з нами вперше?",
    "user.ui.requestPasswordReset": "Запит нового паролю та видалення старого паролю",
    "user.ui.requestPasswordSuccess": "Найближчим часом ви отримаєте електронного листа з посиланням для перезавантаження.",
    "user.ui.requestPasswordError": "Невідома помилка при скиданні пароля.",
    "user.ui.resetPassword": "Скинути пароль",
    "user.ui.setPassword": "Встановити пароль",
    "user.ui.resetPasswordPage": "Скинути пароль",
    "user.ui.setPasswordPage": "Встановіть свій пароль",
    "user.ui.resetPasswordSuccess": "Ваш пароль було скинуто, тепер ви можете увійти в систему",
    "user.ui.setPasswordSuccess": "Ваш пароль встановлено, ви можете увійти в систему",
    "user.ui.selectCustomerType": "Ти... оберіть, будь ласка",
    "user.ui.stayLoggedin": "Залишайтеся на сайті",
    "user.ui.stayLoggedinInfo": "Якщо це загальнодоступний пристрій, будь ласка, вимкніть цю опцію і вийдіть з системи, щоб завершити роботу.",
    "user.ui.street": "Вулиця",
    "user.ui.salutation": "Привітання",
    "user.ui.title": "Назва",
    "user.ui.thankYouForSubscribing": "Дякуємо, що підписалися на нашу розсилку.",
    "user.ui.errorAtSubscribing": "Помилка при підписці на розсилку",
    "user.ui.unsubscribe": "вийти з системи",
    "user.ui.unsubscribePage": "Ви дійсно хочете відписатися від нашої розсилки?",
    "user.ui.uploadDrop": "Для завантаження перетягніть файл сюди або",
    "user.ui.uploadSelectFile": "вибрати файл",
    "user.ui.uploadToMyStorage": "Завантажити нові зображення в мій каталог",
    "user.ui.uploadUserImage": "Виберіть фотографію користувача",
    "user.ui.username": "Адреса електронної пошти",
    "user.ui.userName": "Ім'я користувача",
    "user.ui.visibilityInfo": "Порада: Ви самі вирішуєте, що буде відображатися публічно з Вашого профілю! Натисніть на символ ока, щоб налаштувати видимість ваших записів:",
    "user.ui.zip": "Поштовий індекс",
    "user.ui.phone": "Номер телефону",
    "user.user": "Абітурієнт",
    "user.users": "Записи",
    "widgets.shoppingCart.wishlist": "Пам'ятка та список побажань",
    "widgets.shoppingCart.wishlist.empty": "У вас ще немає товарів у вашому блокноті та списку бажань",
    "widgets.shoppingCart.cart": "Кошик для покупок",
    "user.product.addedToFavorites": "Товар додано до обраного",
    "order.status.zero": "Замовлення отримано",
    "order.status.one": "У процесі виконання",
    "order.status.two": "Замовлення відправлено",
    "order.status.three": "Доступ",
    "order.status.four": "Повернення",
    "order.status.default": "Невідома держава-окупант",
    "country.germany": "Німеччина",
    "country.de": "Німеччина",
    "country.austria": "Австрія",
    "country.at": "Австрія",
    "country.belgium": "Бельгія",
    "country.denmark": "Данія",
    "country.bulgaria": "Болгарія",
    "country.croatia": "Хорватія",
    "country.cyprus": "Кіпр",
    "country.czechia": "Чеська Республіка",
    "country.estonia": "Естонія",
    "country.finland": "Фінляндія",
    "country.france": "Франція",
    "country.fr": "Франція",
    "country.greece": "Греція",
    "country.hungary": "Угорщина",
    "country.ireland": "Ірландія",
    "country.italy": "Італія",
    "country.it": "Італія",
    "country.latvia": "Латвія",
    "country.lithuania": "Литва",
    "country.luxembourg": "Люксембург",
    "country.lu": "Люксембург",
    "country.malta": "Мальта",
    "country.netherlands": "Нідерланди",
    "country.poland": "Польща",
    "country.portugal": "Португалія",
    "country.romania": "Румунія",
    "country.slovakia": "Словаччина",
    "country.slovenia": "Словенія",
    "country.spain": "Іспанія",
    "country.sweden": "Швеція",
    "country.norway": "Норвегія",
    "country.switzerland": "Швейцарія",
    "country.ch": "Швейцарія",
    "country.turkey": "Туреччина",
    "country.unitedKingdom": "Сполучене Королівство",
    "country.gb": "Великобританія",
    "openingHours.tNowClosed": "Закрито",
    "openingHours.tOpeningHoursNotKnown": "Подальші години роботи не відомі",
    "openingHours.tNowOpen": "Відкрито зараз",
    "openingHours.tNowStillOpen": "Зараз все ще відкрито",
    "openingHours.tNextOpeningHourUnknown": "наступна дата відкриття невідома",
    "openingHours.tSoonOpenFrom": "незабаром знову відкриється з",
    "openingHours.tFrom": "від",
    "openingHours.tUntil": "до",
    "openingHours.tOpenAgainFrom": "знову відкрито з",
    "openingHours.tOpenAgainFrom2": "Відкриваємо знову на",
    "comparison.fields.measurements": "Маса",
    "comparison.fields.weight": "Вага",
    "application.tag.neu": "Новий",
    "application.tag.sale": "Розпродаж",
    "application.tag.eco": "Eco",
    "application.tag.sonderrabatt": "Спеціальна знижка",
    "job.application.selectLocation": "Оберіть своє місцезнаходження",
    "job.application.location": "Доступно в наступних регіонах"
  };
});
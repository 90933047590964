define('webapp/helpers/trim-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var text = params[0];
    var len = params[1] || 100;
    var ellipse = params[2] === undefined ? '...' : params[2];

    if (text && text.length > len) {
      return text.slice(0, len) + ellipse;
    } else {
      return text;
    }
  });
});
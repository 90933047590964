define('webapp/services/language-service', ['exports', 'webapp/utils/find-by'], function (exports, _findBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var botRegex = /crawler|bot|spider|larbin|ABACHOBot|80legs|AddSugarSpiderBot|AnyApexBot|Baidu|B-l-i-t-z-B-O-T|BecomeBot|BillyBobBot|Bimbot|Arachmo|Accoona-AI-Agent|searchme\.com|Cerberian Drtrs|DataparkSearch|Covario-IDS|findlinks|holmes|htdig|ia_archiver|ichiro|igdeSpyder|L\.webis|LinkWalker|lwp-trivial|mabontland|Google|Mnogosearch|mogimogi|MVAClient|NetResearchServer|NewsGator|NG-Search|Nymesis|oegp|Pompos|PycURL|Qseero|SBIder|SBIder|ScoutJet|Scrubby|SearchSight|semanticdiscovery|silk|Snappy|Sqworm|StackRambler|Ask Jeeves\/Teoma|truwoGPS|voyager|VYU2|^updated|TinEye|webcollage|Yahoo|yoogliFetchAgent|^Zao/i;

  exports.default = Ember.Service.extend(Ember.Evented, {
    i18n: Ember.inject.service(),
    moment: Ember.inject.service(),
    viewService: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    router: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    isSetup: false,
    selectedLanguage: 'de',

    // get from api config
    multiLanguage: true,
    useLanguageSlug: true,
    defaultLanguageOnRoot: true,

    // overwritten by site
    defaultLanguage: 'de',
    languages: Ember.computed(function () {
      return ['de', 'en'];
    }),

    setLanguageSettings: function setLanguageSettings(site) {
      var multiLanguage = site.multiLanguage || false;
      var useLanguageSlug = site.useLanguageSlug || false;
      var defaultLanguageOnRoot = site.defaultLanguageOnRoot || false;
      var defaultLanguage = site.defaultLanguage || 'de';

      var languages = site.languages || ['de', 'en'];

      this.setProperties({
        multiLanguage: multiLanguage,
        useLanguageSlug: useLanguageSlug,
        defaultLanguageOnRoot: defaultLanguageOnRoot,
        languages: languages,
        defaultLanguage: defaultLanguage
      });
    },
    languageIsDefault: function languageIsDefault(lang) {
      return this.defaultLanguage === lang;
    },
    getLanguagePrefix: function getLanguagePrefix(overridLanguage) {
      if (this.get('useLanguageSlug')) {
        var selectedLanguage = overridLanguage || this.get('selectedLanguage');
        if (this.languageIsDefault(selectedLanguage) && this.get('defaultLanguageOnRoot')) {
          return '';
        } else {
          return selectedLanguage;
        }
      } else {
        return '';
      }
    },


    languagePrefix: Ember.computed('useLanguageSlug', 'selectedLanguage', 'defaultLanguageOnRoot', function () {
      return this.getLanguagePrefix();
    }),

    getTranslatedMenuTitle: function getTranslatedMenuTitle(page) {
      var selectedLanguage = this.get('selectedLanguage');
      return Ember.get(page, 'translations.' + selectedLanguage + '.menuTitle') || page.menuTitle;
    },
    translateField: function translateField(model, fieldName) {
      var selectedLanguage = this.get('selectedLanguage');
      return Ember.get(model, 'translations.' + selectedLanguage + '.' + fieldName) || Ember.get(model, fieldName);
    },
    translateSlug: function translateSlug(page, language) {
      var slug = page.fullSlug;
      var languagePrefix = void 0;
      if (this.get('multiLanguage')) {
        var selectedLanguage = language || this.get('selectedLanguage');
        var langSlugObj = (0, _findBy.default)(page.fullSlugs, 'language', selectedLanguage);
        slug = langSlugObj && langSlugObj.fullSlug || page.fullSlug;
        languagePrefix = this.getLanguagePrefix(selectedLanguage);
      }
      return [languagePrefix, slug].filter(Boolean).join('/');
    },
    setLanguage: function setLanguage(language) {
      if (this.get('multiLanguage')) {
        this.set('selectedLanguage', language);
        this.set('i18n.locale', language);
        this.get('moment').changeLocale(language === 'zh' ? 'zh-cn' : language);
        if (this.get('fastboot.isFastBoot')) {
          var fbDocument = Ember.getOwner(this).lookup('service:-document');
          fbDocument.documentElement.setAttribute('lang', language);
        } else {
          document.documentElement.setAttribute('lang', language);
        }
        this.trigger('languageChange', language);
      }
    },
    setInitialLanguage: function setInitialLanguage(detectedLanguage) {
      if (!this.isSetup) {
        this.isSetup = true;
        this.setLanguage(detectedLanguage);
      }
    },
    transitionToHome: function transitionToHome() {
      var router = this.get('router');

      var languagePrefix = this.getLanguagePrefix();
      if (languagePrefix) {
        router.transitionTo('page', languagePrefix);
      } else {
        router.transitionTo('index');
      }
    },


    // set language and switch to language specific route
    switchLanguage: function switchLanguage(lang) {
      var router = this.get('router');
      var currentRouteName = this.get('router.currentRouteName');
      var currentModel = this.get('viewService.currentModel');
      var activeDetail = this.get('cmsService.activeDetail');

      this.setLanguage(lang);

      // reset categories cache, so fresh translated categories will be fetched
      this.get('cachedData').clearCategoryCache();

      var transitionSlug = void 0;

      if (currentModel.type === 'account' || ['register', 'login'].includes(currentRouteName) || currentRouteName.startsWith('checkout')) {
        var currentRoute = Ember.getOwner(this).lookup('route:' + currentRouteName);
        currentRoute.refresh();
      } else {
        var restSlug = currentModel.restSlug;
        var rootPage = currentModel.containerPage || currentModel; // if page is a sub page of a container, pick the container page as rootPage

        // only articles support multi language slugs right now, other enties not (besides pages)
        if (currentModel.type === 'articleContainer' && activeDetail && activeDetail.translatedSlugs) {
          var foundSlug = activeDetail.translatedSlugs.find(function (x) {
            return x.language === lang;
          });
          if (foundSlug) {
            restSlug = foundSlug.slug;
          }
        }

        if (this.languageIsDefault(lang) && this.defaultLanguageOnRoot) {
          transitionSlug = rootPage.fullSlug;
        } else {
          transitionSlug = this.translateSlug(rootPage, lang);
        }

        var targetSlug = [transitionSlug, restSlug].filter(Boolean).join('/');
        if (targetSlug.length) {
          router.transitionTo('page', targetSlug);
        } else {
          router.transitionTo('index');
        }
      }
    },


    languageSlug: Ember.computed('multiLanguage', 'selectedLanguage', 'defaultLanguageOnRoot', function () {
      var selectedLanguage = this.get('selectedLanguage');
      var defaultLanguageOnRoot = this.get('defaultLanguageOnRoot');
      var multiLanguage = this.get('multiLanguage');
      var isDefaultLanguage = this.languageIsDefault(selectedLanguage);

      var langSlug = '';
      if (multiLanguage) {
        langSlug = defaultLanguageOnRoot && isDefaultLanguage ? '' : selectedLanguage;
      }
      return langSlug;
    }),

    getNavigatorLanguage: function getNavigatorLanguage() {
      if (!window.navigator) {
        return;
      }

      var navLanguageFull = window.navigator.language;
      var navLanguage = navLanguageFull && navLanguageFull.slice(0, 2);

      if (botRegex.test(navigator.userAgent)) {
        navLanguage = 'de';
      }

      var languages = this.get('languages');

      if (languages.indexOf(navLanguage) >= 0) {
        this.setLanguage(navLanguage);
      } else {
        this.setLanguage(this.defaultLanguage || 'de');
      }
    }
  });
});
define('webapp/helpers/price-basic-selling', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var price = params[0] / 100 || 1;
    var basicUnit = params[1] || 1;
    var sellingUnit = params[2] || 1;
    var result = price * basicUnit / sellingUnit;

    try {
      return result.toFixed(2).replace('.', ',');
    } catch (e) {
      return number;
    }
  });
});
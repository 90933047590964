define('webapp/routes/account/job-applications/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service('myStorage'),
    userProxy: Ember.inject.service('userProxy'),
    itemStorage: Ember.computed.alias('myStorage.jobApplicationAdmin'),
    modelName: 'JobApplications',

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.resetState && controller.resetState();
    },
    model: function model() {
      var itemStorage = this.get('itemStorage');
      var userId = this.get('userProxy._id');

      if (!userId) {
        throw new Error('no userId');
      }

      return itemStorage.ajaxPost({
        param: 'search',
        data: { perPage: 9999, onlyOwned: true, sortBy: 'createdAt', sortDir: 'desc' }
      }).then(function (data) {
        return data.model;
      });
    }
  });
});
define('webapp/components/widgets/widget-campaign', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),

    fetchData: function fetchData() {
      var _this = this;

      var campaign = this.get('data.options.campaign');

      if (!campaign) {
        console.log('widget-campaign has no campaign assigned');
        return;
      }

      var myStorage = this.get('myStorage');
      return myStorage.campaign.ajaxGet({
        param: campaign,
        query: {
          relations: false
        }
      }).then(function (res) {
        _this.set('detail', res);
      });
    },
    didInsertElement: function didInsertElement() {
      this.fetchData();
    }
  });
});
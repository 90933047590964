define('webapp/services/gdpr-service', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    cachedData: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    gdprStatus: 'pending', // pending, denied accepted

    ignoreFacebook: false,

    init: function init() {
      this._super.apply(this, arguments);
      var isFastBoot = this.get('fastboot.isFastBoot');

      if (isFastBoot) {
        this.set('gdprClicked', false);
      } else {
        if (navigator.userAgent && /googlebot/i.test(navigator.userAgent)) {
          this.set('gdprStatus', 'denied');
          return;
        }
        var gdprStatus = localStorage.getItem('gdprStatus') || 'pending';
        this.set('gdprStatus', gdprStatus);
      }

      // hack for bodystreet.sg
      if (!isFastBoot && window.location.hostname === 'www.bodystreet.sg') {
        this.set('gdprStatus', 'accepted');
        window.socialTrackingEnabled = true;
        window.localStorage.setItem('social-tracking-enabled', JSON.stringify(true));
        this.setAllGtagConsent();
      }
    },
    acceptGDPR: function acceptGDPR() {
      if (_environment.default.gtagConsentEnabled) {
        this.setAllGtagConsent();
      }
      this.acceptGDPRLoad();
    },
    acceptGDPRLoad: function acceptGDPRLoad() {
      var _this = this;

      var cmsService = this.get('cmsService');

      this.set('gdprStatus', 'accepted');
      window.localStorage.setItem('gdprStatus', 'accepted');

      cmsService.setSiteCodes(this.get('cachedData.cachedSite'));
      Ember.run.later(function () {
        _this.trigger('gdprAccepted');
      }, 500);
    },
    denyGDPR: function denyGDPR() {
      this.set('gdprStatus', 'denied');
      window.localStorage.setItem('gdprStatus', 'denied');

      if (_environment.default.gtagConsentEnabled) {
        this.setDenyGtagConsent();
      }
    },
    setAllGtagConsent: function setAllGtagConsent() {
      this.setGtagConsent({
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted'
      });
    },
    setDenyGtagConsent: function setDenyGtagConsent() {
      this.setGtagConsent({
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied'
      });
    },
    setGtagConsent: function setGtagConsent(consentSettings) {
      window.localStorage.setItem('gtag-constent-settings', JSON.stringify(consentSettings));
      this.applyGtagConsent();
    },
    applyGtagConsent: function applyGtagConsent() {
      if (window.gtag) {
        window.gtag('set', 'ads_data_redaction', true);
        window.gtag('set', 'url_passthrough', true);

        var consentSettingsString = window.localStorage.getItem('gtag-constent-settings');
        var consentSettings = consentSettingsString ? JSON.parse(consentSettingsString) : {
          ad_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          analytics_storage: 'denied'
        };

        console.log('gtag consent update', consentSettings);
        window.gtag('consent', 'update', consentSettings);
      }
    }
  });
});
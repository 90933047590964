define('webapp/components/widgets/widget-news-aggregator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    languageService: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.fetchData();
    },


    hasArticles: Ember.computed('data.options.aggregationType', 'model.options.articles', function () {
      var aggregationType = this.get('data.options.aggregationType');
      var articles = this.get('data.options.articles', false);

      return aggregationType === 'separate' && articles;
    }),

    hasSocials: Ember.computed('data.options.aggregationType', 'model.options.social', function () {
      var aggregationType = this.get('data.options.aggregationType');
      var social = this.get('data.options.social', false);

      return aggregationType === 'separate' && social;
    }),

    hasMixed: Ember.computed('data.options.aggregationType', function () {
      var aggregationType = this.get('data.options.aggregationType');

      return aggregationType === 'mixed';
    }),

    getData: function getData() {
      // has count? and how many?
      var count = this.get('data.options.count', 0);
      var hasCount = false;
      if (Number.isInteger(count) && count >= 1) {
        hasCount = true;
      }

      // what do I want to search for?
      var aggregationType = this.get('data.options.aggregationType');
      var articles = this.get('data.options.articles', false);
      var social = this.get('data.options.social', false);
      var mixed = false;
      if (aggregationType === 'mixed') {
        mixed = true;
      }

      // base data
      var data = {
        count: count,
        hasCount: hasCount,
        articles: articles,
        social: social,
        mixed: mixed
      };

      // opt limit by the timeframe
      var isLimitedByTime = this.get('data.options.isLimitedByTime');
      if (isLimitedByTime) {
        data['startDate'] = this.get('data.options.startDate');
        data['stopDate'] = this.get('data.options.stopDate');
      }

      // opt limit the social media services
      if (social || mixed) {
        var queryServices = new Set();
        var services = this.get('data.options.services');
        var keys = Object.keys(services);
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = keys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var key = _step.value;

            if (services[key]) {
              queryServices.add(key);
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        data['services'] = Array.from(queryServices);
      }

      return data;
    },
    fetchData: function fetchData() {
      var _this = this;

      var data = this.getData();
      var myStorage = this.get('myStorage');
      var sessionId = this.get('cmsService.sessionId');
      var lang = this.get('languageService.selectedLanguage');
      var param = 'news_aggregator';
      var query = { sessionId: sessionId, lang: lang };

      return myStorage.widget.ajaxGet({ param: param, query: query, data: data }).then(function (ret) {
        _this.set('articles', ret.articles);
        _this.set('socials', ret.social);
        _this.set('mixed', ret.mixed);
      });
    }
  });
});
define('webapp/components/uk-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showContent: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var dropdownElement = this.element.querySelector('[uk-dropdown]');
      this._hiddenListener = function () {
        _this.set('showContent', false);
      };
      this._beforeshowListener = function () {
        _this.set('showContent', true);
      };

      dropdownElement.addEventListener('hidden', this._hiddenListener);
      dropdownElement.addEventListener('beforeshow', this._beforeshowListener);
    },
    willDestroyElement: function willDestroyElement() {
      var dropdownElement = this.element.querySelector('[uk-dropdown]');

      dropdownElement.removeEventListener('hidden', this._hiddenListener);
      dropdownElement.removeEventListener('beforeshow', this._beforeshowListener);
    }
  });
});
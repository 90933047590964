define('webapp/components/search/location-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    searchService: Ember.inject.service(),
    languageService: Ember.inject.service(),
    trackingService: Ember.inject.service(),
    browserLocationDenied: false,
    browserLocation: null,
    manualLocation: null,

    searchTerm: '',
    searchTermZip: '',
    searchType: 'location', // location or zip

    selectedIdx: 0,

    hasGeolocationSupport: Ember.computed(function () {
      return !this.get('fastboot.isFastboot') && 'geolocation' in navigator;
    }),

    didInsertElement: function didInsertElement() {
      var initialSearchTerm = this.get('searchService.tempSearchTerm');
      if (initialSearchTerm) {
        this.setInitialSearch(initialSearchTerm);
      }
    },
    fetchGeoSearchResult: function fetchGeoSearchResult(searchTerm) {
      var isZip = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var myStorage = this.get('myStorage');

      var mapboxSearchCountriesString = (this.get('searchService.mapboxSearchCountries') || '').trim();

      var countries = undefined;
      if (mapboxSearchCountriesString) {
        countries = mapboxSearchCountriesString.split(/\s*,\s*/);
      }

      var proximity = undefined;
      var types = undefined;
      if (isZip) {
        types = ['postcode'];
        var isNumber = !isNaN(Number(searchTerm));
        var isAustria = isNumber && searchTerm.length === 4;
        if (isAustria) {
          proximity = { lat: 47.72, lon: 14.76 };
        }
      }

      return myStorage.geocoder.ajaxPost({
        param: 'search_place',
        data: {
          searchTerm: searchTerm,
          language: this.get('languageService.selectedLanguage'),
          proximity: proximity,
          countries: countries,
          types: types
        }
      });
    },
    setInitialSearch: function setInitialSearch(initialSearchTerm) {
      var _this = this;

      if (initialSearchTerm.length >= 3) {
        this.fetchGeoSearchResult(initialSearchTerm).then(function (res) {
          var item = res[0];
          if (item) {
            _this.selectLocation({
              name: item.place_name,
              location: { lat: item.center[1], lon: item.center[0] }
            });
          }
        });
      }
    },
    locationChanged: function locationChanged(location) {
      var searchService = this.get('searchService');
      searchService.set('location', location);
      searchService.set('sort', 'geoDistance');
      searchService.updateSearch();
    },
    resetLocation: function resetLocation() {
      this.set('manualLocation', null);
      var searchService = this.get('searchService');
      searchService.set('location', null);
      searchService.set('sort', null);
      searchService.updateSearch();
    },
    geoSearch: function geoSearch(searchTerm) {
      var _this2 = this;

      var searchType = this.get('searchType');
      var isZip = searchType === 'zip';
      if (searchTerm.length >= 3) {
        this.fetchGeoSearchResult(searchTerm, isZip).then(function (res) {
          _this2.set('geoSearchResult', res.map(function (item) {
            return {
              name: item.place_name,
              location: { lat: item.center[1], lon: item.center[0] }
            };
          }));
        });
      } else {
        this.set('geoSearchResult', []);
      }
    },
    changeIndex: function changeIndex(value) {
      var geoSearchResult = this.geoSearchResult;
      if (geoSearchResult && geoSearchResult.length) {
        var selectedIdx = this.get('selectedIdx');
        this.set('selectedIdx', (selectedIdx + value + geoSearchResult.length) % geoSearchResult.length);
      }
    },
    selectLocation: function selectLocation(locationItem) {
      if (this.get('searchType') === 'location') {
        this.set('searchTerm', locationItem.name);
      }

      var trackingService = this.get('trackingService');
      trackingService.gaSearch(locationItem.name, locationItem.location && locationItem.location.lat, locationItem.location && locationItem.location.lon);

      this.set('manualLocation', locationItem.location);
      this.set('geoSearchResult', []);
      this.locationChanged(locationItem.location);
    },


    actions: {
      getLocation: function getLocation() {
        var _this3 = this;

        this.set('locationLoading', true);
        navigator.geolocation.getCurrentPosition(function (location) {
          var browserLocation = { lat: location.coords.latitude, lon: location.coords.longitude };
          _this3.setProperties({
            browserLocationDenied: false,
            browserLocation: browserLocation,
            locationLoading: false,
            searchTerm: '',
            searchTermZip: ''
          });
          _this3.locationChanged(browserLocation);
        }, function (err) {
          if (err) {
            _this3.set('browserLocationDenied', true);
            _this3.set('locationLoading', false);
          }
        });
      },
      resetLocationAction: function resetLocationAction() {
        this.setProperties({
          searchTerm: '',
          searchTermZip: ''
        });
        this.resetLocation();
        // this.set('manualLocation', null);
        // const searchService = this.get('searchService');
        // searchService.set('location', null);
        // searchService.set('sort', null);
        // searchService.updateSearch();
      },
      searchKeyup: function searchKeyup(searchType, e) {
        this.set('searchType', searchType);
        if (searchType === 'zip') {
          this.set('searchTerm', '');
        } else {
          this.set('searchTermZip', '');
        }

        if (e.key === 'ArrowUp') {
          this.changeIndex(-1);
          e.preventDefault();
        } else if (e.key === 'ArrowDown') {
          this.changeIndex(1);
          e.preventDefault();
        } else if (e.key === 'Enter') {
          if (this.geoSearchResult.length) {
            this.selectLocation(this.geoSearchResult[this.selectedIdx]);
          } else {
            this.resetLocation();
          }
          e.preventDefault();
        } else {
          var searchTerm = e.target.value;
          if (searchType === 'zip') {
            this.set('searchTermZip', searchTerm);
          } else {
            this.set('searchTerm', searchTerm);
          }
          this.set('selectedIdx', 0);
          Ember.run.debounce(this, this.geoSearch, searchTerm, 200);
        }
      },
      searchFocusOut: function searchFocusOut(e) {
        var _this4 = this;

        // delay focusOut so geoSearchResults can still trigger "selectLocation" action
        Ember.run.later(function () {
          _this4.set('geoSearchResult', []);
        }, 500);
      },
      selectLocation: function selectLocation(locationItem) {
        this.selectLocation(locationItem);
      },
      setGeoDistance: function setGeoDistance(value) {
        var searchService = this.get('searchService');
        searchService.set('geoDistance', value);
        searchService.updateSearch();
      }
    }
  });
});
define('webapp/components/shopping/shopping-payment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),
    shoppingCart: Ember.computed.alias('shoppingCartService.shoppingCart'),
    paymentService: Ember.inject.service(),

    willRender: function willRender() {
      var shoppingCart = this.get('shoppingCart');
      if (shoppingCart) {
        var paymentMethod = shoppingCart.paymentMethod;
        var _paymentService = this.get('paymentService');

        // INFO: can't show a message because this code gets run
        //       multiple times
        if (!_paymentService.canUsePaymentMethod(paymentMethod)) {
          var shoppingCartService = this.get('shoppingCartService');
          var addressValidated = this.get('userProxy.customer.addressValidated');
          paymentMethod = addressValidated ? 'invoice' : 'prepayment';
          this.set('shoppingCart.paymentMethod', paymentMethod);
          shoppingCartService.updateShoppingCart(this.get('shoppingCart'));
        }
      }
      var paymentService = this.get('paymentService');
      paymentService.fetchPaymentMethodInfo();
    },


    isPayPal: Ember.computed('shoppingCart.paymentMethod', function () {
      var paymentMethod = this.get('shoppingCart.paymentMethod');
      return paymentMethod === 'paypal';
    }),

    isSepa: Ember.computed('shoppingCart.paymentMethod', function () {
      var paymentMethod = this.get('shoppingCart.paymentMethod');
      return paymentMethod === 'sepa_debit';
    }),

    isSofort: Ember.computed('shoppingCart.paymentMethod', function () {
      var paymentMethod = this.get('shoppingCart.paymentMethod');
      return paymentMethod === 'sofort';
    }),

    actions: {
      paymentMethodChanged: function paymentMethodChanged() {
        var shoppingCartService = this.get('shoppingCartService');
        this.set('shoppingCart.paymentSelected', true);
        shoppingCartService.updateShoppingCart(this.get('shoppingCart'));
      },
      next: function next() {
        var owner = Ember.getOwner(this);
        var checkoutOrderController = owner.lookup('controller:checkout.order');
        var shoppingCartService = this.get('shoppingCartService');
        // TODO: probably redundant
        this.set('shoppingCart.paymentStatus', 'approved');
        this.set('shoppingCart.paymentSelected', true);
        shoppingCartService.updateShoppingCart(this.get('shoppingCart'));
        checkoutOrderController.set('payment_intent_client_secret', undefined);
        checkoutOrderController.set('redirect_status', undefined);
        checkoutOrderController.set('payment_intent', undefined);
        checkoutOrderController.set('source_type', undefined);
        this.get('router').transitionTo('checkout.order');
      }
    }
  });
});
define('webapp/helpers/round-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var number = params[0];
    var digits = params[1] || 0;

    try {
      return parseFloat(number).toFixed(digits);
    } catch (e) {
      return number;
    }
  });
});
define("webapp/locales/en/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.register": "Sign up",
    "account.ui.personate": "Impersonate User",
    "account.ui.registeredAs": "You are registered as",
    "account.ui.userNameNotEditableTooltip": "Your username. Your username cannot be changed afterwards. Select carefully.",
    "accountTypes.aussteller": "Exhibitors",
    "accountTypes.gastgeber": "Hosts",
    "accountTypes.veranstalter": "Organizers",
    "application.beta.infotext": "This function is not implemented or no subscriptions active.",
    "application.breadcrumbsHome": "Home",
    "application.month": "per month",
    "application.newsletter.alreadySubscribed": "You are already subscribed to {{list}}. You can access the newsletters in your account.",
    "application.newsletter.alreadySubscribedNoList": "You already have an active subscription for the newsletter",
    "application.newsletter.goToList": "access mailing list",
    "application.newsletter.noAccess": "You have not the right permissions to subscribe to this newsletter.",
    "application.newsletter.subscribeButton": "Enter your e-mail address to subscribe to the newsletter",
    "application.newsletter.subscribeButtonMobile": "E-Mail",
    "application.newsletter.subscribe": "Subscribe",
    "application.newsletter.subscribeList": "You subscribe newsletters from: ",
    "application.newsletter.subscribeThanks": "Thank you for your subscription. A validation e-mail has been sent for a second opt-in.",
    "application.newsletter.subscribeWithAccount": "With your current signed in account.",
    "application.noCosts": "free of charge",
    "application.placeholderImageText": "without illustration",
    "application.searchAllGroups": "in all ",
    "application.searchAllRegions": "in all areas",
    "application.ui.access": "",
    "application.ui.action": "action",
    "application.ui.addImage": "Add image",
    "application.ui.addImages": "Add image",
    "application.ui.advancedEdit": "Advanced",
    "application.ui.all": "All",
    "application.ui.also": "Also",
    'application.ui.alreadyFavorite': "This product is on your wishlist",
    'application.ui.makeFavorite': "Add this product to your wishlist",
    "application.ui.alphabetical": "Alphabetical",
    "application.ui.at": "at",
    "application.ui.atHour": "at",
    "application.ui.back": "back",
    "application.ui.in": "in",
    "application.ui.hour": "h",
    "application.ui.certificate": "Certificates",
    "application.ui.count": "Amount",
    "application.ui.deleteEntry": "delete",
    "application.ui.discardUnsavedChanges": "Discard changes and continue",
    "application.ui.discardUnsavedChangesMobile": "Discard",
    "application.ui.distance": "Distance",
    "application.ui.distant": "away",
    "application.ui.dreampoints": "Entrypoints",
    "application.ui.dreampointsAllDreams": "All your dream's dreampoints",
    "application.ui.dreampointsDonate": "donate amount",
    "application.ui.dreampointsTooltip": "You can collect dreampoints with your dreams",
    "application.ui.edit": "edit",
    "application.ui.editDescription": "edit description",
    "application.ui.editEntry": "edit",
    "application.ui.editEvent": "Edit event",
    "application.ui.editImage": "Edit image",
    "application.ui.editPoi": "Edit place",
    "application.ui.editProduct": "edit product",
    "application.ui.editProfile": "Edit profile",
    "application.ui.editShownProfile": "Change profile",
    "application.ui.entriesFocus": "Selected entrys:",
    "application.ui.entriesLatest": "Recent entrys:",
    "application.ui.for": "for",
    "application.ui.fullView": "full view",
    "application.ui.gallery": "Images",
    "application.ui.gavePoints": "gave you {{points}} points.",
    "application.ui.goToEntry": "Go to this dream",
    "application.ui.goToEvent": "Go to this event",
    "application.ui.goToPoi": "Go to this place",
    "application.ui.goToProduct": "Go to this product",
    "application.ui.hasBeen": "has been",
    "application.ui.here": "Nearby",
    "application.ui.invalidEmbed": "Was not able to embed URL.",
    "application.ui.imprintLink": "legally responsible for the content for {{entry}} sub-page – Imprint",
    "application.ui.isActive": "active",
    "application.ui.isNotActive": "disabled",
    "application.ui.knowMore": "More about {{about}}",
    "application.ui.language.de": "Deutsch",
    "application.ui.language.en": "English",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "Italiano",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "Currently selected language",
    "application.ui.language.switchTo": "Change language to ",
    "application.ui.memberSince": "Member since ",
    "application.ui.mobileRotate": "rotate for full screen image",
    "application.ui.more": "more",
    "application.ui.name": "Name",
    "application.ui.navigateOneLevelUp": "Back to parent folder",
    "application.ui.new": "New",
    "application.ui.newEntry": "Create a new dream",
    "application.ui.newEvent": "Create a new event",
    "application.ui.newPoi": "Create a new place",
    "application.ui.newProduct": "Create a new product",
    "application.ui.noEntry": "No dream created yet. Start your first dream now!",
    "application.ui.noEntryProfile": "has no dream so far.",
    "application.ui.noEvent": "No event created yet. Start your first event now!",
    "application.ui.noPoi": "No place created yet. Create places to be better found by nearby friends.",
    "application.ui.noJob": "You have not created a job yet. Create your first job now...",
    "application.ui.noProduct": "No product created yet. Start your first product now!",
    "application.ui.notEditable": "cannot be edited",
    "application.ui.nothingFound": "We could not find any results for this search.",
    "application.ui.offer": "Skills",
    "application.ui.offer.offers": "Offers",
    "application.ui.offer.acceptOffer": "accept offer",
    "application.ui.offer.rejectOffer": "reject offer",
    "application.ui.offer.noOffers": "no offers",
    "application.ui.offer.offerExpires": "Offer expires",
    "application.ui.offer.setOtherExpirationDate": "set another date",
    "application.ui.offer.inXDays": "in 30 Days",
    "application.ui.expiresAt": "Offer expires",
    "application.ui.only": "Only",
    "application.ui.or": "or",
    "application.ui.playVideo": "play video",
    "application.ui.readMore": "read more",
    "application.ui.region": "Region",
    "application.ui.relevance": "Relevance",
    "application.ui.reset": "Reset",
    "application.ui.routingDescription": "Directions",
    "application.ui.saveShownProfile": "Save profile changes",
    "application.ui.saveUnsavedChanges": "Save",
    "application.ui.select": "Please select",
    "application.ui.showAllEntries": "Show all entrys",
    "application.ui.showAllSearch": "Show all results",
    "application.ui.showMoreSearch": "Show more results",
    "application.ui.resetSearch": "Reset selection and search again",
    "application.ui.sort": "Sort by",
    "application.ui.stockPhotoLinks": "Links to stock photo libraries",
    "application.ui.suitableFor": "Suitable for ",
    "application.ui.total": "Total",
    "application.ui.totalDreampoints": "Entrypoints of all your dreams",
    "application.ui.trySearch": "Would You try another search, like",
    "application.ui.type": "Type",
    "application.ui.unsavedChanges": "Unsaved changes",
    "application.ui.updated": "updated",
    "application.ui.userName": "Username ",
    "application.ui.visibleForAll": "visible for all",
    "application.ui.visibleForFollower": "visible for follower",
    "application.ui.visibleForJoiner": "visible for joiner",
    "application.ui.visibleForNone": "visible for nobody",
    "application.ui.visibleForSupporter": "visible for supporter",
    "application.ui.writeUs": "write us...",
    "application.ui.years": "years old",
    "application.ui.yearsFew": "baby age",
    "application.widgets.highestRatedEntries": "Most dreampoints",
    "application.widgets.inFocus": "Spotlight",
    "application.widgets.newestEntries": "Newest dreams",
    "blog.ui.category": "Category",
    "blog.ui.categoryHeading": "Posts from",
    "blog.ui.lastUpdated": "Last updated ",
    "blog.ui.openSinglePost": "Go to article",
    "blog.ui.publishedIn": "published in",
    "blog.ui.subCategories": "subcategories",
    "blog.ui.written": "written",
    "blog.ui.writtenBy": "written by ",
    "buttons.cancel": "Cancel",
    "buttons.confirm": "Confirm",
    "buttons.delete": "Delete",
    "buttons.deleteFavorite": "Delete follow",
    "buttons.deleteLike": "Delete like",
    "buttons.newPassword": "Request new password",
    "buttons.ok": "Ok",
    "buttons.save": "Save changes",
    "buttons.saveMobile": "Save",
    "buttons.selectThis": "Select this",
    "category.ui.hideCategories": "hide subcategories",
    "category.ui.oneLevelUp": "back to parent category",
    "category.ui.showCategories": "show subcategories",
    "categoryFields.additionalGauge": "",
    "categoryFields.additionalWeight": "",
    "categoryFields.attachmentWidth": "Attachment Width",
    "categoryFields.clutch": "Clutch",
    "categoryFields.companyName": "Company Name",
    "categoryFields.dealerInfo": "Dealer Info",
    "categoryFields.diameter": "Tyre Diameter",
    "categoryFields.displayNetPrice": "Net Prize",
    "categoryFields.electricalPower": "Electrical Power",
    "categoryFields.engine": "Engine",
    "categoryFields.engineDescription": "Engine Equipment",
    "categoryFields.ERPname": "ERP name",
    "categoryFields.horsePower": "Performance",
    "categoryFields.netWidth": "Gauge without tyres",
    "categoryFields.packingUnit": "Packing Unit",
    "categoryFields.PTO": "PTO",
    "categoryFields.relativeGauge": "Additional Gauge",
    "categoryFields.sectionWidth": "",
    "categoryFields.vendorAcronym": "Acronym",
    "categoryFields.volume": "Volume",
    "categoryFields.weight": "Weight",
    "categoryFields.wheelLock": "Wheel Lock-Up when standing",
    "checkout.cart.title": "My shopping basket",
    "checkout.cart.item": "Article",
    "checkout.cart.amount": "Amount",
    "checkout.cart.edit": "change",
    "checkout.cart.price": "Price",
    "checkout.cart.singlePrice": "Unit price",
    "checkout.cart.product.isNonPhysical": "Product is not shipped (may be available for download)",
    "checkout.cart.product.requiresExpressDelivery": "Fresh product. Must be shipped EXPRESS. Only available for Germany",
    "checkout.cart.product.deliveryProblem": "The selected product may be subject to delivery delays.",
    "checkout.cart.subtotal": "Subtotal",
    "checkout.cart.deliveryCosts": "Shipping charges",
    "checkout.cart.total": "Total",
    "checkout.cart.help": "Do you have questions about the ordering process? We have compiled important information for you.",
    "checkout.cart.subtotalInfo": "incl. VAT",
    "checkout.cart.subtotalShipment": "plus deviating shipping costs",
    "checkout.cart.checkout": "Checkout",
    "checkout.cart.checkoutWithoutUser": "Checkout without customer account",
    "checkout.cart.checkoutFast": "Fast Checkout",
    "checkout.cart.deliveryInfo": "Estimated delivery date for the items in the shopping cart",
    "checkout.cart.orderSuccess": "Your order was successful.",
    "checkout.cart.myOrder": "My order",
    "checkout.cart.leftover": "leftover",
    "checkout.checkout.orderNumber": "Order number",
    "checkout.checkout.account": "Customer account",
    "checkout.checkout.andOrder": "and order",
    "checkout.checkout.delivery": "Delivery and delivery address",
    "checkout.checkout.delivery.street": "Address",
    "checkout.checkout.delivery.co": "(c/o)",
    "checkout.checkout.delivery.city": "City",
    "checkout.checkout.delivery.default": "Set this address as my default delivery address",
    "checkout.checkout.delivery.otherAddress": "The billing address differs from the delivery address",
    "checkout.checkout.delivery.dhlNr": "Your DHL number",
    "checkout.checkout.delivery.packingStation": "Packing station number",
    "checkout.checkout.delivery.showPackingStations": "Show Packstations in the vicinity of my address",
    "checkout.checkout.delivery.wantExpressShipping": "Express delivery",
    "checkout.checkout.delivery.isPickUp": "Pick up",
    "checkout.checkout.payment": "Payment method",
    "checkout.checkout.payment.cc": "Credit card",
    "checkout.checkout.payment.ccInfo": "For card payments within the EU, your identity must be confirmed each time you pay online. After ordering, you will be guided through the confirmation - provided by your bank.",
    "checkout.checkout.payment.complimentary": "free",
    "checkout.checkout.payment.paypal": "PayPal",
    "checkout.checkout.payment.paypalInfo": "You will be redirected to PayPal to complete the payment process.",
    "checkout.checkout.payment.sepa": "Direct debit (SEPA)",
    "checkout.checkout.payment.noSepa": "Your billing or delivery address is outside the EU. Therefore, we cannot offer you payment by direct debit.",
    "checkout.checkout.payment.sepaInfo": "Quick and easy. After ordering, you only need to provide your bank details (IBAN).",
    "checkout.checkout.payment.prepayment": "Prepayment (bank transfer)",
    "checkout.checkout.payment.prepaymentInfo": "After you have placed the order, we will send you the bank transfer information by email. We can only reserve the items in your order for a maximum of 7 days. Please keep this time window for the bank transfer. As soon as we receive the payment, the order will be shipped.",
    "checkout.checkout.payment.invoice": "Invoice",
    "checkout.checkout.payment.invoiceInfo": "The invoice amount is due with the dispatch confirmation by e-mail.",
    "checkout.checkout.payment.noInvoice": "Unfortunately, we cannot offer you payment by invoice for this order. Please choose one of the other payment methods.",
    "checkout.checkout.title": "Checkout",
    "checkout.checkout.next": "continue",
    "checkout.checkout.order": "Buy now",
    "checkout.checkout.orderConsentPre": "By placing an order you agree to our  ",
    "checkout.checkout.orderConsentPost": ".",
    "checkout.checkout.noAccount": "Do not create a customer account",
    "checkout.redirect.countryPriceChange": "The prices are different in your country, please take note of the price change.",
    "comments.blockComments": "Commenting on this post has ended.",
    "comments.comment.anonymous": "Anonymous",
    "comments.comment.button": "Add new comment",
    "comments.comment.buttonReply": "Reply",
    "comments.comment.cancel": "Cancel",
    "comments.comment.content": "Your comment",
    "comments.comment.hasCommented": "",
    "comments.comment.login": "Login to submit comments",
    "comments.comment.past": "has commented",
    "comments.comment.replyFrom": "reply to",
    "comments.comment.showReplies": "show replies",
    "comments.comment.showReply": "show reply",
    "comments.comment.submit": "Submit",
    "comments.comment.title": "Comment title",
    "comments.comment.userName": "Your username",
    "comments.heading": "Comments",
    "comments.hierarchy.flat": "flat",
    "comments.hierarchy.nested": "nested",
    "comments.hierarchy.title": "Comment view:",
    "comments.message.afterSaveError": "Comment could not be saved!",
    "comments.message.afterSaveSuccess": "Your comment has been saved.",
    "comments.message.afterSaveUnlock": "Your comment has been saved and is awaiting admin approval.",
    "comments.noComments": "This posts has no comments yet",
    "comments.reply": "RE: ",
    "comments.title": "Comments",
    "entries.edit": "Edit dream <strong>{{title}}</strong>",
    "entries.entries": "Entrys",
    "entries.entry.fields.address.city": "city \n",
    "entries.entry.fields.address.country": "country \n",
    "entries.entry.fields.address.houseNumber": "housenumber \n",
    "entries.entry.fields.address.street": "street \n",
    "entries.entry.fields.address.zip": "zip \n",
    "entries.entry.fields.prefixMissing": "Folgende Felder sind nicht ausgefüllt: \n",
    "entries.entry.profile.edit": "Edit profile",
    "entries.entry.profile.publicProfile": "Go to my public profile",
    "entries.entry.progress.title": "Improve your dream",
    "entries.entry.tooltip.alreadyCopied": "you already copied this entry before",
    "entries.entry.tooltip.alreadyFollow": "you follow this dream already",
    "entries.entry.tooltip.alreadyLiked": "you like this dream already",
    "entries.entry.tooltip.copied": "you successfully copied this entry",
    "entries.entry.tooltip.follow": "click here to follow this dream",
    "entries.entry.tooltip.like": "click here to like this dream",
    "entries.entry.tooltip.noFollow": "you cannot follow your own dream",
    "entries.entry.tooltip.noFollowNotLoggedIn": "sign in to follow this dream",
    "entries.entry.tooltip.noLike": "you cannot like your own dream (although you do :-)",
    "entries.entry.ui.access": "This dream is visible for",
    "entries.entry.ui.added": "Was added.",
    "entries.entry.ui.addEntry": "Add a dream",
    "entries.entry.ui.addOpening": "Add opening hours",
    "entries.entry.ui.addStep": "Add a step",
    "entries.entry.ui.agreementHours": "open by appointment",
    "entries.entry.ui.allAccess": "This dream is visible for all",
    "entries.entry.ui.assignedCategories": "assigned categories",
    "entries.entry.ui.assignedCertificates": "My certificates",
    "entries.entry.ui.assignedProducts": "assigned products",
    "entries.entry.ui.businessContact": "Company contact",
    "entries.entry.ui.categories": "Categories",
    "entries.entry.ui.categoryFields": "Additional information",
    "entries.entry.ui.companyTitle": "My Company information",
    "entries.entry.ui.cssClass": "Additional CSS class",
    "entries.entry.ui.deliveryInfo": "Delivery information",
    "entries.entry.ui.descriptionPlaceholder": "Describe the dream. You can use HTML. 600 characters max.",
    "entries.entry.ui.editEntry": "Edit dream",
    "entries.entry.ui.exclusionHours": "Opening hours exeptions",
    "entries.entry.ui.galleryImages": "Image gallery",
    "entries.entry.ui.globalTitle": "Name",
    "entries.entry.ui.hasKiosk": "I have a kiosk",
    "entries.entry.ui.imagesHorizontal": "horizontal",
    "entries.entry.ui.imagesInfo": "Go to \"My Images/Videos\" to edit images.",
    "entries.entry.ui.imagesOrientation": "Image alignment",
    "entries.entry.ui.imagesSquared": "squared",
    "entries.entry.ui.imagesVertical": "vertical",
    "entries.entry.ui.isListImage": "This image is the list image",
    "entries.entry.ui.isMainImage": "This image is the main image",
    "entries.entry.ui.kioskDescription": "Kiosk description",
    "entries.entry.ui.listImage": "List image",
    "entries.entry.ui.logo": "Logo",
    "entries.entry.ui.longDescription": "Long description",
    "entries.entry.ui.mainImage": "Main image",
    "entries.entry.ui.makeListImage": "Assign this image as list image",
    "entries.entry.ui.makeListImageTooltip": "Search results are shown as list with the list images. If no list image is assigned the main image is shown.",
    "entries.entry.ui.makeMainImage": "Assign this image as main image",
    "entries.entry.ui.makeMainImageTooltip": "The main image is shown in the top banner. Images not assigned as main or list image are shown in the gallery.",
    "entries.entry.ui.metaDescription": "Short description",
    "entries.entry.ui.metaDescriptionPlaceholder": "Short description with 200 characters maximum",
    "entries.entry.ui.multiCode": "Additional Code for this step",
    "entries.entry.ui.multiDescription": "Description for this step",
    "entries.entry.ui.multiImages": "Images for this step",
    "entries.entry.ui.multiProgress": "Progress of this step ",
    "entries.entry.ui.multiTitle": "Title for this step",
    "entries.entry.ui.multiTitlePlaceholder": "Enter step title (max. 50 characters)",
    "entries.entry.ui.noDescriptions": "Descriptions for this dream are missing.",
    "entries.entry.ui.noEntries": "No dreams found.",
    "entries.entry.ui.openAt": "open at",
    "entries.entry.ui.openingHours": "Opening hours",
    "entries.entry.ui.openingInfo": "Opening hours additional information",
    "entries.entry.ui.openingReligious": "Address in mainly catholic region",
    "entries.entry.ui.parking": "Parking",
    "entries.entry.ui.parkingLots": "Lots",
    "entries.entry.ui.parkingType": "Type",
    "entries.entry.ui.personImage": "Contact image",
    "entries.entry.ui.relatedEvents": "Connected Events",
    "entries.entry.ui.relatedJobs": "Connected Jobs",
    "entries.entry.ui.relatedArticles": "Connected Articles",
    "entries.entry.ui.rentKiosk": "I want to rent a kiosk",
    "entries.entry.ui.resolveAddress": "Address geolocation",
    "entries.entry.ui.resolveAddressInfo": "Please geolocate your address to be better found.",
    "entries.entry.ui.resolveGeolocation": "Search for address",
    "entries.entry.ui.routingPlaceholder": "Your directions with HTML.",
    "entries.entry.ui.selectEvent": "Select Event",
    "entries.entry.ui.selectJob": "Select Job",
    "entries.entry.ui.selectArticle": "Select Article",
    "entries.entry.ui.shortDescriptionInfo": "Is short description",
    "entries.entry.ui.sharing": "Share this page on {{sharing}}",
    "entries.entry.ui.shareMail": "Share this page by email",
    "entries.entry.ui.skillsNeeded": "I need...",
    "entries.entry.ui.sortword": "Alternative title for sorting",
    "entries.entry.ui.step": "Step",
    "entries.entry.ui.stepInDream": "Is this step",
    "entries.entry.ui.stepInDreamAfter": "after the dream",
    "entries.entry.ui.stepInDreamBefore": "before the dream",
    "entries.entry.ui.stepInDreamDuring": "during the dream",
    "entries.entry.ui.subtitle": "Descriptive subtitle",
    "entries.entry.ui.subtitlePlaceholder": "Subtitle (max. 60 characters)",
    "entries.entry.ui.tabCategories": "Categories",
    "entries.entry.ui.tabCompany": "Company Info",
    "entries.entry.ui.tabDescription": "Description",
    "entries.entry.ui.tabImages": "Images/Videos",
    "entries.entry.ui.tags": "Tags",
    "entries.entry.ui.title": "The dream appears under the following name",
    "entries.entry.ui.titleError": "To save this dream you must supply a title",
    "entries.entry.ui.titlePlaceholder": "Enter dream title (max. 50 characters)",
    "entries.entry.ui.visible": "This dream is visible",
    "entries.entry.ui.promotion": "Participation in promotions for this entry",
    "entries.entry.ui.regions": "Region categories for this entry",
    "entries.job.ui.promotion": "Participation in promotions for this job",
    "entries.event.edit": "Edit event <strong>{{title}}</strong>",
    "entries.event.ui.addEvent": "Add event",
    "entries.event.ui.cooperation": "Cooperations",
    "entries.event.ui.directions": "Directions to event",
    "entries.event.ui.endDate": "Last day of event",
    "entries.event.ui.eventDone": "exhibition report",
    "entries.event.ui.noEvents": "No events found",
    "entries.event.ui.opening": "Opening hours",
    "entries.event.ui.startDate": "Start day of event",
    "entries.event.ui.title": "This event appears under",
    "entries.event.ui.upcoming": "upcoming",
    "entries.event.ui.moreEvents": "More events",
    "entries.open": "Go to public dream page",
    "entries.person.ui.company": "Company",
    "entries.person.ui.female": "Mrs.",
    "entries.person.ui.fullName": "First and last name",
    "entries.person.ui.fullSize": "Full size",
    "entries.person.ui.gender": "Gender",
    "entries.person.ui.greeting": "Greeting",
    "entries.person.ui.male": "Mr.",
    "entries.person.ui.open": "Go to this persons dreams",
    "entries.person.ui.personAddress": "Personal address",
    "entries.person.ui.personContact": "Personal contact information",
    "entries.person.ui.personImage": "Person image",
    "entries.person.ui.position": "Position/Type",
    "entries.person.ui.quote": "Citation, image description",
    "entries.person.ui.signature": "Signature image",
    "entries.poi.ui.title": "This place appears under",
    "entries.product.edit": "Edit product <strong>{{title}}</strong>",
    "entries.product.fields.engine": "Engine",
    "entries.product.fields.horsePower": "Performance",
    "entries.product.menu.allAttachments": "Attachments Overview",
    "entries.product.menu.allCarriers": "Carriers Overview",
    "entries.product.menu.attachments": "Attachments",
    "entries.product.menu.carriers": "Carriers",
    "entries.product.tags.eco": "eco",
    "entries.product.tags.neu": "new",
    "entries.product.ui.addProduct": "Add product",
    "entries.product.ui.equipmentVariants": "Equipment Versions",
    "entries.product.ui.variants": "Versions",
    "entries.product.ui.gauge": "Gauge in cm",
    "entries.product.ui.gaugeMin": "Minimum gauge in cm",
    "entries.product.ui.inclusive": "incl.",
    "entries.product.ui.live": "see {{name}} live",
    "entries.product.ui.netPrize": "Net prize plus statutory value added tax. Ex works Entschenreuth, and does not include the cost of packing, freight, transport insurance, assembly or commissioning.",
    "entries.product.ui.noProducts": "No products found",
    "entries.product.ui.noPurchaseOnThisPage": "Cannot be purchased on this page. Please open the product itself.",
    "entries.product.ui.onlyBasicVariant": "Basic version only",
    "entries.product.ui.onlyWithCarrier": "Can only purchased together with ",
    "entries.product.ui.order": "Buy now",
    "entries.product.ui.overview": "Overview",
    "entries.product.ui.prizeFrom": "from ",
    "entries.product.ui.required": "Nur mit ",
    "entries.product.ui.suitableFor": "Suitable for",
    "entries.product.ui.suitableForAttachments": "Suitable for following attachments",
    "entries.product.ui.suitableForCarriers": "Suitable for following carriers",
    "entries.product.ui.title": "Product",
    "entries.product.ui.variant": "Variant",
    "entries.product.ui.variantNotOrderable": "The selected variant cannot be ordered",
    "entries.product.ui.inVariants": "in product variants",
    "entries.product.ui.bundleparts": "with following products",
    "entries.product.ui.chooseVariant": "Choose variant first",
    "entries.product.ui.chooseVariantMobile": "Choose variant",
    "entries.product.ui.combinationNotAvailable": "Combination not available",
    "entries.tabbar.basics": "Basics",
    "entries.tabbar.entry": "Entry",
    "entries.tabbar.gallery": "Images",
    "entries.tabbar.layout": "Layout",
    "entries.tabbar.openingHours": "Opening hours",
    "entries.tabbar.order": "Order information",
    "entries.tabbar.tooltip.entryNotSavedDetail": "Entry data can only modified, if the dream has been saved once with title.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "Layout data can only be modified, if the dream has been saved one with title.",
    "entries.types.poi": "Poi",
    "entries.types.standard": "Standard",
    "entries.ui.groupDetailName": "Name for group entries",
    "entries.ui.groupName": "Name for groups (Region)",
    "entryTypes.entry": "Entry",
    "entryTypes.event": "Event",
    "entryTypes.poi": "Place",
    "errors.blank": "{{description}} needs to be filled",
    "errors.description": "This field",
    "errors.email": "{{description}} must be a valid email",
    "errors.empty": "{{description}} can't be empty",
    "errors.exclusion": "{{description}} is reserved",
    "errors.inclusion": "{{description}} is not included in the list",
    "errors.invalid": "{{description}} is invalid",
    "errors.slug": "Slug must contain only lower case text, numbers and \"-\" ",
    "errors.mandatory": "{{field}} is mandatory",
    "errors.mandatoryCheckbox": "Check is mandatory.",
    "firstname": "Firstname",
    "forms.ui.deleteFile": "Delete file",
    "forms.ui.formErr": "Error sending the form",
    "forms.ui.formSent": "Form sent",
    "forms.ui.noForm": "No form assigned",
    "forms.ui.invalidMail": "Please enter a valid e-mail address.",
    "forms.ui.isSubmitted": "Your request has been submitted. We will contact you as soon as possible.",
    "forms.ui.missingFields": "The following fields must be filled in:",
    "forms.ui.uploadSuccess": "{{file}} successfully uploaded",
    "forms.ui.fieldBuilder.street": "Street",
    "forms.ui.fieldBuilder.houseNumber": "Nr.",
    "forms.ui.fieldBuilder.zip": "ZIP",
    "forms.ui.fieldBuilder.city": "City",
    "forms.ui.fieldBuilder.country": "Country",
    "forms.ui.fieldBuilder.firstName": "First name",
    "forms.ui.fieldBuilder.lastName": "Last name",
    "forms.ui.privacyRead": "I have read the privacy policy",
    "media.documents.search.noResult": "Please enter a search term or select a category.",
    "media.documents.search.placeholder": "Search for serial number or type",
    "media.documents.search.reset": "Reset search",
    "media.ui.copyright": "Copyright",
    "media.ui.cropFormat": "Formatvorgabe",
    "media.ui.cropImage": "Crop image",
    "media.ui.cropImageDuplicate": "Duplicate and Crop",
    "media.ui.description": "Description",
    "media.ui.filename": "Filename",
    "media.ui.imageSaved": "Image saved",
    "media.ui.processingImage": "Image processing, please wait",
    "media.ui.resetCrop": "Reset crop",
    "media.ui.resetImage": "Reset and save",
    "media.ui.rotateImage": "Rotate image 90° clockwise",
    "media.ui.title": "Title",
    "name": "Name",
    "openingHours.additionalInfo": "Additional information",
    "openingHours.addOpening": "Add opening hour",
    "openingHours.date.day.di": "Tue",
    "openingHours.date.day.do": "Thu",
    "openingHours.date.day.fr": "Fri",
    "openingHours.date.day.mi": "Wed",
    "openingHours.date.day.mo": "Mon",
    "openingHours.date.day.sa": "Sat",
    "openingHours.date.day.so": "Sun",
    "openingHours.dateS": "Date",
    "openingHours.delete": "Delete",
    "openingHours.from": "from",
    "openingHours.options": "Range",
    "openingHours.time": "Time",
    "openingHours.to": "to",
    "openingHours.type": "Type of opening",
    "opt-in.error-can-not-update-member": "Changes to the member data in {{email}} could not be processed.",
    "opt-in.error-list-does-not-exist": "No list found.",
    "opt-in.error-list-does-not-need-validation": "This list has no opt-in.",
    "opt-in.error-member-not-in-list": "No member for this list found.",
    "opt-in.success-member-verified": "{{email}} was added to {{listname}}.",
    "opt-out.error-can-not-update-member": "Changes to the member data in {{email}} could not be processed.",
    "opt-out.error-list-does-not-exist": "No list found.",
    "opt-out.error-list-does-not-have-opt-out": "This list has no opt-out.",
    "opt-out.error-member-not-in-list": "No member for this list found.",
    "opt-out.success-membership-terminated": "{{email}} was successfully removed from {{listname}}.",
    "password.errors.not-secure-enough": "We recommend a password with a minimum length of 8–10 characters. Please supply additional characters like numbers or special characters.",
    "password.errors.password-and-conf-do-not-match": "Both passwords must match.",
    "password.quality.0": "Very bad password quality, easy to hack",
    "password.quality.1": "Bad password quality.",
    "password.quality.2": "Not so good password, but OK.",
    "password.quality.3": "Good password",
    "password.quality.4": "Very well choosen password.",
    "profile.entries.copycats": "Entrys {{userName}} also wants to entry",
    "profile.entries.donates": "Entrys {{userName}} is supporting",
    "profile.entries.entries": "{{userName}}s Entrys",
    "profile.entries.follows": "Entrys {{userName}} is following",
    "profile.entries.joins": "Entrys {{userName}} joined",
    "profile.marketplace.new": "New Skill or Request",
    "profile.marketplace.offer.delete": "Delete Skill",
    "profile.marketplace.offer.description": "Descripe your skill",
    "profile.marketplace.offer.descriptionPlaceholder": "relevant description, to be better matched.",
    "profile.marketplace.offer.new": "New Skill",
    "profile.marketplace.offer.relatedEntry": "related Entry",
    "profile.marketplace.offer.tags": "Skills tags (helps to be better found)",
    "profile.marketplace.request.delete": "Delete Request",
    "profile.marketplace.request.new": "New Request",
    "profile.messages.approve": "Approve",
    "profile.messages.as": "as",
    "profile.messages.comemntSuff": " ",
    "profile.messages.commentPre": "commented",
    "profile.messages.decided": "to entry",
    "profile.messages.deletedUser": "Deleted user",
    "profile.messages.donateDream": "",
    "profile.messages.dreamer": "dreamer",
    "profile.messages.dreamToo": "too",
    "profile.messages.enter": "entered",
    "profile.messages.favoriteDream": "You follow this entry now.",
    "profile.messages.follow": "follows",
    "profile.messages.has": "has decided",
    "profile.messages.is": "is",
    "profile.messages.join": "joined",
    "profile.messages.joinDream": "You want to join this entry.",
    "profile.messages.likeDream": "You like this entry now.",
    "profile.messages.sendText": "Your message has been sent.",
    "profile.messages.shareDream": "You have shared this entry.",
    "profile.messages.supporter": "supporter",
    "profile.messages.want": "wants",
    "profile.messages.youAre": "You are",
    "profile.messages.youWant": "You want to",
    "profile.newsletter.notSubscribed": "subscribe",
    "profile.newsletter.privacyInformation": "By clicking subscribe you opt-in to receive the future newsletters on this list. You can opt-out by clicking subscribe again.",
    "profile.newsletter.subscribed": "subscribed",
    "profile.press.articleDetail": "Article detail",
    "profile.press.downloadImage": "Download image",
    "profile.press.downloadImages": "Download images",
    "profile.press.downloadText": "Export text",
    "profile.press.filterAuthor": "Filter by author",
    "profile.press.hide": "hide",
    "profile.press.show": "show",
    "profile.progress.title": "Improve your profile",
    "register.ui.addressData": "Address",
    "register.ui.addressInfo": "No need to supply your address, but you can be found more easily if so.",
    "register.ui.companyData": "Company Information",
    "register.ui.companyDataMandatory": "You must supply for company name",
    "register.ui.emptyFirstName": "Please supply your first name",
    "register.ui.emptyLastName": "Please supply your last name",
    "register.ui.emptyMail": "Please supply a valid e-mail address",
    "register.ui.emptyUserName": "The username is mandatory",
    "register.ui.language": "Language",
    "register.ui.loginData": "Login information",
    "register.ui.password": "Password",
    "register.ui.passwordRepeat": "Repeat password",
    "register.ui.personalData": "Personal information",
    "register.ui.takenEmail": "This e-mail is already registered. Please supply a different e-mail.",
    "register.ui.takenUserName": "This username has already been taken",
    "register.ui.timezone": "Timezone",
    "register.ui.typeMinimum": "You have to choose one type minimum",
    "register.ui.usernameInfo": "Important: this is your login and profile name",
    "register.ui.oauthInfo": "You will be redirected to the FRM login page to log in with your user credentials and returned to the website.",
    "search.andFilter": " and filter",
    "search.for": "for",
    "search.zipCountries": "Postcodes from",
    "search.resetPosition": "Reset position",
    "search.geoPositionBrowser": "User browser location",
    "search.geoPositionFault": "Position by browser could not be retrieved.",
    "search.geoPositionTitle": "Enter your position for a ditance search",
    "search.hits": "hits",
    "search.removeDatefilter": "remove filter",
    "search.resulted": "results in",
    "search.resultType.category": "Category",
    "search.resultType.entry": "Dealer",
    "search.resultType.event": "Event",
    "search.resultType.group": "Group",
    "search.resultType.page": "Page",
    "search.resultType.person": "Person",
    "search.resultType.post": "Post",
    "search.resultType.product": "Product",
    "search.resultType.job": "Job",
    "search.search": "search",
    "search.searchBarPlaceholder": "search...",
    "search.searchBarPlaceholderEntries": "search for dealer name oder address...",
    "search.searchBarPlaceholderEntriesPosition": "For distance search use your position.",
    "search.searchBarPlaceholderGeoSearch": "type your address...",
    "search.searchBarPlaceholderMobile": "search",
    "search.searchBarPlaceholderProducts": "search products...",
    "search.searchBarPositionTooltip": "Determine position or enter location with address",
    "social.comment": "Comment",
    "social.Comment": "Comment",
    "social.dream.alreadyMeToo": "You are already entrying this entry",
    "social.dream.done": "DONE",
    "social.dream.edit": "Edit dream",
    "social.dream.followedDreams": "Entries you follow",
    "social.dream.joinerApproved": "You participate in this dream",
    "social.dream.joinerButton": "Join this dream",
    "social.dream.joinerButtonMobile": "Join",
    "social.dream.joinerDream": "Group dream",
    "social.dream.joinerInfo": "This dream will turn into a group dream, when the dream owner approves your request.",
    "social.dream.joinerMessage": "Your message for the entry owner",
    "social.dream.joinerPending": "The dream owner must approve your join request",
    "social.dream.joinerSupport": "You support this dream",
    "social.dream.joinerSupportButton": "Support this dream",
    "social.dream.joinerSupportButtonMobile": "Support",
    "social.dream.joinerSupportInfo": "We always implement new features like cash or entrypoints support. Stay tuned.",
    "social.dream.joinerSupportPending": "The dream owner must approve your dream request",
    "social.dream.member": "Member",
    "social.dream.members": "Members",
    "social.dream.meToo": "I want to dream this, too",
    "social.dream.noFollow": "You follow no dream",
    "social.dream.noJoiner": "Your not a member of a Group-Entry",
    "social.dream.noLike": "You haven't liked a dream yet!",
    "social.dream.noSupport": "You don't support any dreams",
    "social.dream.registerFirstText": "In order to join or support a entry You have to be logged in. Register now. It is fast, easy and free. Just click the \"Sign-In\" Button.",
    "social.dream.registerFirstTitle": "Join or Support?",
    "social.dream.settings": "Entry settings",
    "social.dream.shareTitle": "Share the dream ",
    "social.dream.similar": "Similar dreams",
    "social.dreamline.addImageToDream": "Add this image to your dream",
    "social.dreamline.addMedia": "Add image/video",
    "social.dreamline.allActivities": "All activities",
    "social.dreamline.anonymous": "Anonymous",
    "social.dreamline.commentButton": "Comment",
    "social.dreamline.comments": "writes:",
    "social.dreamline.depublished": "This comment has been removed.",
    "social.dreamline.dreampointsInfo": "This are the dream's dreampoints. More information about dreampoints in your profile.",
    "social.dreamline.edited": {},
    "social.dreamline.fewDreampoints": "has only few dreampoints. Comment, like or share this dream.",
    "social.dreamline.filterBySteps": "Filter by steps",
    "social.dreamline.filterComment": "comments",
    "social.dreamline.filteredBy": "Activities filtered by",
    "social.dreamline.filteredBySteps": "Filtered by steps",
    "social.dreamline.filterFavorite": "follows",
    "social.dreamline.filterLike": "likes",
    "social.dreamline.filterShare": "shares",
    "social.dreamline.filterStep": "steps",
    "social.dreamline.flagged": "This reply has been reported",
    "social.dreamline.isComment": " ago",
    "social.dreamline.isCopycatJoiner": "",
    "social.dreamline.isFavorite": "",
    "social.dreamline.isFirstUpdate": " created",
    "social.dreamline.isJoiner": "",
    "social.dreamline.isLike": "",
    "social.dreamline.isShare": " shared",
    "social.dreamline.isStep": " has made a new step",
    "social.dreamline.isUpdate": " updated",
    "social.dreamline.lastEntry": "Last dreamline entry",
    "social.dreamline.locked": "This Content is only visible for Joiner",
    "social.dreamline.noMessages": "This dream has no activity yet. Be the first to comment this dream",
    "social.dreamline.noMessagesFiltered": "No activities in the dreamline with this filter settings",
    "social.dreamline.nowFollower": "follws this dream",
    "social.dreamline.nowJoiner": "is now supporter",
    "social.dreamline.nowLikes": "likes this dream.",
    "social.dreamline.nowSharing": "has shared this dream.",
    "social.dreamline.onlyJoinerVisible": "Only visible for joiner or supporter",
    "social.dreamline.prefixComment": "commented this dream  ",
    "social.dreamline.prefixCopycatJoiner": "wants to entry this entry too since",
    "social.dreamline.prefixFavorite": "follows this dream since ",
    "social.dreamline.prefixJoiner": "supports this dream since ",
    "social.dreamline.prefixLike": "likes this dream since ",
    "social.dreamline.prefixShare": "shared this dream at ",
    "social.dreamline.prefixStep": "has made a new step ",
    "social.dreamline.prefixUpdate": "has updated this dream at ",
    "social.dreamline.profile": "Profile",
    "social.dreamline.provideImage": "the dream owner can use this image",
    "social.dreamline.resetFilter": "Clear filter",
    "social.dreamline.sendComment": "send comment",
    "social.dreamline.sharePart": "share",
    "social.dreamline.toggleStream": "comments",
    "social.dreamline.toggleStreamSingle": "comment",
    "social.dreamline.writeComment": "Write comment...",
    "social.Favorite": "Follow",
    "social.follow": "Follow",
    "social.Follow": "Follow",
    "social.hasLiked": "You like this dream already.",
    "social.join": "Join",
    "social.Join": "Join",
    "social.like": "Like",
    "social.Like": "Like",
    "social.report.headlinePrefix": "You are about to report ",
    "social.report.headlineSuffix": "",
    "social.report.login": "To report an entry, you have to be logged in.",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "Other offensive content",
    "social.report.reportScam": "Scam",
    "social.report.reportSex": "Sexual content",
    "social.report.reportSpam": "Spam",
    "social.report.reportViolence": "Voilence",
    "social.report.send": "send report",
    "social.report.title": "report",
    "social.share": "Share",
    "social.Share": "Share",
    "user.anonymous-user-name": "anonymous",
    "user.company.companyName": "Company name",
    "user.company.copyContact": "Use contact information from company profile",
    "user.company.email": "E-mail for customer contacts",
    "user.company.facebook": "Company's facebook account (account name only)",
    "user.company.fax": "Contact fax number",
    "user.company.google": "Company's google+ account (account name only)",
    "user.company.instagram": "Company's instagram account (account name only)",
    "user.company.newCustomerAccount": "Create a business-account",
    "user.company.noCustomerValidation": "Your business account is awaiting validation.",
    "user.company.noCustomerYet": "Your registration has no business account yet",
    "user.company.phone": "Contact phone number",
    "user.company.pinterest": "Company's pinterest account (account name only)",
    "user.company.remarks": "Remarks",
    "user.company.twitter": "Company's twitter account (account name only)",
    "user.company.website": "Company's website",
    "user.message.after-save-error": "Changes could not be saved.",
    "user.message.after-save-success": "Changes have been saved.",
    "user.message.can-not-login": "We cannot sign you in!",
    "user.message.can-not-logout": "We could not sign you out!",
    "user.message.conflict": "This username is already taken. Please try another one.",
    "user.message.fileExistsError": "Upload failed. File already exists",
    "user.message.fileSizeError": "Upload failed. File size limit reached",
    "user.message.loginFailureBottom": "",
    "user.message.loginFailureTop": "",
    "user.message.registration-error": "An error occured during registration. Please try again.",
    "user.message.registration-success": "You should receive a confirmation mail.",
    "user.message.unknownError": "Unknown error while uploading the file",
    "user.message.verify-error": "Your email could not be verified. Please contact the administrator.",
    "user.message.verify-success": "Your email is now verified. You can now log in. ",
    "user.ui.accountGreeting": "Hello, ",
    "user.ui.accountMenuActivities": "Activities",
    "user.ui.accountMenuCompanySettings": "Company Info",
    "user.ui.accountMenuEntries": "My Entries",
    "user.ui.accountMenuEvents": "My Events",
    "user.ui.accountMenuFavorites": "Favorites",
    "user.ui.accountMenuFollows": "Follows",
    "user.ui.accountMenuImages": "My Images/Videos",
    "user.ui.accountMenuJoin": "Joined Entries",
    "user.ui.accountMenuLikes": "Entries I like",
    "user.ui.accountMenuMessages": "Messages",
    "user.ui.accountMenuNewsletter": "My Newsletters",
    "user.ui.accountMenuPoi": "My Places",
    "user.ui.accountMenuPress": "My Articles",
    "user.ui.accountMenuProducts": "My Products",
    "user.ui.accountMenuJobs": "My Job Offers",
    "user.ui.accountMenuProfile": "Profile",
    "user.ui.accountMenuRegistrationData": "Registration information",
    "user.ui.accountMenuReviews": "Reviews",
    "user.ui.accountMenuSettings": "Settings",
    "user.ui.accountMenuSkills": "My Skills and Offers",
    "user.ui.accountMenuSkillsOffer": "I need...",
    "user.ui.accountMenuSkillsRequest": "I offer...",
    "user.ui.accountMenuSubscriptions": "My Subscriptions",
    "user.ui.accountMenuSupport": "Supported Entries",
    "user.ui.accountMenuSupports": "Entries I support",
    "user.ui.accountNoMessages": "You have received no messages yet.",
    "user.ui.accountProgressbar": "Your profile is {{value}}% complete.",
    "user.ui.accountProgressbarEntry": "Your dream is {{value}}% complete.",
    "user.ui.accountSettings": "Advanced Settings",
    "user.ui.accountCookieSettings": "Or choose your settings.",
    "user.ui.accountCookieEssential": "Essential",
    "user.ui.accountCookieAnalysis": "Analytics",
    "user.ui.accountCookieSocial": "Social Media",
    "user.ui.accountCookieReviews": "Reviews",
    "user.ui.accountToolbarLogin": "Sign in",
    "user.ui.accountToolbarLoginTooltip": "Sign in",
    "user.ui.accountToolbarLogout": "Logout",
    "user.ui.adminPreview": "Preview",
    "user.ui.backToLogin": "Back to login",
    "user.ui.birthday": "Birthday",
    "user.ui.cannotLogin": "Login problems? Click here to reset Your password.",
    "user.ui.changePassword": "I want to change my password",
    "user.ui.changeUserGroup": "Upgrade your user group",
    "user.ui.city": "City",
    "user.ui.commercialTitle": "Company info for commercial dream",
    "user.ui.companyName": "Company name",
    "user.ui.companyNameTooltip": "Important: your dream is published under the company name.",
    "user.ui.country": "Country",
    "user.ui.createNewAccount": "New account",
    "user.ui.dataDelete": " other entries. Do you want to delete the file permanently? All file occurencies were replaced by a placeholder.",
    "user.ui.dataUsage": "The file is used in ",
    "user.ui.dataUsageNone": "This file is not in use, yet. Do you want to delete this file?",
    "user.ui.deleteImage": "Delete image",
    "user.ui.description": "Describe yourself",
    "user.ui.documents": "Documents",
    "user.ui.documentSearch": "Document search",
    "user.ui.download": "Download {{title}}",
    "user.ui.email": "E-mail address",
    "user.ui.fillOutForm": "All mandatory fields must be filled out to register",
    "user.ui.firstName": "First name",
    "user.ui.academicTitle": "Academic title",
    "user.ui.gender": "M/F",
    "user.ui.genderFemale": "female",
    "user.ui.genderFemaleSalutation": "Ms.",
    "user.ui.genderMale": "male",
    "user.ui.genderMaleSalutation": "Mr.",
    "user.ui.genderDiverse": "diverse",
    "user.ui.housenumber": "Number",
    "user.ui.iAmCustomer": "I want to create a share your dream business account",
    "user.ui.internalInfo": "We have news for you. Please click here.",
    "user.ui.lastLogin": "Last login ",
    "user.ui.lastName": "Last name",
    "user.ui.loginButton": "Sign in",
    "user.ui.loginTitle": "Sign in",
    "user.ui.languageAvailable": "Available in your language",
    "user.ui.languageMissing": "Only available in",
    "user.ui.memberSince": "Member since ",
    "user.ui.myAddedStorage": "Usable images from other users",
    "user.ui.myStorage": "My directory",
    "user.ui.noRegisterMessage": "Automatic user registration is not active. For access and how to setup an user account, please contact the site admninistrator.",
    "user.ui.oldPassword": "Old password",
    "user.ui.password": "Password",
    "user.ui.registerButton": "Set up your account now",
    "user.ui.registerMessage": "Sign up now and you can start immediately.",
    "user.ui.registerTitle": "New here? Welcome!",
    "user.ui.requestPasswordReset": "Request new password and delete the old one.",
    "user.ui.resetPassword": "Reset password",
    "user.ui.resetPasswordPage": "Here, you can reset your password",
    "user.ui.resetPasswordSuccess": "Your password was reset, you can now login",
    "user.ui.selectCustomerType": "You are ... please select",
    "user.ui.stayLoggedin": "Stay logged in",
    "user.ui.stayLoggedinInfo": "Deactivate this option if you sign in at a publicly available device.",
    "user.ui.street": "Street",
    "user.ui.title": "Title",
    "user.ui.uploadDrop": "Drag a file here to upload or ",
    "user.ui.uploadSelectFile": "select a file.",
    "user.ui.uploadToMyStorage": "Upload new images to my image directory",
    "user.ui.uploadUserImage": "Choose user image",
    "user.ui.username": "Username (or e-mail)",
    "user.ui.userName": "Username",
    "user.ui.zip": "ZIP",
    "user.user": "entryer",
    "user.users": "entryers",

    "user.product.addedToFavorites": "The item was added to your favorites.",

    "country.germany": "Germany",
    "country.de": "Germany",
    "country.austria": "Austria",
    "country.at": "Austria",
    // EU
    "country.belgium": "Belgium",
    "country.bulgaria": "Bulgaria",
    "country.croatia": "Croatia",
    "country.cyprus": "Cyprus",
    "country.czechia": "Czechia",
    "country.denmark.": "Denmark",
    "country.estonia": "Estonia",
    "country.finland": "Finland",
    "country.france": "France",
    "country.fr": "France",
    "country.greece": "Greece",
    "country.hungary": "Hungary",
    "country.ireland": "Ireland",
    "country.italy": "Italy",
    "country.it": "Italy",
    "country.latvia": "Latvia",
    "country.lithuania": "Lithuania",
    "country.luxembourg": "Luxembourg",
    "country.lu": "Luxembourg",
    "country.malta": "Malta",
    "country.netherlands": "Netherlands",
    "country.poland": "Poland",
    "country.portugal": "Portugal",
    "country.romania": "Romania",
    "country.slovakia": "Slovakia",
    "country.slovenia": "Slovenia",
    "country.spain": "Spain",
    "country.sweden": "Sweden",
    // other european
    "country.norway": "Norway",
    "country.switzerland": "Switzerland",
    "country.ch": "Switzerland",
    "country.turkey": "Turkey",
    "country.unitedKingdom": "United Kingdom",
    "country.gb": "United Kingdom",

    "openingHours.tNowClosed": "Now closed",
    "openingHours.tOpeningHoursNotKnown": "further opening hours not known",
    "openingHours.tNowOpen": "Now open",
    "openingHours.tNowStillOpen": "Now still open",
    "openingHours.tNextOpeningHourUnknown": "next opening date unknown",
    "openingHours.tSoonOpenFrom": "soon open again from",
    "openingHours.tFrom": "from",
    "openingHours.tUntil": "until",
    "openingHours.tOpenAgainFrom": "open again from",
    "openingHours.tOpenAgainFrom2": "open again on",

    // auto translated
    "account.ui.entryBannerImageNew": "Set a main image for your entry.",
    "account.ui.profileBannerImageNew": "Set a main image for your profile.",
    "application.ui.addDocument": "Add document",
    "application.ui.addRelation": "Add relation",
    "application.ui.addMedia": "Add pictures/videos",
    "application.ui.addVideo": "Add video",
    "application.ui.and": "and",
    "application.ui.buy": "buy",
    "application.ui.confirmDeleteEntry": "Delete the entry {{title}} delete the entry irrevocably?",
    "application.ui.contact": "Contact",
    "application.ui.currentlyActive": "Running right now",
    "application.ui.email": "Email",
    "application.ui.galleryImages": "to the gallery...",
    "application.ui.goToMeasuring": "Measuring points",
    "application.ui.mailBodyPre": "Hello, I found something interesting:",
    "application.ui.mailSubject": "I found an interesting link:",
    "application.ui.mandatory": "Mandatory field",
    "application.ui.openDocument": "Open file",
    "application.ui.shop": "Shop",
    "application.ui.searchEnter": "Enter search term and press ⏎ ",
    "application.ui.uploadedFileFor": "Uploaded file for:",
    "buttons.alreadyMeToo": "Glad you dreamed this entry too.",
    "buttons.alreadyMeTooMobile": "Glad you dreamed this entry too.",
    "buttons.createAccount": "Set up EntryerAccount now",
    "buttons.meToo": "I want to do that too",
    "buttons.ready": "Ready",
    "buttons.tryAgain": "Please try again",
    "checkout.checkout.delivery.type": "The dispatch takes place with",
    "checkout.checkout.delivery.houseNumber": "No.",
    "checkout.checkout.delivery.selectCountry": "Please select country",
    "checkout.checkout.delivery.addressBoth": "Invoice and delivery address",
    "checkout.checkout.delivery.addressInvoice": "Invoice address",
    "checkout.checkout.delivery.addressDelivery": "Delivery address",
    "checkout.checkout.delivery.specialCountryInfo": "Your country cannot be selected?",
    "checkout.checkout.delivery.specialCountry": "Your country outside our standard shipping",
    "checkout.checkout.delivery.specialCountryMessage": "Individual products in your shopping cart cannot be shipped to all countries. Please remove some products from your order until your country can be selected or contact us.",
    "checkout.checkout.paymentTitle": "How do you intend to pay?",
    "checkout.checkout.paymentSelected": "Selected payment method",
    "checkout.checkout.payment.sepaLegal": "I authorize {{siteOwner}} {{siteOwnerAddress}} (Creditor ID: {{siteOwnerCreditorID}}) to collect payments from my account by direct debit. At the same time, I instruct my credit institution to honour the direct debits {{siteOwner}} to my account.",
    "checkout.checkout.payment.sofort": "Sofortüberweisung",
    "checkout.checkout.payment.sofortInfo": "When completing your order (next step) you will be asked to enter your bank details (IBAN). Please have these ready. When the transfer is completed, you will receive a confirmation.",
    "checkout.checkout.payment.accountName": "Account holder",
    "checkout.checkout.payment.accountEmail": "Confirmation email address",
    "checkout.checkout.sepaAndOrder": "Direct debit mandate for {{totalAmount}} € confirm and order with costs",
    "checkout.checkout.sofortAndOrder": "Transfer mandate for {{totalAmount}} € confirm and order with costs",
    "comments.show": "Show comments",
    "entries.entry.fields.language": "Language",
    "entries.entry.tooltip.filterComments": "Click here to show only Comments in the Entryline",
    "entries.entry.tooltip.filterFollows": "Click here to show only Follows in the Entryline",
    "entries.entry.tooltip.filterLikes": "Click here to show only likes in the entryline",
    "entries.entry.tooltip.filterShares": "Click here to show only shares in the entryline",
    "entries.entry.ui.address": "Address data",
    "entries.entry.ui.commercialTitle": "Company information on the entry",
    "entries.entry.ui.contactPerson": "My contact details",
    "entries.entry.ui.descriptionInfo": "Tip: When words are highlighted, styles can be assigned.",
    "entries.entry.ui.editEntryNew": "Create entry",
    "entries.entry.ui.isMainImageAndListImage": "This image appears as the main image of the entry",
    "entries.entry.ui.onlyVisibleForCustomers": "This entry is only visible for registered customers",
    "entries.entry.ui.overallProgress": "This entry is {{value}}% realized",
    "entries.entry.ui.relatedCampaigns": "Related campaigns",
    "entries.entry.ui.selectCampaign": "Select campaign",
    "entries.entry.ui.tabCategoriesMobile": "Cat.",
    "entries.entry.ui.tabDescriptionMobile": "Beschr.",
    "entries.entry.ui.tabExhibitor": "Exhibitor details",
    "entries.entry.ui.tabImagesMobile": "Pictures/Videos/Files",
    "entries.entry.ui.tabRelations": "Connections",
    "entries.event.ui.editEvent": "Edit event",
    "entries.event.ui.editEventNew": "Create business event",
    "entries.event.ui.openEvent": "Call event",
    "entries.event.ui.tabCategories": "Categories",
    "entries.event.ui.tabDescription": "Description",
    "entries.event.ui.tabOpening": "Date and times",
    "entries.event.ui.tabRelations": "Connections",
    "entries.person.ui.diverse": "Without",
    "entries.poi.ui.poiEvents": "Events at this location",
    "product.ui.content": "Content",
    "product.ui.ingredients": "Components",
    "product.ui.ingredientsFood": "Ingredients",
    "product.ui.nonPhysical": "Electronic product download",
    "entries.product.ui.choose": "select",
    "entries.product.ui.discount": "Discount",
    "entries.product.ui.discountSelect": "% discount advantage",
    "entries.product.ui.netPrizeDefault": "including value added tax, plus",
    "entries.product.ui.deliveryTerms": "Shipping costs",
    "entries.product.ui.notOrderable": "Please select a variant.",
    "entries.product.ui.prizeDecimalPoint": ".",
    "entries.product.ui.item": "Piece",
    "entries.product.ui.prizePerItem": "Price per piece",
    "entries.product.ui.noPrize": "Please ask your contact person for the price.",
    "entries.product.ui.size": "Size",
    "entries.progress.title": "How to improve your entry",
    "errors.mandatorySubmit": "You can only submit the form when all mandatory fields have been filled in.",
    "jobs.ui.employmentType.FULL_TIME": "Full-time",
    "jobs.ui.employmentType.PART_TIME": "Part-time",
    "jobs.ui.employmentType.APPRENTICE": "Apprentice",
    "jobs.ui.employmentType.INTERN": "Internship",
    "jobs.ui.employmentType.CONTRACTOR": "Freelance work",
    "jobs.ui.employmentType.TRAINEE": "Trainee, extra-occupational studies",
    "jobs.ui.employmentType.VOLUNTEER": "Voluntary",
    "jobs.ui.employmentType.OTHER": "",
    "jobs.ui.location": "Job available at",
    "jobs.ui.role.male": "M",
    "jobs.ui.role.female": "F",
    "jobs.ui.role.divers": "D",
    "jobs.ui.startDateNow": "As of now",
    "jobs.ui.startFrom": "From",
    "profile.marketplace.fields.energyRequired": "electricity demand",
    "profile.marketplace.fields.livingAnimals": "Taking of live animals",
    "profile.marketplace.fields.loadingUnloading": "Necessary for arrival/departure",
    "profile.marketplace.fields.maxRange": "For my offer I am already so many km to drive",
    "profile.marketplace.fields.placeRequired": "Requirements for the pitch",
    "profile.marketplace.fields.securityRequired": "Need for security",
    "profile.marketplace.fields.shows": "Program offer",
    "profile.marketplace.fields.spaceRequired": "Space requirement in m²",
    "profile.marketplace.fields.waterRequired": "Demand for water in m³",
    "profile.marketplace.offer.title": "I offer",
    "profile.marketplace.request.missingCredentials": "You can only start a query when you have created at least one location and one measuring point.",
    "profile.marketplace.request.title": "I need",
    "profile.messages.commentSuff": "Comments",
    "profile.newsletter.notSubscribedAny": "You have not yet subscribed to a newsletter list.",
    "register.ui.privacyStatement": "I agree that my personal data will be stored. My personal data will not be passed on to third parties. I can revoke this data protection consent at any time with effect for the future.",
    "search.allDistances": "All distances",
    "search.closeTags": "Hide tags",
    "search.selectedFilters": "Selected filters",
    "search.selectFilters": "Filter display",
    "search.openTags": "Show tags",
    "search.resultType.recipe": "Recipe",
    "search.showAll": "Show all",
    "social.CopycatJoiner": "Dreams your entry",
    "social.dream.donateAmount": "Support with a sum of money",
    "social.dream.donateDreampoints": "Support with Dreampoints",
    "social.dream.donateInfo": "The message is private and can only be read by the entry owner.",
    "social.dream.donateTip": "Support with advice and action",
    "social.dream.findDreams": "Find small and large entries here and make your entry real and/or help others realize their entries.",
    "social.dream.giveEmail": "Share my email address",
    "social.dream.givePhone": "Share my phone number",
    "social.dream.meTooInfo": "Show the entry owner that you think the entry is great and that you want to make this entry.",
    "social.dream.noLikes": "You don't like any entry yet!",
    "social.dreamline.lockedCTA": "Join this entry now",
    "social.Joiner": "Dreaming along",
    "syd.ui.dreamerCommercial": "B2B standard",
    "syd.ui.userCommercial": "B2B Dreamer",
    "user.company.addUser": "Create additional users for",
    "user.company.inviteUser": "Send invitation mail",
    "user.company.userIsInvited": "Invitation mail was sent",
    "user.company.userIsActive": "User is active",
    "user.company.userIsNotActive": "User is not yet activated",
    "user.company.additionalWebsite": "Other website of the company (only domain without https://)",
    "user.company.contactFor": "Contact details for",
    "user.company.contactForDefault": "general contact",
    "user.company.linkedin": "LinkedIn account of the company (account name only)",
    "user.company.notAllowed": "You cannot make any changes because your user status ({{type}}) does not allow this.",
    "user.company.registerNumber": "Commercial register number",
    "user.company.registerAuth": "Local court",
    "user.company.acerCode": "ACER code of your energy supply",
    "user.company.taxNumber": "Tax number",
    "user.company.type.default": "Standard",
    "user.company.type.procurator": "Authorized signatory",
    "user.company.type.manager": "Managing Director",
    "user.company.ustid": "UStID",
    "user.company.ustid.notValid": "This sales tax identification number is invalid",
    "user.company.xing": "Xing account of the company (account name only)",
    "user.company.youtube": "Youtube account of the company (account name only)",
    "user.message.upload-success": "Upload successful",
    "user.ui.accountMenuEntryPre": "Entries,",
    "user.ui.accountMenuMeasuring": "My measurement locations and data",
    "user.ui.accountMenuRatings": "Reviews",
    "user.ui.accountMenuOffer": "My offers",
    "user.ui.accountMenuRequest": "My requests",
    "user.ui.accountMenuSkillsSearch": "Requests and offers",
    "user.ui.accountMenuSubscritions": "My subscription",
    "user.ui.accountMenuTender": "Tenders",
    "user.ui.accountMenuOrders": "My orders",
    "user.ui.genderThird": "not stated",
    "user.ui.mobileNumber": "Mobile number",
    "user.ui.pleaseSelect": "Please select",
    "user.ui.pleaseSelectSalutation": "Please select salutation",
    "user.ui.requestPasswordSuccess": "You should receive an email with a reset link shortly.",
    "user.ui.requestPasswordError": "Unknown error when resetting the password.",
    "user.ui.setPassword": "Set password",
    "user.ui.setPasswordPage": "Set your password",
    "user.ui.setPasswordSuccess": "Your password has been set, you can log in now",
    "user.ui.salutation": "Salutation",
    "user.ui.visibilityInfo": "Tip: You decide what is displayed publicly from your profile! Click on the eye symbol to set the visibility of your entries:",
    "user.ui.phone": "Phone number",
    "order.status.zero": "order received",
    "order.status.one": "In process",
    "order.status.two": "Order has been shipped",
    "order.status.three": "Accessed",
    "order.status.four": "Return",
    "order.status.default": "Unknown occupation state",
    "country.denmark": "Denmark",

    // auto translated new
    "account.ui.additionalDeliveryAddresses": "You have specified different delivery addresses.",
    "account.ui.editDeliveryAddresses": "Edit delivery address",
    "account.ui.editInvoiceAddress": "Edit company address",
    "account.ui.noDeliveryAddresses": "You have not yet specified a different delivery address.",
    "account.ui.addDeliveryAddresses": "Add a {{further}} Add a different delivery address",
    "account.ui.addDeliveryAddressTitle": "Add delivery address",
    "account.ui.editDeliveryAddressTitle": "Edit delivery address",
    "account.ui.isDefaultAddress": "This is the default delivery address",
    "account.ui.saveToApplyChanges": "You must save the change, then you can, for example, specify different delivery addresses.",
    "checkout.cart.orderCommitting": "Your order is being processed - please wait",
    "checkout.checkout.paymentProblem": "An error occurred when executing the payment",
    "checkout.checkout.backToPayment": "Back to the payment settings.",
    "user.ui.thankYouForSubscribing": "Thank you for subscribing to our newsletter.",
    "user.ui.errorAtSubscribing": "Error when subscribing to the newsletter",
    "user.ui.unsubscribe": "log out",
    "user.ui.unsubscribePage": "Are you sure you want to unsubscribe from our newsletter?",

    "comparison.fields.measurements": "Measurements",
    "comparison.fields.weight": "Weight",

    // tags

    "application.tag.neu": "New",
    "application.tag.sale": "Sale",
    "application.tag.eco": "Eco",
    "application.tag.sonderrabatt": "Special discount",

    // missing translations from de
    "application.newsletter.subscribeThanksOptIn": "Thank you for your newsletter subscription. You will receive a confirmation email to activate the opt-in.",
    "application.ui.from": "from",
    "application.ui.offer.expiresAt": "Offer runs until",
    "application.ui.status": "Status",
    "application.ui.title": "Title",
    "application.ui.createdAt": "Created on",
    "application.ui.viewAsList": "List view",
    "application.ui.viewAsTable": "Table view",
    "application.widgets.noNewestEntries": "No new entries found...",
    "application.widgets.showCategories": "Show category filter",
    "application.widgets.showFilter": "Show more filters",
    "checkout.checkout.alreadyAccount": "Already a customer? Click here to register.",
    "checkout.checkout.delivery.title": "Addresses",
    "checkout.checkout.delivery.addressNotVerified": "Your selected address is not complete or has not yet been verified. We cannot deliver to you. To complete your address, please click on the pencil symbol for your address. If you have any questions about the ordering process, our customer support team will be happy to help and advise you.",
    "checkout.checkout.payment.paypalButton": "with other payment methods, even without a PayPal account",
    "checkout.checkout.payment.paypalInfoExtended": "You will be redirected to PayPal to complete the payment process. Your account or PayPal account will not be debited until you confirm the order in the next step.",
    "checkout.checkout.orderPre": "Click on \"Buy now\" to complete your order",
    "entries.entry.ui.initiative": "Unsolicited application",
    "entries.entry.ui.jobApply": "Apply now",
    "entries.entry.ui.noJobs": "No vacancies.",
    "entries.event.ui.current": "Currently taking place",
    "entries.event.ui.history": "Has already taken place",
    "entries.job.ui.relatedEntries": "Entries for which the job should appear",
    "entries.job.ui.addJob": "Create job",
    "entries.job.ui.editJob": "Edit job",
    "entries.job.ui.editJobNew": "Create job",
    "entries.job.ui.tabDescription": "Description",
    "entries.job.ui.tabDescriptionMobile": "Descr.",
    "entries.job.ui.tabImages": "Pictures/Videos/Documents",
    "entries.job.ui.tabImagesMobile": "Pictures/videos/files",
    "entries.job.ui.tabCategories": "Categories",
    "entries.job.ui.tabCategoriesMobile": "Cat.",
    "entries.job.ui.title": "The job appears under the following name",
    "entries.job.ui.title.placeholder": "Enter title",
    "entries.job.ui.subtitle": "Descriptive subtitle",
    "entries.job.ui.subtitle.placeholder": "e.g. enter region as descriptive subtitle",
    "entries.job.ui.longDescription": "Long description text",
    "entries.job.ui.imagesInfo": "Images can be edited under \"Images/Videos/Documents\".",
    "entries.job.ui.categories": "Categories for this job",
    "entries.job.ui.regions": "Regions in which the job is offered",
    "entries.job.ui.baseSalary": "Wages, salary",
    "entries.job.ui.locationType": "Location dependency",
    "entries.job.ui.isTelecommute": "Home office possible",
    "entries.job.ui.substatus": "Job status",
    "entries.job.ui.substatus.occupied": "Occupied position",
    "entries.job.ui.substatus.open": "Vacancy",
    "entries.job.ui.substatus.inAssessment": "In ongoing assessment",
    "entries.job.ui.role.gender": "Information on gender",
    "entries.job.ui.role.male": "M",
    "entries.job.ui.role.female": "W",
    "entries.job.ui.role.divers": "D",
    "entries.job.ui.startPublishDate": "Publish job from when",
    "entries.job.ui.endDate": "End of the application period",
    "entries.job.ui.startDate": "Job available from when",
    "entries.job.ui.startDateNow": "As of now",
    "entries.job.ui.shortDescriptionInfo": "Is also the short description",
    "entries.product.ui.netPrizeNet": "Net price plus {{tax}}% VAT, plus",
    "entries.product.ui.netPrizeInfo": "plus VAT (net prices)",
    "entries.product.ui.netPrizeDefaultNonPhysical": "incl. VAT at the statutory rate.",
    "entries.entry.ui.products.delivery.red": "Product currently not available",
    "entries.entry.ui.products.delivery.yellow": "Product in stock. Possibly longer delivery time.",
    "entries.entry.ui.products.delivery.green": "Product in sufficient quantities in stock",
    "entries.products.ui.combinationNotAvailable": "Product not available in this combination",
    "entries.product.per": "Each",
    "entries.product.perServing": "Per portion",
    "entries.product.reference": "Reference quantity",
    "entries.product.nutritionTable": "Nutritional information",
    "entries.product.nutrientsTable": "Nutrients, minerals and vitamins",
    "entries.product.bio": "Ingredients from controlled organic cultivation",
    "forms.ui.privacy": "Privacy policy",
    "jobs.ui.employmentType": "Type of job",
    "search.resultType.article": "Article",
    "search.result": "Result",
    "search.results": "Results",
    "search.searchBarPlaceholderEntriesPositionZip": "...or zip code",
    "search.globalSearch": "Search page",
    "user.ui.accountMenu": "Home page and information",
    "user.ui.phoneType": "Telephone",
    "user.ui.phoneTypeMobile": "Mobile",
    "user.ui.phoneTypeLandline": "Landline",
    "widgets.shoppingCart.wishlist": "Memo and wish list",
    "widgets.shoppingCart.wishlist.empty": "You don't have any products on your wish list yet",
    "widgets.shoppingCart.cart": "Shopping cart",
    "job.application.selectLocation": "Select your location",
    "job.application.location": "Available in the following regions"
  };
});
define('webapp/services/user-proxy', ['exports', 'ember-ajax/errors', 'webapp/utils/clone-deep', 'webapp/utils/misc'], function (exports, _errors, _cloneDeep, _misc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var signedInFields = ['_id', 'email', 'token', 'isTemporary'];
  var nameFields = ['firstName', 'lastName'];

  // TODO move user fields to seperate user object of userProxy
  var USER_FIELDS = ['_id', 'userName', 'academicTitle', 'firstName', 'lastName', 'gender', 'email', 'description', 'token', 'image', 'banner', 'userGroups', 'adminGroup', 'accessPermissions', 'address', 'contact', 'geoPoint', 'additionalData', 'shownFields', 'createdAt', 'options', 'lastLogin', 'language', 'impersonatedBy', 'isTemporary'];

  var WRITEABLE_USER_FIELDS = ['userName', 'academicTitle', 'firstName', 'lastName', 'gender', 'description', 'image', 'banner', 'address', 'contact', 'geoPoint', 'options', 'additionalData', 'shownFields', 'language'];

  var LS_USER_KEY = 'userfe';

  function treeifyMap(vals) {
    vals = vals.sort().reverse();
    vals = vals.map(function (x) {
      return x.split('.');
    });

    var tree = {};

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = vals[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var split = _step.value;

        var node = tree;
        for (var i = 0; i < split.length; i++) {
          var part = split[i];
          if (!(part in node)) {
            if (i === split.length - 1) {
              node[part] = true;
            } else {
              node[part] = {};
            }
          }
          node = node[part];
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return tree;
  }

  function parseGeoPoint(data) {
    if (data.lat && data.lon) {
      return {
        lat: data.lat,
        lon: data.lon
      };
    }
  }

  exports.default = Ember.Service.extend(Ember.Evented, {
    myStorage: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    i18n: Ember.inject.service(),
    message: Ember.inject.service(),
    fastboot: Ember.inject.service(),

    _id: null,
    firstName: null,
    lastName: null,
    email: null,
    street: null,
    city: null,
    zip: null,
    token: null,
    image: null,
    options: null,
    geoPoint: null,

    location: Ember.computed('geoPoint.lat', 'geoPoint.lon', function () {
      var geoPoint = this.get('geoPoint');
      return geoPoint && parseGeoPoint(geoPoint);
    }),

    getCustomerCountryCode: function getCustomerCountryCode() {
      var customer = this.get('customer');
      var customerCountryCode = customer && customer.address && customer.address.countryCode;

      return customerCountryCode;
    },
    _isVatFreeBusiness: function _isVatFreeBusiness() {
      var customer = this.get('customer');

      if (customer) {
        var isBusiness = customer ? customer.isBusiness : false;
        // find out if the customers country has vat for businesses
        // first: find the country
        var customerCountryCode = customer && customer.address && customer.address.countryCode;
        var businessInCountryIsVatFree = false;
        if (customerCountryCode) {
          var customerCountry = void 0;
          var countries = this.get('countries');
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = countries[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var country = _step2.value;

              if (country.countryCode === customerCountryCode) {
                customerCountry = country;
              }
            }
            // then: find out if the country has vat for businesses
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          if (customerCountry && customerCountry.vatFreeForBusiness) {
            businessInCountryIsVatFree = true;
          }
        }

        return isBusiness && businessInCountryIsVatFree;
      } else {
        var userGroups = this.get('userGroups');
        var commercialUserGroup = undefined;

        if (userGroups) {
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = userGroups[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var userGroup = _step3.value;

              if (userGroup.isCommercial) {
                commercialUserGroup = userGroup;
              }
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }
        }

        if (commercialUserGroup) {
          return !!commercialUserGroup.showNetPrice;
        }

        return false;
      }
    },


    isVatFreeBusiness: Ember.computed('customer', 'userGroups', function () {
      return this._isVatFreeBusiness();
    }),

    isEligibleForPromocodes: Ember.computed('impersonatedBy', function () {
      var impersonatedBy = this.get('impersonatedBy');
      var isEligible = !impersonatedBy;

      return isEligible;
    }),

    __setUser: function __setUser(user) {
      var data = Ember.getProperties(user, USER_FIELDS);
      data.address = data.address || {};
      data.contact = data.contact || {};
      data.additionalData = data.additionalData || {};
      data.options = data.options || {};

      this.setProperties(data);
    },
    __clearUser: function __clearUser() {
      var props = { customer: null };
      USER_FIELDS.forEach(function (field) {
        props[field] = null;
      });
      this.setProperties(props);
    },
    _isLoggedIn: function _isLoggedIn() {
      var user = this.getProperties(signedInFields);

      return user && !user.isTemporary && user._id && user.email && user.token;
    },


    isLoggedIn: Ember.computed.apply(undefined, signedInFields.concat([function () {
      return this._isLoggedIn();
    }])),

    // only used for comments
    shownName: Ember.computed.apply(undefined, nameFields.concat([function () {
      var userName = this.get('userName');
      if (userName) {
        return userName;
      } else {
        var user = this.getProperties(nameFields);
        var name = user.firstName + ' ' + user.lastName;

        return name.trim();
      }
    }])),

    _login: function _login(user) {
      this.__clearUser();
      this.__setUser(user);
      var isFastboot = this.get('fastboot.isFastBoot');
      if (user._id && user.token) {
        if (!isFastboot) {
          localStorage.setItem(LS_USER_KEY, JSON.stringify({ _id: user._id, token: user.token }));
        }
        this.afterLogin();
      }
    },
    afterLogin: function afterLogin() {
      this.fetchCountries();
      this.fetchCustomer();
      this.trigger('login');
    },
    _logout: function _logout() {
      var isFastboot = this.get('fastboot.isFastBoot');
      if (!isFastboot) {
        localStorage.removeItem(LS_USER_KEY);
      }
      this.__clearUser();
      this.trigger('logout');
    },
    getUser: function getUser() {
      var user = this.getProperties(USER_FIELDS);
      user.additionalData = Ember.assign({}, user.additionalData || {});
      user.address = Ember.assign({}, user.address || {});
      user.options = Ember.assign({}, user.options || {});
      user.contact = Ember.assign({}, user.contact || {});
      return user;
    },
    signIn: function signIn() {
      var _this = this;

      var isFastboot = this.get('fastboot.isFastBoot');
      var lsUser = isFastboot ? null : localStorage.getItem(LS_USER_KEY);

      if (lsUser) {
        var user = JSON.parse(lsUser);
        return this.get('myStorage.user').ajaxGet({
          param: 'self',
          token: user.token,
          query: {
            sessionId: this.get('cmsService.sessionId')
          }
        }).then(function (retUser) {
          retUser.token = user.token;
          _this._login(retUser);
          return true;
        }).catch(function () {
          // console.log('user not found');
          if (!isFastboot) {
            localStorage.removeItem(LS_USER_KEY);
          }
          return false;
        });
      } else {
        return Ember.RSVP.resolve(false);
      }
    },
    updateUser: function updateUser(data) {
      var _this2 = this;

      var myStorage = this.get('myStorage');
      var message = this.get('message');
      var i18n = this.get('i18n');

      return myStorage.user.ajaxPut({ param: 'self', data: data }).then(function () {
        message.info(i18n.t('user.message.after-save-success').toString());
        _this2.setProperties(data);
      }).catch(function (err) {
        window.console.error(err.stack);
        if ((0, _errors.isConflictError)(err)) {
          message.error(i18n.t('user.message.after-save-error').toString());
        } else {
          message.error(i18n.t('user.message.after-save-error').toString());
        }
      });
    },


    // pick the saveable fields from data and update user
    saveUser: function saveUser(data) {
      var updateData = Ember.getProperties(data, WRITEABLE_USER_FIELDS);
      return this.updateUser(updateData);
    },
    getWritableData: function getWritableData() {
      return Ember.getProperties(this, WRITEABLE_USER_FIELDS);
    },


    userGroupSlugs: Ember.computed('userGroups', function () {
      var userGroups = this.get('userGroups');
      return userGroups && userGroups.map(function (x) {
        return x.slug;
      });
    }),

    userGroupsString: Ember.computed('userGroups', function () {
      var userGroups = this.get('userGroups');
      if (!userGroups) {
        return '';
      }
      var userGroupsNames = userGroups.map(function (x) {
        return x.name;
      });
      if (userGroupsNames.length <= 1) {
        return userGroupsNames[0];
      } else {
        return userGroupsNames.slice(0, -1).join(', ') + ' und ' + userGroupsNames[userGroupsNames.length - 1];
      }
    }),

    // only biostadt
    relevantUserGroups: Ember.computed('userGroups', function () {
      var userGroups = this.get('userGroups');
      if (!userGroups) {
        return;
      }
      var slugs = ['aussteller', 'gastgeber', 'veranstalter'];

      return slugs.map(function (slug) {
        return userGroups.find(function (group) {
          return group.slug === slug;
        });
      });
    }),

    assignedEntryId: Ember.computed('accessPermissions', function () {
      var permissions = this.get('accessPermissions');
      if (permissions) {
        var entryPerm = permissions.find(function (perm) {
          return perm.targetModel === 'Entry' && perm.target;
        });
        return entryPerm && entryPerm.target;
      }
    }),

    assignedEntryIds: Ember.computed('accessPermissions', function () {
      var permissions = this.get('accessPermissions');
      if (permissions) {
        return permissions.filter(function (perm) {
          return perm.targetModel === 'Entry' && perm.target;
        }).map(function (perm) {
          return perm.target;
        });
      } else {
        return [];
      }
    }),

    fetchEntry: function fetchEntry() {
      var myStorage = this.get('myStorage');
      var entryId = this.get('assignedEntryId');

      if (entryId) {
        return myStorage.entryAdmin.ajaxGet({ param: entryId });
      } else {
        // message.error('Dem Benutzer ist kein Eintrag zugeordnet!');
        // console.log('no entry assigned');
        return Ember.RSVP.reject(new Error('no entry assigned to user'));
      }
    },
    fetchOwnEntries: function fetchOwnEntries() {
      var myStorage = this.get('myStorage');

      return myStorage.entry.ajaxGet({
        param: 'ownentries'
      }).then(function (res) {
        return res.hits;
      });
    },
    fetchEvents: function fetchEvents(entry) {
      var _this3 = this;

      var myStorage = this.get('myStorage');

      var eventIds = entry.events.map(function (x) {
        return x.item;
      });
      return myStorage.eventAdmin.ajaxPost({
        param: 'search',
        data: { filter: { _id: { $in: eventIds } } }
      }).then(function (res) {
        var events = res.model.filter(function (x) {
          return x.geoPoint && x.geoPoint.lat;
        }).map(function (x) {
          return { title: x.title, geoPoint: x.geoPoint };
        });

        _this3.set('entryEvents', events);
      });
    },
    fetchUserGroups: function fetchUserGroups() {
      var _this4 = this;

      var myStorage = this.get('myStorage');

      return myStorage.userGroup.ajaxGet().then(function (groups) {
        _this4.set('availableUserGroups', groups);
      });
    },


    accountRoutes: Ember.computed('userGroups', function () {
      var userGroups = this.get('userGroups') || [];

      var selectedAccountRoutes = [];
      userGroups.forEach(function (group) {
        if (group.availableAccountRoutes) {
          group.availableAccountRoutes.forEach(function (route) {
            selectedAccountRoutes.push(route);
          });
        }
      });

      var accountRoutes = treeifyMap(selectedAccountRoutes);

      return accountRoutes;
    }),

    fetchCustomer: function fetchCustomer() {
      var _this5 = this;

      var myStorage = this.get('myStorage');
      return myStorage.customer.ajaxGet().then(function (customer) {
        _this5.set('customer', customer);
      });
    },
    fetchCountries: function fetchCountries() {
      var _this6 = this;

      var myStorage = this.get('myStorage');
      return myStorage.country.ajaxGet().then(function (countries) {
        _this6.set('countries', countries);
      });
    },
    updateCustomer: function updateCustomer(data) {
      var _this7 = this;

      var myStorage = this.get('myStorage');
      data = (0, _misc.omit)((0, _cloneDeep.default)(data), ['users']);

      return myStorage.customer.ajaxPut({ data: data }).then(function () {
        _this7.fetchCustomer();
      });
    }
  });
});
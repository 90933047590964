define("webapp/helpers/string-includes", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var text = params[0] || "";
    var textToFind = params[1] || "";

    return text.includes(textToFind);
  });
});
define('webapp/components/account/opening-edit/opening-category', ['exports', 'webapp/models/opening-config', 'webapp/utils/find-by'], function (exports, _openingConfig, _findBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    config: _openingConfig.default,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var key = this.get('key');
      var entries = this.get('data.' + key) || [];
      entries = entries.map(function (entry) {
        return _this._createEntry(entry);
      }).filter(function (entry) {
        return !!entry;
      });
      this.set('entries', entries);
      this.set('data.' + key, entries);
    },

    _createEntry: function _createEntry(entry) {
      var entryConfig = (0, _findBy.default)(_openingConfig.default, 'option', entry.option);

      if (!entryConfig) {
        return;
      }
      var Model = entryConfig.Model;
      var entryRaw = Ember.Object.create(entry);
      var newEntry = Model.create(Ember.getOwner(this).ownerInjection(), entryRaw);
      entryConfig.options.forEach(function (eOption) {
        newEntry[eOption.id] = newEntry[eOption.id] || eOption.values[0].id;
      });
      return newEntry;
    },


    actions: {
      addEntry: function addEntry() {
        this.get('entries').pushObject(this._createEntry({ option: 'default' }));
      },
      removeEntry: function removeEntry(entry) {
        this.get('entries').removeObject(entry);
      },
      entryOptionChanged: function entryOptionChanged(entry) {
        var entries = this.get('entries');
        var newEntry = this._createEntry(entry);
        var idx = entries.indexOf(entry);
        entries.replace(idx, 1, newEntry);
        this.set('entries', entries);
      }
    }
  });
});
define('webapp/helpers/shorten-text', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var text = params[0] || "";
    var length = params[1] || 100;
    var trimmedText = text.slice(0, length);
    var wordLength = Math.max(0, Math.min(trimmedText.length, trimmedText.lastIndexOf(" ")));
    if (text.length > length) {
      // string has no words eg spaces
      if (wordLength.length > 0) {
        var textSeg = text && text.slice(0, wordLength) + " ...";
      } else {
        // trim again, if we are in the middle of a word
        var trimmedText = text.slice(0, Math.min(trimmedText.length, trimmedText.lastIndexOf(" ")));
        var textSeg = trimmedText + " ...";
      }
    } else {
      var textSeg = text;
    }

    return Ember.String.htmlSafe(textSeg);
  });
});
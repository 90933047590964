define('webapp/helpers/filter-array-props', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.filterArrayProps = filterArrayProps;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function filterArrayProps(_ref) {
    var _ref2 = _toArray(_ref),
        array = _ref2[0],
        filters = _ref2.slice(1);

    if (!Array.isArray(array)) {
      return;
    }
    var filterPairs = [];
    if (filters.length % 2 !== 0) {
      throw new Error('filter-array-props needs equal amount of filter args');
    }
    for (var i = 0; i < filters.length; i += 2) {
      filterPairs.push(filters.slice(i, i + 2));
    }

    return array.filter(function (elem) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = filterPairs[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref3 = _step.value;

          var _ref4 = _slicedToArray(_ref3, 2);

          var key = _ref4[0];
          var value = _ref4[1];

          if (key[0] === '!') {
            if (Ember.get(elem, key.slice(1)) === value) {
              return false;
            }
          } else {
            if (Ember.get(elem, key) !== value) {
              return false;
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return true;
    });
  }

  exports.default = Ember.Helper.helper(filterArrayProps);
});
define('webapp/components/job-application', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var mandatoryFields = ['email',
  // 'title',
  'firstName', 'lastName',
  // 'earliestEntry',
  // 'birthday',
  // 'street',
  // 'houseNumber',
  // 'zip',
  // 'city',
  // 'country',
  // 'countryCode',
  // 'phone',
  // 'message',
  // 'coverNote',
  'curriculum'
  // 'certificates'
  ];

  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    message: Ember.inject.service(),
    submitted: false,
    selectedCategory: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var cachedData = this.get('cachedData');
      var entries = this.get('entries');
      var entryCatIds = [];
      if (entries) {
        entries.forEach(function (entry) {
          return entry.categories.forEach(function (cat) {
            return entryCatIds.push(cat);
          });
        });
      }

      cachedData.getCategories().then(function (categories) {
        var shownCategories = [];
        var regionCat = (0, _treeUtils.findInTree)(categories, function (x) {
          return x.slug === 'region';
        });
        (0, _treeUtils.walkTree)(regionCat.children, function (rCat) {
          if (!rCat.noDisplay && rCat.level > 1 && entryCatIds.includes(rCat._id)) {
            shownCategories.push(rCat);
          }
        });

        _this.set('shownCategories', shownCategories);
      });
    },


    data: Ember.computed('job', function () {
      return {
        job: this.get('job._id'),
        entries: [],
        coverNote: [],
        curriculum: [],
        images: [],
        certificates: [],
        otherFiles: []
      };
    }),

    entries: Ember.computed('entryRelations', function () {
      var entryRelations = this.get('entryRelations');
      return entryRelations && entryRelations.map(function (x) {
        return x.item;
      });
    }),

    filteredEntries: Ember.computed('entries', 'selectedCategory', function () {
      var entries = this.get('entries');
      var selectedCategory = this.get('selectedCategory');

      if (selectedCategory) {
        var selectedCategoryId = selectedCategory._id;
        return entries.filter(function (entry) {
          return entry.categories.some(function (eCatId) {
            return eCatId === selectedCategoryId;
          });
        });
      } else {
        return entries;
      }
    }),

    canSubmit: Ember.computed.apply(undefined, _toConsumableArray(mandatoryFields.map(function (x) {
      return 'data.' + x;
    })).concat([function () {
      var data = this.get('data');

      return true;

      return mandatoryFields.every(function (field) {
        return !!data[field];
      });
    }])),

    actions: {
      selectCategory: function selectCategory(category) {
        if (category && this.get('selectedCategory') !== category) {
          this.set('data.entries', []);
        }
        this.set('selectedCategory', category);
      },
      submit: function submit() {
        var _this2 = this;

        var myStorage = this.get('myStorage');
        var message = this.get('message');
        var data = this.get('data');

        myStorage.jobApplication.ajaxPost({ data: data }).then(function () {
          message.info('Bewerbung verschickt');
          _this2.set('submitted', true);
          window.scroll(0, 300);
        }).catch(function (err) {
          message.error(err.payload && err.payload.message);
        });
      },
      removeFile: function removeFile(file, fieldKey) {
        var dataFieldKey = 'data.' + fieldKey;
        var field = this.get(dataFieldKey);

        if (Array.isArray(field)) {
          this.set(dataFieldKey, field.filter(function (x) {
            return x.path !== file.path;
          }));
        } else {
          this.set(dataFieldKey, null);
        }
      }
    }
  });
});
define('webapp/components/home-page-link-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HomePageLinkTo = Ember.LinkComponent.extend({
    languageService: Ember.inject.service(),

    params: Ember.computed('languageService.languageSlug', function () {
      var langSlug = this.get('languageService.languageSlug');

      if (langSlug) {
        return ['page', langSlug];
      } else {
        return ['index'];
      }
    })
  });

  exports.default = HomePageLinkTo;
});
define('webapp/utils/generated-path', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (medium) {
    if (!medium.generatedExtension) return medium.path;

    var path = medium.directory || '';
    if (path) path += '/';
    return path + medium.name + '.' + medium.generatedExtension;
  };
});
define('webapp/components/social-embed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    socialService: Ember.inject.service(),

    didRender: function didRender() {
      var content = this.get('content');
      var myRe = /<(style)[\s\S]*?<\/(style)>/;
      if (content) {
        var filteredContent = content.replace(myRe, '<!--'.concat(content, '-->'));
        if (filteredContent.indexOf('twitter')) {
          this.get('socialService').initTwitter();
        }
      }
    }
  });
});
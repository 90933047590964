define('webapp/components/proven-experts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('isChecked', !!this.get('entry.fields.provenExpertsCode'));
    },
    createWidget: function createWidget() {
      var email = this.get('entry.contact.email');

      var myStorage = this.get('myStorage');
      return myStorage.entry.ajaxPost({ param: 'proven_experts/create_widget', data: { email: email } }).then(function (res) {
        return res;
      });
    },


    actions: {
      toggleProvenExperts: function toggleProvenExperts() {
        var _this = this;

        var wasChecked = this.get('isChecked');
        var message = this.get('message');

        if (wasChecked) {
          this.set('entry.fields.provenExpertsCode', '');
          this.set('isChecked', false);
        } else {
          this.set('isChecked', true);
          this.createWidget().then(function (res) {
            if (res.status === 'success') {
              _this.set('entry.fields.provenExpertsCode', res.html);
              _this.set('isChecked', true);
            } else {
              _this.set('entry.fields.provenExpertsCode', '');
              _this.set('isChecked', false);
              message.error('Error at connecting Proven Experts. Probably no subaccount established');
            }
          }).catch(function (err) {
            message.error(err.payload.message);
            _this.set('entry.fields.provenExpertsCode', '');
            _this.set('isChecked', false);
          });
        }
      }
    }
  });
});
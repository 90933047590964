define('webapp/helpers/word-compare', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var text1 = params[0] || '';
    var text2 = params[1] || '';

    if (!text1 || !text2) {
      return false;
    }

    var split1 = text1.split(/[ -]/);
    var split2 = text2.split(/[ -]/);

    return split1.some(function (word) {
      return split2.includes(word);
    });
  });
});
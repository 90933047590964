define('webapp/services/shopping-cart-service', ['exports', 'webapp/utils/misc', 'webapp/utils/clone-deep', 'webapp/utils/validation-utils'], function (exports, _misc, _cloneDeep, _validationUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var PREFERRED_PAYMENT_METHOD = 'invoice';
  var DEFAULT_PAYMENT_METHOD = 'sepa_debit';

  function buildAddressFieldsArray(base) {
    return _validationUtils.addressFields.map(function (field) {
      return base + '.' + field;
    });
  }

  function formatPrice(value) {
    return Math.round(value * 100) / 100 / 100;
  }

  function serializeCart(cart) {
    cart = (0, _cloneDeep.default)(cart); // gets rid of validation circular structure
    var serializedCart = _extends({}, cart, {
      items: cart.items.map(function (item) {
        return _extends({}, item, { ref: item.ref._id });
      }),
      deliveryAddress: cart.deliveryAddress,
      invoiceAddress: cart.invoiceAddress,
      paymentMethod: cart.paymentMethod,
      // new fields for offers
      additionalDetails: cart.additionalDetails,
      comment: cart.comment,
      canExpire: cart.canExpire,
      expiresAt: cart.expiresAt,
      turnIntoOffer: cart.turnIntoOffer
    });

    return serializedCart;
  }

  function addressIsValid(address) {
    return address !== undefined && address.gender !== undefined && address.gender !== '' && address.firstName !== undefined && address.firstName !== '' && address.lastName !== undefined && address.lastName !== '' && address.street !== undefined && address.street !== '' && address.houseNumber !== undefined && address.houseNumber !== '' && address.zip !== undefined && address.zip !== '' && address.city !== undefined && address.city !== '' && address.countryCode !== undefined && address.countryCode !== '';
  }

  exports.default = Ember.Service.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    message: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    languageService: Ember.inject.service(),
    shoppingCart: null,
    _cachedPaymentInfo: null,

    fetchPaymentMethodInfo: function fetchPaymentMethodInfo() {
      var myStorage = this.get('myStorage');
      var param = 'payment_method_info';

      if (!this._cachedPaymentInfo) {
        this._cachedPaymentInfo = myStorage.payment.ajaxGet({ param: param });
      }

      return this._cachedPaymentInfo;
    },
    init: function init() {
      this._super.apply(this, arguments);

      var userProxy = this.get('userProxy');
      userProxy.on('login', this, this.onLogin);
      userProxy.on('logout', this, this.onLogout);

      var languageService = this.get('languageService');
      languageService.on('languageChange', this, this.refreshShoppingCart);
    },
    reset: function reset() {
      this.set('shoppingCart', null);
    },
    setAddress: function setAddress(type, address) {
      if (type === 'invoice') {
        this.set('shoppingCart.invoiceAddress', address);
      } else if (type === 'delivery') {
        this.set('shoppingCart.deliveryAddress', address);
      }
    },
    _getAddressesFrom: function _getAddressesFrom(customer) {
      var invoiceAddress = (0, _cloneDeep.default)(customer.address);
      var deliveryAddress = undefined;
      var addresses = customer.addresses;
      var invoiceAddressIsDefault = true;

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = addresses[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var address = _step.value;

          if (address.isDefault) {
            invoiceAddressIsDefault = false;
            deliveryAddress = address;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      if (invoiceAddressIsDefault) {
        deliveryAddress = invoiceAddress;
      }

      var hasSeparateDeliveryAddress = !invoiceAddressIsDefault;

      return { hasSeparateDeliveryAddress: hasSeparateDeliveryAddress, deliveryAddress: deliveryAddress, invoiceAddress: invoiceAddress };
    },
    setAddresses: function setAddresses(customer) {
      var _getAddressesFrom2 = this._getAddressesFrom(customer),
          hasSeparateDeliveryAddress = _getAddressesFrom2.hasSeparateDeliveryAddress,
          deliveryAddress = _getAddressesFrom2.deliveryAddress,
          invoiceAddress = _getAddressesFrom2.invoiceAddress;

      this.set('shoppingCart.hasSeparateDeliveryAddress', hasSeparateDeliveryAddress);
      this.set('shoppingCart.invoiceAddress', invoiceAddress);
      this.set('shoppingCart.deliveryAddress', deliveryAddress);
    },
    getAddresses: function getAddresses() {
      var userProxy = this.get('userProxy');
      var customer = userProxy.customer;

      if (customer) {
        return this._getAddressesFrom(customer);
      } else {
        var hasSeparateDeliveryAddress = false;
        var invoiceAddress = { gender: 'diverse' };
        var deliveryAddress = { gender: 'diverse' };
        return { hasSeparateDeliveryAddress: hasSeparateDeliveryAddress, deliveryAddress: deliveryAddress, invoiceAddress: invoiceAddress };
      }
    },


    totalQuantity: Ember.computed('shoppingCart', function () {
      var totalQuantity = 0;
      var items = this.get('shoppingCart.items') || [];
      items.forEach(function (item) {
        return totalQuantity += item.quantity;
      });
      return totalQuantity;
    }),

    hasAdditionalDetails: Ember.computed('shoppingCart', function () {
      // INFO: was based on isLocked
      var additionalDetails = this.get('shoppingCart.additionalDetails') || [];

      return additionalDetails.length > 0;
    }),

    deliveryAddressValid: Ember.computed.apply(undefined, _toConsumableArray(buildAddressFieldsArray('shoppingCart.deliveryAddress')).concat(_toConsumableArray(buildAddressFieldsArray('shoppingCart.invoiceAddress')), ['shoppingCart.hasSeparateDeliveryAddress', function () {
      var hasSeparateDeliveryAddress = this.get('shoppingCart.hasSeparateDeliveryAddress');
      var deliveryAddress = this.get('shoppingCart.deliveryAddress');
      var deliveryAddressValid = addressIsValid(deliveryAddress);
      var invoiceAddress = this.get('shoppingCart.invoiceAddress');
      var invoiceAddressValid = addressIsValid(invoiceAddress);

      if (hasSeparateDeliveryAddress) {
        var bothValid = invoiceAddressValid && deliveryAddressValid;
        return bothValid;
      } else {
        return invoiceAddressValid;
      }
    }])),

    // TODO: THIS IS SO GOING TO HAVE TO GO
    isCountryValidForDelivery: function isCountryValidForDelivery(countryCode) {
      return countryCode === 'DE';
    },


    addressValidForExpressDelivery: Ember.computed('shoppingCart.deliveryAddress.countryCode', 'shoppingCart.invoiceAddress.countryCode', 'shoppingCart.hasSeparateDeliveryAddress', function () {
      var hasSeparateDeliveryAddress = this.get('shoppingCart.hasSeparateDeliveryAddress');
      var countryCode = void 0;
      if (hasSeparateDeliveryAddress) {
        countryCode = this.get('shoppingCart.deliveryAddress.countryCode');
      } else {
        countryCode = this.get('shoppingCart.invoiceAddress.countryCode');
      }

      return this.isCountryValidForDelivery(countryCode);
    }),

    deliveryValid: Ember.computed('deliveryAddressValid', 'deliveryAddress', 'shoppingCart.deliveryDetails.isExpressDelivery', 'addressValidForExpressDelivery', function () {
      var deliveryAddressValid = this.get('deliveryAddressValid');

      if (!deliveryAddressValid) {
        return false;
      }

      var isExpressDelivery = this.get('shoppingCart.deliveryDetails.isExpressDelivery');
      var addressValidForExpressDelivery = this.get('addressValidForExpressDelivery');
      var valid = !isExpressDelivery || addressValidForExpressDelivery;

      return valid;
    }),

    paymentComplete: Ember.computed('shoppingCart.paymentStatus', function () {
      return this.get('shoppingCart.paymentStatus') === 'approved';
    }),

    getHasShoppingWidgetOnOffcanvas: function getHasShoppingWidgetOnOffcanvas() {
      var activePage = this.get('cmsService.activePage');
      return activePage.widgets.some(function (widget) {
        return widget.widget.type === 'shoppingCartContent' && widget.widget.position === 'addOffcanvas';
      });
    },
    setShoppingCart: function setShoppingCart(shoppingCart) {
      if (!shoppingCart.additionalInfo) {
        shoppingCart.additionalInfo = {};
      }
      this.set('shoppingCart', shoppingCart);
    },
    getDefaultPaymentMethod: function getDefaultPaymentMethod(defaultPaymentMethod) {
      var userProxy = this.get('userProxy');
      var customer = userProxy.customer;
      var cart = this.get('shoppingCart');

      // INFO: this is a hack to fix a timing bug
      if (cart && cart.paymentMethod) {
        return cart.paymentMethod;
      }

      if (defaultPaymentMethod) {
        if (defaultPaymentMethod === 'payPal') {
          return 'paypal';
        } else if (defaultPaymentMethod === 'stripe') {
          return 'stripe';
        }
      }

      if (customer && customer.addressValidated) {
        return PREFERRED_PAYMENT_METHOD;
      }

      return DEFAULT_PAYMENT_METHOD;
    },
    createNewCart: function createNewCart() {
      var _this = this;

      return this.fetchPaymentMethodInfo().then(function (_ref) {
        var defaultPaymentMethod = _ref.defaultPaymentMethod;

        var paymentMethod = _this.getDefaultPaymentMethod(defaultPaymentMethod);

        var _getAddresses = _this.getAddresses(),
            hasSeparateDeliveryAddress = _getAddresses.hasSeparateDeliveryAddress,
            deliveryAddress = _getAddresses.deliveryAddress,
            invoiceAddress = _getAddresses.invoiceAddress;

        var cart = {
          sessionId: _this.get('cmsService.sessionId'),
          site: _this.get('cachedData.cachedSite._id'),
          items: [],
          paymentMethod: paymentMethod,
          invoiceAddress: invoiceAddress,
          deliveryAddress: deliveryAddress,
          hasSeparateDeliveryAddress: hasSeparateDeliveryAddress,
          additionalInfo: {}
        };

        return cart;
      });
    },
    getQueryParams: function getQueryParams() {
      return {
        sessionId: this.get('cmsService.sessionId'),
        site: this.get('cachedData.cachedSite._id'),
        lang: this.get('languageService.selectedLanguage')
      };
    },


    // fetch last shopping cart
    fetchShoppingCart: function fetchShoppingCart() {
      var _this2 = this;

      var myStorage = this.get('myStorage');
      myStorage.shoppingCart.ajaxGet({ query: this.getQueryParams() }).then(function (carts) {
        var cart = carts[0];

        if (cart) {
          _this2.setShoppingCart(cart);
          _this2._currentVersion = cart.version;
        } else {
          _this2.set('shoppingCart', null);
        }
      });
    },
    refreshShoppingCart: function refreshShoppingCart() {
      if (this.get('shoppingCart')) {
        return this.fetchShoppingCart();
      } else {
        return Ember.RSVP.resolve();
      }
    },
    onLogin: function onLogin() {
      if (!this.get('userProxy.isTemporary')) {
        this.transferCartToUser();
      }
    },
    onLogout: function onLogout() {
      this.set('shoppingCart', null);
    },
    transferCartToUser: function transferCartToUser() {
      var _this3 = this;

      // merge anonymous cart with shopping cart after login
      var cartId = this.get('shoppingCart._id');
      if (cartId) {
        var myStorage = this.get('myStorage');

        return myStorage.shoppingCart.ajaxPost({
          param: cartId + '/assign_user',
          data: { sessionId: this.get('cmsService.sessionId') }
        }).then(function (cart) {
          _this3.setShoppingCart(cart);
          _this3._currentVersion = cart.version + 1;
        }).catch(function (err) {
          _this3.get('message').error(err.payload.message);
        });
      } else {
        return this.fetchShoppingCart();
      }
    },
    getAdditional: function getAdditional() {
      var additional = this.get('shoppingCart.additional');
      if (additional && additional.hasData) {
        //redirect: true,
        //reason: 'countryPriceChange'
        return additional.data;
      } else {
        return {};
      }
    },


    // so an earlier cart promise cant overwrite the latest cart update
    setCartPromise: function setCartPromise(cartPromise) {
      var _this4 = this;

      return cartPromise.then(function (cart) {
        if (_this4._currentVersion === cart.version) {
          _this4.setShoppingCart(cart);
        }
        return cart;
      }).catch(function (err) {
        _this4.get('message').error(err.payload.message);
        throw err;
      });
    },
    updateShoppingCart: function updateShoppingCart(cart) {
      this.setShoppingCart(cart);
      this._currentVersion++;
      Ember.run.debounce(this, this._updateShoppingCart, _extends({}, cart, { version: this._currentVersion }), 500);
    },
    updateShoppingCartWODebounce: function updateShoppingCartWODebounce(cart) {
      this.setShoppingCart(cart);
      this._currentVersion++;
      return this._updateShoppingCart(_extends({}, cart, { version: this._currentVersion }));
    },
    _updateShoppingCart: function _updateShoppingCart(cartData) {
      var myStorage = this.get('myStorage');

      var param = cartData._id;
      var data = serializeCart(cartData);
      var query = this.getQueryParams();

      return this.setCartPromise(myStorage.shoppingCart.ajaxPut({
        param: param,
        data: data,
        query: query
      }));
    },
    maybeOpenShoppingOffcanvas: function maybeOpenShoppingOffcanvas() {
      // open shoppingoffcanvas if widget-shopping-content in addOffcanvas position
      var hasShoppingWidgetOnOffcanvas = this.getHasShoppingWidgetOnOffcanvas();

      if (hasShoppingWidgetOnOffcanvas) {
        var offcanvasElement = document.getElementById('additional-offcanvas');
        if (offcanvasElement) {
          UIkit.offcanvas(offcanvasElement).show();
        }
      }
    },
    sendAddToCartAnalytics: function sendAddToCartAnalytics(product, quantity) {
      if (!window.gtag) {
        return;
      }

      var data = {
        value: formatPrice(product.priceGross),
        currency: 'EUR',
        items: [{
          item_id: product.orderNumber || product._id,
          item_name: product.title + ' ' + product.subtitle,
          index: 0,
          item_category: product.primaryCategory && product.primaryCategory.title,
          price: formatPrice(product.priceGross),
          quantity: quantity
        }]
      };

      window.gtag('event', 'add_to_cart', data);
    },


    // THIS IS WHERE THINGS NEED TO CHANGE
    addToShoppingCart: function addToShoppingCart(product) {
      var _this5 = this;

      var quantity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

      var oldCart = this.get('shoppingCart');

      // catch this, just in case something goes wrong, so item is still added to cart
      try {
        this.sendAddToCartAnalytics(product, quantity);
      } catch (err) {
        console.error('error sending add_to_cart analytics', err);
      }

      var cartPromise = oldCart ? Ember.RSVP.resolve(oldCart) : this.createNewCart();
      return cartPromise.then(function (cart) {
        if (cart.isLocked) {
          _this5.get('message').errorT('shoppingCart.isLocked');
          return;
        }

        var items = cart.items;
        var existingItem = items.find(function (item) {
          return item.ref._id === product._id;
        });

        if (existingItem) {
          items = items.map(function (item) {
            if (item.ref._id === product._id) {
              return _extends({}, item, {
                quantity: item.quantity + quantity
              });
            } else {
              return item;
            }
          });
        } else {
          items = [].concat(_toConsumableArray(cart.items), [{
            ref: product,
            type: 'Product',
            quantity: quantity,
            priceGross: product.priceGross,
            priceNet: product.priceNet
          }]);
        }

        _this5.setShoppingCart(_extends({}, cart, { items: items }));

        var myStorage = _this5.get('myStorage');
        var p = void 0;
        if (oldCart) {
          _this5._currentVersion++;
          p = myStorage.shoppingCart.ajaxPut({
            param: oldCart._id,
            data: serializeCart(_extends({}, cart, { items: items, version: _this5._currentVersion })),
            query: _this5.getQueryParams()
          });
        } else {
          _this5._currentVersion = 0;
          p = myStorage.shoppingCart.ajaxPost({
            data: serializeCart(_extends({}, cart, { items: items })),
            query: { lang: _this5.get('languageService.selectedLanguage') }
          });
        }

        _this5.maybeOpenShoppingOffcanvas();

        return _this5.setCartPromise(p);
      });
    },
    removeFromShoppingCart: function removeFromShoppingCart(itemId) {
      var cart = this.get('shoppingCart');

      if (cart.isLocked) {
        this.get('message').errorT('shoppingCart.isLocked');
        return;
      }

      var items = cart.items.filter(function (item) {
        return item.ref && item.ref._id !== itemId;
      });

      this.updateShoppingCart(_extends({}, cart, { items: items }));
    },
    changeItemQuantity: function changeItemQuantity(itemId, quantity) {
      var cart = this.get('shoppingCart');

      if (cart.isLocked) {
        this.get('message').errorT('shoppingCart.isLocked');
        return;
      }

      var items = cart.items.map(function (item) {
        if (item.ref._id === itemId) {
          return _extends({}, item, {
            quantity: quantity
          });
        }
        return item;
      });

      this.updateShoppingCart(_extends({}, cart, { items: items }));
    },
    addItemQuantity: function addItemQuantity(itemId, value) {
      var cart = this.get('shoppingCart');

      if (cart.isLocked) {
        this.get('message').errorT('shoppingCart.isLocked');
        return;
      }

      var items = cart.items.map(function (item) {
        if (item.ref._id === itemId) {
          return _extends({}, item, {
            quantity: Math.max(0, item.quantity + value)
          });
        }
        return item;
      });

      this.updateShoppingCart(_extends({}, cart, { items: items }));
    },
    setCustomerDefaultDeliveryAddress: function setCustomerDefaultDeliveryAddress(address) {
      var myStorage = this.get('myStorage');
      var userProxy = this.get('userProxy');

      var customerAddresses = (this.get('userProxy.customer.addresses') || []).slice().map(function (x) {
        return _extends({}, x);
      }); // shallow clone

      if (address) {
        var addressFound = false;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = customerAddresses[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var cAddress = _step2.value;

            if ((0, _misc.compareByFields)(cAddress, address, _validationUtils.addressFields)) {
              cAddress.isDefault = true;
              addressFound = true;
            } else {
              cAddress.isDefault = false;
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        if (!addressFound) {
          customerAddresses.push(_extends({
            type: 'delivery',
            isDefault: true
          }, address));
        }
      } else {
        // invoice address is default
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = customerAddresses[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var _cAddress = _step3.value;

            _cAddress.isDefault = false;
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }
      }

      return myStorage.customer.ajaxPut({
        data: {
          addresses: customerAddresses
        }
      }).then(function () {
        return userProxy.fetchCustomer();
      });
    },
    commit: function commit() {
      var _this6 = this;

      var cart = this.get('shoppingCart');
      var myStorage = this.get('myStorage');
      var userProxy = this.get('userProxy');

      return myStorage.shoppingCart.ajaxPost({
        param: cart._id + '/commit',
        query: this.getQueryParams(),
        data: serializeCart(cart)
      }).then(function (cart) {
        _this6.set('shoppingCart', null);
        _this6.set('lastCartId', cart._id); // used for order thanks page
        _this6.sendOrderAnalytics(cart._id);
        if (_this6.get('userProxy.isTemporary')) {
          userProxy._logout();
        } else {
          userProxy.fetchCustomer(); // need to refetch customer, because customer is created on first commit
        }
      });
    },


    // TODO handling of vouchers
    sendOrderAnalytics: function sendOrderAnalytics(shoppingCartId) {
      var myStorage = this.get('myStorage');

      if (!(window.gtag || window.fbq)) {
        return Ember.RSVP.resolve();
      }

      return myStorage.shopping.ajaxGet({ param: 'order_full/' + shoppingCartId }).then(function (order) {
        var items = order.details.items.map(function (item, idx) {
          // only use the last 5 categories (the most nested ones)
          var productCategories = [];

          var primaryCategory = item.product.primaryCategory;
          if (primaryCategory) {
            (function () {
              var currentCat = primaryCategory;

              while (currentCat) {
                productCategories.push(currentCat);
                currentCat = currentCat.parent && item.product.categories.find(function (x) {
                  return x.level > 1 && x._id === currentCat.parent;
                });
              }

              productCategories = productCategories.reverse();
              productCategories = productCategories.slice(-5);
            })();
          } else {
            productCategories = item.product.categories.filter(function (x) {
              return x.type === 'product';
            }).filter(function (x) {
              return x.level > 1;
            }).sort(function (a, b) {
              return a.level - b.level;
            }).slice(-5);
          }

          return {
            item_id: item.product.orderNumber || item.product._id,
            item_name: item.product.title + ' ' + item.product.subtitle,
            index: idx,
            item_category: productCategories[0] && productCategories[0].title,
            item_category2: productCategories[1] && productCategories[1].title,
            item_category3: productCategories[2] && productCategories[2].title,
            item_category4: productCategories[3] && productCategories[3].title,
            item_category5: productCategories[4] && productCategories[4].title,
            price: formatPrice(item.unitPriceGross),
            quantity: item.quantity
          };
        });

        if (window.gtag) {
          window.gtag('event', 'purchase', {
            transaction_id: order._id,
            value: formatPrice(order.details.finalGross),
            tax: formatPrice(order.details.finalGross - order.details.finalNet),
            shipping: formatPrice(order.deliveryCosts),
            currency: 'EUR',
            items: items
          });
        }
        if (window.fbq) {
          window.fbq('track', 'Purchase', {
            value: formatPrice(order.details.finalGross),
            currency: 'EUR'
          });
        }
      });
    },
    turnIntoOffer: function turnIntoOffer() {
      var _this7 = this;

      var cart = this.get('shoppingCart');
      cart.turnIntoOffer = true;

      return new Promise(function (resolve) {
        _this7.updateShoppingCart(cart);

        Ember.run.later(function () {
          _this7.set('shoppingCart', null);
          resolve();
        }, 700);
      });
    },
    clearShoppingCart: function clearShoppingCart() {
      var cart = this.get('shoppingCart');
      var myStorage = this.get('myStorage');

      if (cart && cart._id) {
        return myStorage.shoppingCart.ajaxDelete({
          param: '' + cart._id
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },
    redeemPromocode: function redeemPromocode(promocode) {
      var additionalDetails = this.get('shoppingCart.additionalDetails');
      var cart = this.get('shoppingCart');
      var hasPromocode = false;

      if (additionalDetails === undefined) {
        additionalDetails = [];
      }

      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = additionalDetails[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var detail = _step4.value;

          if (detail.itemType === 'Promocode') {
            hasPromocode = true;
          }
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      if (hasPromocode) {
        // TODO: need better error handling
        // error, no more than one promo code
      } else {
        var isCalculated = undefined;
        var percentage = undefined;
        var priceNet = undefined; // calc in backend since i'm not showing it in the frontend
        var priceGross = undefined;

        if (promocode.type === 'percentage') {
          isCalculated = true;
          percentage = promocode.percentage;
          var totalGross = this.get('shoppingCart.totalGross');
          priceGross = Math.round(totalGross * percentage / 100);
        } else if (promocode.type === 'value') {
          isCalculated = false;
          priceGross = promocode.value;
        } else {
          // TODO: need better error handling
          // error
        }

        if (priceGross) {
          priceGross = -Math.abs(priceGross);
        }

        if (priceNet) {
          priceNet = -Math.abs(priceNet);
        } else {
          priceNet = priceGross;
        }

        var _detail = {
          itemType: 'Promocode',
          item: promocode.id,

          isCalculated: isCalculated,
          percentage: percentage,

          quantity: 1,
          description: promocode.title,
          taxRate: 0, // rebate is on gross price

          unitPriceGross: priceGross,
          unitPriceNet: priceNet,
          totalGross: priceGross,
          totalNet: priceNet
        };

        additionalDetails.push(_detail);
        this.set('shoppingCart.additionalDetails', additionalDetails);
      }

      this.updateShoppingCart(cart);
    }
  });
});
define('webapp/components/map/search-map', ['exports', 'webapp/components/map/base-map'], function (exports, _baseMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseMap.default.extend({
    userProxy: Ember.inject.service(),
    searchService: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    mapPopup: null,
    showCluster: Ember.computed.alias('searchService.displayOptions.showMapCluster'),

    locationMarker: null,

    init: function init() {
      this._super.apply(this, arguments);
      this._onMapDataDebounced = this._onMapDataDebounced.bind(this);
    },
    getLocationIcon: function getLocationIcon() {
      var markerClass = 'user-location-marker';
      var div = document.createElement('div');
      div.classList.add(markerClass);
      return div;
    },
    renderUserLocation: function renderUserLocation() {
      var location = this.get('searchService.location');

      if (this.locationMarker) {
        this.locationMarker.remove();
      }

      if (location) {
        var marker = this.locationMarker = new mapboxgl.Marker(this.getLocationIcon()).setLngLat(this.convertGeopoint(location));
        marker.addTo(this.map);
        this.map.flyTo({
          center: this.convertGeopoint(location),
          speed: 2,
          zoom: 9
        });
      } else {
        this.locationMarker = null;
      }
    },
    mapLoaded: function mapLoaded() {
      var searchService = this.get('searchService');
      searchService.on('searchReceived', this, this._searchReceived);
      searchService.on('showPopup', this, this.createPopup);
      searchService.on('clearPopups', this, this.clearPopups);

      if (searchService.get('searchResult.markers')) {
        this._searchReceived();
      }
      this.renderUserLocation();
      if (this.showCluster) {
        this.map.on('data', this._onMapDataDebounced);
        this.map.on('move', this._onMapDataDebounced);
        this.map.on('moveend', this._onMapDataDebounced);
      }
    },


    _obsUserLocation: Ember.observer('searchService.location', function () {
      this.renderUserLocation();
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var searchService = this.get('searchService');
      searchService.off('searchReceived', this, this._searchReceived);
      searchService.off('showPopup', this, this.createPopup);
      searchService.off('clearPopups', this, this.clearPopups);

      if (this.map) {
        this.map.off('data', this._onMapDataDebounced);
        this.map.off('move', this._onMapDataDebounced);
        this.map.off('moveend', this._onMapDataDebounced);
      }
    },


    // single marker for cluster
    getSingleMarker: function getSingleMarker(feature) {
      var _this = this;

      var hits = this.get('searchService.searchResult.markers');

      var properties = feature.properties;
      var coords = feature.geometry.coordinates;
      var element = document.createElement('div');
      element.className = 'my-marker';

      element.addEventListener('click', function () {
        var hit = hits.find(function (x) {
          return x._id === properties._id;
        });
        if (hit) {
          _this.loadDetail(hit).then(function (fullHit) {
            _this.get('searchService').trigger('markerClick', fullHit);
            // this.createPopup(fullHit);
          });
        }
      });

      return new mapboxgl.Marker({
        element: element
      }).setLngLat(coords);
    },
    _onMapDataDebounced: function _onMapDataDebounced() {
      Ember.run.debounce(this, this._onMapData, 100);
    },
    _onMapData: function _onMapData() {
      var _this2 = this;

      var features = this.map.querySourceFeatures('cluster-source');

      var cachedMarkers = this._cachedMarkers || {};
      var shownMarkers = {};

      var ids = [];

      features.forEach(function (feature) {
        var featureProps = feature.properties;
        var isCluster = featureProps.cluster;
        var id = isCluster ? String(featureProps.cluster_id) : featureProps._id;
        ids.push(id);
        var marker = cachedMarkers[id] || shownMarkers[id];
        if (!marker) {
          if (isCluster) {
            marker = _this2.getClusterMarker(feature);
          } else {
            marker = _this2.getSingleMarker(feature);
          }
          marker.addTo(_this2.map);
        }
        shownMarkers[id] = marker;
      });

      // remove old markers
      Object.keys(cachedMarkers).forEach(function (id) {
        if (!(id in shownMarkers)) {
          cachedMarkers[id].remove();
        }
      });

      this._cachedMarkers = shownMarkers;
    },
    _searchReceived: function _searchReceived() {
      var _this3 = this;

      var map = this.get('map');
      var searchService = this.get('searchService');
      var hits = searchService.get('searchResult.markers');
      var validHits = hits.filter(function (hit) {
        return hit.geoPoint && hit.geoPoint.lat && hit.geoPoint.lon;
      });

      this.clearPopups();
      if (this.showCluster) {
        this.renderClusters(validHits);
      } else {
        this.renderSimpleMarkers(validHits);
      }

      if (searchService.setCustomCenter) {
        map.flyTo({
          center: [searchService.customCenter.lon, searchService.customCenter.lat],
          zoom: Number(searchService.customZoom),
          easing: function easing(t) {
            return t;
          },
          duration: 500
        });
      } else {
        if (validHits.length) {
          var bounds = new mapboxgl.LngLatBounds();
          validHits.forEach(function (hit) {
            return bounds.extend(_this3.convertGeopoint(hit.geoPoint));
          });
          map.fitBounds(bounds, { padding: 40, maxZoom: 17, easing: function easing(t) {
              return t;
            }, duration: 500 });
        }
      }

      this.renderUserLocation();
    }
  });
});
define('webapp/components/widgets/widget-entries-form', ['exports', 'webapp/utils/scroll-to'], function (exports, _scrollTo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function calcMinVariationPrice(item) {
    var prices = [];
    var priceGross = item.priceGross;
    if (priceGross) {
      prices.push(priceGross);
    }
    var detailProducts = item.variants || [];
    detailProducts.forEach(function (_ref) {
      var item = _ref.item;

      if (item.priceGross) {
        prices.push(item.priceGross);
      }
    });

    return prices.length ? Math.min.apply(Math, prices) : 0;
  }

  // mapboxSearchCountriesShowCheckboxes
  // mapboxSearchCountriesCheckboxes

  function parseCountries(countriesString) {
    if (countriesString) {
      return countriesString.trim().split(/\s*,\s*/);
    }
  }

  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    languageService: Ember.inject.service(),
    entryContainer: Ember.inject.service(),

    searchTerm: '',
    selectedItem: null,
    geoLocation: null,

    size: Ember.computed(function () {
      return this.getDefaultSize();
    }),

    getDefaultSize: function getDefaultSize() {
      return this.get('options.size') || 20;
    },
    resetSize: function resetSize() {
      this.set('size', this.getDefaultSize());
    },
    didReceiveAttrs: function didReceiveAttrs() {
      // this.fetchData();

      var selectedMapboxSearchCountries = parseCountries(this.get('data.options.mapboxSearchCountries'));

      if (this.get('data.options.mapboxSearchCountriesShowCheckboxes')) {
        var selectedCountries = parseCountries(this.get('data.options.mapboxSearchCountriesCheckboxes'));
        if (selectedCountries) {
          selectedMapboxSearchCountries = selectedCountries;
        }
      }

      this.set('selectedMapboxSearchCountries', selectedMapboxSearchCountries);
    },
    getCategoryFilter: function getCategoryFilter() {
      var rootCategories = this.get('data.options.rootCategories');

      var catFilters = [];
      if (rootCategories && rootCategories.length) {
        catFilters.push({ type: 'or', items: rootCategories });
      }

      return catFilters;
    },


    mapboxSearchCountries: Ember.computed('data.options.mapboxSearchCountries', function () {
      var countries = parseCountries(this.get('data.options.mapboxSearchCountries'));
      return countries;
    }),

    fetchGeoSearchResult: function fetchGeoSearchResult(searchTerm) {
      var isZip = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var myStorage = this.get('myStorage');

      var countries = this.get('selectedMapboxSearchCountries') || [];

      var proximity = undefined;
      var types = undefined;
      if (isZip) {
        types = ['postcode'];
        var isNumber = !isNaN(Number(searchTerm));
        var isAustria = isNumber && searchTerm.length === 4;
        if (isAustria) {
          proximity = { lat: 47.72, lon: 14.76 };
        }
      }

      return myStorage.geocoder.ajaxPost({
        param: 'search_place',
        data: {
          searchTerm: searchTerm,
          language: this.get('languageService.selectedLanguage'),
          proximity: proximity,
          countries: countries.length ? countries : undefined,
          types: types
        }
      });
    },
    fetchEntries: function fetchEntries(options) {
      var geoResult = this.get('geoResult');
      var location = geoResult && geoResult.location;

      var sort = location ? { sort: 'geoDistance' } : {
        sort: options.sortField,
        sortOrder: options.sortOrder
      };

      var searchRequest = _extends({
        itemTypes: options.types,
        size: this.get('size'),
        categoryFilter: this.getCategoryFilter(),
        tagFilter: options.tags,
        aggregations: false,
        showVariants: options.types.includes('product'),
        location: location
      }, sort);
      var myStorage = this.get('myStorage');
      return myStorage.search.ajaxPost({
        data: searchRequest,
        query: {
          lang: this.get('languageService.selectedLanguage'),
          site: this.get('cachedData.cachedSite._id')
        }
      }).then(function (res) {
        return {
          items: res.hits.map(function (item) {
            item.minVariationPrice = calcMinVariationPrice(item);
            return item;
          }),
          totalHits: res.totalHits
        };
      });
    },
    fetchData: function fetchData() {
      var _this = this;

      var options = this.get('data.options');

      var p = void 0;
      if (options.types.includes('article')) {
        throw new Error('articles not supported yet');
      } else {
        p = this.fetchEntries(options);
      }

      p.then(function (res) {
        if (!_this.isDestroyed) {
          _this.setProperties(res);
          _this.set('selectedItem', null);
        }
      });
    },
    search: function search() {
      var _this2 = this;

      var searchTerm = this.get('searchTerm');
      return this.fetchGeoSearchResult(searchTerm).then(function (geoResult) {
        var geoItem = geoResult[0];
        if (geoItem) {
          _this2.set('geoResult', {
            name: geoItem.place_name,
            location: { lat: geoItem.center[1], lon: geoItem.center[0] }
          });
        } else {
          _this2.set('geoResult', null);
        }
        return _this2.fetchData();
      });
    },


    hasMore: Ember.computed('totalHits', 'size', function () {
      var totalHits = this.get('totalHits');
      var size = this.get('size');
      return totalHits > size;
    }),

    hasContentAfter: Ember.computed('data.contentAfter', function () {
      var contentAfter = this.get('data.contentAfter');
      return Boolean(contentAfter);
    }),

    hasContentBefore: Ember.computed('data.content', function () {
      var contentBefore = this.get('data.content');
      return Boolean(contentBefore);
    }),

    actions: {
      resetSelectedItem: function resetSelectedItem() {
        this.set('selectedItem', null);
        setTimeout(function () {
          (0, _scrollTo.scrollToElement)('#tm-widget-entries-form-search', { offset: 100 });
        }, 300);
      },
      selectItem: function selectItem(item) {
        var _this3 = this;

        var entryContainer = this.get('entryContainer');
        entryContainer.fetchDetail(item.slug).then(function (detail) {
          _this3.set('selectedItem', detail);
        });
        setTimeout(function () {
          (0, _scrollTo.scrollToElement)('#trialform', { offset: 100 });
        }, 300);
      },
      search: function search() {
        this.resetSize();
        this.search();
      },
      toggleCountry: function toggleCountry() {
        if (this.get('searchTerm')) {
          this.resetSize();
          this.search();
        }
      },
      showMore: function showMore() {
        var size = this.get('size');
        this.set('size', size + this.getDefaultSize());
        this.fetchData();
      }
    }
  });
});
define('webapp/components/campaign/lead-form', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var mandatoryFields = ['name', 'email', 'date'];

  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    languageService: Ember.inject.service(),
    router: Ember.inject.service(),
    privacyAccepted: false,
    isSubmitted: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('data', {});
    },


    canSubmit: Ember.computed.apply(undefined, ['isSubmitted', 'privacyAccepted'].concat(_toConsumableArray(mandatoryFields.map(function (x) {
      return 'data.' + x;
    })), [function () {
      if (this.get('isSubmitted') || !this.get('privacyAccepted')) {
        return false;
      }

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = mandatoryFields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var field = _step.value;

          if (!this.get('data.' + field)) {
            return false;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return true;
    }])),

    redirectAfterSuccess: function redirectAfterSuccess(internalName) {
      if (!internalName) {
        throw new Error('need internalName');
      }
      var languageService = this.get('languageService');
      var router = this.get('router');
      var pages = this.get('cachedData.cachedSite.pages');
      var page = (0, _treeUtils.findInTree)(pages, function (page) {
        return page.internalName === internalName;
      });

      if (page) {
        var translatedSlugSplit = languageService.translateSlug(page).split('/');

        var firstPart = translatedSlugSplit.slice(0, translatedSlugSplit.length - 1);
        var lastPart = translatedSlugSplit[translatedSlugSplit.length - 1];

        var redirectFullSlug = [].concat(_toConsumableArray(firstPart), [this.get('campaign.slug'), lastPart]).join('/');

        Ember.run.next(function () {
          return router.transitionTo('page', redirectFullSlug);
        });
      } else {
        console.error('subpage for internalName: ' + internalName + ' not found.');
      }
    },


    actions: {
      submit: function submit() {
        var _this = this;

        var myStorage = this.get('myStorage');
        var message = this.get('message');

        var data = this.get('data');
        var campaign = this.get('campaign');
        var selectedEntry = this.get('selectedEntry');

        var formData = {
          campaign: campaign._id,
          targetItem: selectedEntry._id,
          data: data
        };

        this.set('isSubmitted', true);

        if (window.ga) {
          window.ga('send', 'event', 'PT-Anfrage gesendet', campaign.slug, selectedEntry.title);
        }

        myStorage.campaign.ajaxPost({
          param: 'form_submission',
          data: formData
        }).then(function () {
          message.infoT('forms.ui.formSent');

          var redirectPage = _this.get('campaign.redirectPage');

          if (redirectPage) {
            _this.redirectAfterSuccess(redirectPage);
          }
        }).catch(function (err) {
          message.error(err.payload.message);
        });
      }
    }
  });
});
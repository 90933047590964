define('webapp/components/uikit-sticky', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div'

    // stickyOptions: function () {
    //   var opts = this.get('data-uk-sticky');
    //   if (opts) {
    //     try {
    //       return JSON.parse(opts);
    //     } catch (err) {
    //       console.log('error at sticky options');
    //       console.error(err);
    //     }
    //   } else return {};
    // }.property('data-uk-sticky'),

    // didInsertElement () {
    //   scheduleOnce('afterRender', () => {
    //     this._sticky = UIkit.sticky(this.$()[0], this.get('stickyOptions'));
    //     var outerHeight = this._sticky.element.outerHeight();
    //     this.sendAction('heightChanged', outerHeight);
    //   });
    // },

    // didUpdateAttrs() {
    //   scheduleOnce('afterRender', this, () => {
    //     this._sticky.computeWrapper();
    //     var outerHeight = this._sticky.element.outerHeight();
    //     var placeHolder = document.querySelector('.uk-sticky-placeholder');
    //     if (placeHolder) placeHolder.style.height = outerHeight + "px";
    //     this.sendAction('heightChanged', outerHeight);
    //   });
    // }

  });
});
define('webapp/controllers/account/orders/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),

    orderStatus: Ember.computed('status', function () {
      var i18n = this.get('i18n');
      var status = this.get('model.status');
      var statusMessage = '';

      switch (status) {
        case 0:
          statusMessage = i18n.t('order.status.zero').toString(); // Bestellung eingegangen
          break;
        case 1:
          statusMessage = i18n.t('order.status.one').toString(); // In Bearbeitung
          break;
        case 2:
          statusMessage = i18n.t('order.status.two').toString(); // Bestellung wurde versandt
          break;
        case 3:
          statusMessage = i18n.t('order.status.three').toString(); // Zugestellt
          break;
        case 4:
          statusMessage = i18n.t('order.status.four').toString(); // Retoure
          break;
        default:
          statusMessage = i18n.t('order.status.default').toString(); // Unbekannter Besetzungszustand
          break;
      }

      return statusMessage;
    })
  });
});
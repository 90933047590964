define('webapp/components/upload-status-new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fileQueue: Ember.inject.service(),

    actions: {
      cancel: function cancel(file) {
        this.sendAction('cancel', file);
      }
    },

    files: Ember.computed('fileQueue.files.@each.progress', function () {
      var files = this.get('fileQueue.files');

      return files && files.map(function (file) {
        var progress = Math.round(file.progress);
        var uploading = true;
        if (file.loaded === 46) {
          uploading = false;
        }
        return {
          name: file.blob.name,
          progress: progress,
          style: Ember.String.htmlSafe('width: ' + progress + '%;'),
          uploading: uploading,
          id: file.id
        };
      });
    })
  });
});
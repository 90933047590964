define('webapp/controllers/account/favorites', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    _removeFavorite: function _removeFavorite(id) {
      var model = [];
      var favorites = this.get('model.model');

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = favorites[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var favorite = _step.value;

          if (id !== favorite._id) {
            model.push(favorite);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this.set('model.model', model);
      this.set('model.hits', model.length);
    },


    actions: {
      removeFavorite: function removeFavorite(id) {
        this._removeFavorite(id);
      }
    }
  });
});
define('webapp/components/shopping/quantity-selection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var QUANT_LIMIT = 10;

  exports.default = Ember.Component.extend({
    quantities: Ember.computed('maxOrderQuantity', function () {
      var maxOrderQuantity = this.get('maxOrderQuantity');
      var limit = 10;
      if (maxOrderQuantity && maxOrderQuantity < QUANT_LIMIT) {
        limit = maxOrderQuantity + 1;
      }

      var quantities = [];
      for (var i = 1; i < limit; i++) {
        quantities.push(i);
      }
      return quantities;
    }),

    showOverflow: Ember.computed('maxOrderQuantity', function () {
      var maxOrderQuantity = this.get('maxOrderQuantity');
      if (maxOrderQuantity) {
        return maxOrderQuantity > QUANT_LIMIT;
      } else {
        return true;
      }
    }),

    showInput: false,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.value >= QUANT_LIMIT) {
        this.set('showInput', true);
      }
    },


    actions: {
      setQuantity: function setQuantity(quanity) {
        if (quanity === QUANT_LIMIT) {
          this.set('showInput', true);
        }
        this.changeValue(quanity);
      },
      setQuantityE: function setQuantityE(e) {
        this.changeValue(Number(e.target.value));
      }
    }
  });
});
define('webapp/utils/deep-equal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function filterKey(key) {
    return key !== '_super' && key.indexOf('__') < 0; //ignore private properties, e.g. introduced by cp-validations
  }

  function deepEqual(a, b) {
    var filterFn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : filterKey;

    if (Array.isArray(a)) {
      if (Array.isArray(b)) {
        if (a.length === b.length) {
          for (var i = 0; i < a.length; i++) {
            if (!deepEqual(a[i], b[i], filterFn)) {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (a === null) {
      return b === null;
    } else if (a instanceof Date) {
      return b instanceof Date && +a === +b;
    } else if ((typeof a === 'undefined' ? 'undefined' : _typeof(a)) === 'object') {
      if (b !== null && (typeof b === 'undefined' ? 'undefined' : _typeof(b)) === 'object') {
        var aKeys = Object.keys(a).filter(filterFn);
        var bKeys = Object.keys(b).filter(filterFn);
        if (aKeys.length === bKeys.length) {
          for (var _i = 0; _i < aKeys.length; _i++) {
            var aKey = aKeys[_i];
            if (b.hasOwnProperty(aKey)) {
              if (!deepEqual(a[aKey], b[aKey], filterFn)) {
                return false;
              }
            } else {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (typeof a === 'number') {
      if (isNaN(a)) {
        return isNaN(b);
      } else {
        return a === b;
      }
    } else {
      return a === b;
    }
  }

  exports.default = deepEqual;
});
define('webapp/components/widgets/widget-menu', ['exports', 'webapp/utils/find-by'], function (exports, _findBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cmsService: Ember.inject.service(), // used to get cached menus cmsService.menus

    isList: Ember.computed('data.options.listDisplay', function () {
      var listDisplay = this.get('data.options.listDisplay');
      return listDisplay === 'list';
    }),

    isDropdown: Ember.computed('data.options.listDisplay', function () {
      var listDisplay = this.get('data.options.listDisplay');
      return listDisplay === 'dropdown';
    }),

    menuData: Ember.computed('cmsService.menus', function () {
      // TODO merge data.menu data.menus in admin interface and api
      var multiMenu = this.get('data.options.multiMenu');
      var menus = this.get('cmsService.menus');

      var menuIds = multiMenu ? this.get('data.menus') : [this.get('data.menu')];

      var shownPages = [];

      menuIds.forEach(function (menuId) {
        var menu = (0, _findBy.default)(menus, '_id', menuId);
        if (menu && menu.pages) {
          shownPages = shownPages.concat(menu.pages);
        }
      });

      return shownPages;
    })
  });
});
define('webapp/components/tracking-external-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',
    trackingService: Ember.inject.service(),
    attributeBindings: ['href', 'target', 'rel'],

    click: function click() {
      var trackingService = this.get('trackingService');
      var eventName = this.get('eventName');
      var eventAttribute = this.get('eventAttribute');
      var eventValue = this.get('eventValue');

      trackingService.gaCustomEvent(eventName, eventAttribute, eventValue);
    }
  });
});
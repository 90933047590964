define('webapp/components/x-select-bound', ['exports', 'emberx-select/components/x-select'], function (exports, _xSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _xSelect.default.extend({
    layoutName: 'components/x-select',

    change: function change() {
      this._super.apply(this, arguments);
      // this enables two way binding
      this.set('value', this._getValue());
    },


    // hack to set default action for bound x-select. TODO set default options not in x-select but explicitly on object creation
    __setDefaultValues: function __setDefaultValues() {
      var canSet = !this.isDestroying && !this.isDestroyed;
      if (canSet && this.get('value') === undefined) {
        var val = this._getValue();
        this.set('value', val);
        this.sendAction('action', val);
      }
    }
  });
});
define('webapp/utils/defaults-deep', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // like lodash defaultsDeep, not considering corner cases but much simpler

  function isValue(obj) {
    return typeof obj === 'string' || typeof obj === 'number' || Array.isArray(obj);
  }

  function defaultsDeep(target, source) {
    Object.keys(source).forEach(function (key) {
      var sourceValue = source[key];

      if (target[key] === undefined) {
        if (isValue(sourceValue)) {
          target[key] = sourceValue;
        } else {
          target[key] = defaultsDeep({}, sourceValue);
        }
      } else {
        if (!isValue(sourceValue)) {
          target[key] = defaultsDeep(target[key], sourceValue);
        }
      }
    });

    return target;
  }

  exports.default = defaultsDeep;
});
define('webapp/components/widgets/widget-social-wall', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    fastboot: Ember.inject.service(),

    feed: [],
    imageRegex: /^(.*?(\bmedia\b)[^$]*)$/g,
    videoRegex: /^(.*?(\bext_tw_video_thumb\b)[^$]*)$/g,

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('fastboot.isFastBoot')) {
        return;
      }

      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this = this;

      var data = this.get('data');
      var myStorage = this.get('myStorage');

      myStorage.socialfeeds.ajaxGet({ param: 'feed/' + data._id }).then(function (res) {
        _this.set('feed', res);
      });
    },


    actions: {
      search: function search(searchTerm) {
        var _this2 = this;

        var myStorage = this.get('myStorage');
        var data = this.get('data');

        if (searchTerm.length >= 1) {
          myStorage.socialfeeds.ajaxGet({
            param: 'feed/' + data._id + '/' + searchTerm.toLowerCase()
          }).then(function (res) {
            _this2.set('feed', res);
            _this2.set('activeFilter', searchTerm);
          });
        } else {
          this.fetchData();
          this.set('activeFilter', '');
        }
      },
      resetFilter: function resetFilter() {
        this.set('searchTerm', '');
        this.set('activeFilter', '');
        this.fetchData();
      }
    }
  });
});
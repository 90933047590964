define('webapp/components/variant-selector', ['exports', 'webapp/utils/product-utils', 'webapp/utils/collections'], function (exports, _productUtils, _collections) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function sortByValue(array) {
    var isAllNumber = array.every(function (x) {
      return !isNaN(Number(x.value));
    });

    if (isAllNumber) {
      return array.sort(function (a, b) {
        return Number(a.value) - Number(b.value);
      });
    } else {
      return array.sort(function (a, b) {
        return String(a.value).localeCompare(String(b.value));
      });
    }
  }

  function getFieldValue(fullFields, key) {
    if (fullFields) {
      var foundField = fullFields.find(function (x) {
        return x.key === key;
      });
      return foundField && foundField.value;
    }
  }

  exports.default = Ember.Component.extend({
    languageService: Ember.inject.service(),

    init: function init() {
      this._super();
      this.calcInitialState();
    },
    calcInitialState: function calcInitialState() {
      this.setVariantProperties();
      this.setupSelectedProperties();
      this._productId = this.get('productId');
      this._selectedLanguage = this.get('languageService.selectedLanguage');
    },
    didUpdateAttrs: function didUpdateAttrs() {
      // recalc state if product or language changes
      if (this._productId !== this.get('productId') || this._selectedLanguage !== this.get('languageService.selectedLanguage')) {
        this.calcInitialState();
      }
    },


    // add nested variants (child variants with options.isEquipment)
    variantItems: Ember.computed('variants', function () {
      var variantRelations = this.get('variants') || [];

      var variantItems = [];

      variantRelations.forEach(function (rel) {
        variantItems.push(rel.item);
        if (rel.childRelations) {
          rel.childRelations.forEach(function (rel) {
            if (rel.type === 'variation' && rel.options && rel.options.isEquipment && rel.direction === 1) {
              variantItems.push(_extends({}, rel.item, { parent: rel.item }));
            }
          });
        }
      });

      return variantItems;
    }),

    setVariantProperties: function setVariantProperties() {
      var variantItems = this.get('variantItems');
      var keys = [];
      var variantProperties = [];

      variantItems.forEach(function (item) {
        if (item.fullFields) {
          item.fullFields.forEach(function (fieldDesc) {
            var key = fieldDesc.key;
            if (fieldDesc.isVariationProperty && !keys.includes(key)) {
              var placeholderField = (0, _productUtils.getPlaceholderField)(fieldDesc);

              variantProperties.push({
                key: fieldDesc.key,
                name: fieldDesc.name,
                options: fieldDesc.options,
                values: (0, _collections.uniqueBy)(variantItems.map(function (item) {
                  var value = getFieldValue(item.fullFields, key);
                  var unit = (0, _productUtils.getUnit)(item, placeholderField);
                  return { value: value, unit: unit, muted: false };
                }).filter(function (x) {
                  return Boolean(x.value);
                }), function (x) {
                  return [x.value, x.unit].join('|');
                }),
                selection: null // TODO not used?
              });
              keys.push(key);
            }
          });
        }
      });

      var hideSameValueFields = this.get('hideSameValueFields');

      if (hideSameValueFields) {
        variantProperties = variantProperties.filter(function (prop) {
          return prop.values.length > 1; // values are unique, so this is sufficient
        });
      }

      variantProperties.forEach(function (prop) {
        prop.values = sortByValue(prop.values);
      });

      this.set('variantProperties', variantProperties);
    },
    setupSelectedProperties: function setupSelectedProperties() {
      var variantProperties = this.get('variantProperties');
      var selectedVariant = this.get('selectedVariant');
      var selectedProperties = variantProperties.reduce(function (acc, prop) {
        acc[prop.key] = null;
        if (selectedVariant && selectedVariant.fullFields) {
          var field = selectedVariant.fullFields.find(function (x) {
            return x.key === prop.key;
          });
          if (field && field.value) {
            acc[prop.key] = field.value;
          }
        }
        return acc;
      }, {});

      this.set('selectedProperties', selectedProperties);
    },
    updateVariantProperties: function updateVariantProperties(key) {
      var selectedProperties = this.get('selectedProperties');
      var newSelectedProperties = _extends({}, selectedProperties);
      var variantProperties = this.get('variantProperties');
      var variantItems = this.get('variantItems');

      // allow a partial match if this product has nested variants
      var hasNestedVariants = variantItems.some(function (x) {
        return x.parent;
      });

      var allPropertiesSelected = true;
      variantProperties.forEach(function (_ref) {
        var key = _ref.key;

        if (selectedProperties[key] === null) {
          allPropertiesSelected = false;
        }
      });

      var matchedVariant = null;
      if (hasNestedVariants || allPropertiesSelected) {
        matchedVariant = variantItems.find(function (item) {
          var matches = true;

          variantProperties.forEach(function (_ref2) {
            var key = _ref2.key;

            var itemProperty = getFieldValue(item.fullFields, key);
            var propertyEquals = itemProperty === selectedProperties[key];
            var isPartialMatch = hasNestedVariants && !itemProperty && !selectedProperties[key];

            if (!(propertyEquals || isPartialMatch)) {
              matches = false;
            }
          });

          return matches;
        });
      }

      var selectVariant = this.get('selectVariant');
      selectVariant && selectVariant(matchedVariant ? matchedVariant : null);

      var newVariantProperties = variantProperties.map(function (prop) {
        var availableVariantsForProp = variantItems.filter(function (item) {
          var matches = true;

          variantProperties.forEach(function (_ref3) {
            var key = _ref3.key;

            if (key !== prop.key && selectedProperties[key] !== null && getFieldValue(item.fullFields, key) !== selectedProperties[key]) {
              matches = false;
            }
          });

          return matches;
        });

        return _extends({}, prop, {
          values: prop.values.map(function (propValue) {
            var muted = !availableVariantsForProp.find(function (item) {
              return getFieldValue(item.fullFields, prop.key) === propValue.value;
            });
            if (muted && selectedProperties[prop.key] !== null && selectedProperties[prop.key] === propValue.value) {
              if (key !== prop.key) {
                newSelectedProperties[prop.key] = null;
              } else {
                muted = false;
              }
            }
            return { value: propValue.value, unit: propValue.unit, muted: muted };
          })
        });
      });

      this.set('variantProperties', newVariantProperties);
      this.set('selectedProperties', newSelectedProperties);
    },


    actions: {
      selectVariant: function selectVariant(variant) {
        this.get('selectVariant')(variant);
      },
      selectProperty: function selectProperty(key, value) {
        var selectedProperties = this.get('selectedProperties');
        if (selectedProperties[key] === value) {
          return;
        }
        var newSelectedProperties = _extends({}, selectedProperties, _defineProperty({}, key, value));
        this.set('selectedProperties', newSelectedProperties);
        this.updateVariantProperties(key, value);
      }
    }
  });
});
define('webapp/components/widgets/widget-trusted-shops', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.loadData();
    },
    loadScript: function loadScript() {
      var trustedShopsId = this.get('data.options.trustedShopsId');

      var script = '    (function () {\n      var _tsid = \'' + trustedShopsId + '\';\n      _tsConfig = {\n        \'yOffset\': \'0\', /* offset from page bottom */\n        \'variant\': \'reviews\', /* default, reviews, custom, custom_reviews */\n        \'customElementId\': \'\', /* required for variants custom and custom_reviews */\n        \'trustcardDirection\': \'\', /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */\n        \'customBadgeWidth\': \'\', /* for custom variants: 40 - 90 (in pixels) */\n        \'customBadgeHeight\': \'\', /* for custom variants: 40 - 90 (in pixels) */\n        \'disableResponsive\': \'false\', /* deactivate responsive behaviour */\n        \'disableTrustbadge\': \'false\' /* deactivate trustbadge */\n      };\n      var _ts = document.createElement(\'script\');\n      _ts.type = \'text/javascript\';\n      _ts.charset = \'utf-8\';\n      _ts.async = true;\n      _ts.src = \'//widgets.trustedshops.com/js/\' + _tsid + \'.js\';\n      var __ts = document.getElementsByTagName(\'script\')[0];\n      __ts.parentNode.insertBefore(_ts, __ts);\n    })()\n    ';

      window.eval(script);
    },
    loadData: function loadData() {
      var _this = this;

      var myStorage = this.get('myStorage');

      var shoppingCartId = this.get('shoppingCartService.lastCartId');
      // const shoppingCartId = '63edf84957b79998fb2028c4';

      if (!shoppingCartId) {
        return;
      }

      myStorage.shopping.ajaxGet({ param: 'order/' + shoppingCartId }).then(function (order) {
        if (!_this.isDestroyed) {
          _this.set('order', order);
          _this.loadScript();
        }
      });
    }
  });
});
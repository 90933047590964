define('webapp/services/payment-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var THIRD_PARTY_PAYMENT_METHODS = ['paypal', 'sepa_debit', 'sofort'];

  exports.default = Ember.Service.extend({
    message: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),
    shoppingCart: Ember.computed.alias('shoppingCartService.shoppingCart'),
    cmsService: Ember.inject.service(),
    cachedData: Ember.inject.service(),

    _cachedPaymentInfo: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.fetchPaymentMethodInfo();
    },


    /*
     * Make sure to get the information about if PayPal or Stripe is enabled in the API
     */
    fetchPaymentMethodInfo: function fetchPaymentMethodInfo() {
      var _this = this;

      return this.get('shoppingCartService').fetchPaymentMethodInfo().then(function (paymentInfo) {
        _this.set('enablePayPal', !!paymentInfo.enablePayPal);
        _this.set('enableStripe', !!paymentInfo.enableStripe);
        _this.set('defaultPaymentMethod', paymentInfo.defaultPaymentMethod);
        _this.set('shoppingCartService.defaultPaymentMethod', paymentInfo.defaultPaymentMethod);
      });
    },


    /*
     * The next functions:
     *     canUsePaymentMethod
     *     _canUsePaymentPortals
     *     _canUseSEPA
     *     _canUsePayPal
     *     _canUseSofort
     * as well as the computed properties
     *     canUseSEPA
     *     canUsePayPal
     *     canUseSofort
     * are used to activate/deactivate payment methods in the UI
     */
    canUsePaymentMethod: function canUsePaymentMethod(paymentMethod) {
      var can = true;

      if (THIRD_PARTY_PAYMENT_METHODS.includes(paymentMethod)) {
        can = this._canUsePaymentPortals(true);
        if (can) {
          if (paymentMethod === 'paypal') {
            var enablePayPal = this.get('enablePayPal');
            can = enablePayPal;
          } else if (['sofort', 'sepa_debit'].includes(paymentMethod)) {
            var enableStripe = this.get('enableStripe');
            can = enableStripe;
          }
        }
      }

      return can;
    },
    _canUsePaymentPortals: function _canUsePaymentPortals(can) {
      var finalGross = this.get('shoppingCart.finalGross');

      // check if the order is 0 (probably thanks to a promocode)
      // if it is, disable third party payment methods
      if (finalGross === 0) {
        return false;
      }

      return can;
    },


    // INFO: the EU check is just a port form JWs check in the hbs file
    // TODO: implement a better check
    _canUseSEPA: function _canUseSEPA() {
      var noneEUCountryCodes = ['NO', 'CH', 'TR', 'GB'];
      var countryCode = this.get('shoppingCart.deliveryAddress.countryCode');
      var can = !noneEUCountryCodes.includes(countryCode);
      can = this._canUsePaymentPortals(can);

      return can;
    },


    canUseSEPA: Ember.computed('shoppingCart.deliveryAddress.countryCode', 'shoppingCart.finalGross', 'enableStripe', function () {
      return this._canUseSEPA();
    }),

    _canUsePayPal: function _canUsePayPal() {
      var enablePayPal = this.get('enablePayPal');
      if (!enablePayPal) {
        return false;
      }

      var can = true;
      can = this._canUsePaymentPortals(can);

      return can;
    },


    canUsePayPal: Ember.computed('shoppingCart.finalGross', 'enablePayPal', function () {
      return this._canUsePayPal();
    }),

    _canUseSofort: function _canUseSofort() {
      var enableStripe = this.get('enableStripe');
      if (!enableStripe) {
        return false;
      }

      var can = true;
      can = this._canUsePaymentPortals(can);

      return can;
    },


    canUseSofort: Ember.computed('shoppingCart.finalGross', 'enableStripe', function () {
      return this._canUseSofort();
    }),

    /*
     * This function is used in the paypal-button.js in the 'createOrder' function
     */
    // TODO data is probably not used by api
    createOrderWithPayPal: function createOrderWithPayPal(data) {
      var cart = this.get('shoppingCart');
      var myStorage = this.get('myStorage');
      var param = cart._id + '/checkout_with_paypal';
      var query = {
        sessionId: this.get('cmsService.sessionId'),
        site: this.get('cachedData.cachedSite._id')
      };

      return myStorage.payment.ajaxPost({ param: param, query: query, data: data });
    },
    logError: function logError(err) {
      var message = this.get('message');
      message.error(err.payload && err.payload.message);
      throw err;
    },


    /*
     * This function is used in the paypal-button.js in the 'onApprove' function
     */
    approveOrderWithPayPal: function approveOrderWithPayPal(data) {
      var _this2 = this;

      var myStorage = this.get('myStorage');
      var param = 'approved_over_paypal';
      var query = { sessionId: this.get('cmsService.sessionId') };
      var shoppingCartId = this.get('shoppingCart._id');

      return myStorage.payment.ajaxPost({
        param: param,
        query: query,
        data: {
          payPalData: data,
          shoppingCartId: shoppingCartId
        }
      }).catch(function (err) {
        return _this2.logError(err);
      });
    },
    setupStripePaymentIntent: function setupStripePaymentIntent(paymentMethod) {
      var _this3 = this;

      var redirect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var cart = this.get('shoppingCart');
      var myStorage = this.get('myStorage');
      var param = cart._id + '/setup_stripe_payment_intent?redirect=' + redirect;
      var query = {
        site: this.get('cachedData.cachedSite._id'),
        paymentMethod: paymentMethod
      };

      return myStorage.payment.ajaxPost({ param: param, query: query }).catch(function (err) {
        return _this3.logError(err);
      });
    },
    saveStripePaymentInformations: function saveStripePaymentInformations(paymentMethod, paymentIntent) {
      var _this4 = this;

      var cart = this.get('shoppingCart');
      var myStorage = this.get('myStorage');
      var param = cart._id + '/save_stripe_payment_informations';
      var data = { paymentMethod: paymentMethod, paymentIntent: paymentIntent };
      var query = {
        site: this.get('cachedData.cachedSite._id')
      };

      return myStorage.payment.ajaxPost({ param: param, query: query, data: data }).catch(function (err) {
        return _this4.logError(err);
      });
    },
    setupStripePaymentRedirect: function setupStripePaymentRedirect(paymentMethod, paymentIntent) {
      var _this5 = this;

      var cart = this.get('shoppingCart');
      var myStorage = this.get('myStorage');
      var param = cart._id + '/setup_stripe_payment_redirect';
      var data = { paymentMethod: paymentMethod, paymentIntent: paymentIntent };
      var query = {
        site: this.get('cachedData.cachedSite._id')
      };

      return myStorage.payment.ajaxPost({ param: param, query: query, data: data }).catch(function (err) {
        return _this5.logError(err);
      });
    },
    retrieveStripePaymentIntent: function retrieveStripePaymentIntent(paymentMethod, paymentIntent) {
      var _this6 = this;

      var cart = this.get('shoppingCart');
      var myStorage = this.get('myStorage');
      var param = cart._id + '/retrieve_stripe_payment_intent';
      var data = { paymentMethod: paymentMethod, paymentIntent: paymentIntent };
      var query = {
        site: this.get('cachedData.cachedSite._id')
      };

      return myStorage.payment.ajaxPost({ param: param, query: query, data: data }).catch(function (err) {
        return _this6.logError(err);
      });
    }
  });
});
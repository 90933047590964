define('webapp/components/map/detail-map', ['exports', 'webapp/components/map/base-map'], function (exports, _baseMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseMap.default.extend({
    searchService: Ember.inject.service(),
    enablePopup: true,
    popupTransform: [0, 0],
    defaultZoom: 8,

    mapLoaded: function mapLoaded() {
      var _this = this;

      var map = this.get('map');
      var detail = this.get('detail');
      var enablePopup = this.get('enablePopup');
      // if (this._markerLayer) map.removeLayer(this._markerLayer);
      // const markerLayer = this._markerLayer = new L.FeatureGroup();

      if (detail && detail.geoPoint) {
        var iconElement = this.getIcon();
        iconElement.addEventListener('click', function () {
          _this.createPopup(detail, { anchor: 'bottom' });
        });
        if (enablePopup) {
          this.createPopup(detail, { transForm: this.get('popupTransform'), duration: 0, anchor: 'bottom' });
        }
        var lnglat = [detail.geoPoint.lon, detail.geoPoint.lat];
        var marker = new mapboxgl.Marker(iconElement).setLngLat(lnglat);
        marker.addTo(map);
      }
      // map.addLayer(markerLayer);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var initialPosition = this.get('initialPosition');
      if (initialPosition && initialPosition.lat) {
        this.set('center', [initialPosition.lon, initialPosition.lat]);
      }
    }
  });
});
define('webapp/helpers/format-price', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        price = _ref2[0],
        type = _ref2[1];

    if (price == null) {
      return;
    }

    price = parseFloat(price);

    if (isNaN(price)) {
      return 'invalid price';
    }

    if (type !== 'decimals') {
      return Math.floor(price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else {
      var res = Math.round(price % 1 * 100).toString();
      if (res.length === 1) {
        res = '0' + res;
      }
      return res;
    }
  });
});
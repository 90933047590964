define('webapp/utils/clone-deep', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = cloneDeep;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  // simple cloneDeep, only own properties
  // lodash.cloneDeep does some strange prototype stuff, that interferes with ember

  function filterKey(key) {
    return key !== '_super' && key.indexOf('__') < 0; //ignore private properties, e.g. introduced by cp-validations
  }

  function cloneDeep(obj) {
    var filterF = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : filterKey;

    if (Array.isArray(obj)) {
      return obj.map(function (x) {
        return cloneDeep(x, filterF);
      });
    } else if (obj === null) {
      return null;
    } else if (obj instanceof Date) {
      return new Date(obj);
    } else if ((typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) === 'object') {
      return Object.keys(obj).filter(filterF).reduce(function (acc, key) {
        acc[key] = cloneDeep(obj[key], filterF);
        return acc;
      }, {});
    } else {
      return obj;
    }
  }
});
define('webapp/controllers/search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    searchService: Ember.inject.service(),
    viewService: Ember.inject.service()
    // category: Ember.computed.alias('searchService.selectedCategoryTitle'),
  });
});
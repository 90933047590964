define('webapp/settings/media-extensions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var vars = {
    IMAGE_EXTENSIONS: ['jpg', 'jpeg', 'png', 'psd', 'gif', 'svg'],
    IMAGE_EXTENSIONS_NO_CONVERT: ['gif', 'svg'],
    DOCUMENT_EXTENSIONS: ['pdf', 'jpg', 'jpeg', 'png'], // TODO why are there images?
    VIDEO_EXTENSIONS: ['mp4', 'mov', 'm4v'],
    AUDIO_EXTENSIONS: ['mp3']
  };

  vars.ALLOWED_EXTENSIONS = Object.keys(vars).reduce(function (all, key) {
    return all.concat(vars[key]);
  }, []);

  exports.default = vars;
});
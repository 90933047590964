define('webapp/services/product-container', ['exports', 'webapp/services/abstract-container'], function (exports, _abstractContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  // only needed for köppl atm
  function addAttachmentPricings(products) {
    var attachments = products.filter(function (item) {
      return item.type === 'attachment';
    }).map(function (vari) {
      return {
        id: vari.relationId,
        relations: vari.childRelations,
        mainPrice: vari.item.fields.displayNetPrice
      };
    });
    var attachmentsMinPriceMap = attachments.map(function (att) {
      if (att.relations) {
        var prices = att.relations.map(function (item) {
          if (item.item.orderNumber && item.item.orderable) {
            return item.item.fields.displayNetPrice;
          }
        });
        prices.push(att.mainPrice);
        var filterdPrices = prices.filter(function (price) {
          return !!price;
        });
        if (filterdPrices.length) {
          var min = Math.min.apply(Math, _toConsumableArray(filterdPrices));
          var showFrom = filterdPrices.length > 1;
          var unit = void 0;
          att.relations.forEach(function (obj) {
            if (obj.fullFields) {
              var priceObj = obj.fullFields.find(function (field) {
                return field.key === 'displayNetPrice' && parseInt(field.value) === parseInt(min);
              });
              if (priceObj && priceObj.options) {
                unit = priceObj.options.unit || '€';
              }
            }
          });
          return { id: att.id, minPrice: { value: min, unit: unit }, showFrom: showFrom };
        }
      }
    });
    var filterdPriceMap = attachmentsMinPriceMap.filter(function (map) {
      return !!map;
    });
    filterdPriceMap.forEach(function (price) {
      var ind = products.findIndex(function (product) {
        return product.relationId.toString() === price.id.toString();
      });
      products[ind].minPrice = price.minPrice;
      products[ind].showFrom = price.showFrom;
    });
    return products;
  }

  exports.default = _abstractContainer.default.extend({
    containerName: 'productContainer',
    selectedVariant: null,
    selectedQuantity: 1,
    languageService: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    cachedData: Ember.inject.service(),

    fetchDetail: function fetchDetail(detailSlug) {
      var _this = this;

      var myStorage = this.get('myStorage');
      return myStorage.product.ajaxGet({
        param: detailSlug,
        query: {
          lang: this.get('languageService').selectedLanguage,
          site: this.get('cachedData.cachedSite._id'),
          view: true,
          sessionId: this.get('cmsService.sessionId')
        }
      }).then(function (res) {
        var selectedVariant = null;
        if (res.products) {
          res.products = addAttachmentPricings(res.products);
          var defaultVariation = res.products.find(function (item) {
            return item.type === 'variation' && item.options && item.options.isDefault;
          });
          if (defaultVariation) {
            selectedVariant = defaultVariation.item;
          }
        }
        _this.set('selectedVariant', selectedVariant);
        _this.set('selectedQuantity', 1);
        return res;
      });
    },
    getTitleToken: function getTitleToken() {
      var title = this.get('detail.title');

      var primaryCategoryTitle = this.get('detail.primaryCategory.title');
      if (primaryCategoryTitle) {
        return [title, primaryCategoryTitle];
      }
      return title;
    }
  });
});
define('webapp/components/time-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    hourValues: Ember.computed(function () {
      var vals = ['', '20'];
      for (var i = 0; i < 24; i++) {
        vals.push(i.toString().padStart(2, '0'));
      }
      return vals;
    }),

    minuteValues: Ember.computed(function () {
      var vals = ['', '15'];
      for (var i = 0; i < 60; i += 5) {
        vals.push(i.toString().padStart(2, '0'));
      }
      for (var _i = 0; _i < 60; _i++) {
        vals.push(_i.toString().padStart(2, '0'));
      }
      return vals;
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var value = this.get('value');

      if (value) {
        var _value$split$map = value.split(':').map(function (x) {
          return parseInt(x);
        }),
            _value$split$map2 = _slicedToArray(_value$split$map, 2),
            hour = _value$split$map2[0],
            minute = _value$split$map2[1];

        if (!isNaN(hour)) {
          this.set('hourValue', hour.toString().padStart(2, '0'));
        }
        if (!isNaN(minute)) {
          this.set('minuteValue', minute.toString().padStart(2, '0'));
        }
      }
    },
    setTime: function setTime() {
      var hourValue = this.get('hourValue');
      var minuteValue = this.get('minuteValue');

      if (hourValue && minuteValue) {
        this.set('value', hourValue + ':' + minuteValue + ':00');
      } else {
        this.set('value', null);
      }
    },


    actions: {
      setHour: function setHour(hour) {
        this.set('hourValue', hour);
        this.setTime();
      },
      setMinute: function setMinute(minute) {
        this.set('minuteValue', minute);
        this.setTime();
      }
    }
  });
});
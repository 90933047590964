define('webapp/services/document-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    myStorage: Ember.inject.service(),
    router: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    languageService: Ember.inject.service(),

    parentFolder: null,
    isSubFolder: false,
    detail: {},

    afterPageModel: function afterPageModel(route, model) {
      var data = { folders: this.get('folders'), files: this.get('files') };
      this.set('detail.document', data);
      this.set('detail.route', route.routeName);

      return model;
    },
    setupPage: function setupPage(pageRoute, page, params) {
      var _this = this;

      var languageService = this.get('languageService');
      var translatedFullSlug = languageService.translateSlug(page);
      var path = decodeURIComponent(params.path);

      var folder = void 0;
      if (path === translatedFullSlug) {
        folder = page.options.folder;
        this.set('parentFolder', null);
      } else {
        var folderName = path.replace(translatedFullSlug, '');
        folder = page.options.folder + folderName;
        if (page.options.navigationFixed) {
          this.set('parentFolder', null);
        } else {
          var parentIndex = folder.lastIndexOf('/');
          this.set('parentFolder', folder.substring(0, parentIndex));
        }
      }
      var myStorage = this.get('myStorage');
      this.set('folder', folder);

      var options = page.options || {};

      return myStorage.media.ajaxPost({
        param: 'public_folder',
        data: {
          folder: folder,
          onlyFiles: options.view === 'flat',
          sortBy: options.sortBy,
          sortOrder: options.sortOrder
        }
      }).then(function (media) {
        if (options.view === 'navigate') {
          var folders = media.folders.map(function (folder) {
            folder.routingPath = folder.path.replace(options.folder + '/', '');

            if (folder.subFolders) {
              folder.subFolders.map(function (subfolder) {
                subfolder.routingPath = subfolder.path.replace(options.folder + '/', '');
                return subfolder;
              });
            }

            return folder;
          });

          if (options.navigationFixed && _this.get('folders')) {
            _this.setProperties({
              currentFolderDetail: media.currentFolderDetail,
              files: media.files
            });
          } else {
            _this.setProperties({
              currentFolderDetail: media.currentFolderDetail,
              files: media.files,
              folders: folders
            });
          }
        } else {
          _this.setProperties({
            currentFolderDetail: media.currentFolderDetail,
            files: media.files,
            folders: []
          });
        }
        _this.set('currentFolder', folder.substring(folder.lastIndexOf('/') + 1));

        return media;
      });
    },
    getBreadcrumbs: function getBreadcrumbs(breadCrumbs) {
      var baseRoute = this.get('cmsService.dynamicPageRoutes.documentContainer');
      var fullSlug = this.get('folder');
      var slugArray = fullSlug.split('/');
      slugArray.shift();

      var addFolders = slugArray.map(function (x, idx) {
        return {
          route: 'page',
          title: x,
          routeParams: baseRoute + '/' + slugArray.slice(0, idx + 1).join('/')
        };
      });

      return breadCrumbs.concat(addFolders);
    }
  });
});
define('webapp/components/custom-search', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    items: [],
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    languageService: Ember.inject.service(),
    showFilters: Ember.computed.alias('model.options.showFilters'),
    showAll: false,

    selectedCategories: [],
    selectedDateRange: null,
    calendarCenter: null,

    init: function init() {
      this._super.apply(this, arguments);

      var calendarCenter = new Date();
      this.set('calendarCenter', calendarCenter); //calendarCenter
    },


    nextMonthCenter: Ember.computed('calendarCenter', function () {
      var calendarCenter = this.get('calendarCenter');
      var nextMonthCenter = new Date(calendarCenter);

      // january = 0 ... dezember = 11
      var nextMonth = nextMonthCenter.getMonth() + 1;
      if (nextMonth === 12) {
        nextMonth = 0;
        var nextYear = nextMonthCenter.getFullYear() + 1;
        nextMonthCenter.setFullYear(nextYear);
      }
      nextMonthCenter.setMonth(nextMonth);

      return nextMonthCenter;
    }),
    afterNextMonthCenter: Ember.computed('calendarCenter', function () {
      var calendarCenter = this.get('calendarCenter');
      var afterNextMonthCenter = new Date(calendarCenter);

      var nextMonth = afterNextMonthCenter.getMonth() + 2;
      if (nextMonth === 11) {
        nextMonth = 0;
        var nextYear = afterNextMonthCenter.getFullYear() + 1;
        afterNextMonthCenter.setFullYear(nextYear);
      }
      if (nextMonth === 12) {
        nextMonth = 1;
        var _nextYear = afterNextMonthCenter.getFullYear() + 1;
        afterNextMonthCenter.setFullYear(_nextYear);
      }
      afterNextMonthCenter.setMonth(nextMonth);

      return afterNextMonthCenter;
    }),

    firstSearchResult: null,

    fetchEntries: function fetchEntries(categoryIds, options) {
      var _this = this;

      var selectedDateRange = this.get('selectedDateRange');

      var query = {
        itemTypes: options.type,
        size: options.size,
        tagFilter: options.tags,
        sort: options.sortField,
        sortOrder: options.sortOrder,
        categoryIds: categoryIds.length ? categoryIds : undefined,
        aggregations: !!options.showFilters
      };

      if (selectedDateRange) {
        if (selectedDateRange.start) {
          query.startDate = selectedDateRange.start.toISOString();
        }
        if (selectedDateRange.end) {
          query.stopDate = selectedDateRange.end.toISOString();
        }
      }

      var myStorage = this.get('myStorage');
      return myStorage.search.ajaxPost({
        data: query,
        query: { lang: this.get('languageService.selectedLanguage') }
      }).then(function (res) {
        if (!_this.isDestroyed) {
          if (!_this.firstSearchResult) {
            // only for daycounts
            _this.set('firstSearchResult', res.hits);
          }
          return {
            items: res.hits,
            aggs: res.aggs
          };
        }
      });
    },
    fetchArticles: function fetchArticles(categoryIds, options) {
      var _this2 = this;

      var filter = [];

      if (categoryIds.length) {
        filter.push({
          terms: {
            'categories._id': categoryIds
          }
        });
      }

      if (options.tags && options.tags.length) {
        filter.push({
          terms: {
            tags: options.tags
          }
        });
      }

      var sortField = options.sortField;
      if (options.sortField === 'alphabetical') {
        sortField = 'title.keyword';
      } else {
        sortField = options.sortField;
      }

      var sortOrder = options.sortOrder || 'asc';

      var myStorage = this.get('myStorage');
      return myStorage.article.ajaxPost({
        param: 'search_public',
        data: {
          allFields: false,
          aggregations: !!options.showFilters,
          perPage: options.size,
          sortField: sortField,
          sortOrder: sortOrder,
          filter: filter.length ? filter : undefined
        },
        query: { lang: this.get('languageService.selectedLanguage') }
      }).then(function (res) {
        if (!_this2.isDestroyed) {
          res.model.forEach(function (item) {
            if (item.fields) {
              item.fields = JSON.parse(item.fields);
            }
          });
          return {
            items: res.model.map(function (x) {
              return _extends({}, x, { itemType: 'article' });
            }),
            aggs: res.aggs
          };
        }
      });
    },
    search: function search() {
      var _this3 = this;

      var options = this.get('options');

      var selectedCategories = this.get('selectedCategories');

      var categoryIds = [];

      if (options.categories && options.categories.length) {
        categoryIds = options.categories;
      }

      if (selectedCategories && selectedCategories.length) {
        categoryIds = categoryIds.concat(selectedCategories.map(function (x) {
          return x._id;
        }));
      }

      var p = void 0;
      if (options.type === 'article') {
        p = this.fetchArticles(categoryIds, options);
      } else {
        p = this.fetchEntries(categoryIds, options);
      }
      p.then(function (res) {
        return _this3.setProperties(res);
      });
    },


    dayCounts: Ember.computed('firstSearchResult', function () {
      var firstSearchResult = this.get('firstSearchResult');
      if (!firstSearchResult) {
        return {};
      }

      var items = firstSearchResult.filter(function (item) {
        return item.startDate;
      });

      var daysMap = {};

      items.forEach(function (item) {
        var startDate = new Date(item.startDate);
        var stopDate = new Date(item.stopDate || item.startDate);
        var currentDate = startDate;

        while (currentDate.getTime() <= stopDate.getTime()) {
          var dayKey = (0, _moment.default)(currentDate).format('YYYY-MM-DD');

          if (daysMap[dayKey]) {
            daysMap[dayKey] += 1;
          } else {
            daysMap[dayKey] = 1;
          }

          currentDate = (0, _moment.default)(currentDate).add(1, 'day').toDate();
        }
      });

      return daysMap;
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var showAll = this.get('model.options.onlyFutureEvents');
      this.set('showAll', !showAll);
      this.search();
    },


    actions: {
      categoriesChanged: function categoriesChanged(cats) {
        this.set('selectedCategories', cats);
        this.search();
      },
      selectDateRange: function selectDateRange(range) {
        this.set('selectedDateRange', range);
        this.search();
      },
      clearDateRange: function clearDateRange() {
        this.set('selectedDateRange', null);
        this.search();
      },
      showAll: function showAll() {
        this.toggleProperty('showAll');
        this.search();
      }
    }
  });
});
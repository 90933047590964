define('webapp/components/search/search-tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    searchService: Ember.inject.service(),

    actions: {
      toggleTag: function toggleTag(tag) {
        var selectedTags = this.get('selectedTags');
        var newSelectedTags = [];

        if (selectedTags.indexOf(tag) >= 0) {
          newSelectedTags = selectedTags.filter(function (_tag) {
            return tag !== _tag;
          });
        } else {
          newSelectedTags = selectedTags.concat(tag);
        }

        this.setSelectedTags && this.setSelectedTags(newSelectedTags);
      }
    }
  });
});
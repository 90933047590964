define('webapp/components/share-links', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    fastboot: Ember.inject.service(),

    fullURL: Ember.computed('router.currentURL', function () {
      if (this.get('fastboot.isFastBoot')) {
        var siteUrl = this.get('cachedData.cachedSite.url');
        var currentURL = this.get('router.currentURL');
        return siteUrl + currentURL;
      } else {
        return window.location.href;
      }
    }),

    fullURLEncoded: Ember.computed('fullURL', function () {
      return encodeURIComponent(this.get('fullURL'));
    })
  });
});
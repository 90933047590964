define('webapp/controllers/account/subscriptions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    selectedSubscription: null,

    actions: {
      changeSelection: function changeSelection(subscription) {
        this.set('selectedSubscription', subscription);
      }
    }
  });
});
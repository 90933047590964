define('webapp/components/widgets/widget-flyout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var widgetIds = []; /* global UIkit */

  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    isOpen: false,
    selectedTab: 1,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var widgetId = this.get('data._id');
      var noTransitionAnimation = this.get('data.options.noTransitionAnimation');
      var flyoutFired = widgetIds.includes(widgetId);
      if (!flyoutFired) {
        widgetIds.push(widgetId);
      }

      this.setFlyoutContentIds();

      if (!flyoutFired || !noTransitionAnimation) {
        Ember.run.later(function () {
          _this.set('isOpen', true);
          _this.setAndSelectTab(_this.get('selectedTab'));
        }, 10);

        this._laterCb = Ember.run.later(function () {
          _this.set('isOpen', false);
        }, 3000);
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this._laterCb) {
        Ember.run.cancel(this._laterCb);
      }
    },
    setFlyoutContentIds: function setFlyoutContentIds() {
      var flyoutContent = this.element.querySelector('.flyout-content');
      if (flyoutContent) {
        var flyoutContentIds = [];
        var children = flyoutContent.children;
        for (var i = 0; i < children.length; i++) {
          var child = children[i];
          flyoutContentIds.push(child.id);
        }
        this.set('flyoutContentIds', flyoutContentIds);
      } else {
        this.set('flyoutContentIds', []);
      }
    },
    selectUikitTab: function selectUikitTab(tabIdx) {
      var _this2 = this;

      Ember.run.schedule('afterRender', function () {
        var flyoutContent = _this2.element.querySelector('.flyout-content');
        if (flyoutContent) {
          var children = flyoutContent.children;
          for (var i = 0; i < children.length; i++) {
            var child = children[i];
            if (i === tabIdx) {
              child.classList.add('uk-active');
            } else {
              child.classList.remove('uk-active');
            }
          }
        }
        //check if active tab has scroll class for scroll-to event an take first class as target-ID
        if (flyoutContent.children[tabIdx].classList.contains('scroll')) {
          var scrollClass = flyoutContent.children[tabIdx].classList.item(0);
          UIkit.scroll('#' + scrollClass, { offset: 300 }).scrollTo('#' + scrollClass);
        }
      });
    },
    setAndSelectTab: function setAndSelectTab(tab) {
      this.set('selectedTab', tab);
      this.selectUikitTab(tab - 1);
    },


    actions: {
      toggleIsOpen: function toggleIsOpen() {
        this.toggleProperty('isOpen');
      },
      selectTab: function selectTab(tab) {
        this.toggleProperty('isOpen');
        this.setAndSelectTab(tab);
      },
      selectTabOnly: function selectTabOnly(tab) {
        this.setAndSelectTab(tab);
        if (this._laterCb) {
          Ember.run.cancel(this._laterCb);
        }
      },
      setIsOpen: function setIsOpen(value) {
        this.set('isOpen', value);
      }
    }
  });
});
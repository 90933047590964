define('webapp/helpers/concat-if', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.concatIf = concatIf;
  function concatIf(params /*, hash*/) {
    return params[2] ? params[0].concat(params[1]) : params[0];
  }

  exports.default = Ember.Helper.helper(concatIf);
});
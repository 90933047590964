define('webapp/helpers/kw-to-ps-round', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var kw = parseFloat(params[0]);
    var ps = kw * 1.35962;
    var digits = params[1] || 0;

    try {
      return ps.toFixed(digits);
    } catch (e) {
      return number;
    }
  });
});
define('webapp/components/entry-transactions', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    transactions: [],
    dreampoints: _environment.default.dreampoints,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var entry = this.get('entry');
      var myStorage = this.get('myStorage');
      myStorage.transactions.ajaxPost({
        param: 'list',
        data: {
          entry: entry
        }
      }).then(function (res) {
        _this.set('transactions', res);
      });
    }
  });
});
define('webapp/routes/request-password-reset', ['exports', 'webapp/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    userProxy: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    i18n: Ember.inject.service(),

    model: function model() {
      return this.get('cmsService').getAndSetActivePage('login');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('success', false);
    },
    getBreadcrumbs: function getBreadcrumbs() {
      return [{
        route: 'login',
        title: 'Login'
      }];
    },


    actions: {
      requestPasswordReset: function requestPasswordReset(email) {
        var _this = this;

        var userStorage = this.get('myStorage.user');
        var message = this.get('message');
        var cachedSite = this.get('cachedData.cachedSite');

        if (!email) {
          return message.info('Bitte geben Sie eine E-Mail Addresse an.');
        }

        userStorage.ajaxPost({
          param: 'request_password_reset',
          data: { email: email, siteId: cachedSite._id }
        }).then(function () {
          message.infoT('user.ui.requestPasswordSuccess');
          _this.controller.set('success', true);
        }).catch(function (err) {
          console.error(err);
          message.errorT('user.ui.requestPasswordError');
        });
      }
    }
  });
});
define('webapp/components/account/my-files', ['exports', 'webapp/settings/media-extensions', 'webapp/utils/find-by', 'webapp/utils/file-type'], function (exports, _mediaExtensions, _findBy, _fileType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function mapFile(file) {
    return Ember.assign({}, file, { type: (0, _fileType.default)(file.path) });
  }

  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    i18n: Ember.inject.service(),

    timestamp: Ember.computed(function () {
      return Date.now();
    }),

    multiSelect: false,
    files: [],
    isExternal: true,
    showSharedFiles: true,
    selectedFiles: [],
    isDeletable: false,
    showVideos: false,
    showDocuments: false,
    showDeleteButton: false,
    filterPreviewImages: true,

    shownExtensions: Ember.computed('showVideos', function () {
      var extensions = _mediaExtensions.default.IMAGE_EXTENSIONS;

      if (this.get('showVideos')) {
        extensions = extensions.concat(_mediaExtensions.default.VIDEO_EXTENSIONS);
      }

      if (this.get('showDocuments')) {
        extensions = extensions.concat(_mediaExtensions.default.DOCUMENT_EXTENSIONS);
      }

      return extensions;
    }),

    showUpload: true,

    uploadFolder: Ember.computed('userProxy._id', 'overrideUploadFolder', function () {
      var overrideUploadFolder = this.get('overrideUploadFolder');
      if (overrideUploadFolder) {
        return overrideUploadFolder;
      }
      var userId = this.get('userProxy._id');
      return 'user/' + userId;
    }),

    loadFiles: function loadFiles() {
      var _this = this;

      var myStorage = this.get('myStorage');
      var uploadFolder = this.get('uploadFolder');
      var message = this.get('message');

      return myStorage.mediaAdmin.ajaxGet({ param: 'list/' + uploadFolder, query: { showMetadata: true } }).then(function (files) {
        if (_this.get('filterPreviewImages')) {
          files = files.filter(function (file) {
            return file.isPreviewImage === false;
          });
        }
        _this.set('files', files.map(mapFile));
        return files;
      }).catch(function (err) {
        message.error('Fehler beim laden', err.payload && err.payload.message);
      });
    },


    displayedFiles: Ember.computed('files', function () {
      var files = this.get('files');
      var shownExtensions = this.get('shownExtensions');
      return files.filter(function (file) {
        return shownExtensions.indexOf(file.extension) >= 0;
      });
    }),

    displayedImageFiles: Ember.computed('displayedFiles', function () {
      var displayedFiles = this.get('displayedFiles');
      return displayedFiles.filter(function (file) {
        return file.type === 'image';
      });
    }),

    displayedVideoFiles: Ember.computed('displayedFiles', function () {
      var displayedFiles = this.get('displayedFiles');
      return displayedFiles.filter(function (file) {
        return file.type === 'video';
      });
    }),

    displayedDocumentFiles: Ember.computed('displayedFiles', function () {
      var displayedFiles = this.get('displayedFiles');
      return displayedFiles.filter(function (file) {
        return file.type === 'document';
      });
    }),

    displayedAudioFiles: Ember.computed('displayedFiles', function () {
      var displayedFiles = this.get('displayedFiles');
      return displayedFiles.filter(function (file) {
        return file.type === 'audio';
      });
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this.loadFiles();
      this.set('selectedFiles', []);
    },


    actions: {
      uploadCompleted: function uploadCompleted(fileIds) {
        var _this2 = this;

        this.loadFiles().then(function (files) {
          var uploadedFiles = files.filter(function (x) {
            return fileIds.includes(x._id);
          });
          _this2.sendAction('afterUpload', uploadedFiles);
        });
      },
      updateStatus: function updateStatus(status) {
        this.set('uploadStatus', status);
      },
      selectFile: function selectFile(file) {
        var multiSelect = this.get('multiSelect');
        if (multiSelect) {
          var selectedFiles = this.get('selectedFiles');
          var selectedFilesPaths = selectedFiles.map(function (x) {
            return x.path;
          });
          if (selectedFilesPaths.indexOf(file.path) === -1) {
            selectedFiles.pushObject(file);
          } else {
            selectedFiles.removeObject((0, _findBy.default)(selectedFiles, 'path', file.path));
          }
          this.sendAction('updateSelectedFiles', selectedFiles);
        } else {
          this.sendAction('selectFile', file);
        }
      },
      showDeleteModal: function showDeleteModal(file) {
        var _this3 = this;

        var myStorage = this.get('myStorage');
        var i18n = this.get('i18n');
        var isFastBoot = this.get('fastboot.isFastBoot');
        myStorage.mediaAdmin.ajaxGet({ param: 'getRelations/' + file._id }).then(function (files) {
          var modalMessage = files.message ? i18n.t('user.ui.dataUsage').toString() + files.message + i18n.t('user.ui.dataDelete').toString() : i18n.t('user.ui.dataUsageNone').toString();

          if (!isFastBoot) {
            UIkit.modal.confirm(modalMessage.toString(), {
              labels: {
                cancel: i18n.t('buttons.cancel').toString(),
                ok: i18n.t('buttons.ok').toString()
              }
            }).then(function () {
              myStorage.mediaAdmin.ajaxDelete({ param: file._id }).then(function () {
                _this3.loadFiles();
              });
            });
          }
        });
      },
      showEditModal: function showEditModal(file) {
        this.set('editFile', file);
        this.set('showingEditModal', true);
      },
      closeEditModal: function closeEditModal() {
        this.set('showingEditModal', false);
      },
      afterTransform: function afterTransform() {
        this.set('timestamp', Date.now());
        this.set('showingEditModal', false);
        this.loadFiles();
      },
      openVideoModal: function openVideoModal(file) {
        if (!this.get('isExternal')) {
          this.set('modalVideo', file);
          this.set('showVideoModal', true);
        }
      },
      closeModal: function closeModal(modalName) {
        this.set(modalName, false);
      }
    }
  });
});
define('webapp/routes/account/entries/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    i18n: Ember.inject.service(),
    fastboot: Ember.inject.service(),

    model: function model() {
      return this.get('userProxy').fetchOwnEntries();
    },


    actions: {
      deleteItem: function deleteItem(item) {
        var _this = this;

        var myStorage = this.get('myStorage');
        var i18n = this.get('i18n');
        var isFastBoot = this.get('fastboot.isFastBoot');
        if (!isFastBoot) {
          UIkit.modal.confirm(i18n.t('application.ui.confirmDeleteEntry', { title: item.title }).toString(), {
            labels: {
              cancel: i18n.t('buttons.cancel').toString(),
              ok: i18n.t('buttons.ok').toString()
            }
          }).then(function () {
            myStorage.entryAdmin.ajaxDelete({ param: item._id }).then(function (res) {
              var message = _this.get('message');
              if (res.message) {
                message.info(res.message);
              }
              Ember.run.later(function () {
                _this.refresh();
              }, 500);
            });
          });
        }
      }
    }
  });
});
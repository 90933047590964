define('webapp/components/content-builder-view/elements/card', ['exports', 'webapp/components/content-builder-view/elements/empty'], function (exports, _empty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _empty.default.extend({
    classNameBindings: ['cardTypeClass'],

    attributeBindings: ['href'],

    href: Ember.computed('options.link', function () {
      var cardLink = this.get('options.link');
      if (cardLink === '') return;
      return cardLink;
    }),

    cardTypeClass: Ember.computed('options.cardStyle', 'options.cardBox', 'options.cardHover', function () {
      var cardStyleClass = 'uk-panel' + this.get('options.cardStyle') ? 'uk-panel-box-' + this.get('options.cardStyle') : '';
      var cardBoxClass = this.get('options.cardBox') ? ' uk-panel-box' : '';
      var cardHoverClass = this.get('options.cardHover') ? ' uk-panel-hover' : '';
      return cardStyleClass + cardBoxClass + cardHoverClass;
    })
  });
});
define('webapp/services/template-settings', ['exports', 'webapp/config/environment', 'webapp/utils/assign-deep'], function (exports, _environment, _assignDeep) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getSelectedTheme() {
    if (window.localStorage) {
      var theme = localStorage.getItem('selected-theme') || 'dark';
      return theme;
    } else {
      return 'dark';
    }
  }

  function saveTheme(theme) {
    if (!window.localStorage) {
      return;
    }

    localStorage.setItem('selected-theme', theme);
  }

  exports.default = Ember.Service.extend({
    cmsService: Ember.inject.service(),
    router: Ember.inject.service(),
    selectedTheme: getSelectedTheme(),
    fastboot: Ember.inject.service(),

    // TODO move to viewservice
    currentPageType: Ember.computed.alias('cmsService.activePage.type'),

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties(_environment.default.templateSettings);
      Ember.run.schedule('afterRender', this, this.setThemeClass);
    },
    setThemeClass: function setThemeClass() {
      if (!this.get('fastboot.isFastBoot')) {
        var selectedTheme = this.get('selectedTheme');
        if (selectedTheme === 'dark') {
          window.document.body.classList.add('tm-dark');
        } else {
          window.document.body.classList.remove('tm-dark');
        }
      }
    },
    setTheme: function setTheme(theme) {
      this.set('selectedTheme', theme);
      this.setThemeClass();
      saveTheme(theme);
    },
    overrideTemplateSettings: function overrideTemplateSettings() {
      var settings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var baseSettings = JSON.parse(JSON.stringify(_environment.default.templateSettings));
      (0, _assignDeep.default)(baseSettings, settings);
      this.setProperties(baseSettings);
    },


    rootRoute: Ember.computed('router.currentRouteName', function () {
      var currentRouteName = this.get('router.currentRouteName');
      return currentRouteName.split('.')[0];
    }),

    getClass: function getClass(classKey) {
      var rootRoute = this.get('rootRoute');
      var currentPageType = this.get('currentPageType');

      var containerClass = void 0;

      if (currentPageType === 'home') {
        containerClass = this.get('pageType.index.' + classKey);
      } else if (rootRoute === 'page') {
        containerClass = this.get('pageType.' + currentPageType + '.' + classKey);
      } else {
        containerClass = this.get('pageType.' + rootRoute + '.' + classKey);
      }

      return containerClass;
    },


    currentContainerClass: Ember.computed('rootRoute', 'currentPageType', function () {
      return this.getClass('mainContainerClass');
    }),

    currentBlockClass: Ember.computed('rootRoute', 'currentPageType', function () {
      return this.getClass('mainBlockClass');
    })
  });
});
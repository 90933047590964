define('webapp/routes/application', ['exports', 'webapp/config/environment', 'webapp/initializers/error-logger'], function (exports, _environment, _errorLogger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getHostName() {
    var url = document.URL;
    if (url) {
      var split = url.split('/');
      return split[2];
    }
  }

  var ApplicationRoute = Ember.Route.extend({
    userProxy: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    languageService: Ember.inject.service(),
    searchService: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    gdprService: Ember.inject.service(),
    countService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    templateSettings: Ember.inject.service(),
    headData: Ember.inject.service(),
    moment: Ember.inject.service(),
    pushNotifications: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),

    queryParams: {
      site: {
        refreshModel: false
      }
    },

    isInitialized: false,

    setFastbootClass: function setFastbootClass() {
      if (!this.get('fastboot.isFastBoot')) {
        document.body.classList.remove('fastboot');
      }
    },
    setupLanguage: function setupLanguage() {
      var userProxy = this.get('userProxy');
      var languageService = this.get('languageService');
      var cachedData = this.get('cachedData');

      return cachedData.getSite().then(function (site) {
        languageService.setLanguageSettings(site);
        if (userProxy.language) {
          languageService.setLanguage(userProxy.language);
        } else {
          languageService.getNavigatorLanguage();
        }
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      // this is needed, because the error transition to triggers the hooks hook again
      if (this.isInitialized) {
        return;
      }

      this.isInitialized = true;

      this._super.apply(this, arguments);
      this.get('moment').changeLocale('de');
      var cmsService = this.get('cmsService');
      var userProxy = this.get('userProxy');
      var countService = this.get('countService');
      var shoppingCartService = this.get('shoppingCartService');

      this.setFastbootClass();
      this.set('siteTitle', model.site.title);

      if (_environment.default.pushNotifications) {
        var pushNotifications = this.get('pushNotifications');
        pushNotifications.requestPush();
        window.addEventListener('focus', function () {
          pushNotifications.closeNotifications();
        });
      }

      return Ember.RSVP.all([cmsService.setupDynamicPages(), userProxy.signIn().then(function () {
        return _this.setupLanguage();
      }), userProxy.fetchUserGroups()]).then(function () {
        if (!_this.get('fastboot.isFastBoot')) {
          shoppingCartService.fetchShoppingCart();
        }
        countService.refreshNotifications();
      });
    },
    maybeRedirect: function maybeRedirect(site) {
      var excludedSlugs = ['/login', '/account', '/azure-oauth', '/custom-login'];

      if (typeof localStorage === 'undefined') {
        return;
      }

      var hasAccessToken = Boolean(localStorage && localStorage.getItem('userfe'));
      var botRegex = /crawler|bot|spider|larbin|ABACHOBot|80legs|AddSugarSpiderBot|AnyApexBot|Baidu|B-l-i-t-z-B-O-T|BecomeBot|BillyBobBot|Bimbot|Arachmo|Accoona-AI-Agent|searchme\.com|Cerberian Drtrs|DataparkSearch|Covario-IDS|findlinks|holmes|htdig|ia_archiver|ichiro|igdeSpyder|L\.webis|LinkWalker|lwp-trivial|mabontland|Google|Mnogosearch|mogimogi|MVAClient|NetResearchServer|NewsGator|NG-Search|Nymesis|oegp|Pompos|PycURL|Qseero|SBIder|SBIder|ScoutJet|Scrubby|SearchSight|semanticdiscovery|silk|Snappy|Sqworm|StackRambler|Ask Jeeves\/Teoma|truwoGPS|voyager|VYU2|^updated|TinEye|webcollage|Yahoo|yoogliFetchAgent|^Zao/i;
      if (site.url === 'https://www.bodystreet.com' && site.geoipCountry === 'US' && navigator.userAgent && !botRegex.test(navigator.userAgent) && !hasAccessToken && !excludedSlugs.some(function (slug) {
        return location.pathname.includes(slug);
      })) {
        location.href = 'https://www.bodystreet.us/';
      }
    },
    model: function model(params) {
      var _this2 = this;

      var cmsService = this.get('cmsService');
      var siteOrHost = params.site;
      var fastboot = this.get('fastboot');
      var isFastBoot = fastboot.isFastBoot;

      if (!siteOrHost) {
        var host = void 0;
        if (isFastBoot) {
          host = fastboot.request.headers.get('x-host'); // this header is set in nginx config
          console.log('fastbootHost', host);
        } else {
          host = getHostName();
        }
        if (host && !host.startsWith('localhost')) {
          siteOrHost = host;
        }
      }

      return this.get('cachedData').getSite(siteOrHost).then(function (site) {
        _this2.maybeRedirect(site);
        // TODO get populate sites for menus from site.pages
        if (site.templateSettings) {
          var templateSettings = _this2.get('templateSettings');
          templateSettings.overrideTemplateSettings(site.templateSettings);
        }

        if (_this2.get('gdprService.gdprStatus') === 'accepted') {
          cmsService.setSiteCodes(site);
        }
        cmsService.setSessionInfo(site);

        if (!isFastBoot) {
          document.body.classList.add(site.shortTitle && site.shortTitle.replace(/\s+/, '-'));
        }

        _this2.get('cmsService').set('menus', site.menus);
        return { site: site };
      });
    },
    title: function title(tokens) {
      var cachedSite = this.get('cachedData.cachedSite');
      var cachedSiteOptions = cachedSite && cachedSite.options || {};

      var titleSeparator = cachedSiteOptions.titleSeparator || ' - ';
      var hidePageTitle = cachedSiteOptions.hidePageTitle || false;

      var siteTitle = this.get('siteTitle');

      var activePageType = this.get('cmsService.activePage.type');

      if (hidePageTitle && activePageType !== 'productContainer') {
        return siteTitle;
      } else {
        var elems = tokens.slice();
        elems.push(siteTitle);
        return elems.join(titleSeparator);
      }
    }
  });

  var lastErrorTimestamp = 0;

  if (_environment.default.handleErrors) {
    ApplicationRoute.reopen({
      actions: {
        error: function error(err) {
          var _this3 = this;

          var now = Date.now();
          var localLastError = lastErrorTimestamp;
          lastErrorTimestamp = now;
          if (localLastError + 1000 > now) {
            return;
          }

          (0, _errorLogger.logError)(err);
          var languageService = this.get('languageService');

          var url = this.get('router.url');
          var languages = languageService.languages;

          var urlLanguageMatch = url.match(/^\/(\w+?)\/.*/);
          var urlLanguage = urlLanguageMatch && urlLanguageMatch[1];

          if (urlLanguage && languages.includes(urlLanguage)) {
            languageService.setLanguage(urlLanguage);
          }

          this.get('cachedData').getSite().then(function (site) {
            var errorPage = site.pages.find(function (page) {
              return page.type === 'error';
            });
            if (errorPage) {
              _this3.replaceWith('page', languageService.translateSlug(errorPage));
            }
          });
        }
      }
    });
  }

  exports.default = ApplicationRoute;
});
define('webapp/components/account/opening-edit/opening-entry', ['exports', 'webapp/models/opening-config', 'webapp/utils/find-by'], function (exports, _openingConfig, _findBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    entryOptionChanged: 'entryOptionChanged',
    config: _openingConfig.default,

    entryConfig: function () {
      return (0, _findBy.default)(_openingConfig.default, 'option', this.get('entry.option'));
    }.property('entry.option'),

    _validate: Ember.observer('entry.opening_from', function () {
      var entry = this.get('entry');
    }),

    actions: {
      mainOptionChanged: function mainOptionChanged(value) {
        this.set('entry.option', value.option);
        this.sendAction('entryOptionChanged', this.get('entry'));
      },
      optionChanged: function optionChanged(newValue, option) {
        var entry = this.get('entry');
        entry[option.id] = newValue.id;
        this.set('entry', entry);
      }
    }
  });
});
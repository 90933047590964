define('webapp/controllers/account/jobs/edit', ['exports', 'webapp/utils/slugify'], function (exports, _slugify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    _selectedLanguage: 'de', // language for translated fields
    forcePermission: true,

    // auto generate slug, if no slug was given
    generateSlug: function generateSlug(model) {
      var title = Ember.get(model, 'title');

      if (title) {
        if (!Ember.get(model, 'slug')) {
          Ember.set(model, 'slug', (0, _slugify.default)(title));
        }
      }
    },


    availableLanguages: Ember.computed('model.categories', function () {
      var categories = this.get('model.categories');

      // TODO hack for bodystreet, better to save them on user or usergroup
      if (categories.some(function (cat) {
        return cat === '5f5615fbe3dd66054dd93a46';
      })) {
        // Italien
        return ['de', 'it'];
      } else if (categories.some(function (cat) {
        return cat === '60b7738bcbc4daa425bb6aa2';
      })) {
        // Schweiz
        return ['de', 'it', 'fr'];
      }
    }),

    // needed, because if a studio with other availableLanguages is selected, available languages can change and the selected language is wrong
    selectedLanguage: Ember.computed('_selectedLanguage', 'availableLanguages', function () {
      var _selectedLanguage = this.get('_selectedLanguage');
      var availableLanguages = this.get('availableLanguages');
      if (availableLanguages && availableLanguages.includes(_selectedLanguage)) {
        return _selectedLanguage;
      }
      return 'de';
    }),

    actions: {
      generateSlugOnFocusOut: function generateSlugOnFocusOut(model) {
        this.generateSlug(model);
      },
      selectLanguage: function selectLanguage(language) {
        this.set('_selectedLanguage', language);
        var model = this.get('model');

        if (!Ember.get(model, 'translations.' + language)) {
          Ember.set(model, 'translations.' + language, {
            title: '',
            subtitle: '',
            description: '',
            metaDescription: ''
          });
        }
      },
      toggleEntry: function toggleEntry(entry) {
        var modelEntries = this.get('model.entries');
        if (modelEntries.some(function (x) {
          return Ember.get(x, 'item._id') === entry._id;
        })) {
          this.set('model.entries', modelEntries.filter(function (x) {
            return Ember.get(x, 'item._id') !== entry._id;
          }));
        } else {
          this.set('model.entries', [
          // only single select
          // ...modelEntries,
          { item: entry, direction: 1, order: modelEntries.length }]);
        }
      }
    }
  });
});
define('webapp/utils/product-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getPlaceholderField = getPlaceholderField;
  exports.getUnit = getUnit;
  var placeHolderRe = /^\{\{(.*)\}\}$/;

  var unitMapping = {
    sellingUnit: 'scaleUnit'
  };

  function getPlaceholderField(fieldDesc) {
    if (fieldDesc.placeholder) {
      var pMatch = fieldDesc.placeholder.match(placeHolderRe);
      if (pMatch) {
        return pMatch[1];
      }
    }
  }

  function getUnit(product, placeholderField) {
    if (placeholderField && unitMapping[placeholderField]) {
      return product[unitMapping[placeholderField]];
    }
  }
});
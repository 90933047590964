define('webapp/controllers/account/messages/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),

    expandedItem: null,
    unapprovedJoiner: [],

    actions: {
      toggleExpand: function toggleExpand(item) {
        var expandedItem = this.get('expandedItem');
        if (expandedItem === item) {
          this.set('expandedItem', null);
        } else {
          this.set('expandedItem', item);
        }
      },
      approveJoiner: function approveJoiner(joiner) {
        var _this = this;

        var myStorage = this.get('myStorage');
        var message = this.get('message');
        var user = this.get('userProxy._id');

        myStorage.joiner.ajaxPut({ param: 'unapproved', data: { joiner: joiner } }).then(function (res) {
          message.infoT('entries.entry.ui.added');
          myStorage.notification.ajaxGet().then(function (res) {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = res[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var notifications = _step.value;

                notifications.isMyDream = notifications.entry.owner === user;
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            _this.set('notifications', res);
          });
        });
      }
    }
  });
});
define('webapp/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    viewService: Ember.inject.service(),
    router: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    languageService: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    userProxy: Ember.inject.service(),

    site: null,
    queryParams: ['site'],

    showEditor: false,
    offcanvasActive: false,

    actions: {
      toggleMobileSearchbar: function toggleMobileSearchbar() {
        this.toggleProperty('viewService.showSearchbar');
      },
      toggleEditor: function toggleEditor() {
        this.toggleProperty('showEditor');
        var body = document.body;
        if (this.get('showEditor')) {
          body.classList.add('editor-open');
        } else {
          body.classList.remove('editor-open');
        }
      },
      offcanvasToggled: function offcanvasToggled(value) {
        this.set('offcanvasActive', value);
      }
    }
  });
});
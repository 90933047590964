define('webapp/components/shopping/additional-detail', ['exports', 'webapp/utils/price-calculations'], function (exports, _priceCalculations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userProxy: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),
    shoppingCart: Ember.computed.alias('shoppingCartService.shoppingCart'),
    requestedCountryCode: Ember.computed.alias('taxData.requestedCountryCode'),
    countryCode: Ember.computed.alias('taxData.countryCode'),
    taxRates: Ember.computed.alias('taxData.taxRates.vats'),

    _unitPriceChanged: function _unitPriceChanged(i, additional) {
      additional.isCalculated = false;
      additional.percentage = 0;
      var additionalDetails = this.get('additionalDetails');
      for (var j = 0; j < additionalDetails.length; j++) {
        if (i === j) {
          Ember.setProperties(additionalDetails[j], additional);
        }
      }
    },
    _recalculatePrice: function _recalculatePrice() {
      var index = this.get('index');
      var taxRate = this.get('model.taxRate');
      var finalTaxRate = (0, _priceCalculations.calcFinalTaxes)(taxRate, 10);
      var quantity = this.get('model.quantity');
      var userProxy = this.get('userProxy');
      var unitPriceGross = 0;
      var unitPriceNet = 0;

      if (userProxy.isVatFreeBusiness) {
        unitPriceNet = this.get('model.unitPriceNet');
        unitPriceGross = (0, _priceCalculations.calcGrossPrice)(unitPriceNet, finalTaxRate);
      } else {
        unitPriceGross = this.get('model.unitPriceGross');
        unitPriceNet = (0, _priceCalculations.calcNetPrice)(unitPriceGross, finalTaxRate);
      }

      var totalGross = quantity * unitPriceGross;
      var totalNet = quantity * unitPriceNet;
      var data = {
        quantity: quantity,
        taxRate: taxRate,
        unitPriceGross: unitPriceGross,
        unitPriceNet: unitPriceNet,
        totalGross: totalGross,
        totalNet: totalNet
      };

      this._unitPriceChanged(index, data);
    },


    actions: {
      remove: function remove() {
        var i = this.get('index');
        var oldAdditionalDetails = this.get('additionalDetails');
        var newAdditionalDetails = [];
        for (var j = 0; j < oldAdditionalDetails.length; j++) {
          if (i !== j) {
            newAdditionalDetails.push(oldAdditionalDetails[j]);
          }
        }
        this.set('additionalDetails', newAdditionalDetails);
      },
      unitPriceNetChanged: function unitPriceNetChanged(unitPriceNet) {
        this.set('model.unitPriceNet', unitPriceNet);
        Ember.run.debounce(this, this._recalculatePrice, 500);
      },
      unitPriceGrossChanged: function unitPriceGrossChanged(unitPriceGross) {
        this.set('model.unitPriceGross', unitPriceGross);
        Ember.run.debounce(this, this._recalculatePrice, 500);
      },
      quantityChanged: function quantityChanged(quantity) {
        this.set('model.quantity', quantity);
        Ember.run.debounce(this, this._recalculatePrice, 500);
      },
      vatRateChanged: function vatRateChanged(taxRate) {
        this.set('model.taxRate', taxRate);
        Ember.run.debounce(this, this._recalculatePrice, 500);
      }
    }
  });
});
define('webapp/components/detail/event-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Component.extend((_EmberComponent$exte = {
    myStorage: Ember.inject.service(),
    i18n: Ember.inject.service(),
    userProxy: Ember.inject.service()
  }, _defineProperty(_EmberComponent$exte, 'myStorage', Ember.inject.service()), _defineProperty(_EmberComponent$exte, 'message', Ember.inject.service()), _defineProperty(_EmberComponent$exte, 'cmsService', Ember.inject.service()), _defineProperty(_EmberComponent$exte, 'eventContainer', Ember.inject.service()), _defineProperty(_EmberComponent$exte, 'seatCount', 1), _defineProperty(_EmberComponent$exte, 'didReceiveAttrs', function didReceiveAttrs() {
    if (!this.isDestroyed) {
      this.set('userAvatar', this.get('userProxy.image'));
      this.set('showMessageModal', false);
    }
  }), _defineProperty(_EmberComponent$exte, 'fetchRemainingSeats', function fetchRemainingSeats() {
    var _this = this;

    var myStorage = this.get('myStorage');

    return myStorage.event.ajaxGet({ param: 'remaining_seats/' + this.get('detail._id') }).then(function (res) {
      _this.set('detail.remainingSeats', res.remainingSeats);
    });
  }), _defineProperty(_EmberComponent$exte, 'seatSelection', Ember.computed('detail.remainingSeats', function () {
    var seatRange = [];
    var remainingSeats = Math.min(this.get('detail.remainingSeats'), 4);

    for (var i = 1; i <= remainingSeats; i++) {
      seatRange.push(i);
    }

    return seatRange;
  })), _defineProperty(_EmberComponent$exte, 'actions', {
    sendMessage: function sendMessage() {
      this.set('showMessageModal', true);
    },
    closeMessageModal: function closeMessageModal() {
      this.set('showMessageModal', false);
    },
    setSeatCount: function setSeatCount(seatCount) {
      this.set('seatCount', seatCount);
    },
    onFormSuccess: function onFormSuccess() {
      this.fetchRemainingSeats();
    },
    onFormError: function onFormError(err) {
      var message = this.get('message');
      var i18n = this.get('i18n');
      if (err.payload.message === 'NOT_ENOUGH_SEATS') {
        message.error('Nicht mehr genügend Plätze');
      } else {
        message.error(i18n.t('forms.ui.formErr').toString() + '\n' + err.payload.message);
      }
      this.fetchRemainingSeats();
    }
  }), _EmberComponent$exte));
});
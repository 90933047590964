define('webapp/components/campaign/promotion-form', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var mandatoryFields = ['name', 'email', 'date'];

  exports.default = Ember.Component.extend({
    cachedData: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    router: Ember.inject.service(),
    languageService: Ember.inject.service(),
    privacyAccepted: false,
    isSubmitted: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('data', {});
    },


    canSubmit: Ember.computed.apply(undefined, ['isSubmitted', 'privacyAccepted'].concat(_toConsumableArray(mandatoryFields.map(function (x) {
      return 'data.' + x;
    })), [function () {
      if (this.get('isSubmitted') || !this.get('privacyAccepted')) {
        return false;
      }

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = mandatoryFields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var field = _step.value;

          if (!this.get('data.' + field)) {
            return false;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return true;
    }])),

    actions: {
      submit: function submit() {
        var _this = this;

        var myStorage = this.get('myStorage');
        var message = this.get('message');

        var data = this.get('data');
        var entryId = this.get('entry._id');

        var formData = {
          entry: entryId,
          data: data
        };

        this.set('isSubmitted', true);

        myStorage.entry.ajaxPost({
          param: 'training_form',
          data: formData
        }).then(function () {
          message.infoT('forms.ui.formSent');

          var pages = _this.get('cachedData.cachedSite.pages');
          var thanksPage = (0, _treeUtils.findInTree)(pages, function (x) {
            return x.internalName === 'entry-thanks';
          });
          if (thanksPage) {
            var router = _this.get('router');
            var languageService = _this.get('languageService');
            var translatedSlug = languageService.translateSlug(thanksPage);
            var slugSplit = translatedSlug.split('/');
            var slugWithEntry = [].concat(_toConsumableArray(slugSplit.slice(0, slugSplit.length - 1)), [_this.get('entry.slug'), slugSplit[slugSplit.length - 1]]).join('/');
            Ember.run.next(function () {
              return router.transitionTo('page', slugWithEntry);
            });
          }
        }).catch(function (err) {
          message.error(err.payload.message);
        });
      }
    }
  });
});
define('webapp/services/comparison-container', ['exports', 'webapp/services/abstract-container'], function (exports, _abstractContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractContainer.default.extend({
    containerName: 'comparisonContainer',

    setupPage: function setupPage() {
      var _this = this;

      var myStorage = this.get('myStorage');
      var sessionId = this.get('cmsService.sessionId');
      var lang = this.get('languageService.selectedLanguage');
      var query = { sessionId: sessionId, lang: lang };
      var opts = {
        path: 'compare',
        query: query
      };

      return myStorage.favorite.ajaxGet(opts).then(function (detail) {
        _this.set('detail', detail);
      });
    }
  });
});
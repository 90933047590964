define('webapp/components/widgets/widget-shopping-cart', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    shoppingCartService: Ember.inject.service(),
    router: Ember.inject.service(),

    actions: {
      openShoppingCart: function openShoppingCart() {
        var hasShoppingWidgetOnOffcanvas = this.get('shoppingCartService').getHasShoppingWidgetOnOffcanvas();

        if (hasShoppingWidgetOnOffcanvas) {
          // toggle additional Offcanvas
          var offcanvasElement = document.getElementById('additional-offcanvas');
          if (offcanvasElement) {
            var isOpen = offcanvasElement.classList.contains('uk-open');

            if (isOpen) {
              UIkit.offcanvas(offcanvasElement).hide();
            } else {
              UIkit.offcanvas(offcanvasElement).show();
            }
          }
        } else {
          this.get('router').transitionTo('checkout.cart');
        }
      }
    }
  });
});
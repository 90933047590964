define('webapp/components/media/image-labels', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    shownLabel: null,
    prevLabels: null,

    didReceiveAttrs: function didReceiveAttrs() {
      // clear popup, if labels change
      var labels = this.get('labels');
      if (this.get('prevLabels') !== labels) {
        this.set('shownLabel', null);
      }
      this.set('prevLabels', labels);
    },


    styledLabels: Ember.computed('labels.@each.x', 'labels.@each.y', 'labels.@each.description', 'labels.@each.title', function () {
      var labels = this.get('labels');
      return labels.map(function (label) {
        return _extends({}, label, { style: Ember.String.htmlSafe('left: ' + label.x + '%; top: ' + label.y + '%') });
      });
    }),

    actions: {
      toggleLabel: function toggleLabel(label) {
        var shownLabel = this.get('shownLabel');
        if (label === shownLabel) {
          this.set('shownLabel', null);
        } else {
          this.set('shownLabel', label);
          this.set('shownLabelStyle', Ember.String.htmlSafe('left: ' + label.x + '%; top: ' + label.y + '%'));
        }
      }
    }
  });
});
define('webapp/controllers/account/marketplace/search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    myStorage: Ember.inject.service(),
    searchTerm: '',

    search: function search() {
      var _this = this;

      var myStorage = this.get('myStorage');

      myStorage.skill.ajaxPost({ param: 'search', data: { perPage: 100, searchTerm: this.get('searchTerm') } }).then(function (res) {
        _this.set('skills', res.hits);
      });
    },


    actions: {
      search: function search() {
        this.search();
      }
    }
  });
});
define('webapp/utils/inject-script', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = injectScript;


  var scriptPromises = {};

  function injectScript(src) {
    var force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    var rootURL = _environment.default.rootURL || '/';

    if (!src.startsWith('https://')) {
      src = rootURL + src;
    }

    var scriptPromise = scriptPromises[src];
    if (!force && scriptPromise) {
      return scriptPromise;
    }

    scriptPromise = new Ember.RSVP.Promise(function (resolve) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = src;
      script.onload = function () {
        resolve();
      };
      document.getElementsByTagName('head')[0].appendChild(script);
    });
    scriptPromises[src] = scriptPromise;
    return scriptPromise;
  }
});
define('webapp/components/account/favorite/favorite-product', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    shoppingCartService: Ember.inject.service(),
    shoppingCart: Ember.computed.alias('shoppingCartService.shoppingCart'),
    myStorage: Ember.inject.service(),

    actions: {
      removeFromFavorites: function removeFromFavorites() {
        var myStorage = this.get('myStorage');
        var id = this.get('favorite._id');
        var removeFavorite = this.get('removeFavorite');

        myStorage.favorite.ajaxDelete({ param: id }).then(function () {
          removeFavorite && removeFavorite(id);
        });
      }
    }
  });
});
define('webapp/routes/account/order/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Order = Ember.Object.extend({
    init: function init() {
      this.discount = this.discount || { discountType: 'percent' };
      this.delivery = this.delivery || {};
      this.details = this.details || { productFormats: [] };
    }
  });

  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),

    model: function model() {
      return Order.create({});
    },


    actions: {
      save: function save(model) {
        var _this = this;

        var message = this.get('message');
        var myStorage = this.get('myStorage');

        myStorage.order.ajaxPost({
          data: model
        }).then(function () {
          message.info('Bestellung abgeschickt');
        }).catch(function (err) {
          console.error(err);
          message.error('Fehler beim bestellen');
        }).finally(function () {
          _this.transitionTo('account.order.index');
        });
      }
    }
  });
});
define('webapp/components/account/crm/entry-order', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var totalPriceFields = ['order.discount.discountType', 'order.discount.percent', 'order.discount.amount', 'order.details.productFormats.@each.standardPrice', 'order.details.productFormats.@each.specialPrice'];

  exports.default = Ember.Component.extend({

    _calcTotal: Ember.observer.apply(undefined, totalPriceFields.concat([function () {
      //TODO is dirty, alwas fires twice because of percent changing amount and reverse
      var productFormats = this.get('order.details.productFormats');
      var discountType = this.get('order.discount.discountType');

      var price = 0;
      productFormats.forEach(function (elem) {
        var specialPrice = parseFloat(elem.specialPrice);
        if (!isNaN(specialPrice)) price += parseFloat(elem.specialPrice);else price += elem.standardPrice;
      });

      if (discountType === 'percent') {
        var discountPercent = parseFloat(this.get('order.discount.percent'));
        if (!isNaN(discountPercent)) {
          var discount = price * discountPercent / 100;
          price -= discount;
          this.set('order.discount.amount', discount.toFixed(2));
        }
      } else {
        var discountAmount = parseFloat(this.get('order.discount.amount'));
        if (!isNaN(discountAmount)) {
          var _discountPercent = 100 * discountAmount / price;
          price -= discountAmount;
          this.set('order.discount.percent', _discountPercent.toFixed(2));
        }
      }

      var total = this.get('order.total');
      this.set('order.total', price);
    }])),

    displayedPrice: Ember.computed('order.total', function () {
      var total = this.get('order.total');

      return total ? total.toFixed(2) : 0;
    })

  });
});
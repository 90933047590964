define('webapp/components/cover-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'img',
    style: null,
    attributeBindings: ['src', 'alt', 'style', 'ukCover:uk-cover'],
    ukCover: true,
    image: null, // required, full image object

    didInsertElement: function didInsertElement() {
      this.coverContainer = this.element.closest('.uk-cover-container');

      this._updateCover = this.updateCover.bind(this);
      window.addEventListener('resize', this._updateCover);

      if (this.element.naturalHeight) {
        this.updateCover();
      } else {
        this.element.addEventListener('load', this._updateCover);
      }
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('resize', this._updateCover);
      this.element.removeEventListener('load', this._updateCover);
    },
    updateCover: function updateCover() {
      if (this.coverContainer && this.image && this.image.importantRegionCenter) {
        var width = this.element.naturalWidth;
        var height = this.element.naturalHeight;
        var importantRegionCenter = this.image.importantRegionCenter;

        var _coverContainer$getBo = this.coverContainer.getBoundingClientRect(),
            containerWidth = _coverContainer$getBo.width,
            containerHeight = _coverContainer$getBo.height;

        var ratioDiff = width / height / (containerWidth / containerHeight);

        var imageIsWider = ratioDiff > 1;

        var style = '';

        if (imageIsWider) {
          var transX = importantRegionCenter.x - 0.5;
          var maxTranslate = (ratioDiff - 1) / 2 / ratioDiff;

          var leftPercent = ((limitAbs(transX, maxTranslate) + 0.5) * 100).toFixed(0);

          style = 'transform: translate(-' + leftPercent + '%, -50%)';
        } else {
          var transY = importantRegionCenter.y - 0.5;
          var _maxTranslate = (1 / ratioDiff - 1) / 2 * ratioDiff;

          var topPercent = ((limitAbs(transY, _maxTranslate) + 0.5) * 100).toFixed(0);
          style = 'transform: translate(-50%, -' + topPercent + '%)';
        }

        this.set('style', Ember.String.htmlSafe(style));
      }
    }
  });


  function limitAbs(value, maxValue) {
    var sig = Math.sign(value);
    var abs = Math.abs(value);

    return sig * Math.min(abs, maxValue);
  }
});
define('webapp/components/content-builder-view/containers/slideshow', ['exports', 'webapp/components/content-builder-view/containers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    fastboot: Ember.inject.service(),

    initSlideshow: function initSlideshow() {
      var isFastboot = this.get('fastboot.isFastBoot');
      var slideElem = this.$('.uk-slidenav-position');
      var options = Ember.getProperties(this.get('options'), ['animation', 'duration', 'height', 'start', 'autoplay', 'pauseOnHover', 'autoplayInterval', 'videoautoplay', 'videomute', 'kenburns', 'kenburnsanimations', 'slices']);
      if (!isFastboot) {
        UIkit.slideshow(slideElem, options);
      }
    },
    initSlideset: function initSlideset() {
      var isFastboot = this.get('fastboot.isFastBoot');
      var options = Ember.getProperties(this.get('options'), ['default', 'small', 'medium', 'large', 'xlarge', 'animation', 'duration', 'delay', 'filter', 'autoplay', 'pauseOnHover', 'autoplayInterval']);
      if (!isFastboot) {
        UIkit.slideset(this.element, options);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var isSlideset = this.get('options.slideset');

      Ember.run.schedule('afterRender', function () {
        if (isSlideset) {
          _this.initSlideset();
        } else {
          _this.initSlideshow();
        }
      });
    }
  });
});
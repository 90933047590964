define('webapp/components/widgets/widget-show-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    languageService: Ember.inject.service(),
    cachedData: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.fetchData();
    },
    getOptions: function getOptions() {
      var refType = this.get('data.options.refType');
      var slug = this.get('data.options.slug');

      if (slug) {
        return { refType: refType, slug: slug };
      } else {
        var ref = this.get('data.options.ref');
        return { refType: refType, ref: ref };
      }
    },
    fetchData: function fetchData() {
      var _this = this;

      var data = this.getOptions();
      var myStorage = this.get('myStorage');
      var sessionId = this.get('cmsService.sessionId');
      var site = this.get('cachedData.cachedSite._id');
      var lang = this.get('languageService.selectedLanguage');
      var param = 'show_detail';
      var query = { sessionId: sessionId, lang: lang, site: site };

      return myStorage.widget.ajaxGet({ param: param, query: query, data: data }).then(function (model) {
        _this.set('model', model);
      });
    }
  });
});
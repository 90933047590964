define('webapp/components/widgets/widget-category-menu', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var cachedCategoryCounts = {};

  exports.default = Ember.Component.extend({
    cachedData: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    categoryContainer: Ember.inject.service(),
    globalCategoryCounts: {},
    mobileFilterVisible: false,

    categories: [],

    activeCategoryId: Ember.computed('cmsService.activePage', 'categoryContainer.activeCategory', function () {
      return this.get('cmsService.activePage.type') === 'categoryContainer' && this.get('categoryContainer.activeCategory._id');
    }),

    _fetchGlobalCategoryCounts: function _fetchGlobalCategoryCounts(rootCategory) {
      var myStorage = this.get('myStorage');
      return myStorage.search.ajaxPost({
        query: {
          site: this.get('cachedData.cachedSite._id')
        },
        data: {
          itemTypes: ['product'],
          categoryIds: rootCategory ? [rootCategory] : [],
          size: 0,
          aggregations: true
        }
      }).then(function (res) {
        return res.aggs.categoryIds;
      });
    },
    fetchGlobalCategoryCounts: function fetchGlobalCategoryCounts(rootCategory) {
      var key = String(rootCategory);
      if (!(key in cachedCategoryCounts)) {
        cachedCategoryCounts[key] = this._fetchGlobalCategoryCounts(rootCategory);
      }
      return cachedCategoryCounts[key];
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var myStorage = this.get('myStorage');
      var cachedData = this.get('cachedData');
      var categoryIds = this.get('data.options.categories');
      var categoryContainerId = this.get('data.options.categoryContainer');

      Ember.RSVP.all([cachedData.getFlatCategories(), myStorage.page.ajaxPost({ param: 'by_ids', data: { pageIds: [categoryContainerId] } })]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            flatCategories = _ref2[0],
            pageRes = _ref2[1];

        if (_this.isDestroyed) {
          return;
        }

        var shownCategories = categoryIds.map(function (catId) {
          return flatCategories.find(function (x) {
            return !x.internal && x._id === catId;
          });
        }).filter(Boolean).sort(function (a, b) {
          return a.order - b.order;
        }).map(function (cat) {
          // remove internal categories
          var filteredChildren = cat.children && (0, _treeUtils.filterTree)(cat.children, function (x) {
            return !x.internal;
          });
          return _extends({}, cat, { children: filteredChildren });
        });
        _this.fetchGlobalCategoryCounts(flatCategories.find(function (x) {
          return x.internalName === 'product';
        })).then(function (categoryCounts) {
          _this.set('globalCategoryCounts', categoryCounts);
        });
        var categoryContainerPage = pageRes[0];
        if (!categoryContainerPage) {
          console.warn('widget-category-menu has no assigned categoryContainer');
        }
        _this.set('categories', categoryContainerPage ? shownCategories : []);
        _this.set('categoryContainerPage', categoryContainerPage);
      });
    },

    actions: {
      showMobileFilter: function showMobileFilter() {
        this.set('mobileFilterVisible', true);
      },
      hideMobileFilter: function hideMobileFilter() {
        this.set('mobileFilterVisible', false);
      }
    }
  });
});
define('webapp/breakpoints', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    mobile: '(max-width: 767px)',
    landscape: '(orientation: landscape)',
    tabletToDesktop: '(min-width: 768px) and (max-width: 1199px)',
    allMobile: '(max-width: 959px)',
    tablet: '(min-width: 768px) and (max-width: 959px)',
    tabletLandscape: '(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)',
    desktop: '(min-width: 960px) and (max-width: 1199px)',
    large: '(min-width: 1200px)',
    smallHeight: '(max-height: 800px)'
  };
});
define('webapp/utils/file-type', ['exports', 'webapp/utils/file-extension', 'webapp/settings/media-extensions'], function (exports, _fileExtension, _mediaExtensions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (fullPath) {
    var extension = (0, _fileExtension.default)(fullPath);
    return invMapping[extension];
  };

  var mapping = {
    image: _mediaExtensions.default.IMAGE_EXTENSIONS,
    video: _mediaExtensions.default.VIDEO_EXTENSIONS,
    document: ['pdf'],
    audio: _mediaExtensions.default.AUDIO_EXTENSIONS
  };

  var invMapping = {};
  Object.keys(mapping).forEach(function (key) {
    var exts = mapping[key];
    exts.forEach(function (ext) {
      invMapping[ext] = key;
    });
  });
});
define('webapp/utils/media', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mediumToNested = mediumToNested;


  function calcPath(item) {
    var path = item.name + '.' + (item.generatedExtension || item.extension);
    if (item.directory) {
      path = item.directory + '/' + path;
    }
    return path;
  }

  function mediumToNested(medium) {
    return {
      path: calcPath(medium),
      mediaId: medium._id,
      description: Ember.get(medium, 'infos.description') || '',
      copyright: Ember.get(medium, 'infos.copyright') || ''
    };
  }
});
define('webapp/utils/filter-utils', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getTimeframeFilter = undefined;


  function getTimeframeFilter(timeframe) {
    var filter = {};

    //window.moment = moment;

    if (timeframe.hasRelativeRange) {
      if (timeframe.relativeStart && timeframe.relativeStop) {
        /*
        filter.bool = {
          must: [{
            range: {
              startDate: {
                gte: moment().subtract(timeframe.relativeStart, 'days').toDate()
              }
            }
          }, {
            range: {
              stopDate: {
                lt: moment().add(timeframe.relativeStop, 'days').toDate()
              }
            }
          }]
        };
        */
        filter.range = {
          startDate: {
            gte: (0, _moment.default)().subtract(timeframe.relativeStart, 'days').toDate(),
            lt: (0, _moment.default)().add(timeframe.relativeStop, 'days').toDate()
          }
        };
      } else {
        if (timeframe.relativeStart) {
          filter.range = {
            startDate: {
              gte: (0, _moment.default)().subtract(timeframe.relativeStart, 'days').toDate()
            }
          };
        }

        if (timeframe.relativeStop) {
          filter.range = {
            startDate: {
              lt: (0, _moment.default)().add(timeframe.relativeStop, 'days').toDate()
            }
          };
        }
      }
    } else {
      if (timeframe.startDate && timeframe.stopDate) {
        /*
        filter.bool = {
          must: [{
            range: {
              startDate: {
                gte: timeframe.startDate
              }
            }
          }, {
            range: {
              stopDate: {
                lt: timeframe.stopDate
              }
            }
          }]
        };
        */
        filter.range = {
          startDate: {
            gte: timeframe.startDate,
            lt: timeframe.stopDate
          }
        };
      } else {
        if (timeframe.startDate) {
          filter.range = {
            startDate: {
              gte: timeframe.startDate
            }
          };
        }

        if (timeframe.stopDate) {
          filter.range = {
            startDate: {
              lt: timeframe.stopDate
            }
          };
        }
      }
    }

    //window.console.log('filter is ');
    //window.console.log(JSON.stringify(filter));


    if (JSON.stringify(filter) === '{}') {
      return false;
    } else {
      return filter;
    }
  }

  exports.getTimeframeFilter = getTimeframeFilter;
});
define('webapp/components/widgets/widget-entry-images', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    shareService: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var myStorage = this.get('myStorage');
      myStorage.entry.ajaxGet({ param: 'images' }).then(function (res) {
        _this.set('entryImages', res);
      });
    },


    actions: {
      shareDream: function shareDream(type, refEntry, refPost) {
        var _this2 = this;

        var myStorage = this.get('myStorage');
        var user = this.get('userProxy._id');
        var currentURL = '/dream/' + refEntry;
        if (refPost) {
          currentURL = currentURL + '?highlight=' + refPost;
        }

        if (!this.get('fastboot.isFastBoot')) {
          shareService.shareLinkBuilder(type, '', currentURL);
        }
        myStorage.share.ajaxPost({
          param: 'by_slug/' + refEntry,
          data: {
            uid: user,
            socialMedia: type
          }
        }).then(function (res) {
          var message = _this2.get('message');
          if (res.message) {
            message.info(res.message);
          }
        });
      }
    }
  });
});
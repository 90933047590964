define('webapp/utils/file-path', ['exports', 'webapp/config/environment', 'webapp/utils/file-extension', 'webapp/utils/image-path', 'webapp/settings/media-extensions'], function (exports, _environment, _fileExtension, _imagePath, _mediaExtensions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (path) {
    if (!path) {
      return;
    }
    var ext = (0, _fileExtension.default)(path);

    if (_mediaExtensions.default.IMAGE_EXTENSIONS.indexOf(ext) >= 0) {
      return (0, _imagePath.default)(path, 'original');
    } else {
      return _environment.default.apiEndpoints.image + '/' + path;
    }
  };
});
define('webapp/helpers/is-last', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var elem = params[0];
    var array = params[1];
    return elem === array[array.length - 1];
  });
});
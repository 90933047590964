define('webapp/services/global-search-container', ['exports', 'webapp/services/abstract-container'], function (exports, _abstractContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var DEFAULT_RESULT_COUNT = 5; // will most likely be overwritten in page options

  function getExclude() {
    var params = new URLSearchParams(window.location.search);
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = params.entries()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _ref = _step.value;

        var _ref2 = _slicedToArray(_ref, 2);

        var key = _ref2[0];
        var value = _ref2[1];

        if (key === 'exclude') {
          return value.split(',');
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return [];
  }

  exports.default = _abstractContainer.default.extend({
    containerName: 'globalSearchContainer',
    cachedData: Ember.inject.service(),
    fingerprintService: Ember.inject.service(),
    gdprService: Ember.inject.service(),
    searchTerm: '',
    prevSearchTerm: '',
    suggestions: null,

    _getSearchData: function _getSearchData(searchTerm) {
      var types = this.get('types');
      var size = this.get('perPage');
      var searchData = { types: types, searchTerm: searchTerm, size: size };
      var exclude = getExclude();
      if (exclude) {
        searchData['exclude'] = exclude;
      }

      return searchData;
    },
    _getSearchQuery: function _getSearchQuery(fingerprint) {
      var gdprStatus = this.get('gdprService.gdprStatus');

      if (gdprStatus !== 'accepted') {
        fingerprint = undefined;
      }
      var searchQuery = {
        lang: this.get('languageService.selectedLanguage'),
        site: this.get('cachedData.cachedSite._id'),
        sessionId: this.get('cmsService.sessionId'),
        fingerprint: fingerprint,
        gdprStatus: gdprStatus
      };

      return searchQuery;
    },
    _fetchData: function _fetchData(data, query) {
      var myStorage = this.get('myStorage');
      var param = 'full_search';
      return myStorage.globalSearch.ajaxPost({ param: param, data: data, query: query }).then(function (searchResult) {
        return searchResult;
      });
    },
    fetchDetail: function fetchDetail(detailSlug) {
      var _this = this;

      var searchTerm = decodeURIComponent(detailSlug);
      this.set('searchTerm', searchTerm);

      var options = this.get('page.options');
      if (!options) {
        options = {};
      }

      var types = options.types || ['pages'];
      var perPage = options.perPage || DEFAULT_RESULT_COUNT;

      this.set('perPage', perPage);
      this.set('types', types);

      var fingerprintService = this.get('fingerprintService');
      if (searchTerm.length) {
        return fingerprintService.getFingerprint().then(function (fingerprint) {
          _this.set('fingerprint', fingerprint);

          var data = _this._getSearchData(searchTerm);
          var query = _this._getSearchQuery(fingerprint);

          return _this._fetchData(data, query);
        });
      } else {
        this.set('suggestions', null);
        return Promise.resolve({});
      }
    }
  });
});
define('webapp/components/content-builder-view/containers/base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function endsWith(searchString, value) {
    var idx = searchString.lastIndexOf(value);
    return idx >= 0 && idx === searchString.length - value.length;
  }

  function mapElement(element, data) {
    element = Ember.assign({}, element);

    element.type = element.type.slice(0, element.type.length - 5); // strip '-data'

    var options = element.options;
    var newOptions = {};
    Object.keys(options).forEach(function (key) {
      var val = options[key];
      if (val !== null && (typeof val === 'undefined' ? 'undefined' : _typeof(val)) === 'object' && val.type === 'data') {
        newOptions[key] = Ember.get(data, val.field);
      } else {
        newOptions[key] = val;
      }
    });

    element.options = newOptions;

    if (element.children && element.children.length) {
      element.children = mapElements(element.children, data);
    }

    return element;
  }

  function mapElements(elements, externalData) {
    var newElements = [];

    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];
      if (endsWith(element.type, '-data')) {

        if (element.dataArray) {
          var dataArray = Ember.get(externalData, element.dataArray);
          for (var _i = 0; _i < dataArray.length; _i++) {
            var data = dataArray[_i];
            newElements.push(mapElement(element, data));
          }
        } else {
          newElements.push(mapElement(element, externalData));
        }
      } else {
        newElements.push(element);
      }
    }

    return newElements;
  }

  exports.default = Ember.Component.extend({
    classNameBindings: ['data.options.class'],
    options: Ember.computed.oneWay('data.options'),

    mappedData: Ember.computed('data', 'externalData', function () {
      var data = this.get('data');
      var externalData = this.get('externalData');
      if (externalData) {
        var mappedContainer = Ember.assign({}, data);
        mappedContainer.elements = mapElements(data.elements, externalData);
        return mappedContainer;
      } else {
        return data;
      }
    })
  });
});
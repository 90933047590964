define('webapp/components/shopping/sofort-form', ['exports', 'webapp/config/environment', 'webapp/utils/inject-script'], function (exports, _environment, _injectScript) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),
    shoppingCart: Ember.computed.alias('shoppingCartService.shoppingCart'),
    paymentService: Ember.inject.service(),
    message: Ember.inject.service(),
    stripe: undefined,
    tagName: 'form',
    elementId: 'giropay-payment-form',

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _injectScript.default)('https://js.stripe.com/v3/').then(function () {
        if (window.Stripe) {
          _this.setupSofortForm();
        }
      });
    },
    setupSofortForm: function setupSofortForm() {
      if (this.isDestroyed) {
        return;
      }

      var stripe = this.get('stripe');
      if (stripe === undefined) {
        stripe = window.Stripe(_environment.default.stripePublishableKey);
        this.set('stripe', stripe);
      }

      var button = this.element.querySelector('button');
      button.disabled = false;
    },
    submit: function submit(e) {
      var _this2 = this;

      e.preventDefault();

      var paymentService = this.get('paymentService');
      var stripe = this.get('stripe');
      var returnUrl = document.location.origin + '/checkout/order';
      var shoppingCartService = this.get('shoppingCartService');
      var invoiceAddress = this.get('shoppingCartService.shoppingCart.invoiceAddress');
      var country = invoiceAddress.countryCode;
      shoppingCartService.updateShoppingCart(this.get('shoppingCart'));

      paymentService.setupStripePaymentIntent('sofort', true).then(function (data) {
        var clientSecret = data.clientSecret;
        _this2.set('clientSecret', clientSecret);
        stripe.confirmSofortPayment(clientSecret, {
          payment_method: {
            sofort: { country: country }
          },
          return_url: returnUrl
        });
      });
    }
  });
});
define('webapp/routes/checkout', ['exports', 'webapp/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    languageService: Ember.inject.service(),
    paymentService: Ember.inject.service(),

    beforeModel: function beforeModel() {
      return this.get('paymentService').fetchPaymentMethodInfo();
    },
    model: function model() {
      var cmsService = this.get('cmsService');
      return cmsService.getAndSetActivePage('checkout');
    },
    activate: function activate() {
      var languageService = this.get('languageService');
      languageService.on('languageChange', this, this.watchLang);
    },
    deactivate: function deactivate() {
      var languageService = this.get('languageService');
      languageService.off('languageChange', this, this.watchLang);
    },
    watchLang: function watchLang() {
      this.refresh();
    }
  });
});
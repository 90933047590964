define('webapp/components/global-link-to', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var entryTypes = ['entry', 'poi', 'event', 'product', 'job', 'recipe', 'campaign'];
  var itemTypeToPageType = {
    entry: 'entryContainer',
    poi: 'poiContainer',
    event: 'eventContainer',
    product: 'productContainer',
    job: 'jobContainer',
    recipe: 'recipeContainer',
    campaign: 'campaignContainer',
    article: 'articleContainer'
  };

  exports.default = Ember.LinkComponent.extend({
    languageService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    message: Ember.inject.service(),

    handleEntry: function handleEntry(item) {
      var pageType = itemTypeToPageType[item.itemType];

      var message = this.get('message');
      var dynamicPageRoutes = this.get('cmsService.dynamicPageRoutes');

      var baseRoute = dynamicPageRoutes[pageType];
      if (!baseRoute) {
        var errMessage = 'there is no dynamic route for ' + pageType;
        message.infoUnique(errMessage);
      }

      return ['page', baseRoute + '/' + item.slug];
    },
    handleArticle: function handleArticle(item) {
      // almost the same as handleEntry, except slug translation
      var languageService = this.get('languageService');
      var pageType = 'articleContainer';

      var message = this.get('message');
      var dynamicPageRoutes = this.get('cmsService.dynamicPageRoutes');

      var baseRoute = dynamicPageRoutes[pageType];
      if (!baseRoute) {
        var errMessage = 'there is no dynamic route for ' + pageType;
        message.infoUnique(errMessage);
      }

      var translatedSlug = languageService.translateField(item, 'slug');
      return ['page', baseRoute + '/' + translatedSlug];
    },
    handlePage: function handlePage(page) {
      var languageService = this.get('languageService');
      var selectedLanguage = this.get('languageService.selectedLanguage');
      var langSlug = this.get('languageService.languageSlug');

      if (page.type === 'account') {
        return ['account'];
      } else if (page.fullSlug === '') {
        // index route;
        if (langSlug) {
          return ['page', langSlug];
        } else {
          return ['index'];
        }
      } else {
        if (langSlug) {
          return ['page', languageService.translateSlug(page, selectedLanguage)];
        } else {
          return ['page', page.fullSlug];
        }
      }
    },
    handlePost: function handlePost(post) {
      var languageService = this.get('languageService');
      var pages = this.get('cachedData.cachedSite.pages');

      var blogPage = (0, _treeUtils.findInTree)(pages, function (page) {
        return page.options && page.options.blog === post.blog;
      });
      var blogPageSlug = blogPage && languageService.translateSlug(blogPage);

      return ['page', blogPageSlug + '/' + post.slug];
    },
    _getErrorHandler: function _getErrorHandler() {
      var languageService = this.get('languageService');
      var selectedLanguage = this.get('languageService.selectedLanguage');
      var pages = this.get('cachedData.cachedSite.pages');

      var errorHandler = false;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = pages[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var page = _step.value;

          if (page.type === 'error') {
            errorHandler = languageService.translateSlug(page, selectedLanguage);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var handler = ['/' + errorHandler];

      return handler;
    },


    params: Ember.computed('item', 'languageService.selectedLanguage', function () {
      var handler = this._getErrorHandler();
      var item = this.get('item');
      var itemType = item.itemType;

      if (entryTypes.includes(itemType)) {
        handler = this.handleEntry(item);
      } else if (itemType === 'article') {
        handler = this.handleArticle(item);
      } else if (itemType === 'page') {
        handler = this.handlePage(item);
      } else if (itemType === 'post') {
        handler = this.handlePost(item);
      }

      return handler;
    })
  });
});
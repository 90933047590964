define("webapp/locales/fr/translations", ["exports", "webapp/locales/fr/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "account.ui.entryBannerImageNew": "Définissez une image principale pour votre studio.",
    "application.ui.back": "retour",
    "application.ui.confirmDeleteEntry": "Le studio {{title}} supprimer irrévocablement ?",
    "application.ui.entriesFocus": "Une sélection de studios en vedette :",
    "application.ui.entriesLatest": "Les derniers studios en vedette :",
    "application.ui.goToEntry": "Appeler le studio",
    "application.ui.newEntry": "Créer un nouveau studio",
    "application.ui.noEntry": "Vous n'avez pas encore créé de studio. Créez votre premier studio dès maintenant...",
    "application.ui.noEntryProfile": "n'a pas encore créé de studio.",
    "application.ui.showAllEntries": "Afficher tous les studios",
    "application.widgets.newestEntries": "Les derniers studios",
    "application.widgets.themeSwitch.active": "Mode sombre activé. Un merci de l'environnement !",
    "application.widgets.themeSwitch.default": "Economisez l'énergie, activez le mode sombre.",
    "buttons.alreadyMeToo": "Vous aimez ce studio.",
    "buttons.alreadyMeTooMobile": "Vous aimez ce studio.",
    "entries.edit": "Studio <strong>{{title}}</strong> éditer",
    "entries.entries": "Studios",
    "entries.entry.tooltip.alreadyCopied": "Vous aimez déjà ce studio.",
    "entries.entry.tooltip.alreadyFollow": "Vous suivez déjà ce studio",
    "entries.entry.tooltip.alreadyLiked": "Vous aimez déjà ce studio",
    "entries.entry.tooltip.copied": "Ravi que ce studio vous plaise. Votre photo de profil a été ajoutée à ce studio.",
    "entries.entry.tooltip.follow": "Cliquez ici pour suivre ce studio",
    "entries.entry.tooltip.like": "Cliquez ici pour donner à ce studio un \"Like",
    "entries.entry.tooltip.noFollow": "Le suivi n'est possible qu'avec les studios étrangers",
    "entries.entry.tooltip.noFollowNotLoggedIn": "Vous devez être connecté pour suivre ce studio",
    "entries.entry.tooltip.noLike": "Comme ne vont que les studios étrangers",
    "entries.entry.ui.access": "Ce studio ne peut être vu que par",
    "entries.entry.ui.addEntry": "Ajouter des studios",
    "entries.entry.ui.allAccess": "Tous peuvent voir ce studio",
    "entries.entry.ui.categories": "Catégories pour ce studio",
    "entries.entry.ui.commercialTitle": "Informations sur le studio",
    "entries.entry.ui.editEntry": "Studio de montage",
    "entries.entry.ui.editEntryNew": "Créer un studio",
    "entries.entry.ui.isMainImageAndListImage": "Cette image apparaît comme l'image principale du studio",
    "entries.entry.ui.isMainImageInfo": "La partie montrée correspond approximativement à l'affichage sur le Desktop, le cadre jaune à l'affichage mobile",
    "entries.entry.ui.noDescriptions": "Le studio ne dispose pas encore de textes de description.",
    "entries.entry.ui.noEntries": "Aucun studio n'a été trouvé.",
    "entries.entry.ui.onlyVisibleForCustomers": "Ce studio n'est visible que pour les clients enregistrés",
    "entries.entry.ui.overallProgress": "Ce studio est {{value}}% décrit",
    "entries.entry.ui.skillsNeeded": "Je pourrais l'utiliser dans mon studio...",
    "entries.entry.ui.title": "Le studio apparaît sous les noms suivants",
    "entries.entry.ui.titleError": "Le studio doit être nommé avant d'être sauvé",
    "entries.entry.ui.visible": "Ce studio est visible",
    "entries.open": "Appeler le studio au frontend",
    "entries.person.ui.open": "Directement au studio de cette personne",
    "entries.entry.ui.toDetail": "Directement à",
    "entries.progress.title": "Comment améliorer votre studio",
    "entries.tabbar.entry": "Données de studio",
    "entries.tabbar.tooltip.entryNotSavedDetail": "Les données du studio ne peuvent pas être éditées tant que le studio avec le titre n'a pas été enregistré.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "Les données de mise en page ne peuvent être éditées qu'après l'enregistrement du studio avec le titre.",
    "entries.ui.groupDetailName": "Nom des entrées de groupe",
    "entryTypes.entry": "Studio",
    "profile.entries.copycats": "Les studios qui {{userName}} veut aussi faire",
    "profile.entries.donates": "Les studios qui {{userName}} soutient",
    "profile.entries.entries": "Studios de {{userName}}",
    "profile.entries.follows": "Les studios, qui {{userName}} suit",
    "profile.entries.joins": "Studios où {{userName}} participe",
    "profile.marketplace.offer.relatedEntry": "Studio assigné",
    "social.hasLiked": "Vous aimez déjà ce studio.",
    "user.company.copyContact": "Reprendre les coordonnées de l'entreprise pour le studio",
    "user.ui.addedStudios": "sont disposés.",
    "user.ui.accountMenuEntries": "Mes Studios",
    "user.ui.accountMenuEntry": "Studio",
    "user.ui.accountMenuEntryPre": "Studios,",
    "user.ui.accountMenuImages": "Mon répertoire",
    "user.ui.accountMenuFollows": "Studios que je suis",
    "user.ui.accountMenuLikes": "Studios que j'aime",
    "user.ui.accountMenuSupport": "Studios soutenus",
    "user.ui.accountMenuSupports": "Studios que je soutiens",
    "user.ui.documents": "Contentpool",
    "user.company.imprintFields": "Informations sur les mentions légales",
    "user.company.imprintCompany": "Fournisseur du site",
    "user.company.imprintCEO": "Directeur général/propriétaire",
    "user.company.imprintTaxId": "Numéro d'identification fiscale",
    "user.company.imprintAddress": "Adresse",
    "user.company.imprintResponsible": "Responsable du contenu",
    "user.ui.ownDocuments": "Documents pour ce studio",
    "user.ui.ownVideos": "Vidéos",
    "user.ui.user": "Utilisateur",
    "user.ui.user.complete": "Vos données d'utilisateur sont complètes.",
    "user.ui.user.incomplete": "Vos données d'utilisateur peuvent encore être complétées. Si vous souhaitez compléter vos données, veuillez remplir les champs suivants :",
    "user.ui.user.verified": "Votre compte d'utilisateur a été vérifié et approuvé.",
    "user.ui.salutation": "Salutation",
    "user.ui.welcome": "Bienvenue,",
    "user.ui.hasAdministrativeRights": "Vous disposez de droits administratifs et pouvez inviter d'autres utilisateurs de votre entreprise.",
    "entries.entry.ui.imagesInfo": "Les images peuvent être éditées sous \"Mon répertoire\".",
    "user.ui.accountProgressbarEntry": "Votre studio est {{value}}% complet",
    "user.company.addUserShort": "Créer un nouvel utilisateur",
    "user.ui.noCompany": "Aucune entreprise n'a encore été créée pour votre compte d'utilisateur. Contactez votre personne de contact.",
    "user.ui.companyNameTooltip": "Important : votre studio apparaîtra alors également sous cette raison sociale.",
    "user.ui.dataDelete": "d'autres studios. Voulez-vous supprimer le fichier définitivement ? Partout où ce fichier est utilisé, il sera remplacé par un espace.",
    "user.user": "Utilisateur",
    "user.users": "Utilisateur",
    "user.company.noEditingRights": "Vous ne pouvez pas encore apporter de modifications parce que les droits nécessaires ne vous ont pas encore été attribués.",
    "search.searchBarPlaceholderEntries": "Recherche par nom ou par adresse de studio.",
    "search.searchBarPlaceholderJobs": "Recherchez des emplois ou dans leur description.",
    "search.searchBarPlaceholder.job": "Recherchez un titre de poste ou dans sa description et appuyez sur Entrée...",
    "search.searchBarPlaceholderMobile.job": "Rechercher un emploi...",
    "search.searchBarPlaceholder.event": "Recherchez un titre d'événement ou dans sa description et appuyez sur Entrée...",
    "search.searchBarPlaceholder.entry": "Recherchez les studios ou dans leur description et appuyez sur Entrée...",
    "search.searchBarPlaceholderEvents": "Recherchez des dates ou des événements.",
    "search.searchBarPlaceholderDefault": "Rechercher...",
    "search.searchBarPlaceholderGeoSearch": "Votre emplacement...",
    'search.searchBarPlaceholderArticles': "Recherche d'articles dans le titre et le texte",
    'search.searchBarPlaceholderArticlesMobile': "Recherche d'articles",
    "search.searchTerm.pre": "Studios uniquement, filtré par",
    "search.backToResults": "Retour à la liste des résultats",
    "noResults": "Aucun résultat",
    "application.ui.flyout.receiver": "Contactez",
    "application.ui.flyout.bodystreet-trial": "Séance d'essai",
    "application.ui.flyout.bodystreet-infopackage": "Dossier d'information",
    "application.ui.flyout.cart": "Boutique",
    "application.ui.flyout.bodystreet-shop": "eStore",
    'application.ui.flyout.bodystreet-studio': 'Studios',
    "application.ui.flyout.facebook": "Facebook",
    "application.ui.flyout.sing": "Xing",
    "application.ui.flyout.info": "Info",
    "application.ui.flyout.bodystreet-info": "Info",
    "application.ui.flyout.bodystreet-ems": "Séance d'essai",
    "application.ui.flyout.bodystreet-voucher": "Coupon",
    "entries.entry.ui.externals": "Interfaces",
    "entries.entry.ui.tabStudio": "Studio",
    "entries.entry.ui.bookTrial": "Réservez dès maintenant une formation d'essai en studio",
    "entries.entry.ui.bookVoucher": "Obtenez dès maintenant votre bon de formation pour",
    "entries.entry.ui.voucherTitle": "Votre bon d’une séance d’essai chez",
    "entries.entry.ui.voucherIntro": "Vous voulez connaître la méthode révolutionnaire de Bodystreet sans obligation ? Ensuite, obtenez votre bon d'entraînement personnel pour une séance d’essai intensive et complète chez Bodystreet avec un entraîneur personnel et tout ce qui va avec.",
    "entries.entry.ui.voucherInfo": "C'est ainsi que cela fonctionne : Il suffit d'entrer votre nom, votre adresse électronique et votre numéro de téléphone ci-dessus et de l'envoyer. Vous recevrez ensuite votre bon par e-mail et le studio vous appellera pour fixer un rendez-vous. N'oubliez pas d'apporter le bon à la formation. Un seul bon peut être échangé par personne.",
    "entries.entry.ui.goTrialSession": "Pour la prise de rendez-vous",
    "entries.entry.ui.noTrial": "n'offre pas de bon pour le moment. Vous souhaitez plutôt nous contacter directement ?",
    "entries.entry.ui.trialSession": "Séance d'essai",
    "entries.entry.ui.facebookPixel": "Code pour le pixel Facebook sur la page de votre studio",
    "entries.entry.ui.magicline": "My Magicline est configuré pour afficher des exercices d'essai.",
    "entries.entry.ui.magiclineNoID": "Aucun identifiant n'est stocké pour votre Magicline. Vous ne pouvez pas vous connecter. Veuillez contacter votre personne de contact.",
    "entries.entry.ui.globalCampaign": "Afficher le formulaire de bon de formation générale",
    "entries.entry.ui.provenExpertsID": "ProvenExperts ID",
    "entries.entry.ui.getDirections": "Naviguer",
    "entries.entry.ui.team": "Notre équipe",
    "entries.entry.ui.jobs": "Postes à pourvoir",
    "entries.entry.ui.noRights": "Ce champ ne peut pas être modifié.",
    "entries.entry.ui.articles": "Nouvelles intéressantes",
    "entries.entry.ui.events": "Événements",
    "entries.entry.ui.jobApply": "Postulez maintenant",
    "entries.entry.ui.jobAvailableAt": "Est disponible dans les endroits suivants",
    "entries.entry.ui.studio": "Notre studio",
    "entries.entry.ui.sharing": "Cette page sur {{sharing}} partager",
    "entries.entry.ui.shareMail": "Partager cette page par courrier",
    'entries.entry.ui.imageType.standard': 'Standard (max. 2 images)',
    'entries.entry.ui.imageType.hero': 'Hero (image de la bannière)',
    "entries.entry.ui.imageType.product": "Image de studio",
    "entries.entry.ui.imageType.cover": "Image de couverture",
    "entries.entry.ui.imageType.team": "Photo d'une personne ou d'une équipe",
    "entries.entry.ui.imageType.award": "Prix ou sceau",
    "entries.entry.ui.imageType.action": "Image de la campagne",
    "entries.entry.ui.imageType.testimonial": "Témoignage",
    "entries.entry.ui.tabRelationsMobile": "Connexions",
    "entries.entry.ui.tabImagesMobile": "Image / Vidéo",
    "entries.entry.ui.closed": "fermé",
    "entries.entry.ui.miscellaneousSettings": "Autres paramètres",
    "entries.entry.ui.provenExpertsSettings": "ProvenExperts",
    "entries.entry.ui.globalPromotionSettings": "Bon générale",
    "entries.entry.ui.globalPromotionPrice": "Texte et prix du bon générale",
    "entries.entry.ui.facebookSettings": "Facebook",
    "entries.entry.ui.facebookTracking": "Suivi sur Facebook",
    "entries.entry.ui.calendly": "Composante de l'URL de calendrier",
    "entries.entry.ui.studioSoftware": "Logiciel Studio",
    "entries.entry.ui.trialBooking": "Choisissez une date pour une formation d'essai",
    "entries.entry.ui.socialPostOpen": "Ouvrir ce poste",
    "entries.entry.ui.openingWeek": "Heures d'ouverture cette semaine",
    "job.application.title": "Votre demande",
    "job.application.thanks.salutation": "Cher demandeur,",
    "job.application.thanks.title": "Nous vous remercions de votre candidature et de votre intérêt pour la prochaine étape de votre carrière chez Bodystreet.",
    "job.application.thanks.content": "Au cours de l'étape suivante, nous traiterons vos documents et prendrons ensuite contact avec vous.",
    "job.application.thanks.contact": "Vous avez déjà quelques questions importantes ? Vous pouvez alors nous contacter directement.",
    "job.application.thanks.successWish": "Nous vous souhaitons beaucoup de succès !",
    "job.application.studio": "Studio",
    "job.application.noStudio": "Pas de studio attribué, veuillez attribuer",
    "job.application.selectRegionCategory": "Sélectionnez une région pour laquelle vous souhaitez postuler",
    "job.application.selectStudio": "Veuillez sélectionner au moins un studio",
    "job.application.personalData": "Vos données personnelles",
    "job.application.documents": "Candidature",
    "job.application.checkTransfer": "J'accepte que ma demande soit transmise à d'autres studios Bodystreet de la région.",
    "job.application.privacyTitle": "Je confirme que j'ai lu les \"Informations sur la protection des données pour les candidats\" et que je consens au traitement de mes données personnelles (cliquez sur la case appropriée).",
    "job.application.privacyInfo": "Aucune autre utilisation des données et aucune divulgation de mes données personnelles à des tiers n'aura lieu. Je peux révoquer ce consentement à la protection des données à tout moment avec effet pour l'avenir.",
    "job.application.checkPrivacy": "stockées et utilisées pour me contacter par écrit, par e-mail ou par téléphone dans le cadre de la procédure de candidature, ainsi que pour m'informer des autres offres d'emploi de Bodystreet GmbH, Bodystreet Trainings GmbH ou des partenaires franchisés de Bodystreet ;",
    "job.application.checkApplicationPool": "sera conservé pendant une période maximale de 6 mois dans le cadre de l'inclusion dans le pool de candidats Bodystreet, transmis aux partenaires franchisés Bodystreet pendant cette période et stocké et utilisé par eux pour me contacter par écrit, par e-mail ou par téléphone dans le cadre du processus de candidature et également pour m'informer sur d'autres postes vacants dans le système Bodystreet.",
    "job.application.submit": "Envoyez votre candidature maintenant",
    "job.application.submitNotReady": "Tous les champs obligatoires doivent être remplis pour envoyer le formulaire.",
    "job.application.coverNote": "Lettre d'accompagnement",
    "job.application.cv": "Curriculum Vitae",
    "job.application.photo": "Photo de la candidature",
    "job.application.certificate": "Témoignages",
    "job.application.misc": "Autres",
    "job.application.message": "Note",
    "job.application.reason": "Pourquoi postulez-vous à Bodystreet ?",
    "job.application.trainer": "Pourquoi êtes-vous l'entraîneur parfait ?",
    'job.fields.yourDuties': 'Vos travaux',
    "job.fields.yourOpportunities": "Ce que vous apprenez avec nous",
    "job.fields.yourProfile": "Votre profil",
    "job.fields.jobPartner": "Nos partenaires de formation et d'études",
    "job.fields.jobContent": "Vos chances + avantages",
    "job.fields.aboutUs": "Ce que nous vous proposons",
    "job.ui.noOffer": "Il n'y a pas de poste à pourvoir pour l'instant.",
    'job.ui.needed': 'Recherche urgente de personnel',
    'job.ui.jobs': 'Emplois',
    "user.ui.phoneType": "Téléphone",
    "user.ui.phoneTypeMobile": "Mobile",
    "user.ui.phoneTypeLandline": "Téléphone",
    "user.ui.fullName": "Prénom et nom de famille",
    "user.ui.dateWish": "Date souhaitée",
    "user.ui.messageWish": "Souhaits particuliers",
    "trial.calendar.today": "Aujourd'hui",
    "trial.calendar.form.privacy": "Oui, les données envoyées seront traitées dans le but de traiter ma demande et pourront également être utilisées pour me contacter par téléphone ou par courrier électronique. Je peux trouver plus d'informations dans la politique de confidentialité.",
    "trial.calendar.form.yourTrailSession": "Votre formation d'essai sur",
    "trial.calendar.form.selectTrailSession": "Choisissez d'abord la date de votre formation d'essai.",
    "trial.calendar.form.info": "Chez Bodystreet, vous serez toujours supervisé par un entraîneur personnel pendant votre formation d'essai - et bien sûr au-delà.",
    "trial.calendar.form.submit": "Obtenez votre rendez-vous dès maintenant",
    "trial.calendar.form.trialSessionBooked": "Votre demande a été reçue. Vous devriez recevoir un courriel de confirmation sous peu.",
    "menu.company.alternativeTitle": "Valeurs",
    "campaign.title": "Actions en cours",
    "campaign.form.privacyInfo": "J'accepte que mes informations et données soient traitées et stockées électroniquement pour traiter et répondre à ma demande. Ce consentement peut être révoqué à tout moment pour l'avenir par e-mail au studio concerné.",
    "campaign.search.location": "Votre code postal",
    "campaign.submit": "Soumettre",
    "campaign.selectedEntry": "Studio d'entraînement d'essai sélectionné:",
    "campaign.selectLocation": "Il suffit de préciser le lieu,",
    'campaign.search.locationFull': 'Ton code postal ou ta localité',
    "campaign.selectStudio": "Sélectionnez le studio,",
    "campaign.start": "Inscrivez-vous et c'est parti !",
    "campaign.form.intro": "Je voudrais travailler {{product}} dans le studio <span class='form-studio-title'>{{title}}</span> pour le prix de {{productPrice}}.",
    "campaign.form.introNoPrice": "Je voudrais travailler {{product}} dans le studio <span class='form-studio-title'>{{title}}</span>.",
    "user.ui.uploadDrop": "Pour télécharger, faites glisser un fichier ici ou",
    "user.ui.uploadSelectFile": "sélectionner un fichier",
    "entries.entry.ui.jobReplyMail": "Envoyez les candidatures à l'adresse électronique suivante (si elle est vide, le courrier sera envoyé à l'adresse électronique du studio)",
    "entries.entry.ui.jobReplyMailInvalid": "Veuillez entrer une adresse e-mail valide de Bodystreet",
    "user.ui.newsletterEmail": "E-mail",
    "user.ui.newsletterEmailnotValid": "Veuillez saisir votre adresse e-mail",
    "user.ui.newsletterEmailValid": "Abonnez-vous maintenant !",
    "widget.gdpr.personalisation": "Contenu personnalisé, mesure de la performance, développement et amélioration d'offres personnalisées",
    "widget.gdpr.advertising": "Mesures d'audience avec stockage et accès aux informations sur un terminal",
    "widget.gdpr.social": "Cookies de médias sociaux"
  });
});
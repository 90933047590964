define('webapp/components/account/user-register', ['exports', 'webapp/utils/validation-utils'], function (exports, _validationUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  /* global UIkit */

  function getEmptyUser() {
    return {
      academicTitle: null,
      firstName: null,
      lastName: null,
      userName: null,
      password: null,
      email: null,
      address: {},
      isCustomer: true,
      registrationData: {
        title: null,
        address: {},
        contact: {},
        account: {},
        userGroups: []
      }
    };
  }

  var userFields = ['academicTitle', 'firstName', 'lastName', 'userName', 'password', 'email', 'address', 'isCustomer', 'registrationData'];

  var requiredFields = ['passwordValid', 'firstName', 'lastName', 'emailValid', 'hasConfirmed'];

  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    cachedData: Ember.inject.service(),

    isRequired: true,
    passwordValid: false,
    hasConfirmed: false,
    showUserGroups: false,
    showCompany: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.resetVars();
    },
    resetVars: function resetVars() {
      this.setProperties(_extends({
        isRequired: true,
        passwordValid: false,
        hasConfirmed: false
      }, getEmptyUser()));
    },
    _afterRegister: function _afterRegister(success, error, user) {
      var i18n = this.get('i18n');
      var messageKey = success ? 'user.message.registration-success' : 'user.message.registration-error';

      var messages = [i18n.t(messageKey).toString()];

      if (error && error.payload) {
        if (error.payload.code === 'USERNAME_EXISTS') {
          messages.push('Dieser Benutzername existiert bereits.');
        }
        if (error.payload.code === 'EMAIL_EXISTS') {
          messages.push('Diese E-Mail-Adresse existiert bereits.');
        }
      }

      UIkit.modal.alert(messages.join('<br/>'));

      if (this.afterRegister) {
        if (success) {
          this.afterRegister(user);
        }
      }
    },


    emailValid: Ember.computed('email', function () {
      return (0, _validationUtils.emailValid)(this.get('email'));
    }),

    canSubmit: Ember.computed.apply(undefined, requiredFields.concat([function () {
      var _this = this;

      var values = requiredFields.map(function (field) {
        return _this.get(field);
      });

      for (var i = 0; i < values.length; i++) {
        var val = values[i];
        if (!val) {
          return false;
        }
      }

      return true;
    }])),

    postRegisterOnly: function postRegisterOnly(data) {
      var _this2 = this;

      var myStorage = this.get('myStorage');
      myStorage.user.ajaxPost({ data: data }).then(function () {
        _this2._afterRegister(true);
      }).catch(function (error) {
        _this2._afterRegister(false, error);
      });
    },
    postRegisterAndLogin: function postRegisterAndLogin(data) {
      var _this3 = this;

      var myStorage = this.get('myStorage');
      myStorage.user.ajaxPost({ data: data, param: 'register_and_login' }).then(function (user) {
        _this3._afterRegister(true, null, user);
      }).catch(function (error) {
        _this3._afterRegister(false, error);
      });
    },


    actions: {
      register: function register() {
        var isCustomer = this.get('isCustomer');

        var dataFields = userFields;
        if (!isCustomer) {
          // dont send registrationData
          dataFields = userFields.filter(function (x) {
            return x !== 'registrationData';
          });
        }

        var data = this.getProperties(dataFields);
        var siteId = this.get('cachedData.cachedSite._id');
        data.siteId = siteId;

        if (isCustomer && data.registrationData) {
          // add address to registrationData
          data.registrationData.address = data.address;
        }

        if (this.get('registerAndLogin')) {
          this.postRegisterAndLogin(data);
        } else {
          this.postRegisterOnly(data);
        }
      },
      toggleVisibility: function toggleVisibility(id) {
        var field = document.getElementById(id);
        if (field.type === 'password') {
          field.type = 'text';
          this.set('visibleField' + id, true);
        } else if (field.type === 'text') {
          field.type = 'password';
          this.set('visibleField' + id, false);
        }
      },
      check: function check() {
        var _this4 = this;

        var myStorage = this.get('myStorage');
        myStorage.user.ajaxPost({
          param: 'check',
          data: {
            userName: this.get('userName'),
            email: this.get('email')
          }
        }).then(function (res) {
          if (res.userAvailable !== undefined) {
            _this4.set('userCheck', !res.userAvailable);
          }

          if (res.emailAvailable !== undefined) {
            _this4.set('emailCheck', !res.emailAvailable);
          }
        });
      }
    }
  });
});
define('webapp/utils/countup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.setupCountup = setupCountup;
  var animationDuration = 1000; // duration
  var frameDuration = 1000 / 60; // fps
  var totalFrames = Math.round(animationDuration / frameDuration); // animation duration on fps
  var easeOutQuad = function easeOutQuad(t) {
    return t * (2 - t);
  }; // ease-out slow down

  var animateCountUp = function animateCountUp(el) {
    var frame = 0;
    var countTo = parseInt(el.innerHTML, 10);
    var counter = setInterval(function () {
      frame++;
      var progress = easeOutQuad(frame / totalFrames);
      var currentCount = Math.round(countTo * progress);

      if (parseInt(el.innerHTML, 10) !== currentCount) {
        el.innerHTML = currentCount;
      }

      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  };

  function setupCountup(el) {
    UIkit.scrollspy(el, { repeat: true });
    UIkit.util.on(el, 'inview', function () {
      animateCountUp(el);
    });
  }
});
define('webapp/components/nested-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    listClass: 'uk-nestable-list',
    isCollapsed: false,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('collapsedItems')) {
        return;
      }

      var collapsedItems = [];
      if (this.get('isCollapsed')) {
        collapsedItems = (this.get('items') || []).slice();
      }
      this.set('collapsedItems', collapsedItems);
    },


    actions: {
      toggleCollapsed: function toggleCollapsed(item, isCollapsed) {
        var collapsedItems = this.get('collapsedItems');
        if (isCollapsed) {
          collapsedItems.removeObject(item);
        } else {
          collapsedItems.addObject(item);
        }
      }
    }
  });
});
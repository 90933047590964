define('webapp/utils/file-extension', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (fullPath) {
    if (!fullPath) {
      return '';
    }

    var point = fullPath.lastIndexOf('.');

    if (point >= 0) {
      return fullPath.slice(point + 1).toLowerCase();
    } else {
      return '';
    }
  };
});
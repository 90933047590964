define('webapp/components/in-tree-checkbox', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Checkbox.extend({
    tagName: 'input',
    singleSelect: false,
    itemKey: null,

    init: function init() {
      this._super.apply(this, arguments);
      this._calculateChecked();
    },


    _obsSelectedItems: Ember.observer('selectedItems.[]', 'item', function () {
      this._calculateChecked();
    }),

    _calculateChecked: function _calculateChecked() {
      var item = this.get('item');
      var itemKey = this.get('itemKey');
      var selectedItems = this.get('selectedItems') || [];
      var tree = this.get('tree');
      var hasChildSelected = false;
      if (tree && tree.length) {
        hasChildSelected = (0, _treeUtils.findInTree)(tree, function (elem) {
          return selectedItems.includes(itemKey ? Ember.get(elem, itemKey) : elem);
        });
      }
      var checked = selectedItems.includes(item);
      var indeterminate = !checked && hasChildSelected;
      this.setProperties({ indeterminate: indeterminate, hasChildSelected: hasChildSelected, checked: checked });
    },
    change: function change() {
      this._super.apply(this, arguments);
      var checked = this.get('checked');
      var indeterminate = this.get('indeterminate');

      var item = this.get('item');
      var selectedItems = this.get('selectedItems');
      var singleSelect = this.get('singleSelect');

      if (checked) {
        selectedItems.pushObject(item);
        if (singleSelect) {
          selectedItems = [item];
          this.set('selectedItems', selectedItems);
        }
      } else {
        selectedItems.removeObject(item);
      }

      this.sendAction('toggleSelect', item);
    }
  });
});
define('webapp/services/article-container', ['exports', 'webapp/services/abstract-container'], function (exports, _abstractContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _abstractContainer.default.extend({
    cachedData: Ember.inject.service(),

    containerName: 'articleContainer',

    mapItem: function mapItem(item) {
      item.descriptions = item.descriptions && item.descriptions.map(function (desc) {
        if (desc.galleryRef) {
          desc.gallery = item.galleries && item.galleries.find(function (gal) {
            return gal.internalId === desc.galleryRef;
          });
        }
        return desc;
      });
      return item;
    },
    fetchDetail: function fetchDetail(detailSlug) {
      var _this = this;

      var myStorage = this.get('myStorage');
      return Ember.RSVP.all([myStorage.article.ajaxGet({
        param: detailSlug,
        query: {
          lang: this.get('languageService').selectedLanguage,
          site: this.get('cachedData.cachedSite._id')
        }
      }), this.get('cachedData').getFlatCategories()]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            article = _ref2[0],
            flatCategories = _ref2[1];

        _this.set('flatCategories', flatCategories);
        return article;
      });
    },
    getTitleToken: function getTitleToken() {
      return this.get('detail.title');
    }
  });
});
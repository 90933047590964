define('webapp/services/profile-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    myStorage: Ember.inject.service(),
    languageService: Ember.inject.service(),

    showEdit: false,

    fetchEntries: function fetchEntries(owner) {
      var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 20;

      var myStorage = this.get('myStorage');

      return myStorage.search.ajaxPost({ data: {
          owner: owner
        } });
    },


    ageInYears: Ember.computed('detail.additionalData.birthday', function () {
      var birthday = this.get('detail.additionalData.birthday');
      var today = new Date();
      var birthDate = new Date(birthday);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || m === 0 && today.getDate() < birthDate.getDate()) {
        age--;
      }
      return age;
    }),

    setupPage: function setupPage(pageRoute, page, params) {
      var _this = this;

      var languageService = this.get('languageService');
      var myStorage = this.get('myStorage');
      var path = params.path;
      var translatedSlug = languageService.translateSlug(page, page.detectedLanguage);
      var userName = path.slice(translatedSlug.length + 1);

      if (userName) {
        return myStorage.user.ajaxGet({ param: 'profile/' + userName }).then(function (detail) {
          detail.address = detail.address || {};
          detail.additionalData = detail.additionalData || {};

          return _this.fetchEntries(detail._id).then(function (entries) {
            _this.set('entries', entries.hits);
            _this.set('detail', detail);
            return page;
          });
        });
      } else {
        this.set('detail', null);
        return Ember.RSVP.resolve(page);
      }
    }
  });
});
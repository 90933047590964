define('webapp/components/widgets/widget-entry-categories-preview', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function defaultInt(numberString, defaultVal) {
    var num = parseInt(numberString);
    if (isNaN(num)) {
      return defaultVal;
    } else {
      return num;
    }
  }

  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    languageService: Ember.inject.service(),
    message: Ember.inject.service(),

    selectedCategory: null,

    fetchEntries: function fetchEntries(categoryId) {
      var _this = this;

      var myStorage = this.get('myStorage');
      var entrySort = this.get('data.options.entrySort');
      var maxEntries = defaultInt(this.get('data.options.maxEntries'), 5);

      // TODO sort

      return myStorage.search.ajaxPost({
        data: {
          categoryIds: [categoryId],
          itemTypes: 'entry',
          size: maxEntries
        }
      }).then(function (searchRes) {
        if (!_this.isDestroyed) {
          _this.set('items', searchRes.hits);
        }
      });
    },
    fetchCategories: function fetchCategories(selectedCategory) {
      var _this2 = this;

      var myStorage = this.get('myStorage');
      var message = this.get('message');

      Ember.RSVP.all([this.get('cachedData').getCategories(), myStorage.search.ajaxPost({
        data: { category: [selectedCategory], size: 0 },
        query: { lang: this.get('languageService.selectedLanguage') }
      })]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            categoryTree = _ref2[0],
            searchResult = _ref2[1];

        var categoryCounts = searchResult.aggs.categoryIds;
        var selectedCategoryTree = (0, _treeUtils.findInTree)(categoryTree, function (cat) {
          return cat._id === selectedCategory;
        });
        var childCats = selectedCategoryTree.children || [];
        var childCatsWithCount = childCats.map(function (cat) {
          return Ember.assign({ count: categoryCounts[cat._id] }, cat);
        });

        var categorySort = _this2.get('data.options.categorySort');
        var showEmpty = _this2.get('data.options.showEmpty');

        if (categorySort === 'count') {
          childCatsWithCount = childCatsWithCount.sort(function (a, b) {
            return b.count - a.count;
          });
        } else if (categorySort === 'title') {
          childCatsWithCount = childCatsWithCount.sort(function (a, b) {
            return a.title.localeCompare(b.title);
          });
        }

        if (!showEmpty) {
          childCatsWithCount = childCatsWithCount.filter(function (cat) {
            return cat.count > 0;
          });
        }

        if (!childCatsWithCount[0]) {
          message.error('widget-entry-categories-preview: category has no children');
          return;
        }

        if (!_this2.isDestroyed) {
          _this2.set('selectedCategory', childCatsWithCount[0]);
        }
        _this2.fetchEntries(childCatsWithCount[0]._id).then(function () {
          if (!_this2.isDestroyed) {
            var maxCategories = _this2.get('data.options.maxCategories');

            _this2.set('shownCategories', maxCategories ? childCatsWithCount.slice(0, maxCategories) : childCatsWithCount);
          }
        });
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var message = this.get('message');
      var selectedCategories = this.get('data.options.categories') || [];
      var selectedCategory = selectedCategories[0];

      if (selectedCategory) {
        this.fetchCategories(selectedCategory);
      } else {
        message.error('widget-entry-categories-preview: no category assigned');
      }
    },


    actions: {
      selectCategory: function selectCategory(category) {
        var _this3 = this;

        this.fetchEntries(category._id).then(function () {
          if (!_this3.isDestroyed) {
            _this3.set('selectedCategory', category);
          }
        });
      }
    }
  });
});
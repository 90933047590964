define('webapp/components/content-builder-view/containers/grid', ['exports', 'webapp/components/content-builder-view/containers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    classNameBindings: ['gridClass', 'options.elementPresetClass', 'options.divider:uk-grid-divider', 'gutterClass', 'coverClass'],

    attributeBindings: ['data-uk-grid-match', 'data-uk-grid-margin'],

    'data-uk-grid-margin': true,

    'data-uk-grid-match': Ember.computed('options.matchHeightClass', 'options.matchHeights', function () {
      if (this.get('options.matchHeights')) {
        var target = this.get('options.matchHeightClass');
        return '{target:\'' + target + '\'}';
      }
    }),

    gutterClass: Ember.computed('options.gutter', function () {
      var gutter = this.get('options.gutter');
      return 'uk-grid-' + gutter;
    }),

    coverClass: Ember.computed('options.coverImagesInGrid', function () {
      return this.get('options.coverImagesInGrid') ? 'uk-cover' : '';
    }),

    gridClass: "uk-grid"
  });
});
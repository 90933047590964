define('webapp/mixins/account/media-edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    showEditModal: false,
    myStorage: Ember.inject.service(),
    timestamp: 0,

    editData: {},

    resetEdit: function resetEdit() {
      this.set('editedItem', { description: '', copyright: '', title: '' });
      this.set('editedMedium', null);
      this.set('editData', {});
      this.set('showEditModal', false);
    },
    maybeTransformImage: function maybeTransformImage() {
      var _this = this;

      if (this.trafoComp) {
        return this.trafoComp.transformAndSave().then(function () {
          _this.set('timestamp', Date.now());
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },


    actions: {
      showEdit: function showEdit(item) {
        var _this2 = this;

        var myStorage = this.get('myStorage');
        var mediaId = item.mediaId;

        myStorage.mediaAdmin.ajaxGet({ param: mediaId }).then(function (fullImage) {
          _this2.set('editedMedium', fullImage);
        }).finally(function () {
          // do this in finally block, so image still gets displayed if e.g. no permission to get full image
          _this2.set('editedItem', item);
          _this2.set('editData', Ember.getProperties(item, ['title', 'description', 'copyright', 'displayType']));
          _this2.set('showEditModal', true);
        });
      },
      confirmEdit: function confirmEdit() {
        var _this3 = this;

        var editedItem = this.get('editedItem');
        var editData = this.get('editData');
        Ember.setProperties(editedItem, editData);

        this.maybeTransformImage().then(function () {
          var mainImageKey = _this3.get('mainImageKey');
          var listImageKey = _this3.get('listImageKey');

          if (mainImageKey) {
            var mainImage = _this3.get('model.' + mainImageKey);
            if (mainImage && mainImage.path === editedItem.path) {
              Ember.setProperties(mainImage, editData);
            }
          }

          if (listImageKey) {
            var listImage = _this3.get('model.' + listImageKey);
            if (listImage && listImage.path === editedItem.path) {
              Ember.setProperties(listImage, editData);
            }
          }

          _this3.resetEdit();
        });
      },
      cancelEdit: function cancelEdit() {
        this.resetEdit();
      }
    }
  });
});
define('webapp/components/widgets/widget-search', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    languageService: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    router: Ember.inject.service(),
    searchTerm: '',
    prevSearchTerm: '',
    suggestions: null,
    cmsService: Ember.inject.service(),
    fingerprintService: Ember.inject.service(),
    gdprService: Ember.inject.service(),
    hasSearch: false,
    hasLinkToGlobalSearch: Ember.computed.alias('data.options.hasLinkToGlobalSearch'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('selectedCategories', []);

      var searchContainerId = this.get('data.options.searchContainer');
      if (searchContainerId) {
        this.get('cachedData').getFlatPages().then(function (flatPages) {
          var searchContainer = flatPages.find(function (x) {
            return x._id === searchContainerId;
          });
          _this.set('searchContainer', searchContainer);
          if (searchContainer) {
            _this.get('cachedData').getCategories().then(function (res) {
              _this.setupCategories(res.slice(), searchContainer);
            });
          }
        });
      }
    },
    setupCategories: function setupCategories(rawCategories, searchContainer) {
      var categoryFilters = [];
      var rootCategories = searchContainer.options.rootCategories;

      if (rootCategories && rootCategories.length) {
        rootCategories.forEach(function (catId) {
          var foundCat = (0, _treeUtils.findInTree)(rawCategories, function (x) {
            return x._id === catId;
          });
          if (foundCat && foundCat.children && foundCat.children.length) {
            var flatCategories = (0, _treeUtils.flattenTree)(foundCat.children).filter(function (x) {
              return !x.noDisplay && x.level > 2;
            });

            categoryFilters.push({
              title: foundCat.title,
              type: foundCat.type,
              categories: foundCat.children,
              flatCategories: flatCategories
            });
          }
        });
      }

      this.set('categoryFilters', categoryFilters);
    },
    _searchAsYouType: function _searchAsYouType(data, query) {
      var _this2 = this;

      var myStorage = this.get('myStorage');
      var param = 'search';
      return myStorage.globalSearch.ajaxPost({ param: param, data: data, query: query }).then(function (searchResult) {
        _this2.set('suggestions', searchResult.hits);
      });
    },
    searchAsYouType: function searchAsYouType(searchTerm) {
      var _this3 = this;

      var fingerprintService = this.get('fingerprintService');
      var options = this.get('data.options');
      if (searchTerm.length) {
        this.set('hasSearch', true);
        var filter = void 0;
        var selectedCategories = this.get('selectedCategories');
        if (selectedCategories.length) {
          filter = { terms: { 'categories._id': selectedCategories.map(function (x) {
                return x._id;
              }) } };
        }
        var gdprStatus = this.get('gdprService.gdprStatus');
        return fingerprintService.getFingerprint().then(function (fingerprint) {
          var searchRequest = {
            types: options.types || ['pages'],
            searchTerm: searchTerm,
            size: options.perPage || 5,
            filter: filter
          };
          if (gdprStatus !== 'accepted') {
            fingerprint = undefined;
          }
          var sysData = {
            lang: _this3.get('languageService.selectedLanguage'),
            site: _this3.get('cachedData.cachedSite._id'),
            sessionId: _this3.get('cmsService.sessionId'),
            fingerprint: fingerprint,
            gdprStatus: gdprStatus
          };

          return _this3._searchAsYouType(searchRequest, sysData);
        });
      } else {
        this.set('hasSearch', false);
        this.set('suggestions', null);
      }
    },
    resetSearch: function resetSearch() {
      this.set('suggestions', null);
      this.set('searchTerm', '');
      this.set('prevSearchTerm', '');
    },
    confirmSearch: function confirmSearch() {
      var router = this.get('router');
      var searchContainer = this.get('searchContainer');

      if (searchContainer) {
        var searchTerm = this.get('searchTerm');
        var selectedCategories = this.get('selectedCategories');

        var wildcardParam = selectedCategories.map(function (x) {
          return x.slug;
        }).join('/');
        if (wildcardParam) {
          wildcardParam += '/';
        }
        if (searchTerm) {
          wildcardParam += searchTerm;
        }

        var searchPageRoute = this.getSearchPageRoute(searchContainer, wildcardParam);
        router.transitionTo('page', searchPageRoute);
      }
    },
    getSearchPageRoute: function getSearchPageRoute(searchContainer, addSeg) {
      var languageService = this.get('languageService');
      var pageRoute = languageService.translateSlug(searchContainer);

      if (addSeg) {
        pageRoute += '/' + addSeg;
      }

      return pageRoute;
    },


    actions: {
      searchKeyup: function searchKeyup(e) {
        var searchTerm = e.target.value;
        this.set('searchTerm', searchTerm);
        if (e.key === 'Escape') {
          this.resetSearch();
        } else if (e.key === 'Enter') {
          this.confirmSearch();
        } else if (searchTerm !== this.prevSearchTerm) {
          this.prevSearchTerm = searchTerm;
          Ember.run.debounce(this, this.searchAsYouType, searchTerm, 200);
        }
      },
      search: function search() {
        Ember.run.debounce(this, this.searchAsYouType, this.get('searchTerm'), 200);
      },
      confirmSearch: function confirmSearch() {
        this.confirmSearch();
      },
      selectSuggestion: function selectSuggestion(suggestion) {
        this.set('searchTerm', suggestion.replace(/<.*?>/g, ''));
        this.set('suggestions', null);
      },
      categoriesChanged: function categoriesChanged(cats) {
        this.set('selectedCategories', cats);
      },
      toggleCategory: function toggleCategory(cat) {
        var selectedCategories = this.get('selectedCategories');
        var foundCat = selectedCategories.find(function (sCat) {
          return sCat._id === cat._id;
        });
        if (foundCat) {
          this.set('selectedCategories', selectedCategories.filter(function (x) {
            return x !== foundCat;
          }));
        } else {
          this.set('selectedCategories', [].concat(_toConsumableArray(selectedCategories), [cat]));
        }
      },
      clearSearch: function clearSearch() {
        this.set('searchTerm', '');
        this.set('hasSearch', false);
        this.set('suggestions', null);
      }
    }
  });
});
define('webapp/components/number-input-fixed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'input',
    //attributeBindings: ['type', 'shownValue:value', 'min', 'max', 'step', 'disabled'],
    type: 'number',
    min: null,
    step: 0.01,
    precision: 2,

    multiplier: Ember.computed('precision', function () {
      var precision = this.get('precision');
      return Math.pow(10, precision);
    }),

    shownValue: '',

    didReceiveAttrs: function didReceiveAttrs() {
      var data = this.get('attr-data');
      if (data === undefined) {
        this.set('attributeBindings', ['type', 'shownValue:value', 'min', 'max', 'step', 'disabled']);
      } else {
        this.set('attributeBindings', ['type', 'shownValue:value', 'min', 'max', 'step', 'disabled', 'attr-data']);
      }

      var multiplier = this.get('multiplier');
      var value = Number(this.get('value')) / multiplier;
      this.set('shownValue', value.toFixed(this.get('precision')));
    },
    keyUp: function keyUp() {
      Ember.run.debounce(this, this.handleChange, 1000);
    },
    change: function change() {
      this.handleChange();
    },
    handleChange: function handleChange() {
      var multiplier = this.get('multiplier');
      var value = Math.round(Number(this.element.value) * multiplier);
      this.onChange && this.onChange(value, this.element);
      this.set('value', value);
    }
  });
});
define('webapp/routes/account/offers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    message: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    languageService: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),

    model: function model() {
      var myStorage = this.get('myStorage');
      var query = { lang: this.get('languageService.selectedLanguage') };

      return myStorage.offer.ajaxGet({ query: query }).then(function (res) {
        return res;
      });
    },
    removeObject: function removeObject(offerId) {
      var controller = this.controllerFor(this.routeName);
      var hits = controller.get('model.hits');
      var offers = controller.get('model.model');
      var model = [];

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = offers[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var offer = _step.value;

          if (offer._id === offerId) {
            hits -= 1;
          } else {
            model.push(offer);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      controller.set('model', { hits: hits, model: model });
    },


    actions: {
      acceptOffer: function acceptOffer(offerId) {
        var _this = this;

        var myStorage = this.get('myStorage');
        var shoppingCartService = this.get('shoppingCartService');
        var param = 'accept/' + offerId;

        return myStorage.offer.ajaxPut({ param: param }).then(function () {
          _this.removeObject(offerId);
          shoppingCartService.reset();
          shoppingCartService.fetchShoppingCart();
          _this.transitionTo('checkout.cart');
        });
      },
      rejectOffer: function rejectOffer(offerId) {
        var _this2 = this;

        var myStorage = this.get('myStorage');
        var message = this.get('message');
        var param = 'reject/' + offerId;
        var i18n = this.get('i18n');
        var rejectOfferMessage = i18n.t("offer.ui.rejectOfferMessage").toString();

        UIkit.modal.prompt(rejectOfferMessage).then(function (rejectionReason) {
          var data = { rejectionReason: rejectionReason };
          return myStorage.offer.ajaxPut({ param: param, data: data }).then(function () {
            _this2.removeObject(offerId);
            message.infoT('offer.ui.offerRejected');
          });
        });
      }
    }
  });
});
define('webapp/services/product-spare-part-container', ['exports', 'webapp/services/product-sub-container'], function (exports, _productSubContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _productSubContainer.default.extend({
    containerName: 'productSparePartContainer',
    type: 'consumableSpare'
  });
});
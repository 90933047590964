define("webapp/locales/it/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.entryBannerImageNew": "Specificare un'immagine principale per il vostro ingresso.",
    "account.ui.profileBannerImageNew": "Definire un'immagine principale per il proprio profilo.",
    "account.ui.register": "registro",
    "account.ui.personate": "Impersonare un utente",
    "account.ui.registeredAs": "Sei attualmente registrato come",
    "account.ui.userNameNotEditableTooltip": "Il tuo nome utente. È possibile modificare il nome utente inserito nelle impostazioni. Importante Dopo la modifica, è necessario effettuare nuovamente il login con il nuovo nome utente.",
    "accountTypes.aussteller": "esponente",
    "accountTypes.gastgeber": "gasterène",
    "accountTypes.veranstalter": "ente",
    "application.beta.infotext": "Questa funzione viene visualizzata solo nella versione beta e non è disponibile nella versione beta.",
    "application.breadcrumbsHome": "Thuis",
    "application.month": "da maand",
    "application.newsletter.alreadySubscribed": "L'utente è già registrato in {{list}}. Bollettini informativi. Hai accesso agli abbonamenti alla newsletter nel tuo account.",
    "application.newsletter.alreadySubscribedNoList": "Sei già iscritto alla newsletter",
    "application.newsletter.goToList": "Mailinglijst",
    "application.newsletter.subscribeButton": "Inserisci il tuo indirizzo e-mail per iscriverti alla newsletter",
    "application.newsletter.subscribeButtonMobile": "E-mail",
    "application.newsletter.noAccess": "Non è possibile iscriversi alla newsletter proposta con il proprio permesso.",
    "application.newsletter.subscribe": "ondertekenen",
    "application.newsletter.subscribeList": "Iscriviti a questa newsletter:",
    "application.newsletter.subscribeThanks": "Iscriviti alla newsletter. Riceverai un'e-mail di conferma per l'opt-in prima dell'attivazione.",
    "application.newsletter.subscribeWithAccount": "Registrati con il tuo utente attualmente connesso.",
    "application.noCosts": "Gratuito",
    "application.placeholderImageText": "Senza illustrazione",
    "application.searchAllGroups": "en totaal",
    "application.searchAllRegions": "dappertutto",
    "application.ui.access": "oproep",
    "application.ui.action": "attività",
    "application.ui.addDocument": "Aggiungere un documento",
    "application.ui.addRelation": "Aggiungi connessione",
    "application.ui.addImage": "Aggiungere un'immagine",
    "application.ui.addImages": "Aggiunta di foto",
    "application.ui.addMedia": "Aggiunta di foto/video",
    "application.ui.addVideo": "Aggiungere un video",
    "application.ui.advancedEdit": "Uitgebreid",
    "application.ui.all": "tous",
    "application.ui.alphabetical": "Alfabetisch",
    "application.ui.at": "a",
    "application.ui.buy": "Acquista",
    "application.ui.back": "indietro",
    "application.ui.certificate": "solleticato",
    "application.ui.confirmDeleteEntry": "Eliminare irrevocabilmente la voce {{title}}?",
    "application.ui.count": "quota",
    "application.ui.currentlyActive": "Porta di scarico ad anello",
    "application.ui.deleteEntry": "feritoie di porte",
    "application.ui.discardUnsavedChanges": "Gettando via i cambiamenti e continuando con i cambiamenti.",
    "application.ui.discardUnsavedChangesMobile": "jetant",
    "application.ui.distance": "onhechting",
    "application.ui.distant": "arraché",
    "application.ui.dreampoints": "Droompunten",
    "application.ui.dreampointsAllDreams": "Sogni delle tue voci",
    "application.ui.dreampointsDonate": "Punti sogno per dare",
    "application.ui.dreampointsTooltip": "Puoi raccogliere i Dreampoints con il tuo invio",
    "application.ui.edit": "wijzigen",
    "application.ui.editDescription": "Modificare la descrizione",
    "application.ui.editEntry": "attività",
    "application.ui.editEvent": "Applicare l'evento",
    "application.ui.editImage": "Modificare un'immagine",
    "application.ui.editPoi": "ammaliato Locatie",
    "application.ui.editProduct": "Applicare al prodotto",
    "application.ui.editProfile": "Modificare il mio profilo",
    "application.ui.editShownProfile": "Modificare il mio profilo pubblicitario",
    "application.ui.entriesFocus": "Oggetti Geselecteerde in Focus :",
    "application.ui.entriesLatest": "Récemment inzendingen in Focus :",
    "application.ui.for": "in ruil voor",
    "application.ui.fullView": "algemene visie",
    "application.ui.gallery": "immagini",
    "application.ui.galleryImages": "alla galleria...",
    "application.ui.gavePoints": "ti ha dato {punti} punti.",
    "application.ui.goToEntry": "Chiamate",
    "application.ui.goToEvent": "Serata Bel",
    "application.ui.goToPoi": "belplaats",
    "application.ui.goToProduct": "Vedere il prodotto",
    "application.ui.hasBeen": "che",
    "application.ui.here": "poeta",
    "application.ui.invalidEmbed": "Questo URL non può essere integrato.",
    "application.ui.isActive": "attività",
    "application.ui.isNotActive": "Gedeactiveerd",
    "application.ui.knowMore": "Scopri di più su {{about}}",
    "application.ui.language.de": "Deutsch",
    "application.ui.language.en": "English",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "Italiano",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "Lingua attualmente selezionata",
    "application.ui.language.switchTo": "Cambia la lingua in ",
    "application.ui.mailBodyPre": "Salve, ho trovato qualcosa di interessante:",
    "application.ui.mailSubject": "Ho trovato un link interessante",
    "application.ui.mandatory": "veld promesse",
    "application.ui.memberSince": "Le palpebre sono",
    "application.ui.mobileRotate": "Rotazione per la visualizzazione a schermo intero",
    "application.ui.more": "aliante",
    "application.ui.name": "Nome",
    "application.ui.navigateOneLevelUp": "Torna alla cartella principale",
    "application.ui.new": "Nieuw",
    "application.ui.newEntry": "Crea un nuovo articolo",
    "application.ui.newEvent": "Creare un nuovo evento aziendale",
    "application.ui.newPoi": "Creare una nuova posizione",
    "application.ui.newProduct": "Creare un nuovo prodotto",
    "application.ui.noEntry": "Non avete ancora fatto un'iscrizione. Ora crea la prima voce",
    "application.ui.noEntryProfile": "non ha ancora fatto un'entrata.",
    "application.ui.noEvent": "Non hai ancora creato un corso di formazione aziendale. Crea il tuo primo evento ora",
    "application.ui.noPoi": "Non hanno ancora creato un sito. Crea la tua prima posizione ora",
    "application.ui.noJob": "Non avete ancora creato un lavoro. Crea ora il tuo primo lavoro...",
    "application.ui.noProduct": "Non hai ancora fabbricato un prodotto. Crea il tuo primo prodotto ora",
    "application.ui.notEditable": "non può essere cambiato",
    "application.ui.nothingFound": "Non abbiamo trovato alcun risultato per questa ricerca.",
    "application.ui.offer": "offerandes",
    "application.ui.only": "emittenti",
    "application.ui.openDocument": "Bestand ouvre",
    "application.ui.or": "o",
    "application.ui.playVideo": "Video in riproduzione",
    "application.ui.readMore": "Lees meer",
    "application.ui.region": "settore",
    "application.ui.relevance": "Pertinente",
    "application.ui.reset": "Resettare",
    "application.ui.routingDescription": "Come raggiungerci",
    "application.ui.saveShownProfile": "Salvare le modifiche al profilo",
    "application.ui.saveUnsavedChanges": "Opslaan",
    "application.ui.showAllEntries": "Tutti",
    "application.ui.showAllSearch": "Mostra tutti i risultati",
    "application.ui.showMoreSearch": "Mostra altri risultati di ricerca",
    "application.ui.resetSearch": "Azzeramento della selezione e nuova ricerca",
    "application.ui.sort": "triage",
    "application.ui.stockPhotoLinks": "Collegamenti a database di immagini",
    "application.ui.suitableFor": "Consigliato per",
    "application.ui.total": "totaal",
    "application.ui.totalDreampoints": "Sogni di tutte le tue voci",
    "application.ui.trySearch": "Vuoi provare altri termini di ricerca, come ad esempio",
    "application.ui.type": "modello",
    "application.ui.unsavedChanges": "Modifiche non salvate",
    "application.ui.updated": "bijgewerkt",
    "application.ui.uploadedFileFor": "File caricato per :",
    "application.ui.userName": "inlognaam",
    "application.ui.visibleForAll": "Visibile a tutti gli utenti",
    "application.ui.visibleForFollower": "Visibile ai tifosi",
    "application.ui.visibleForJoiner": "Visibile al falegname",
    "application.ui.visibleForNone": "Visibile a nessuno",
    "application.ui.visibleForSupporter": "Visibile ai sostenitori",
    "application.ui.writeUs": "Scrivici...",
    "application.ui.years": "Anni",
    "application.ui.yearsFew": "Pochi anni",
    "application.widgets.highestRatedEntries": "La maggior parte dei sogni",
    "application.widgets.inFocus": "A fuoco",
    "application.widgets.newestEntries": "Le ultime voci",
    "blog.ui.category": "Categoria Categoria Categoria Categoria",
    "blog.ui.categoryHeading": "Uit de categorie",
    "blog.ui.lastUpdated": "Ultimo aggiornamento",
    "blog.ui.openSinglePost": "Alla vista articolo",
    "blog.ui.publishedIn": "Pubblicato in",
    "blog.ui.subCategories": "sottocategoria",
    "blog.ui.written": "scagliatoio",
    "blog.ui.writtenBy": "Scritto da",
    "buttons.alreadyMeToo": "Sono contento che anche tu stia sognando questa entrata.",
    "buttons.alreadyMeTooMobile": "Sono contento che anche tu stia sognando questa entrata.",
    "buttons.cancel": "rotto",
    "buttons.createAccount": "Aprire un nuovo EntryerAccount in",
    "buttons.delete": "feritoie di porte",
    "buttons.deleteFavorite": "volume del rivetto",
    "buttons.deleteLike": "Non mi piace più.",
    "buttons.meToo": "E' quello che voglio fare.",
    "buttons.newPassword": "Richiedi una nuova password",
    "buttons.ok": "Ok",
    "buttons.ready": "Claar",
    "buttons.save": "Registrazione delle modifiche",
    "buttons.saveMobile": "Opslaan",
    "buttons.selectThis": "Selezionare questa opzione",
    "buttons.tryAgain": "Prova di nuovo",
    "category.ui.hideCategories": "Nascondi sottocategorie",
    "category.ui.oneLevelUp": "Torna alla categoria superiore",
    "category.ui.showCategories": "Sottocategorie di visualizzazione",
    "categoryFields.additionalGauge": "",
    "categoryFields.additionalWeight": "",
    "categoryFields.attachmentWidth": "Larghezza dell'allegato",
    "categoryFields.clutch": "Frizione",
    "categoryFields.companyName": "Nome dell'azienda",
    "categoryFields.dealerInfo": "Informazioni sul rivenditore",
    "categoryFields.diameter": "Diametro dei pneumatici",
    "categoryFields.displayNetPrice": "Prezzo netto",
    "categoryFields.electricalPower": "Potenza elettrica",
    "categoryFields.engine": "Motore",
    "categoryFields.engineDescription": "Equipaggiamento del motore",
    "categoryFields.ERPname": "Nome in ERP",
    "categoryFields.horsePower": "Potenza del motore",
    "categoryFields.netWidth": "Calibro senza pneumatici",
    "categoryFields.packingUnit": "Unità di imballaggio",
    "categoryFields.PTO": "PTO",
    "categoryFields.relativeGauge": "Allarga la pista di",
    "categoryFields.sectionWidth": "",
    "categoryFields.vendorAcronym": "Denominazione abbreviata",
    "categoryFields.volume": "Volume",
    "categoryFields.weight": "Peso",
    "categoryFields.wheelLock": "Blocco ruota in piedi",
    "comments.blockComments": "I commenti su questo punto sono stati finalizzati.",
    "comments.comment.anonymous": "anonima",
    "comments.comment.button": "Scrivi un nuovo commento",
    "comments.comment.buttonReply": "ribattere",
    "comments.comment.cancel": "rotto",
    "comments.comment.content": "Uw ha commentato",
    "comments.comment.hasCommented": "geanotecnico",
    "comments.comment.login": "Accedi per inviare un commento",
    "comments.comment.past": "leva",
    "comments.comment.replyFrom": "Risposta alla",
    "comments.comment.showReplies": "Visualizza le risposte",
    "comments.comment.showReply": "Password Toon",
    "comments.comment.submit": "verzending",
    "comments.comment.title": "titolo del commento",
    "comments.comment.userName": "Il tuo nome utente",
    "comments.heading": "Suggerimenti, osservazioni, commenti, osservazioni, commenti, osservazioni, osservazioni, commenti, commenti, commenti",
    "comments.hierarchy.flat": "congiura",
    "comments.hierarchy.nested": "Genezen",
    "comments.hierarchy.title": "Vedere i commenti:",
    "comments.message.afterSaveError": "Il tuo commento non può essere salvato in questo momento!",
    "comments.message.afterSaveSuccess": "Il suo commento è stato registrato.",
    "comments.message.afterSaveUnlock": "Il tuo commento è stato registrato, ma deve essere esaminato da un amministratore.",
    "comments.noComments": "Non sono ancora stati fatti commenti.",
    "comments.reply": "ACCESO:",
    "comments.show": "Visualizza i commenti",
    "comments.title": "critiche",
    "entries.edit": "Modificare la voce <strong>{{title}}</strong> Modificare la voce.",
    "entries.entries": "inzendingen",
    "entries.entry.fields.address.city": "stadje",
    "entries.entry.fields.address.country": "paese",
    "entries.entry.fields.address.houseNumber": "numero civico",
    "entries.entry.fields.address.street": "straat",
    "entries.entry.fields.address.zip": "POSTCODE",
    "entries.entry.fields.prefixMissing": "I seguenti campi non sono compilati:",
    "entries.entry.profile.edit": "profilo sconcertato",
    "entries.entry.profile.publicProfile": "alla pagina del mio profilo pubblico",
    "entries.entry.tooltip.alreadyCopied": "Stai già sognando questa entrata.",
    "entries.entry.tooltip.alreadyFollow": "State già seguendo molto da vicino questo annuncio.",
    "entries.entry.tooltip.alreadyLiked": "Ti piace già questa menzione",
    "entries.entry.tooltip.copied": "Sono contento che anche tu voglia sognare questa entrata. La foto del tuo profilo è stata aggiunta a questo articolo.",
    "entries.entry.tooltip.filterComments": "Clicca qui per visualizzare solo i commenti in Entryline",
    "entries.entry.tooltip.filterFollows": "Fare clic qui per visualizzare solo la traccia nella riga di registrazione.",
    "entries.entry.tooltip.filterLikes": "Clicca qui per visualizzare solo i Mi piace nell'Entryline.",
    "entries.entry.tooltip.filterShares": "Clicca qui per vedere solo le azioni di Entryline.",
    "entries.entry.tooltip.follow": "Clicca qui per seguire questa voce",
    "entries.entry.tooltip.like": "Clicca qui per dare a questa voce un Like.",
    "entries.entry.tooltip.noFollow": "Seguire solo i lavori per gli ingressi esterni",
    "entries.entry.tooltip.noFollowNotLoggedIn": "Devi essere loggato per seguire questo messaggio.",
    "entries.entry.tooltip.noLike": "Come lavorare solo con gli stranieri...",
    "entries.entry.ui.access": "Vedo solo questa menzione",
    "entries.entry.ui.added": "...è stato aggiunto.",
    "entries.entry.ui.addEntry": "Aggiunta di voci",
    "entries.entry.ui.addOpening": "Aggiungere l'orario di apertura",
    "entries.entry.ui.address": "Indirizzo Mijn",
    "entries.entry.ui.addStep": "Aggiungi un passo",
    "entries.entry.ui.agreementHours": "Aperto solo su appuntamento",
    "entries.entry.ui.allAccess": "Tutti possono vedere questa entrata",
    "entries.entry.ui.assignedCategories": "categorie interessate",
    "entries.entry.ui.assignedCertificates": "Certificati Mijn",
    "entries.entry.ui.assignedProducts": "prodotti interessati",
    "entries.entry.ui.businessContact": "Dati di contatto dell'azienda",
    "entries.entry.ui.categories": "Categorie sotto le quali posso essere trovato",
    "entries.entry.ui.categoryFields": "Maggiori informazioni",
    "entries.entry.ui.commercialTitle": "Informazioni sull'azienda in merito alla registrazione",
    "entries.entry.ui.companyTitle": "Dati di contatto della mia azienda",
    "entries.entry.ui.contactPerson": "I miei dati di contatto",
    "entries.entry.ui.cssClass": "Classe CSS per i contenuti",
    "entries.entry.ui.deliveryInfo": "Informazioni sul servizio di consegna",
    "entries.entry.ui.descriptionInfo": "Suggerimento: se si selezionano le parole, è possibile assegnare gli stili.",
    "entries.entry.ui.descriptionPlaceholder": "Descrizione con HTML. Fare doppio clic sul testo nella barra degli strumenti HTML (fino a 600 caratteri).",
    "entries.entry.ui.editEntry": "Modifica di una voce",
    "entries.entry.ui.editEntryNew": "Creare una voce",
    "entries.entry.ui.exclusionHours": "periodi di esclusione",
    "entries.entry.ui.galleryImages": "Galleria fotografica (2 o 3 foto a seconda della configurazione)",
    "entries.entry.ui.globalTitle": "nausea",
    "entries.entry.ui.hasKiosk": "Ho uno stand di vendita...",
    "entries.entry.ui.imagesHorizontal": "Orizzontale",
    "entries.entry.ui.imagesInfo": "Le immagini possono essere modificate in \"Immagini/Video/Documenti\".",
    "entries.entry.ui.imagesOrientation": "Allineamento del telaio",
    "entries.entry.ui.imagesSquared": "parentesi",
    "entries.entry.ui.imagesVertical": "Verticale",
    "entries.entry.ui.isListImage": "Questa schermata è la schermata di panoramica.",
    "entries.entry.ui.isMainImage": "Questa immagine è l'immagine più importante",
    "entries.entry.ui.isMainImageAndListImage": "Questa schermata viene visualizzata come schermata principale della voce.",
    "entries.entry.ui.kioskDescription": "Descrizione del vostro stand",
    "entries.entry.ui.listImage": "elenco immagine",
    "entries.entry.ui.logo": "stemma",
    "entries.entry.ui.longDescription": "Testo descrittivo lungo",
    "entries.entry.ui.mainImage": "schermata principale",
    "entries.entry.ui.makeListImage": "Definire questa schermata come schermata di panoramica.",
    "entries.entry.ui.makeListImageTooltip": "I risultati della ricerca vengono visualizzati in forma di elenco con la schermata di panoramica. Se non è selezionata alcuna schermata di panoramica, viene utilizzata la schermata principale.",
    "entries.entry.ui.makeMainImage": "Definire questa schermata come schermata principale",
    "entries.entry.ui.makeMainImageTooltip": "La schermata principale viene visualizzata nella parte superiore del profilo. Le immagini non selezionate come immagini principali o di elenco vengono visualizzate nella galleria fotografica.",
    "entries.entry.ui.metaDescription": "breve descrizione",
    "entries.entry.ui.metaDescriptionPlaceholder": "Breve descrizione con un massimo di 200 caratteri",
    "entries.entry.ui.multiCode": "Codice aggiuntivo per questo passaggio",
    "entries.entry.ui.multiDescription": "Descrizione di questa fase",
    "entries.entry.ui.multiImages": "Foto per questo passo",
    "entries.entry.ui.multiProgress": "anticipo",
    "entries.entry.ui.multiTitle": "Titolo di questa fase",
    "entries.entry.ui.multiTitlePlaceholder": "Titolo per questo passaggio (fino a 50 caratteri)",
    "entries.entry.ui.noDescriptions": "La voce non ha ancora un testo lungo.",
    "entries.entry.ui.noEntries": "Nessuna voce trovata.",
    "entries.entry.ui.onlyVisibleForCustomers": "Questa voce è visibile solo ai clienti registrati.",
    "entries.entry.ui.openAt": "aprirsi",
    "entries.entry.ui.openingHours": "orari di apertura",
    "entries.entry.ui.openingInfo": "Note sugli orari di apertura",
    "entries.entry.ui.openingReligious": "Discorso in una comunità prevalentemente cattolica",
    "entries.entry.ui.overallProgress": "Questa voce è fatta a {{value}}%.",
    "entries.entry.ui.parking": "parcheggio",
    "entries.entry.ui.parkingLots": "cifra",
    "entries.entry.ui.parkingType": "scherzare",
    "entries.entry.ui.personImage": "Foto di contatto",
    "entries.entry.ui.relatedEvents": "Eventi correlati",
    "entries.entry.ui.rentKiosk": "Vorrei affittare uno stand",
    "entries.entry.ui.resolveAddress": "Determinazione delle coordinate",
    "entries.entry.ui.resolveAddressInfo": "Determina il Geopoint (posizione sulla mappa) dal tuo indirizzo. Senza un geopunto corretto, potrebbero non essere facili da trovare.",
    "entries.entry.ui.resolveGeolocation": "Indirizzo Bepalen",
    "entries.entry.ui.routingPlaceholder": "Inserisci qui il tuo itinerario in HTML.",
    "entries.entry.ui.selectEvent": "Selezionare evento",
    "entries.entry.ui.shortDescriptionInfo": "Questa è allo stesso tempo la breve descrizione",
    "entries.entry.ui.sharing": "Condividi questa pagina su {{sharing}}",
    "entries.entry.ui.shareMail": "Condividi questa pagina via email",
    "entries.entry.ui.skillsNeeded": "Per la mia presentazione, posso davvero utilizzare...",
    "entries.entry.ui.sortword": "Titolo alternativo per l'ordinamento",
    "entries.entry.ui.step": "pergola",
    "entries.entry.ui.stepInDream": "In quale fase della registrazione è questa fase?",
    "entries.entry.ui.stepInDreamAfter": "na realisatie",
    "entries.entry.ui.stepInDreamBefore": "per la realizzazione",
    "entries.entry.ui.stepInDreamDuring": "durante l'attuazione",
    "entries.entry.ui.subtitle": "Sottotitolo descrittivo",
    "entries.entry.ui.subtitlePlaceholder": "Inserimento dei sottotitoli (fino a 60 caratteri)",
    "entries.entry.ui.tabCategories": "classi",
    "entries.entry.ui.tabCategoriesMobile": "Kat.",
    "entries.entry.ui.tabCompany": "informatica aziendale",
    "entries.entry.ui.tabDescription": "Descrizione del",
    "entries.entry.ui.tabDescriptionMobile": "cercapersone",
    "entries.entry.ui.tabExhibitor": "coni di esposizione",
    "entries.entry.ui.tabImages": "Foto/Video/Documenti",
    "entries.entry.ui.tabImagesMobile": "Foto/Video/File",
    "entries.entry.ui.tabRelations": "incollato",
    "entries.entry.ui.tags": "etichette",
    "entries.entry.ui.title": "La voce appare sotto i seguenti nomi",
    "entries.entry.ui.titleError": "La voce deve essere menzionata prima di salvare i dati.",
    "entries.entry.ui.titlePlaceholder": "Inserisci il titolo (fino a 50 caratteri)",
    "entries.entry.ui.visible": "Questo elemento è visibile",
    "entries.entry.ui.promotion": "Partecipazione ad azioni per questa voce",
    "entries.entry.ui.regions": "Categorie regionali per questa voce",
    "entries.job.ui.promotion": "Partecipazione alle azioni per questo lavoro",
    "entries.event.edit": "Modificare un evento <strong>{{title}}</strong>.",
    "entries.event.ui.addEvent": "Aggiungi un evento",
    "entries.event.ui.cooperation": "Partenaire Partner di cooperazione",
    "entries.event.ui.directions": "Come raggiungerci",
    "entries.event.ui.editEvent": "Applicare l'evento",
    "entries.event.ui.editEventNew": "Creare un evento aziendale",
    "entries.event.ui.endDate": "Fine dell'evento (giorno)",
    "entries.event.ui.eventDone": "rapporto fiera",
    "entries.event.ui.noEvents": "Nessun evento trovato",
    "entries.event.ui.openEvent": "Serata Bel",
    "entries.event.ui.opening": "orari di apertura",
    "entries.event.ui.startDate": "Inizio dell'evento (giorno)",
    "entries.event.ui.tabCategories": "classi",
    "entries.event.ui.tabDescription": "Descrizione del",
    "entries.event.ui.tabOpening": "Data e ora",
    "entries.event.ui.tabRelations": "incollato",
    "entries.event.ui.title": "L'evento appare sotto i seguenti nomi",
    "entries.event.ui.upcoming": "nel prossimo futuro",
    "entries.event.ui.moreEvents": "Altri eventi",
    "entries.open": "Chiamare una voce nel frontend",
    "entries.person.ui.company": "standvastig",
    "entries.person.ui.female": "vrouw",
    "entries.person.ui.fullName": "Nome e cognome",
    "entries.person.ui.fullSize": "Completamente formattato",
    "entries.person.ui.gender": "Genere",
    "entries.person.ui.greeting": "piumaggiormente",
    "entries.person.ui.male": "baaaa",
    "entries.person.ui.open": "Direttamente all'ingresso di questa persona",
    "entries.person.ui.personAddress": "Adres van de persoon",
    "entries.person.ui.personContact": "Dati personali",
    "entries.person.ui.personImage": "persoonsimago",
    "entries.person.ui.position": "Posizione/Tipo",
    "entries.person.ui.quote": "Citazione / illustrazione sottotitoli",
    "entries.person.ui.signature": "tipicamente beeld",
    "entries.poi.ui.title": "La posizione appare sotto i seguenti nomi",
    "entries.product.edit": "Modificare il prodotto <strong>{{title}}</strong>.",
    "entries.product.fields.engine": "forza motrice",
    "entries.product.fields.horsePower": "apte",
    "entries.product.menu.allAttachments": "Prospetto allegati",
    "entries.product.menu.allCarriers": "Panoramica dei vettori",
    "entries.product.menu.attachments": "Attaccamenti",
    "entries.product.menu.carriers": "Veicoli da trasporto",
    "entries.product.tags.eco": "Ecologico",
    "entries.product.tags.neu": "Nuova",
    "product.ui.content": "Contenuti",
    "product.ui.ingredients": "Componenti",
    "product.ui.ingredientsFood": "Ingredienti",
    "product.ui.nonPhysical": "Scarica il prodotto elettronico",
    "entries.product.ui.addProduct": "Aggiungi un prodotto",
    "entries.product.ui.equipmentVariants": "Varianti di equipaggiamento",
    "entries.product.ui.variants": "Varianti",
    "entries.product.ui.gauge": "Allevamento in cm",
    "entries.product.ui.gaugeMin": "Larghezza minima della carreggiata in cm",
    "entries.product.ui.inclusive": "Incluso",
    "entries.product.ui.live": "Ervaring en direct",
    "entries.product.ui.netPrize": "Prezzo netto più IVA. Senza attrezzature e accessori speciali. Ex fabbrica di Entschenreuth, più i costi di imballaggio, trasporto, assicurazione di trasporto, montaggio e messa in servizio.",
    "entries.product.ui.noProducts": "Nessun prodotto trovato",
    "entries.product.ui.noPurchaseOnThisPage": "Se non è possibile effettuare un ordine su questa pagina, si prega di chiamare il prodotto.",
    "entries.product.ui.onlyBasicVariant": "Solo nella versione base",
    "entries.product.ui.onlyWithCarrier": "Disponibile solo con l'acquisto di un'unità base",
    "entries.product.ui.order": "Aggiungi al carrello",
    "entries.product.ui.overview": "Panoramica del",
    "entries.product.ui.prizeFrom": "da ",
    "entries.product.ui.noPrize": "Chiedete il prezzo al vostro interlocutore.",
    "entries.product.ui.required": "Viali con",
    "entries.product.ui.suitableFor": "Storia",
    "entries.product.ui.suitableForAttachments": "Adatto per i seguenti accessori",
    "entries.product.ui.suitableForCarriers": "Adatto per i seguenti veicoli",
    "entries.product.ui.title": "Prodotto",
    "entries.product.ui.variant": "Varie/Fascio",
    "entries.product.ui.variantNotOrderable": "La variante selezionata non può essere ordinata",
    "entries.product.ui.bundleparts": "comprendente",
    "entries.product.ui.chooseVariant": "Seleziona la variante",
    "entries.product.ui.chooseVariantMobile": "Seleziona variante",
    "entries.progress.title": "Come migliorare la tua registrazione",
    "entries.product.ui.combinationNotAvailable": "Combinazione non disponibile",
    "entries.tabbar.basics": "basi",
    "entries.tabbar.entry": "dati inseriti",
    "entries.tabbar.gallery": "immagini",
    "entries.tabbar.layout": "conformazione",
    "entries.tabbar.openingHours": "orari di apertura",
    "entries.tabbar.order": "formato di comando",
    "entries.tabbar.tooltip.entryNotSavedDetail": "I dati di input possono essere modificati con il titolo solo dopo che l'input è stato salvato.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "I dati di layout possono essere modificati solo dopo che la voce con il titolo è stata salvata.",
    "entries.types.poi": "poi",
    "entries.types.standard": "modello di riferimento",
    "entries.ui.groupDetailName": "Nome per le voci di gruppo",
    "entries.ui.groupName": "Nomi dei gruppi (regione)",
    "entryTypes.entry": "stimolante",
    "entryTypes.event": "Evento",
    "entryTypes.poi": "piane",
    "errors.blank": "deve essere completato...",
    "errors.description": "Dit veld",
    "errors.email": "deve essere un indirizzo e-mail valido",
    "errors.empty": "non può essere vuoto...",
    "errors.exclusion": "è riservato...",
    "errors.inclusion": "non è incluso nell'elenco...",
    "errors.invalid": "è invalido...",
    "errors.slug": "La limaccia deve contenere solo lettere minuscole, numeri e \"-\".",
    "forms.ui.deleteFile": "Cancella file",
    "forms.ui.isSubmitted": "La tua richiesta è stata inoltrata. Vi contatteremo il prima possibile.",
    "forms.ui.uploadSuccess": "{{file}} caricato con successo",
    "media.documents.search.noResult": "Inserisci un termine di ricerca o seleziona una categoria.",
    "media.documents.search.placeholder": "Ricerca per numero di serie o tipo",
    "media.documents.search.reset": "Reimposta risultato della ricerca",
    "media.ui.copyright": "diritto d'autore",
    "media.ui.cropFormat": "disposizione standard",
    "media.ui.cropImage": "coltello",
    "media.ui.cropImageDuplicate": "Duplicazione e rifilatura",
    "media.ui.description": "Descrizione del",
    "media.ui.filename": "stock serpente",
    "media.ui.imageSaved": "Immagine registrata",
    "media.ui.processingImage": "L'immagine sarà tagliata, attendere prego.",
    "media.ui.resetCrop": "Reset blanco",
    "media.ui.resetImage": "Torna all'originale",
    "media.ui.rotateImage": "Ruotare il numero di 90° in senso orario",
    "media.ui.title": "benigna",
    "openingHours.additionalInfo": "Informazioni sugli orari di apertura",
    "openingHours.addOpening": "Aggiungere l'orario di apertura",
    "openingHours.date.day.di": "Di",
    "openingHours.date.day.do": "Do",
    "openingHours.date.day.fr": "Fr",
    "openingHours.date.day.mi": "Mi",
    "openingHours.date.day.mo": "Mo",
    "openingHours.date.day.sa": "Sa",
    "openingHours.date.day.so": "So",
    "openingHours.dateS": "afspraak",
    "openingHours.delete": "Enlever",
    "openingHours.from": "sportello",
    "openingHours.options": "aanbod",
    "openingHours.time": "più keer",
    "openingHours.to": "fino a",
    "openingHours.type": "Tipo di orario di apertura",
    "opt-in.error-can-not-update-member": "Non è stato possibile apportare modifiche ai dettagli dell'utente per {{email}}.",
    "opt-in.error-list-does-not-exist": "Non è stato trovato alcun elenco per i dati specificati.",
    "opt-in.error-list-does-not-need-validation": "L'elenco specificato non ha diritto di opt-in.",
    "opt-in.error-member-not-in-list": "Non sono stati trovati membri di un elenco per i dati forniti.",
    "opt-in.success-member-verified": "Il file {{email}} è stato aggiunto all'elenco {{list name}}}.",
    "opt-out.error-can-not-update-member": "Non è stato possibile apportare modifiche ai dettagli dell'utente per {{email}}.",
    "opt-out.error-list-does-not-exist": "Non è stato trovato alcun elenco per i dati specificati.",
    "opt-out.error-list-does-not-have-opt-out": "L'elenco specificato non prevede la possibilità di dissociazione.",
    "opt-out.error-member-not-in-list": "Non sono stati trovati membri di un elenco per i dati forniti.",
    "opt-out.success-membership-terminated": "Il file {{email}} è stato eliminato dal {list name}}.",
    "password.errors.not-secure-enough": "Si consiglia una password di almeno 8 caratteri, lettere maiuscole e minuscole, numeri e caratteri speciali ($@$!%*#? &amp;).",
    "password.errors.password-and-conf-do-not-match": "Le due password devono essere identiche.",
    "password.quality.0": "Zeer slecht",
    "password.quality.1": "Slecht",
    "password.quality.2": "Voldoende",
    "password.quality.3": "Fijn",
    "password.quality.4": "Zeer se n'e' andato.",
    "profile.entries.copycats": "Gli invii {{userName}} vogliono anche avere un'opzione",
    "profile.entries.donates": "Inserimento supportato da {{userName}}.",
    "profile.entries.entries": "Le presentazioni di {{userName}}.",
    "profile.entries.follows": "Presentazione seguita da {{userName}}.",
    "profile.entries.joins": "Voci in cui è coinvolto {{userName}}.",
    "profile.marketplace.fields.energyRequired": "requisiti di potenza",
    "profile.marketplace.fields.livingAnimals": "Trasporto di animali vivi",
    "profile.marketplace.fields.loadingUnloading": "Obbligatorio all'arrivo e alla partenza",
    "profile.marketplace.fields.maxRange": "Per la mia offerta, ho già percorso così tanti chilometri.",
    "profile.marketplace.fields.placeRequired": "Requisiti di spazio di parcheggio",
    "profile.marketplace.fields.securityRequired": "Impostare un sistema di sicurezza",
    "profile.marketplace.fields.shows": "gamma di programmi",
    "profile.marketplace.fields.spaceRequired": "Fabbisogno di spazio in m² richiesti",
    "profile.marketplace.fields.waterRequired": "Fabbisogno idrico in m³ (m³)",
    "profile.marketplace.new": "Nuova offerta o domanda",
    "profile.marketplace.offer.delete": "Enlever",
    "profile.marketplace.offer.description": "Descrizione del",
    "profile.marketplace.offer.descriptionPlaceholder": "Una descrizione significativa, in modo che un altro utente possa farne qualcosa.",
    "profile.marketplace.offer.new": "Fai una nuova offerta",
    "profile.marketplace.offer.relatedEntry": "Posizione assegnata",
    "profile.marketplace.offer.tags": "Etichette per l'offerta e la domanda (sarà meglio con esso)",
    "profile.marketplace.offer.title": "Ik bied",
    "profile.marketplace.request.delete": "Cancellare la richiesta",
    "profile.marketplace.request.new": "Fai una nuova richiesta",
    "profile.marketplace.request.title": "Ne ho bisogno. Ne ho bisogno.",
    "profile.messages.approve": "Bevestigeno",
    "profile.messages.as": "terwijl",
    "profile.messages.commentPre": "leva",
    "profile.messages.commentSuff": "geanotecnico",
    "profile.messages.decided": "ha deciso di farlo",
    "profile.messages.deletedUser": "Utente cancellato",
    "profile.messages.donateDream": "Grazie per aver sostenuto questo partecipante in parole e azioni per raggiungere la sua presentazione. Riceverà immediatamente il vostro messaggio e, si spera, vi contatterà e accetterà il vostro aiuto.",
    "profile.messages.dreamer": "alcune caratteristiche",
    "profile.messages.dreamToo": "per sognare e sognare",
    "profile.messages.enter": "solidale",
    "profile.messages.favoriteDream": "Segui questa voce ora.",
    "profile.messages.follow": "rintracciabilità",
    "profile.messages.has": "is",
    "profile.messages.is": "is",
    "profile.messages.join": "ridimensionamento",
    "profile.messages.joinDream": "Vuoi sognare questa entrata.",
    "profile.messages.likeDream": "Ora ti piace questa entrata.",
    "profile.messages.sendText": "Il tuo messaggio è stato inviato con successo.",
    "profile.messages.shareDream": "Siete riusciti a condividere questo messaggio.",
    "profile.messages.supporter": "arbitri monetari",
    "profile.messages.want": "disponibilità",
    "profile.messages.youAre": "U piegato",
    "profile.messages.youWant": "U appassimento",
    "profile.newsletter.notSubscribed": "ondertekenen",
    "profile.newsletter.privacyInformation": "Cliccando su \"iscriviti\", accetti che ti invieremo le future newsletter su questo abbonamento (opt-in). È possibile disdire la newsletter in qualsiasi momento cliccando nuovamente su \"Iscriviti\" (opt-out).",
    "profile.newsletter.subscribed": "abonniert",
    "profile.press.articleDetail": "dettagli dell'articolo",
    "profile.press.downloadImage": "Scarica l'immagine",
    "profile.press.downloadImages": "Scaricare le immagini",
    "profile.press.downloadText": "Esportatori Tekst",
    "profile.press.filterAuthor": "Filtrare per autore",
    "profile.press.hide": "essere maschio",
    "profile.press.show": "verbena",
    "profile.progress.title": "Come migliorare il tuo profilo",
    "register.ui.addressData": "adresgevens",
    "register.ui.addressInfo": "Non è necessario fornire il proprio indirizzo di casa. Tuttavia, questo porta a calcoli corretti del percorso e della distanza.",
    "register.ui.companyData": "i dati di contatto della vostra azienda",
    "register.ui.companyDataMandatory": "È necessario inserire il nome della propria azienda.",
    "register.ui.emptyFirstName": "Devi inserire il tuo nome",
    "register.ui.emptyLastName": "Devi inserire il tuo cognome.",
    "register.ui.emptyMail": "È necessario inserire un indirizzo e-mail valido.",
    "register.ui.emptyUserName": "È necessario inserire un nome utente",
    "register.ui.language": "taal",
    "register.ui.loginData": "riferimenti",
    "register.ui.password": "La password",
    "register.ui.passwordRepeat": "Ripetere la password",
    "register.ui.personalData": "I vostri dati personali",
    "register.ui.takenEmail": "Questa e-mail è già stata inserita da un utente.",
    "register.ui.takenUserName": "Questo nome utente è già stato assegnato",
    "register.ui.timezone": "tijdzone",
    "register.ui.typeMinimum": "È necessario selezionare almeno un tipo",
    "register.ui.usernameInfo": "Importante Questo è il tuo nome utente per effettuare il login.",
    "search.allDistances": "Tutti gli altri prodotti",
    "search.andFilter": "Impostazioni del filtro",
    "search.closeTags": "Balise Montagna",
    "search.for": "in seguito a",
    "search.zipCountries": "Codici postali da",
    "search.hits": "scorie",
    "search.openTags": "Tags tonen",
    "search.removeDatefilter": "Rimuovere il filtro data",
    "search.resulted": "ha portato alla",
    "search.resultType.category": "Categoria",
    "search.resultType.entry": "Rivenditore",
    "search.resultType.event": "Evento",
    "search.resultType.group": "Gruppo",
    "search.resultType.page": "Pagina",
    "search.resultType.person": "Persona",
    "search.resultType.post": "Post",
    "search.resultType.product": "Prodotto",
    "search.search": "Ricerca",
    "search.searchBarPlaceholder": "consultabile",
    "search.searchBarPlaceholderEntries": "Ricerca rivenditori. ",
    "search.searchBarPlaceholderEntriesPosition": "Specificare la posizione per la ricerca del raggio.",
    "search.searchBarPlaceholderGeoSearch": "Indirizzo Vul uw in...",
    "search.searchBarPlaceholderMobile": "consultabile",
    "search.searchBarPlaceholderProducts": "Ricerca prodotti",
    "search.searchBarPositionTooltip": "Determinare la posizione o inserire la posizione con l'indirizzo",
    "search.showAll": "Auguro a tutti",
    "social.comment": "telecronaca",
    "social.Comment": "telecronaca",
    "social.CopycatJoiner": "Sogna il tuo ingresso",
    "social.dream.donateAmount": "Sostegno con una somma di denaro",
    "social.dream.donateDreampoints": "Supporto con Dreampoints",
    "social.dream.donateInfo": "Il messaggio è privato e può essere letto solo dal proprietario dell'ingresso.",
    "social.dream.donateTip": "Sostegno attraverso la consulenza e l'azione",
    "social.dream.done": "GEDAAN",
    "social.dream.edit": "Modifica di una voce",
    "social.dream.findDreams": "Trova qui le voci piccole e grandi e rendere la tua voce vera e / o aiutare gli altri a fare le loro voci.",
    "social.dream.followedDreams": "Incendibilità che stai seguendo",
    "social.dream.giveEmail": "Mijn e-mail adres delen",
    "social.dream.givePhone": "Inserisci il mio numero di telefono",
    "social.dream.joinerApproved": "Lei è coinvolto in questa presentazione.",
    "social.dream.joinerButton": "Iscriviti a questa voce",
    "social.dream.joinerButtonMobile": "Deelnemen",
    "social.dream.joinerDream": "sogno collettivo",
    "social.dream.joinerInfo": "Se il titolare della registrazione conferma la tua partecipazione, questa registrazione diventa un sogno di gruppo, in cui hai più diritti.",
    "social.dream.joinerMessage": "Inserisci qui il tuo messaggio per il proprietario dell'ingresso:",
    "social.dream.joinerPending": "Il titolare dell'iscrizione deve comunque confermare la vostra partecipazione.",
    "social.dream.joinerSupport": "Voi supportate questo messaggio",
    "social.dream.joinerSupportButton": "Il rapporto Steun dice che",
    "social.dream.joinerSupportButtonMobile": "ondersteuning",
    "social.dream.joinerSupportInfo": "Lavoriamo costantemente per migliorare, ad esempio con fondi o punti di ingresso per il sostegno. Restate sintonizzati.",
    "social.dream.joinerSupportPending": "Il proprietario dell'ingresso deve sempre autorizzare il vostro supporto.",
    "social.dream.member": "palpebra",
    "social.dream.members": "prugna",
    "social.dream.meToo": "Voglio farlo anch'io.",
    "social.dream.meTooInfo": "Mostrare al proprietario dell'ingresso che si pensa che l'ingresso è grande e che si vuole fare anche questo ingresso.",
    "social.dream.noFollow": "Non stai seguendo un'entrata!",
    "social.dream.noJoiner": "Non sei ancora membro di un gruppo di sogni.",
    "social.dream.noLikes": "Non ti piace ancora la menzione!",
    "social.dream.noSupport": "Non supportate l'accesso a un annuncio",
    "social.dream.registerFirstText": "Devi essere loggato per poter cancellare una voce, supportarla o indicare che vuoi fare una voce. La registrazione è rapida e gratuita. Basta cliccare sul pulsante \"Registrati\".",
    "social.dream.registerFirstTitle": "Sognate anche voi di sostegno?",
    "social.dream.settings": "parametri di ingresso",
    "social.dream.shareTitle": "Deel de droom",
    "social.dream.similar": "Voci correlate",
    "social.dreamline.addImageToDream": "Aggiungi questa immagine al tuo invio",
    "social.dreamline.addMedia": "Aggiungere un'immagine/video",
    "social.dreamline.allActivities": "Tutte le attività di Dreamline",
    "social.dreamline.anonymous": "anonima",
    "social.dreamline.commentButton": "telecronaca",
    "social.dreamline.comments": "...scritto...:",
    "social.dreamline.depublished": "Questa osservazione è stata ritirata a causa di un comportamento inappropriato.",
    "social.dreamline.dreampointsInfo": "Questi sono i Dreampoint dell'ingresso. Maggiori informazioni sui Dreampoints possono essere trovate nel vostro profilo.",
    "social.dreamline.fewDreampoints": "ha solo pochi punti di vista. Commento, come o condividere la presentazione.",
    "social.dreamline.filterBySteps": "Dopo Tredenfiltern",
    "social.dreamline.filterComment": "critiche",
    "social.dreamline.filteredBy": "Attività filtrate da",
    "social.dreamline.filteredBySteps": "Filtrato per fasi",
    "social.dreamline.filterFavorite": "Volgt",
    "social.dreamline.filterLike": "aime",
    "social.dreamline.filterShare": "aandele",
    "social.dreamline.filterStep": "palcoscenici",
    "social.dreamline.flagged": "Questo messaggio è stato riportato",
    "social.dreamline.isComment": "geanotecnico",
    "social.dreamline.isCopycatJoiner": "ook dromen",
    "social.dreamline.isFirstUpdate": "artigianale",
    "social.dreamline.isShare": "ad eccezione di",
    "social.dreamline.isStep": "ha fatto un nuovo passo avanti",
    "social.dreamline.isUpdate": "bijgewerkt",
    "social.dreamline.lastEntry": "Aggiorna Laatste",
    "social.dreamline.locked": "Questo contenuto è visibile solo ad altri sognatori.",
    "social.dreamline.lockedCTA": "Iscriviti ora a questa voce",
    "social.dreamline.noMessages": "Questa voce non ha ancora alcuna attività. Scrivi il tuo primo commento ora.",
    "social.dreamline.noMessagesFiltered": "Nessuna attività con queste impostazioni di filtro per questo elemento.",
    "social.dreamline.nowFollower": "Segui questa voce!",
    "social.dreamline.nowJoiner": "è ora un sostenitore...",
    "social.dreamline.nowLikes": "è piaciuta questa voce ora!",
    "social.dreamline.nowSharing": "hanno condiviso questa entrata!",
    "social.dreamline.onlyJoinerVisible": "Visibile solo ad altri sognatori / sostenitori",
    "social.dreamline.prefixComment": "ha già fatto questa voce prima di",
    "social.dreamline.prefixCopycatJoiner": "Richieste di ricevere questa menzione come un",
    "social.dreamline.prefixFavorite": "segue questa voce, poiché",
    "social.dreamline.prefixJoiner": "sostiene questa affermazione, dato che",
    "social.dreamline.prefixLike": "piace questa entrata perche",
    "social.dreamline.prefixShare": "ha già fatto questa voce prima di",
    "social.dreamline.prefixStep": "ha previsto",
    "social.dreamline.prefixUpdate": "ha già fatto questa voce prima di",
    "social.dreamline.profile": "Profilo",
    "social.dreamline.provideImage": "Metto a disposizione del proprietario dell'ingresso questa foto",
    "social.dreamline.resetFilter": "Reimpostare il filtro",
    "social.dreamline.sendComment": "Verzenden Verzenden Verzenden",
    "social.dreamline.sharePart": "uitwisseling",
    "social.dreamline.toggleStream": "critiche",
    "social.dreamline.toggleStreamSingle": "telecronaca",
    "social.dreamline.writeComment": "Scrivi un commento...",
    "social.Favorite": "Volg",
    "social.follow": "Volg",
    "social.Follow": "Volg",
    "social.hasLiked": "Ti è già piaciuto questo articolo.",
    "social.join": "connettorante",
    "social.Join": "connettorante",
    "social.Joiner": "cammello",
    "social.like": "zoo",
    "social.Like": "zoo",
    "social.report.headlinePrefix": "U piegato in",
    "social.report.headlineSuffix": "om te rapporteren.",
    "social.report.login": "Devi essere loggato per segnalare il messaggio.",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "Altri contenuti offensivi",
    "social.report.reportScam": "False dichiarazioni, intimidazioni.",
    "social.report.reportSex": "Contenuto sessuale",
    "social.report.reportSpam": "E' spam. E' spam.",
    "social.report.reportViolence": "rappresentazione della violenza",
    "social.report.send": "Invia un messaggio",
    "social.report.title": "comunicazione",
    "social.share": "Aandeel",
    "social.Share": "Aandeel",
    "syd.ui.dreamerCommercial": "Standard B2B",
    "syd.ui.userCommercial": "B2B Dromer",
    "user.company.additionalWebsite": "Altro sito web aziendale (solo dominio senza https://)",
    "user.company.companyName": "nome dell'azienda",
    "user.company.copyContact": "Copiare le coordinate dai dati aziendali per l'inserimento.",
    "user.company.email": "Contattaci via e-mail",
    "user.company.facebook": "Account Facebook dell'azienda (solo nome account)",
    "user.company.fax": "Numero di fax per il contatto scritto",
    "user.company.google": "Account Google+ dell'azienda (solo nome account)",
    "user.company.instagram": "Compte Instagram (solo nome del conto)",
    "user.company.linkedin": "Conto LinkedIn della società (solo nome del conto)",
    "user.company.newCustomerAccount": "Creare un conto commerciale",
    "user.company.noCustomerValidation": "Il vostro conto commerciale non è ancora stato attivato.",
    "user.company.noCustomerYet": "La tua registrazione non è ancora stata assegnata ad un conto commerciale.",
    "user.company.phone": "Numero di telefono per contattarci",
    "user.company.pinterest": "Conto di interesse della società (solo il nome del conto)",
    "user.company.remarks": "annotazioni",
    "user.company.twitter": "Account Twitter aziendale (solo nome account senza @)",
    "user.company.website": "Sito web aziendale (solo dominio senza https://)",
    "user.company.xing": "Conto Xing aziendale (solo il nome del conto)",
    "user.company.youtube": "Compte Youtube Company (solo nome del conto)",
    "user.message.after-save-error": "Non è stato possibile salvare le modifiche.",
    "user.message.after-save-success": "Le modifiche sono state salvate.",
    "user.message.can-not-login": "Questi ID e password non ci permettono di connettervi! Il nome utente è scritto correttamente? Password inserita correttamente? Forse qualcosa è andato storto con la registrazione.",
    "user.message.can-not-logout": "Non siamo riusciti a staccarti!",
    "user.message.conflict": "Questo nome utente esiste già, sceglierne un altro!",
    "user.message.fileExistsError": "Il download è fallito. Il file esiste già",
    "user.message.fileSizeError": "Il download è fallito. Il file è troppo grande",
    "user.message.loginFailureBottom": "Forse non hai ancora un account Entryer?",
    "user.message.loginFailureTop": "Il tuo nome utente è scritto correttamente? Hai inserito correttamente la tua password?",
    "user.message.registration-error": "Si è verificato un errore durante la registrazione!",
    "user.message.registration-success": "Dovresti ricevere presto un'e-mail di conferma da parte nostra.",
    "user.message.unknownError": "Errore sconosciuto durante il download del file",
    "user.message.upload-success": "Download di successo",
    "user.message.verify-error": "Non è stato possibile verificare il tuo indirizzo e-mail. Contattare l'amministratore.",
    "user.message.verify-success": "Il tuo indirizzo e-mail è stato confermato. Ora è possibile registrarsi:",
    "user.ui.accountGreeting": "Salve,",
    "user.ui.accountMenuActivities": "Droompunten",
    "user.ui.accountMenuCompanySettings": "dettagli dell'azienda",
    "user.ui.accountMenuEntries": "Mijn inzendingen",
    "user.ui.accountMenuEntryPre": "encens",
    "user.ui.accountMenuEvents": "I miei eventi",
    "user.ui.accountMenuFavorites": "Mijn favorieten",
    "user.ui.accountMenuFollows": "Inzendingen la volg Ik",
    "user.ui.accountMenuImages": "Foto/Video/Documenti",
    "user.ui.accountMenuJoin": "Il mio gruppo sogna",
    "user.ui.accountMenuLikes": "Voci che mi piacciono",
    "user.ui.accountMenuMessages": "novità",
    "user.ui.accountMenuNewsletter": "Iscriviti alla mia newsletter",
    "user.ui.accountMenuPoi": "I miei posti",
    "user.ui.accountMenuPress": "Mes articoli",
    "user.ui.accountMenuProducts": "Prodotto Mijn",
    "user.ui.accountMenuProfile": "Profilo",
    "user.ui.accountMenuRatings": "valutazioni",
    "user.ui.accountMenuRegistrationData": "riferimenti",
    "user.ui.accountMenuReviews": "herzieningen",
    "user.ui.accountMenuSettings": "ambiente circostante",
    "user.ui.accountMenuSkillsOffer": "Ik bied.................",
    "user.ui.accountMenuSkillsRequest": "Devo.....",
    "user.ui.accountMenuSkillsSearch": "Richieste e offerte",
    "user.ui.accountMenuSubscriptions": "Abbonamento",
    "user.ui.accountMenuSubscritions": "Abbonamento Mijn",
    "user.ui.accountMenuSupport": "Inzendingen inzendingen inzendingen inzendingen",
    "user.ui.accountMenuSupports": "Inzendingen the ik steun",
    "user.ui.accountNoMessages": "Non avete ancora ricevuto alcun messaggio.",
    "user.ui.accountProgressbar": "Il tuo profilo è {{value}}% completo.",
    "user.ui.accountProgressbarEntry": "La voce è completa a {{value}}%.",
    "user.ui.accountSettings": "Installazione su richiesta",
    "user.ui.accountCookieSettings": "Clicca qui per regolare le tue impostazioni.",
    "user.ui.accountCookieEssential": "Essenziale",
    "user.ui.accountCookieAnalysis": "Analisi",
    "user.ui.accountCookieSocial": "Media sociali",
    "user.ui.accountCookieReviews": "Recensioni",
    "user.ui.accountToolbarLogin": "Accedi al tuo account",
    "user.ui.accountToolbarLoginTooltip": "Registrati gratuitamente e crea il tuo ingresso personale",
    "user.ui.accountToolbarLogout": "Logout",
    "user.ui.adminPreview": "Anteprima",
    "user.ui.backToLogin": "Torna alla pagina di login",
    "user.ui.birthday": "Anniversario",
    "user.ui.cannotLogin": "Non riesci a connetterti? Clicca qui per reimpostare la tua password.",
    "user.ui.changePassword": "Voglio cambiare la mia password",
    "user.ui.changeUserGroup": "Abbonamento Ander",
    "user.ui.city": "legatura",
    "user.ui.companyName": "nome dell'azienda",
    "user.ui.companyNameTooltip": "Importante La sua menzione apparirà sotto i nomi di queste società.",
    "user.ui.country": "paese",
    "user.ui.createNewAccount": "Creare un nuovo account utente",
    "user.ui.dataDelete": "viene utilizzato per altre importazioni. Vuoi cancellare il file in modo permanente? Quando questo file viene utilizzato, viene sostituito da un carattere di riempimento.",
    "user.ui.dataUsage": "Il file viene salvato in",
    "user.ui.dataUsageNone": "Il file non è ancora in uso. Vuoi cancellare il file in modo permanente?",
    "user.ui.deleteImage": "Elimina l'immagine",
    "user.ui.description": "Compilare qui una descrizione generale della persona o dell'azienda",
    "user.ui.documents": "Documento",
    "user.ui.documentSearch": "Ricerca di documenti",
    "user.ui.download": "Scaricare {{title}}",
    "user.ui.email": "indirizzi e-mail",
    "user.ui.fillOutForm": "Per registrarsi, tutti i campi obbligatori devono essere compilati per intero.",
    "user.ui.firstName": "nome",
    "user.ui.academicTitle": "Titolo accademico",
    "user.ui.gender": "H/F",
    "user.ui.genderFemale": "femminile",
    "user.ui.genderMale": "mannelijk",
    "user.ui.genderThird": "incerto",
    "user.ui.housenumber": "No.",
    "user.ui.iAmCustomer": "Vorrei creare un account Share Your B2B Dream.",
    "user.ui.internalInfo": "Abbiamo una notizia per voi. Fare clic qui.",
    "user.ui.lastLogin": "Accedi a Laatste",
    "user.ui.lastName": "Achternaam",
    "user.ui.loginButton": "Accedi al tuo account",
    "user.ui.loginTitle": "login utente",
    "user.ui.languageAvailable": "Disponibile nella tua lingua",
    "user.ui.languageMissing": "Disponibile solo in",
    "user.ui.memberSince": "Le palpebre sono",
    "user.ui.mobileNumber": "numero di cellulare",
    "user.ui.myAddedStorage": "Foto disponibili",
    "user.ui.myStorage": "Elenco di Mijn",
    "user.ui.noRegisterMessage": "La registrazione automatica dell'utente non è attiva. Per un account utente, si prega di contattare gli indirizzi di contatto specificati.",
    "user.ui.oldPassword": "Password precedente",
    "user.ui.password": "La password",
    "user.ui.pleaseSelect": "Selezionare",
    "user.ui.registerButton": "Crea un account ora",
    "user.ui.registerMessage": "Registrati subito e inizia subito a lavorare. E' facile e veloce.",
    "user.ui.registerTitle": "Nuovo nel nostro paese?",
    "user.ui.requestPasswordReset": "Richiedi una nuova password e cancella quella vecchia",
    "user.ui.resetPassword": "Reimpostare la password",
    "user.ui.resetPasswordPage": "Réinitialiser uw wachtwoord",
    "user.ui.resetPasswordSuccess": "La tua password è stata reimpostata, ora puoi effettuare il login.",
    "user.ui.selectCustomerType": "Sono...selezionati a mano.",
    "user.ui.stayLoggedin": "Rester Connecté",
    "user.ui.stayLoggedinInfo": "Se si tratta di un dispositivo pubblicamente accessibile, disattivare questa opzione e uscire alla fine.",
    "user.ui.street": "straat",
    "user.ui.uploadDrop": "Per scaricare, trascinare e rilasciare un file qui, oppure",
    "user.ui.uploadSelectFile": "una scelta migliore e migliore",
    "user.ui.uploadToMyStorage": "Carica nuove foto nel mio elenco",
    "user.ui.uploadUserImage": "Selezionare la schermata utente",
    "user.ui.username": "Nome utente (indirizzo e-mail)",
    "user.ui.userName": "inlognaam",
    "user.ui.visibilityInfo": "Suggerimento: Decidi tu cosa viene visualizzato pubblicamente dal tuo profilo! Fare clic sull'icona dell'occhio per regolare la visibilità della propria voce:",
    "user.ui.zip": "contea",
    "user.user": "alcune caratteristiche",
    "user.users": "draghe",

    // auto translated
    "application.ui.and": "e",
    "application.ui.in": "su",
    "application.ui.atHour": "su",
    "application.ui.hour": "Guarda",
    "application.ui.contact": "Contatta",
    "application.ui.email": "Email",
    "application.ui.goToMeasuring": "Punti di misura",
    "application.ui.imprintLink": "Responsabile e legalmente responsabile del contenuto di questa sottopagina {{entry}} - Impronta",
    "application.ui.select": "Si prega di selezionare",
    "application.ui.shop": "Negozio",
    "buttons.confirm": "Confermare",
    "checkout.cart.title": "Il mio carrello",
    "checkout.cart.item": "Articolo",
    "checkout.cart.amount": "Numero",
    "checkout.cart.edit": "Cambia",
    "checkout.cart.price": "Prezzo",
    "checkout.cart.singlePrice": "Prezzo unitario",
    "checkout.cart.product.isNonPhysical": "Il prodotto non sarà spedito (potrebbe essere disponibile per il download)",
    "checkout.cart.product.requiresExpressDelivery": "Prodotto fresco. Deve essere spedito EXPRESS.",
    "checkout.cart.product.deliveryProblem": "Il prodotto selezionato può essere soggetto a ritardi di consegna.",
    "checkout.cart.subtotal": "Subtotale",
    "checkout.cart.deliveryCosts": "Spese di spedizione",
    "checkout.cart.total": "Totale",
    "checkout.cart.help": "Hai domande sul processo di ordinazione? Abbiamo compilato delle informazioni importanti per voi.",
    "checkout.cart.subtotalInfo": "IVA inclusa",
    "checkout.cart.subtotalShipment": "più i costi di spedizione deviati",
    "checkout.cart.checkout": "Alla cassa",
    "checkout.cart.checkoutWithoutUser": "Cassa senza conto cliente",
    "checkout.cart.checkoutFast": "Pagamento rapido",
    "checkout.cart.deliveryInfo": "Data di consegna stimata per gli articoli nel carrello",
    "checkout.cart.orderSuccess": "Il tuo ordine è andato a buon fine.",
    "checkout.cart.myOrder": "Il mio ordine",
    "checkout.checkout.orderNumber": "Numero d'ordine",
    "checkout.checkout.account": "Conto cliente",
    "checkout.checkout.andOrder": "e ordine",
    "checkout.checkout.delivery": "Consegna e indirizzo di consegna",
    "checkout.checkout.delivery.type": "L'invio avviene con",
    "checkout.checkout.delivery.street": "Indirizzo linea 1",
    "checkout.checkout.delivery.houseNumber": "No.",
    "checkout.checkout.delivery.co": "Suffisso dell'indirizzo (c/o)",
    "checkout.checkout.delivery.city": "Città",
    "checkout.checkout.delivery.selectCountry": "Selezionare il paese",
    "checkout.checkout.delivery.addressBoth": "Fattura e indirizzo di consegna",
    "checkout.checkout.delivery.addressInvoice": "Indirizzo della fattura",
    "checkout.checkout.delivery.addressDelivery": "Indirizzo di consegna",
    "checkout.checkout.delivery.default": "Imposta questo indirizzo come mio indirizzo di consegna predefinito",
    "checkout.checkout.delivery.otherAddress": "L'indirizzo di consegna è diverso da quello di fatturazione",
    "checkout.checkout.delivery.dhlNr": "Il tuo numero di posta DHL",
    "checkout.checkout.delivery.packingStation": "Numero della stazione di imballaggio",
    "checkout.checkout.delivery.showPackingStations": "Mostra le stazioni di imballaggio vicino al mio indirizzo",
    "checkout.checkout.delivery.wantExpressShipping": "Consegna espressa",
    "checkout.checkout.delivery.specialCountryInfo": "Il tuo paese non può essere selezionato?",
    "checkout.checkout.delivery.specialCountry": "Il tuo paese al di fuori della nostra spedizione standard",
    "checkout.checkout.delivery.isPickUp": "Collezione in loco",
    "checkout.checkout.delivery.specialCountryMessage": "I singoli prodotti nel tuo carrello non possono essere spediti in tutti i paesi. Si prega di rimuovere alcuni prodotti dal vostro ordine fino a quando il vostro paese può essere selezionato o contattateci",
    "checkout.checkout.payment": "Metodo di pagamento",
    "checkout.checkout.paymentTitle": "Come intende pagare?",
    "checkout.checkout.paymentSelected": "Metodo di pagamento selezionato",
    "checkout.checkout.payment.cc": "Carta di credito",
    "checkout.checkout.payment.ccInfo": "Per i pagamenti con carta all'interno dell'UE, la sua identità deve essere confermata ogni volta che paga online. Dopo l'ordine, sarete guidati attraverso la conferma - fornita dalla vostra banca.",
    "checkout.checkout.payment.complimentary": "libero",
    "checkout.checkout.payment.paypal": "PayPal",
    "checkout.checkout.payment.paypalInfo": "Sarai reindirizzato a PayPal per completare il processo di pagamento. Il tuo conto PayPal non sarà addebitato fino alla conferma dell'ordine nel passo successivo.",
    "checkout.checkout.payment.sepa": "Addebito diretto (SEPA)",
    "checkout.checkout.payment.sepaLegal": "Autorizzo {{siteOwner}} {{siteOwnerAddress}} (ID del creditore: {{siteOwnerCreditorID}}) per riscuotere i pagamenti dal mio conto tramite addebito diretto. Allo stesso tempo, ordino al mio istituto di credito di onorare gli addebiti diretti {{siteOwner}} sul mio conto.",
    "checkout.checkout.payment.noSepa": "Il tuo indirizzo di fatturazione o spedizione è al di fuori dell'UE. Pertanto non possiamo offrirvi il pagamento con addebito diretto.",
    "checkout.checkout.payment.sepaInfo": "Veloce e senza complicazioni. Al momento di completare il tuo ordine (passo successivo) ti verrà chiesto di inserire le tue coordinate bancarie (IBAN). Per favore, teneteli pronti. Quando il trasferimento è completato, riceverai una conferma.",
    "checkout.checkout.payment.prepayment": "Pagamento anticipato (bonifico bancario)",
    "checkout.checkout.payment.prepaymentInfo": "Dopo aver effettuato l'ordine, vi invieremo le informazioni per il bonifico bancario via e-mail. Possiamo riservare gli articoli del suo ordine solo per un massimo di 7 giorni. Si prega di mantenere questa finestra temporale per il trasferimento bancario. Non appena riceviamo il pagamento, l'ordine sarà spedito.",
    "checkout.checkout.payment.invoice": "Fattura",
    "checkout.checkout.payment.invoiceInfo": "L'importo della fattura è dovuto alla data di pagamento specificata nell'e-mail di conferma della spedizione.",
    "checkout.checkout.payment.noInvoice": "Sfortunatamente, non possiamo offrirvi il pagamento tramite fattura per questo ordine. Si prega di scegliere uno degli altri metodi di pagamento.",
    "checkout.checkout.payment.sofort": "Sofortüberweisung",
    "checkout.checkout.payment.sofortInfo": "Al momento di completare il tuo ordine (passo successivo) ti verrà chiesto di inserire le tue coordinate bancarie (IBAN). Per favore, teneteli pronti. Quando il trasferimento è completato, riceverai una conferma.",
    "checkout.checkout.payment.accountName": "Titolare del conto",
    "checkout.checkout.payment.accountEmail": "Indirizzo e-mail di conferma",
    "checkout.checkout.sepaAndOrder": "Mandato di addebito diretto per {{totalAmount}} € confermare e ordinare con costi",
    "checkout.checkout.sofortAndOrder": "Mandato di trasferimento per {{totalAmount}} € confermare e ordinare con costi",
    "checkout.checkout.title": "Cassa",
    "checkout.checkout.next": "leggere di più",
    "checkout.checkout.order": "Compra ora",
    "checkout.checkout.orderConsentPre": "Con l'ordine vi spiegate con il nostro",
    "checkout.checkout.orderConsentPost": "concordato.",
    "checkout.checkout.noAccount": "Non creare un account cliente",
    "checkout.redirect.countryPriceChange": "I prezzi sono diversi nel tuo paese, prendi nota del cambiamento di prezzo.",
    "entries.entry.fields.language": "Lingua",
    "entries.entry.ui.relatedJobs": "Lavori correlati",
    "entries.entry.ui.relatedArticles": "Articoli correlati",
    "entries.entry.ui.relatedCampaigns": "Campagne correlate",
    "entries.entry.ui.selectJob": "Seleziona il lavoro",
    "entries.entry.ui.selectArticle": "Selezionare l'articolo",
    "entries.entry.ui.selectCampaign": "Seleziona la campagna",
    "entries.person.ui.diverse": "Senza",
    "entries.poi.ui.poiEvents": "Eventi in questo luogo",
    "entries.product.ui.choose": "selezionare",
    "entries.product.ui.discount": "Sconto",
    "entries.product.ui.discountSelect": "% vantaggio di sconto",
    "entries.product.ui.netPrizeDefault": "compresa l'imposta sul valore aggiunto, più",
    "entries.product.ui.deliveryTerms": "Spese di spedizione",
    "entries.product.ui.notOrderable": "Si prega di selezionare una variante.",
    "entries.product.ui.prizeDecimalPoint": ",",
    "entries.product.ui.item": "Pezzo",
    "entries.product.ui.prizePerItem": "Prezzo per pezzo",
    "entries.product.ui.size": "Dimensione",
    "errors.mandatory": "{{field}} deve essere completato",
    "errors.mandatoryCheckbox": "Deve essere confermato.",
    "errors.mandatorySubmit": "Puoi inviare il modulo solo quando tutti i campi obbligatori sono stati compilati.",
    "forms.ui.formErr": "Errore nell'invio del modulo",
    "forms.ui.formSent": "Modulo inviato",
    "forms.ui.noForm": "Nessun modulo assegnato",
    "forms.ui.invalidMail": "Inserisci un indirizzo e-mail valido.",
    "forms.ui.missingFields": "I seguenti campi devono essere compilati:",
    "forms.ui.fieldBuilder.street": "Via",
    "forms.ui.fieldBuilder.houseNumber": "No.",
    "forms.ui.fieldBuilder.zip": "CAP",
    "forms.ui.fieldBuilder.city": "Città",
    "forms.ui.fieldBuilder.country": "Paese",
    "forms.ui.fieldBuilder.firstName": "Nome",
    "forms.ui.fieldBuilder.lastName": "Cognome",
    "forms.ui.privacyRead": "Ho preso nota dell'informativa sulla privacy",
    "jobs.ui.employmentType.FULL_TIME": "A tempo pieno",
    "jobs.ui.employmentType.PART_TIME": "Tempo parziale",
    "jobs.ui.employmentType.APPRENTICE": "Apprendista",
    "jobs.ui.employmentType.INTERN": "Stage",
    "jobs.ui.employmentType.CONTRACTOR": "Lavoro freelance",
    "jobs.ui.employmentType.TRAINEE": "Tirocinio, studio a tempo parziale",
    "jobs.ui.employmentType.VOLUNTEER": "Volontario",
    "jobs.ui.employmentType.OTHER": "",
    "jobs.ui.location": "Lavoro disponibile presso",
    "jobs.ui.role.male": "M",
    "jobs.ui.role.female": "F",
    "jobs.ui.role.divers": "D",
    "jobs.ui.startDateNow": "A partire da ora",
    "jobs.ui.startFrom": "Da",
    "name": "Nome",
    "profile.marketplace.request.missingCredentials": "Puoi iniziare una query solo quando hai creato almeno una località e un punto di misurazione.",
    "profile.newsletter.notSubscribedAny": "Non ti sei ancora iscritto a una lista di newsletter.",
    "register.ui.privacyStatement": "Accetto che i miei dati personali vengano conservati. I miei dati personali non saranno trasmessi a terzi. Posso revocare questo consenso alla protezione dei dati in qualsiasi momento con effetto per il futuro.",
    "register.ui.oauthInfo": "Sarai reindirizzato alla pagina di login di FRM per accedere con le tue informazioni utente e poi tornare al sito web.",
    "search.resetPosition": "Posizione di reset",
    "search.geoPositionBrowser": "Determinare la posizione in base al browser",
    "search.geoPositionFault": "La determinazione della posizione da parte del browser non è possibile",
    "search.geoPositionTitle": "Impostare la posizione per una ricerca di prossimità",
    "search.selectedFilters": "Filtri selezionati",
    "search.selectFilters": "Visualizzazione del filtro",
    "search.resultType.job": "Lavoro",
    "search.resultType.recipe": "Ricetta",
    "social.dreamline.edited": "[oggetto Oggetto Oggetto]",
    "user.company.addUser": "Creare utenti aggiuntivi per",
    "user.company.inviteUser": "Inviare mail di invito",
    "user.company.userIsInvited": "La mail di invito è stata inviata",
    "user.company.userIsActive": "L'utente è attivo",
    "user.company.userIsNotActive": "L'utente non è ancora attivato",
    "user.company.contactFor": "Dettagli di contatto per",
    "user.company.contactForDefault": "contatto generale",
    "user.company.notAllowed": "Non puoi fare alcuna modifica perché il tuo stato di utente ({{type}}) non lo permette.",
    "user.company.registerNumber": "Numero del registro commerciale",
    "user.company.registerAuth": "Tribunale locale",
    "user.company.acerCode": "Codice ACER della vostra fornitura di energia",
    "user.company.taxNumber": "Codice fiscale",
    "user.company.type.default": "Standard",
    "user.company.type.procurator": "Firmatario autorizzato",
    "user.company.type.manager": "Amministratore Delegato",
    "user.company.ustid": "UStID",
    "user.company.ustid.notValid": "Questo numero di identificazione dell'imposta sulle vendite non è valido",
    "user.ui.accountMenuMeasuring": "I miei luoghi e dati di misurazione",
    "user.ui.accountMenuOffer": "Le mie offerte",
    "user.ui.accountMenuRequest": "Le mie richieste",
    "user.ui.accountMenuTender": "Offerte",
    "user.ui.accountMenuOrders": "I miei ordini",
    "user.ui.accountMenuJobs": "Il mio lavoro offre",
    "user.ui.genderDiverse": "miscellanea",
    "user.ui.pleaseSelectSalutation": "Si prega di selezionare il saluto",
    "user.ui.requestPasswordSuccess": "Dovresti ricevere a breve un'e-mail con un link di reset.",
    "user.ui.requestPasswordError": "Errore sconosciuto quando si resetta la password.",
    "user.ui.setPassword": "Imposta la password",
    "user.ui.setPasswordPage": "Imposta la tua password",
    "user.ui.setPasswordSuccess": "La tua password è stata impostata, ora puoi accedere",
    "user.ui.salutation": "Saluto",
    "user.ui.title": "Titolo",
    "user.ui.phone": "Numero di telefono",
    "order.status.zero": "ordine ricevuto",
    "order.status.one": "In corso",
    "order.status.two": "L'ordine è stato spedito",
    "order.status.three": "Accesso a",
    "order.status.four": "Ritorna",
    "order.status.default": "Stato di occupazione sconosciuto",
    "country.germany": "Germania",
    "country.de": "Germania",
    "country.austria": "Austria",
    "country.at": "Austria",
    "country.belgium": "Belgio",
    "country.denmark": "Danimarca",
    "country.bulgaria": "Bulgaria",
    "country.croatia": "Croazia",
    "country.cyprus": "Cipro",
    "country.czechia": "Repubblica Ceca",
    "country.estonia": "Estonia",
    "country.finland": "Finlandia",
    "country.france": "Francia",
    "country.fr": "Francia",
    "country.greece": "Grecia",
    "country.hungary": "Ungheria",
    "country.ireland": "Irlanda",
    "country.italy": "Italia",
    "country.it": "Italia",
    "country.latvia": "Lettonia",
    "country.lithuania": "Lituania",
    "country.luxembourg": "Lussemburgo",
    "country.lu": "Lussemburgo",
    "country.malta": "Malta",
    "country.netherlands": "Paesi Bassi",
    "country.poland": "Polonia",
    "country.portugal": "Portogallo",
    "country.romania": "Romania",
    "country.slovakia": "Slovacchia",
    "country.slovenia": "Slovenia",
    "country.spain": "Spagna",
    "country.sweden": "Svezia",
    "country.norway": "Norvegia",
    "country.switzerland": "Svizzera",
    "country.ch": "Svizzera",
    "country.turkey": "Turchia",
    "country.unitedKingdom": "Regno Unito",
    "country.gb": "Regno Unito",
    "openingHours.tNowClosed": "Ora chiuso",
    "openingHours.tOpeningHoursNotKnown": "ulteriori orari di apertura non noti",
    "openingHours.tNowOpen": "Ora aperto",
    "openingHours.tNowStillOpen": "Ora ancora aperto",
    "openingHours.tNextOpeningHourUnknown": "prossima data di apertura sconosciuta",
    "openingHours.tSoonOpenFrom": "aperto di nuovo presto da",
    "openingHours.tFrom": "da",
    "openingHours.tUntil": "Da",
    "openingHours.tOpenAgainFrom": "aprire di nuovo da",
    "openingHours.tOpenAgainFrom2": "aperto di nuovo il",

    // auto translatedNew
    "account.ui.additionalDeliveryAddresses": "Hai specificato diversi indirizzi di consegna.",
    "account.ui.editDeliveryAddresses": "Modifica indirizzo di consegna",
    "account.ui.editInvoiceAddress": "Modifica l'indirizzo dell'azienda",
    "account.ui.noDeliveryAddresses": "Non hai ancora specificato un indirizzo di consegna diverso.",
    "account.ui.addDeliveryAddresses": "Aggiungere un {{further}} Aggiungere un altro indirizzo di consegna",
    "account.ui.addDeliveryAddressTitle": "Aggiungere l'indirizzo di consegna",
    "account.ui.editDeliveryAddressTitle": "Modifica indirizzo di consegna",
    "account.ui.isDefaultAddress": "Questo è l'indirizzo di consegna predefinito",
    "account.ui.saveToApplyChanges": "Devi salvare la modifica, poi puoi, per esempio, specificare indirizzi di consegna diversi.",
    "checkout.cart.orderCommitting": "Il suo ordine è in fase di elaborazione - si prega di attendere",
    "checkout.checkout.paymentProblem": "Si è verificato un errore durante l'esecuzione del pagamento",
    "checkout.checkout.backToPayment": "Torna alle impostazioni di pagamento.",
    "user.ui.genderFemaleSalutation": "Signora.",
    "user.ui.genderMaleSalutation": "Il Sig.",
    "user.ui.thankYouForSubscribing": "Grazie per esserti iscritto alla nostra newsletter.",
    "user.ui.errorAtSubscribing": "Errore durante l'iscrizione alla newsletter",
    "user.ui.unsubscribe": "disconnettersi",
    "user.ui.unsubscribePage": "Sei sicuro di volerti cancellare dalla nostra newsletter?",
    "user.product.addedToFavorites": "L'articolo è stato aggiunto ai tuoi preferiti"
  };
});
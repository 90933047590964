define('webapp/services/widget-interactions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    changeCounter: 0,

    incCounter: function incCounter() {
      this.set('changeCounter', this.get('changeCounter') + 1);
    },


    shownUserModal: null, // userBanner, userImage

    init: function init() {
      this._super.apply(this, arguments);
      this.handlers = {};
    },
    resetVars: function resetVars() {
      this.setProperties({
        shownUserModal: null
      });
    },
    closeUserModal: function closeUserModal() {
      this.set('shownUserModal', null);
    },
    registerHandler: function registerHandler(name, handler, target) {
      this.handlers[name] = { handler: handler, target: target };
    },
    unregisterHandler: function unregisterHandler(name) {
      delete this.handlers[name];
    },


    actions: {
      showUserModal: function showUserModal(modalName) {
        this.set('shownUserModal', modalName);
      },
      closeUserModal: function closeUserModal() {
        this.closeUserModal();
      },
      removeImage: function removeImage(image) {
        if (this.handlers['removeImage']) {
          var _handlers$removeImage = this.handlers['removeImage'],
              handler = _handlers$removeImage.handler,
              target = _handlers$removeImage.target;

          handler.call(target, image);
        }
      },
      editImage: function editImage(image) {
        if (this.handlers['editImage']) {
          var _handlers$editImage = this.handlers['editImage'],
              handler = _handlers$editImage.handler,
              target = _handlers$editImage.target;

          handler.call(target, image);
        }
      },
      removeAvatarImage: function removeAvatarImage(image) {
        if (this.handlers['removeAvatarImage']) {
          var _handlers$removeAvata = this.handlers['removeAvatarImage'],
              handler = _handlers$removeAvata.handler,
              target = _handlers$removeAvata.target;

          handler.call(target, image);
        }
      },
      editAvatarImage: function editAvatarImage(image) {
        if (this.handlers['editAvatarImage']) {
          var _handlers$editAvatarI = this.handlers['editAvatarImage'],
              handler = _handlers$editAvatarI.handler,
              target = _handlers$editAvatarI.target;

          handler.call(target, image);
        }
      }
    }
  });
});
define('webapp/components/account/images-chooser', ['exports', 'webapp/mixins/account/media-edit', 'webapp/utils/media'], function (exports, _mediaEdit, _media) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function moveItemInArray(items, item, offset) {
    items = items.slice();
    var oldIdx = items.indexOf(item);
    var newIdx = oldIdx + offset;
    newIdx = (newIdx + items.length) % items.length;
    var tmpItem = items[newIdx];

    items[newIdx] = items[oldIdx];
    items[oldIdx] = tmpItem;
    return items;
  }

  exports.default = Ember.Component.extend(_mediaEdit.default, {
    showAddImageModal: false,

    maybeRemoveMainListImage: function maybeRemoveMainListImage(image) {
      var mainImageKey = this.get('mainImageKey');
      var listImageKey = this.get('listImageKey');
      var mainImagePath = this.get('model.' + mainImageKey + '.path');
      var listImagePath = this.get('model.' + listImageKey + '.path');

      if (image.path === mainImagePath) {
        this.set('model.' + mainImageKey, null);
      }

      if (image.path === listImagePath) {
        this.set('model.' + listImageKey, null);
      }
    },


    actions: {
      selectImageForKey: function selectImageForKey(image, key, listKey) {
        var assignedPath = this.get('model.' + key + '.path');
        if (assignedPath === image.path) {
          this.set('model.' + key, null);
        } else {
          this.set('model.' + key, image);
        }
        if (listKey) {
          var assignedListPath = this.get('model.' + listKey + '.path');
          if (assignedListPath === image.path) {
            this.set('model.' + listKey, null);
          } else {
            this.set('model.' + listKey, image);
          }
        }
      },
      removeImage: function removeImage(image) {
        var images = this.get('images');
        images = images.filter(function (x) {
          return x !== image;
        });
        this.set('images', images);
        this.maybeRemoveMainListImage(image);
      },
      addImage: function addImage() {
        this.set('showAddImageModal', true);
      },
      cancelAddImage: function cancelAddImage() {
        this.set('showAddImageModal', false);
      },
      confirmAddImage: function confirmAddImage(image) {
        var images = this.get('images');
        images.pushObject((0, _media.mediumToNested)(image));

        this.set('showAddImageModal', false);
      },
      moveImage: function moveImage(image, direction) {
        var oldImages = this.get('images');
        var newImages = moveItemInArray(oldImages, image, direction);
        this.set('images', newImages);
      },
      unregisterTrafoComp: function unregisterTrafoComp() {
        this.trafoComp = null;
      },
      registerTrafoComp: function registerTrafoComp(comp) {
        this.trafoComp = comp;
      }
    }
  });
});
define('webapp/components/detail/category-container-product', ['exports', 'webapp/utils/min-variation-price'], function (exports, _minVariationPrice) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),
    selectedQuantity: 1,
    selectedVariant: null,

    minVariationPrice: Ember.computed('item', function () {
      var context = this.get('item');
      var isVatFreeBusiness = this.get('userProxy.isVatFreeBusiness');
      if (isVatFreeBusiness) {
        return (0, _minVariationPrice.minVariationPrice)('Net', 'variants', context);
      } else {
        return (0, _minVariationPrice.minVariationPrice)('Gross', 'variants', context);
      }
    }),

    _updateFavoriteCount: function _updateFavoriteCount() {
      var event = new Event('updated-favorite-count');
      var elements = document.getElementsByClassName('tm-widget-favorites');
      var element = elements[0];
      element.dispatchEvent(event);
    },


    actions: {
      selectVariant: function selectVariant(item) {
        this.set('selectedVariant', item);
      },
      selectQuantity: function selectQuantity(value) {
        this.set('selectedQuantity', value);
      },
      addToShoppingCart: function addToShoppingCart(product, quantity) {
        this.get('shoppingCartService').addToShoppingCart(product, quantity);
      },
      addToFavorite: function addToFavorite(evt) {
        var _this = this;

        evt.stopPropagation();

        var message = this.get('message');
        var myStorage = this.get('myStorage');
        var item = this.get('item._id');
        var data = { item: item };

        myStorage.favorite.ajaxPost({ data: data }).then(function () {
          _this.set('item.isFavorite', true);
          message.infoT('user.product.addedToFavorites');
          _this._updateFavoriteCount();
        });

        return false;
      }
    }
  });
});
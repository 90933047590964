define('webapp/components/widgets/widget-language-switcher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    languageService: Ember.inject.service(),
    viewService: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),

    languages: Ember.computed.alias('languageService.languages'),

    actions: {
      switchLanguage: function switchLanguage(lang) {
        var languageService = this.get('languageService');
        languageService.switchLanguage(lang);
      }
    }
  });
});
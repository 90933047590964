define('webapp/components/set-rating', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    maxRating: 5,
    value: 0,
    intermediateValue: 0,

    stars: Ember.computed('intermediateValue', function () {
      var intermediateValue = this.get('intermediateValue');
      var maxRating = this.get('maxRating');

      var stars = [];
      for (var i = 1; i < maxRating + 1; i++) {
        var iconClass = void 0;

        if (i <= intermediateValue) {
          iconClass = 'rating-star filled';
        } else {
          iconClass = 'rating-star';
        }

        stars.push({
          value: i,
          iconClass: iconClass
        });
      }

      return stars;
    }),

    mouseLeave: function mouseLeave() {
      this.set('intermediateValue', this.get('value'));
    },


    actions: {
      highlightStar: function highlightStar(value) {
        this.set('intermediateValue', value);
      },
      setRating: function setRating(value) {
        this.set('value', value);
        var handler = this.get('setRating');
        handler && handler(value);
      }
    }
  });
});
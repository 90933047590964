define('webapp/routes/mailinglist-subscribe', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    cmsService: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    displayMessage: '',
    message: Ember.inject.service(),

    subscribe: function subscribe(params) {
      var myStorage = this.get('myStorage');
      var message = this.get('message');
      var controller = this.controllerFor(this.routeName);

      return myStorage.mail.ajaxGet({ param: 'opt_in/' + params.mailingListId + '/' + params.memberId }).then(function (res) {
        if (res && (res.message === 'success-member-verified' || res.message === 'error-list-does-not-need-validation')) {
          controller.set('displayMessage', 'user.ui.thankYouForSubscribing');
        } else {
          controller.set('displayMessage', 'user.ui.errorAtSubscribing');
        }
      }).catch(function (err) {
        controller.set('displayMessage', 'user.ui.errorAtSubscribing');
        message.error(err);
      });
    },
    model: function model(params) {
      var _this = this;

      var cmsService = this.get('cmsService');
      return cmsService.getAndSetActivePage('mailinglist-subscribe').catch(function () {}).then(function (model) {
        _this.subscribe(params);
        return model;
      });
    }
  });
});
define('webapp/components/widgets/widget-counter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      var type = 'entry'; // TODO widget options
      var myStorage = this.get('myStorage');

      myStorage.search.ajaxPost({
        data: {
          types: type,
          aggregations: false,
          size: 0
        }
      }).then(function (res) {
        _this.set('count', res.totalHits);
      });
    }
  });
});
define('webapp/utils/slugify', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function slugify(text) {
    if (!text) {
      return text;
    }
    text = text.toLowerCase();

    text = text.replace(/\u00ad/, ''); // invisible shy (soft hyphen)
    text = text.replace(/ä/g, 'ae');
    text = text.replace(/ö/g, 'oe');
    text = text.replace(/ü/g, 'ue');
    text = text.replace(/ß/g, 'ss');
    text = text.replace(/\W+/g, '-');
    text = text.replace(/-$/, '');
    text = text.replace(/^-/, '');

    return text;
  }

  exports.default = slugify;
});
define('webapp/helpers/dynamic-page-route', ['exports', 'webapp/utils/misc'], function (exports, _misc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var cachedCategories = void 0; // need global cache, because helper is instantiated for each link

  var categorySeparator = 'und-nur';

  exports.default = Ember.Helper.extend({
    cachedData: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    languageService: Ember.inject.service(),
    message: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (!cachedCategories) {
        this.get('cachedData').getFlatCategories().then(function (categories) {
          cachedCategories = categories;
          _this.recompute();
        });
      }
    },
    getFullCategorySlug: function getFullCategorySlug(categorySlug) {
      var cat = cachedCategories.find(function (x) {
        return x.slug === categorySlug;
      });
      var catHierarchy = [];

      if (!cat) {
        return '';
      }

      while (cat.parent) {
        catHierarchy.push(cat);
        cat = cachedCategories.find(function (x) {
          return x._id === cat.parent;
        });
      }

      catHierarchy = catHierarchy.reverse().slice(1); // first category is mainCategory not needed in cat slug
      return catHierarchy.map(function (x) {
        return x.slug;
      }).join('/');
    },
    handleCategoryRoute: function handleCategoryRoute(baseRoute, target, andOnlyPart) {
      if (cachedCategories) {
        var fullCategorySlug = this.getFullCategorySlug(target);
        if (andOnlyPart) {
          return (0, _misc.joinRoutes)(baseRoute, fullCategorySlug, categorySeparator, andOnlyPart);
        } else {
          return (0, _misc.joinRoutes)(baseRoute, fullCategorySlug);
        }
      } else {
        // categories not fetched, return error page, will be recomputed
        return '404';
      }
    },
    compute: function compute(_ref, hash) {
      var _ref2 = _slicedToArray(_ref, 3),
          pageType = _ref2[0],
          target = _ref2[1],
          page = _ref2[2];

      var message = this.get('message');
      var dynamicPageRoutes = this.get('cmsService.dynamicPageRoutes');
      var languageService = this.get('languageService');

      var baseRoute = void 0;
      if (page) {
        if (typeof page === 'string') {
          baseRoute = page;
        } else {
          // full page object
          baseRoute = languageService.translateSlug(page);
        }
      } else {
        baseRoute = dynamicPageRoutes[pageType];
      }

      if (!baseRoute) {
        var errMessage = 'there is no dynamic route for ' + pageType;
        message.infoUnique(errMessage);
        // throw new Error(errMessage);
      }

      if (pageType === 'categoryContainer') {
        return this.handleCategoryRoute(baseRoute, target, hash.andOnlyPart);
      } else {
        return (0, _misc.joinRoutes)(baseRoute, target);
      }
    }
  });
});
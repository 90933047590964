define('webapp/components/widgets/widget-region-hero', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    viewService: Ember.inject.service(),
    searchService: Ember.inject.service(),

    searchLink: Ember.computed('viewService.currentModel.region', function () {
      var searchService = this.get('searchService');
      var currentRegion = this.get('viewService.currentModel.region');
      if (!currentRegion) return null;
      var regionSlug = currentRegion.categories.map(function (x) {
        return x.slug;
      }).join('/');
      return searchService.getSearchPageRoute(regionSlug + '/');
    }),

    infoSlug: Ember.computed('pageModel.region.menu.pages', function () {
      var menuPages = this.get('pageModel.region.menu.pages');
      if (!menuPages) return;
      var foundPage = menuPages.find(function (page) {
        return page.page.subType == 'regionInfo';
      });

      return foundPage && foundPage.page.fullSlug;
    })
  });
});
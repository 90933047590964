define('webapp/routes/account', ['exports', 'webapp/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    languageService: Ember.inject.service(),

    activate: function activate() {
      var languageService = this.get('languageService');
      languageService.on('languageChange', this, this.watchLang);
    },
    deactivate: function deactivate() {
      var languageService = this.get('languageService');
      languageService.off('languageChange', this, this.watchLang);
    },
    watchLang: function watchLang() {
      this.refresh();
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (!this.get('userProxy.isLoggedIn')) {
        this.replaceWith('login');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        page: this.get('cmsService').getAndSetActivePage('account')
      }).then(function (res) {
        return res.page;
      });
    },
    getBreadcrumbs: function getBreadcrumbs() {
      return [{
        route: 'account',
        title: this.get('currentModel.menuTitle')
      }];
    }
  });
});
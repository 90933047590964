define('webapp/components/detail/category-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userProxy: Ember.inject.service(),
    categoryContainer: Ember.inject.service(),
    category: Ember.computed.alias('page.category'),
    items: Ember.computed.alias('categoryContainer.items'),
    parentSlug: Ember.computed.alias('categoryContainer.parentSlug'),
    isLoading: Ember.computed.alias('categoryContainer.isLoading'),
    categoryOpen: true,

    actions: {
      toggleChilds: function toggleChilds() {
        this.toggleProperty('categoryOpen');
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.scrollListener = this.scrollListener.bind(this);
    },
    didInsertElement: function didInsertElement() {
      var options = this.get('page.options');

      if (options.noInfiniteScroll) {
        // NO SCROLLING
      } else {
        window.addEventListener('scroll', this.scrollListener);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var options = this.get('page.options');

      if (options.noInfiniteScroll) {
        // NO SCROLLING
      } else {
        window.removeEventListener('scroll', this.scrollListener);
      }
    },
    scrollListener: function scrollListener() {
      this.get('categoryContainer').infiniteScroll();
    }
  });
});
define('webapp/services/comment-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    commentContainers: Ember.computed(function () {
      return {};
    }),

    registerCommentContainer: function registerCommentContainer(parentId, component) {
      var commentContainers = this.get('commentContainers');
      Ember.set(commentContainers, parentId, component);
    },
    unregisterCommentContainer: function unregisterCommentContainer(parentId) {
      var commentContainers = this.get('commentContainers');
      Ember.set(commentContainers, parentId, undefined);
    }
  });
});
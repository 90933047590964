define('webapp/components/content-editable', ['exports', 'ember-content-editable/components/content-editable'], function (exports, _contentEditable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _contentEditable.default;
    }
  });
});
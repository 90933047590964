define('webapp/components/map/geopoint-selector', ['exports', 'webapp/components/map/base-map'], function (exports, _baseMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseMap.default.extend({
    assignGeopoint: function assignGeopoint(evt) {
      var latLon = { lat: evt.lngLat.lat, lon: evt.lngLat.lng };
      this.sendAction('setGeopoint', latLon);
    },


    center: Ember.computed(function () {
      var geoPoint = this.get('geoPoint');

      if (geoPoint && geoPoint.lat) {
        return [geoPoint.lon, geoPoint.lat];
      } else {
        return [12, 49];
      }
    }),

    defaultZoom: Ember.computed('center', function () {
      if (this.get('geoPoint.lat')) {
        return 11;
      } else {
        return 0;
      }
    }),

    mapLoaded: function mapLoaded() {
      var _this = this;

      var map = this.get('map');

      map.on('click', function (evt) {
        _this.assignGeopoint(evt);
      });

      this.drawMarker();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    drawMarker: function drawMarker() {
      var geoPoint = this.get('geoPoint');
      var map = this.get('map');
      if (!(geoPoint && geoPoint.lat)) {
        return;
      }

      var marker = this.get('marker');
      if (marker) {
        marker.remove();
      }

      var lngLat = [geoPoint.lon, geoPoint.lat];
      var iconElement = this.getIcon();
      marker = new mapboxgl.Marker(iconElement).setLngLat(lngLat);
      marker.addTo(map);
      this.set('marker', marker);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var map = this.get('map');

      if (map && map.loaded()) {
        this.drawMarker();
      }
    }
  });
});
define('webapp/helpers/background-image', ['exports', 'webapp/utils/image-path'], function (exports, _imagePath) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var newPath = (0, _imagePath.default)(params[0], params[1]);
    var val = "background-image:url(" + newPath + ")";
    return Ember.String.htmlSafe(val);
  });
});
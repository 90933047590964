define('webapp/components/account/user-impersonate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),
    searchTerm: '',
    users: Ember.computed(function () {
      return [];
    }),

    search: function search(searchTerm) {
      var myStorage = this.get('myStorage');

      return myStorage.user.ajaxPost({
        param: 'search',
        data: {
          perPage: 10,
          search: {
            term: searchTerm,
            field: ['firstName', 'lastName', 'email', 'userName', 'companyName']
          }
        }
      });
    },
    debouncedSearch: function debouncedSearch(searchTerm) {
      var _this = this;

      var searchP = this.search(searchTerm);
      this.searchP = searchP;

      searchP.then(function (res) {
        if (searchP === _this.searchP) {
          _this.set('users', res.model);
        }
      });
    },
    _loginUser: function _loginUser(user) {
      var userProxy = this.get('userProxy');
      var shoppingCartService = this.get('shoppingCartService');
      shoppingCartService.reset();
      userProxy._login(user);
      var router = this.get('router');
      var currentRoute = Ember.getOwner(this).lookup('route:' + router.currentRouteName);
      currentRoute.refresh();
    },


    actions: {
      searchKeyUp: function searchKeyUp(e) {
        var searchTerm = e.target.value;
        this.set('searchTerm', searchTerm);
        Ember.run.debounce(this, this.debouncedSearch, searchTerm, 200);
      },
      impersonateUser: function impersonateUser(user) {
        var _this2 = this;

        var myStorage = this.get('myStorage');

        myStorage.user.ajaxPost({
          param: 'impersonate',
          data: {
            targetUser: user._id
          }
        }).then(function (res) {
          _this2._loginUser(res);
        });
      },
      unimpersonate: function unimpersonate() {
        var _this3 = this;

        var myStorage = this.get('myStorage');
        myStorage.user.ajaxPost({ param: 'unimpersonate' }).then(function (user) {
          _this3._loginUser(user);
        });
      }
    }
  });
});

if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('webapp/app')['default'].create({"name":"webapp","version":"0.0.0+b74b8fd4"});
  }
}

define('~fastboot/app-factory', ['webapp/app', 'webapp/config/environment'], function(App, config) {
  App = App['default'];
  config = config['default'];

  return {
    'default': function() {
      return App.create(config.APP);
    }
  };
});


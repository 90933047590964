define('webapp/controllers/redeem-code', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    userProxy: Ember.inject.service(),

    verificationEmail: '',
    target: null,
    entries: null,

    emailMatches: Ember.computed('userProxy.email', 'verificationEmail', function () {
      var email = this.get('userProxy.email');
      var verificationEmail = this.get('verificationEmail');

      if (!email || !verificationEmail) {
        return false;
      }

      return email.toLowerCase() === verificationEmail.toLowerCase();
    }),

    actions: {
      redeemCode: function redeemCode() {
        var _this = this;

        var myStorage = this.get('myStorage');
        var message = this.get('message');
        return myStorage.redemptionCode.ajaxPost({
          data: {
            code: this.get('model.code'),
            targetModel: 'Entry',
            target: this.get('target')
          }
        }).then(function () {
          message.info('Success');
          _this.set('success', true);
          // TODO redirect to ?
        }).catch(function (err) {
          if (err.status === 409) {
            message.error('Code bereits entwertet.');
          } else {
            message.error(err.payload.message);
          }
        });
      }
    }
  });
});
define("webapp/utils/price-calculations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function calcNetPrice(priceGross, taxRate) {
    var rawNetPrice = priceGross / (1 + 0.01 * taxRate);
    var netPrice = Math.round(rawNetPrice);

    return netPrice;
  }

  function calcGrossPrice(netPrice, taxRate) {
    var rawGrossPrice = netPrice / (1 + 0.01 * taxRate);
    var priceGross = Math.round(rawGrossPrice);

    return priceGross;
  }

  function calcFinalTaxes(taxRate, precision) {
    var finalTaxRate = taxRate / precision;

    return finalTaxRate;
  }

  function sumField(items, field) {
    return items.reduce(function (sum, item) {
      return sum + item[field];
    }, 0);
  }

  exports.calcNetPrice = calcNetPrice;
  exports.calcGrossPrice = calcGrossPrice;
  exports.calcFinalTaxes = calcFinalTaxes;
  exports.sumField = sumField;
});
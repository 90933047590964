define('webapp/helpers/file-icon', ['exports', 'webapp/utils/file-extension'], function (exports, _fileExtension) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fileIcon = fileIcon;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var mapping = {
    // "pdf": "uk-icon-file-pdf-o",
    // "xls": "uk-icon-file-excel-o",
    // "xlsx": "uk-icon-file-excel-o",
    // "doc": "uk-icon-file-word-o",
    // "docx": "uk-icon-file-word-o",
    // "png": "uk-icon-file-picture-o",
    // "jpg": "uk-icon-file-picture-o",
    // "gif": "uk-icon-file-picture-o",
    // "mov": "uk-icon-file-movie-o",
    // "webm": "uk-icon-file-movie-o",
    // "mp4": "uk-icon-file-movie-o",
    // "wav": "uk-icon-file-sound-o",
    // "aiff": "uk-icon-file-sound-o",
    // "mp3": "uk-icon-file-sound-o",
    // "ppt": "uk-icon-file-powerpoint-o",
    // "pptx": "uk-icon-file-powerpoint-o",
    // "zip": "uk-icon-file-archive-o",
    // "rar": "uk-icon-file-archive-o",
    // "html": "uk-icon-file-code-o",
    // "css": "uk-icon-file-code-o",
    // "js": "uk-icon-file-code-o"
    "pdf": "file-pdf",
    "doc": "file-text",
    "docx": "file-text",
    "zip": "folder"
  };

  function fileIcon(_ref) /*, hash*/{
    var _ref2 = _slicedToArray(_ref, 1),
        path = _ref2[0];

    var ext = (0, _fileExtension.default)(path);

    var fileClass = mapping[ext];

    //return 'uk-icon ' + (fileClass || 'file-o');
    return fileClass || '';
  }

  exports.default = Ember.Helper.helper(fileIcon);
});
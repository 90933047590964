define('webapp/components/send-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var userProxy = this.get('userProxy');
      this.setProperties({
        initialized: false,
        data: { address: {} },
        title: '',
        description: '',
        target: null,
        targetModel: null
      });

      userProxy.fetchOwnEntries().then(function (entries) {
        _this.set('entries', entries);
        _this.set('initialized', true);
      }).catch(function (err) {
        _this.set('initialized', true);
      });
    },


    dataIncomplete: Ember.computed('title', function () {
      return !this.get('title');
    }),

    actions: {
      selectEntry: function selectEntry(entryId) {
        this.setProperties({
          target: entryId,
          targetModel: 'Entry'
        });
      },
      sendMessage: function sendMessage() {
        var _this2 = this;

        var message = this.get('message');
        var myStorage = this.get('myStorage');
        var toUser = this.get('toUser._id');

        var messageData = {
          toUser: toUser,
          title: this.get('title'),
          description: this.get('description'),
          data: this.get('data'),
          target: this.get('target'),
          targetModel: this.get('targetModel')
        };

        myStorage.message.ajaxPost({ param: 'send', data: messageData }).then(function () {
          message.info('Anfrage verschickt');
          _this2.get('close') && _this2.get('close')();
        }).catch(function (err) {
          console.error(err.stack);
          message.error('Fehler beim Verschicken der Anfrage');
        });
      }
    }
  });
});
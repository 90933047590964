define('webapp/routes/account/profile/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    userProxy: Ember.inject.service(),
    widgetInteractions: Ember.inject.service(),

    activate: function activate() {
      var controller = this.controllerFor(this.routeName);
      this.get('widgetInteractions').registerHandler('removeImage', controller.removeBanner, controller);
      this.get('widgetInteractions').registerHandler('editImage', controller.editBanner, controller);
      this.get('widgetInteractions').registerHandler('removeAvatarImage', controller.removeAvatar, controller);
      this.get('widgetInteractions').registerHandler('editAvatarImage', controller.editAvatar, controller);
    },
    deactivate: function deactivate() {
      this.get('widgetInteractions').unregisterHandler('removeImage');
      this.get('widgetInteractions').unregisterHandler('editImage');
      this.get('widgetInteractions').unregisterHandler('removeAvatarImage');
      this.get('widgetInteractions').unregisterHandler('editAvatarImage');
    },
    model: function model() {
      var userProxy = this.get('userProxy');
      return userProxy.getUser();
    }
  });
});
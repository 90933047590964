define('webapp/components/detail/campaign-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    gdprService: Ember.inject.service(),
    trackingService: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var gdprService = this.get('gdprService');
      gdprService.one('gdprAccepted', this, this.initTrackers);
    },
    willDestroyElement: function willDestroyElement() {
      var gdprService = this.get('gdprService');
      gdprService.off('gdprAccepted', this, this.initTrackers);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.isDestroyed) {
        Ember.run.debounce(this, this.initTrackers, 500);
      }
    },
    initTrackers: function initTrackers() {
      var detail = this.get('detail');
      this.get('trackingService').trackGA(detail);
    }
  });
});
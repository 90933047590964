define('webapp/components/range-slider', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['range-slider'],
    min: null,
    max: null,
    from: null,
    to: null,
    rangeModel: null,

    didInsertElement: function didInsertElement() {
      var opts = this.getProperties(['min', 'max', 'from', 'to']);
      if (!opts.from) opts.from = opts.min;
      if (!opts.to) opts.to = opts.max;
      this.setProperties({
        _from: opts.from,
        _to: opts.to
      });

      var $this = this;
      var sliderOptions = Ember.Object.create({
        type: "double",
        min: opts.min,
        max: opts.max,
        from: opts.from,
        to: opts.to,
        prettify_enabled: false,
        onFinish: function onFinish(data) {
          $this._sliderChanged(data);
        }
      });

      var addSliderOptions = this.get('rangeModel.sliderOptions');
      if (addSliderOptions) sliderOptions.setProperties(addSliderOptions);

      var slider = this.$('input').ionRangeSlider(sliderOptions).data("ionRangeSlider");

      this.set('_slider', slider);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var opts = this.getProperties(['min', 'max']);
      if (opts.min != null) this.get('_slider').update(opts);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('_slider').destroy();
    },
    _sliderChanged: function _sliderChanged(data) {
      this.setProperties({
        _from: data.from,
        _to: data.to
      });
      this.sendAction('rangeChange', { from: data.from, to: data.to });
    }
  });
});
define('webapp/components/account/documents-chooser', ['exports', 'webapp/settings/media-extensions', 'webapp/mixins/account/media-edit'], function (exports, _mediaExtensions, _mediaEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function calcPath(item) {
    var path = item.name + '.' + (item.generatedExtension || item.extension);
    if (item.directory) {
      path = item.directory + '/' + path;
    }
    return path;
  }

  exports.default = Ember.Component.extend(_mediaEdit.default, {
    i18n: Ember.inject.service(),
    documentExtensions: Ember.computed(function () {
      return _mediaExtensions.default.DOCUMENT_EXTENSIONS.slice();
    }),

    buttonText: Ember.computed(function () {
      var i18n = this.get('i18n');
      return i18n.t('application.ui.addDocument').toString();
    }),

    showAddItemModal: false,

    actions: {
      removeItem: function removeItem(item) {
        var items = this.get('items');
        items = items.filter(function (x) {
          return x !== item;
        });
        this.set('items', items);
      },
      addItem: function addItem() {
        this.set('showAddItemModal', true);
      },
      cancelAddItem: function cancelAddItem() {
        this.set('showAddItemModal', false);
      },
      confirmAddItem: function confirmAddItem(item) {
        console.log('confirmAddItem');
        var items = this.get('items');

        items.pushObject({
          path: calcPath(item),
          mediaId: item._id,
          description: Ember.get(item, 'infos.description') || '',
          copyright: Ember.get(item, 'infos.copyright') || ''
        });

        this.set('showAddItemModal', false);
      },
      afterUpload: function afterUpload(files) {
        if (this.get('instantAdd')) {
          var items = this.get('items');

          items.pushObjects(files.map(function (item) {
            return {
              path: calcPath(item),
              mediaId: item._id,
              description: Ember.get(item, 'infos.description') || '',
              copyright: Ember.get(item, 'infos.copyright') || ''
            };
          }));

          this.set('showAddItemModal', false);
        }
        this.sendAction('afterUpload');
      }
    }
  });
});
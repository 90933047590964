define('webapp/routes/account/pois/index', ['exports', 'webapp/mixins/list-route'], function (exports, _listRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _listRoute.default.extend({
    itemStorage: Ember.computed.alias('myStorage.poiAdmin'),
    modelName: 'Poi'
  });
});
define('webapp/routes/account/orders/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    customerService: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),

    model: function model() {
      var customerService = this.get('customerService');
      return customerService.getOrders();
    },


    actions: {
      buyAgain: function buyAgain(item) {
        var shoppingCartService = this.get('shoppingCartService');

        var product = {
          _id: item.itemId,
          priceGross: item.priceGross,
          priceNet: item.priceNet
        };

        shoppingCartService.addToShoppingCart(product);
      }
    }
  });
});
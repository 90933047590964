define('webapp/routes/account/events/edit', ['exports', 'webapp/mixins/edit-route'], function (exports, _editRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _editRoute.default.extend({
    itemStorage: Ember.computed.alias('myStorage.eventAdmin'),
    modelName: 'Event'
  });
});
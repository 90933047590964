define('webapp/components/widgets/widget-ad', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    isOpen: true,

    didInsertElement: function didInsertElement() {
      var scrollElement = this.element;
      var showWidget = this.get('data.options.trackScroll');
      var showScroll = this.get('data.options.trackPosition');
      if (showWidget) {
        scrollElement.classList.add('uk-hidden');
      }
      document.onscroll = function () {
        var scrollWindow = document.body.parentNode;
        var scrollPosition = Math.round((document.body.scrollTop || scrollWindow.scrollTop) / (scrollWindow.scrollHeight - scrollWindow.clientHeight) * 100);
        if (scrollPosition > showScroll) {
          scrollElement.classList.remove('uk-hidden');
        }
      };
    },

    actions: {
      closeWidget: function closeWidget() {
        this.set('isOpen', false);
      }
    }
  });
});
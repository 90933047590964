define('webapp/controllers/account/documents/folder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      download: function download(file) {
        var user = this.get('userProxy');
        var myStorage = this.get('myStorage');
        var fingerPrint = this.get('fingerprintService');
        myStorage.media.ajaxPost({
          param: 'public_download',
          data: { media: file, fingerPrint: fingerPrint, user: user }
        });
      }
    }
  });
});
define('webapp/helpers/detail-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // TODO replace by dynamic-page-route
  var itemTypeToPageType = {
    entry: 'entryContainer',
    poi: 'poiContainer',
    event: 'eventContainer',
    product: 'productContainer',
    article: 'articleContainer',
    job: 'jobContainer',
    recipe: 'recipeContainer',
    campaign: 'campaignContainer'
  };

  exports.default = Ember.Helper.extend({
    entryContainer: Ember.inject.service(),
    message: Ember.inject.service(),
    cmsService: Ember.inject.service(),

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          target = _ref2[0],
          _ref2$ = _ref2[1],
          itemType = _ref2$ === undefined ? 'entry' : _ref2$;

      var pageType = itemTypeToPageType[itemType];

      var message = this.get('message');
      var dynamicPageRoutes = this.get('cmsService.dynamicPageRoutes');

      var baseRoute = dynamicPageRoutes[pageType];
      if (!baseRoute) {
        var errMessage = 'there is no dynamic route for ' + pageType;
        message.infoUnique(errMessage);
      }

      return baseRoute + '/' + target;
    }
  });
});
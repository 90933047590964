define('webapp/components/widgets/widget-theme-switch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    templateSettings: Ember.inject.service(),
    selectedTheme: Ember.computed.oneWay('templateSettings.selectedTheme'),

    actions: {
      toggleTheme: function toggleTheme() {
        var selectedTheme = this.get('selectedTheme');
        var templateSettings = this.get('templateSettings');

        var newSelectedTheme = selectedTheme === 'dark' ? 'light' : 'dark';

        templateSettings.setTheme(newSelectedTheme);
      }
    }
  });
});
define('webapp/components/widgets/widget-page-teaser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    message: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    languageService: Ember.inject.service(),

    fetchPages: function fetchPages() {
      var _this = this;

      var pages = this.get('data.options.pages') || [];

      var myStorage = this.get('myStorage');

      myStorage.page.ajaxPost({
        param: 'by_ids',
        data: {
          pageIds: pages
        },
        query: { lang: this.get('languageService.selectedLanguage') }
      }).then(function (pages) {
        _this.set('pages', pages);
      });
    },
    didInsertElement: function didInsertElement() {
      this.fetchPages();
    }
  });
});
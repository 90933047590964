define('webapp/components/external-content', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function refreshEmbeds() {
    if (window.twttr) {
      try {
        window.twttr.widgets.load();
      } catch (err) {
        console.error(err);
      }
    }
    if (window.instgrm) {
      try {
        window.instgrm.Embeds.process();
      } catch (err) {
        console.error(err);
      }
    }
    if (window.FB) {
      try {
        window.FB.XFBML.parse();
      } catch (err) {
        console.error(err);
      }
    }
  }

  function injectScript(scriptSrc) {
    if (document.head.querySelector('script[src="' + scriptSrc + '"]')) {
      return;
    }
    var script = document.createElement('script');
    script.src = scriptSrc;
    document.head.appendChild(script);
  }

  var scriptRe = /<script.* src="(.*?)"/;
  function maybeExecuteScript(code) {
    if (!code) {
      return;
    }
    var match = code.match(scriptRe);
    if (match) {
      injectScript(match[1]);
    } else if (code.includes('class="fb-post"')) {
      injectScript('https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2');
    }
    refreshEmbeds();
  }

  exports.default = Ember.Component.extend({
    didRender: function didRender() {
      var code = this.get('code');
      maybeExecuteScript(code);
      Ember.run.later(function () {
        refreshEmbeds();
      }, 100);
    }
  });
});
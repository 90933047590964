define('webapp/services/fingerprint-service', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    fingerprint: null,
    isKnown: false,
    wasOnPage: false,
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    router: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    cachedData: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    languageService: Ember.inject.service(),
    gdprService: Ember.inject.service(),

    postFingerprintAndLocation: function postFingerprintAndLocation(location) {
      var _this = this;

      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var optData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (_environment.default.fingerprint) {
        var gdprStatus = this.get('gdprService.gdprStatus');
        var myStorage = this.get('myStorage');
        var userProxy = this.get('userProxy');
        return this.getFingerprint().then(function (fingerprint) {
          var param = 'fingerprint';
          var user = userProxy._id || null;
          if (gdprStatus !== 'accepted') {
            fingerprint = undefined;
          }
          var data = {
            fingerprint: fingerprint,
            location: location,
            user: user,
            gdprStatus: gdprStatus
          };
          if (type) {
            data['type'] = type;
          }
          if (optData) {
            data['data'] = optData;
          }
          var query = {
            site: _this.get('cachedData.cachedSite._id'),
            sessionId: _this.get('cmsService.sessionId'),
            lang: _this.get('languageService.selectedLanguage')
          };
          _this.set('fingerprint', fingerprint);
          return myStorage.user.ajaxPost({ param: param, data: data, query: query }).then(function (res) {
            _this.setProperties({
              isKnown: res.isKnown,
              isKnownTime: res.isKnownTime,
              wasOnPage: res.wasOnPage,
              wasOnPageTime: res.wasOnPageTime
            });
            return res;
          });
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },
    getFingerprint: function getFingerprint() {
      if (!this._cachedFingerprint) {
        this._cachedFingerprint = new Ember.RSVP.Promise(function (resolve) {
          Fingerprint2.get({
            userDefinedFonts: ['Nimbus Mono', 'Junicode', 'Presto']
          }, function (components) {
            var values = components.map(function (component) {
              return component.value;
            });
            var murmur = Fingerprint2.x64hash128(values.join(''), 31);
            resolve(murmur);
          });
        });
      }

      return this._cachedFingerprint;
    },
    handleBeforeTransition: function handleBeforeTransition(transition) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (this.get('fastboot.isFastBoot')) {
        return Ember.RSVP.resolve();
      } else {
        var targetURL = this._getTargetUrl(transition);
        return this.postFingerprintAndLocation(targetURL, type, data);
      }
    },


    // https://stackoverflow.com/questions/31140407/ember-get-target-url-of-transition
    _getTargetUrl: function _getTargetUrl(transition) {
      var router = this.get('router._router');
      var paramsCount = 0;
      var params = {};
      var args = [transition.targetName];
      // Iterate over route segments
      for (var key1 in transition.params) {
        if (transition.params.hasOwnProperty(key1)) {
          var segment = transition.params[key1];
          // Iterate over the params for the route segment.
          for (var key2 in segment) {
            if (segment.hasOwnProperty(key2)) {
              if (segment[key2] != null) {
                params[key2] = segment[key2];
                paramsCount++;
              }
            }
          }
        }
      }
      if (paramsCount > 0) {
        args.push(params);
      }
      return router.generate.apply(router, args);
    }
  });
});
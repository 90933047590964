define("webapp/locales/sv/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.entryBannerImageNew": "Ange en huvudbild för ditt bidrag.",
    "account.ui.profileBannerImageNew": "Ange en huvudbild för din profil.",
    "account.ui.register": "Registrera",
    "account.ui.registeredAs": "Du är för närvarande registrerad som",
    "account.ui.personate": "Ändra användaridentitet",
    "account.ui.userNameNotEditableTooltip": "Ditt användarnamn. Du kan inte ändra det användarnamn som du har angett. Om du vill ändra ditt användarnamn kontaktar du din kontaktperson. Viktigt: Efter en ändring måste du logga in igen med det nya användarnamnet.",
    "account.ui.additionalDeliveryAddresses": "Du har angett olika leveransadresser.",
    "account.ui.editDeliveryAddresses": "Redigera leveransadressen",
    "account.ui.editInvoiceAddress": "Redigera företagets adress",
    "account.ui.noDeliveryAddresses": "Du har ännu inte angett någon annan leveransadress.",
    "account.ui.addDeliveryAddresses": "Lägg till en {{further}} Lägg till en annan leveransadress",
    "account.ui.addDeliveryAddressTitle": "Lägg till leveransadress",
    "account.ui.editDeliveryAddressTitle": "Redigera leveransadressen",
    "account.ui.isDefaultAddress": "Detta är standardleveransadressen",
    "account.ui.saveToApplyChanges": "Du måste spara ändringen, sedan kan du t.ex. ange olika leveransadresser.",
    "accountTypes.aussteller": "Utställare",
    "accountTypes.gastgeber": "Värd",
    "accountTypes.veranstalter": "Arrangör",
    "application.beta.infotext": "Inga prenumerationer har skapats.",
    "application.breadcrumbsHome": "Hem",
    "application.month": "på månadsbasis",
    "application.newsletter.alreadySubscribed": "Du är redan prenumerant på {{list}} nyhetsbrev. Du kan få tillgång till prenumerationerna på nyhetsbrev på ditt konto.",
    "application.newsletter.alreadySubscribedNoList": "Du har redan registrerat dig för nyhetsbrevet",
    "application.newsletter.goToList": "Uppringning av sändlista",
    "application.newsletter.subscribeButton": "Ange din e-postadress för att prenumerera på nyhetsbrevet",
    "application.newsletter.subscribeButtonMobile": "E-post",
    "application.newsletter.noAccess": "Det nyhetsbrev som erbjuds kan inte prenumerera på ditt tillstånd.",
    "application.newsletter.subscribe": "Prenumerera på",
    "application.newsletter.subscribeList": "Du prenumererar på detta nyhetsbrev från:",
    "application.newsletter.subscribeThanks": "Tack för din prenumeration på nyhetsbrevet. Du kommer att få ett e-postmeddelande som bekräftar att du har valt att delta.",
    "application.newsletter.subscribeWithAccount": "Prenumerera med din nuvarande inloggade användare.",
    "application.noCosts": "Gratis",
    "application.placeholderImageText": "Utan illustration",
    "application.searchAllGroups": "överhuvudtaget",
    "application.searchAllRegions": "på alla ställen",
    "application.ui.access": "gå till",
    "application.ui.action": "Åtgärd",
    "application.ui.addDocument": "Lägg till dokument",
    "application.ui.addRelation": "Lägg till anslutning",
    "application.ui.addImage": "Lägg till en bild",
    "application.ui.addImages": "Lägg till bilder",
    "application.ui.addMedia": "Lägg till bilder/videor",
    "application.ui.addVideo": "Lägg till video",
    "application.ui.advancedEdit": "Förbättrad",
    "application.ui.all": "Alla",
    "application.ui.alphabetical": "Alfabetisk",
    "application.ui.and": "och",
    "application.ui.at": "på",
    "application.ui.in": "på",
    "application.ui.atHour": "på",
    "application.ui.hour": "Titta på",
    "application.ui.buy": "köpa",
    "application.ui.certificate": "Certifikat",
    "application.ui.confirmDeleteEntry": "Radera posten {{title}} radera posten oåterkalleligt?",
    "application.ui.contact": "Kontakta",
    "application.ui.count": "Antal",
    "application.ui.currentlyActive": "Kör just nu",
    "application.ui.deleteEntry": "ta bort",
    "application.ui.discardUnsavedChanges": "Förkasta ändringar och fortsätta",
    "application.ui.discardUnsavedChangesMobile": "Kasta",
    "application.ui.distance": "Avstånd",
    "application.ui.distant": "bort",
    "application.ui.dreampoints": "Dreampoints",
    "application.ui.dreampointsAllDreams": "Dreampoints för dina bidrag",
    "application.ui.dreampointsDonate": "Dreampoints som ska doneras",
    "application.ui.dreampointsTooltip": "Du kan samla Dreampoints med ditt bidrag.",
    "application.ui.edit": "Ändra",
    "application.ui.editDescription": "Redigera beskrivning",
    "application.ui.editEntry": "redigera",
    "application.ui.editEvent": "Redigera händelse",
    "application.ui.editImage": "Redigera bilden",
    "application.ui.editPoi": "Redigera plats",
    "application.ui.editProduct": "Redigera produkt",
    "application.ui.editProfile": "Redigera min profil",
    "application.ui.editShownProfile": "Ändra min profilvisning",
    "application.ui.entriesFocus": "Utvalda poster i Focus:",
    "application.ui.entriesLatest": "De senaste inläggen i Fokus:",
    "application.ui.email": "E-post",
    "application.ui.for": "för",
    "application.ui.fullView": "Allmänt",
    "application.ui.gallery": "Bilder",
    "application.ui.galleryImages": "till galleriet...",
    "application.ui.gavePoints": "gav dig {{points}} Jag gav dig poäng.",
    "application.ui.goToEntry": "Ingång till samtal",
    "application.ui.goToEvent": "Uppringning av händelse",
    "application.ui.goToPoi": "Plats för uppringning",
    "application.ui.goToMeasuring": "Mätpunkter",
    "application.ui.goToProduct": "Ring upp produkten",
    "application.ui.hasBeen": "har varit",
    "application.ui.here": "Närliggande",
    "application.ui.invalidEmbed": "Den här URL:n kan inte bäddas in.",
    "application.ui.imprintLink": "Ansvarig och juridiskt ansvarig för innehållet på denna sida. {{entry}} - Tryck på",
    "application.ui.isActive": "Aktiv",
    "application.ui.isNotActive": "Inaktiverad",
    "application.ui.knowMore": "Lär dig mer om {{about}}",
    "application.ui.language.de": "Deutsch",
    "application.ui.language.en": "English",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "Italiano",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "För närvarande valt språk",
    "application.ui.language.switchTo": "Ändra språk till ",
    "application.ui.mailBodyPre": "Hej, jag har hittat något intressant:",
    "application.ui.mailSubject": "Jag hittade en intressant länk:",
    "application.ui.mandatory": "Obligatoriskt fält",
    "application.ui.memberSince": "Medlem sedan",
    "application.ui.mobileRotate": "Rotera för helskärmsvisning",
    "application.ui.more": "mer",
    "application.ui.name": "Namn",
    "application.ui.navigateOneLevelUp": "Tillbaka till den överordnade mappen",
    "application.ui.new": "Ny",
    "application.ui.newEntry": "Skapa en ny post",
    "application.ui.newEvent": "Skapa en ny affärshändelse",
    "application.ui.newPoi": "Skapa en ny plats",
    "application.ui.newProduct": "Skapa en ny produkt",
    "application.ui.noEntry": "Du har inte skapat någon post ännu. Skapa din första post redan nu...",
    "application.ui.noEntryProfile": "har inte skapat någon post ännu.",
    "application.ui.noEvent": "Du har inte skapat något evenemang ännu. Skapa ditt första evenemang redan nu...",
    "application.ui.noPoi": "Du har inte skapat någon plats ännu. Skapa din första plats redan nu...",
    "application.ui.noJob": "Du har inte skapat något jobb ännu. Skapa ditt första jobb nu...",
    "application.ui.noProduct": "Du har inte skapat någon produkt ännu. Skapa din första produkt redan nu...",
    "application.ui.notEditable": "kan inte ändras.",
    "application.ui.nothingFound": "Vi kunde inte hitta några resultat för denna sökning.",
    "application.ui.offer": "Erbjudanden",
    "application.ui.only": "Endast",
    "application.ui.openDocument": "Öppna fil",
    "application.ui.or": "eller",
    "application.ui.playVideo": "Spela upp video",
    "application.ui.readMore": "Läs mer",
    "application.ui.region": "Region",
    "application.ui.relevance": "Relevans",
    "application.ui.reset": "Återställ",
    "application.ui.routingDescription": "Vägbeskrivning",
    "application.ui.saveShownProfile": "Spara profiländringar",
    "application.ui.saveUnsavedChanges": "Spara",
    "application.ui.select": "Vänligen välj",
    "application.ui.shop": "Butik",
    "application.ui.showAllEntries": "Visa alla poster",
    "application.ui.showAllSearch": "Visa alla resultat",
    "application.ui.showMoreSearch": "Visa fler sökresultat",
    "application.ui.resetSearch": "Återställ urvalet och sök på nytt",
    "application.ui.sort": "Sortering",
    "application.ui.stockPhotoLinks": "Länkar till bilddatabaser",
    "application.ui.suitableFor": "Rekommenderas för",
    "application.ui.total": "Totalt",
    "application.ui.totalDreampoints": "Dreampoints av alla dina bidrag",
    "application.ui.trySearch": "Vill du prova andra sökord, till exempel.",
    "application.ui.type": "Typ",
    "application.ui.unsavedChanges": "Osparade ändringar",
    "application.ui.updated": "UPPDATERAD",
    "application.ui.uploadedFileFor": "Uppladdad fil för:",
    "application.ui.userName": "Användarnamn",
    "application.ui.visibleForAll": "Synlig för alla användare",
    "application.ui.visibleForFollower": "Synlig för följare",
    "application.ui.visibleForJoiner": "Synlig för fogaren",
    "application.ui.visibleForNone": "Inte synlig för någon",
    "application.ui.visibleForSupporter": "Synlig för supportrar",
    "application.ui.writeUs": "Skriv till oss...",
    "application.ui.years": "År",
    "application.ui.yearsFew": "Babyålder",
    "application.widgets.highestRatedEntries": "De flesta Dreampoints",
    "application.widgets.inFocus": "I fokus",
    "application.widgets.newestEntries": "De senaste bidragen",
    "blog.ui.category": "Kategori",
    "blog.ui.categoryHeading": "Från kategorin",
    "blog.ui.lastUpdated": "Senast uppdaterad den",
    "blog.ui.openSinglePost": "Läs mer",
    "blog.ui.publishedIn": "Publicerad i",
    "blog.ui.subCategories": "Underkategorier",
    "blog.ui.written": "",
    "blog.ui.writtenBy": "Skrivet av",
    "buttons.alreadyMeToo": "Jag är glad att du också drömde om detta inlägg.",
    "buttons.alreadyMeTooMobile": "Jag är glad att du också drömde om detta inlägg.",
    "buttons.cancel": "Avbryt",
    "buttons.confirm": "Bekräfta",
    "buttons.createAccount": "Skapa EntryerAccount nu",
    "buttons.delete": "ta bort",
    "buttons.deleteFavorite": "Följer inte längre",
    "buttons.deleteLike": "Inga fler likheter",
    "buttons.meToo": "Jag vill också göra det.",
    "buttons.newPassword": "Begär nytt lösenord",
    "buttons.ok": "Okej",
    "buttons.ready": "Redo",
    "buttons.save": "Spara ändringar",
    "buttons.saveMobile": "Spara",
    "buttons.selectThis": "Välj detta",
    "buttons.tryAgain": "Försök igen",
    "category.ui.hideCategories": "Dölj underkategorier",
    "category.ui.oneLevelUp": "Tillbaka till den överordnade kategorin",
    "category.ui.showCategories": "Visa underkategorier",
    "categoryFields.attachmentWidth": "Genomföra bredd",
    "categoryFields.clutch": "Koppling",
    "categoryFields.companyName": "Företagets namn",
    "categoryFields.dealerInfo": "Information om återförsäljare",
    "categoryFields.diameter": "Diameter",
    "categoryFields.displayNetPrice": "Nettopris",
    "categoryFields.electricalPower": "Elektrisk kraft",
    "categoryFields.engine": "Motor",
    "categoryFields.engineDescription": "Motorutrustning",
    "categoryFields.ERPname": "Namn inom varuförvaltning",
    "categoryFields.horsePower": "Effekt",
    "categoryFields.netWidth": "Spårvidd utan däck",
    "categoryFields.packingUnit": "Förpackningsenhet",
    "categoryFields.PTO": "PTO",
    "categoryFields.relativeGauge": "Breddar spåret med",
    "categoryFields.vendorAcronym": "Kortnamn",
    "categoryFields.volume": "Volym",
    "categoryFields.weight": "Vikt",
    "categoryFields.wheelLock": "Stativhjulslås",
    "checkout.cart.title": "Min kundvagn",
    "checkout.cart.item": "Artikel",
    "checkout.cart.amount": "Antal",
    "checkout.cart.edit": "Ändra",
    "checkout.cart.price": "Pris",
    "checkout.cart.singlePrice": "Pris per enhet",
    "checkout.cart.product.isNonPhysical": "Produkten kommer inte att skickas (kan finnas tillgänglig för nedladdning).",
    "checkout.cart.product.requiresExpressDelivery": "Ny produkt. Måste skickas EXPRESS.",
    "checkout.cart.product.deliveryProblem": "Den valda produkten kan vara föremål för leveransförseningar.",
    "checkout.cart.subtotal": "Delsumma",
    "checkout.cart.deliveryCosts": "Försändelsekostnader",
    "checkout.cart.total": "Totalt",
    "checkout.cart.help": "Har du frågor om beställningsprocessen? Vi har samlat viktig information för dig.",
    "checkout.cart.subtotalInfo": "inkl. moms",
    "checkout.cart.subtotalShipment": "plus avvikande fraktkostnader",
    "checkout.cart.checkout": "Till kassan",
    "checkout.cart.checkoutWithoutUser": "Kassan utan kundkonto",
    "checkout.cart.checkoutFast": "Snabb utcheckning",
    "checkout.cart.deliveryInfo": "Beräknat leveransdatum för varorna i kundvagnen",
    "checkout.cart.orderSuccess": "Din beställning har lyckats.",
    "checkout.cart.orderCommitting": "Din beställning håller på att bearbetas - vänligen vänta",
    "checkout.cart.myOrder": "Min beställning",
    "checkout.checkout.orderNumber": "Beställningsnummer",
    "checkout.checkout.account": "Kundkonto",
    "checkout.checkout.andOrder": "och beställa",
    "checkout.checkout.delivery": "Leverans och leveransadress",
    "checkout.checkout.delivery.type": "Avsändningen sker med hjälp av",
    "checkout.checkout.delivery.street": "Adressrad 1",
    "checkout.checkout.delivery.houseNumber": "Nej.",
    "checkout.checkout.delivery.co": "Suffix för adress (c/o)",
    "checkout.checkout.delivery.city": "Staden",
    "checkout.checkout.delivery.selectCountry": "Vänligen välj land",
    "checkout.checkout.delivery.addressBoth": "Faktura och leveransadress",
    "checkout.checkout.delivery.addressInvoice": "Fakturans adress",
    "checkout.checkout.delivery.addressDelivery": "Leveransadress",
    "checkout.checkout.delivery.default": "Ange den här adressen som min standardleveransadress",
    "checkout.checkout.delivery.otherAddress": "Leveransadressen skiljer sig från faktureringsadressen.",
    "checkout.checkout.delivery.dhlNr": "Ditt DHL Post-nummer",
    "checkout.checkout.delivery.packingStation": "Nummer på förpackningsstationen",
    "checkout.checkout.delivery.showPackingStations": "Visa packstationer i närheten av min adress",
    "checkout.checkout.delivery.wantExpressShipping": "Expressleverans",
    "checkout.checkout.delivery.isPickUp": "insamling på plats",
    "checkout.checkout.delivery.specialCountryInfo": "Ditt land kan inte väljas?",
    "checkout.checkout.delivery.specialCountry": "Ditt land ligger utanför vår standardleverans",
    "checkout.checkout.delivery.specialCountryMessage": "Enskilda produkter i din varukorg kan inte skickas till alla länder. Ta bort vissa produkter från din beställning tills ditt land kan väljas eller kontakta oss.",
    "checkout.checkout.payment": "Betalningsmetod",
    "checkout.checkout.paymentProblem": "Ett fel inträffade när betalningen utfördes",
    "checkout.checkout.backToPayment": "Tillbaka till betalningsinställningarna.",
    "checkout.checkout.paymentTitle": "Hur tänker du betala?",
    "checkout.checkout.paymentSelected": "Vald betalningsmetod",
    "checkout.checkout.payment.cc": "Kreditkort",
    "checkout.checkout.payment.ccInfo": "För kortbetalningar inom EU måste din identitet bekräftas varje gång du betalar online. Efter beställningen kommer du att guidas genom bekräftelsen - som tillhandahålls av din bank.",
    "checkout.checkout.payment.complimentary": "gratis",
    "checkout.checkout.payment.paypal": "PayPal",
    "checkout.checkout.payment.paypalInfo": "Du kommer att omdirigeras till PayPal för att slutföra betalningen. Ditt PayPal-konto debiteras inte förrän du bekräftar beställningen i nästa steg.",
    "checkout.checkout.payment.sepa": "Direktdebitering (SEPA)",
    "checkout.checkout.payment.sepaLegal": "Jag godkänner {{siteOwner}} {{siteOwnerAddress}} (Kreditor ID: {{siteOwnerCreditorID}}) att ta ut betalningar från mitt konto genom autogiro. Samtidigt ger jag mitt kreditinstitut instruktioner om att godkänna autogireringarna. {{siteOwner}} till mitt konto.",
    "checkout.checkout.payment.noSepa": "Din fakturerings- eller leveransadress ligger utanför EU. Därför kan vi inte erbjuda dig betalning via autogiro.",
    "checkout.checkout.payment.sepaInfo": "Snabbt och okomplicerat. När du slutför din beställning (nästa steg) kommer du att bli ombedd att ange dina bankuppgifter (IBAN). Var vänlig och ha dem redo. När överföringen är klar får du en bekräftelse.",
    "checkout.checkout.payment.prepayment": "Förskottsbetalning (banköverföring)",
    "checkout.checkout.payment.prepaymentInfo": "När du har gjort din beställning skickar vi dig information om banköverföringen via e-post. Vi kan reservera artiklarna i din beställning i högst 7 dagar. Vänligen håll dig till detta tidsfönster för banköverföringen. Så snart vi har mottagit betalningen skickas beställningen.",
    "checkout.checkout.payment.invoice": "Faktura",
    "checkout.checkout.payment.invoiceInfo": "Fakturabeloppet ska betalas på det betalningsdatum som anges i e-postmeddelandet om leveransbekräftelse.",
    "checkout.checkout.payment.noInvoice": "Tyvärr kan vi inte erbjuda betalning med faktura för den här beställningen. Välj en av de andra betalningsmetoderna.",
    "checkout.checkout.payment.sofort": "Direktöverföring",
    "checkout.checkout.payment.sofortInfo": "När du slutför din beställning (nästa steg) kommer du att bli ombedd att ange dina bankuppgifter (IBAN). Ha dem redo. När överföringen är klar får du en bekräftelse.",
    "checkout.checkout.payment.accountName": "Kontoinnehavare",
    "checkout.checkout.payment.accountEmail": "E-postadress för bekräftelse",
    "checkout.checkout.sepaAndOrder": "Fullmakt för autogiro för {{totalAmount}} € bekräfta och beställa med kostnader",
    "checkout.checkout.sofortAndOrder": "Överföringsmandat för {{totalAmount}} € bekräfta och beställa med kostnader",
    "checkout.checkout.title": "Kassan",
    "checkout.checkout.next": "Läs mer",
    "checkout.checkout.order": "Köp nu",
    "checkout.checkout.orderConsentPre": "Med beställningen förklarar ni er med våra",
    "checkout.checkout.orderConsentPost": "överens.",
    "checkout.checkout.noAccount": "Skapa inget kundkonto",
    "checkout.redirect.countryPriceChange": "Priserna är olika i ditt land, observera prisändringen.",
    "comments.blockComments": "Kommentarerna till det här inlägget har avslutats.",
    "comments.comment.anonymous": "Anonymt",
    "comments.comment.button": "Skriv en ny kommentar",
    "comments.comment.buttonReply": "Svara",
    "comments.comment.cancel": "Avbryt",
    "comments.comment.content": "Din kommentar",
    "comments.comment.hasCommented": "Kommentarer",
    "comments.comment.login": "Logga in för att skriva en kommentar",
    "comments.comment.past": "har",
    "comments.comment.replyFrom": "Svar på",
    "comments.comment.showReplies": "Visa svaren",
    "comments.comment.showReply": "Visa svaret",
    "comments.comment.submit": "Skicka in",
    "comments.comment.title": "Titel på kommentaren",
    "comments.comment.userName": "ditt användarnamn",
    "comments.heading": "Förslag, anteckningar, kommentarer",
    "comments.hierarchy.flat": "Platt",
    "comments.hierarchy.nested": "Genested",
    "comments.hierarchy.title": "Kommentarer:",
    "comments.message.afterSaveError": "Din kommentar kan inte sparas för tillfället!",
    "comments.message.afterSaveSuccess": "Din kommentar har sparats.",
    "comments.message.afterSaveUnlock": "Din kommentar har sparats, men måste fortfarande granskas av en administratör.",
    "comments.noComments": "Inga kommentarer har ännu lämnats in",
    "comments.reply": "AN:",
    "comments.show": "Visa kommentarer",
    "comments.title": "Kommentarer",
    "entries.edit": "Inträde <strong>{{title}}</strong> redigera",
    "entries.entries": "Anmälningar",
    "entries.entry.fields.address.city": "Staden",
    "entries.entry.fields.address.country": "Land",
    "entries.entry.fields.address.houseNumber": "Husnummer",
    "entries.entry.fields.address.street": "Street",
    "entries.entry.fields.address.zip": "POSTKOD",
    "entries.entry.fields.language": "Språk",
    "entries.entry.fields.prefixMissing": "Följande fält är inte ifyllda:",
    "entries.entry.profile.edit": "Redigera profil",
    "entries.entry.profile.publicProfile": "till min offentliga profilsida",
    "entries.entry.tooltip.alreadyCopied": "Du drömmer redan om det här inlägget.",
    "entries.entry.tooltip.alreadyFollow": "Du följer redan detta inlägg",
    "entries.entry.tooltip.alreadyLiked": "Du gillar redan det här inlägget",
    "entries.entry.tooltip.copied": "Jag är glad att du också vill drömma om detta inlägg. Din profilbild har lagts till i det här inlägget.",
    "entries.entry.tooltip.filterComments": "Klicka här för att visa endast kommentarer i Entryline",
    "entries.entry.tooltip.filterFollows": "Klicka här för att visa endast Follows i Entryline",
    "entries.entry.tooltip.filterLikes": "Klicka här för att visa endast likes i startfältet",
    "entries.entry.tooltip.filterShares": "Klicka här för att visa endast aktier i entryline",
    "entries.entry.tooltip.follow": "Klicka här för att följa detta inlägg",
    "entries.entry.tooltip.like": "Klicka här för att ge det här inlägget en like",
    "entries.entry.tooltip.noFollow": "Follow fungerar endast för utländska poster",
    "entries.entry.tooltip.noFollowNotLoggedIn": "Du måste vara inloggad för att följa detta inlägg.",
    "entries.entry.tooltip.noLike": "Liksom fungerar bara för andras poster",
    "entries.entry.ui.access": "Endast följande personer kan se detta inlägg",
    "entries.entry.ui.added": "...har lagts till.",
    "entries.entry.ui.addEntry": "Lägg till poster",
    "entries.entry.ui.addOpening": "Lägg till öppningstid",
    "entries.entry.ui.address": "Adressuppgifter",
    "entries.entry.ui.addStep": "Lägg till ett steg",
    "entries.entry.ui.agreementHours": "Öppet enligt överenskommelse",
    "entries.entry.ui.allAccess": "Alla kan se detta inlägg",
    "entries.entry.ui.assignedCategories": "Tilldelade kategorier.",
    "entries.entry.ui.assignedCertificates": "Mina certifieringar",
    "entries.entry.ui.assignedProducts": "tilldelade produkter",
    "entries.entry.ui.businessContact": "Kontaktuppgifter till företaget",
    "entries.entry.ui.categories": "Kategorier för denna post",
    "entries.entry.ui.categoryFields": "Ytterligare information",
    "entries.entry.ui.commercialTitle": "Information om företaget i posten",
    "entries.entry.ui.companyTitle": "Uppgifter om mitt företag",
    "entries.entry.ui.contactPerson": "Mina kontaktuppgifter",
    "entries.entry.ui.cssClass": "CSS-klass för innehållet",
    "entries.entry.ui.deliveryInfo": "Information om leveranstjänsten",
    "entries.entry.ui.descriptionInfo": "Tips: När ord är markerade kan stilar tilldelas.",
    "entries.entry.ui.descriptionPlaceholder": "Beskrivning med HTML. Dubbelklicka på texten för HTML-verktygsfältet (högst 600 tecken).",
    "entries.entry.ui.editEntry": "Redigera posten",
    "entries.entry.ui.editEntryNew": "Skapa en post",
    "entries.entry.ui.exclusionHours": "Uteslutningstider",
    "entries.entry.ui.galleryImages": "Bildgalleri (2 eller 3 bilder beroende på arrangemang)",
    "entries.entry.ui.globalTitle": "Namn",
    "entries.entry.ui.hasKiosk": "Jag har ett bås",
    "entries.entry.ui.imagesHorizontal": "Horisontell",
    "entries.entry.ui.imagesInfo": "Bilder kan redigeras under \"Bilder/Video/Dokument\".",
    "entries.entry.ui.imagesOrientation": "Anpassning av bilder",
    "entries.entry.ui.imagesSquared": "Kvadrat",
    "entries.entry.ui.imagesVertical": "Vertikal",
    "entries.entry.ui.isListImage": "Den här bilden är listbilden",
    "entries.entry.ui.isMainImage": "Denna bild är huvudbilden",
    "entries.entry.ui.isMainImageAndListImage": "Denna bild visas som huvudbild i posten.",
    "entries.entry.ui.kioskDescription": "Beskrivning av din monter",
    "entries.entry.ui.listImage": "Skärm med lista",
    "entries.entry.ui.logo": "Logotyp",
    "entries.entry.ui.longDescription": "Lång beskrivningstext",
    "entries.entry.ui.mainImage": "Huvudbild",
    "entries.entry.ui.makeListImage": "Ange den här bilden som listbild",
    "entries.entry.ui.makeListImageTooltip": "Sökresultaten visas som en lista med listbilden. Om ingen listbild har valts används huvudbilden.",
    "entries.entry.ui.makeMainImage": "Ange den här bilden som huvudbild",
    "entries.entry.ui.makeMainImageTooltip": "Huvudbilden visas högst upp i profilen. Bilder som inte har valts som huvud- eller listbild visas i bildgalleriet.",
    "entries.entry.ui.metaDescription": "Kortfattad beskrivning",
    "entries.entry.ui.metaDescriptionPlaceholder": "Kort beskrivning med högst 200 tecken",
    "entries.entry.ui.multiCode": "Ytterligare kod för detta steg",
    "entries.entry.ui.multiDescription": "Beskrivning av detta steg",
    "entries.entry.ui.multiImages": "Bilder för detta steg",
    "entries.entry.ui.multiProgress": "Framsteg",
    "entries.entry.ui.multiTitle": "Titel för detta steg",
    "entries.entry.ui.multiTitlePlaceholder": "Titel för detta steg (högst 50 tecken)",
    "entries.entry.ui.noDescriptions": "Det finns ännu ingen beskrivningstext för posten.",
    "entries.entry.ui.noEntries": "Inga poster hittades.",
    "entries.entry.ui.onlyVisibleForCustomers": "Denna post är endast synlig för registrerade kunder",
    "entries.entry.ui.openAt": "Öppna",
    "entries.entry.ui.openingHours": "Öppettider",
    "entries.entry.ui.openingInfo": "Anmärkningar om öppettider",
    "entries.entry.ui.openingReligious": "Adress i en övervägande katolsk församling",
    "entries.entry.ui.overallProgress": "Denna post är {{value}}% förverkligad",
    "entries.entry.ui.parking": "Parkering",
    "entries.entry.ui.parkingLots": "Antal",
    "entries.entry.ui.parkingType": "Konst",
    "entries.entry.ui.personImage": "Bild på kontaktpersonen",
    "entries.entry.ui.relatedEvents": "Relaterade evenemang",
    "entries.entry.ui.relatedJobs": "Relaterade jobb",
    "entries.entry.ui.relatedArticles": "Relaterade artiklar",
    "entries.entry.ui.relatedCampaigns": "Relaterade kampanjer",
    "entries.entry.ui.rentKiosk": "Jag skulle vilja hyra en monter",
    "entries.entry.ui.resolveAddress": "Bestämma koordinater",
    "entries.entry.ui.resolveAddressInfo": "Bestäm geopunkten (positionen på kartan) utifrån din adress. Utan rätt geopunkt kanske de inte hittas korrekt.",
    "entries.entry.ui.resolveGeolocation": "Hämta adress",
    "entries.entry.ui.routingPlaceholder": "Ange dina anvisningar med HTML här.",
    "entries.entry.ui.selectEvent": "Välj evenemang",
    "entries.entry.ui.selectJob": "Välj jobb",
    "entries.entry.ui.selectArticle": "Välj artikel",
    "entries.entry.ui.selectCampaign": "Välj kampanj",
    "entries.entry.ui.shortDescriptionInfo": "Är samtidigt den korta beskrivningen",
    "entries.entry.ui.sharing": "Dela den här sidan på {{sharing}}",
    "entries.entry.ui.shareMail": "Dela den här sidan via e-post",
    "entries.entry.ui.skillsNeeded": "För mitt bidrag skulle jag verkligen kunna använda....",
    "entries.entry.ui.sortword": "Alternativa titlar för sortering",
    "entries.entry.ui.step": "Steg",
    "entries.entry.ui.stepInDream": "I vilken fas av inmatningen ingår detta steg?",
    "entries.entry.ui.stepInDreamAfter": "efter förverkligandet",
    "entries.entry.ui.stepInDreamBefore": "före förverkligandet",
    "entries.entry.ui.stepInDreamDuring": "Under genomförandet.",
    "entries.entry.ui.subtitle": "Beskrivande undertitel",
    "entries.entry.ui.subtitlePlaceholder": "Ange undertitel (högst 60 tecken)",
    "entries.entry.ui.tabCategories": "Kategorier",
    "entries.entry.ui.tabCategoriesMobile": "Cat.",
    "entries.entry.ui.tabCompany": "Uppgifter om företaget",
    "entries.entry.ui.tabDescription": "Beskrivning",
    "entries.entry.ui.tabDescriptionMobile": "Beschr.",
    "entries.entry.ui.tabExhibitor": "Uppgifter om utställare",
    "entries.entry.ui.tabImages": "Bilder/video/dokument",
    "entries.entry.ui.tabImagesMobile": "Bilder/Video/Filer",
    "entries.entry.ui.tabRelations": "Anslutningar",
    "entries.entry.ui.tags": "Taggar",
    "entries.entry.ui.title": "Posten visas under följande namn",
    "entries.entry.ui.titleError": "Posten måste namnges innan den sparas.",
    "entries.entry.ui.titlePlaceholder": "Ange titel (högst 50 tecken)",
    "entries.entry.ui.visible": "Denna post är synlig",
    "entries.entry.ui.promotion": "Deltagande i åtgärder för denna post",
    "entries.entry.ui.regions": "Regionskategorier för denna post",
    "entries.job.ui.promotion": "Deltagande i åtgärder för detta jobb",
    "entries.event.edit": "Evenemang <strong>{{title}}</strong> redigera",
    "entries.event.ui.addEvent": "Lägg till händelse",
    "entries.event.ui.cooperation": "Samarbetspartner",
    "entries.event.ui.directions": "Vägbeskrivning",
    "entries.event.ui.editEvent": "Redigera händelse",
    "entries.event.ui.editEventNew": "Skapa ett evenemang",
    "entries.event.ui.endDate": "Slutet av evenemanget (dag)",
    "entries.event.ui.eventDone": "Rapport om mässan",
    "entries.event.ui.noEvents": "Inga evenemang hittades",
    "entries.event.ui.openEvent": "Uppringning av händelse",
    "entries.event.ui.opening": "Öppettider",
    "entries.event.ui.startDate": "Evenemangets början (dag)",
    "entries.event.ui.tabCategories": "Kategorier",
    "entries.event.ui.tabDescription": "Beskrivning",
    "entries.event.ui.tabOpening": "Datum och tider",
    "entries.event.ui.tabRelations": "Anslutningar",
    "entries.event.ui.title": "Evenemanget visas under följande namn",
    "entries.event.ui.upcoming": "I ett nötskal",
    "entries.open": "Anrop i frontend",
    "entries.person.ui.company": "Företag",
    "entries.person.ui.female": "Ms.",
    "entries.person.ui.diverse": "Utan",
    "entries.person.ui.fullName": "För- och efternamn",
    "entries.person.ui.fullSize": "Fullstorlek",
    "entries.person.ui.gender": "Genus",
    "entries.person.ui.greeting": "Hälsning",
    "entries.person.ui.male": "Herr.",
    "entries.person.ui.open": "Direkt till denna persons post",
    "entries.person.ui.personAddress": "Personens adress",
    "entries.person.ui.personContact": "Personliga kontaktuppgifter",
    "entries.person.ui.personImage": "Personlig bild",
    "entries.person.ui.position": "Position",
    "entries.person.ui.quote": "Citat/rubrik",
    "entries.person.ui.signature": "Signaturbild",
    "entries.poi.ui.poiEvents": "Evenemang på denna plats",
    "entries.poi.ui.title": "Platsen förekommer under följande namn",
    "entries.product.edit": "Produkt <strong>{{title}}</strong> redigera",
    "entries.product.fields.engine": "Motor",
    "entries.product.fields.horsePower": "Effekt",
    "entries.product.menu.allAttachments": "Alla bilagor i översikten",
    "entries.product.menu.allCarriers": "Alla transportfordon i översikten",
    "entries.product.menu.attachments": "Bilagor",
    "entries.product.menu.carriers": "Bärande fordon",
    "entries.product.tags.eco": "eko",
    "entries.product.tags.neu": "Ny",
    "product.ui.content": "Innehåll",
    "product.ui.ingredients": "Beståndsdelar",
    "product.ui.ingredientsFood": "Ingredienser",
    "product.ui.nonPhysical": "Ladda ner den elektroniska produkten",
    "entries.product.ui.addProduct": "Lägg till en produkt",
    "entries.product.ui.choose": "Välj",
    "entries.product.ui.discount": "Rabatt",
    "entries.product.ui.discountSelect": "% rabattfördel",
    "entries.product.ui.equipmentVariants": "Utrustningsvarianter",
    "entries.product.ui.variants": "Varianter",
    "entries.product.ui.gauge": "Bredd i cm",
    "entries.product.ui.gaugeMin": "Minsta spårbredd i cm",
    "entries.product.ui.inclusive": "Inkluderad",
    "entries.product.ui.live": "{{name}} uppleva live",
    "entries.product.ui.netPrize": "inkluderande {{tax}}% mervärdesskatt, plus",
    "entries.product.ui.netPrizeDefault": "inklusive mervärdesskatt, plus",
    "entries.product.ui.deliveryTerms": "Försändelsekostnader",
    "entries.product.ui.noProducts": "Inga produkter hittades",
    "entries.product.ui.noPurchaseOnThisPage": "Kan inte beställas på den här sidan, ring produkten för att göra det.",
    "entries.product.ui.onlyBasicVariant": "Endast i grundutrustningen",
    "entries.product.ui.onlyWithCarrier": "Endast tillgänglig vid köp av en basenhet",
    "entries.product.ui.order": "Lägg till i kundvagnen",
    "entries.product.ui.notOrderable": "Välj en variant.",
    "entries.product.ui.overview": "Översikt",
    "entries.product.ui.prizeDecimalPoint": ",",
    "entries.product.ui.prizeFrom": "från",
    "entries.product.ui.item": "Stycke",
    "entries.product.ui.prizePerItem": "Pris per styck",
    "entries.product.ui.noPrize": "Vänligen fråga din kontaktperson om priset.",
    "entries.product.ui.size": "Storlek",
    "entries.product.ui.required": "Endast med",
    "entries.product.ui.suitableFor": "Lämplig för",
    "entries.product.ui.suitableForAttachments": "Lämplig för följande redskap",
    "entries.product.ui.suitableForCarriers": "Lämplig för följande transportfordon",
    "entries.product.ui.title": "Produkt",
    "entries.product.ui.variant": "Variant/Bundel",
    "entries.product.ui.variantNotOrderable": "Den valda varianten kan inte beställas",
    "entries.product.ui.bundleparts": "som består av",
    "entries.product.ui.chooseVariant": "Välj variant",
    "entries.product.ui.chooseVariantMobile": "Välj variant",
    "entries.product.ui.combinationNotAvailable": "Kombination inte tillgänglig",
    "entries.progress.title": "Hur du kan förbättra ditt bidrag",
    "entries.tabbar.basics": "Grundläggande uppgifter",
    "entries.tabbar.entry": "Inmatningsuppgifter",
    "entries.tabbar.gallery": "Bilder",
    "entries.tabbar.layout": "Layout",
    "entries.tabbar.openingHours": "Öppettider",
    "entries.tabbar.order": "Beställningsinformation",
    "entries.tabbar.tooltip.entryNotSavedDetail": "Inmatningsdata kan endast redigeras efter att inmatningen med titel har sparats.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "Layoutdata kan endast redigeras efter att posten har sparats med en titel.",
    "entries.types.poi": "Poi",
    "entries.types.standard": "Standard",
    "entries.ui.groupDetailName": "Namn för gruppposter",
    "entries.ui.groupName": "Namn för grupperna (region)",
    "entryTypes.entry": "Inträde",
    "entryTypes.event": "Evenemang",
    "entryTypes.poi": "Plats",
    "errors.blank": "{{description}} måste fyllas i.",
    "errors.description": "Detta fält",
    "errors.email": "{{description}} måste vara en giltig e-postadress",
    "errors.empty": "{{description}} får inte vara tom.",
    "errors.exclusion": "{{description}} är redan upptagen.",
    "errors.inclusion": "{{description}} ingår inte i urvalet.",
    "errors.invalid": "{{description}} är ogiltig",
    "errors.mandatory": "{{field}} måste fyllas i.",
    "errors.mandatoryCheckbox": "Måste bekräftas.",
    "errors.mandatorySubmit": "Du kan skicka in formuläret först när alla obligatoriska fält har fyllts i.",
    "errors.slug": "Slug får endast innehålla små bokstäver, siffror och \"-\".",
    "forms.ui.deleteFile": "Ta bort filen",
    "forms.ui.formErr": "Fel vid sändning av formuläret",
    "forms.ui.formSent": "Formulär skickat",
    "forms.ui.noForm": "Inget formulär tilldelat",
    "forms.ui.invalidMail": "Ange en giltig e-postadress.",
    "forms.ui.isSubmitted": "Formuläret har skickats. Vi kontaktar dig så snart som möjligt.",
    "forms.ui.missingFields": "Följande fält måste fyllas i:",
    "forms.ui.uploadSuccess": "{{file}} har laddats upp.",
    "forms.ui.fieldBuilder.street": "Street",
    "forms.ui.fieldBuilder.houseNumber": "Nej.",
    "forms.ui.fieldBuilder.zip": "POSTKOD",
    "forms.ui.fieldBuilder.city": "Staden",
    "forms.ui.fieldBuilder.firstName": "Förnamn",
    "forms.ui.fieldBuilder.lastName": "Efternamn",
    "forms.ui.privacyRead": "Jag har tagit del av sekretesspolicyn",
    "jobs.ui.employmentType.FULL_TIME": "Heltid",
    "jobs.ui.employmentType.APPRENTICE": "Utbildning",
    "jobs.ui.location": "Jobb tillgängligt på",
    "jobs.ui.role.male": "M",
    "jobs.ui.role.female": "W",
    "jobs.ui.role.divers": "D",
    "jobs.ui.startDateNow": "Från och med nu",
    "jobs.ui.startFrom": "Från",
    "media.documents.search.noResult": "Ange ett serienummer eller välj en kategori.",
    "media.documents.search.placeholder": "Sök efter enhetens serienummer eller typnummer",
    "media.documents.search.reset": "Återställ sökresultatet",
    "media.ui.copyright": "Upphovsrätt",
    "media.ui.cropFormat": "Specifikation av format",
    "media.ui.cropImage": "Trim",
    "media.ui.cropImageDuplicate": "Duplicera och beskära",
    "media.ui.description": "Beskrivning",
    "media.ui.filename": "Filnamn",
    "media.ui.imageSaved": "Bild sparad",
    "media.ui.processingImage": "Bilden beskärs, vänligen vänta",
    "media.ui.resetCrop": "Återställ skärning",
    "media.ui.resetImage": "Tillbaka till originalet",
    "media.ui.rotateImage": "Rotera bilden 90° medurs",
    "media.ui.title": "Titel",
    "name": "Namn",
    "openingHours.additionalInfo": "Information om öppettider",
    "openingHours.addOpening": "Lägg till öppningstid",
    "openingHours.date.day.di": "Tis",
    "openingHours.date.day.do": "Gör",
    "openingHours.date.day.fr": "fre",
    "openingHours.date.day.mi": "Mi",
    "openingHours.date.day.mo": "Måndag",
    "openingHours.date.day.sa": "Lördagen",
    "openingHours.date.day.so": "Så",
    "openingHours.dateS": "Datum",
    "openingHours.delete": "Ta bort",
    "openingHours.from": "Från",
    "openingHours.options": "Område",
    "openingHours.time": "Tid",
    "openingHours.to": "Genom",
    "openingHours.type": "Typ av öppettider",
    "opt-in.error-can-not-update-member": "Ändringarna i medlemsuppgifterna för {{email}} inte kunde genomföras.",
    "opt-in.error-list-does-not-exist": "Ingen lista hittades för de angivna datumen.",
    "opt-in.error-list-does-not-need-validation": "Den angivna listan har ingen opt-int.",
    "opt-in.error-member-not-in-list": "Ingen medlem i en lista hittades för de angivna datumen.",
    "opt-in.success-member-verified": "{{email}} lades till i {{listname}} Tillagd till listan.",
    "opt-out.error-can-not-update-member": "Ändringarna i medlemsuppgifterna för {{email}} inte kunde genomföras.",
    "opt-out.error-list-does-not-exist": "Ingen lista hittades för de angivna datumen.",
    "opt-out.error-list-does-not-have-opt-out": "Den angivna listan har ingen opt-out-möjlighet.",
    "opt-out.error-member-not-in-list": "Ingen medlem i en lista hittades för de angivna datumen.",
    "opt-out.success-membership-terminated": "{{email}} togs bort från {{listname}} avlägsnas.",
    "password.errors.not-secure-enough": "Vi rekommenderar ett lösenord med minst 8 tecken, med stora och små bokstäver, siffror och specialtecken ($@$!%*#?&amp;).",
    "password.errors.password-and-conf-do-not-match": "De två lösenorden måste vara identiska.",
    "password.quality.0": "Mycket dåligt",
    "password.quality.1": "Dålig",
    "password.quality.2": "Tillräckligt",
    "password.quality.3": "Bra",
    "password.quality.4": "Mycket bra",
    "profile.entries.copycats": "Anteckningar som {{userName}} vill också göra",
    "profile.entries.donates": "Anteckningar som {{userName}} stöder",
    "profile.entries.entries": "Inlägg från {{userName}}",
    "profile.entries.follows": "Poster som {{userName}} följer",
    "profile.entries.joins": "Poster där {{userName}} deltar",
    "profile.marketplace.fields.energyRequired": "efterfrågan på el.",
    "profile.marketplace.fields.livingAnimals": "Inhämtning av levande djur",
    "profile.marketplace.fields.loadingUnloading": "Nödvändigt för ankomst/avresa",
    "profile.marketplace.fields.maxRange": "För mitt erbjudande har jag redan så många kilometer att köra.",
    "profile.marketplace.fields.placeRequired": "Krav för planen",
    "profile.marketplace.fields.securityRequired": "Behov av säkerhet",
    "profile.marketplace.fields.shows": "Programutbud",
    "profile.marketplace.fields.spaceRequired": "Utrymmesbehov i m²",
    "profile.marketplace.fields.waterRequired": "Behov av vatten i m³",
    "profile.marketplace.new": "Nytt utbud eller ny efterfrågan",
    "profile.marketplace.offer.delete": "Ta bort",
    "profile.marketplace.offer.description": "Beskrivning",
    "profile.marketplace.offer.descriptionPlaceholder": "En meningsfull beskrivning så att en annan användare kan göra något med den.",
    "profile.marketplace.offer.new": "Skapa ett nytt erbjudande",
    "profile.marketplace.offer.relatedEntry": "Tilldelad post",
    "profile.marketplace.offer.tags": "Taggar för erbjudande/begäran (kommer att hittas bättre)",
    "profile.marketplace.offer.title": "Jag erbjuder",
    "profile.marketplace.request.delete": "Ta bort begäran",
    "profile.marketplace.request.new": "Skapa en ny begäran",
    "profile.marketplace.request.missingCredentials": "Du kan bara starta en sökning när du har skapat minst en plats och en mätpunkt.",
    "profile.marketplace.request.title": "Jag behöver",
    "profile.messages.approve": "Bekräfta",
    "profile.messages.as": "som",
    "profile.messages.commentPre": "har",
    "profile.messages.commentSuff": "Kommentarer",
    "profile.messages.decided": "beslutade att göra det.",
    "profile.messages.deletedUser": "Borttagen användare",
    "profile.messages.donateDream": "Tack så mycket för att ni hjälpte den här deltagaren med råd och stöd för att förverkliga sitt bidrag. Han kommer att få ditt meddelande omedelbart och kommer förhoppningsvis att återkomma till dig och acceptera din hjälp.",
    "profile.messages.dreamer": "Enda bärare",
    "profile.messages.dreamToo": "också att drömma",
    "profile.messages.enter": "Gått med i",
    "profile.messages.favoriteDream": "Du följer detta inlägg nu.",
    "profile.messages.follow": "Sync och korrigeringar av n17t01",
    "profile.messages.has": "har inträffat",
    "profile.messages.is": "Sync och korrigeringar av n17t01",
    "profile.messages.join": "JOIN",
    "profile.messages.joinDream": "Du vill drömma detta inlägg med dig.",
    "profile.messages.likeDream": "Du gillar det här inlägget nu.",
    "profile.messages.sendText": "Ditt meddelande skickades.",
    "profile.messages.shareDream": "Du har lyckats dela detta inlägg.",
    "profile.messages.supporter": "Supportrar",
    "profile.messages.want": "skulle vilja",
    "profile.messages.youAre": "Du är",
    "profile.messages.youWant": "Du vill",
    "profile.newsletter.notSubscribed": "prenumerera",
    "profile.newsletter.notSubscribedAny": "Du har ännu inte prenumererat på en nyhetsbrevslista.",
    "profile.newsletter.privacyInformation": "Genom att klicka på \"prenumerera\" godkänner du att vi skickar dig framtida nyhetsbrev om denna prenumeration (opt-in). Du kan när som helst avregistrera dig från nyhetsbrevet genom att klicka på \"prenumerera\" igen (opt-out).",
    "profile.newsletter.subscribed": "prenumererade",
    "profile.press.articleDetail": "Detaljerad artikel",
    "profile.press.downloadImage": "Ladda ner bilden",
    "profile.press.downloadImages": "Ladda ner bilder",
    "profile.press.downloadText": "Exportera text",
    "profile.press.filterAuthor": "Filtrera efter författare",
    "profile.press.hide": "Tona ut",
    "profile.press.show": "tonas in",
    "profile.progress.title": "Hur du förbättrar din profil",
    "register.ui.addressData": "Adressuppgifter",
    "register.ui.addressInfo": "Du behöver inte ange din personliga adress. Detta leder dock till korrekta väg- och avståndsberäkningar.",
    "register.ui.companyData": "uppgifter om ditt företag",
    "register.ui.companyDataMandatory": "Du måste ange ditt företagsnamn.",
    "register.ui.emptyFirstName": "Du måste ange ditt förnamn.",
    "register.ui.emptyLastName": "Du måste ange ditt efternamn.",
    "register.ui.emptyMail": "Du måste ange en giltig e-postadress.",
    "register.ui.emptyUserName": "Du måste ange ett användarnamn",
    "register.ui.language": "Språk",
    "register.ui.loginData": "Inloggningsuppgifter",
    "register.ui.password": "Lösenord",
    "register.ui.passwordRepeat": "Upprepa lösenordet",
    "register.ui.personalData": "Dina personuppgifter",
    "register.ui.privacyStatement": "Jag godkänner att mina personuppgifter lagras. Mina personuppgifter kommer inte att lämnas vidare till tredje part. Jag kan när som helst återkalla detta samtycke till dataskydd med verkan för framtiden.",
    "register.ui.takenEmail": "Det här e-postmeddelandet har redan angetts av en användare.",
    "register.ui.takenUserName": "Detta användarnamn är redan upptaget",
    "register.ui.timezone": "Tidszon",
    "register.ui.typeMinimum": "Du måste välja minst en typ.",
    "register.ui.usernameInfo": "Viktigt: Detta är ditt användarnamn för inloggningen.",
    "register.ui.oauthInfo": "Du kommer att omdirigeras till FRM-inloggningssidan för att logga in med dina användaruppgifter och sedan återvända till webbplatsen.",
    "search.allDistances": "Alla avstånd",
    "search.andFilter": "och filterinställningar",
    "search.closeTags": "Dölj taggar",
    "search.for": "till",
    "search.resetPosition": "Återställ position",
    "search.geoPositionBrowser": "Bestäm positionen med hjälp av webbläsaren",
    "search.geoPositionFault": "Det är inte möjligt att bestämma positionen med hjälp av webbläsaren",
    "search.geoPositionTitle": "Ange positionen för en närhetssökning",
    "search.selectedFilters": "Valda filter",
    "search.selectFilters": "Filtrera sökningen efter kategori",
    "search.hits": "Hit",
    "search.openTags": "Visa taggar",
    "search.removeDatefilter": "Ta bort filtret datum",
    "search.resulted": "avslöjade",
    "search.resultType.category": "Kategori",
    "search.resultType.entry": "Studio",
    "search.resultType.event": "Evenemang",
    "search.resultType.group": "Grupp",
    "search.resultType.page": "Sidan",
    "search.resultType.person": "Person",
    "search.resultType.post": "Inlägg",
    "search.resultType.product": "Produkt",
    "search.resultType.job": "Jobb",
    "search.resultType.recipe": "Recept",
    "search.search": "Sök på",
    "search.searchBarPlaceholder": "Sök...",
    "search.searchBarPlaceholderEntries": "Sök efter återförsäljarens namn eller adress.",
    "search.searchBarPlaceholderEntriesPosition": "Ange position för radiesökning.",
    "search.searchBarPlaceholderGeoSearch": "Ange din adress...",
    "search.searchBarPlaceholderMobile": "Sök...",
    "search.searchBarPlaceholderProducts": "Sök produkter",
    "search.searchBarPositionTooltip": "Bestämma position eller ange plats med adress",
    "search.showAll": "Visa alla",
    "social.comment": "Kommentar",
    "social.Comment": "Kommentar",
    "social.CopycatJoiner": "Drömmar ditt bidrag",
    "social.dream.donateAmount": "Stöd med en summa pengar",
    "social.dream.donateDreampoints": "Stöd med Dreampoints",
    "social.dream.donateInfo": "Meddelandet är privat och kan endast läsas av ägaren till posten.",
    "social.dream.donateTip": "Stöd med råd och åtgärder",
    "social.dream.done": "DONE",
    "social.dream.edit": "Redigera posten",
    "social.dream.findDreams": "Här kan du hitta små och stora bidrag och förverkliga ditt bidrag och/eller hjälpa andra att förverkliga sina bidrag.",
    "social.dream.followedDreams": "Poster som du följer",
    "social.dream.giveEmail": "Dela min e-postadress",
    "social.dream.givePhone": "Dela mitt telefonnummer",
    "social.dream.joinerApproved": "Du deltar i detta bidrag",
    "social.dream.joinerButton": "Gå med i detta inlägg",
    "social.dream.joinerButtonMobile": "Gå med i",
    "social.dream.joinerDream": "Gruppens dröm",
    "social.dream.joinerInfo": "Om ägaren till posten bekräftar ditt deltagande blir posten en gruppdröm, där du har ytterligare rättigheter.",
    "social.dream.joinerMessage": "Här kan du skriva ditt meddelande till ägaren av annonsen:",
    "social.dream.joinerPending": "Ägaren till posten måste fortfarande bekräfta ditt deltagande.",
    "social.dream.joinerSupport": "Du stöder detta inlägg",
    "social.dream.joinerSupportButton": "Stöd denna post",
    "social.dream.joinerSupportButtonMobile": "Stöd",
    "social.dream.joinerSupportInfo": "Vi arbetar ständigt med förbättringar, till exempel stöd med pengar eller ingångar. Håll ögonen öppna.",
    "social.dream.joinerSupportPending": "Ägaren till posten måste fortfarande tillåta ditt stöd.",
    "social.dream.member": "Ledamot",
    "social.dream.members": "Ledamöter",
    "social.dream.meToo": "Jag vill också göra det.",
    "social.dream.meTooInfo": "Visa ägaren till posten att du tycker att posten är bra och att du vill göra den här posten.",
    "social.dream.noFollow": "Du följer ingen post!",
    "social.dream.noJoiner": "Du är ännu inte medlem i en gruppdröm",
    "social.dream.noLikes": "Du tycker inte om någon post ännu!",
    "social.dream.noSupport": "Du stöder inte en post",
    "social.dream.registerFirstText": "För att kunna dela en post, stödja posten eller ange att du vill göra denna post måste du vara inloggad. Det går snabbt och är gratis att registrera sig för Share your entry. Klicka bara på knappen \"Registrera\".",
    "social.dream.registerFirstTitle": "Drömma med, stödja, till och med göra?",
    "social.dream.settings": "Inställningar för inmatning",
    "social.dream.shareTitle": "Dela drömmen",
    "social.dream.similar": "Relaterade poster",
    "social.dreamline.addImageToDream": "Lägg till den här bilden i ditt bidrag",
    "social.dreamline.addMedia": "Lägg till bild/video",
    "social.dreamline.allActivities": "Alla Dreamline aktiviteter",
    "social.dreamline.anonymous": "Anonymt",
    "social.dreamline.commentButton": "Kommentar",
    "social.dreamline.comments": "skriver:",
    "social.dreamline.depublished": "Den här kommentaren har tagits bort på grund av olämpligt beteende.",
    "social.dreamline.dreampointsInfo": "Dessa är de trepunktsinriktningar som ingår i posten. Du kan läsa mer om Dreampoints i din profil.",
    "social.dreamline.fewDreampoints": "har mycket få Dreampoints. Kommentera, gilla eller dela inlägget.",
    "social.dreamline.filterBySteps": "Filtrera efter steg",
    "social.dreamline.filterComment": "Kommentarer",
    "social.dreamline.filteredBy": "Aktiviteter filtrerade efter",
    "social.dreamline.filteredBySteps": "Filtreras av steg",
    "social.dreamline.filterFavorite": "Följer",
    "social.dreamline.filterLike": "Gillar",
    "social.dreamline.filterShare": "Aktier",
    "social.dreamline.filterStep": "Steg",
    "social.dreamline.flagged": "Detta inlägg rapporterades",
    "social.dreamline.isComment": "Kommentarer",
    "social.dreamline.isCopycatJoiner": "dagdrömmer också",
    "social.dreamline.isFirstUpdate": "skapat",
    "social.dreamline.isShare": "delad",
    "social.dreamline.isStep": "tagit ett nytt steg",
    "social.dreamline.isUpdate": "UPPDATERAD",
    "social.dreamline.lastEntry": "Uppdatering av senaste posten",
    "social.dreamline.locked": "Detta innehåll är endast synligt för andra drömmare",
    "social.dreamline.lockedCTA": "Gå med i detta inlägg nu",
    "social.dreamline.noMessages": "Denna post har ännu ingen aktivitet. Skriv den första kommentaren nu.",
    "social.dreamline.noMessagesFiltered": "Ingen aktivitet med dessa filterinställningar för denna post.",
    "social.dreamline.nowFollower": "följ detta inlägg!",
    "social.dreamline.nowJoiner": "är nu en supporter",
    "social.dreamline.nowLikes": "gillade det här inlägget nu!",
    "social.dreamline.nowSharing": "delade detta bidrag!",
    "social.dreamline.onlyJoinerVisible": "Endast synlig för andra drömmare/stödjare",
    "social.dreamline.prefixComment": "har denna post tidigare",
    "social.dreamline.prefixCopycatJoiner": "vill uppdatera denna post eftersom",
    "social.dreamline.prefixFavorite": "följer denna post eftersom",
    "social.dreamline.prefixJoiner": "stöder denna post eftersom",
    "social.dreamline.prefixLike": "gillar detta inlägg eftersom",
    "social.dreamline.prefixShare": "har denna post tidigare",
    "social.dreamline.prefixStep": "har före",
    "social.dreamline.prefixUpdate": "har denna post tidigare",
    "social.dreamline.profile": "Profil",
    "social.dreamline.provideImage": "Jag ger den här bilden till ägaren av posten",
    "social.dreamline.resetFilter": "Återställ filter",
    "social.dreamline.sendComment": "Skicka",
    "social.dreamline.sharePart": "Dela",
    "social.dreamline.toggleStream": "Kommentarer",
    "social.dreamline.toggleStreamSingle": "Kommentar",
    "social.dreamline.writeComment": "Skriv en kommentar...",
    "social.Favorite": "Följ",
    "social.follow": "Följ",
    "social.Follow": "Följ",
    "social.hasLiked": "Du gillar redan det här inlägget.",
    "social.join": "Gå med i",
    "social.Join": "Gå med i",
    "social.Joiner": "Drömmer med",
    "social.like": "Som",
    "social.Like": "Som",
    "social.report.headlinePrefix": "Du är där",
    "social.report.headlineSuffix": "att rapportera.",
    "social.report.login": "Du måste vara inloggad för att rapportera inlägget.",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "Annat olämpligt innehåll",
    "social.report.reportScam": "Förvrängningar, mobbning.",
    "social.report.reportSex": "Sexuellt innehåll",
    "social.report.reportSpam": "Detta är skräppost",
    "social.report.reportViolence": "Skildring av våld",
    "social.report.send": "Skicka meddelande",
    "social.report.title": "Rapportera",
    "social.share": "Dela",
    "social.Share": "Dela",
    "syd.ui.dreamerCommercial": "B2B-standard",
    "syd.ui.userCommercial": "B2B Dreamer",
    "user.company.addUser": "Skapa ytterligare användare för",
    "user.company.inviteUser": "Skicka en inbjudan via e-post",
    "user.company.userIsInvited": "Inbjudningsbrev skickades",
    "user.company.userIsActive": "Användaren är aktiv",
    "user.company.userIsNotActive": "Användaren är ännu inte aktiverad",
    "user.company.additionalWebsite": "Företagets andra webbplats (endast domän utan https://)",
    "user.company.companyName": "Företagets namn",
    "user.company.contactFor": "Kontaktuppgifter för",
    "user.company.contactForDefault": "allmän kontakt",
    "user.company.copyContact": "Ta över kontaktuppgifter från företagsuppgifter för posten.",
    "user.company.email": "Kontakt via e-post",
    "user.company.facebook": "Företagets Facebook-konto (endast kontonamnet)",
    "user.company.fax": "Faxnummer för skriftlig kontakt",
    "user.company.google": "Företagets Google+ konto (endast kontonamnet)",
    "user.company.instagram": "Företagets Instagram-konto (endast kontonamnet)",
    "user.company.linkedin": "Företagets LinkedIn-konto (endast kontonamnet)",
    "user.company.newCustomerAccount": "Skapa ett företagskonto",
    "user.company.noCustomerValidation": "Ditt företagskonto har ännu inte aktiverats.",
    "user.company.noCustomerYet": "Din registrering har ännu inte tilldelats ett företagskonto.",
    "user.company.notAllowed": "Du kan inte göra några ändringar eftersom din användarstatus ({{type}}) tillåter inte detta.",
    "user.company.phone": "Telefonnummer för kontaktperson",
    "user.company.pinterest": "Företagets Pinterest-konto (endast kontonamnet)",
    "user.company.remarks": "Anteckningar",
    "user.company.registerNumber": "Handelsregistrets nummer",
    "user.company.registerAuth": "Lokal domstol",
    "user.company.acerCode": "ACER-kod för din energiförsörjning",
    "user.company.taxNumber": "Skattenummer",
    "user.company.type.default": "Standard",
    "user.company.type.procurator": "Auktoriserad undertecknare",
    "user.company.type.manager": "Verkställande direktör",
    "user.company.twitter": "Företagets Twitter-konto (endast kontonamn utan @)",
    "user.company.ustid": "UStID",
    "user.company.ustid.notValid": "Ange ett giltigt identifikationsnummer för försäljningsskatt",
    "user.company.website": "Företagets webbplats (endast domän utan https://)",
    "user.company.xing": "Företagets Xing-konto (endast kontonamn)",
    "user.company.youtube": "Företagets Youtube-konto (endast kontonamnet)",
    "user.message.after-save-error": "Dina ändringar kunde inte sparas.",
    "user.message.after-save-success": "Dina ändringar har sparats.",
    "user.message.can-not-login": "Vi kan inte logga in dig med dessa användar- och lösenordsuppgifter! Är användarnamnet rätt stavat? Har du angett lösenordet korrekt? Kanske har något gått fel vid registreringen.",
    "user.message.can-not-logout": "Vi kunde inte skriva ut dig!",
    "user.message.conflict": "Det här användarnamnet finns redan, välj ett annat!",
    "user.message.fileExistsError": "Uppladdningen misslyckades. Filen finns redan",
    "user.message.fileSizeError": "Uppladdningen misslyckades. Filen är för stor",
    "user.message.loginFailureBottom": "Du kanske inte har ett entryer-konto ännu?",
    "user.message.loginFailureTop": "Är ditt användarnamn rätt stavat? Har du angett ditt lösenord korrekt?",
    "user.message.registration-error": "Ett fel har inträffat under registreringen!",
    "user.message.registration-success": "Du bör snart få ett bekräftelsemail från oss.",
    "user.message.unknownError": "Okänt fel vid uppladdning av filen",
    "user.message.upload-success": "Uppladdning lyckad",
    "user.message.verify-error": "Din e-postadress kunde inte verifieras. Kontakta administratören.",
    "user.message.verify-success": "Din e-postadress har bekräftats. Du kan registrera dig nu:",
    "user.ui.accountGreeting": "Hej,",
    "user.ui.accountMenuActivities": "Verksamheter",
    "user.ui.accountMenuCompanySettings": "Information om företaget",
    "user.ui.accountMenuEntries": "Mina bidrag",
    "user.ui.accountMenuEntryPre": "Inlägg,",
    "user.ui.accountMenuEvents": "Mina evenemang",
    "user.ui.accountMenuFavorites": "Mina favoriter",
    "user.ui.accountMenuFollows": "Poster som jag följer",
    "user.ui.accountMenuImages": "Bilder/video/dokument",
    "user.ui.accountMenuJoin": "Min grupps drömmar",
    "user.ui.accountMenuLikes": "Poster som jag gillar",
    "user.ui.accountMenuMeasuring": "Mina mätplatser och mätdata",
    "user.ui.accountMenuMessages": "Nyheter",
    "user.ui.accountMenuNewsletter": "Mina prenumerationer på nyhetsbrev",
    "user.ui.accountMenuPoi": "Mina platser",
    "user.ui.accountMenuPress": "Mina artiklar",
    "user.ui.accountMenuProducts": "Mina produkter",
    "user.ui.accountMenuJobs": "Mitt jobb erbjuder",
    "user.ui.accountMenuProfile": "Användarprofil",
    "user.ui.accountMenuRatings": "Recensioner",
    "user.ui.accountMenuRegistrationData": "Inloggningsuppgifter",
    "user.ui.accountMenuReviews": "Recensioner",
    "user.ui.accountMenuSettings": "Inställningar",
    "user.ui.accountMenuSkillsOffer": "Jag erbjuder...",
    "user.ui.accountMenuSkillsRequest": "Jag behöver...",
    "user.ui.accountMenuOffer": "Mina erbjudanden",
    "user.ui.accountMenuRequest": "Mina önskemål",
    "user.ui.accountMenuSkillsSearch": "Förfrågningar och erbjudanden",
    "user.ui.accountMenuSubscriptions": "Prenumerationer",
    "user.ui.accountMenuSubscritions": "Min prenumeration",
    "user.ui.accountMenuSupport": "Poster som stöds",
    "user.ui.accountMenuSupports": "Poster som jag stöder",
    "user.ui.accountMenuTender": "Anbud",
    "user.ui.accountMenuOrders": "Mina beställningar",
    "user.ui.accountNoMessages": "Du har inte fått några meddelanden ännu.",
    "user.ui.accountProgressbar": "Din profil är {{value}}% komplett",
    "user.ui.accountProgressbarEntry": "Ditt bidrag är {{value}}% komplett",
    "user.ui.accountSettings": "Andra inställningar",
    "user.ui.accountCookieSettings": "Med ett klick på inställningar kan du justera dina inställningar.",
    "user.ui.accountCookieEssential": "Viktigt",
    "user.ui.accountCookieAnalysis": "Analysuppgifter",
    "user.ui.accountCookieSocial": "sociala medier",
    "user.ui.accountCookieReviews": "Recensioner",
    "user.ui.accountToolbarLogin": "Logga in",
    "user.ui.accountToolbarLoginTooltip": "Registrera dig gratis och kom igång",
    "user.ui.accountToolbarLogout": "Logga ut",
    "user.ui.adminPreview": "Förhandsgranska",
    "user.ui.backToLogin": "Tillbaka till registreringssidan",
    "user.ui.birthday": "Födelsedag",
    "user.ui.cannotLogin": "Kan du inte logga in? Klicka här för att återställa ditt lösenord.",
    "user.ui.changePassword": "Jag vill ändra mitt lösenord",
    "user.ui.changeUserGroup": "Annan prenumeration",
    "user.ui.city": "Staden",
    "user.ui.companyName": "Företagets namn",
    "user.ui.companyNameTooltip": "Viktigt: Din post kommer också att visas under följande företagsnamn",
    "user.ui.country": "Land",
    "user.ui.createNewAccount": "Skapa ett nytt användarkonto",
    "user.ui.dataDelete": "används för andra poster. Vill du radera filen permanent? När denna fil används kommer den att ersättas av en platshållare.",
    "user.ui.dataUsage": "Filen sparas i",
    "user.ui.dataUsageNone": "Filen används inte ännu. Vill du radera filen permanent?",
    "user.ui.deleteImage": "Ta bort bilden",
    "user.ui.description": "Ange en allmän beskrivning av personen eller företaget här.",
    "user.ui.documents": "Dokument",
    "user.ui.documentSearch": "Sökning av dokument",
    "user.ui.download": "Ladda ner {{title}}",
    "user.ui.email": "E-postadress",
    "user.ui.fillOutForm": "För registrering måste alla obligatoriska fält fyllas i fullständigt.",
    "user.ui.firstName": "Förnamn",
    "user.ui.academicTitle": "Akademisk titel",
    "user.ui.gender": "M/F",
    "user.ui.genderFemale": "kvinnlig",
    "user.ui.genderFemaleSalutation": "Ms.",
    "user.ui.genderMale": "Man",
    "user.ui.genderMaleSalutation": "Herr.",
    "user.ui.genderDiverse": "diverse",
    "user.ui.genderThird": "ej angivet.",
    "user.ui.housenumber": "Nej.",
    "user.ui.iAmCustomer": "Jag vill skapa ett B2B-konto",
    "user.ui.internalInfo": "Det finns meddelanden till dig. Klicka här.",
    "user.ui.lastLogin": "Senaste inloggning den",
    "user.ui.lastName": "Efternamn",
    "user.ui.loginButton": "Logga in",
    "user.ui.loginTitle": "Användarinloggning",
    "user.ui.languageAvailable": "Finns på ditt språk",
    "user.ui.languageMissing": "Finns endast på",
    "user.ui.memberSince": "Medlem sedan",
    "user.ui.mobileNumber": "Mobiltelefonnummer",
    "user.ui.myAddedStorage": "Bilder tillhandahålls",
    "user.ui.myStorage": "Min katalog",
    "user.ui.noRegisterMessage": "För ett användarkonto, vänligen kontakta de angivna kontaktadresserna. Du kan inte registrera dig själv som användare.",
    "user.ui.oldPassword": "Föregående lösenord",
    "user.ui.password": "Lösenord",
    "user.ui.pleaseSelect": "Vänligen välj",
    "user.ui.pleaseSelectSalutation": "Vänligen välj hälsningsformel",
    "user.ui.registerButton": "Skapa ett användarkonto nu",
    "user.ui.registerMessage": "Registrera dig nu och kom igång direkt. Det går snabbt och enkelt.",
    "user.ui.registerTitle": "Är du ny hos oss?",
    "user.ui.requestPasswordReset": "Begär ett nytt lösenord och radera det gamla lösenordet",
    "user.ui.requestPasswordSuccess": "Du bör få ett e-postmeddelande med en länk för återställning inom kort.",
    "user.ui.requestPasswordError": "Okänt fel vid återställning av lösenordet.",
    "user.ui.resetPassword": "Återställ lösenordet",
    "user.ui.setPassword": "Ange lösenord",
    "user.ui.resetPasswordPage": "Återställ ditt lösenord",
    "user.ui.setPasswordPage": "Ange ditt lösenord",
    "user.ui.resetPasswordSuccess": "Ditt lösenord har återställts, du kan logga in nu.",
    "user.ui.setPasswordSuccess": "Ditt lösenord har fastställts, du kan logga in nu.",
    "user.ui.selectCustomerType": "Du är ... välj",
    "user.ui.stayLoggedin": "Håll dig inloggad",
    "user.ui.stayLoggedinInfo": "Om det här är en enhet som är tillgänglig för allmänheten ska du avmarkera det här alternativet och logga ut igen för att avsluta.",
    "user.ui.street": "Street",
    "user.ui.salutation": "Hälsning",
    "user.ui.title": "Titel",
    "user.ui.thankYouForSubscribing": "Tack för att du prenumererar på vårt nyhetsbrev.",
    "user.ui.errorAtSubscribing": "Fel vid prenumeration på nyhetsbrevet",
    "user.ui.unsubscribe": "logga ut",
    "user.ui.unsubscribePage": "Är du säker på att du vill avregistrera dig från vårt nyhetsbrev?",
    "user.ui.uploadDrop": "För att ladda upp en fil drar du den hit eller",
    "user.ui.uploadSelectFile": "välja en fil",
    "user.ui.uploadToMyStorage": "Ladda upp nya bilder till min katalog",
    "user.ui.uploadUserImage": "Välj användarbild",
    "user.ui.username": "Användarnamn (e-postadress)",
    "user.ui.userName": "Användarnamn",
    "user.ui.visibilityInfo": "Tips: Du bestämmer själv vad som ska visas offentligt från din profil! Klicka på ögonsymbolen för att ställa in synligheten för dina poster:",
    "user.ui.zip": "Postnummer",
    "user.ui.phone": "Telefonnummer",
    "user.user": "Enda bärare",
    "user.users": "Anmälningar",
    "user.product.addedToFavorites": "Artikeln har lagts till bland dina favoriter",
    "order.status.zero": "beställning som mottagits",
    "order.status.one": "Under behandling",
    "order.status.two": "Beställningen har skickats",
    "order.status.three": "Tillgänglig på",
    "order.status.four": "Återge",
    "order.status.default": "Okänd yrkesstatus",
    "country.germany": "Tyskland",
    "country.austria": "Österrike",
    "country.belgium": "Belgien",
    "country.denmark": "Danmark",
    "country.bulgaria": "Bulgarien",
    "country.croatia": "Kroatien",
    "country.cyprus": "Cypern",
    "country.czechia": "Tjeckien",
    "country.estonia": "Estland",
    "country.finland": "Finland",
    "country.france": "Frankrike",
    "country.greece": "Grekland",
    "country.hungary": "Ungern",
    "country.ireland": "Irland",
    "country.italy": "Italien",
    "country.latvia": "Lettland",
    "country.lithuania": "Litauen",
    "country.luxembourg": "Luxemburg",
    "country.lu": "Luxemburg",
    "country.malta": "Malta",
    "country.netherlands": "Nederländerna",
    "country.poland": "Polen",
    "country.portugal": "Portugal",
    "country.romania": "Rumänien",
    "country.slovakia": "Slovakien",
    "country.slovenia": "Slovenien",
    "country.spain": "Spanien",
    "country.sweden": "Sverige",
    "country.norway": "Norge",
    "country.switzerland": "Schweiz",
    "country.turkey": "Turkiet",
    "country.unitedKingdom": "Storbritannien",
    "openingHours.tNowClosed": "Nu stängd",
    "openingHours.tOpeningHoursNotKnown": "ytterligare öppettider inte kända",
    "openingHours.tNowOpen": "Nu öppen",
    "openingHours.tNowStillOpen": "Nu fortfarande öppen",
    "openingHours.tNextOpeningHourUnknown": "Nästa öppningsdatum är okänt.",
    "openingHours.tSoonOpenFrom": "öppnar snart igen från",
    "openingHours.tFrom": "från",
    "openingHours.tUntil": "Genom",
    "openingHours.tOpenAgainFrom": "öppnas igen från",
    "openingHours.tOpenAgainFrom2": "öppnades igen den"
  };
});
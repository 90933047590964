define('webapp/services/geo-coding', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    myStorage: Ember.inject.service(),

    resolveAddress: function resolveAddress(address) {
      return this.get('myStorage').geocoder.ajaxPost({ param: 'resolveaddress', data: address });
    },
    reverseGeo: function reverseGeo(latLon) {
      return this.get('myStorage').geocoder.ajaxPost({ param: 'reversegeo', data: latLon });
    }
  });
});
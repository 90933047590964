define('webapp/services/cached-data', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    myStorage: Ember.inject.service(),
    languageService: Ember.inject.service(),
    cachedCategoriesPromise: null, //is a promise
    cachedFlatCategoriesPromise: null, //is a promise
    cachedSitePromise: null, //is a promise

    clearCategoryCache: function clearCategoryCache() {
      this.setProperties({
        cachedCategoriesPromise: null,
        cachedFlatCategoriesPromise: null
      });
    },
    getCategories: function getCategories() {
      var myStorage = this.get('myStorage');
      var cachedCategoriesPromise = this.get('cachedCategoriesPromise');
      var languageService = this.get('languageService');
      if (!cachedCategoriesPromise) {
        cachedCategoriesPromise = myStorage.category.ajaxGet({ param: 'json', query: { lang: languageService.selectedLanguage } }).then(function (categories) {
          return categories;
        });
        this.set('cachedCategoriesPromise', cachedCategoriesPromise);
      }

      return cachedCategoriesPromise;
    },
    getFlatCategories: function getFlatCategories() {
      var cachedFlatCategoriesPromise = this.get('cachedFlatCategoriesPromise');
      if (!cachedFlatCategoriesPromise) {
        cachedFlatCategoriesPromise = this.getCategories().then(function (categories) {
          return (0, _treeUtils.flattenTree)(categories);
        });
        this.set('cachedFlatCategoriesPromise', cachedFlatCategoriesPromise);
      }
      return cachedFlatCategoriesPromise;
    },
    getSite: function getSite(siteId) {
      var _this = this;

      var myStorage = this.get('myStorage');
      var cachedSitePromise = this.get('cachedSitePromise');
      if (!cachedSitePromise) {
        if (siteId) {
          cachedSitePromise = myStorage.site.ajaxGet({ param: siteId }).then(function (res) {
            _this.set('cachedSite', res);
            return res;
          });
        } else {
          cachedSitePromise = myStorage.site.ajaxGet().then(function (res) {
            _this.set('cachedSite', res[0]);
            return res[0];
          });
        }
        this.set('cachedSitePromise', cachedSitePromise);
      }

      return cachedSitePromise;
    },
    getFlatPages: function getFlatPages() {
      return this.getSite().then(function (site) {
        return site && site.pages && (0, _treeUtils.flattenTree)(site.pages);
      });
    }
  });
});
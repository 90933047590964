define('webapp/services/view-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    fastboot: Ember.inject.service(),
    templateSettings: Ember.inject.service(),
    showMap: Ember.computed(function () {
      return this.get('templateSettings.search.showMap');
    }),
    showSearchbar: false,
    transitionCount: 0, //used for sticky component, so it can react to a new transition
    hasScrolled: false,

    uploadInProgress: false, // use this variable to lock modal keyevents, if upload is in progress

    skipNextScrollToTop: function skipNextScrollToTop() {
      this.set('skipScroll', true);
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (!this.get('fastboot.isFastBoot')) {
        window.addEventListener('scroll', function (e) {
          _this.set('hasScrolled', window.pageYOffset !== 0);
        });
      }
    }
  });
});
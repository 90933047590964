define('webapp/components/widgets/widget-entry-stats', ['exports', 'webapp/utils/find-by'], function (exports, _findBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),

    fetchSelectedEntries: function fetchSelectedEntries() {
      var myStorage = this.get('myStorage');
      var selectedEntries = this.get('data.options.selectedEntries');

      if (selectedEntries && selectedEntries.length) {
        return myStorage.search.ajaxPost({ data: { slugFilter: selectedEntries } }).then(function (searchRes) {
          return selectedEntries.map(function (slug) {
            return (0, _findBy.default)(searchRes.hits, 'slug', slug);
          }).filter(Boolean);
        });
      } else {
        return Ember.RSVP.resolve([]);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var myStorage = this.get('myStorage');
      var maxEntries = this.get('data.options.maxEntries');

      Ember.RSVP.hash({
        selectedEntries: this.fetchSelectedEntries(),
        newestEntries: myStorage.search.ajaxPost({ data: { sort: 'createdAt', sortOrder: 'desc', size: maxEntries } }).then(function (res) {
          return res.hits;
        }),
        mostDreampointsEntries: myStorage.search.ajaxPost({ data: { sort: 'dreampoints', sortOrder: 'desc', size: maxEntries } }).then(function (res) {
          return res.hits;
        })
      }).then(function (res) {
        _this.setProperties(res);
        _this.set('groups', Object.keys(res).map(function (key) {
          return {
            title: key,
            items: res[key]
          };
        }));
      });
    }
  });
});
define('webapp/components/detail/product-container', ['exports', 'webapp/utils/misc', 'webapp/utils/min-variation-price'], function (exports, _misc, _minVariationPrice) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    message: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    productContainer: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),
    languageService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    selectedVariant: Ember.computed.oneWay('productContainer.selectedVariant'),
    selectedQuantity: Ember.computed.oneWay('productContainer.selectedQuantity'),
    cmsService: Ember.inject.service(),
    router: Ember.inject.service(),

    fullURL: Ember.computed('router.currentURL', function () {
      if (this.get('fastboot.isFastBoot')) {
        var siteUrl = this.get('cachedData.cachedSite.url');
        var currentURL = this.get('router.currentURL');
        return siteUrl + currentURL;
      } else {
        return window.location.href;
      }
    }),

    getPriceList: function getPriceList() {
      var detail = this.get('detail');
      var variations = (detail.products || []).filter(function (item) {
        return item.type === 'variation' && item.item.orderNumber && item.item.orderable;
      });
      var priceList = {};
      variations.forEach(function (product) {
        if (product.item.fullFields && product.item.fields.displayNetPrice) {
          var priceObj = product.item.fullFields.find(function (field) {
            return field.key === 'displayNetPrice';
          });
          if (priceObj && priceObj.options) {
            priceList[priceObj.value] = priceObj.options.unit || '€';
          }
        }
      });
      if (detail.fields && detail.fields.displayNetPrice) {
        var priceObj = detail.fullFields.find(function (field) {
          return field.key === 'displayNetPrice';
        });
        if (priceObj && priceObj.options) {
          priceList[priceObj.value] = priceObj.options.unit || '€';
        }
      }
      return priceList;
    },


    variationCount: Ember.computed('detail', function () {
      var detail = this.get('detail');

      var variations = (detail.products || []).filter(function (item) {
        // regardless of orderability
        return item.type === 'variation';
      });
      return variations.length;
    }),

    hasCarriers: Ember.computed('detail.variationAttachments', function () {
      var variationAttachments = this.get('detail.variationAttachments');

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = variationAttachments[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;

          var itemHasCarriers = !!item.categories.find(function (cat) {
            return cat.slug === 'traegerfahrzeuge';
          });
          if (itemHasCarriers) {
            return true;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return false;
    }),

    hasAttachments: Ember.computed('detail.variationAttachments', function () {
      var variationAttachments = this.get('detail.variationAttachments');

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = variationAttachments[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var item = _step2.value;

          var itemHasCarriers = !!item.categories.find(function (cat) {
            return cat.slug === 'anbaugeraete';
          });
          if (itemHasCarriers) {
            return true;
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return false;
    }),

    minPrice: Ember.computed('detail.products.@each', function () {
      var priceList = this.getPriceList();
      var vat = 0;
      // TODO in future from userGroup
      if (Object.keys(priceList).length === 0) {
        return false;
      }
      var min = Math.min.apply(Math, _toConsumableArray(Object.keys(priceList)));
      return { value: min * (1 + vat), unit: priceList[min] };
    }),

    hasVariations: Ember.computed('detail.products.@each', function () {
      var priceList = this.getPriceList();

      if (Object.keys(priceList).length > 1) {
        return true;
      } else {
        return false;
      }
    }),

    minVariationPrice: Ember.computed('detail.products', 'userProxy.isVatFreeBusiness', function () {
      var context = this.get('detail');
      var isVatFreeBusiness = this.get('userProxy.isVatFreeBusiness');
      if (isVatFreeBusiness) {
        return (0, _minVariationPrice.minVariationPrice)('Net', 'products', context);
      } else {
        return (0, _minVariationPrice.minVariationPrice)('Gross', 'products', context);
      }
    }),

    variants: Ember.computed('detail.products', function () {
      var detailProducts = this.get('detail.products') || [];
      return detailProducts.filter(function (_ref) {
        var type = _ref.type,
            direction = _ref.direction;
        return type === 'variation' && direction === 1;
      });
    }),

    displayedQuantities: Ember.computed('detail', 'selectedVariant', function () {
      var selectedVariant = this.get('selectedVariant') || this.get('detail');
      var pricingScale = selectedVariant.pricingScale || [];
      var priceGross = selectedVariant.priceGross;

      var displayedQuantities = [];

      var quantityLimit = selectedVariant.maxOrderQuantity || 20;

      var _loop = function _loop(quantity) {
        var pricingElement = (0, _misc.findLast)(pricingScale, function (x) {
          return x.from <= quantity;
        });

        var discount = 0;
        if (pricingElement) {
          discount = (100 * (1 - pricingElement.priceGross / priceGross)).toFixed(0);
        }

        displayedQuantities.push({
          quantity: quantity,
          discount: discount
        });
      };

      for (var quantity = 1; quantity <= quantityLimit; quantity++) {
        _loop(quantity);
      }

      return displayedQuantities;
    }),

    _updateFavoriteCount: function _updateFavoriteCount() {
      var event = new Event('updated-favorite-count');
      var elements = document.getElementsByClassName('tm-widget-favorites');
      var element = elements[0];
      element.dispatchEvent(event);
    },


    actions: {
      selectVariant: function selectVariant(variant) {
        var _this = this;

        var myStorage = this.get('myStorage');

        // fetch relations for variant if they dont exist
        if (variant && !variant.products) {
          var lang = this.get('languageService.selectedLanguage');
          var site = this.get('cachedData.cachedSite._id');
          var query = { lang: lang, site: site };
          var param = variant.slug;

          myStorage.product.ajaxGet({ param: param, query: query }).then(function (product) {
            Ember.set(variant, 'products', product.products);
            _this.set('productContainer.selectedVariant', variant);
          });
        } else {
          this.set('productContainer.selectedVariant', variant);
        }
      },
      selectQuantity: function selectQuantity(value) {
        this.set('productContainer.selectedQuantity', value);
      },
      addToShoppingCart: function addToShoppingCart(product, quantity) {
        this.get('shoppingCartService').addToShoppingCart(product, quantity);
      },
      addToFavorite: function addToFavorite() {
        var _this2 = this;

        var message = this.get('message');
        var myStorage = this.get('myStorage');
        var data = { item: this.get('detail._id') };
        var sessionId = this.get('cmsService.sessionId');
        var query = { sessionId: sessionId };
        var params = { data: data, query: query };

        myStorage.favorite.ajaxPost(params).then(function () {
          _this2.set('detail.isFavorite', true);
          message.infoT('user.product.addedToFavorites');
          _this2._updateFavoriteCount();
        });
      }
    }
  });
});
define('webapp/components/search/category-list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['isEmpty'],

    isEmpty: Ember.computed.alias('category.isEmpty'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('isExpanded', this.get('initialExpand'));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var selectedCategories = this.get('selectedCategories');
      var category = this.get('category');
      var categorySelected = selectedCategories && selectedCategories.indexOf(category._id) >= 0;
      this.set('categorySelected', categorySelected);
    },


    _obsCurrentExpanded: Ember.observer('currentExpanded', function () {
      // ensures only one category is expanded
      var currentExpanded = this.get('currentExpanded');
      if (currentExpanded !== this) {
        this.set('isExpanded', false);
      }
    }),

    isSelected: Ember.computed('category._id', 'selectedCategories.length', function () {
      var categoryId = this.get('category._id');
      var selectedCategories = this.get('selectedCategories');
      return !!selectedCategories.find(function (cat) {
        return cat._id === categoryId;
      });
    }),

    actions: {
      toggleCategory: function toggleCategory(cat) {
        this.toggleCategory && this.toggleCategory(cat);
      },
      toggleExpand: function toggleExpand() {
        var isExpanded = this.get('isExpanded');
        this.set('isExpanded', !isExpanded);
        if (!isExpanded) {
          this.expandItem && this.expandItem(this);
        }
      },
      expandItem: function expandItem(comp) {
        this.set('currentExpandedChild', comp);
      }
    }
  });
});
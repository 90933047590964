define('webapp/services/count-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),

    entryCount: 0,
    userCount: 0,
    imagesCount: 0,
    shareCount: 0,
    dreamlineCommentsCount: 0,
    groupDreamsCount: 0,
    notificationCount: 0,

    resetNotifications: function resetNotifications() {
      var _this = this;

      // TODO check what is need for master
      return;
      var myStorage = this.get('myStorage');
      myStorage.notification.ajaxGet({ param: 'reset' }).then(function (res) {
        _this.set('notificationCount', 0);
      });
    },
    refreshNotifications: function refreshNotifications() {
      var _this2 = this;

      // TODO check what is need for master
      return;
      var isLoggedIn = this.get('userProxy.isLoggedIn');
      var myStorage = this.get('myStorage');
      if (isLoggedIn) {
        myStorage.notification.ajaxGet({ param: 'count' }).then(function (res) {
          if (res <= 999) {
            _this2.set('notificationCount', res);
          } else {
            _this2.set('notificationCount', '99+');
          }
        });
      }
    },
    init: function init() {
      var _this3 = this;

      this._super.apply(this, arguments);
      var myStorage = this.get('myStorage');

      // TODO check what is need for master
      return;
      myStorage.social.ajaxGet({ param: 'get_all_counts/' }).then(function (res) {
        _this3.set('entryCount', res.entryCount);
        _this3.set('userCount', res.userCount);
        _this3.set('imagesCount', res.imagesCount);
        _this3.set('shareCount', res.shareCount);
        _this3.set('dreamlineCommentsCount', res.dreamlineCommentsCount);
        _this3.set('groupDreamsCount', res.groupDreamsCount);
      });
    }
  });
});
define('webapp/components/widgets/widget-favorite-count', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    hasData: false,
    element: false,

    init: function init() {
      this._super.apply(this, arguments);

      this.fetchData();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var elements = document.getElementsByClassName('tm-widget-favorites');
      if (elements.length >= 1) {
        var callback = function callback() {
          _this.fetchData();
        };
        var element = elements[0];
        this.set('element', element);
        this.set('callback', callback);
        element.addEventListener('updated-favorite-count', callback);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var element = this.get('element');
      var callback = this.get('callback');
      if (element) {
        element.removeEventListener('updated-favorite-count', callback);
      }
    },
    fetchData: function fetchData() {
      var _this2 = this;

      var myStorage = this.get('myStorage');
      var sessionId = this.get('cmsService.sessionId');
      var query = { sessionId: sessionId };
      var param = 'favorite_count';

      return myStorage.widget.ajaxGet({ param: param, query: query }).then(function (hits) {
        _this2.set('hits', hits);
        _this2.set('hasData', true);
      });
    }
  });
});
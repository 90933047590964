define('webapp/utils/filter-categories', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function filterCategories(categories, slugs) {
    var onlyChildren = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    var cats = slugs.map(function (slug) {
      return (0, _treeUtils.findInTree)(categories, function (cat) {
        return cat.slug === slug;
      });
    }).filter(Boolean);

    if (onlyChildren) {
      var res = [];
      cats.forEach(function (cat) {
        if (cat && cat.children) {
          res = res.concat(cat.children);
        }
      });
      return res;
    } else {
      return cats;
    }
  }

  exports.default = filterCategories;
});
define('webapp/components/press/article-export', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var replaceTemplates = [{
    name: 'Deutsche Anführungszeichen',
    type: 'regex',
    match: '"(.*?)"',
    replace: '„$1“'
  }, {
    name: 'Englische Anführungszeichen',
    type: 'regex',
    match: '„(.*?)“',
    replace: '"$1"'
  }, {
    name: 'Guillemets',
    type: 'regex',
    match: '"(.*?)"',
    replace: "«$1»"
  }];

  function computedReplacement(field) {
    return Ember.computed('selectedReplacement', field, function () {
      var item = this.get('item');
      var selectedReplacement = this.get('selectedReplacement');
      var content = Ember.get(item, field);

      if (content && selectedReplacement) {
        var matchRe = new RegExp(selectedReplacement.match, 'gm');
        return content.replace(matchRe, selectedReplacement.replace);
      } else {
        return content;
      }
    });
  }

  exports.default = Ember.Component.extend({
    replaceTemplates: replaceTemplates,

    selectedReplacement: null,

    actions: {
      toggleReplacement: function toggleReplacement(replacement) {
        var selectedReplacement = this.get('selectedReplacement');

        if (selectedReplacement === replacement) {
          selectedReplacement = null;
        } else {
          selectedReplacement = replacement;
        }

        this.set('selectedReplacement', selectedReplacement);
      }
    },

    descriptionR: computedReplacement('description'),
    titleR: computedReplacement('title'),
    hintR: computedReplacement('hint'),
    shortDescriptionR: computedReplacement('shortDescription')

  });
});
define('webapp/components/widgets/widget-gdpr-banner', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    gdprService: Ember.inject.service(),

    settingsShown: false,

    // those props are probably deprecated
    gaAccepted: true,
    fbAccepted: true,
    peAccepted: true,

    gtagAddsAccepted: true,
    gtagPersonalizationAccepted: true,
    socialTrackingEnabled: true,

    actions: {
      acceptGDPR: function acceptGDPR() {
        var gdprService = this.get('gdprService');
        window.socialTrackingEnabled = true;
        window.localStorage.setItem('social-tracking-enabled', JSON.stringify(true));
        gdprService.acceptGDPR();
      },
      denyGDPR: function denyGDPR() {
        var gdprService = this.get('gdprService');
        gdprService.denyGDPR();
      },
      showSettings: function showSettings() {
        this.set('settingsShown', true);
      },
      saveSettingsNew: function saveSettingsNew() {
        var gdprService = this.get('gdprService');
        var gtagOptions = {
          ad_storage: 'granted',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          analytics_storage: 'denied'
        };

        var gtagAddsAccepted = this.get('gtagAddsAccepted');
        var gtagPersonalizationAccepted = this.get('gtagPersonalizationAccepted');
        var socialTrackingEnabled = this.get('socialTrackingEnabled');
        window.socialTrackingEnabled = socialTrackingEnabled;
        window.localStorage.setItem('social-tracking-enabled', JSON.stringify(socialTrackingEnabled));

        if (gtagAddsAccepted) {
          gtagOptions.analytics_storage = 'granted';
        }

        if (gtagPersonalizationAccepted) {
          gtagOptions.ad_user_data = 'granted';
          gtagOptions.ad_personalization = 'granted';
        }

        if (!socialTrackingEnabled) {
          gdprService.set('ignoreFacebook', true);
        }

        gdprService.setGtagConsent(gtagOptions);
        gdprService.acceptGDPRLoad();
      },
      saveSettings: function saveSettings() {
        var gaAccepted = this.get('gaAccepted');
        var fbAccepted = this.get('fbAccepted');
        var peAccepted = this.get('peAccepted');

        var gdprService = this.get('gdprService');

        if (gaAccepted && fbAccepted && peAccepted) {
          gdprService.acceptGDPR();
        } else {
          gdprService.denyGDPR();
        }
      }
    }
  });
});
define('webapp/components/map/base-map', ['exports', 'webapp/utils/inject-script', 'webapp/config/environment'], function (exports, _injectScript, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _objectWithoutProperties(obj, keys) {
    var target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function easing(t) {
    return t;
  }

  exports.default = Ember.Component.extend(Ember.Evented, {
    myStorage: Ember.inject.service(),
    languageService: Ember.inject.service(),
    showControls: true,
    defaultZoom: 0,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _injectScript.default)('assets/mapbox-gl.js').then(function () {
        _this.setupMap();
      });
    },
    convertGeopoint: function convertGeopoint(geoPoint) {
      return [geoPoint.lon, geoPoint.lat];
    },
    setupMap: function setupMap() {
      var _this2 = this;

      if (this.isDestroyed) {
        return;
      }

      var center = this.get('center') || [12, 49];
      var minZoom = this.get('minZoom') || 1;
      var maxZoom = this.get('maxZoom') || 20;

      var mapElement = this.element.getElementsByClassName('map-element');
      mapElement = mapElement.length > 0 ? mapElement[0] : this.element;

      mapboxgl.accessToken = _environment.default.mapboxAccessToken;
      var map = new mapboxgl.Map({
        container: mapElement,
        center: this.get('customCenter') || center,
        style: this.get('mapboxStyle') || _environment.default.mapboxStyle,
        minZoom: minZoom,
        maxZoom: maxZoom,
        zoom: this.get('defaultZoom'),
        scrollZoom: !this.get('disableScrollZoom'),
        attributionControl: false
      });

      if (this.get('showControls')) {
        var nav = new mapboxgl.NavigationControl();
        // should be another config parameter in template map.controls.position
        map.addControl(nav, 'top-right');
      }

      map.once('load', function () {
        Ember.run.schedule('afterRender', _this2, function () {
          _this2.map = map;
          if (_this2.isDestroyed) {
            _this2.destroyMap();
          } else {
            _this2.trigger('mapLoaded');
            // only for debugging
            // map.on('click', evt => this.logGeoPoint(evt));
          }
        });
      });
    },
    logGeoPoint: function logGeoPoint(evt) {
      console.log('mapClick');
      console.log({ lat: evt.lngLat.lat, lon: evt.lngLat.lng });
    },
    destroyMap: function destroyMap() {
      var map = this.map;
      if (!map) {
        return;
      }

      this.map = undefined;
      if (map.loaded()) {
        map.remove();
      } else {
        map.once('load', function () {
          map.remove();
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.map) {
        this.destroyMap();
      }
      this._super.apply(this, arguments);
    },
    getIcon: function getIcon() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var markerClass = options.class || 'my-marker';
      var div = document.createElement('div');
      if (options && options.idx) {
        div.innerHTML = "<div class='marker-number'>" + options.idx + '</div>';
      }
      markerClass.split(' ').forEach(function (className) {
        return div.classList.add(className);
      });
      return div;
    },
    getClusterMarker: function getClusterMarker(feature) {
      var _this3 = this;

      var coords = feature.geometry.coordinates;
      var pointCount = feature.properties.point_count;
      var radius = 0;

      if (pointCount > 10) {
        radius = 80;
      } else if (pointCount > 5) {
        radius = 60;
      } else if (pointCount > 2) {
        radius = 40;
      } else {
        radius = 30;
      }

      var element = document.createElement('div');
      element.className = 'cluster-marker';
      element.style = 'width: ' + radius + 'px; height: ' + radius + 'px; border-radius: ' + radius + 'px';
      element.innerHTML = '<div class="cluster-count">' + pointCount + '</div>';

      element.addEventListener('click', function () {
        var source = _this3.map.getSource('cluster-source');

        source.getClusterLeaves(feature.properties.cluster_id, 0, 0, function (err, children) {
          var bounds = new mapboxgl.LngLatBounds();
          children.forEach(function (x) {
            return bounds.extend(x.geometry.coordinates);
          });
          _this3.map.fitBounds(bounds, { padding: 80, maxZoom: 17, easing: function easing(t) {
              return t;
            }, duration: 500 });
        });
      });

      return new mapboxgl.Marker({
        element: element
      }).setLngLat(coords);
    },
    loadDetail: function loadDetail(item) {
      var myStorage = this.get('myStorage');

      if (item.itemType === 'entry') {
        return myStorage.entry.ajaxGet({
          param: 'entryslug/' + item.slug,
          query: { lang: this.get('languageService.selectedLanguage') }
        }).then(function (x) {
          return _extends({}, x, { itemType: item.itemType });
        });
      } else if (item.itemType === 'event') {
        return myStorage.event.ajaxGet({
          param: item.slug,
          query: { lang: this.get('languageService.selectedLanguage') }
        }).then(function (x) {
          return _extends({}, x, { itemType: item.itemType });
        });
      } else {
        return Ember.RSVP.resolve(item);
      }
    },
    renderSimpleMarkers: function renderSimpleMarkers(hits) {
      var _this4 = this;

      var map = this.get('map');

      if (this._markerLayer) {
        this._markerLayer.forEach(function (marker) {
          return marker.remove();
        });
      }
      this._markerLayer = [];
      hits.forEach(function (hit, idx) {
        var iconElement = _this4.getIcon({
          idx: idx + 1,
          class: 'my-marker marker-' + (hit.primaryCategory && hit.primaryCategory.slug)
        });
        var lnglat = _this4.convertGeopoint(hit.geoPoint);
        iconElement.addEventListener('click', function () {
          _this4.loadDetail(hit).then(function (fullHit) {
            _this4.createPopup(fullHit);
          });
        });
        var marker = new mapboxgl.Marker(iconElement).setLngLat(lnglat);
        marker.addTo(map);
        _this4._markerLayer.push(marker);
      });
    },
    renderClusters: function renderClusters(hits) {
      var _this5 = this;

      var map = this.get('map');
      var featureCollection = {
        type: 'FeatureCollection',
        features: hits.map(function (_ref) {
          var geoPoint = _ref.geoPoint,
              rest = _objectWithoutProperties(_ref, ['geoPoint']);

          return {
            type: 'Feature',
            properties: rest,
            geometry: {
              type: 'Point',
              coordinates: _this5.convertGeopoint(geoPoint)
            }
          };
        })
      };

      var source = map.getSource('cluster-source');
      if (source) {
        source.setData(featureCollection);
      } else {
        map.addSource('cluster-source', {
          type: 'geojson',
          data: featureCollection,
          cluster: true,
          clusterRadius: 60,
          clusterMaxZoom: 10
        });
      }
      // invisible dummy cluster layer, features are empty otherwise
      if (!map.getLayer('cluster-layer')) {
        map.addLayer({
          id: 'cluster-layer',
          type: 'circle',
          filter: ['has', 'point_count'],
          source: 'cluster-source',
          paint: {
            'circle-color': 'rgba(255, 0, 0, 0)'
          }
        });
      }
    },
    clearPopups: function clearPopups() {
      if (this._popupLayer) {
        this._popupLayer.forEach(function (x) {
          return x.remove();
        });
      }
    },
    createPopup: function createPopup(model) {
      var _this6 = this;

      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var mapPopup = this.get('mapPopup');
      var searchService = this.get('searchService');
      mapPopup.set('model', model);

      setTimeout(function () {
        _this6.clearPopups();
        var map = _this6.get('map');

        var lnglat = [model.geoPoint.lon, model.geoPoint.lat];
        var popupLayer = [];
        if (options.onlyMarker) {
          var markerElement = _this6.getIcon({ class: 'my-marker detail-marker' });
          if (searchService) {
            // check needed, because marker-map doesnt have searchService
            markerElement.addEventListener('click', function () {
              searchService.trigger('markerClick', model);
            });
          }
          var marker = new mapboxgl.Marker(markerElement).setLngLat(lnglat).addTo(map);

          popupLayer.push(marker);
        } else {
          if (options.showMarker) {
            var _markerElement = _this6.getIcon({ class: 'my-marker detail-marker' });
            var _marker = new mapboxgl.Marker(_markerElement).setLngLat(lnglat).addTo(map);

            popupLayer.push(_marker);
          }

          var popupOptions = { anchor: options.anchor };
          var popup = new mapboxgl.Popup(popupOptions).setLngLat(lnglat).setDOMContent(mapPopup.element).addTo(map);
          popupLayer.push(popup);
        }

        if (options.transForm) {
          var tr = map.transform;
          var point = tr.project(new mapboxgl.LngLat(lnglat[0], lnglat[1]));
          point.y -= options.transForm[0];
          point.x -= options.transForm[1];
          lnglat = tr.unproject(point);
        }

        var panOptions = { easing: easing };

        if (options.duration === undefined) {
          panOptions.duration = 500;
        } else {
          panOptions.duration = options.duration;
        }

        map.panTo(lnglat, panOptions);
        _this6._popupLayer = popupLayer;
      }, 1);
    }
  });
});
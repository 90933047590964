define("webapp/helpers/nutrition-percent", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var serving = params[0];
    var nutritionType = params[1];

    // table NRV and table Nutrition (EU) – rev 2021 – values are NRV/day
    var nutritionTable = {
      "nutritionEnergy": 8400,
      "nutritionFat": 70,
      "nutritionFatSaturated": 20,
      "nutritionCarb": 260,
      "nutritionSugar": 90,
      "nutritionProtein": 50,
      "nutritionSalt": 6,
      "nutritionA": 800,
      "nutritionD": 5,
      "nutritionE": 12,
      "nutritionK": 75,
      "nutritionB1": 1.1,
      "nutritionB2": 1.4,
      "nutritionB3": 16,
      "nutritionB6": 1.4,
      "nutritionB12": 2.5,
      "nutritionZinc": 10,
      "calcium": 800,
      "magnesium": 375,
      "kalium": 2000,
      "mangan": 2,
      "nutritionSelen": 55,
      "nutritionCopper": 1,
      "jod": 1.5
    };

    var result = serving / nutritionTable[nutritionType] * 100;

    if (result > 0) {
      return parseFloat(result).toFixed(0);
    } else {
      return "– ";
    }
  });
});
define('webapp/helpers/split-by-brackets', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    // first parameter:         Text with {Splitted Text}
    // second parameter:        Part to return 1 = pre, 2 = Splitted Text, 3 = post
    var currentText = params[0] || '';
    var returnPart = params[1] || 2;
    var results = currentText.split(/({.*?})/);

    if (results[0] && returnPart == 1 && results[0].charAt(0) != '{') {
      return results[0];
    }
    if (results[0] && returnPart == 2 && results[0].charAt(0) == '{') {
      return results[0].slice(1, -1);
    }
    if (results[1] && returnPart == 2 && results[1].charAt(0) == '{') {
      return results[1].slice(1, -1);
    }
    if (results[1] && returnPart == 3 && results[1].charAt(0) != '{') {
      return results[1];
    }
    if (results[2] && returnPart == 3 && results[2].charAt(0) != '{') {
      return results[2];
    }
  });
});
define('webapp/components/account/catalogue-relations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function moveItemInArray(items, item, offset) {
    var oldIdx = items.indexOf(item);
    var newIdx = oldIdx + offset;
    newIdx = (newIdx + items.length) % items.length;
    var tmpItem = items[newIdx];
    Ember.run(function () {
      items.replace(oldIdx, 1, [tmpItem]);
      items.replace(newIdx, 1, [item]);
    });
  }

  exports.default = Ember.Component.extend({
    listClass: 'uk-panel uk-panel-box uk-margin-bottom',
    oneWay: false,
    hasOpeningHours: false,
    itemType: 'entry', // poi or event

    move: function move(item, offset) {
      var items = this.get('items');
      moveItemInArray(items, item, offset);
    },

    moveIdx: function moveIdx(itemIdx, offset) {
      var items = this.get('items').slice();

      var otherItemIdx = (itemIdx + offset + items.length) % items.length;

      var tempSortOrder = items[itemIdx].sortOrder;
      Ember.set(items[itemIdx], 'sortOrder', items[otherItemIdx].sortOrder);
      Ember.set(items[otherItemIdx], 'sortOrder', tempSortOrder);

      var tmpItem = items[otherItemIdx];
      items[otherItemIdx] = items[itemIdx];
      items[itemIdx] = tmpItem;

      this.set('items', items);
    },

    actions: {
      addItemModal: function addItemModal() {
        this.set('showModal', true);
      },
      cancel: function cancel() {
        this.set('showModal', false);
      },
      addItem: function addItem(item) {
        var hasOpeningHours = this.get('hasOpeningHours');
        var items = this.get('items');

        var options = hasOpeningHours ? { relatedOpeningHours: { data: { include: [], exclude: [], maybe: [] } } } : {};
        items.pushObject({
          item: item,
          options: options
        });

        this.set('showModal', false);
      },
      removeItem: function removeItem(item) {
        var items = this.get('items');
        items.removeObject(items.findBy('_id', item._id));
      },


      moveUp: function moveUp(itemIdx) {
        this.moveIdx(itemIdx, -1);
      },

      moveDown: function moveDown(itemIdx) {
        this.moveIdx(itemIdx, 1);
      },

      showOpeningHoursModal: function showOpeningHoursModal(item) {
        this.set('modalItem', item);
        this.set('showOpeningHoursModal', true);
      },
      confirmOpeningHours: function confirmOpeningHours(modalItem) {
        this.set('showOpeningHoursModal', false);
        this.set('item', modalItem);
      },
      cancelOpeningHours: function cancelOpeningHours() {
        this.set('showOpeningHoursModal', false);
      }
    }
  });
});
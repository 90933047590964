define('webapp/components/search/category-browser', ['exports', 'webapp/utils/tree-utils', 'webapp/utils/filter-categories'], function (exports, _treeUtils, _filterCategories) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    cachedData: Ember.inject.service(),
    categoryCounts: {},
    rawCategories: [],
    selectedCategories: [],
    maxLevel: Infinity,

    hideEmptyCats: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (!this.get('customCategories')) {
        this.get('cachedData').getCategories().then(function (res) {
          _this.set('rawCategories', res.slice());
        });
      }
    },


    availableCategories: Ember.computed('rawCategories', 'filter', function () {
      var customCategories = this.get('customCategories');
      if (customCategories) {
        return customCategories;
      }
      var onlyChildren = this.get('onlyChildren');
      var filters = this.get('filter').split(/,\s*/);
      var rawCategories = this.get('rawCategories');
      var filteredCategories = (0, _filterCategories.default)(rawCategories, filters);

      if (onlyChildren) {
        var childCats = [];
        for (var i = 0; i < filteredCategories.length; i++) {
          var cat = filteredCategories[i];
          if (cat.children && cat.children.length) {
            childCats = childCats.concat(cat.children);
          }
        }
        return childCats;
      } else {
        return filteredCategories;
      }
    }),

    enhancedCategories: Ember.computed('availableCategories', 'categoryCounts', function () {
      var maxLevel = this.get('maxLevel');
      var availableCategories = this.get('availableCategories');
      var categoryCounts = this.get('categoryCounts') || {};
      var hideEmptyCats = this.get('hideEmptyCats');

      return (0, _treeUtils.mapTree)(availableCategories, function (cat, parent, level) {
        cat = _extends({}, cat);
        var count = categoryCounts[cat._id] || 0;
        cat.count = count;
        cat.isEmpty = !count;
        if (level < maxLevel && !(hideEmptyCats && count === 0)) {
          return cat;
        }
      });
    }),

    selectCategory: function selectCategory(cat) {
      var selectedCategories = this.get('selectedCategories');
      // const flatCategories = this.get('flatCategories').map(x => x._id);

      var multiSelect = this.get('multiSelect');

      selectedCategories = selectedCategories.concat(cat);

      if (!multiSelect) {
        selectedCategories = [cat];
        // selectedCategories = selectedCategories.filter(x => {
        //   return x._id === cat._id || flatCategories.indexOf(x._id) < 0;
        // });
      }

      this.categoriesChanged && this.categoriesChanged(selectedCategories);
    },


    actions: {
      expandItem: function expandItem(comp) {
        this.set('currentExpandedChild', comp);
      },
      toggleCategory: function toggleCategory(cat) {
        var selectedCategories = this.get('selectedCategories');
        var foundCat = selectedCategories.find(function (sCat) {
          return sCat._id === cat._id;
        });
        if (foundCat) {
          this.categoriesChanged && this.categoriesChanged(selectedCategories.filter(function (x) {
            return x !== foundCat;
          }));
        } else {
          this.selectCategory(cat);
        }
      }
    }
  });
});
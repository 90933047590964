define('webapp/services/location-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    browserLocationDenied: false,
    browserLocation: null,

    getBrowserLocation: function getBrowserLocation() {
      var _this = this;

      if (this.get('browserLocationDenied')) {
        return Ember.RSVP.resolve(null);
      }
      return new Ember.RSVP.Promise(function (resolve) {
        navigator.geolocation.getCurrentPosition(function (location) {
          _this.set('browserLocationDenied', false);
          var browserLocation = { lat: location.coords.latitude, lon: location.coords.longitude };
          _this.set('browserLocation', browserLocation);
          resolve(browserLocation);
        }, function (err) {
          if (err) {
            _this.set('browserLocationDenied', true);
          }
          resolve(null);
        });
      });
    }
  });
});
define('webapp/components/show-rating-stars', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    maxRating: 5,

    stars: Ember.computed('maxRating', 'value', function () {
      var maxRating = this.get('maxRating');
      var value = this.get('value');

      var roundValue = Math.round(2 * value) / 2;

      var stars = [];
      for (var i = 1; i < maxRating + 1; i++) {
        var iconClass = void 0;

        if (i <= roundValue) {
          iconClass = 'rating-star filled';
        } else if (i === roundValue + 0.5) {
          iconClass = 'rating-star filled';
        } else {
          iconClass = 'rating-star';
        }

        stars.push({
          iconClass: iconClass
        });
      }

      return stars;
    })

  });
});
define('webapp/components/uk-lightbox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fastboot: Ember.inject.service(),

    willDestroyElement: function willDestroyElement() {
      if (this._lightbox) {
        this._lightbox.hide(true);
      }
    },
    _checkLightbox: function _checkLightbox() {
      var isFastBoot = this.get('fastboot.isFastBoot');
      if (!isFastBoot) {
        this._lightbox = UIkit.lightbox(this.element, {
          //for more information on these options visit https://getuikit.com/docs/lightbox#lightbox-panel-options
          animation: this.get('animation'),
          autoplay: this.get('autoplay'),
          autoplayInterval: this.get('autoplayInterval'),
          pauseOnHover: this.get('pauseOnHover'),
          videoAutoplay: this.get('videoAutoplay'),
          index: this.get('index'),
          velocity: this.get('velocity'),
          preload: this.get('preload'),
          items: this.get('items'),
          template: this.get('template'),
          delayControls: this.get('delayControls')
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._checkLightbox();
    }
  });
});
define('webapp/components/detail/global-search-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    globalSearchContainer: Ember.inject.service(),
    hits: Ember.computed.oneWay('detail.hits'),
    totalHits: Ember.computed.oneWay('detail.totalHits'),
    globalSearch: Ember.computed.oneWay('detail.globalSearch'),
    pagingPage: 1,
    isLoading: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.scrollListener = this.scrollListener.bind(this);
    },


    loadedHits: Ember.computed('detail', function () {
      var hits = this.get('detail.hits');

      return hits.length;
    }),

    didInsertElement: function didInsertElement() {
      var search = this.get('globalSearchContainer');
      var selectedCategories = search.get('selectedCategories', []);
      this.set('selectedCategories', selectedCategories);

      window.addEventListener('scroll', this.scrollListener);
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('scroll', this.scrollListener);
    },
    scrollListener: function scrollListener() {
      var _this = this;

      var search = this.get('globalSearchContainer');
      var pagingPage = this.get('pagingPage');
      var perPage = search.get('perPage');
      var totalHits = this.get('totalHits');
      var isLoading = this.get('isLoading');
      var docHeight = document.body.clientHeight;

      var dataShouldLoadMore = !isLoading && totalHits > pagingPage * perPage;
      var positionShouldLoadMore = window.scrollY + 1.7 * window.innerHeight > docHeight;
      var shouldLoadMore = dataShouldLoadMore && positionShouldLoadMore;

      if (shouldLoadMore) {
        var fingerprint = search.get('fingerprint');
        var searchTerm = search.get('searchTerm');

        this.set('pagingPage', pagingPage + 1);
        this.set('isLoading', true);

        var data = search._getSearchData(searchTerm);
        data.page = pagingPage;
        var query = search._getSearchQuery(fingerprint);

        return search._fetchData(data, query).then(function (detail) {
          var currentHits = _this.get('hits');
          var hits = [].concat(_toConsumableArray(currentHits), _toConsumableArray(detail.hits));
          detail.hits = hits;
          _this.set('detail', detail);
          _this.set('isLoading', false);
        });
      }
    },
    _triggerUpdate: function _triggerUpdate() {
      var _this2 = this;

      var search = this.get('globalSearchContainer');
      var owner = Ember.getOwner(this);
      var controller = owner.lookup('controller:page');
      var types = this.get('detail.globalSearch.types');

      var exclude = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = types[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var type = _step.value;

          if (!type.display) {
            exclude.push(type.name);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      if (exclude.length === 0) {
        controller.set('exclude', null);
      } else {
        controller.set('exclude', exclude);
      }

      var fingerprint = search.get('fingerprint');
      var searchTerm = search.get('searchTerm');
      var data = search._getSearchData(searchTerm);
      data['exclude'] = exclude;
      var query = search._getSearchQuery(fingerprint);

      this.set('isLoading', true);
      return search._fetchData(data, query).then(function (detail) {
        _this2.set('detail', detail);
        _this2.set('isLoading', false);
        _this2.set('pagingPage', 1);
      });
    },


    actions: {
      filterUpdate: function filterUpdate(type) {
        var types = this.get('detail.globalSearch.types');
        var name = type.name;
        var display = type.display;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = types[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var _type = _step2.value;

            if (_type.name === name) {
              Ember.set(_type, 'display', !display);
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        this._triggerUpdate();
      }
    }
  });
});
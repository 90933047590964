define('webapp/controllers/account/company', ['exports', 'webapp/utils/clone-deep', 'webapp/utils/countries', 'webapp/utils/validation-utils', 'webapp/utils/ustid'], function (exports, _cloneDeep, _countries, _validationUtils, _ustid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function getDefaultDeliveryAddress() {
    return {
      type: 'delivery',
      firstName: '',
      lastName: '',
      street: '',
      houseNumber: '',
      zip: '',
      city: '',
      countryCode: null
    };
  }

  exports.default = Ember.Controller.extend({
    shoppingCartService: Ember.inject.service(),

    isNew: false,
    addressModalShown: false,
    editedAddress: null,

    // TODO: This should be set in Shopping-Component

    shoppingHasBusinessCustomers: false,
    shoppingHasExtendedContact: false,

    // end above

    countries: Ember.computed(function () {
      return _countries.DEFAULT_COUNTRIES_GROUPED;
    }),

    ustIDValid: Ember.computed('model.ustID', 'model.address.countryCode', function () {
      var ustIDString = this.get('model.ustID');
      var countryCode = this.get('model.address.countryCode');

      if (!ustIDString) {
        return false;
      }

      return (0, _ustid.checkUstID)(ustIDString, countryCode);
    }),

    invoiceAddressIsDefaultAddress: Ember.computed('model.addresses.@each.isDefault', function () {
      var addresses = this.get('model.addresses') || [];

      if (addresses.length === 0) {
        return true;
      }

      return addresses.every(function (address) {
        return !address.isDefault;
      });
    }),

    actions: {
      setDefaultDeliveryAddress: function setDefaultDeliveryAddress(addressIndex) {
        var addresses = this.get('model.addresses');

        for (var i = 0; i < addresses.length; i++) {
          var address = addresses[i];
          Ember.set(address, 'isDefault', addressIndex === i);
        }
      },
      setInvoiceAddressAsDefaultAddress: function setInvoiceAddressAsDefaultAddress(e) {
        var checked = e.target.checked;
        var addresses = this.get('model.addresses');

        if (checked) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = addresses[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var address = _step.value;

              Ember.set(address, 'isDefault', false);
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        } else if (addresses.length === 0) {
          var _address = this.get('model.address');
          addresses.pushObject(_extends({}, _address, {
            type: 'delivery',
            isDefault: true
          }));
        }
      },
      addDeliveryAddress: function addDeliveryAddress() {
        var ownerInjection = Ember.getOwner(this).ownerInjection();

        this.set('addressEditMode', 'new');
        this.set('editedAddress', (0, _validationUtils.makeValidatedAddress)(getDefaultDeliveryAddress(), ownerInjection));
        this.set('addressModalShown', true);
      },
      editDeliveryAddress: function editDeliveryAddress(addressIndex) {
        var ownerInjection = Ember.getOwner(this).ownerInjection();
        var address = this.get('model.addresses')[addressIndex];

        this.set('editedAddress', (0, _validationUtils.makeValidatedAddress)((0, _cloneDeep.default)(address), ownerInjection));
        this.set('editedAddressIndex', addressIndex);
        this.set('addressEditMode', 'edit');
        this.set('addressModalShown', true);
      },
      confirmEditDeliveryAddress: function confirmEditDeliveryAddress() {
        var addressEditMode = this.get('addressEditMode');
        var editedAddress = (0, _cloneDeep.default)(this.get('editedAddress')); // to remove cp validations and return pojo
        var addresses = this.get('model.addresses');

        if (addressEditMode === 'new') {
          addresses.pushObject(editedAddress);
        } else {
          var editedAddressIndex = this.get('editedAddressIndex');
          var originalEditedAddress = addresses[editedAddressIndex];

          Ember.setProperties(originalEditedAddress, editedAddress);
        }

        this.set('editedAddress', null);
        this.set('addressModalShown', false);
        this.target.send('saveCustomer', this.get('model'));
      },
      cancelEditDeliveryEdit: function cancelEditDeliveryEdit() {
        this.set('editedAddress', null);
        this.set('addressModalShown', false);
      },
      removeDeliveryAddress: function removeDeliveryAddress(addressIdx) {
        var addresses = this.get('model.addresses');

        this.set('model.addresses', addresses.filter(function (x, idx) {
          return idx !== addressIdx;
        }));
      },
      saveCustomer: function saveCustomer(model) {
        var shoppingCartService = this.get('shoppingCartService');

        // proxy to route
        this.target.send('saveCustomer', model);
        if (shoppingCartService.shoppingCart) {
          shoppingCartService.setAddresses(model);
        }
      }
    }
  });
});
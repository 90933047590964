define('webapp/controllers/account/settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    languageService: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    i18n: Ember.inject.service(),
    changePassword: false,

    actions: {
      saveUser: function saveUser() {
        var message = this.get('message');
        var userProxy = this.get('userProxy');
        var myStorage = this.get('myStorage');

        var data = {
          userName: this.get('model.userName'),
          language: this.get('model.language')
        };
        var password = this.get('model.password');

        if (this.get('changePassword') && password) {
          myStorage.user.ajaxPost({
            param: 'password/' + userProxy._id,
            data: {
              oldPass: this.get('passOld'),
              newPass: password
            }
          }).then(function () {
            message.info('Passwort geändert');
          }).catch(function (err) {
            // console.log(err);
            message.error('Fehler beim ändern des Passworts');
          });
        }
        return userProxy.updateUser(data);
      },
      toggleChangePassword: function toggleChangePassword() {
        this.toggleProperty('changePassword');
      },
      setLanguage: function setLanguage(language) {
        var languageService = this.get('languageService');
        this.set('i18n.locale', language);
        this.set('model.language', language);
        languageService.setLanguage(language);
      }
    }
  });
});
define('webapp/helpers/divide', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var number = params[0];
    var divider = params[1] || 1;
    var digits = params[2] || 0;
    var divisionType = params[3];
    var result = divisionType == '%' ? number * (divider / 100) : number / divider;

    return parseFloat(result).toFixed(digits);
  });
});
define('webapp/components/search/search-result-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    searchService: Ember.inject.service(),
    userCanView: true, // this was given as param, set to userProxy?!?!

    mouseEnter: function mouseEnter() {
      var searchService = this.get('searchService');
      if (this.get('item.geoPoint')) {
        searchService.trigger('showPopup', this.get('item'), { onlyMarker: this.get('onlyMarkerOnHover') });
      }
    },


    actions: {
      selectDetail: function selectDetail(detail) {
        this.selectDetail && this.selectDetail(detail);
      }
    }
  });
});
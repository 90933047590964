define('webapp/components/account/visibility-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userProxy: Ember.inject.service(),
    availableUserGroups: Ember.computed.alias('userProxy.availableUserGroups')
  });
});
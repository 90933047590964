define('webapp/components/in-array-checkbox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Checkbox.extend({
    singleSelect: false,
    cloneArrayOnChange: false,

    init: function init() {
      this._super.apply(this, arguments);
      this._calculateChecked();
    },


    _obsSelectedItems: Ember.observer('selectedItems.[]', 'item', function () {
      this._calculateChecked();
    }),

    _calculateChecked: function _calculateChecked() {
      var item = this.get('item');
      var selectedItems = this.get('selectedItems') || [];
      this.set('checked', selectedItems.indexOf(item) >= 0);
    },
    change: function change() {
      this._super.apply(this, arguments);
      var checked = this.get('checked');
      var item = this.get('item');
      var selectedItems = this.get('selectedItems');
      var singleSelect = this.get('singleSelect');

      if (checked) {
        selectedItems.pushObject(item);
        if (singleSelect) {
          selectedItems = [item];
          this.set('selectedItems', selectedItems);
        }
      } else {
        selectedItems.removeObject(item);
      }
      if (this.get('cloneArrayOnChange')) {
        this.set('selectedItems', selectedItems.slice());
      }
      if (this.get('toggleSelect')) {
        this.get('toggleSelect')(item);
      }
    }
  });
});
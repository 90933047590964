define('webapp/components/upload-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    progress: Ember.computed('uploadStatus.progress', function () {
      return Math.round(this.get('uploadStatus.progress'));
    }),

    files: Ember.computed('uploadStatus.files', function () {
      var files = this.get('uploadStatus.files');

      return files && files.map(function (x) {
        var progress = Math.round(x.progress);
        return {
          name: x.name,
          progress: progress,
          style: Ember.String.htmlSafe('width: ' + Ember.String.htmlSafe(progress) + '%;')
        };
      });
    })
  });
});
define('webapp/components/nested-list/nested-list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showChildren: Ember.computed('item.children.length', 'isCollapsed', function () {
      return !this.get('isCollapsed') && this.get('item.children.length') > 0;
    }),

    level: Ember.computed('parentLevel', function () {
      var parentlevel = this.get('parentLevel') || 0;
      return parentlevel + 1;
    }),

    isCollapsed: Ember.computed('item', 'collapsedItems.[]', function () {
      var item = this.get('item');
      if (!item.children || item.children.length === 0) {
        return false;
      }
      var collapsedItems = this.get('collapsedItems');
      return collapsedItems.indexOf(item) >= 0;
    })
  });
});
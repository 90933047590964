define('webapp/services/route-history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    routeInfos: [],

    pushState: function pushState(currentRoute, handlerInfos) {
      var routeInfos = this.get('routeInfos');
      // let routeNames = this.get('routeNames');
      var titles = [];
      var params = [];
      handlerInfos.forEach(function (handlerInfo) {
        var model = handlerInfo.handler.currentModel;
        var title = void 0;
        if (model) {
          title = Ember.get(model, 'name') || Ember.get(model, 'title') || Ember.get(model, 'email');
        }
        if (title) {
          titles.push(title);
        }
        var hParams = handlerInfo.params;
        var keys = Object.keys(hParams);
        if (keys.length) {
          params.push(hParams[keys[0]]);
        }
      });

      routeInfos.push({
        routeName: currentRoute,
        params: params,
        titles: titles
      });

      if (routeInfos.length > 20) {
        routeInfos.unshift();
      }

      this.set('routeInfos', routeInfos.slice()); //notify changes
    },
    cameFromState: function cameFromState(currentState, stateBefore) {
      var routeInfos = this.get('routeInfos');

      var lastCurrentIdx = routeInfos.length - 1;
      for (var i = routeInfos.length - 1; i >= 0; i--) {
        var state = routeInfos[i];
        if (state.routeName.indexOf(currentState) !== 0) {
          lastCurrentIdx = i;
          break;
        }
      }

      var prevState = routeInfos[lastCurrentIdx];

      if (prevState.routeName.indexOf(stateBefore) === 0) {
        return prevState;
      } else {
        return false;
      }
    }
  });
});
define('webapp/components/detail/profile-container', ['exports', 'webapp/utils/media'], function (exports, _media) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    profileContainer: Ember.inject.service(),
    viewService: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    widgetInteractions: Ember.inject.service(),

    itemType: Ember.computed.alias('profileContainer.itemType'),
    detail: Ember.computed.oneWay('profileContainer.detail'),
    entries: Ember.computed.oneWay('profileContainer.entries'),
    showEdit: Ember.computed.alias('profileContainer.showEdit'),

    actions: {
      toggleEdit: function toggleEdit() {
        this.toggleProperty('showEdit');
      },
      saveUser: function saveUser(data) {
        var _this = this;

        var userProxy = this.get('userProxy');
        return userProxy.saveUser(data).then(function () {
          _this.set('showEdit', false);
        });
      },
      confirmImage: function confirmImage(modalType, image) {
        var detail = this.get('detail');
        var userProxy = this.get('userProxy');

        var nestedImage = (0, _media.mediumToNested)(image);

        // set property to userProxy, so new images are displayed immediately
        if (modalType === 'userImage') {
          Ember.set(detail, 'image', nestedImage);
          Ember.set(userProxy, 'image', nestedImage);
        } else if (modalType === 'userBanner') {
          Ember.set(detail, 'banner', nestedImage);
          Ember.set(userProxy, 'banner', nestedImage);
        }

        this.get('widgetInteractions').closeUserModal();
      }
    }
  });
});
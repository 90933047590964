define('webapp/utils/misc', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.omit = omit;
  exports.findLast = findLast;
  exports.joinRoutes = joinRoutes;
  exports.compareByFields = compareByFields;
  exports.executeScripts = executeScripts;
  exports.executeScriptsSrc = executeScriptsSrc;
  function omit(obj, keys) {
    var ret = {};
    Object.keys(obj).forEach(function (key) {
      if (!keys.includes(key)) {
        ret[key] = obj[key];
      }
    });
    return ret;
  }

  function findLast(array, fn) {
    for (var i = array.length - 1; i >= 0; i--) {
      if (fn(array[i])) {
        return array[i];
      }
    }
    return null;
  }

  function joinRoutes() {
    for (var _len = arguments.length, segments = Array(_len), _key = 0; _key < _len; _key++) {
      segments[_key] = arguments[_key];
    }

    return segments.filter(function (x) {
      return x !== undefined && x !== '';
    }).join('/');
  }

  function compareByFields(a, b, fields) {
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = fields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var field = _step.value;

        if (a[field] !== b[field]) {
          return false;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return true;
  }

  function shouldExecuteScript(data) {
    var isJs = true;
    var typeMatch = data.match(/<script.*?type="(.*?)".*?>/);
    if (typeMatch && typeMatch[1] !== 'text/javascript' && typeMatch[1] !== 'application/javascript') {
      isJs = false;
    }
    return isJs;
  }

  function executeScripts(data) {
    if (!data) {
      return;
    }

    var scriptRe = /<script.*?>([\s\S]*?)<\/script>/g;

    var match = void 0;
    while ((match = scriptRe.exec(data)) !== null) {
      if (match[1] && shouldExecuteScript(match[0])) {
        window.eval(match[1]);
        // const script = document.createElement('script');
        // const inlineCode = document.createTextNode(match[1]);
        // script.appendChild(inlineCode);
        // document.body.appendChild(script);
      }
    }
  }

  function executeScriptsSrc(data) {
    if (!data) {
      return;
    }

    var scriptRe = /<script.*? src="(.*?)".*?>/g;

    var match = void 0;
    while ((match = scriptRe.exec(data)) !== null) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = match[1];
      document.body.appendChild(script);
    }
  }
});